<template>
  <div class="sales-pos-appointment-item-list">
    <p class="text-sub text-gray-60" style="padding-left: 32px; margin-bottom: 10px">點按今日預約服務進行入帳</p>
    <swiper
      v-if="loaded"
      class="w-full h-full"
      navigation
      pagination
      centeredSlides
      :loop="false"
    >
      <swiper-slide v-for="(chunk, chunkIdx) in displayData" :key="chunkIdx">
        <div class="list-chunk">
          <div
            v-for="product in chunk"
            :key="product.id"
            class="sales-pos-appointment-item"
            :class="{ isDisabled: isDisabled(product) }"
            @click="onSelectItem(product)"
          >
            <p>{{ product.name }}</p>
            <p>共 {{ product.itemsCount }} 項服務</p>
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { useSalesPOSCreate } from '@/use/useSalesPOS'
import { passValue } from '@/utils/helper'
import { find, map, chunk, get } from 'lodash'

import { Swiper, SwiperSlide, SwiperCore } from 'swiper-vue2'
import { Navigation, Pagination } from 'swiper/core'
import 'swiper/swiper-bundle.css'
import { computed, defineComponent, nextTick, onMounted, ref, watch } from 'vue'

SwiperCore.use([Navigation, Pagination])
export default defineComponent({
  name: 'SalesPOSAppointmentItemList',
  components: {
    Swiper,
    SwiperSlide,
  },
  setup (props, { emit }) {
    const { configData, context, addProductToCart, cart, addOrderPayment } = useSalesPOSCreate()
    const loaded = ref(false)

    const displayData = computed(() => {
      const appointmentOrders = get(configData, 'appointmentOrders', [])
      const showList = map(appointmentOrders, (item) => {
        return {
          id: item.appointmentOrderId,
          name: item.name,
          itemsCount: item.salesRecordItems.length,
          data: item,
        }
      })
      return chunk(showList, 10)
    })

    const isSelected = computed(() => {
      return (product) => {
        return product.id === context.product
      }
    })

    const isDisabled = computed(() => {
      return (item) => {
        const isExist = find(cart.value, {
          appointmentData: {
            AppointmentOrderId: item.data.appointmentOrderId,
          },
        })
        return Boolean(isExist)
      }
    })

    const onSelectItem = (item) => {
      if (isDisabled.value(item)) return
      const curContext = passValue(context)
      const contextData = {
        categoryGroup: curContext.categoryGroup,
        category: curContext.category,
        productGroup: curContext.productGroup,
        // salesUnitGroup: curContext.salesUnitGroup,
        // salesUnit: curContext.salesUnit,
      }

      for (const order of get(item.data, 'salesRecordItems')) {
        addProductToCart({
          id: order.AppointmentServiceId,
          name: order.name,
          defaultPrice: order.price,
          appointmentData: order,
          type: get(order, 'type'),
          ...contextData,
        })
      }

      // 將預約服務的付款方式加入訂單付款方式
      const salesRecordPayments = get(item.data, 'salesRecordPayments')
      if (!salesRecordPayments || salesRecordPayments.length === 0) return
      for (const payment of salesRecordPayments) {
        addOrderPayment({
          type: payment.type,
          price: payment.amount,
          method: 'all',
          appointmentOrderId: payment.AppointmentOrderId,
        })
      }
    }

    const refreshSwiper = () => {
      const prevBtn = document.querySelector('.sales-pos-appointment-item-list .swiper-button-prev')
      const nextBtn = document.querySelector('.sales-pos-appointment-item-list .swiper-button-next')
      const chevronRightIcon = document.createElement('span')
      chevronRightIcon.classList.add('material-icons')
      chevronRightIcon.textContent = 'chevron_right'
      chevronRightIcon.style.fontSize = '24px'
      chevronRightIcon.style.width = '24px'
      chevronRightIcon.style.color = 'var(--primary-100)'

      const chevronLefttIcon = document.createElement('span')
      chevronLefttIcon.classList.add('material-icons')
      chevronLefttIcon.textContent = 'chevron_left'
      chevronLefttIcon.style.fontSize = '24px'
      chevronLefttIcon.style.width = '24px'
      chevronLefttIcon.style.color = 'var(--primary-100)'

      // 清空 prevBtn 和 nextBtn 的內容，避免重複添加
      if (prevBtn) prevBtn.innerHTML = ''
      if (nextBtn) nextBtn.innerHTML = ''

      if (prevBtn) prevBtn.appendChild(chevronLefttIcon)
      if (nextBtn) nextBtn.appendChild(chevronRightIcon)
    }

    watch(displayData, async () => {
      loaded.value = false
      await nextTick()
      setTimeout(() => {
        refreshSwiper()
      }, 0)
      loaded.value = true
    })

    onMounted(async () => {
      await nextTick()
      setTimeout(() => {
        refreshSwiper()
      }, 100)
      loaded.value = true
    })

    return { displayData, configData, loaded, isSelected, onSelectItem, isDisabled }
  },
})
</script>

<style lang="postcss" scoped>
.list-chunk {
  @apply grid grid-cols-5 grid-rows-3 gap-[12px] px-[32px];
  @apply w-full h-full;
}

.sales-pos-appointment-item {
  @apply bg-primary-30 rounded-[6px] max-h-[88px];
  @apply flex flex-col items-center justify-center text-center;
  @apply select-none cursor-pointer;
  @apply border-[2px] border-transparent;
}

.isSelected {
  @apply border-primary-100 border-[2px] bg-white text-primary-100;
}

.isDisabled {
  @apply bg-gray-20 cursor-not-allowed;
}

::v-deep(.swiper-button-prev) {
  @apply bg-primary-10 left-0 top-[50%] cursor-pointer;
  @apply w-[24px] h-[24px] rounded-[6px];
}

::v-deep(.swiper-button-next) {
  @apply bg-primary-10 right-0 top-[50%] cursor-pointer;
  @apply w-[24px] h-[24px] rounded-[6px];
}

::v-deep(.swiper-button-prev::after) {
  content: none;
}
::v-deep(.swiper-button-next::after) {
  content: none;
}
</style>
