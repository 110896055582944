<template>
  <BaseDialog title="手動輸入折扣金額" @close="$emit('close')" @confirm="onConfirm">
    <div>
      <BaseElForm ref="formRef" :model="formData" :rules="formRules">
        <BaseElFormItem label="手動輸入折扣金額" prop="amount">
          <BaseElInput v-model="formData.amount" type="number" :max="maxDiscount" />
        </BaseElFormItem>
      </BaseElForm>
    </div>
  </BaseDialog>
</template>

<script>
import { computed, defineComponent } from 'vue'
import BaseDialog from './Dialog/BaseDialog.vue'
import { useBaseForm } from '@/use/useForm'
import { toNumber } from 'lodash'
import { isDigitRules, noEmptyRules, maxNumberRules } from '@/validation'
import { useSalesPOSCreate } from '@/use/useSalesPOS'

export default defineComponent({
  name: 'SalesPOSManualCashDiscountModal',
  components: {
    BaseDialog,
  },
  props: {
    scope: {
      type: String,
      required: true,
    },
    maxPrice: {
      type: Number,
    }
  },
  setup(props, { emit }) {
    const { orderUnpaidPrice, getActiveCartItem } = useSalesPOSCreate()
    const { formRef, formData, initFormData, checkForm, } = useBaseForm()

    initFormData({
      amount: null,
    })

    const formRules = computed(() => ({
      amount: [noEmptyRules(), isDigitRules(), maxNumberRules(maxDiscount.value)],
    }))

    // 最大可折抵金額
    const maxDiscount = computed(() => {
      if (props.maxPrice) {
        return props.maxPrice
      }
      if (props.scope === 'singleDiscount') {
        const activeCartItem = getActiveCartItem()
        return activeCartItem.getDiscountRemainingPrice()
      } else if (props.scope === 'orderDiscount') {
        return orderUnpaidPrice.value
      }
      return props.maxPrice
    })

    const onConfirm = async () => {
      const pass = await checkForm(formRef.value)
      if (!pass) return
      const discount = {
        cashAmount: toNumber(formData.amount),
        id: 'manual',
        name: '手動折扣',
        type: 'cash',
      }
      emit('confirm', discount)
      emit('close')
    }

    return { formRef, formData, onConfirm, formRules, maxDiscount }
  },
})
</script>

<style lang="postcss" scoped></style>
