<template>
  <div class="pospal-api-form">
    <BaseElFormItem
      v-for="(field, index) in formFields"
      :key="index"
      :label="field.label"
      :prop="field.prop"
    >
      <template v-if="field.toolTip">
        <template slot="label">
          <FormItemTooltipLabel :label="field.label" :tooltipWidth="200">
            <div v-html="field.toolTip" />
          </FormItemTooltipLabel>
        </template>
        <BaseElInput v-model="syncFields[field.prop]" :disabled="disableFields" />
      </template>
      <BaseElInput v-else v-model="syncFields[field.prop]" :disabled="disableFields" />
    </BaseElFormItem>
    <slot></slot>
    <p>會員同步完成時間：{{ displaySyncTime }}</p>
  </div>
</template>

<script>
import { useVModel } from '@vueuse/core'
import { defineComponent, onMounted, ref, computed } from 'vue'
import FormItemTooltipLabel from '@/components/Form/FormItemTooltipLabel.vue'
import { GetPospalClientConfig } from '@/api/pospal'
import { useShop } from '@/use/shop'
import { get } from 'lodash'
import { formatDate } from '@/utils/date'
export default defineComponent({
  name: 'PospalApiForm',
  components: {
    FormItemTooltipLabel,
  },
  props: {
    formData: { type: Object, default: () => ({}) },
    disableFields: Boolean,
  },
  emits: ['update:formData'],
  setup (props, { emit }) {
    const { shopId } = useShop()
    const syncFields = useVModel(props, 'formData', emit)
    const formFields = ref([
      { label: '門店 areaId', prop: 'areaId' },
      { label: '門店 API Host', prop: 'host' },
      { label: '門店 appId', prop: 'appId' },
      { label: '門店 appKey', prop: 'appKey' },
      {
        label: '會員編號前綴', prop: 'memberPrefix', toolTip: `提示訊息文字：<br>
          會員編號前綴是作為在 POS 端區分各門店會員使用<br>
          若是您在 POS 端會員為互通，請在各分店間填入相同前綴<br>
          若是您在 POS 端會員不相通，請在各分店間填入不同前綴<br>`,
      },
    ])
    const registerInfo = ref(null)

    const displaySyncTime = computed(() => {
      const done = get(registerInfo.value, 'registerStatus') === 'done'
      if (done) {
        const dateTime = get(registerInfo.value, 'registerSuccessAt')
        return formatDate(dateTime, 'YYYY/MM/DD HH:mm:ss')
      }
      return '-'
    })

    const getPospalClientConfig = async () => {
      const [res, err] = await GetPospalClientConfig({
        shopId: shopId.value,
      })
      if (err) {
        window.$message.error(err)
        return
      }
      registerInfo.value = res
      console.log(res)
    }

    onMounted(async () => {
      getPospalClientConfig()
    })

    return {
      syncFields,
      displaySyncTime,
      formFields,
    }
  },
})
</script>

<style lang="postcss" scoped>

</style>
