<template>
  <div class="sales-pos-side-panel-footer">
    <el-collapse-transition>
      <div v-show="expand.detail" class="detail-container">
        <div v-for="item in displayDetailData" :key="item.name" class="flex justify-between items-center">
          <p>{{ item.name }}</p>
          <p :style="`color: ${item.valueColor}`">{{ item.value }}</p>
        </div>
      </div>
    </el-collapse-transition>

    <div class="flex justify-between items-center cursor-pointer" style="padding: 12px" @click="expand.detail = !expand.detail">
      <div class="flex items-center" style="gap: 8px">
        <MaterialIcon v-if="expand.detail" size="16">keyboard_arrow_down</MaterialIcon>
        <MaterialIcon v-if="!expand.detail" size="16">keyboard_arrow_up</MaterialIcon>
        <p>合計</p>
      </div>
      <p>{{ displayData.totalPrice }}</p>
    </div>
  </div>
</template>

<script>
import { useSalesPOSCreate } from '@/use/useSalesPOS'
import { reduce, filter } from 'lodash'
import { localeString } from '@/utils/helper'

import { computed, defineComponent, reactive } from 'vue'
export default defineComponent({
  name: 'SalesPOSSidePanelFooter',
  setup (props, { emit }) {
    const { context, originTotalPrice, getAllSingleDiscountsPrice, cart } = useSalesPOSCreate()
    const expand = reactive({
      detail: false,
    })

    const displayData = computed(() => {
      const paidDeposit = reduce(context.payments, (sum, payment) => {
        if (payment.type === 'paidDeposit') {
          return sum + payment.price
        }
        return sum
      }, 0)

      const totalPrice = originTotalPrice.value - paidDeposit - getAllSingleDiscountsPrice()

      return {
        totalPrice: localeString(totalPrice),
      }
    })

    const displayDetailData = computed(() => {
      const omitList = []
      const paidDeposit = reduce(context.payments, (sum, payment) => {
        if (payment.type === 'paidDeposit') {
          return sum + payment.price
        }
        return sum
      }, 0)

      const data = [
        { name: '品項數量', value: cart.value.length },
        { name: '品項合計', value: originTotalPrice.value },
        { name: '已收訂金', value: `-$ ${paidDeposit}`, valueColor: 'var(--danger)' },
        { name: '折扣金額', value: `-$ ${getAllSingleDiscountsPrice()}`, valueColor: 'var(--danger)' },
      ]

      if (!paidDeposit) {
        omitList.push('已收訂金')
      }

      return filter(data, (item) => {
        return !omitList.includes(item.name)
      })
    })
    return { expand, displayDetailData, displayData }
  },
})
</script>

<style lang="postcss" scoped>
.sales-pos-side-panel-footer {
    @apply select-none;
}

.detail-container {
  @apply py-[8px] text-sub px-[12px] pl-[38px];
  @apply flex flex-col gap-[8px];
  @apply border-b-[1px] border-primary-10 border-solid;
}
</style>
