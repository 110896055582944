import shift from './shift'

export default [
  ...shift,
  {
    name: 'Playground',
    path: '/playground',
    component: () => import('@/views/test/playground.vue'),
  },
]
