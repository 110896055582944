<template>
  <header class="homepage-headbar">
    <p class="welcome">你好, {{ shopConfig.name }}</p>
    <div class="head-item-list">
      <p class="head-item">
        <img src="../assets/phone.svg">
        <!-- <i class="ph-phone" /> -->
        {{ shopConfig.phone || '尚未設定' }}
      </p>
      <p class="head-item">
        <img src="../assets/location.svg">
        <!-- <i class="ph-map-pin" /> -->
        {{ shopConfig.address || '尚未設定' }}
      </p>
    </div>
  </header>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'HeadBar',
  data: () => ({}),
  computed: {
    shopConfig () {
      return this.$store.state.shop.shopConfig
    },
  },
}
</script>

<style lang="postcss">
.welcome {
  @apply text-primary-100 text-[24px] leading-[28.8px] font-medium;
}
.homepage-headbar {
  @apply flex justify-between items-center;
}
.head-item-list {
  @apply flex items-center gap-[36px];
}
.head-item {
  @apply flex items-center gap-[12px] text-gray-60;
}
</style>
