<template>
  <BaseDialog fullscreen hideConfirm hideCancel @close="$emit('close')">
    <div class="flex flex-col items-center justify-cente h-full">
      <div class="qrcode-scan">
        <QrcodeStream
          class="qrcode-reader"
          :constraints="qrcodeConstraints"
          @init="onInit"
          @decode="onDecode"
        >
          <div v-if="qrcode.init" class="qrcode-reader__prompt">等待開啟相機...</div>
        </QrcodeStream>
      </div>
    </div>
  </BaseDialog>
</template>

<script>
import { defineComponent, reactive } from 'vue'
import { QrcodeStream } from 'vue-qrcode-reader'
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import { includes, get } from 'lodash'

export default defineComponent({
  name: 'SalesPOSQrcodeScan',
  components: {
    QrcodeStream,
    BaseDialog,
  },
  setup (props, { emit }) {
    const qrcodeConstraints = reactive({
      facingMode: 'environment',
    })
    const qrcode = reactive({
      init: true,
      visible: false,
      decodedString: '',
    })

    const onInit = async (promise) => {
      qrcode.init = true
      try {
        await promise
      } catch (error) {
        console.log('qrcode error', error)
        window.$message.error('無法開啟相機')
      } finally {
        qrcode.init = false
      }
    }

    const isValidUUID = (uuid) => {
      const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i
      return uuidRegex.test(uuid)
    }

    const onDecode = async (decodedString) => {
      qrcode.decodedString = decodedString
      qrcode.visible = false

      const splitString = decodedString.split(':')
      if (includes(splitString, 'bob') && includes(splitString, 'member')) {
        emit('decode', get(splitString, '[2]'))
        emit('close')
        return
      }
      // use regex to match the string if it is a valid uuid

      emit('decode', decodedString)
      emit('close')
    }
    return {
      qrcode,
      onInit,
      onDecode,
      qrcodeConstraints,
    }
  },
})
</script>

<style lang="postcss" scoped>
.qrcode-scan {
  @apply w-[429px] h-[429px] rounded-[30px] overflow-hidden;
  @apply bg-white bg-opacity-50;
}
.qrcode-reader {
  @apply flex items-center justify-center;
}

::v-deep(.el-dialog) {
  @apply flex items-center justify-center bg-transparent;
}

::v-deep(.qrcode-stream-overlay) {
  @apply flex items-center justify-center;
}
</style>
