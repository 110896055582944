<template>
  <div class="page">
    <PageTitle
      title="銷售人員報表"
      btn="匯出"
      btn2="顯示設定"
      :hideBtn1="!useSalesUnitReport"
      @btnClick="dialog.export = true"
      @btn2Click="dialog.displaySettings = true"
    />
    <FilterContainer>
      <BaseElSelect
        v-model="search.name"
        class="test2"
        clearable
        multiple
        placeholder="搜尋銷售人員"
        @change="refresh(true)"
        @clear="refresh(true)"
      >
        <BaseElSelectOption
          v-for="item in salesUnitList"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        />
      </BaseElSelect>
    </FilterContainer>
    <SalesReportDisplaySettingsDialog
      v-if="dialog.displaySettings"
      @close="dialog.displaySettings = false"
      @confirm="getDisplaySettings"
    />

    <div class="datetime-container">
      <GroupTimeFilterButton
        :isCustomRange.sync="customerRange"
        @month="getMonthRange"
        @week="getWeekRange"
        @today="getTodayRange"
      />

      <div class="time-picker-container">
        <el-date-picker
          v-model="searchTime"
          editable
          type="datetimerange"
          range-separator="至"
          start-placeholder="開始日期"
          end-placeholder="结束日期"
          format="yyyy-MM-dd HH:mm"
          :default-time="['00:00:00', '23:59:59']"
          @change="changeToCustomer"
        />
      </div>
    </div>

    <div class="title">
      <span class="title-hint">|</span>
      <span>銷售業績</span>
    </div>
    <BaseTable
      v-loading="loading.table"
      empty-text="暫無數據"
      :data="salesPerformanceItems"
      :default-sort="{ prop: 'date', order: 'descending' }"
    >
      <EmptyBlock slot="empty" />

      <BaseElTableColumn prop="salesUnit" label="銷售人員" sortable />
      <BaseElTableColumn prop="totalPrice" label="銷售金額" sortable />
      <BaseElTableColumn prop="totalPaymentPrice" :label="totalPaymentPriceLabel" sortable>
        <template slot-scope="scope">
          {{
            get(displaySettings, 'excludeWallet')
              ? unitTotalPaymentPrice(
                scope.row.totalPaymentPrice - scope.row.totalWalletAmount
              )
              : unitTotalPaymentPrice(scope.row.totalPaymentPrice)
          }}
        </template>
      </BaseElTableColumn>
      <BaseElTableColumn prop="itemQuantity" label="銷售產品數" sortable />
      <BaseElTableColumn prop="itemRevenuePrice" label="業績金額" sortable />
    </BaseTable>
    <div class="title">
      <span class="title-hint">|</span>
      <span>銷售產品紀錄</span>
    </div>
    <BaseTable
      v-loading="loading.table"
      empty-text="暫無數據"
      :data="salesRecordItems"
      :default-sort="{ prop: 'date', order: 'descending' }"
    >
      <EmptyBlock slot="empty" />
      <BaseElTableColumn prop="orderNo" label="訂單編號" />
      <BaseElTableColumn prop="status" label="訂單狀態">
        <template slot-scope="scope">
          <Tag :type="selectStatus(scope.row.status, 'tagType')">
            {{ selectStatus(scope.row.status, 'label') }}
          </Tag>
        </template>
      </BaseElTableColumn>
      <BaseElTableColumn prop="createdAt" label="建立時間" width="120px" />
      <BaseElTableColumn prop="salesUnit" label="銷售人員" />
      <BaseElTableColumn prop="productName" label="產品名稱" />
      <BaseElTableColumn prop="quantity" label="數量" />
      <BaseElTableColumn prop="totalPrice" label="產品銷售總額" />
      <BaseElTableColumn prop="totalPaymentPrice" :label="productTotalPaymentPriceLabel">
        <template slot-scope="scope">
          {{
            get(displaySettings, 'excludeWallet')
              ? unitTotalPaymentPrice(
                scope.row.itemAmountByPaymentProportion - scope.row.itemAmountByWalletProportion
              )
              : unitTotalPaymentPrice(scope.row.itemAmountByPaymentProportion)
          }}
        </template>
      </BaseElTableColumn>
      <BaseElTableColumn prop="itemRevenuePrice" label="業績金額" />
    </BaseTable>
    <Pagination
      :curPage.sync="tableOptions.page"
      :pageLimit="tableOptions.pageLimit"
      :total="tableDataCount"
      @pageChange="refresh"
    />
    <ExportOptionsDialog
      v-if="dialog.export"
      allRange
      :useExportTask="useSalesUnitReport"
      @close="dialog.export = false"
      @export="prepareExport"
    />
  </div>
</template>

<script>
import { defineComponent, reactive, onMounted, ref, computed } from 'vue'
import GroupTimeFilterButton from '@/components/Input/GroupTimeFilterButton'
import SalesReportDisplaySettingsDialog from './components/SalesReportDisplaySettingsDialog.vue'
import ExportOptionsDialog from '@/components/Dialog/ExportOptionsDialog.vue'
import FilterContainer from '@/components/Container/FiltersContainer.vue'
import EmptyBlock from '@/components/EmptyBlock.vue'
import {
  GetSalesUnitList,
  GetSalesUnitPerformance,
  // FindSalesUnitReportConfig,
  GetSalesItemRecord,
  GetSalesItemRecordCount,
} from '@/api/sales'
import { CreateSalesUnitReportSheetExportTask } from '@/api/exportTask'
import dayjs from '@/lib/dayjs'
import startOfDay from 'date-fns/startOfDay'
import endOfDay from 'date-fns/endOfDay'
import startOfWeek from 'date-fns/startOfWeek'
import endOfWeek from 'date-fns/endOfWeek'
import startOfMonth from 'date-fns/startOfMonth'
import endOfMonth from 'date-fns/endOfMonth'
import { salesRecordOrderStatusConfig } from '@/config/sales'
import { usePermissions } from "@/use/permissions"
import { useDisplaySettings } from '@/use/displaySettings'
import { get, isEmpty, map } from 'lodash'
import { useTable } from '@/use/table'
import { useExportCenter } from '@/use/useExportCenter'

export default defineComponent({
  name: 'SalesUnitReport',
  components: {
    EmptyBlock,
    GroupTimeFilterButton,
    SalesReportDisplaySettingsDialog,
    FilterContainer,
    ExportOptionsDialog,
  },
  setup (props) {
    const { checkAction } = usePermissions()
    const { messageOptions } = useExportCenter()
    const { getDisplaySettings, displaySettings } = useDisplaySettings('salesReport')
    const {
      shopId, loading, search, extendData, pageStartIndex,
      tableData, tableDataCount, tableOptions,
      fetchData, fetchDataCount, dateFormat
    } = useTable()
    const useSalesUnitReport = computed(() => checkAction('admin.salesUnitReport.createSheetExportTask'))
    const searchTime = ref([])
    const salesUnitList = ref([])
    const salesPerformance = ref([])
    const customerRange = ref(false)
    extendData('search', {
      name: null,
    })
    const dialog = reactive({
      displaySettings: false,
      export: false,
    })
    const totalPaymentPriceLabel = computed(() => {
      return get(displaySettings.value, 'excludeWallet')
        ? '實收比例金額(扣除儲值金)'
        : '實收比例金額'
    })
    const productTotalPaymentPriceLabel = computed(() => {
      return get(displaySettings.value, 'excludeWallet')
        ? '產品已付金額(扣除儲值金)'
        : '產品已付金額'
    })
    const salesRecordItems = computed(() => {
      return map(tableData.value, item => ({
        itemRevenuePrice: item.itemRevenuePrice,
        itemAmountByPaymentProportion: item.itemAmountByPaymentProportion,
        itemAmountByWalletProportion: item.itemAmountByWalletProportion,
        orderNo: get(item, 'SalesRecordItem.SalesRecord.orderNo', '-'),
        status: get(item, 'SalesRecordItem.SalesRecord.status', '-'),
        createdAt: get(item, 'SalesRecordItem.createdAt') ? dateFormat(get(item, 'SalesRecordItem.createdAt')) : '-',
        salesUnit: get(item, 'SalesRecordItem.SalesUnit.name', '無銷售人員'),
        productName: get(item, 'SalesRecordItem.name', '-'),
        quantity: get(item, 'SalesRecordItem.quantity', '-'),
        totalPaymentPrice: get(item, 'SalesRecordItem.SalesRecord.totalPaymentPrice', '-'),
        totalWalletAmount: get(item, 'SalesRecordItem.SalesRecord.totalWalletAmount', '-'),
        totalPrice: get(item, 'SalesRecordItem.totalPrice', '-'),
      }))
    })
    const salesPerformanceItems = computed(() => {
      return map(salesPerformance.value, item => ({
        salesUnit: get(item, 'SalesUnit.name', '無銷售人員'),
        totalPrice: get(item, 'itemTotalPrice', '-'),
        totalPaymentPrice: get(item, 'itemAmountByPaymentProportion', '-'),
        itemQuantity: get(item, 'itemQuantity', '-'),
        itemRevenuePrice: get(item, 'itemRevenuePrice', '-'),
        totalWalletAmount: get(item, 'itemAmountByWalletProportion', '-'),
      }))
    })

    const selectStatus = (status, attr) => {
      return get(salesRecordOrderStatusConfig[status], attr)
    }
    // const findSalesUnitReportConfig = async () => {
    //   const [res, err] = await FindSalesUnitReportConfig({
    //     shopId: shopId.value,
    //   })
    //   if (err) {
    //     window.$message.error(err)
    //     return
    //   }
    //   console.log('findSalesUnitReportConfig', res)
    // }
    const getSalseUnitList = async () => {
      const [res, err] = await GetSalesUnitList({
        shopId: shopId.value,
      })
      if (err) {
        window.$message.error(err)
        return
      }
      salesUnitList.value = res
      if (isEmpty(salesUnitList.value)) {
        window.$message.error('無銷售人員資料')
      } else {
        await getTodayRange()
      }
    }

    const getSalesUnitPerformance = async (payload) => {
      const [res, err] = await GetSalesUnitPerformance(payload)
      if (err) {
        window.$message.error(err)
        return
      }
      salesPerformance.value = res.units
    }
    const unitTotalPaymentPrice = (price) => {
      if (!price) return 0
      const total = price.toFixed(1)
      return parseFloat(total)
    }
    // const unitSalesPrice = (row) => {
    //   const totalRevenuePrice = row.salesUnitId ? row.totalRevenuePrice : 0
    //   if (get(displaySettings.value, 'excludeWallet')) { return totalRevenuePrice - row.totalWalletAmount }
    //   return totalRevenuePrice
    // }

    const refresh = async () => {
      const payload = {
        shopId: shopId.value,
        createdAtStart: searchTime.value[0],
        createdAtEnd: searchTime.value[1],
        salesUnitIds: !isEmpty(search.name) ? search.name.join(',') : null,
        start: pageStartIndex.value,
        limit: tableOptions.pageLimit,
      }
      loading.table = true
      await Promise.all([
        // findSalesUnitReportConfig(),
        getSalesUnitPerformance(payload),
        fetchData(GetSalesItemRecord, payload),
        fetchDataCount(GetSalesItemRecordCount, payload),
      ])
      loading.table = false
    }
    const getTodayRange = async () => {
      const start = startOfDay(new Date())
      const end = endOfDay(new Date())
      searchTime.value = []
      searchTime.value[0] = start
      searchTime.value[1] = end
      await refresh()
    }
    const getWeekRange  = async () => {
      const start = startOfWeek(new Date())
      const end = endOfWeek(new Date())
      searchTime.value = []
      searchTime.value[0] = start
      searchTime.value[1] = end
      await refresh()
    }
    const getMonthRange = async () => {
      const start = startOfMonth(new Date())
      const end = endOfMonth(new Date())
      searchTime.value = []
      searchTime.value[0] = start
      searchTime.value[1] = end
      await refresh()
    }
    const changeToCustomer = async () => {
      const start = dayjs(searchTime.value[0])
      const end = dayjs(searchTime.value[1])

      if (start.hour() !== 0 || start.minute() !== 0) {
        customerRange.value = true
      } else if (end.hour() !== 23 || end.minute() !== 59) {
        customerRange.value = true
      }
      await refresh()
    }
    const prepareExport = async ({ all, range }) => {
      dialog.export = false
      const payload = {
        shopId: shopId.value,
        createdAtStart: searchTime.value[0],
        createdAtEnd: searchTime.value[1],
        salesUnitIds: !isEmpty(search.name) ? search.name : null,
        deductWallet: get(displaySettings.value, 'excludeWallet'),
      }
      await createSheetExportTask(CreateSalesUnitReportSheetExportTask, payload)
    }
    const createSheetExportTask = async (apiFunc, payload) => {
      const [, err] = await apiFunc(payload)
      if(err) {
        window.$message.error(err)
        return
      }
      window.$message(messageOptions.value)
    }
    onMounted(async() => {
      getDisplaySettings()
      await getSalseUnitList()
    })
    return {
      get,
      dialog,
      getDisplaySettings,
      displaySettings,
      search,
      searchTime,
      salesUnitList,
      refresh,
      tableOptions,
      tableData,
      tableDataCount,
      loading,
      pageStartIndex,
      salesRecordItems,
      unitTotalPaymentPrice,
      salesPerformanceItems,
      getTodayRange,
      getWeekRange,
      getMonthRange,
      selectStatus,
      changeToCustomer,
      customerRange,
      useSalesUnitReport,
      prepareExport,
      totalPaymentPriceLabel,
      productTotalPaymentPriceLabel,
      // unitSalesPrice,
    }
  },
})
</script>

<style lang="scss" scoped>
.page {
  padding: 0 10px;
  font-weight: normal;
}
.title {
  font-size: 24px;
  margin-top: 30px;
  margin-bottom: 30px;
  .sub {
    font-size: 13px;
    color: #666;
  }
}

.title-hint {
  @apply text-primary-100 mr-[5px];
}

.picker-container {
  margin-right: 10px;
}

.date-range-btn {
  color: white;
  background: white;
  border: 1px solid #dedede;
  border-right-color: #dedede !important;
  border-left-color: #dedede !important;
}

.active {
  @apply border border-primary-100 bg-primary-100 text-white;
}

.dialog-content {
  display: flex;
  flex-direction: column;
}

.end-block {
  padding-bottom: 100px;
}

.datetime-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px;
  margin: 8px 0 30px;
}

.date {
  width: 30%;
  height: 100%;
  text-align: right;
  vertical-align: bottom;
}

::v-deep .el-select .el-input {
  @apply h-full;
}
</style>
