<template>
  <div class="sales-pos-member-info-block">
    <div>
      <p>{{ displayData.name }}</p>
      <p>{{ displayData.phone }}</p>
    </div>

    <div class="grid grid-cols-2">
      <div v-for="resource in displayResourceFields" :key="resource.label" class="text-sub">
        <span class="font-medium">{{ resource.label }}</span>：<span class="text-primary-100">{{ resource.value }}</span>
      </div>
    </div>

    <div class="flex items-center" style="gap: 8px">
      <BaseElButton plain @click="onChangeMember">
        <div class="flex items-center" style="gap: 4px">
          <MaterialIcon size="20">person_search</MaterialIcon>
          重輸號碼
        </div>
      </BaseElButton>
      <BaseElButton plain @click="onReset">
        <div class="flex items-center" style="gap: 4px">
          <MaterialIcon size="20">refresh</MaterialIcon>
          重置明細
        </div>
      </BaseElButton>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent } from 'vue'
import { get, filter, isNull } from 'lodash'
import { useSalesPOSCreate } from '@/use/useSalesPOS'
import { useRouter } from 'vue-router/composables'
import { usePermissions } from '@/use/permissions'

export default defineComponent({
  name: 'SalesPOSMemberInfoBlock',
  setup (props, { emit }) {
    const router = useRouter()
    const { configData, reset, context, cart } = useSalesPOSCreate()
    const { checkAction } = usePermissions()

    const useFeatures = computed(() => {
      return {
        usePoint: checkAction('admin.shopPoint.page'),
        useChashback: checkAction('admin.shopCashback.page'),
        useClassTicket: checkAction('admin.classTicket.page'),
        useCoupon: checkAction('admin.coupon.page'),
      }
    })

    const displayData = computed(() => {
      const consumerData = configData.consumerData
      let userName
      let userPhone

      if (get(context.member, 'id')) {
        userName = get(consumerData, 'userInfo.name')
        userPhone = get(consumerData, 'userInfo.phone')
      } else {
        userName = get(context.member, 'name')
        userPhone = get(context.member, 'phone')
      }
      return {
        name: userName,
        phone: userPhone,
        resources: {
          cashback: get(consumerData, 'cashbackBalance'), // 回饋金
          wallet: get(consumerData, 'walletBalance'), // 儲值金
          coupon: get(consumerData, 'couponRecords.length'), // 票券
          point: get(consumerData, 'pointBalance'), // 點數
        },
      }
    })

    const displayResourceFields = computed(() => {
      const omitList = []
      const dields = [
        { label: '儲值金', value: displayData.value.resources.wallet },
        { label: '點數', value: displayData.value.resources.point },
        { label: '回饋金', value: displayData.value.resources.cashback },
        { label: '票券', value: displayData.value.resources.coupon },
      ]

      if (isNull(displayData.value.resources.point)) omitList.push('點數')
      if (isNull(displayData.value.resources.cashback)) omitList.push('回饋金')
      if (isNull(displayData.value.resources.coupon)) omitList.push('票券')
      if (isNull(displayData.value.resources.wallet)) omitList.push('儲值金')

      if (!get(context, 'member.id')) {
        return []
      }

      return filter(dields, (field) => !omitList.includes(field.label))
    })

    const onReset = () => {
      cart.value = []
      context.payments = []
      context.orderDiscount = {}
    }
    const onChangeMember = () => {
      router.push({ name: 'SalesPOSEntry' })
    }
    return { displayData, displayResourceFields, onReset, onChangeMember }
  },
})
</script>

<style lang="postcss" scoped>
.sales-pos-member-info-block {
  @apply p-[16px];
  @apply flex flex-col gap-[12px];
}
</style>
