<template>
  <div class="contract-plan-list">
    <PageTitle :title="$t('sheetExportCenter.title')" hideBtn />
    <section>
      <BaseTable v-loading="loading.table" :data="downloadList" :empty-text="$t('common.table.empty.text')">
        <EmptyBlock slot="empty" />
        <BaseElTableColumn :label="$t('sheetExportCenter.table.workbookTemplateType.title')" prop="workbookTemplateType" align="center" fixed="left" />
        <BaseElTableColumn :label="$t('sheetExportCenter.table.createdAt.title')" prop="createdAt" align="center" />
        <BaseElTableColumn :label="$t('sheetExportCenter.table.createdBy.title')" prop="createdBy" align="center" />
        <BaseElTableColumn :label="$t('sheetExportCenter.table.status.title')" prop="status" align="center">
          <template slot-scope="scope">
            <Tag :type="taskStatus(scope.row.status,'type')">{{ taskStatus(scope.row.status, 'label') }}</Tag>
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn :label="$t('sheetExportCenter.table.lastStatusAt.title')" prop="lastStatusAt" align="center" />
        <BaseElTableColumn :label="$t('sheetExportCenter.table.fileRetentionAt.title')" prop="fileRetentionAt" align="center" />
        <BaseElTableColumn :label="$t('sheetExportCenter.table.description.title')" prop="description" align="center" />
        <BaseElTableColumn fixed="right" :label="$t('common.table.action.text')" width="100" align="center">
          <template slot-scope="scope">
            <TableEditBtnGroup
              hideDelete
              :editBtn="$t('sheetExportCenter.button.download.text')"
              :editLoading="scope.row.loading"
              :hideEdit="scope.row.status !== 'completed'"
              @edit="onDownload(scope.row)"
            />
          </template>
        </BaseElTableColumn>
      </BaseTable>

      <Pagination
        :curPage.sync="tableOptions.page"
        :pageLimit="tableOptions.pageLimit"
        :total="tableDataCount"
        @pageChange="refresh"
      />
    </section>
  </div>
</template>

<script>
import EmptyBlock from '@/components/EmptyBlock.vue'
import { GetSheetExport, GetSheetExportCount, GetSheetExportDownloadLink } from '@/api/sheetExportCenter'
import { get, map } from 'lodash'
import { defineComponent, ref, reactive, computed, onMounted, set } from 'vue'
import { taskStatusConfig, workBookTemplateConfig, errorCodeConfig } from '@/config/sheetExportCenter'
// Utils
import { formatDate } from '@/utils/date'
import { useTable } from '@/use/table'
import { i18n } from '@/plugins/i18n/i18n'

export default defineComponent( {
  name: 'SheetExportCenter',
  components: { EmptyBlock },
  setup () {
    const { tableData, tableDataCount, tableOptions, fetchData, fetchDataCount, pageStartIndex, loading, shopId } = useTable()
    const selectRow = ref(null)
    const downloadList = computed(() => {
      return map(tableData.value, one => ({
        id: one.id,
        workbookTemplateType: one.workbookTemplateType ? workbookTemplate(one.workbookTemplateType, 'label') : '-',
        createdAt: one.createdAt ? formatDate(one.createdAt, 'YYYY/MM/DD HH:mm') : '-',
        createdBy: one.CreatedBy ? get(one.CreatedBy, 'name') : '-',
        status: one.status,
        lastStatusAt: one.lastStatusAt ? formatDate(one.lastStatusAt, 'YYYY/MM/DD HH:mm') : '-',
        fileRetentionAt: one.fileRetentionAt ? formatDate(one.fileRetentionAt, 'YYYY/MM/DD HH:mm') : '-',
        description: one.status === 'failed' ? `${errorCodeType(one.errorCode, 'label')}: ${one.errorMessage}` : '-',
        loading: false,
      }))
    })
    const errorCodeType = (key, attr) => {
      return get(errorCodeConfig[key], attr)
    }
    const workbookTemplate = (key, attr) => {
      return get(workBookTemplateConfig[key], attr)
    }
    const taskStatus = (key, attr) => {
      return get(taskStatusConfig[key], attr)
    }
    const onDownload = async (row) => {
      row.loading = true
      selectRow.value = row
      await getDownloadLink()
      row.loading = false
    }
    const getDownloadLink = async () => {
      const payload = {
        shopId: shopId.value,
        id: get(selectRow.value, 'id'),
      }
      const [res, err] = await GetSheetExportDownloadLink(payload)
      if(err) {
        window.$message.error(i18n.t('sheetExportCenter.message.downloadFail'))
        return
      }
      window.location.href = res.downloadLink
    }
    const refresh = async () => {
      const payload = {
        shopId: shopId.value,
        start: pageStartIndex.value,
        limit: tableOptions.pageLimit,
      }
      loading.table = true
      await Promise.allSettled([
        fetchData(GetSheetExport, payload),
        fetchDataCount(GetSheetExportCount, payload),
      ])
      loading.table = false
    }

    onMounted(async () => {
      await refresh()
    })

    return {
      loading,
      selectRow,
      tableOptions,
      tableData,
      tableDataCount,
      downloadList,
      formatDate,
      taskStatus,
      onDownload,
      refresh,
    }
  },
})
</script>

<style scoped lang="scss"></style>
