<template>
  <div class="base-divider" />
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'BaseDivider',
})
</script>

<style lang="postcss" scoped>
.base-divider {
  @apply h-[1px] bg-gray-30 w-full;
}
</style>
