<template>
  <div class="main-wrapper">
    <div class="left-panel">
      <SalesPOSSidePanel />
    </div>

    <div class="right-panel">
      <SalesPOSItemEditTab v-if="posState.tab === 'edit'" class="flex-1" />
      <SalesPOSItemDiscountTab v-if="posState.tab === 'singleDiscount'" />
      <SalesPOSOperateFooter />
    </div>
  </div>
</template>

<script>
import { defineComponent, onBeforeMount, provide, reactive, ref } from 'vue'
import SalesPOSSidePanel from './components/SalesPOSSidePanel.vue'
import SalesPOSOperateFooter from './components/SalesPOSOperateFooter.vue'
import SalesPOSItemEditTab from './components/SalesPOSItemEditTab.vue'
import SalesPOSItemDiscountTab from './components/SalesPOSItemDiscountTab.vue'
import { useSalesPOSCreate } from '@/use/useSalesPOS'
import { onBeforeRouteLeave, useRoute, useRouter } from 'vue-router/composables'
import { get } from 'lodash'
import { useWindowUnload } from '@/use/useWindowUnload'
import store from '@/store'
import { FindMember } from '@/api/member'
import { apiFormatAdaptor } from '@/utils/api'
import { useShop } from '@/use/shop'

export default defineComponent({
  name: 'SalesPOS',
  components: {
    SalesPOSSidePanel,
    SalesPOSOperateFooter,
    SalesPOSItemEditTab,
    SalesPOSItemDiscountTab,
  },
  setup (props, { emit }) {
    const { shopId } = useShop()
    const { init, configData, getCreateConfig, context, cart, reset } = useSalesPOSCreate()
    const { registerWarnUnloadEvent } = useWindowUnload()
    registerWarnUnloadEvent()
    const route = useRoute()
    const router = useRouter()
    const routeBypass = ref(false)
    const posState = reactive({
      item: {},
      tab: 'edit',
    })

    const checkQueryMember = async () => {
      const queryMemberId = get(route.query, 'memberId')
      if (queryMemberId) {
        const [res, err] = await apiFormatAdaptor(FindMember)({
          shopId: shopId.value,
          id: queryMemberId,
        })
        console.log('res', res)
        if (err) return
        context.member = {
          id: res.id,
          name: get(res, 'UserInfo.name'),
          phone: get(res, 'UserInfo.phone'),
        }
      }
    }

    provide('posState', posState)
    provide('configData', configData)

    onBeforeMount(async () => {
      console.log('onBeforeMount')
      store.commit('app/SET_SIDEBAR', false)

      await checkQueryMember()

      if (!get(context, 'member.name')) {
        routeBypass.value = true
        router.push({ name: 'SalesPOSEntry' })
        return
      }

      await getCreateConfig()
      init()
      context.sellAt = get(route.query, 'sellAt')
    })

    onBeforeRouteLeave((to, from, next) => {
      if (routeBypass.value) return next()
      if (to.name !== 'SalesPOSCheckout') {
        const confirm = window.confirm('將會清除此工作階段內容，確定要離開嗎？')
        if (confirm) reset()
        else {
          store.commit('app/TOGGLE_PAGE_CHANGING', false)
          return next(route.fullPath)
        }
      }
      next()
    })

    // TODO 自動切換 tab 的時候要 scrollIntoView

    // FIXME coupon 與 cash percent 混用

    return { posState, configData, context, cart }
  },
})
</script>

<style lang="postcss" scoped>
.main-wrapper {
  @apply flex w-full h-full bg-white;
}

.right-panel {
  @apply overflow-y-auto w-full;
  @apply flex flex-col justify-between p-[16px] gap-[12px];
}
</style>
