<template>
  <el-drawer
    :title="$t('reservationOrderDetail.title')"
    :visible.sync="show"
    :append-to-body="true"
    destroy-on-close
    size="600px"
    class="reservation-order-detail"
    @close="onClose"
  >
    <div v-loading="loading.orderInfo" style="padding: 20px">
      <!-- 預約訂單 -->
      <div data-testid="order-info-section">
        <p class="section-block-title">{{ blockIndex.orderInfo }}. {{ $t('reservationOrderDetail.orderInfo.title')}}</p>
        <OrderInfoBlock
          :order="reservation"
          :loading="loading.orderInfo"
          @refresh="refreshOrder"
        />
        <div class="flex justify-end">
          <BaseElButton plain @click="showHistory = true"> {{ $t('reservationOrderDetail.button.orderRecord.title')}} </BaseElButton>
          <BaseElButton v-if="orderAgainIsShow" plain @click="orderAgain"> {{ $t('reservationOrderDetail.button.orderAgain.title')}} </BaseElButton>
          <BaseElButton
            v-if="getData(reservation, 'AppointmentOrder.status')"
            plain
            :disabled="orderStatus === 'cancel'"
            @click="onCancelOrder(reservation)"
          >
            {{ $t('reservationOrderDetail.button.cancelOrder.title')}}
          </BaseElButton>
        </div>
      </div>

      <!-- 自訂流程 -->
      <div v-if="customFlowConfig.length" data-testid="custom-flow-section">
        <p class="section-block-title">{{ blockIndex.customFlow }}. {{ $t('reservationOrderDetail.customFlow.title')}}</p>
        <CustomFlowArea
          :orderId="getData(reservation, 'AppointmentOrderId')"
          :customFlowConfig="customFlowConfig"
          :record="orderCustomFlowRecord"
          @refresh="refreshOrder"
        />
      </div>

      <!-- 詳細資訊 -->
      <hr style="margin: 12px 0">
      <div data-testid="detail-section">
        <p class="section-block-title">{{ blockIndex.orderDetail }}. {{ $t('reservationOrderDetail.orderDetail.title')}}</p>
        <OrderDetailBlock :order="reservation" :syncData="syncData" @refresh="refreshOrder" />
      </div>

      <div v-if="showPayment || showPubAptPayment" data-testid="order-payment-section">
        <hr style="margin: 12px 0">
        <p class="section-block-title">{{ blockIndex.paymentInfo }}. {{ $t('reservationOrderDetail.paymentInfo.title')}}</p>

        <div class="flex flex-col" style="gap: 20px">
          <!-- 付款資訊 -->

          <!-- 訂金-->
          <OrderPaymentBlock
            v-if="getData(reservation, 'AppointmentOrder.AppointmentOrderDeposit')"
            data-testid="deposit-block"
            :reservation="reservation"
            @refresh="refreshOrder"
          />

          <!-- 結帳 -->
          <div v-if="chargeType === 'checkout'" class="flex items-end justify-between" data-testid="checkout-block">
            <CheckoutBlock
              :reservation="reservation"
              @refresh="refreshOrder"
            />
            <div class="flex justify-end pt-[20px]">
              <BaseElButton plain @click="drawer.checkoutDetail = true">{{ $t('reservationOrderDetail.button.checkoutDetail.title')}}</BaseElButton>
            </div>
          </div>

          <!-- 合併付款 -->
          <div
            v-if="getData(reservation, 'AppointmentOrder.AppointmentBatchOrder')"
            class="flex items-end justify-between"
            data-testid="merge-checkout-block"
          >
            <BatchPaymentBlock
              :chargeType="chargeType"
              :reservation="reservation"
              @refresh="refreshOrder"
            />
            <div class="flex justify-end pt-[20px]">
              <BaseElButton plain @click="drawer.batchDetail = true">{{ $t('reservationOrderDetail.button.batchDetail.title')}}</BaseElButton>
            </div>
          </div>

          <!-- 堂票預約資訊 -->
          <ClassTicketDetailBlock
            v-if="useReservationClassTicket"
            data-testid="classTicket-block"
            :orderStatus="orderStatus"
            :orderId="getData(reservation, 'AppointmentOrderId')"
            :reservationId="getData(reservation, 'id')"
            :classTickets.sync="syncData.classTickets"
            :service="syncData.serviceDetail"
            @refresh="refreshOrder"
          />
          <!-- 訪客付款資訊 -->
          <OrderPubAptPaymentBlock
            v-if="showPubAptPayment"
            data-testid="pubApt-block"
            :reservation="reservation"
            @refresh="refreshOrder"
          />
        </div>
      </div>
      <div v-if="showCheckIn" data-testid="checkin-block">
        <hr style="margin: 12px 0">
        <p class="section-block-title">{{ blockIndex.checkInInfo }}. {{ $t("reservationOrderDetail.checkInfo.title")}}</p>
        <CheckInBlock :order="getData(reservation, 'AppointmentOrder')" />
      </div>

      <div v-if="showInvoice" v-loading="loading.invoice" data-testid="invoice-block">
        <hr style="margin: 12px 0">
        <p class="section-block-title">{{ blockIndex.invoiceInfo }}. {{ $t('reservationOrderDetail.invoiceInfo.title')}}</p>
        <InvoiceBlock
          :order="getData(reservation, 'AppointmentOrder')"
          @updateInvoiceStatus="updateInvoiceStatus"
        />
      </div>
    </div>

    <div class="dialog-footer flex justify-end" style="padding: 20px">
      <BaseElButton plain @click="$emit('close')">{{ $t('common.button.return.text')}}</BaseElButton>
    </div>

    <ReservationOrderHistory
      :show.sync="showHistory"
      :orderId="getData(reservation, 'AppointmentOrder.id')"
      @close="showHistory = false"
    />

    <OrderCheckoutReceiptDrawer
      v-if="drawer.checkoutDetail"
      :orderCheckoutData="orderCheckoutData"
      @close="drawer.checkoutDetail = false"
    />

    <BatchOrderPaymentDrawer
      v-if="drawer.batchDetail"
      :chargeType="chargeType"
      :batchOrder="batchOrder"
      @close="drawer.batchDetail = false"
    />
  </el-drawer>
</template>

<script>
import {
  computed,
  defineComponent,
  reactive,
  ref,
  getCurrentInstance,
  onMounted,
} from 'vue'
import { statusTranslate } from '@/utils/helper'
import { FindReservation, UpdateReservationCheckoutInvoiceStatus } from '@/api/reservation'
import { UpdatePubAptInvoiceStatus } from '@/api/pubApt'
import { FindService } from '@/api/service'
import OrderInfoBlock from './components/OrderInfoBlock.vue'
import OrderDetailBlock from './components/OrderDetailBlock.vue'
import OrderPubAptPaymentBlock from './components/OrderPubAptPaymentBlock.vue'
import OrderPaymentBlock from './components/OrderPaymentBlock.vue'
import ClassTicketDetailBlock from './components/ClassTicketDetailBlock.vue'
import CheckInBlock from './components/CheckInBlock.vue'
import InvoiceBlock from './components/InvoiceBlock.vue'
import { getData } from '@/utils/object'
import ReservationOrderHistory from '@/components/Reservation/ReservationOrderHistory/ReservationOrderHistory.vue'
import CustomFlowArea from '@/components/CustomFlowArea.vue'
import dayjs from '@/lib/dayjs'
import { usePermissions } from '@/use/permissions'
import { useFetch } from '@/use/fetch'
import { get } from 'lodash'
import { useCustomFlow } from '@/use/useCustomFlow'
import { useShop } from '@/use/shop'
import CheckoutBlock from './components/CheckoutBlock.vue'
import OrderCheckoutReceiptDrawer from './components/OrderCheckoutReceiptDrawer.vue'
import { useRouter } from 'vue-router/composables'
import BatchPaymentBlock from './components/BatchPaymentBlock.vue'
import BatchOrderPaymentDrawer from './components/BatchOrderPaymentDrawer.vue'
import { i18n } from '@/plugins/i18n/i18n'

const featureKeys = {
  useClassTicket: 'admin.appointmentReservationTicket.find',
  useCheckIn: 'admin.checkInPageSetting.page',
  useOrderAgain: 'admin.appointmentOrder.create',
}

export default defineComponent({
  name: 'ReservationOrderDetail',
  components: {
    CheckoutBlock,
    OrderInfoBlock,
    OrderDetailBlock,
    OrderPaymentBlock,
    OrderPubAptPaymentBlock,
    ReservationOrderHistory,
    ClassTicketDetailBlock,
    CheckInBlock,
    InvoiceBlock,
    CustomFlowArea,
    OrderCheckoutReceiptDrawer,
    BatchOrderPaymentDrawer,
    BatchPaymentBlock,
  },
  props: {
    // show: Boolean,
    width: String,
    selecReservation: Object,
    customFlowConfig: { type: Array, default: () => [] },
    orderId: String,
  },
  setup (props, { emit }) {
    const router = useRouter()
    const show = ref(false)
    const loading = reactive({
      orderInfo: false,
      invoice: false,
    })
    const drawer = reactive({
      checkoutDetail: false,
      batchDetail: false,
    })
    const refreshOrder = ref(null)
    const { checkAction } = usePermissions()
    const { simpleFetch } = useFetch()
    const { useCustomFlowFeature } = useCustomFlow()
    const { shopId } = useShop()
    const syncData = reactive({
      classTickets: [],
      serviceDetail: {},
    })
    const orderCheckoutData = computed(() => get(reservation.value, 'AppointmentOrder.AppointmentCheckoutOrder'))
    const batchOrder = computed(() => get(reservation.value, 'AppointmentOrder.AppointmentBatchOrder'))
    const chargeType = computed(() => get(reservation.value, 'AppointmentOrder.chargeType'))
    const useVisitorPreOrder = computed(() =>
      checkAction('adminView.appointmentReservation.includeAppointmentVisitorPreOrder'),
    )
    const usePubAptPaymentConfig = computed(() => checkAction('admin.pubAptPaymentConfig.page'))
    const reservation = ref({})
    const orderCustomFlowRecord = computed(
      () => get(reservation.value, 'AppointmentOrder.CustomFlowRecords') || [],
    )
    const useReservationClassTicket = computed(() => checkAction(featureKeys.useClassTicket))
    const orderStatus = computed(() => get(reservation.value, 'status'))
    const showPubAptPayment = computed(
      () =>
        (get(
          reservation.value,
          'AppointmentOrder.AppointmentVisitorPreOrder.DepositPubAptPaymentOrder',
        ) ||
          get(
            reservation.value,
            'AppointmentOrder.AppointmentVisitorPreOrder.PenaltyPubAptPaymentOrder',
          ) ||
          get(
            reservation.value,
            'AppointmentOrder.AppointmentVisitorPreOrder.CardSecretPubAptPaymentOrder',
          )) &&
        usePubAptPaymentConfig.value,
    )
    const showPayment = computed(
      () =>
        get(reservation.value, 'AppointmentOrder.AppointmentOrderDeposit') ||
        get(reservation.value, 'isUseClassTicket') ||
        chargeType.value === 'checkout',
    )
    const showCheckIn = computed(() => checkAction(featureKeys.useCheckIn))
    const showInvoice = computed(() => {
      const pubAptInvoice = get(
        reservation.value,
        'AppointmentOrder.AppointmentVisitorPreOrder.PubAptInvoiceOrder',
      )
      const checkoutInvoice = get(
        reservation.value,
        'AppointmentOrder.AppointmentCheckoutOrder.AppointmentCheckoutInvoice',
      )
      return pubAptInvoice || checkoutInvoice
    })
    const toOrderIndex = (indexConfig) => {
      const result = {}
      let index = 0
      for (const key in indexConfig) {
        if (indexConfig[key]) {
          index += 1
          result[key] = index
        }
      }
      return result
    }

    const blockIndex = computed(() => {
      const indexConfig = {
        orderInfo: true,
        customFlow: true,
        orderDetail: true,
        paymentInfo: true,
        checkInInfo: true,
        invoiceInfo: true,
      }
      if (!props.customFlowConfig.length) indexConfig.customFlow = false
      if (!showCheckIn.value) indexConfig.checkInInfo = false

      if (!chargeType.value !== 'checkout' && !showPayment.value && !showPubAptPayment.value) {
        indexConfig.paymentInfo = false
      }

      return toOrderIndex(indexConfig)
    })
    const updateInvoiceStatus = async (invoiceStatus) => {
      if (loading.invoice) return
      loading.invoice = true
      let invoiceOrderId
      let apiMethod
      if (chargeType.value === 'checkout') {
        apiMethod = UpdateReservationCheckoutInvoiceStatus
        invoiceOrderId = get(reservation.value, 'AppointmentOrder.AppointmentCheckoutOrder.id')
      } else {
        apiMethod = UpdatePubAptInvoiceStatus
        invoiceOrderId = get(reservation.value, 'AppointmentOrder.AppointmentVisitorPreOrder.id')
      }

      const [, err] = await apiMethod({
        shopId: shopId.value,
        id: invoiceOrderId,
        invoiceStatus,
      })

      if (err) {
        window.$message.error(err)
        loading.invoice = false
        return
      }
      window.$message.success(i18n.t('reservationOrderDetail.message.updateInvoiceStatusSuccess'))

      await refreshOrder.value()
      loading.invoice = false
    }

    const orderAgain = () => {
      router.push({ name: 'ReservationCreate', query: { id: get(reservation.value, 'id'), member: get(reservation.value, 'MemberId') } })
    }
    const orderAgainIsShow = computed(() => {
      return checkAction(featureKeys.useOrderAgain)
    })

    onMounted(() => {
      const vm = getCurrentInstance().proxy
      refreshOrder.value = vm.refreshOrder
      show.value = true
    })

    const onClose = () => {
      show.value = false
      setTimeout(() => {
        emit('close')
      }, 150)
    }

    return {
      useReservationClassTicket,
      syncData,
      simpleFetch,
      orderStatus,
      reservation,
      showPayment,
      showCheckIn,
      showInvoice,
      toOrderIndex,
      blockIndex,
      useCustomFlowFeature,
      useVisitorPreOrder,
      orderCustomFlowRecord,
      updateInvoiceStatus,
      showPubAptPayment,
      loading,
      chargeType,
      show,
      onClose,
      drawer,
      orderCheckoutData,
      orderAgain,
      orderAgainIsShow,
      batchOrder,
    }
  },
  data: () => ({
    showHistory: false,
  }),
  computed: {
    shopId () {
      return this.$store.getters.shop
    },
    page () {
      return this.$route.name
    },
    attachServices () {
      if (!this.reservation.AppointmentServiceAttaches) return [{ name: '無', id: 0 }]
      return this.reservation.AppointmentServiceAttaches
    },
    syncShow: {
      get () {
        return this.show
      },

      set (data) {
        this.$emit('opend:show', data)
      },
    },
  },
  watch: {
    async show () {
      if (this.show) {
        await this.findReservation()

        this.simpleFetch(
          FindService,
          { shopId: this.shopId, id: get(this.reservation, 'AppointmentServiceId') },
          (res) => {
            console.log('findService', res)
            this.syncData.serviceDetail = res
          },
        )
      }
    },
  },

  methods: {
    getData,
    statusContent: (status) => statusTranslate(status),

    dateFormat (date) {
      if (!date) return '無'
      return dayjs(date).format('YYYY/MM/DD HH:mm:ss')
    },

    async findReservation () {
      if (this.loading.orderInfo) return
      this.loading.orderInfo = true
      const res = await FindReservation({
        shopId: this.shopId,
        id: this.reservation?.id || this.orderId,
        includeCustomFlowRecord: this.useCustomFlowFeature.record,
        includeAppointmentVisitorPreOrder: this.useVisitorPreOrder,
      })
      this.reservation = res
      this.loading.orderInfo = false
    },

    async onCancelOrder (reservation) {
      console.log('reservation', reservation)
      this.$emit('cancel', reservation)
      // this.refreshOrder()
    },

    async refreshOrder () {
      await this.findReservation()
      this.simpleFetch(
        FindService,
        { shopId: this.shopId, id: get(this.reservation, 'AppointmentServiceId') },
        (res) => {
          console.log('findService', res)
          this.syncData.serviceDetail = res
        },
      )

      this.$emit('refresh')
    },
  },
})
</script>

<style lang="postcss" scoped>
::v-deep .el-form-item {
  @apply mb-0;
}

.section-block-title {
  @apply text-primary-100 font-medium text-[18px] pb-[12px];
}
</style>
