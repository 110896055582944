<template>
  <HorizontalTabs
    v-show="displayTabs.length"
    :tabs="displayTabs"
    :value.sync="context.category"
    type="button"
    @select="onSelectTab"
  />
</template>

<script>
import { computed, defineComponent, ref } from 'vue'
import HorizontalTabs from '@/components/HorizontalTabs.vue'
import { map, get } from 'lodash'
import { useSalesPOSCreate } from '@/use/useSalesPOS'

export default defineComponent({
  name: 'SalesPOSCategoryTab',
  components: {
    HorizontalTabs,
  },
  setup (props, { emit }) {
    const { context, filterProductGroupMenu, filterCategoryMenu } = useSalesPOSCreate()

    const displayTabs = computed(() => {
      const showList = filterCategoryMenu(context.categoryGroup)
      showList.push({ name: '其他', id: null })
      return map(showList, (item) => ({
        label: item.name,
        key: item.id,
      }))
    })

    const onSelectTab = () => {
      context.productGroup = get(filterProductGroupMenu(context.category), '[0].id', null)
    }

    return {
      context,
      displayTabs,
      onSelectTab,
    }
  },
})
</script>

<style lang="postcss" scoped>
::v-deep(.el-tabs__header) {
  @apply !border-none ;
}
::v-deep(.el-tabs__nav) {
  @apply !border-none;
}
::v-deep(.el-tabs__item) {
  @apply !border-none text-normal leading-[20.27px] h-auto py-[8px] text-gray-80;
  @apply bg-[#F6F7FB] rounded-[6px];
  @apply hover:text-primary-100;
}

::v-deep(.el-tabs__item.is-active) {
  @apply bg-primary-100 text-white;
  @apply  !border-none;
}
</style>
