import Layout from '@/layout'

export const resourceRoutes = [
  // 預約模組
  {
    path: '/resource',
    component: Layout,
    alwaysShow: true,
    isGroup: true,
    meta: {
      title: 'page.resources.title',
    },
    children: [
      {
        path: 'resource-setting',
        name: 'ResourceSetting',
        component: () =>
          import('@/views/Resource/ResourceSetting'),
        meta: {
          title: 'page.resourceSetting.title',
          action: 'admin.resourceItem.page',
          // bobPageAction: 'PAGE_VIEW_DASH_SHOP_RESERVATION_UNIT_LIST',
        },
      },
      {
        path: '/resource-edit/:resourceId?',
        name: 'ResourceEdit',
        hidden: true,
        component: () =>
          import('@/views/Resource/ResourceEdit/ResourceEdit.vue'),
        meta: {
          title: 'page.createResource.title',
          action: 'admin.resourceItem.page',
          // bobPageAction: 'PAGE_VIEW_DASH_SHOP_RESERVATION_SERVICE_LIST',
        },
      },
      {
        path: 'resource-time-space',
        name: 'ResourceTimeSpace',
        component: () => import('@/views/Resource/ResourceTimeSpace'),
        meta: {
          title: 'page.resourceTimeSpace.title',
          action: 'admin.resourceItemTimeOff.page',
          // bobPageAction: 'PAGE_VIEW_DASH_SHOP_RESERVATION_UNIT_SPACE_LIST',
        },
      },
    ],
  },
]
