<template>
  <div class="el-input-wrapper">
    <slot />
  </div>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'ElInputWrapper',
})
</script>

  <style lang="postcss" scoped>
  ::v-deep .el-input{
    @apply w-full;
  }
  ::v-deep .el-select{
    @apply w-full;
  }
  ::v-deep .el-input__inner {
    @apply w-full;
  }
  </style>
