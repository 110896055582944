import { admin2 } from './instance'

export const GetCouponConfig = async ({
  shopId,
}) => {
  return admin2({
    method: 'GET',
    url: `/${shopId}/couponConfig`,
  })
}

export const UpdateCouponConfig = async ({
  shopId,
  displayName,
  expireNotificationBefore,
}) => {
  return admin2({
    method: 'PUT',
    url: `/${shopId}/couponConfig`,
    data: {
      displayName,
      expireNotificationBefore,
    },
  })
}

export const GetCouponExchange = async ({
  shopId,
  start,
  limit,
}) => {
  return admin2({
    method: 'GET',
    url: `/${shopId}/couponExchange`,
    params: {
      start,
      limit,
    },
  })
}

export const FindCouponExchange = async ({
  shopId,
  id,
}) => {
  return admin2({
    method: 'GET',
    url: `/${shopId}/couponExchange/${id}`,
  })
}

export const GetCouponExchangeCount = async ({
  shopId,
}) => {
  return admin2({
    method: 'GET',
    url: `/${shopId}/couponExchange/count`,
  })
}

export const CreateCouponExchange = async ({
  shopId,
  image,
  name,
  expType,
  expDay,
  expAt,
  notice,
  showMinute,
  systemRedeem,
  endOfPeriodUnit,
}) => {
  return admin2({
    method: 'POST',
    url: `/${shopId}/couponExchange`,
    data: {
      name,
      image,
      expType,
      expDay,
      expAt,
      notice,
      showMinute,
      systemRedeem,
      endOfPeriodUnit,
    },
  })
}

export const UpdateCouponExchange = async ({
  shopId,
  id,
  name,
  image,
  expType,
  expDay,
  expAt,
  notice,
  showMinute,
  exchangeType,
  exchangeLink,
  systemRedeem,
}) => {
  return admin2({
    method: 'PUT',
    url: `/${shopId}/couponExchange/${id}`,
    data: {
      name,
      image,
      expType,
      expDay,
      expAt,
      notice,
      showMinute,
      exchangeType,
      exchangeLink,
      systemRedeem,
    },
  })
}

export const DeleteCouponExchange = async ({
  shopId,
  id,
}) => {
  return admin2({
    method: 'DELETE',
    url: `/${shopId}/couponExchange/${id}`,
  })
}

export const CreateCouponExchangeSerial = async ({
  shopId,
  CouponId,
  source,
  serials,
}) => {
  return admin2({
    method: 'POST',
    url: `/${shopId}/couponExchangeSerial`,
    data: {
      CouponId,
      source,
      serials,
    },
  })
}

export const CreateSystemCouponExchangeSerial = async ({
  shopId,
  CouponId,
  amount,
  generators,
}) => {
  return admin2({
    method: 'POST',
    url: `/${shopId}/couponExchangeSerial/systemCreate`,
    data: {
      CouponId,
      amount,
      generators,
    },
  })
}

export const GetCouponExchangeSerial = async ({
  shopId,
  CouponId,
  start,
  limit,
  code,
}) => {
  return admin2({
    method: 'GET',
    url: `/${shopId}/couponExchangeSerial`,
    params: {
      CouponId,
      start,
      limit,
      code,
    },
  })
}

export const GetCouponExchangeSerialCount = async ({
  shopId,
  CouponId,
  code,
}) => {
  return admin2({
    method: 'GET',
    url: `/${shopId}/couponExchangeSerial/count`,
    params: {
      CouponId,
      code,
    },
  })
}

export const UpdateCouponExchangeSerial = async ({
  shopId,
  id,
  code,
  totalStock,
}) => {
  return admin2({
    method: 'PUT',
    url: `/${shopId}/couponExchangeSerial/${id}`,
    data: {
      code,
      totalStock,
    },
  })
}

export const DeleteCouponExchangeSerial = async ({
  shopId,
  id,
}) => {
  return admin2({
    method: 'DELETE',
    url: `/${shopId}/couponExchangeSerial/${id}`,
  })
}
