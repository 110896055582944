<template>
  <div class="sales-pos-entry">
    <div class="flex justify-between items-end">
      <div class="flex flex-col" style="gap: 8px">
        <p class="font-medium">{{ displayData.formItemLabel.searchMember }}</p>
        <div class="flex items-center" style="gap: 12px">
          <ElInputWrapper>
            <BaseElInput
              v-model="searchValue"
              style="width: 392px;"
              :maxlength="10"
              :placeholder="displayData.placeholder.searchMember"
            />
          </ElInputWrapper>
          <div class="cursor-pointer" @click="modal.qrcodeScan = true">
            <SVGIcon icon="common-scanner" fill="var(--primary-100)" width="40" height="40" />
          </div>
        </div>
      </div>

      <div class="identity" @click="onSelect({ name: displayData.guest, phone: null, id: null })">
        {{ displayData.guest }}
      </div>
    </div>

    <div class="flex justify-between" style="gap: 20px">
      <div class="flex flex-col flex-1" style="gap: 8px">
        <p class="font-medium" style="margin-bottom: 8px">{{ displayData.formItemLabel.searchResult }}</p>
        <div v-loading="loading" class="flex flex-col overflow-y-auto" style="gap: 6px">
          <div
            v-for="member in displayMemberSearchResults"
            :key="member.value"
            class="search-result-item"
            :class="{ isMember: member.memberId }"
            @click="onSelect(member)"
          >
            <div class="text-primary-100 truncate">{{ member.name }}</div>
            <div class="text-gray-100">{{ member.phone }}</div>
          </div>

          <div v-if="searchValue && !displayMemberSearchResults.length && !loading" class="flex flex-col items-center justify-center" style="gap:14px">
            <img src="@/assets/icon/salesPOS/user_notfound.png" alt="">
            <p class="text-gray-100 text-sm">{{ displayData.hint.notFound }}</p>
          </div>
        </div>
      </div>
      <NumPadPanel
        :value.sync="searchValue"
        :max-length="10"
        @clear="searchValue = ''"
      />
    </div>

    <SalesPOSQrcodeScan
      v-if="modal.qrcodeScan"
      @close="modal.qrcodeScan = false"
      @decode="searchValue = $event"
    />
  </div>
</template>

<script>
import { computed, defineComponent, reactive, ref, watchEffect } from 'vue'
import NumPadPanel from './components/NumPadPanel.vue'
import SalesPOSQrcodeScan from './components/SalesPOSQrcodeScan.vue'
import { useRoute, useRouter } from 'vue-router/composables'
import { GetSalesPosSearchMember } from '@/api/salesPOS'
import { useShop } from '@/use/shop'
import { filter, map, get } from 'lodash'
import ElInputWrapper from '@/components/ElInputWrapper.vue'
import { useSalesPOSCreate } from '@/use/useSalesPOS'
import debounce from 'lodash.debounce'
import SVGIcon from '@/components/SVGIcon.vue'
import { isUUID } from '@/utils/helper'

export default defineComponent({
  name: 'SalesPOSEntry',
  components: {
    NumPadPanel,
    SalesPOSQrcodeScan,
    ElInputWrapper,
    SVGIcon,
  },
  setup (props) {
    const { context } = useSalesPOSCreate()
    const router = useRouter()
    const route = useRoute()
    const { shopId } = useShop()
    const searchValue = ref('')
    const memberList = ref([])

    const displayData = computed(() => {
      const data = {
        formItemLabel: {
          searchMember: '顧客資料查詢',
          searchResult: '查詢結果',
        },
        hint: {
          notFound: '查無此人',
        },
        placeholder: {
          searchMember: '請輸入 姓名、電話或 BOB MemberID 查詢',
        },
        guest: '過路客',
        notMember: '非會員',
      }
      return data
    })

    const sellAt = computed(() => {
      return get(route.query, 'sellAt')
    })

    const displayMemberSearchResults = computed(() => {
      if (!searchValue.value) return []
      // 根據 memberList 轉換成 {name: <會員名稱>, phone: <電話>, value: <會員ID>} 的格式
      // 並過濾掉沒有 phone 的會員
      const filterPhoneMember = filter(memberList.value, (member) => member.phone)
      const members = filterPhoneMember

      if (members.length) {
        return map(members, (member) => {
          return {
            name: member.name,
            phone: member.phone,
            id: member.memberId,
          }
        })
      }

      // 如果 searchValue 有值，且用 regex 判斷為手機號碼，則返回該手機號碼
      if (searchValue.value && /^09\d{8}$/.test(searchValue.value)) {
        return [{ name: displayData.value.notMember, phone: searchValue.value, id: null }]
      }

      return []
    })

    const loading = ref(false)
    const modal = reactive({
      qrcodeScan: false,
      scanNotfound: false,
    })

    const onSelect = (member) => {
      context.member = member
      router.push({
        name: 'SalesPOS',
        query: {
          sellAt: sellAt.value,
        },
      })
    }

    const displayMemberList = computed(() => {
      return memberList.value
    })

    const searchMembers = debounce(async () => {
      if (!searchValue.value) {
        memberList.value = []
        return
      }
      if (loading.value) return
      loading.value = true

      const payload = {
        shopId: shopId.value,
        search: searchValue.value,
        memberId: searchValue.value,
      }

      if (isUUID(searchValue.value)) {
        delete payload.search
      } else {
        delete payload.memberId
      }

      const [res, err] = await GetSalesPosSearchMember(payload)
      loading.value = false
      if (err) {
        window.$message.error(err)
        return
      }
      memberList.value = res
    }, 300, { leading: false, trailing: true })

    watchEffect(async () => {
      if (!searchValue.value) {
        memberList.value = []
      }
      if (searchValue.value.length >= 2) {
        searchMembers()
      }
    })

    return { searchValue, modal, onSelect, displayMemberList, loading, displayMemberSearchResults, displayData }
  },
})
</script>

<style lang="postcss" scoped>
.sales-pos-entry {
  @apply max-w-[754px] m-auto my-[32px];
  @apply flex flex-col gap-[24px] text-gray-100;
}
.search-result-item {
  @apply w-[392px];
  @apply flex items-center justify-between gap-[8px];
  @apply border-[2px] border-primary-30 rounded-[5px] py-[6px] px-[12px];
  @apply cursor-pointer;
}

.identity {
  @apply bg-primary-100 text-white px-[26px] py-[8.5px] font-medium rounded-[4px] select-none cursor-pointer;
}

.isMember {
  @apply bg-primary-30;
}
</style>
