<template>
  <BaseDialog
    data-tetsid="reservation-calendar-display-setting-modal"
    :show="true"
    :title="$t('reservationCalendarDisplaySettingModal.title')"
    @confirm="onSubmit(handleSubmit)"
    @close="$emit('close')"
  >
    <BaseElForm ref="formRef" :model="formData" :rules="formRules">
      <BaseElFormItem :label="$t('reservationCalendarDisplaySettingModal.displayContent.title')" prop="displayContent">
        <BaseElSelect v-model="formData.displayContent" placeholder="" testName="formData_displayContent">
          <BaseElSelectOption
            v-for="option in displayContentOptions"
            :key="option.value"
            :label="$t('reservationCalendarDisplaySettingModal.display.title', { item: option.label})"
            :value="option.value"
          />
        </BaseElSelect>
      </BaseElFormItem>

      <BaseElFormItem :label="$t('reservationCalendarDisplaySettingModal.displayColor.title')">
        <BaseElSelect v-model="formData.displayColor" placeholder="" testName="formData_displayColor">
          <BaseElSelectOption
            v-for="option in displayColorOptions"
            :key="option.value"
            :label="$t('reservationCalendarDisplaySettingModal.display.title', { item: option.label})"
            :value="option.value"
          />
        </BaseElSelect>
      </BaseElFormItem>
    </BaseElForm>
  </BaseDialog>
</template>

<script>
import { defineComponent, onMounted, computed } from 'vue'
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import { useBaseForm } from '@/use/useForm'
import { useDisplaySettings } from '@/use/displaySettings'
import { noEmptyRules } from '@/validation'
import { usePermissions } from '@/use/permissions'
import { filter } from 'lodash'
import { i18n } from '@/plugins/i18n/i18n'

export default defineComponent({
  name: 'ReservationCalendarDisplaySettingModal',
  components: { BaseDialog },
  emits: ['close', 'refresh'],
  setup (props, { emit }) {
    const { checkAction } = usePermissions()

    const { initFormData, formData, formRef, onSubmit } = useBaseForm()
    const { getDisplaySettings, displaySettings, updateDisplaySettings } = useDisplaySettings('reservationCalendar', {
      displayContent: 'serviceUnit-service',
      displayColor: 'order-status',
    })
    const formRules = {
      displayContent: [noEmptyRules()],
      displayColor: [noEmptyRules()],
    }

    const displayContentOptions = computed(() => {
      const list = [
        { label: i18n.t('reservationCalendarDisplaySettingModal.displayContentHintConfig.serviceUnitService.title'), value: 'serviceUnit-service' },
        { label: i18n.t('reservationCalendarDisplaySettingModal.displayContentHintConfig.resrouceService.title'), value: 'resource-service' },
        { label: i18n.t('reservationCalendarDisplaySettingModal.displayContentHintConfig.memberService.title'), value: 'member-service' },
      ]
      const omitList = []
      if (!checkAction('admin.resourceItem.page'))omitList.push('resource-service')
      const result = filter(list, item => !omitList.includes(item.value))
      return result
    })
    const displayColorOptions = [
      { label: i18n.t('reservationCalendarDisplaySettingModal.displayColorHintConfig.orderStatus.title'), value: 'order-status' },
      { label: i18n.t('reservationCalendarDisplaySettingModal.displayColorHintConfig.serviceUnit.title'), value: 'service-unit' },
    ]

    initFormData({
      displayContent: 'serviceUnit-service',
      displayColor: 'order-status',
    })

    const handleSubmit = () => {
      updateDisplaySettings({ ...formData })
      emit('refresh')
      emit('close')
    }

    const syncData = () => {
      const config = displaySettings.value
      formData.displayContent = config.displayContent
      formData.displayColor = config.displayColor
    }

    onMounted(() => {
      getDisplaySettings()
      syncData()
    })

    return {
      onSubmit,
      formRef,
      formData,
      formRules,
      handleSubmit,
      displayContentOptions,
      displayColorOptions,
    }
  },
})
</script>

<style lang="postcss" scoped></style>
