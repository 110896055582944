<template>
  <BaseDialog title="堂票折抵" @close="$emit('close')" @confirm="onConfirm">
    <div style="margin-bottom: 40px">
      <BaseElForm ref="formRef" :model="formData">
        <BaseElFormItem label="使用堂票">
          <BaseElSelect
            v-model="formData.classTickets"
            :multiple="multiple"
            collapse-tags
            value-key="id"
          >
            <BaseElSelectOption
              v-for="item in displayOptions"
              :key="item.id"
              :label="item.label"
              :value="item"
              :disabled="item.disabled"
            />
          </BaseElSelect>
        </BaseElFormItem>
      </BaseElForm>

      <div class="flex justify-between items-center font-medium text-danger">
        <span>折扣金額</span>
        <span>-$ {{ displayData.discountPrice }}</span>
      </div>
    </div>
  </BaseDialog>
</template>

<script>
import { computed, defineComponent, ref } from 'vue'
import BaseDialog from './Dialog/BaseDialog.vue'
import { useBaseForm } from '@/use/useForm'
import { useSalesPOSCreate } from '@/use/useSalesPOS'
import { map, get, isEmpty, filter } from 'lodash'
import { formatDate } from '@/utils/date'

export default defineComponent({
  name: 'SalesPOSClassTicketDiscountSelectModal',
  components: {
    BaseDialog,
  },
  props: {
    scope: {
      type: String,
      required: true,
    },
    multiple: {
      type: Boolean,
      default: true,
    },
    availableIds: {
      type: Array,
      default: () => [],
    },
  },
  setup (props, { emit }) {
    const { configData, computedUsedResource, getActiveCartItem } = useSalesPOSCreate()
    const { formData, formRef, initFormData, loading, checkForm } = useBaseForm()
    initFormData({
      classTickets: [],
    })
    const displayOptions = computed(() => {
      const memberClassTicketRecords = get(configData, 'consumerData.classTicketRecords')

      const options = filter(
        map(memberClassTicketRecords, (item) => {
          const preUsedTimes = get(computedUsedResource.value, `classTicket.${item.id}`, 0)
          const remainingTimes = item.availableUseTimes - preUsedTimes
          let label = `${item.name} 剩餘 ${remainingTimes} 張`

          if (item.exp) {
            label += `（期限 ${formatDate(item.exp, 'YYYY/MM/DD')}）`
          }

          return {
            ...item,
            label,
            disabled: remainingTimes <= 0,
            remainingTimes,
          }
        }),
        (item) => item.status === 'open' && item.remainingTimes > 0,
      )

      if (props.scope === 'singleDiscount') {
        return filter(options, (item) => {
          return props.availableIds.includes(item.ClassTicketId)
        })
      }

      return options
    })

    const displayData = computed(() => {
      const data = {
        discountPrice: 0,
      }

      if (formData.classTickets || !isEmpty(formData.classTickets)) {
        if (props.scope === 'singleDiscount') {
          const activeCartItem = getActiveCartItem()
          data.discountPrice = activeCartItem.getDiscountRemainingPrice()
        }
      }
      return data
    })

    const onSelect = (data) => {
      formData.classTickets = data
    }

    const onConfirm = async () => {
      const pass = await checkForm(formRef.value)
      if (!pass) return
      emit('confirm', formData.classTickets)
      emit('close')
    }

    return {
      configData,
      formData,
      formRef,
      loading,
      onSelect,
      onConfirm,
      displayOptions,
      displayData,
    }
  },
})
</script>

<style lang="postcss" scoped>

</style>
