<template>
  <BaseDialog
    key="event-edit"
    :title="$t('shiftCalendar.dialog.editShift.title')"
    :btn1-loading="$t('common.button.save.text')"
    :btn1Loading="loading"
    @confirm="updateScheduleCalss"
    @close="$emit('close')"
  >
    <BaseElForm label-position="top">
      <BaseElFormItem :label="`${$t('shiftCalendar.dialog.date.title')} ：`">
        <div v-if="selectSchedule">{{ selectSchedule.day }}</div>
      </BaseElFormItem>
      <BaseElFormItem :label="`${$t('shiftCalendar.dialog.serviceUnit.title')} ：`">
        <div v-if="selectSchedule">
          {{ selectSchedule.AppointmentUnit.name }}
        </div>
      </BaseElFormItem>
      <BaseElFormItem :label="`${$t('shiftCalendar.dialog.shiftClass.title')} ：`">
        <BaseElSelect v-model="selectScheduleClass">
          <BaseElSelectOption
            v-for="item in shopShiftsClassList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </BaseElSelect>
      </BaseElFormItem>
    </BaseElForm>
  </BaseDialog>
</template>

<script>
import { computed, defineComponent, ref } from 'vue'
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import { UpdateShiftsSchedule } from '@/api/shiftSchedule'
import { useShop } from '@/use/shop'
import { get } from 'lodash'
import { i18n } from '@/plugins/i18n/i18n'

export default defineComponent({
  name: 'EditSingleShiftEventModal',
  components: { BaseDialog },
  props: {
    selectSchedule: { type: Object, default: () => ({}) },
    shopScheduleList: { type: Array, default: () => [] }, // 店家現有排班列表
    shopShiftsClassList: { type: Array, default: () => [] }, // 店家班別列表
  },
  setup (props, { emit }) {
    const { shopId } = useShop()
    const loading = ref(false)

    const curSelectScheduleClass = computed(() => get(props.selectSchedule, 'AppointmentScheduleDayId'))
    const selectScheduleClass = ref(curSelectScheduleClass.value)

    const updateShiftsSchedule = async (newSchedule) => {
      await UpdateShiftsSchedule({
        shopId: shopId.value,
        data: newSchedule,
      })
      window.$message.success(i18n.t('shiftCalendar.message.updateSuccess'))
    }

    const updateScheduleCalss = async () => {
      loading.value = true
      const id = props.selectSchedule.id
      const newScheduleClass = selectScheduleClass.value
      const newSchedule = []

      props.shopScheduleList.forEach((item) => {
        if (item.id === id) {
          item.AppointmentScheduleDayId = newScheduleClass
        }

        newSchedule.push({
          day: item.day,
          appointmentUnitId: item.AppointmentUnitId,
          appointmentScheduleDayId: item.AppointmentScheduleDayId,
        })
      })

      try {
        await updateShiftsSchedule(newSchedule)
        loading.value = false
        emit('refresh')
        emit('close')
      } catch (error) {
        window.$message.error(error)
      }
    }
    return { loading, updateScheduleCalss, selectScheduleClass }
  },
})
</script>

<style lang="postcss" scoped>

</style>
