import { render, staticRenderFns } from "./UnitServicesSelect.vue?vue&type=template&id=b63c92d0&scoped=true"
import script from "./UnitServicesSelect.vue?vue&type=script&lang=js"
export * from "./UnitServicesSelect.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b63c92d0",
  null
  
)

export default component.exports