import { admin2 } from '@/api/instance'

export const CreatePospalConfig = async ({
  shopId,
  config,
  enabled,
}) => {
  return await admin2({
    method: 'POST',
    url: `/${shopId}/shopCustomModule/posPal`,
    data: {
      enabled,
      config,
    },
  })
}

export const UpdatePospalConfig = async ({
  shopId,
  config,
  enabled,
}) => {
  return admin2({
    method: 'PUT',
    url: `/${shopId}/shopCustomModule/pospal`,
    data: {
      enabled,
      config,
    },
  })
}

export const FindPospalWallet = async ({
  shopId,
  memberId,
}) => {
  return admin2({
    method: 'POST',
    url: `/${shopId}/shopCustomModule/posPalMemberWallet`,
    data: {
      memberId,
    },
  })
}

export const GetPospalClientConfig = async ({
  shopId,
}) => {
  return admin2({
    method: 'POST',
    url: `/${shopId}/shopCustomModule/posPalClientConfig`,
    data: {},
  })
}

export const SyncPospalMember = async ({
  shopId,
  memberId,
}) => {
  return admin2({
    method: 'POST',
    url: `/${shopId}/shopCustomModule/syncPosPalClientMember`,
    data: {
      memberId,
    },
  })
}

export const CheckPospalMemberSync = ({
  shopId,
  memberId,
}) => {
  return admin2({
    method: 'POST',
    url: `/${shopId}/shopCustomModule/retrievePosPalClientMember`,
    data: {
      memberId,
    },
  })
}

export const SyncPospalMemberLevel = async ({
  shopId,
}) => {
  return admin2({
    method: 'POST',
    url: `/${shopId}/shopCustomModule/syncPosPalClientMemberLevel`,
  })
}

export const SyncPospalCoupon = async ({
  shopId,
}) => {
  return admin2({
    method: 'POST',
    url: `/${shopId}/shopCustomModule/syncPosPalCoupon`,
    data: {},
  })
}

export const GetPospalCoupon = async ({
  shopId,
  start,
  limit,
  name,
}) => {
  return admin2({
    method: 'GET',
    url: `/${shopId}/couponPospal`,
    params: {
      start,
      limit,
      name,
    },
  })
}

export const GetPospalCouponCount = async ({
  shopId,
  name,
}) => {
  return admin2({
    method: 'GET',
    url: `/${shopId}/couponPospal/count`,
    params: {
      name,
    },
  })
}

export const FindPospalCoupon = async ({
  shopId,
  id,
}) => {
  return admin2({
    method: 'GET',
    url: `/${shopId}/couponPospal/${id}`,
  })
}

export const ResendPospalCouponCode = async ({
  shopId,
  id,
}) => {
  return admin2({
    method: 'POST',
    url: `/${shopId}/couponPospal/${id}/resendCouponCode`,
    data: {},
  })
}

// 手動同步pospal交易及相關資訊
export const ManualSyncPospalTransaction = async ({ shopId }) => {
  return admin2({
    method: 'POST',
    url: `/${shopId}/shopCustomModule/posPal/manualSyncPosPalTrxAndRelatedUpdates`,
  })
}
