<template>
  <div class="reward-activity-info-block">
    <p class="card-title">訂閱品項</p>
    <BaseElForm ref="formRef" label-position="top" :model="formData" :rules="formRules">
      <BaseElFormItem prop="name">
        <template #label>
          <FormItemTooltipLabel label="選擇會員權益範本" :tooltip-width="200">
            如需新增選項，請至「會員權益模組 > 會員權益範本管理」新增屬性為「訂閱商品」的會員權益範本
          </FormItemTooltipLabel>
        </template>
        <BaseElSelect v-model="formData.equityPackId" placeholder="請選擇" clearable :disabled="!!formData.orderCount">
          <BaseElSelectOption v-for="item in subscribeEquityPackList" :key="item.id" :label="item.name" :value="item.id" />
        </BaseElSelect>
      </BaseElFormItem>
      <div v-if="formData.equityPackId" class="preview w-[560px] mb-[20px]">
        <GrayBlockContainer>
          <div class="flex flex-col gap-[8px]">
            <div v-for="(item, idx) in displayPeriodicBenefitData" :key="idx" class="flex text-gray-100 text-sub whitespace-pre-line gap-[20px]">
              <span class="font-medium flex-shrink-0" style="width: 75px">{{ item.label }}</span>
              <span class="font-normal">{{ item.value }}</span>
            </div>
            <div class="flex items-center text-gray-100 text-sub whitespace-pre-line gap-[20px]">
              <span class="font-medium flex-shrink-0" style="width: 75px">商品描述</span>
              <BaseElButton class="underline" type="text" size="small" style="padding: 0; font-size: 14px;" @click="modal.equityPackDescription = true">查看</BaseElButton>
            </div>
            <!-- <p class="text-danger text-sm">系統不會自動寄出品項。如會員訂閱此方案，可至「訂閱模組 > 訂閱紀錄」查看每筆訂單</p> -->
          </div>
        </GrayBlockContainer>
      </div>
    </BaseElForm>

    <BaseDialog
      v-if="modal.equityPackDescription"
      title="會員權益商品描述"
      width="560px"
      hideCancel
      @confirm="modal.equityPackDescription = false"
      @close="modal.equityPackDescription = false"
    >
      <div>
        <GrayBlockContainer style="margin-bottom: 40px;">
          <div v-html="selectEquityPackDescription" />
        </GrayBlockContainer>
      </div>
    </BaseDialog>
  </div>
</template>

<script>
import { defineComponent, reactive, ref, nextTick, onMounted, watch, computed } from 'vue'
import { get, find, map, reduce, join, filter } from 'lodash'
import GrayBlockContainer from '@/components/Container/GrayBlockContainer.vue'
// import { useSubscribe } from '@/use/useSubscribe'
import { GetPeriodicBenefitTemplate } from '@/api/periodicBenefit'
import FormItemTooltipLabel from '@/components/Form/FormItemTooltipLabel.vue'
import { useFetch } from '@/use/fetch'
import { useShop } from '@/use/shop'
import { usePeriodBenefitTemplate } from '@/use/usePeriodBenefit'
import BaseDialog from '@/components/Dialog/BaseDialog.vue'

export default defineComponent({
  name: 'SubscribeCombineBlock',
  components: { FormItemTooltipLabel, GrayBlockContainer, BaseDialog },
  props: ['FormsContext', 'planData'],
  setup (props) {
    // const { getSubscribeEquityPack, subscribeEquityPackList } = useSubscribe()
    const { fetchAll } = useFetch()
    const { shopId } = useShop()
    const { formatPeriodPreviewText } = usePeriodBenefitTemplate()
    const subscribeEquityPackList = ref([])

    const formRef = ref(null)
    const formData = reactive({
      equityPackId: null,
      orderCount: 0,
    })
    const formRules = {}
    const modal = reactive({
      equityPackDescription: false,
    })

    const selectEquityPackDescription = computed(() => {
      const equityPack = find(subscribeEquityPackList.value, { id: formData.equityPackId })
      return get(equityPack, 'description')
    })

    const displayPeriodicBenefitData = computed(() => {
      const equityPack = find(subscribeEquityPackList.value, { id: formData.equityPackId })
      const periodConfig = get(equityPack, 'period')
      const startType = periodConfig.startType
      const periodType = periodConfig.type
      const instantAward = get(equityPack, 'instantAward')
      let fixedWeekDay = '-'
      const nDayValue = periodConfig.nDayValue || '-'
      const fixedValue = periodConfig.fixedValue || '-'
      const totalAwardCount = get(equityPack, 'totalAwardCount', '-')
      if (periodType === 'weekly' && startType === 'fixed') {
        fixedWeekDay = fixedValue
      }

      const entitlements = join(map(get(equityPack, 'entitlements', '-'), (item) => {
        const itemData = get(item, 'StandardAward', {})
        if (itemData.type === 'classTicket') {
          return `${itemData.name}（${itemData.amount} 份）`
        } else if (itemData.type === 'coupon') {
          return `${itemData.name}（${itemData.amount} 張）`
        } else if (itemData.type === 'pointCard') {
          return `${itemData.name}（${itemData.amount} 張）`
        } else if (itemData.type === 'punchCard') {
          return `${itemData.name}（${itemData.amount} 份）`
        } else if (itemData.type === 'wallet') {
          return `儲值金（${itemData.amount} 元）`
        } else if (itemData.type === 'cashback') {
          return `回饋金（${itemData.amount} 元）`
        } else if (itemData.type === 'point') {
          return `點數（${itemData.amount} 點）`
        }
      }), '\n')

      return [
        { label: '權益項目', value: entitlements },
        {
          label: '發放週期',
          value: formatPeriodPreviewText({
            startType,
            periodType,
            fixedWeekDay,
            nDayValue,
            fixedValue,
            instantAward,
            totalAwardCount,
          }, { highLight: false }),
        },
        { label: '發放總次數', value: totalAwardCount },
      ]
    })

    const equityPackData = computed(() => {
      const equityPack = find(subscribeEquityPackList.value, { id: formData.equityPackId })
      const itemsName = map(get(equityPack, 'equities'), 'name').join('、')
      const sum = reduce(get(equityPack, 'equities'), (total, equity) => total + get(equity, 'price', 0), 0)
      return [
        { label: '品項內容', value: itemsName },
        { label: '預估售價', value: `${sum} 元` },
      ]
    })

    const getSubscribeEquityPack = async () => {
      await fetchAll(GetPeriodicBenefitTemplate, {
        shopId: shopId.value,
      }, (res) => {
        subscribeEquityPackList.value = filter(res, { type: 'subscription' })
      })
    }

    const syncData = () => {
      const data = props.planData
      formData.equityPackId = get(data, 'planEquityPack.equityPack.customOptions.customEquityPackId')
      formData.orderCount = get(data, 'orderCount')
    }

    const compactData = computed(() => {
      const equityPack = find(subscribeEquityPackList.value, { id: formData.equityPackId })
      const data = {
        equityOptions: {
          mode: 'custom',
          customEquityPackOptions: {
            // customEquityPackName: get(equityPack, 'name'),
            customEquityPackId: get(formData, 'equityPackId') || null,
            customEquityPackType: 'periodicBenefitTemplate',
          },
        },
      }
      return { ...data }
    })
    onMounted(async () => {
      await nextTick()
      await getSubscribeEquityPack()
      props.FormsContext.setFormRef('equityPack', formRef.value)

      if (get(props.planData, 'id')) syncData()
    })

    watch(formData, () => {
      props.FormsContext.setFormData('equityPack', { ...compactData.value })
    })

    return {
      modal,
      formRef,
      formData,
      formRules,
      equityPackData,
      subscribeEquityPackList,
      GetPeriodicBenefitTemplate,
      displayPeriodicBenefitData,
      selectEquityPackDescription,
    }
  },
})
</script>

<style lang="postcss" scoped>
::v-deep .el-form-item__content, .previewArea {
  @apply w-[560px]
}
</style>
