<template>
  <div class="message-block">
    <ImageCropper
      v-if="uploadDialog"
      :image="syncModel.Image"
      @close="uploadDialog = false"
      @uploaded="getImage"
    />
    <i
      v-if="closeBtn"
      class="el-icon-circle-close close"
      @click="$emit('close')"
    />
    <section class="header flex-between">
      <span class="title">訊息</span>
      <div class="icon-btn-container">
        <div
          v-if="!onlyText"
          :class="{ active: syncModel.type === 'text' }"
          class="icon"
          style="width: fit-content"
          @click="changeType('text')"
        >
          <MaterialIcon size="20">chat_bubble_outline</MaterialIcon>
        </div>

        <div
          v-if="!onlyText"
          :class="{ active: syncModel.type === 'image' }"
          class="icon"
          @click="changeType('image')"
        >
          <MaterialIcon size="20">crop_original</MaterialIcon>
        </div>
      </div>
    </section>

    <BaseElForm ref="form" :model="syncModel">
      <BaseElFormItem
        prop="content"
        :rules="[{ required: true, message: '請輸入內容', trigger: 'blur' }]"
      >
        <BaseElInput
          v-if="syncModel.type === 'text'"
          v-model="syncModel.content"
          v-loading="hintLoading"
          type="textarea"
          :autosize="{ minRows: 8, maxRows: 8 }"
          placeholder="請輸入內容"
          :maxlength="maxContent"
          show-word-limit
        />
        <AiHintBlock
          v-if="aiHint && syncModel.type === 'text'"
          class="mt-[20px]"
          :hintLoading="hintLoading"
          showKeepOriginalContent
          @confirm="handleConfirm"
        />
      </BaseElFormItem>
    </BaseElForm>

    <UploadButton
      v-if="syncModel.type === 'image'"
      class="upload"
      :img="syncModel.Image"
      @change="imageChange"
    />

    <section v-if="syncModel.type === 'image'">
      <div>
        <p class="img-hint-text">圖片最大寬度為 2000 px</p>
        <p>點擊圖片行為</p>

        <BaseElRadioGroup
          v-model="syncModel.action"
          style="margin: 8px 0;"
          @change=";(syncModel.actionContent = undefined), resetForm()"
        >
          <BaseElRadio :label="undefined">無反應</BaseElRadio>
          <BaseElRadio label="uri">點擊跳轉網址</BaseElRadio>
          <BaseElRadio label="message">點擊發送文字</BaseElRadio>
        </BaseElRadioGroup>

        <BaseElForm ref="form" :model="syncModel">
          <BaseElFormItem
            v-if="syncModel.action === 'uri'"
            class="mb-0"
            prop="actionContent"
            :rules="[
              { required: true, message: '請輸入內容', trigger: 'blur' },
              urlCheck(),
            ]"
          >
            <BaseElInput
              v-if="syncModel.action !== undefined"
              v-model="syncModel.actionContent"
              class="w-100"
              :type="syncModel.action === 'url' ? 'url' : 'text'"
              placeholder="文字"
              clearable
              @clear="syncModel.actionContent = undefined"
            />
          </BaseElFormItem>

          <BaseElFormItem
            v-if="syncModel.action === 'message'"
            class="mb-0"
            :rules="[
              { required: true, message: '請輸入內容', trigger: 'blur' },
            ]"
            prop="actionContent"
          >
            <ElInputWrapper v-if="syncModel.action !== undefined">
              <BaseElInput
                v-model="syncModel.actionContent"
                style="width: 100%;"
                :type="syncModel.action === 'url' ? 'url' : 'text'"
                placeholder="文字"
                clearable
                @clear="syncModel.actionContent = undefined"
              />
            </ElInputWrapper>
          </BaseElFormItem>
        </BaseElForm>
      </div>
    </section>
  </div>
</template>

<script>
import ImageCropper from '@/components/ImageCropper.vue'
import UploadButton from '@/components/Button/UploadButton.vue'
import { urlRules } from '@/validation'
import formUtils from '@/utils/form'
import AiHintBlock from '@/components/AiHintBlock.vue'
import { useAi } from '@/use/useAi'
import { get } from 'lodash'
import { computed, defineComponent, set } from 'vue'
import { useVModel } from '@vueuse/core'
import { hint } from '@/config/hint'
export default defineComponent({
  name: 'MessageBlock',
  components: {
    UploadButton,
    ImageCropper,
    AiHintBlock,
  },
  // props: ['model', 'closeBtn'],
  props: {
    model: {
      type: Object,
      default: () => ({
        type: 'text',
        content: '',
        action: undefined,
        actionContent: undefined,
        Image: undefined,
      }),
    },
    closeBtn: {
      type: Boolean,
      default: false,
    },
    onlyText: {
      type: Boolean,
      default: false,
    },
    maxContent: {
      type: [String, Number],
      default: '200',
    },
    aiHint: {
      type: Boolean,
      default: false,
    },
  },
  setup (props, { emit }) {
    const { configurationHint, hintLoading } = useAi()
    const syncModel = useVModel(props, 'model', emit)
    const handleConfirm = async (payload) => {
      payload.origin = payload.origin ? { blockText: get(syncModel.value, 'content') } : undefined
      const key = payload.origin ? 'groupPushMessageCorrection.key' : 'groupPushMessage.key'
      const res = await configurationHint(get(hint, key), payload)
      if (!res) {
        hintLoading.value = false
        return
      }
      set(syncModel.value, 'content', get(res.hint, 'blockText'))
      // console.log(res.hint.blockText)
      // set(notifyMessageStack.value[0], 'content', get(res.hint, 'blockText'))
      // notifyMessageStack.value[0].content = res.hint.blockText
      // setData(formData.value, res)
    }
    return {
      syncModel,
      hintLoading,
      handleConfirm,
    }
  },
  data: () => ({
    uploadDialog: false,
    // image: null,
  }),

  // computed: {
  //   syncModel: {
  //     get () {
  //       return this.model
  //     },

  //     set (data) {
  //       this.$emit('update:model', data)
  //     },
  //   },
  // },

  watch: {
    async 'syncModel.actionContent' () {
      if (!(await formUtils.checkForm(this.$refs.form))) {
        this.$emit('error')
      } else {
        this.$emit('checked')
      }
    },

    async 'syncModel.content' () {
      if (!(await formUtils.checkForm(this.$refs.form))) {
        this.$emit('error')
      } else {
        this.$emit('checked')
      }
    },
  },

  methods: {
    changeType (type) {
      this.syncModel.type = type
      this.syncModel.content = undefined
      this.syncModel.action = undefined
      this.syncModel.actionContent = undefined
      this.resetForm()
      this.$emit('checked')
      this.syncModel.Image = undefined
    },
    resetForm () {
      formUtils.resetForm(this.$refs.form)
    },
    urlCheck () {
      return urlRules()
    },

    imageChange (img) {
      this.syncModel.Image = img
      this.uploadDialog = true
    },

    getImage (img) {
      this.syncModel.Image = img
      this.uploadDialog = false
      this.syncModel.content = img.id
    },
  },
})
</script>

<style scoped lang="scss">
section {
  margin: 0;
}

.img-hint-text {
  @apply text-sm text-gray-60 my-[10px] leading-[14.4px] font-medium;
}

.message-block {
  @apply relative w-[440px] p-[20px] bg-white rounded-sm;

  .header {
    @apply mb-[20px];
    .icon-btn-container {
      @apply flex items-center gap-[15px];
      .icon {
        @apply grid place-content-center cursor-pointer text-[20px] w-[24px] h-[24px];
      }
    }
  }
}

.active {
  @apply text-primary-100 bg-primary-30 rounded-sm;
  // color: var(--primary-100);
}

.upload {
  @apply h-[150px];
}

.close {
  @apply  cursor-pointer absolute top-[10px] right-[-40px] text-[20px];
}
::v-deep .el-form-item{
  @apply mb-0;
}
::v-deep .el-input__count{
  @apply bottom-0 right-[20px];
}

::v-deep .el-textarea {
  @apply w-full;
}
</style>
