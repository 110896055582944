<template>
  <div class="bg-white" style="padding: 12px; border-radius: 6px;">
    <div class="flex flex-col" style="gap: 24px">
      <div v-for="block in displayData" :key="block.type" class="flex flex-col" style="gap: 12px">
        <p class="text-primary-100 font-bold text-normal select-none">{{ block.title }}</p>
        <div class="options-container">
          <div
            v-for="option in block.options"
            :key="option.value"
            class="payment-option-item"
            :class="{
              isSelected: isSelected(option.value),
              isDisabled: isDisabled(option.value),
            }"
            @click="onSelectPayment(option)"
          >
            {{ option.label }}
          </div>
        </div>
      </div>
    </div>

    <SalesPOSCheckoutPaymentPriceModal
      v-if="modal.editPrice"
      :data="selectPayment"
      @close="modal.editPrice = false"
      @confirm="onConfirmPaymentMethod"
    />
  </div>
</template>

<script>
import { useSalesPOSCreate } from '@/use/useSalesPOS'
import { map, filter, find, get, some } from 'lodash'
import SalesPOSCheckoutPaymentPriceModal from './SalesPOSCheckoutPaymentPriceModal.vue'

import { computed, defineComponent, reactive, ref } from 'vue'

export default defineComponent({
  name: 'SalesPOSCheckoutMethods',
  components: {
    SalesPOSCheckoutPaymentPriceModal,
  },
  props: {},
  setup (props, { emit }) {
    const {
      sortedMenus, context, configData,
      originTotalPrice,
      getAllOrderDiscountsPrice,
      getAllSingleDiscountsPrice,
      needPaidCashPrice,
      addOrderPayment,
      resetOrderPayment,
    } = useSalesPOSCreate()
    const modal = reactive({
      editPrice: false,
    })
    const selectPayment = ref(null)
    const displayData = computed(() => {
      const data = [
        {
          title: '同時使用多種付款方式',
          type: 'multiple',
          options: map(filter(sortedMenus.value.paymentType, (i) => !['ezAIOTrust', 'cash'].includes(i.type)), (item) => {
            return {
              label: item.name,
              value: item.type,
            }
          }),
        },
        {
          title: '單一付款方式',
          type: 'single',
          options: map(filter(sortedMenus.value.paymentType, { type: 'ezAIOTrust' }), (item) => {
            return {
              label: item.name,
              value: item.type,
            }
          }),
        },
      ]

      return filter(data, (i) => i.options.length)
    })
    const isSelected = computed(() => {
      return (type) => find(context.payments, { type })
    })

    const isDisabled = (type) => {
      if (isSelected.value(type)) {
        return false
      }
      if (!needPaidCashPrice.value) return true

      if (type === 'ezAIOTrust') {
        if (some(context.payments, (item) => item.type !== 'ezAIOTrust')) {
          return true
        }
      }

      return false
    }

    const onSelectPayment = (payment) => {
      if (isDisabled(payment.value)) return
      const isExist = find(context.payments, { type: payment.value })

      if (isExist) {
        context.payments = filter(context.payments, (i) => i.type !== payment.value)
        return
      }

      if (payment.value === 'ezAIOTrust') {
        const totalOrderDiscount = getAllOrderDiscountsPrice()
        const totalSingleDiscount = getAllSingleDiscountsPrice()
        const finalTotalPrice = originTotalPrice.value - totalSingleDiscount - totalOrderDiscount
        resetOrderPayment()
        addOrderPayment({
          type: 'ezAIOTrust',
          price: finalTotalPrice,
          method: 'all',
        })
      }

      selectPayment.value = payment

      if (payment.value !== 'ezAIOTrust') modal.editPrice = true
    }

    const onConfirmPaymentMethod = (data) => {
      const isExist = find(context.payments, { type: data.type })
      context.payments = filter(context.payments, (i) => i.type !== 'ezAIOTrust')
      if (isExist) {
        isExist.price = data.price
        isExist.method = data.method
      } else {
        addOrderPayment(data)
      }
    }

    return {
      isDisabled,
      originTotalPrice,
      displayData,
      configData,
      modal,
      selectPayment,
      onSelectPayment,
      onConfirmPaymentMethod,
      isSelected,
      needPaidCashPrice,
    }
  },
})
</script>

<style lang="postcss" scoped>
.options-container {
  @apply grid grid-cols-3 gap-[12px];
}

.payment-option-item {
  @apply rounded-[6px] border-[2px] border-transparent bg-primary-30 h-[88px];
  @apply whitespace-pre-line;
  @apply flex items-center justify-center;
  @apply cursor-pointer select-none;
}

.isSelected {
  @apply bg-white border-primary-100 text-primary-100;
}

.isDisabled {
  @apply bg-gray-30 opacity-50;
}

.payment-option-item.selected {
  @apply bg-white border-primary-100 text-primary-100;
}

</style>
