<template>
  <div class="coupon-exchange-setting">
    <!-- <PageTitle
      title="第三方消費紀錄"
      btn="匯出"
      @btnClick="modal.export = true"
    /> -->
    <div class="page-title">
      <div class="flex items-center title">
        <span class="title" style="font-size: 18px">第三方消費紀錄</span>
      </div>
      <div class="grid grid-flow-col gap-[12px]">
        <div v-if="enableManualUpdate" class="flex items-center">
          <p class="text-sub text-gray-60 mr-[12px]">
            <TipInfo class="ml-[8px] text-gray-60" :size="20" placement="left" width="260">
              注意：每次僅同步 24 小時內的紀錄；且每次執行手動更新間隔不能小於 10 分鐘。
            </TipInfo>
          </p>
          <BaseElButton
            class="btn page-title-btn"
            plain
            testName="ecount_sync"
            @click="syncRecord"
          >
          刷新紀錄
          </BaseElButton>
        </div>
        <BaseElButton
          class="btn page-title-btn"
          type="primary"
          testName="create_product"
          @click="modal.export = true"
        >
          匯出
        </BaseElButton>
      </div>
    </div>
    <!-- <FiltersContainer> -->
    <div class="flex flex-col " style="gap: 20px">
      <div class="flex gap-[8px]">
        <ElInputWrapper>
          <BaseElSelect
            v-model="search.source"
            style="width: 224px"
            placeholder="選擇來源"
            clearable
            @clear="refresh(true)"
            @change="refresh(true)"
          >
            <BaseElSelectOption
              v-for="option in sourceOptions"
              :key="option.value"
              :value="option.value"
              :label="option.label"
            />
          </BaseElSelect>
        </ElInputWrapper>
        <ElInputWrapper v-if="showBranch">
          <BaseSearch
            style="width: 224px"
            :value.sync="search.branchs"
            :api="GetBranchStore"
            multiple
            collapse-tags
            placeholder="選擇交易門市"
            @change="refresh(true)"
          />
        </ElInputWrapper>
        <ElInputWrapper>
          <SearchTextInput
            style="width: 224px"
            :value.sync="search.customerPhone"
            placeholder="輸入會員手機號碼"
            @refresh="refresh(true)"
          />
        </ElInputWrapper>
      </div>

      <FiltersContainer>
        <!-- 交易時間 -->
        <el-date-picker
          v-model="search.timeRange"
          editable
          style="width: 340px"
          type="datetimerange"
          range-separator="至"
          start-placeholder="開始日期"
          end-placeholder="结束日期"
          format="yyyy-MM-dd HH:mm"
          :default-time="['00:00:00', '23:59:59']"
          clearable
          @change="refresh(true)"
          @clear="refresh(true)"
        />
        <SearchTextInput :value.sync="search.transactionId" placeholder="輸入消費紀錄編號" @refresh="refresh" />
      </FiltersContainer>
    </div>
    <!-- </FiltersContainer> -->
    <ExternalTransactionRecordTable
      v-loading="loading.table"
      :tableData="tableData"
      :options="{
        showBranch: showBranch,
        showPhone: true,
      }"
      @refresh="refresh(false)"
    />
    <Pagination
      :curPage.sync="tableOptions.page"
      :pageLimit="tableOptions.pageLimit"
      :total="tableDataCount"
      @pageChange="refresh(false)"
    />

    <ExportOptionsDialog
      v-if="modal.export"
      allRange
      :useExportTask="useExportTask"
      @close="modal.export = false"
      @export="prepareExport"
    />
    <ExportDialog
      v-if="exportState.modal"
      title="匯出第三方消費紀錄"
      :inProgress="exportState.exportting"
      :isError="exportState.error"
      :content="exportState.content"
      :percentage="totalPercentege"
      @close="resetExport"
    />
  </div>
</template>

<script>
import { computed, defineComponent, onBeforeMount, onMounted, reactive, ref } from 'vue'
import { useRouter } from 'vue-router/composables'
import { useTable } from '@/use/table'
// import PageTitle from '@/components/Title/PageTitle.vue'
import SearchTextInput from '@/components/Input/SearchTextInput.vue'
import ExternalTransactionRecordTable from '@/components/ExternalTransaction/ExternalTransactionRecordTable.vue'
import { GetMemberExternalTransaction, GetMemberExternalTransactionCount, GetMemberExternalTransactionSourceOptions } from '@/api/memberExternalTransaction'
import { useFetch } from '@/use/fetch'
import { memberExternalTransactionStatusConfig, originsConfig } from '@/config/externalTransaction'
import { CreateMemberExternalTransactionSheetExportTask } from '@/api/exportTask'
import { GetBranchStore } from '@/api/branchStore'
import { get, map, forEach, join } from 'lodash'
import { useExport } from '@/use/export'
import ExportOptionsDialog from '@/components/Dialog/ExportOptionsDialog.vue'
import ExportDialog from '@/components/Dialog/ExportDialog.vue'
import { ExportMoreSheetExcel } from '@/utils/excel'
import { formatDate } from '@/utils/date'
import { localeString } from '@/utils/helper'
import ElInputWrapper from '@/components/ElInputWrapper.vue'
import BaseSearch from '@/components/Search/BaseSearch.vue'
import { usePermissions } from '@/use/permissions'
import { useExportCenter } from '@/use/useExportCenter'
import TipInfo from '@/components/TipInfo.vue'
import { GetCustomModule } from '@/api/customModule'
import { ManualSyncPospalTransaction } from '@/api/pospal'

export default defineComponent({
  name: 'ExternalTransactionRecordList',
  components: {
    // PageTitle,
    ExternalTransactionRecordTable,
    SearchTextInput,
    ExportOptionsDialog,
    ExportDialog,
    ElInputWrapper,
    BaseSearch,
    TipInfo,
  },
  setup (props) {
    const router = useRouter()
    const { simpleFetch } = useFetch()
    const {
      tableData,
      tableOptions,
      tableDataCount,
      loading,
      pageStartIndex,
      shopId,
      fetchData,
      fetchDataCount,
    } = useTable()
    const { exportState, resetExport, getExportData, totalDataCount, totalPercentege } = useExport()
    const { messageOptions } = useExportCenter()
    const { checkAction } = usePermissions()
    const sourceOptions = ref([])
    const showBranch = computed(() => {
      const sources = map(sourceOptions.value, 'value')
      return sources.includes(originsConfig.weiby.value) || sources.includes(originsConfig.dudoo.value)
    })
    const modal = reactive({
      export: false,
    })
    const search = reactive({
      transactionId: null,
      source: null,
      timeRange: null,
      customerPhone: null,
      branchs: [],
    })
    const configData = ref({})
    const useExportTask = computed(() => checkAction('admin.memberExternalTransaction.createSheetExportTask'))
    const enableManualUpdate = computed(() => {
      return get(configData.value, '[0].config.enableManualUpdate', false)
    })
    const onCreate = () => {
      router.push({ name: 'ItemCreatePage' })
    }
    const syncRecord = async () => {
      const [res, err] = await ManualSyncPospalTransaction({ shopId: shopId.value })
      if (err) {
        window.$message.error(err)
        return
      }
      if (res && !res.success) {
        window.$message.error(res.msg)
        return
      }
      window.$message.success('更新成功')
      // FIXME: 暫時處理
      setTimeout(async () => {
        await refresh(true);
      }, 5000);
    }
    const getTableData = async () => {
      const payload = {
        shopId: shopId.value,
        start: pageStartIndex.value,
        limit: tableOptions.pageLimit,
        transactionId: search.transactionId || undefined,
        source: search.source || undefined,
        customerPhone: search.customerPhone || undefined,
        transactionTimeStart: search.timeRange ? search.timeRange[0] : undefined,
        transactionTimeEnd: search.timeRange ? search.timeRange[1] : undefined,
        branchIds: search.branchs.length ? join(map(search.branchs, 'id'), ',') : undefined,
      }
      await Promise.allSettled([
        fetchData(GetMemberExternalTransaction, payload),
        fetchDataCount(GetMemberExternalTransactionCount, payload),
      ])
    }

    const formatRecordData = (data) => {
      const sourceType = get(data, 'source') ? get(data, 'source').toLowerCase() : ''
      if (sourceType === originsConfig.pospal.value) {
        const itemsData = []
        forEach(data.items, (item, idx) => {
          itemsData.push({
            消費紀錄編號: data.transactionId,
            來源: get(originsConfig, `${data.source}.label`, data.source),
            會員手機號碼: data.customerPhone,
            認列金額: localeString(data.amount),
            實際消費金額: localeString(data.sellAmount),
            交易時間: formatDate(data.transactionTime),
            是否作廢: data.isInvalid ? '是' : '否',
            訂單狀態: get(memberExternalTransactionStatusConfig, `${data.status}.label`),
            消費品項名稱: item.name,
            消費品項金額: localeString(item.price),
            消費品項數量: item.quantity,
            訂單備註: item.note,
            紀錄建立時間: formatDate(data.createTime),
          })
        })
        return itemsData
      }
      if (sourceType === originsConfig.opentix.value) {
        const itemsData = []
        forEach(data.items, (item, idx) => {
          itemsData.push({
            消費紀錄編號: data.transactionId,
            來源: get(originsConfig, `${data.source}.label`, data.source),
            會員手機號碼: data.customerPhone,
            認列金額: localeString(data.amount),
            實際消費金額: localeString(data.sellAmount),
            交易時間: formatDate(data.transactionTime),
            是否作廢: data.isInvalid ? '是' : '否',
            訂單狀態: get(memberExternalTransactionStatusConfig, `${data.status}.label`),
            場次名稱: get(item, 'name'),
            消費金額: localeString(item.price),
            消費數量: item.quantity,
            每場起訖時間: `${formatDate(item.context.opentixEventStartTime * 1000)} ~ ${formatDate(item.context.opentixEventEndTime * 1000)}`,
            紀錄建立時間: formatDate(data.createTime),
          })
        })
        return itemsData
      }
      if (sourceType === originsConfig.weiby.value) {
        const itemsData = []
        forEach(data.items, (item, idx) => {
          itemsData.push({
            消費紀錄編號: data.transactionId,
            來源: get(originsConfig, `${data.source}.label`, data.source),
            交易門市: get(data.Branch, 'name'),
            會員手機號碼: data.customerPhone,
            認列金額: localeString(data.amount),
            實際消費金額: localeString(data.sellAmount),
            交易時間: formatDate(data.transactionTime),
            是否作廢: data.isInvalid ? '是' : '否',
            訂單狀態: get(memberExternalTransactionStatusConfig, `${data.status}.label`),
            消費品項名稱: item.name,
            消費品項金額: localeString(item.price),
            消費品項數量: item.quantity,
            紀錄建立時間: formatDate(data.createTime),
          })
        })
        return itemsData
      }
      if (sourceType === originsConfig.dudoo.value) {
        const itemsData = []
        forEach(data.items, (item, idx) => {
          itemsData.push({
            消費紀錄編號: data.transactionId,
            來源: get(originsConfig, `${data.source}.label`, data.source),
            交易門市: get(data.Branch, 'name'),
            會員手機號碼: data.customerPhone,
            認列金額: localeString(data.amount),
            實際消費金額: localeString(data.sellAmount),
            交易時間: formatDate(data.transactionTime),
            是否作廢: data.isInvalid ? '是' : '否',
            訂單狀態: get(memberExternalTransactionStatusConfig, `${data.status}.label`),
            消費品項名稱: item.name,
            消費品項金額: localeString(item.price),
            消費品項數量: item.quantity,
            紀錄建立時間: formatDate(data.createTime),
          })
        })
        return itemsData
      }
      if (sourceType === originsConfig.drfoot.value) {
        const itemsData = []
        forEach(data.items, (item, idx) => {
          itemsData.push({
            消費紀錄編號: data.transactionId,
            來源: get(originsConfig, `${data.source}.label`, data.source),
            會員手機號碼: data.customerPhone,
            認列金額: localeString(data.amount),
            實際消費金額: localeString(data.sellAmount),
            交易時間: formatDate(data.transactionTime),
            是否作廢: data.isInvalid ? '是' : '否',
            訂單狀態: get(memberExternalTransactionStatusConfig, `${data.status}.label`),
            消費品項名稱: item.name,
            消費品項編號: item.itemNo,
            消費品項金額: localeString(item.price),
            消費品項數量: item.quantity,
            紀錄建立時間: formatDate(data.createTime),
          })
        })
        return itemsData
      }
    }

    const prepareExport = async () => {
      modal.export = false
      const payload = {
        shopId: shopId.value,
        start: pageStartIndex.value,
        limit: tableOptions.pageLimit,
        transactionId: search.transactionId || undefined,
        source: search.source || undefined,
        customerPhone: search.customerPhone || undefined,
        transactionTimeStart: search.timeRange ? search.timeRange[0] : undefined,
        transactionTimeEnd: search.timeRange ? search.timeRange[1] : undefined,
      }
      if (useExportTask.value) {
        const [, err] = await CreateMemberExternalTransactionSheetExportTask(payload)
        if (err) {
          window.$message.error(err)
          return
        }
        window.$message(messageOptions.value)
      } else {
        exportState.modal = true
        exportState.exportting = true
        const exportData = {
          pospal: {
            sheetName: 'Pospal',
            data: [],
          },
          weiby: {
            sheetName: 'Weiby',
            data: [],
          },
          opentix: {
            sheetName: 'Opentix',
            data: [],
          },
          drFoot: {
            sheetName: 'Dr.Foot',
            data: [],
          },
          dudoo: {
            sheetName: 'Dudoo',
            data: [],
          },
        }
        try {
          const res = await getExportData({
            stage: 0,
            fetchAPI: GetMemberExternalTransaction,
            payload,
          })
          exportState.dataCount = [res.length]
          if (!totalDataCount.value) {
            exportState.content = '尚無資料可匯出'
            exportState.error = true
            window.$message.warning('尚無資料可匯出')
            return
          }

          for (const data of res) {
            const sourceType = get(data, 'source') ? get(data, 'source').toLowerCase() : ''
            if (sourceType === originsConfig.pospal.value) exportData.pospal.data.push(...formatRecordData(data))
            else if (sourceType === originsConfig.weiby.value) exportData.weiby.data.push(...formatRecordData(data))
            else if (sourceType === originsConfig.opentix.value) exportData.opentix.data.push(...formatRecordData(data))
            else if (sourceType === originsConfig.drfoot.value) exportData.drFoot.data.push(...formatRecordData(data))
            else if (sourceType === originsConfig.dudoo.value) exportData.dudoo.data.push(...formatRecordData(data))
          }
          exportState.success = true
          exportState.content = '匯出完成'

          console.log(map(exportData, (sheet) => sheet))

          ExportMoreSheetExcel(map(exportData, (sheet) => sheet), '第三方消費紀錄')
        } catch (error) {
          exportState.exportting = false
          exportState.error = true
          exportState.content = '匯出失敗'
        }
      }
    }

    const refresh = async (search = false) => {
      if (search) tableOptions.page = 1
      loading.table = true
      await getTableData()
      loading.table = false
    }
    const getCustomModule = async () => {
      const [res, err] = await GetCustomModule({
        shopId: shopId.value,
        type: 'posPal',
      })
      if (err) throw err
      configData.value = res
    }
    onBeforeMount(async () => {
      simpleFetch(GetMemberExternalTransactionSourceOptions, { shopId: shopId.value }, (res) => {
        sourceOptions.value = map(res, (item) => {
          const origin = get(originsConfig, item)
          if (!origin) return { label: item, value: item }
          return origin
        })
      })
    })

    onMounted(() => {
      getCustomModule()
      refresh()
    })
    return {
      loading,
      tableData,
      tableOptions,
      tableDataCount,
      onCreate,
      refresh,
      search,
      sourceOptions,
      resetExport,
      exportState,
      totalDataCount,
      modal,
      totalPercentege,
      prepareExport,
      GetBranchStore,
      showBranch,
      useExportTask,
      enableManualUpdate,
      syncRecord,
    }
  },
})
</script>
<style scoped lang="postcss">
::v-deep .el-range-separator {
  width: auto !important;
}
.page-title {
  @apply flex justify-between mb-[24px];
}

.title {
  @apply font-medium;
}

.btn {
  @apply min-w-[100px] text-normal;
}
</style>
