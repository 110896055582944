<template>
  <BaseDialog title="銷售紀錄折抵設定" width="610px" :btn1-loading="loading" @close="$emit('close')" @confirm="onConfirm">
    <BaseElForm ref="formRef" :model="formData" :rules="formRules" label-position="top">
      <BaseElFormItem v-if="showControl.enableCashbackDiscount" label="回饋金折抵" prop="enableCashbackDiscount">
        <BaseElSwitch
          v-model="formData.enableCashbackDiscount"
          inactive-text="關閉"
          active-text="開啟"
        />
      </BaseElFormItem>

      <div v-if="showControl.enablePointDiscount">
        <BaseElFormItem label="點數折抵" prop="enablePointDiscount">
          <BaseElSwitch
            v-model="formData.enablePointDiscount"
            inactive-text="關閉"
            active-text="開啟"
          />
        </BaseElFormItem>

        <BaseElFormItem v-if="formData.enablePointDiscount" prop="pointDiscountRate">
          <div class="flex items-center" style="gap: 8px">
            <p>扣除</p>
            <BaseElInput
              v-model="formData.pointDiscountRate"
              type="number"
              placeholder="請輸入數字"
              style="width: 100px"
            />
            <p>點，折抵 1 元</p>
          </div>
        </BaseElFormItem>
      </div>
    </BaseElForm>
  </BaseDialog>
</template>

<script>
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import { useBaseForm } from '@/use/useForm'
import { isDigitRules, minRules, noEmptyRules } from '@/validation'
import { computed, defineComponent, onMounted } from 'vue'
import { UpdateSalesConfig } from '@/api/sales'
import { apiFormatAdaptor } from '@/utils/api'
import { useShop } from '@/use/shop'
import notifyMessage from '@/config/notifyMessage'
import { usePermissions } from '@/use/permissions'

export default defineComponent({
  name: 'EditSalesDiscountConfigModal',
  components: {
    BaseDialog,
  },
  props: {
    configData: {
      type: Object,
      default: () => ({}),
    },
  },
  setup (props, { emit }) {
    const { shopId } = useShop()
    const { checkAction } = usePermissions()
    const { formRef, formData, initFormData, loading, checkForm } = useBaseForm()
    initFormData({
      enableCashbackDiscount: false,
      enablePointDiscount: false,
      pointDiscountRate: null,
    })
    const useFeatures = computed(() => {
      return {
        usePoint: checkAction('admin.shopPoint.page'),
        useCashback: checkAction('admin.shopCashback.page'),
      }
    })

    const formRules = computed(() => {
      const rules = {
        pointDiscountRate: [
          noEmptyRules(),
          isDigitRules(),
          minRules(1),
        ],
      }
      return rules
    })

    const showControl = computed(() => {
      const control = {
        enableCashbackDiscount: useFeatures.value.useCashback,
        enablePointDiscount: useFeatures.value.usePoint,
      }
      return control
    })

    const onConfirm = async () => {
      if (!await checkForm(formRef.value)) return
      loading.value = true

      const [, err] = await apiFormatAdaptor(UpdateSalesConfig)({
        shopId: shopId.value,
        enableCashbackDiscount: formData.enableCashbackDiscount,
        enablePointDiscount: formData.enablePointDiscount,
        pointDiscountRate: formData.pointDiscountRate,
      })
      loading.value = false
      if (err) {
        window.$message.error(err)
        return
      }
      window.$message.success(notifyMessage.updateSuccess)
      emit('refresh')
      emit('close')
    }

    onMounted(() => {
      formData.enableCashbackDiscount = props.configData.enableCashbackDiscount
      formData.enablePointDiscount = props.configData.enablePointDiscount
      formData.pointDiscountRate = props.configData.pointDiscountRate
    })

    return { formRef, formData, loading, formRules, onConfirm, showControl }
  },
})
</script>

<style lang="postcss" scoped>

</style>
