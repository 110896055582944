import { render, staticRenderFns } from "./AppointmentCheckoutDetailBlock.vue?vue&type=template&id=2407d3cc&scoped=true"
import script from "./AppointmentCheckoutDetailBlock.vue?vue&type=script&lang=js"
export * from "./AppointmentCheckoutDetailBlock.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2407d3cc",
  null
  
)

export default component.exports