<template>
  <section :v-loading="loading" class="settings-section section-block">
    <div>
      <SectionTitle :title="$t('publicAppointment.paymentTypeSettings.title')" :btn="$t('common.button.create.text')" @edit="handlePayment('create')" />
      <BaseTable :data="displayData">
        <BaseElTableColumn :label="$t('publicAppointment.paymentTypeSettings.displayName.title')" prop="displayName" align="center" />
        <BaseElTableColumn :label="$t('publicAppointment.paymentTypeSettings.type.title')" prop="type" align="center" />
        <BaseElTableColumn :label="$t('publicAppointment.paymentTypeSettings.verified.title')" align="center">
          <template slot-scope="scope">
            <Tag :type="tagType(scope.row.verified)">
              {{ scope.row.verified ? $t('publicAppointment.paymentTypeSettings.verified.success.title') : $t('publicAppointment.paymentTypeSettings.verified.fail.title') }}
            </Tag>
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn :label="$t('common.table.action.text')" fixed="right" align="center">
          <template slot-scope="scope">
            <TableEditBtnGroup
              :copyBtn="$t('common.button.view.text')"
              :hideCopy="!scope.row.verified"
              :hideEdit="scope.row.verified"
              :hideDelete="scope.row.verified"
              @copy="handlePayment('view', scope.row)"
              @edit="handlePayment('edit', scope.row)"
              @delete="handlePayment('delete', scope.row)"
            />
          </template>
        </BaseElTableColumn>
      </BaseTable>
    </div>
    <EditPubAptPaymentDialog
      v-if="showEditPaymentDialog"
      :selectPayment="selectPayment"
      :type="paymentDialogType"
      @refresh="refresh"
      @close="showEditPaymentDialog = false"
    />
    <ViewPubAptPaymentDialog
      v-if="showViewPaymentDialog"
      :selectPayment="selectPayment"
      :pubAptPaymentTypes="pubAptPaymentTypes"
      @close="showViewPaymentDialog = false"
    />

    <DeleteDialog
      v-if="deleteDialog"
      :title="$t('common.dialog.delete.title')"
      :content="$t('common.dialog.delete.content')"
      width="40%"
      @close="deleteDialog = false"
      @delete="deletePubAptPaymentConfig"
    />
  </section>
</template>

<script>
import { defineComponent, ref, computed } from 'vue'
import DeleteDialog from '@/components/Dialog/DeleteDialog.vue'
import EditPubAptPaymentDialog from './EditPubAptPaymentDialog.vue'
import ViewPubAptPaymentDialog from './ViewPubAptPaymentDialog.vue'
import { pubAptPaymentTypes } from '@/config/pubApt'
import { DeletePubAptPaymentConfig } from '@/api/pubApt'
import { useShop } from '@/use/shop'
import { map, get } from 'lodash'
import { i18n } from '@/plugins/i18n/i18n'

export default defineComponent({
  name: 'PaymentTypeSettings',
  components: { EditPubAptPaymentDialog, ViewPubAptPaymentDialog, DeleteDialog },
  props: {
    content: { type: Array, default: () => [] },
  },
  setup (props, { emit }) {
    const { shopId } = useShop()
    const loading = ref(false)
    const deleteDialog = ref(false)
    const showEditPaymentDialog = ref(false)
    const showViewPaymentDialog = ref(false)
    const paymentDialogType = ref('create')
    const displayData = computed(() => {
      return map(get(props, 'content'), (item) => {
        return {
          ...item,
          type: get(pubAptPaymentTypes, get(item, 'config.type')).name,
        }
      })
    })
    const selectPayment = ref(null)
    const handlePayment = async (type, row) => {
      paymentDialogType.value = type
      if (type === 'delete') {
        selectPayment.value = row
        deleteDialog.value = true
      }
      if (type === 'create') {
        selectPayment.value = null
        showEditPaymentDialog.value = true
      }
      if (type === 'edit') {
        selectPayment.value = row
        showEditPaymentDialog.value = true
      }
      if (type === 'view') {
        selectPayment.value = row
        showViewPaymentDialog.value = true
      }
    }
    const deletePubAptPaymentConfig = async () => {
      deleteDialog.value = false
      const [, err] = await DeletePubAptPaymentConfig({
        shopId: shopId.value,
        id: get(selectPayment, 'value.id'),
      })
      if (err) return window.$message.error(err)
      window.$message.success(i18n.t('common.message.updateSuccess'))
      await refresh()
    }
    const tagType = (val) => {
      let type = 'info'
      // if(! val) type = 'danger'
      if (val) type = 'action'
      return type
    }
    const refresh = async () => {
      emit('refresh')
    }
    return {
      tagType,
      refresh,
      loading,
      pubAptPaymentTypes,
      // pubAptPayments,
      displayData,
      handlePayment,
      deletePubAptPaymentConfig,
      showEditPaymentDialog,
      showViewPaymentDialog,
      deleteDialog,
      paymentDialogType,
      selectPayment,
    }
  },
})
</script>
<style></style>
