<template>
  <div class="card-container flex flex-col" style="padding: 20px; padding-left: 30px; gap: 24px">
    <p class="section-title">預約付款資訊</p>
    <transition name="el-fade-in-linear">
      <AppointmentDiscountBlock v-if="showControl.block.discount" />
    </transition>
    <transition name="el-fade-in-linear">
      <AppointmentCheckoutInfo v-if="showControl.block.detail" />
    </transition>
    <transition name="el-fade-in-linear">
      <AppointmentInvoiceInfoBlock v-if="showControl.block.invoice" />
    </transition>
  </div>
</template>

<script>
import { featureKeys, useCreateAappointment } from '@/use/useCreateAppointment'
import { computed, defineComponent, onMounted, watch } from 'vue'
import AppointmentDiscountBlock from './AppointmentDiscountBlock.vue'
import AppointmentCheckoutInfo from './AppointmentCheckoutInfo.vue'
import AppointmentInvoiceInfoBlock from './AppointmentInvoiceInfoBlock.vue'
import { get } from 'lodash'
import { usePermissions } from '@/use/permissions'

export default defineComponent({
  name: 'AppointmentPaymentBlock',
  components: { AppointmentDiscountBlock, AppointmentCheckoutInfo, AppointmentInvoiceInfoBlock },
  setup (props, { emit }) {
    const { context, configData, refreshOrderPrice, allCartItemReady } = useCreateAappointment()
    const { checkAction } = usePermissions()

    const showControl = computed(() => {
      const controls = {
        block: {
          detail: false,
          discount: false,
          invoice: false,
        },
      }

      const chargeType = get(context.checkoutDetail, 'chargeType')

      if (get(configData.checkoutInvoice, 'enable') && chargeType === 'checkout') {
        if (['free', 'wallet', 'externalWallet'].includes(context.payment.method)) {
          controls.block.invoice = false
        } else {
          controls.block.invoice = true
        }
      }

      if (get(configData.checkout, 'enableCashbackDeduction') && chargeType === 'checkout') {
        controls.block.discount = true
      }
      // 權限判斷
      if (!checkAction(featureKeys.useCashbackWallet) || !checkAction(featureKeys.useCashbackWallet)) {
        controls.block.discount = false
      }

      if (allCartItemReady.value) {
        controls.block.detail = true
      }

      return controls
    })

    const cashbackUse = computed(() => get(context.discount, 'cashback.use', 0))

    watch(cashbackUse, () => {
      refreshOrderPrice()
    })

    onMounted(() => {
      refreshOrderPrice()
    })

    return { context, showControl }
  },
})
</script>

<style lang="postcss" scoped>
.section-title {
  @apply font-bold text-primary-100 text-[18px];
}
</style>
