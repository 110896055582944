<template>
  <section class="settings-section section-block">
    <SectionTitle
      cyBtn="edit-time-parameter"
      :title="$t('reservationBasicSettings.title')"
      :btn="$t('common.button.edit.text')"
      @edit="modal.edit = true"
    />
    <div>
      <BaseElForm label-position="left" label-width="250px">
        <BaseElFormItem class="settings-item">
          <template slot="label">
            <div class="flex items-center" style="gap: 8px">
              <p>{{ $t('reservationBasicSettings.timeUnit.title') }}</p>
              <TipInfo :size="16" width="200">
                <p>{{ $t('reservationBasicSettings.timeUnit.tooltip')}}</p>
              </TipInfo>：
            </div>
          </template>
          <span class="content">{{
            configData.timeUnit !== null ? configData.timeUnit : $t('common.notSetupYet.text')
          }}</span>
        </BaseElFormItem>

        <BaseElFormItem class="settings-item">
          <template slot="label">
            <div class="flex items-center" style="gap: 8px">
              <p>{{ $t('reservationBasicSettings.timeUnitOffset.title')}}</p>
              <TipInfo :size="16" width="200">
                <p>{{ $t('reservationBasicSettings.timeUnitOffset.tooltip')}}</p>
              </TipInfo>：
            </div>
          </template>
          <span class="content">{{
            configData.timeUnitOffset !== null ? configData.timeUnitOffset : $t('common.notSetupYet.text')
          }}</span>
        </BaseElFormItem>

        <BaseElFormItem class="settings-item">
          <template slot="label">
            <div class="flex items-center" style="gap: 8px">
              <p>{{ $t('reservationBasicSettings.minReservationInterval.title')}}</p>
              <TipInfo :size="16" width="200">
                <p>{{ $t('reservationBasicSettings.minReservationInterval.tooltip')}}</p>
              </TipInfo>：
            </div>
          </template>
          <span class="content">{{
            configData.minReservationInterval !== null
              ? configData.minReservationInterval
              : $t('common.notSetupYet.text')
          }}</span>
        </BaseElFormItem>

        <BaseElFormItem class="settings-item">
          <template slot="label">
            <div class="flex items-center" style="gap: 8px">
              <p>{{ $t('reservationBasicSettings.maxReservationInterval.title')}}</p>
              <TipInfo :size="16" width="200">
                <p>{{ $t('reservationBasicSettings.maxReservationInterval.tooltip')}}</p>
              </TipInfo>：
            </div>
          </template>
          <span class="content">{{
            configData.maxReservationInterval ? configData.maxReservationInterval : $t('common.notSetupYet.text')
          }}</span>
        </BaseElFormItem>

        <BaseElFormItem class="settings-item">
          <template slot="label">
            <div class="flex items-center" style="gap: 8px">
              <p>{{ $t('reservationBasicSettings.cancelInterval.title')}}</p>
              <TipInfo :size="16" width="200">
                <p>{{ $t('reservationBasicSettings.cancelInterval.tooltip')}}</p>
              </TipInfo>：
            </div>
          </template>
          <span class="content">{{
            configData.cancelInterval ? configData.cancelInterval : $t('common.notSetupYet.text')
          }}</span>
        </BaseElFormItem>
      </BaseElForm>
    </div>

    <EditReservationBasicSettingsModal
      v-if="modal.edit"
      data-testid="edit-reservation-basic-settings-modal"
      :configData="configData"
      @close="modal.edit = false"
      @refresh="$emit('refresh')"
    />
  </section>
</template>

<script>
import { defineComponent, reactive } from 'vue'
import EditReservationBasicSettingsModal from './EditReservationBasicSettingsModal.vue'
import TipInfo from '@/components/TipInfo.vue'
export default defineComponent({
  name: 'ReservationBasicSettings',
  components: {
    EditReservationBasicSettingsModal,
    TipInfo,
  },
  props: {
    configData: {
      type: Object,
      default: () => ({}),
    },
  },
  setup (props, { emit }) {
    const modal = reactive({
      edit: false,
    })
    return { modal }
  },
})
</script>

<style lang="postcss" scoped></style>
