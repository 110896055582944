<template>
  <div class="page-title">
    <div class="flex items-center title">
      <span v-if="icon" class="material-icons cursor-pointer" style="margin-right: 9px;" @click="$emit('iconClick')">{{ icon }}</span>
      <span class="title" :style="`font-size: ${fontSize}px`">{{ title }}</span>
    </div>
    <div>
      <BaseElButton
        v-if="showBtn2"
        v-loading="btn2Loading"
        class="btn page-title-btn"
        :plain="showBtn? true : false"
        :type="showBtn? '' : 'primary'"
        :data-testid="btn2TestId"
        @click="$emit('btn2Click')"
      >
        {{ btn2 }}
      </BaseElButton>
      <slot name="btn1">
        <BaseElButton
          v-if="showBtn"
          v-loading="btnLoading"
          class="btn page-title-btn"
          :plain="btnPlain"
          :type="btnPlain ? '' : 'primary'"
          :data-testid="btn1TestId"
          @click="$emit('btnClick')"
        >
          {{ btn || $t('common.button.edit.text') }}
        </BaseElButton>
      </slot>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed } from 'vue'
import { usePermissions } from '@/use/permissions'

export default defineComponent({
  name: 'PageTitle',
  props: {
    title: { default: '' },
    btn1TestId: { default: 'page-title_btn1' },
    btn2TestId: { default: 'page-title_btn2' },
    btn: { default: '' },
    btnPlain: { type: Boolean, default: false },
    btn2: { default: '' },
    btnLoading: { type: Boolean, default: false },
    btn2Loading: { type: Boolean, default: false },
    btnFeature: { default: '' },
    btn2Feature: { default: '' },
    fontSize: { default: 18 },
    hideBtn: Boolean,
    hideBtn1: Boolean,
    icon: {
      type: String,
      default: null,
    },
  },
  setup (props, { emit }) {
    const { checkAction } = usePermissions()

    const btnPermission = computed(() => {
      if (!props.btnFeature) return true
      return checkAction(props.btnFeature)
    })
    const btn2Permission = computed(() => {
      if (!props.btn2Feature) return true
      return checkAction(props.btn2Feature)
    })

    const showBtn = computed(() => {
      return !props.hideBtn && btnPermission.value && !props.hideBtn1
    })

    const showBtn2 = computed(() => {
      return !props.hideBtn && props.btn2 && btn2Permission.value
    })

    return {
      btnPermission,
      btn2Permission,
      showBtn,
      showBtn2,
    }
  },
})
</script>

<style lang="postcss" scoped>
.page-title {
  @apply flex justify-between mb-[24px];
}

.title {
  @apply font-medium;
}

.btn {
  @apply min-w-[100px] text-normal;
}
</style>
