<template>
  <div class="history__content">
    <div
      v-for="(item, index, key) in data"
      :key="key"
      class="content__item"
    >
      <div class="item__label font-[12px]">
        <span class="mx-1">{{ item.label }}</span>
        <span v-if="item.tip">{{ item.tip }}</span>
        <Tipinfo v-if="item.tooltip" width="200">
          {{ item.tooltip }}
        </Tipinfo>
      </div>
      <div class="item__value">
        <span v-show="showSymbol(item.label) && item.value && item.value < 0">- </span>
        <!-- {{ item.value || item.value === 0 ? Math.abs(item.value) : '-' }} 點 -->
        {{ displayValue(item.value) }} {{ displayData.pointUnitName }}
      </div>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent } from 'vue'
import Tipinfo from '@/components/TipInfo.vue'
import { isNumber, isNaN, get, find } from 'lodash'
import { useShop } from '@/use/shop'

export default defineComponent({
  name: 'PointStatisticBlock',
  components: { Tipinfo },
  props: {
    data: { type: Array, default: () => [] },
    shopPointKey: { type: String, default: '' },
  },
  setup (props, { emit }) {
    const { shopPointList } = useShop()

    const showSymbol = (label) => {
      return ['未實現數', '總進出差額', '總進出差額(排除過期)'].includes(label)
    }

    const displayData = computed(() => {
      const pointData = find(shopPointList.value, { key: props.shopPointKey }) || find(shopPointList.value, { isDefault: true })
      const data = {
        pointUnitName: get(pointData, 'unitName', '點'),
      }
      return data
    })

    const displayValue = computed(() => {
      return (value) => {
        let showValue
        if (isNumber(value) && !isNaN(value)) {
          showValue = Math.abs(value)
        } else {
          showValue = '-'
        }
        return showValue
      }
    })

    return {
      showSymbol,
      displayData,
      displayValue,
    }
  },
})
</script>

<style scoped lang="scss">
.history__content,
.record__content {
  display: flex;
  padding: 3px;
  min-height: 79px;
  background-color: white;
}

.history__content {
  margin-top: 22px;
}

.content__item {
  position: relative;
  flex: 1 1 33%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;

  &:not(:last-of-type)::after {
    content: "";
    position: absolute;
    top: 10%;
    right: -1px;
    width: 2px;
    height: 80%;
    background-color: #DCDFE6;
  }

  .item__label {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    color: #2d271b;
    font-size: 14px;
    line-height: 21px;
  }

  .item__value {
    margin-top: 4px;
    @apply text-primary-100;
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
  }
}
</style>
