<template>
  <main v-loading="loading" class="mb-[20px]">
    <PointStatisticBlock
      v-if="data.length"
      :data="displayData"
      :shopPointKey="shopPointKey"
    />
  </main>
</template>

<script>
import PointStatisticBlock from '@/components/Report/PointStatisticBlock.vue'
import { FindMemberPointYearExpirations } from '@/api/shopPoint'
import store from '@/store'
import { computed, defineComponent, inject, onMounted, ref, watch } from 'vue'
import { map, reduce, get } from 'lodash'
import { formatDate } from '@/utils/date'

export default defineComponent({
  name: 'PointStatistic',
  components: {
    PointStatisticBlock,
  },
  props: {
    memberId: {
      type: String,
    },
    loading: {
      type: Boolean,
    },
    shopPointKey: {
      type: String,
    },
  },
  setup (props, { emit }) {
    const pageShopPoint = inject('pageShopPoint')
    const data = ref([])
    const shopId = computed(() => store.getters.shop)

    // ?? 頁面顯示資料
    const displayData = computed(() => {
      if (!data.value.length) return []
      const res = map(data.value, i => {
        return {
          label: `${formatDate(i.expirationDate, 'YYYY/MM/DD')} 到期`,
          value: i.balance,
        }
      })
      res.unshift({
        label: `總${get(pageShopPoint.value, 'name', '點數')}`,
        value: reduce(res, (sum, i) => sum + i.value, 0),
      })

      return res
    })

    // => 查詢會員點數到期資料
    const findMemberPointYearExpirations = async () => {
      const [res, err] = await FindMemberPointYearExpirations({
        shopId: shopId.value,
        memberId: props.memberId,
        shopPointKey: props.shopPointKey,
      })
      if (err) {
        window.$message.error(err)
        return
      }
      data.value = res
    }

    watch(props, async () => {
      if (props.loading) await findMemberPointYearExpirations()
    })

    onMounted(() => {
      findMemberPointYearExpirations()
    })

    return {
      data,
      displayData,
    }
  },

})

</script>

<style scoped lang="postcss">

</style>
