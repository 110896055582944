<template>
  <GrayBlockContainer class="flex flex-col" style="gap: 24px">
    <p class="section-title">預約明細</p>
    <AppointmentCheckoutDetailBlock />
    <AppointmentPaymentMethodsBlock />
  </GrayBlockContainer>
</template>

<script>
import GrayBlockContainer from '@/components/Container/GrayBlockContainer.vue'
import { defineComponent } from 'vue'
import AppointmentPaymentMethodsBlock from './AppointmentPaymentMethodsBlock.vue'
import AppointmentCheckoutDetailBlock from './AppointmentCheckoutDetailBlock.vue'

export default defineComponent({
  name: 'AppointmentCheckoutInfo',
  components: {
    GrayBlockContainer,
    AppointmentPaymentMethodsBlock,
    AppointmentCheckoutDetailBlock,
  },
  setup (props, { emit }) {
    return {}
  },
})
</script>

  <style lang="postcss" scoped>
.section-title {
  @apply font-bold text-primary-100 text-[18px];
}
  </style>
