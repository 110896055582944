<template>
  <div class="form-item-tooltip-lable" style="gap: 8px">
    <p class="text-normal">{{ label }}</p>
    <slot name="subTitle" />
    <el-tooltip placement="right">
      <div slot="content" :style="`width: ${tooltipWidth}px`">
        <slot />
      </div>
      <MaterialIcon v-if="!hideIcon" :size="iconSize">help_outline</MaterialIcon>
    </el-tooltip>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import MaterialIcon from '@/components/MaterialIcon.vue'

export default defineComponent({
  name: 'FormItemTooltipLabel',
  components: { MaterialIcon },
  props: {
    label: String,
    tooltipWidth: { type: [String, Number], default: 200 },
    iconSize: { type: Number, default: 24 },
    hideIcon: Boolean,
  },
})
</script>

<style lang="postcss" scoped>
.form-item-tooltip-lable {
  @apply flex items-center !inline-flex;
}
</style>
