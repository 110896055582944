<template>
  <BaseElSelect v-model="syncModel" placeholder="請選擇" clearable>
    <BaseElSelectOption
      v-for="nav in allowOptionList"
      :key="nav.value"
      :label="nav.name"
      :value="nav.value"
    />
  </BaseElSelect>
</template>

<script>
import { navConfig } from '@/config/memberCenter'
import { onMounted, ref } from 'vue'
import { find, map, filter } from 'lodash'
import { useExternalWallet } from '@/use/useExternalWallet'
export default {
  name: 'NavItemSelect',
  props: ['model', 'selected', 'allowNavFooter'],
  setup (props) {
    const { externalWalletName, getExternalWalletConfig } = useExternalWallet()
    const allowOptionList = ref([])
    const filterOptions = () => {
      const config = map(props.allowNavFooter, 'type')
      const data = map(filter(navConfig, (nav) => config.includes(nav.value)), (nav) => ({
        name: nav.value === 'posPalMemberWallet' ? externalWalletName.value : nav.name,
        value: nav.value,
      }))
      return data
    }
    onMounted(async() => {
      await getExternalWalletConfig()
      allowOptionList.value = filterOptions()
    })
    return {
      externalWalletName,
      allowOptionList,
    }
  },
  // data: () => ({
    // navConfig,
    // allowOptionList: [],
  // }),
  computed: {
    syncModel: {
      get () {
        return this.model
      },
      set (val) {
        this.$emit('update:model', val)
      },
    },
  },

  // mounted () {
  //   this.allowOptionList = this.filterOptions()
  // },
  // methods: {
    // disabledSelected (nav) {
    //   return Boolean(find(this.selected, (item) => item === nav.value))
    // },

    // filterOptions () {
    //   const config = map(this.allowNavFooter, 'type')
    //   const data = map(filter(this.navConfig, (nav) => config.includes(nav.value)), (nav) => ({
    //     name: nav.value === 'posPalMemberWallet' ? this.externalWalletName : nav.name,
    //     value: nav.value,
    //   }))
    //   return data
    // },
  // },
}
</script>

<style lang="postcss" scoped></style>
