<template>
  <section class="area-wrapper section-block">
    <SectionTitle title="會員卡顯示設定" @edit="showEditModal = true" />
    <!-- <p class="container-title mb-[24px]">基本設定</p> -->
    <BaseElForm label-width="200px" label-position="left">
      <BaseElFormItem label="顯示設定（至多三個）">
        {{ displayData }}
      </BaseElFormItem>
    </BaseElForm>

    <MemberCardShowEditModal
      v-if="showEditModal"
      :configData="configData"
      @close="showEditModal = false"
      @refresh="$emit('refresh')"
    />
  </section>
</template>

<script>
import SectionTitle from '@/components/Title/SectionTitle.vue'
import MemberCardShowEditModal from './components/MemberCardShowEditModal.vue'
import { onMounted, ref, reactive, computed, defineComponent } from 'vue'
import { get, map, filter, find } from 'lodash'
import { useExternalWallet } from '@/use/useExternalWallet'
import { usePermissions } from '@/use/permissions'
import { useShop } from '@/use/shop'

export default defineComponent({
  name: 'MemberCardShow',
  components: {
    SectionTitle,
    MemberCardShowEditModal,
  },
  props: {
    configData: Array,
  },
  setup(props) {
    const { shopId, shopPointList } = useShop()
    const { checkAction, checkActionList } = usePermissions()
    const { externalWalletName, getExternalWalletConfig } = useExternalWallet()
    const usePospal = computed(() => checkActionList(['adminView.memberCenterConfig.findPosPalMemberWallet', 'admin.externalWallet.page']))
    const useWallet = computed(() => checkAction('admin.walletRecord.page'))
    const showEditModal = ref(false)
    const showType = computed(() => {
      const types = {
        // walletBalance: { label: '儲值金', value: 'walletBalance' },
        couponCount: { label: '票券', value: 'couponCount' },
        cashbackBalance: { label: '回饋金', value: 'cashbackBalance' },
        pointBalance: { label: '點數', value: 'pointBalance' },
        classTicketRecordCount: { label: '堂票', value: 'classTicketRecordCount' },
      }
      if (useWallet.value) {
        types.walletBalance = { label: '儲值金', value: 'walletBalance' }
      }
      if (usePospal.value) {
        types.posPalMemberBalance = { label: externalWalletName.value, value: 'posPalMemberBalance' }
      }
      return types
    })
    const displayData = computed(() => {
      const showTypes = Object.keys(showType.value)
      let enableConfig = filter(props.configData, config => config.enable)
      enableConfig = filter(enableConfig, i => showTypes.includes(i.type))
      enableConfig.sort((a, b) => a.order - b.order)
      console.log(enableConfig)
      const typesLabels = map(enableConfig, (i) => {
        const shopPoint = find(shopPointList.value, { key: i.shopPointKey })
        const typeName = get(showType.value, `${i.type}.label`)
        return shopPoint ? `${typeName}（${shopPoint.name}）` : typeName
      })
      if (!typesLabels.length) return '尚未設置'
      return typesLabels.join('、')
    })
    onMounted(() => {
      getExternalWalletConfig()
    })
    return {
      externalWalletName,
      showEditModal,
      displayData,
      shopPointList,
    }
  },
})
</script>

<style>

</style>
