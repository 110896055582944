import { render, staticRenderFns } from "./EditReservationBasicSettingsModal.vue?vue&type=template&id=abf010a0&scoped=true"
import script from "./EditReservationBasicSettingsModal.vue?vue&type=script&lang=js"
export * from "./EditReservationBasicSettingsModal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "abf010a0",
  null
  
)

export default component.exports