<template>
  <div class="flex flex-col" style="gap: 12px;">
    <p class="section-label">票券</p>
    <div class="grid grid-cols-2" style="gap: 12px">
      <div
        class="coupon-discount-item"
        :class="{ isSelected, isDisabled }"
        @click="onSelect"
      >
        票券
      </div>
    </div>

    <SalesPOSCouponDiscountSelectModal
      v-if="showModal"
      scope="singleDiscount"
      @close="showModal = false"
      @confirm="onConfirm"
    />
  </div>
</template>

<script>
import SalesPOSCouponDiscountSelectModal from '@/components/SalesPOSCouponDiscountSelectModal.vue'
import { useSalesPOSCreate } from '@/use/useSalesPOS'
import { computed, defineComponent, ref } from 'vue'
import { find, get, first, forEach } from 'lodash'

export default defineComponent({
  name: 'SalesPOSCouponDiscountList',
  components: {
    SalesPOSCouponDiscountSelectModal,
  },
  setup (props, { emit }) {
    const { context, getActiveCartItem } = useSalesPOSCreate()
    const showModal = ref(false)

    const isSelected = computed(() => {
      const activeCartItem = getActiveCartItem()
      if (!activeCartItem) return false
      return Boolean(find(activeCartItem.discounts, { id: 'coupon-discount' }))
    })

    const isDisabled = computed(() => {
      if (!isSelected.value) {
        const activeCartItem = getActiveCartItem()
        const remainingPrice = activeCartItem.getDiscountRemainingPrice()
        if (remainingPrice <= 0) {
          return true
        }
      }
      return false
    })

    const onSelect = () => {
      if (isDisabled.value) return
      const activeCartItem = getActiveCartItem()
      if (!activeCartItem) return
      if (isSelected.value) {
        activeCartItem.pruneSingleDiscounts('coupon-discount')
        return
      }
      showModal.value = true
    }

    const onConfirm = (data) => {
      const activeCartItem = getActiveCartItem()
      if (!activeCartItem) return
      forEach(data, (item) => {
        activeCartItem.addSingleDiscount({
          id: 'coupon-discount',
          name: item.name,
          mustSpendSetting: {
            type: get(item, 'mustSpendSetting.type'),
            couponRecordId: get(item, 'couponRecord.id'),
            amount: 1,
          },
          discountSource: 'discount',
          percentAmount: item.percentAmount,
          cashAmount: item.cashAmount,
          salesDiscountId: item.id,
          type: item.type,
        })
      })

      showModal.value = false
    }

    return { isSelected, showModal, isDisabled, onSelect, onConfirm }
  },
})
</script>

<style lang="postcss" scoped>
.section-label {
  @apply text-primary-100 text-normal font-bold;
}
.coupon-discount-item {
  @apply bg-primary-10 rounded-[6px] h-[88px] w-full;
  @apply flex flex-col items-center justify-center text-center;
  @apply select-none cursor-pointer;
  @apply border-[2px] border-transparent;
}
.isSelected {
  @apply border-primary-100 border-[2px] bg-white text-primary-100;
}
.isDisabled {
  @apply border-gray-20 bg-gray-20 cursor-not-allowed;
}
</style>
