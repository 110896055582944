<template>
  <section class="section-block card-container">
    <SectionTitle :title="$t('contentSetting.customSetting.title')" :disabled="displayData.disabled" @edit="$router.push({ name: 'EditCustomSetting'})" />
    <BaseElForm v-if="showContent" label-position="left" label-width="250px">
      <BaseElFormItem v-for="(item, index) in displayData.fullContentSetting" :key="item.text" :label="`${$t('contentSetting.customSetting.order.title')} ${index+1}`">
        <div class="text-[#5E5E5E]">
          <p class="text-sub font-bold">{{ item.title }}</p>
          <pre class="text-sub font-normal leading-[20.27px]">{{ item.text }}</pre>
        </div>
      </BaseElFormItem>
    </BaseElForm>
    <p v-else class="text-sub">{{ $t('contentSetting.customSetting.subTitle') }}</p>
  </section>
</template>

<script>
import { computed, defineComponent } from 'vue'
import { get } from 'lodash'

export default defineComponent({
  name: 'CustomSetting',
  props: {
    configData: { type: Object, default: () => ({}) },
    updatePubAptConfig: { type: Function, default: () => {} },
  },
  setup (props) {
    const displayData = computed(() => {
      return {
        fullContentSetting: get(props, 'configData.fullContentSetting'),
        disabled: get(props, 'configData.homeMode') === 'normal',
      }
    })
    const showContent = computed(() => get(props, 'configData.fullContentSetting') && get(props, 'configData.fullContentSetting').length > 0)
    return { displayData, showContent }
  },
})
</script>

<style lang="postcss" scoped></style>
