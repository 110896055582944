<template>
  <el-dialog
    :title="$t('reservationCustomTextSettings.title')"
    :visible="true"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <BaseElForm ref="formRef" :model="formData" label-position="top">
      <BaseElFormItem :label="$t('reservationCustomTextSettings.style.title')" prop="timeUnit">
        <BaseElSelect v-model="formData.pageStyle" name="pageStyle">
          <BaseElSelectOption
            v-for="styleItem in pageStyleList"
            :key="styleItem.value"
            :label="styleItem.name"
            :value="styleItem.value"
          />
        </BaseElSelect>
      </BaseElFormItem>
    </BaseElForm>

    <div slot="footer">
      <BaseElButton plain @click="$emit('close')">{{ $t('common.button.cancel.text')}}</BaseElButton>
      <BaseElButton type="primary" @click="onSubmit"> {{ $t('common.button.confirm.text')}} </BaseElButton>
    </div>
  </el-dialog>
</template>

<script>
import { defineComponent, onBeforeMount } from 'vue'
import { useBaseForm } from '@/use/useForm'
import { UpdateReservationParameter } from '@/api/reservation'
import { useShop } from '@/use/shop'
import notifyMessage from '@/config/notifyMessage'
import { pageStyleList } from '@/config/reservation'

export default defineComponent({
  name: 'EditReservationThemeModal',
  props: {
    configData: { type: Object, default: () => ({}) },
  },
  setup(props, { emit }) {
    const { formRef, formData, initFormData, loading, checkForm } = useBaseForm()
    const { shopId } = useShop()

    const onSubmit = async () => {
      if (loading.value) return
      loading.value = true
      if (!(await checkForm(formRef.value))) return
      const [, err] = await UpdateReservationParameter({
        shopId: shopId.value,
        pageStyle: formData.pageStyle,
      })
      loading.value = false
      if (err) {
        window.$message.error(err)
        return
      }

      window.$message.success(notifyMessage.updateSuccess)
      emit('close')
      emit('refresh')
    }

    onBeforeMount(() => {
      initFormData({
        pageStyle: props.configData.pageStyle,
      })
    })
    return { formRef, formData, loading, onSubmit, pageStyleList }
  },
})
</script>

<style lang="postcss" scoped></style>
