<template>
  <div class="">
    <div>
      <!-- 品項明細 -->
      <div class="item-detail-container">
        <div v-for="item in displayCartItemInfo" :key="item.nanoId" @click="onExpand(item.nanoId)">
          <div class="item-row">
            <p>{{ item.name }}</p>
            <p class="flex-shrink-0">$ {{ item.totalPrice }}</p>
          </div>
          <el-collapse-transition>
            <div
              v-if="expand.item[item.nanoId] && item.discountsDetail.length"
              class="item-discount-detail"
              style="padding: 0 8px"
            >
              <div v-for="discount in item.discountsDetail" :key="discount.nanoId">
                <div class="row" style="padding: 4px 0;">
                  <p>{{ discount.name }}</p>
                  <p class="flex-shrink-0">-$ {{ discount.savePrice }}</p>
                </div>
              </div>
            </div>
          </el-collapse-transition>
        </div>
      </div>

      <el-divider style="margin: 12px 0;" />

      <div class="flex flex-col" style="gap: 12px; padding: 0 8px;">
        <!-- 整單折扣 -->
        <div>
          <div class="row" @click="expand.orderDiscount = !expand.orderDiscount">
            <p>整單折扣</p>
            <p class="flex-shrink-0">$ {{ displayData.orderDiscountPrice }}</p>
          </div>
          <el-collapse-transition>
            <div v-if="expand.orderDiscount" class="item-discount-detail">
              <div v-for="discount in displayData.orderDiscount" :key="discount.id">
                <div class="row">
                  <p>{{ discount.name }}</p>
                  <p class="flex-shrink-0">-{{ discount.savePrice }}</p>
                </div>
              </div>
            </div>
          </el-collapse-transition>
        </div>

        <!-- 總折扣 -->
        <div class="row">
          <p>總折扣</p>
          <p class="text-danger flex-shrink-0">-$ {{ displayData.totalDiscountPrice }}</p>
        </div>
      </div>

      <el-divider style="margin: 12px 0;" />

      <div class="row" style="padding: 0 8px;">
        <p class="font-bold text-normal">合計</p>
        <p class="font-medium text-sub flex-shrink-0">$ {{ displayData.orderUnpaidPrice }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { useSalesPOSCreate } from '@/use/useSalesPOS'
import { find, get, map } from 'lodash'

import { computed, defineComponent, onBeforeMount, reactive, ref, set } from 'vue'
export default defineComponent({
  name: 'SalesPOSCheckoutReceipt',
  setup (props) {
    const {
      context, cart, orderUnpaidPrice,
      getAllOrderPaymentsPrice,
      getAllSingleDiscountsPrice,
      getAllOrderDiscountsPrice,
      getAllOrderDiscountsDetail,
    } = useSalesPOSCreate()
    const expand = reactive({
      item: {},
      orderDiscount: true,
    })

    const displayCartItemInfo = computed(() => {
      return map(cart.value, (item) => {
        return {
          nanoId: item.nanoId,
          name: item.name,
          totalPrice: item.totalPrice(),
          discountsDetail: item.getSingleDiscountsDetail(),
        }
      })
    })

    const displayData = computed(() => {
      return {
        orderUnpaidPrice: orderUnpaidPrice.value,
        paymentsPrice: getAllOrderPaymentsPrice(),
        totalDiscountPrice: getAllSingleDiscountsPrice() + getAllOrderDiscountsPrice(),
        orderDiscount: getAllOrderDiscountsDetail(),
        orderDiscountPrice: getAllOrderDiscountsPrice(),
      }
    })

    const initExpand = () => {
      for (const item of cart.value) {
        set(expand.item, item.nanoId, true)
      }
    }

    const onExpand = (nanoId) => {
      expand.item[nanoId] = !expand.item[nanoId]
    }

    onBeforeMount(() => {
      initExpand()
    })

    return { displayData, displayCartItemInfo, expand, onExpand }
  },
})
</script>

<style lang="postcss" scoped>
.row {
  @apply flex justify-between gap-[8px];
  @apply font-medium;
}

.item-row {
  @apply flex justify-between bg-gray-20 gap-[8px];
  @apply py-[4px] px-[8px] rounded-[6px];
  @apply font-medium;
}

.item-discount-detail {
  @apply gap-[6px] pt-[6px];
  @apply flex flex-col;
  @apply text-gray-60 text-sm;
}

::v-deep(.el-divider) {
  @apply my-[12px];
}

.item-detail-container {
  @apply flex flex-col gap-[6px];
  /* @apply max-h-[350px] overflow-y-auto; */
}
</style>
