<template>
  <main v-loading="loading" class="rebate-tab">
    <template v-if="!showPage">
      <p>{{ displayData.hint.unRegister }}</p>
    </template>
    <template v-if="showPage">
      <PointStatistic :memberId="memberId" :loading="loading" :shopPointKey="shopPointKey" />
      <UsePointBlock
        :memberId="memberId"
        :pointBalance="pointWalletBalance"
        :shopPointKey="shopPointKey"
        @refresh="refresh"
      />
      <SectionTitle :title="displayData.tableTitle" hideBtn />
      <BaseTable v-loading="loading" :data="displayTableData">
        <BaseElTableColumn prop="createdAt" :label="displayData.columnText.createdAt" align="center" />
        <BaseElTableColumn prop="type" :label="displayData.columnText.type" align="center" />
        <BaseElTableColumn prop="pointName" :label="displayData.columnText.pointName" align="center" />
        <BaseElTableColumn prop="amount" :label="displayData.columnText.amount" align="center">
          <template slot-scope="scope">
            <p :class="{'text-danger': scope.row.amount < 0}">
              <span>{{ scope.row.amount > 0 ? '+' : '' }}</span>
              <span>{{ scope.row.amount }}</span>
            </p>
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn v-if="useStorePermission" prop="Branch" :label="displayData.columnText.Branch" align="center">
          <template slot-scope="scope">
            {{ scope.row.Branch?.name || "-" }}
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn prop="originMessage" :label="displayData.columnText.originMessage" align="center">
          <template slot-scope="scope">{{ scope.row.originMessage || '-' }}</template>
        </BaseElTableColumn>
      </BaseTable>
      <Pagination
        class="pagination"
        :curPage.sync="tableOptions.page"
        :pageLimit="tableOptions.pageLimit"
        :total="tableOptions.dataCount"
        @pageChange="pageChange"
      />
    </template>
  </main>
</template>

<script>
import { useTable } from '@/use/table'
import { FindShopPoint, FindPointWalletRecord, FindPointWalletRecordCount } from '@/api/shopPoint'
import PointStatistic from './components/PointStatistic.vue'
import { originConfig } from '@/config/point'
import UsePointBlock from './components/UsePointBlock.vue'
import store from '@/store'
import { computed, ref, onMounted, defineComponent, provide } from 'vue'
import { get, map, find } from 'lodash'
import { usePermissions } from '@/use/permissions'
import { useShop } from '@/use/shop'

export default defineComponent({
  components: {
    PointStatistic,
    UsePointBlock,
  },
  props: {
    memberId: {
      type: String,
    },
    pointWalletBalance: {
      type: Object,
      default: () => ({}),
    },
    shopPointKey: {
      type: String,
      required: true,
    },
  },
  setup (props, { emit }) {
    const { shopPointList } = useShop()
    const { pageStartIndex, dateFormat, tableOptions, tableData } = useTable()
    const loading = ref(false)
    const showPage = ref(false)
    const shopId = computed(() => store.getters.shop)
    const { checkAction } = usePermissions()

    const useStorePermission = computed(() => {
      return checkAction('admin.branch.adminUseStore')
    })

    // ?? 當前頁面的點數
    const pageShopPoint = computed(() => {
      const pointData = find(shopPointList.value, { key: props.shopPointKey }) || find(shopPointList.value, { isDefault: true })
      return pointData || { name: '點數', unitName: '點' }
    })

    // ?? 表格顯示資料
    const displayTableData = computed(() => {
      return map(tableData.value, (item) => {
        return {
          ...item,
          pointName: get(find(shopPointList.value, { key: props.shopPointKey }), 'name', '-'),
          createdAt: dateFormat(item.createdAt),
          type: get(originConfig, `${item.origin}.name`),
        }
      })
    })

    // ?? 頁面顯示資訊
    const displayData = computed(() => {
      const data = {
        tableTitle: '點數使用紀錄',
        columnText: {
          createdAt: '建立時間',
          type: '形式',
          pointName: '點數名稱',
          amount: get(pageShopPoint.value, 'name', '點數'),
          Branch: '操作單位',
          originMessage: '備註',
        },
        hint: {
          unRegister: '尚未註冊',
        },
      }
      return data
    })

    // => 取得點數紀錄
    const findPointWalletRecord = async () => {
      const [res, err] = await FindPointWalletRecord({
        shopId: shopId.value,
        memberId: props.memberId,
        start: pageStartIndex.value,
        limit: tableOptions.pageLimit,
        shopPointKey: props.shopPointKey,
      })
      if (err) return (window.$message.error(err))
      tableData.value = res
    }

    // => 取得點數紀錄總數
    const findPointWalletRecordCount = async () => {
      const [res, err] = await FindPointWalletRecordCount({
        shopId: shopId.value,
        memberId: props.memberId,
        shopPointKey: props.shopPointKey,
      })
      if (err) return (window.$message.error(err))
      tableOptions.dataCount = res.count
    }

    // => 當頁碼改變時
    const pageChange = async () => {
      await refresh()
    }

    // => 刷新
    const refresh = async () => {
      loading.value = true
      await findShopPoint()
      if (!showPage.value) return
      await Promise.all([
        findPointWalletRecord(),
        findPointWalletRecordCount(),
      ])
      emit('updatePoint')
      loading.value = false
    }

    // => 取得點數資訊
    const findShopPoint = async () => {
      const [res, err] = await FindShopPoint({ shopId: shopId.value })
      if (err) return (window.$message.error(err))
      if (res.id) showPage.value = true
      else loading.value = false
    }

    provide('pageShopPoint', pageShopPoint)

    onMounted(async () => {
      await refresh()
    })

    return {
      loading,
      showPage,
      displayData,
      displayTableData,
      pageShopPoint,
      useStorePermission,
      refresh,
      pageChange,
      originConfig,
      dateFormat,
      tableOptions,
    }
  },
})

</script>

<style scoped lang="postcss">

</style>
