import { admin2 } from './instance'

/* MemberShopProduct 商品 */
export const GetMemberShopProduct = async ({
  shopId, start,
  limit,
  type,
  name,
  isPointExchange,
}) => {
  return admin2({
    method: 'GET',
    url: `/${shopId}/memberStoreProduct`,
    params: {
      start,
      limit,
      type,
      name,
      isPointExchange,
    },
  })
}
export const GetMemberShopProductCount = async ({
  shopId,
  type,
  name,
  isPointExchange,
}) => {
  return admin2({
    method: 'GET',
    url: `/${shopId}/memberStoreProduct/count`,
    params: {
      type,
      name,
      isPointExchange,
    },
  })
}
export const FindMemberShopProduct = async ({
  shopId,
  id,
}) => {
  return admin2({
    method: 'GET',
    url: `/${shopId}/memberStoreProduct/${id}`,
  })
}
export const CreateMemberShopProduct = async ({
  shopId,
  type,
  walletAmount,
  externalWalletAmount,
  classTicketId,
  pointCardId,
  punchCardId,
  periodicBenefitTemplateId,
  name,
  description,
  notice,
  imageId,
  isPublic,
  isPaymentPromise,
  price,
  useCashbackRate,
  order,
  promisePaymentConfigId,
}) => {
  return await admin2({
    method: 'POST',
    url: `/${shopId}/memberStoreProduct`,
    data: {
      type,
      walletAmount,
      externalWalletAmount,
      classTicketId,
      pointCardId,
      punchCardId,
      periodicBenefitTemplateId,
      name,
      description,
      notice,
      imageId,
      isPublic,
      isPaymentPromise,
      price,
      useCashbackRate,
      order,
      promisePaymentConfigId,
    },
  })
}
export const UpdateMemberShopProduct = async ({
  shopId,
  id,
  name,
  description,
  notice,
  imageId,
  isPublic,
  isPaymentPromise,
  price,
  useCashbackRate,
  order,
  walletAmount,
  externalWalletAmount,
}) => {
  return await admin2({
    method: 'PUT',
    url: `/${shopId}/memberStoreProduct/${id}`,
    data: {
      name,
      description,
      notice,
      imageId,
      isPublic,
      isPaymentPromise,
      price,
      useCashbackRate,
      order,
      walletAmount,
      externalWalletAmount,
    },
  })
}

export const DeleteMemberShopProduct = async ({
  shopId,
  id,
}) => {
  return await admin2({
    method: 'DELETE',
    url: `/${shopId}/memberStoreProduct/${id}`,
  })
}

/* MemberShopOrder 訂單 */
export const GetMemberShopOrder = async ({
  shopId,
  start,
  limit,
  MemberId,
  code,
  status,
  paymentStatus,
  isPointExchange,
  createdAtStart,
  createdAtEnd,
  includeCustomFlowRecord,
  customFlowNodeId,
  shopPointKey,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/memberStoreOrder`,
    params: {
      start,
      limit,
      MemberId,
      code,
      status,
      paymentStatus,
      isPointExchange,
      createdAtStart,
      createdAtEnd,
      includeCustomFlowRecord,
      customFlowNodeId,
      shopPointKey,
    },
  })
}
export const GetMemberShopOrderCount = async ({
  shopId,
  MemberId,
  code,
  status,
  paymentStatus,
  isPointExchange,
  createdAtStart,
  createdAtEnd,
  customFlowNodeId,
  shopPointKey,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/memberStoreOrder/count`,
    params: {
      MemberId,
      code,
      status,
      paymentStatus,
      isPointExchange,
      createdAtStart,
      createdAtEnd,
      customFlowNodeId,
      shopPointKey,
    },
  })
}
export const FindMemberShopOrder = async ({
  shopId,
  id,
  includeCustomFlowRecord,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/memberStoreOrder/${id}`,
    params: {
      includeCustomFlowRecord,
    },
  })
}
export const FindMemberShopOrderTransitionCodes = async ({
  shopId,
  id,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/memberStoreOrder/${id}/transitionCodes`,
  })
}
export const FindMemberShopOrderLogs = async ({
  shopId,
  id,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/memberStoreOrder/${id}/logs`,
  })
}
export const FindMemberShopOrderProductDetail = async ({
  shopId,
  id,
  memberStoreOrderProductId,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/memberStoreOrder/${id}/orderProduct/${memberStoreOrderProductId}/view`,
  })
}

export const CancelMemberShopOrder = async ({
  shopId,
  id,
}) => {
  return await admin2({
    method: 'PUT',
    url: `/${shopId}/memberStoreOrder/${id}/cancel`,
  })
}
export const MemberShopOrderRefund = async ({
  shopId,
  id,
}) => {
  return await admin2({
    method: 'POST',
    url: `/${shopId}/memberStoreOrder/${id}/refundPayment`,
  })
}
export const MemberShopOrderInvalidInvoice = async ({ shopId, id }) => {
  return await admin2({
    method: 'POST',
    url: `/${shopId}/memberStoreOrder/${id}/invalidInvoice`,
  })
}
export const MemberShopOrderIssueInvoice = async ({ shopId, id }) => {
  return await admin2({
    method: 'POST',
    url: `/${shopId}/memberStoreOrder/${id}/issueInvoice`,
  })
}
export const MemberShopOrderAllowanceAllInvoice = async ({ shopId, id }) => {
  return await admin2({
    method: 'POST',
    url: `/${shopId}/memberStoreOrder/${id}/allowanceAllInvoice`,
  })
}
export const MemberShopOrderRecycleProduct = async ({ shopId, orderId, id }) => {
  return await admin2({
    method: 'POST',
    url: `/${shopId}/memberStoreOrder/${orderId}/product/${id}/recycle`,
  })
}
export const MemberShopOrderSendProduct = async ({ shopId, orderId, id }) => {
  return await admin2({
    method: 'POST',
    url: `/${shopId}/memberStoreOrder/${orderId}/product/${id}/send`,
  })
}
export const MemberShopOrderCanelPayment = async ({ shopId, id }) => {
  return await admin2({
    method: 'POST',
    url: `/${shopId}/memberStoreOrder/${id}/cancelPayment`,
  })
}

/* MemberShopGiveaway 優惠活動 */
export const GetMemberShopGiveaway = async ({
  shopId,
  start,
  limit,
  name,
  status,
}) => {
  return admin2({
    method: 'GET',
    url: `/${shopId}/memberStorePromotion`,
    params: {
      start,
      limit,
      name,
      status,
    },
  })
}
export const GetMemberShopGiveawayCount = async ({
  shopId,
  name,
  status,
}) => {
  return admin2({
    method: 'GET',
    url: `/${shopId}/memberStorePromotion/count`,
    params: {
      name,
      status,
    },
  })
}
export const FindMemberShopGiveaway = async ({
  shopId,
  id,
}) => {
  return admin2({
    method: 'GET',
    url: `/${shopId}/memberStorePromotion/${id}`,
  })
}
export const CreateMemberShopGiveaway = async ({
  shopId,
  name,
  startAt,
  endAt,
  targetProductIds,
  conditions,
}) => {
  return await admin2({
    method: 'POST',
    url: `/${shopId}/memberStorePromotion`,
    data: {
      name,
      startAt,
      endAt,
      targetProductIds,
      conditions,
    },
  })
}
export const UpdateMemberShopGiveaway = async ({
  shopId,
  id,
  name,
  startAt,
  endAt,
  targetProductIds,
  conditions,
}) => {
  return await admin2({
    method: 'PUT',
    url: `/${shopId}/memberStorePromotion/${id}`,
    data: {
      name,
      startAt,
      endAt,
      targetProductIds,
      conditions,
    },
  })
}
export const DeleteMemberShopGiveaway = async ({
  shopId,
  id,
}) => {
  return await admin2({
    method: 'DELETE',
    url: `/${shopId}/memberStorePromotion/${id}`,
  })
}

/* MemberShopPayments 付款方式 */
export const GetMemberShopPaymentConfig = async ({
  shopId,
  start,
  limit,
  isOnline,
}) => {
  return admin2({
    method: 'GET',
    url: `/${shopId}/memberStorePaymentConfig`,
    params: {
      start,
      limit,
      isOnline,
    },
  })
}
export const GetMemberShopPaymentConfigCount = async ({
  shopId,
  isOnline,
}) => {
  return admin2({
    method: 'GET',
    url: `/${shopId}/memberStorePaymentConfig/count`,
    params: {
      isOnline,
    },
  })
}
export const FindMemberShopPaymentConfig = async ({
  shopId,
  id,
}) => {
  return admin2({
    method: 'GET',
    url: `/${shopId}/memberStorePaymentConfig/${id}`,
  })
}
export const CreateMemberShopPaymentConfig = async ({
  shopId,
  name,
  enable,
  limitProductType,
  type,
  paymentConfig,
  isPaymentPromise,
  paymentPromiseVendor,
  promisePeriodDays,
  promiseConfig,
}) => {
  return await admin2({
    method: 'POST',
    url: `/${shopId}/memberStorePaymentConfig`,
    data: {
      name,
      enable,
      limitProductType,
      type,
      paymentConfig,
      isPaymentPromise,
      paymentPromiseVendor,
      promisePeriodDays,
      promiseConfig,
    },
  })
}
export const UpdateMemberShopPaymentConfig = async ({
  shopId,
  id,
  name,
  enable,
}) => {
  return await admin2({
    method: 'PUT',
    url: `/${shopId}/memberStorePaymentConfig/${id}`,
    data: {
      name,
      enable,
    },
  })
}
export const DeleteMemberShopPaymentConfig = async ({
  shopId,
  id,
}) => {
  return await admin2({
    method: 'DELETE',
    url: `/${shopId}/memberStorePaymentConfig/${id}`,
  })
}

/* MemberShopInvoice 發票 */
export const GetMemberShopInvoiceConfig = async ({ shopId }) => {
  return admin2({
    method: 'GET',
    url: `/${shopId}/memberStoreInvoiceConfig`,
  })
}
export const UpdateMemberShopInvoiceConfig = async ({
  shopId,
  enable,
  platform,
  invoiceApiConfig,
}) => {
  return admin2({
    method: 'PUT',
    url: `/${shopId}/memberStoreInvoiceConfig`,
    data: {
      enable,
      platform,
      invoiceApiConfig,
    },
  })
}

/* 點數兌換 */
export const CreatePointExchangeProduct = async ({
  shopId,
  type,
  classTicket: classTicketId,
  couponId,
  punchCardId,
  pointCardId,
  cashbackAmount,
  name,
  description,
  notice,
  imageId,
  isPublic,
  isPaymentPromise,
  price,
  pointExchangeAmount,
  externalPointAmount,
  walletAmount,
  order,
  promisePaymentConfigId,
  shopPointKey,
}) => {
  return admin2({
    method: 'POST',
    url: `/${shopId}/memberStoreProduct/createByPointExchange`,
    data: {
      type,
      classTicketId,
      punchCardId,
      pointCardId,
      cashbackAmount,
      name,
      description,
      notice,
      imageId,
      isPublic,
      isPaymentPromise,
      price,
      pointExchangeAmount,
      externalPointAmount,
      walletAmount,
      order,
      couponId,
      promisePaymentConfigId,
      shopPointKey,
    },
  })
}

export const UpdateMemberShopProductByPointExchange = async ({
  shopId,
  id,
  name,
  description,
  notice,
  imageId,
  isPublic,
  isPaymentPromise,
  price,
  pointExchangeAmount,
  externalPointAmount,
  cashbackAmount,
  order,
}) => {
  return await admin2({
    method: 'PUT',
    url: `/${shopId}/memberStoreProduct/${id}/updateByPointExchange`,
    data: {
      name,
      description,
      notice,
      imageId,
      isPublic,
      isPaymentPromise,
      price,
      pointExchangeAmount,
      externalPointAmount,
      cashbackAmount,
      order,
    },
  })
}

export const GetRewardActivity = async ({
  shopId,
  start,
  limit,
  source,
  rewardType,
  name,
  enabled,
}) => {
  return admin2({
    method: 'GET',
    url: `/${shopId}/rewardActivity`,
    params: {
      start,
      limit,
      source,
      rewardType,
      name,
      enabled,
    },
  })
}

export const GetRewardActivityCount = async ({
  shopId,
  source,
  rewardType,
  name,
  enabled,
}) => {
  return admin2({
    method: 'GET',
    url: `/${shopId}/rewardActivity/count`,
    params: {
      source,
      rewardType,
      name,
      enabled,
    },
  })
}

export const DeleteMemberShopRewardActivity = async ({
  shopId,
  id,
}) => {
  return admin2({
    method: 'DELETE',
    url: `/${shopId}/rewardActivity/${id}`,
  })
}

// 會員商城公開頁設定
export const GetMemberShopPublicPageConfig = async ({
  shopId,
}) => {
  return admin2({
    method: 'GET',
    url: `/${shopId}/memberStorePublicPageConfig`,
  })
}

// 更新會員商城公開頁設定
export const UpdateMemberShopPublicPageConfig = async ({
  shopId,
  name,
  address,
  phone,
  shopIdentifier,
}) => {
  return admin2({
    method: 'PUT',
    url: `/${shopId}/memberStorePublicPageConfig`,
    data: {
      name,
      address,
      phone,
      shopIdentifier,
    },
  })
}

export const GetPaymentTrustOrderPreRefundDetail = async ({
  shopId,
  id,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/paymentTrustOrder/${id}/preRefundDetail`,
  })
}

export const RefundPaymentTrustOrder = async ({
  shopId,
  id,
}) => {
  return await admin2({
    method: 'POST',
    url: `/${shopId}/paymentTrustOrder/${id}/refund`,
  })
}

export const GetMemberStorePointConfig = async ({ shopId }) => {
  return admin2({
    method: 'GET',
    url: `/${shopId}/memberStorePointConfig`,
  })
}

export const UpdateMemberStorePointConfig = async ({
  shopId,
  pointExchangeDisplayName,
  pointExchangeRecordDisplayName,
}) => {
  return admin2({
    method: 'PUT',
    url: `/${shopId}/memberStorePointConfig`,
    data: {
      pointExchangeDisplayName,
      pointExchangeRecordDisplayName,
    },
  })
}
