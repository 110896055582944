<template>
  <el-dialog
    :title="title"
    :visible="true"
    width="610px"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <section class="flex flex-col">
      <ResourceUnitSelect class="mb-3" :model.sync="unit" @change="findResourceUnit" />
      <el-date-picker
        v-model="date"
        editable
        type="date"
        :placeholder="$t('resourceTimeSpace.search.date.placeholder')"
        @change="findResourceUnit"
      />
    </section>

    <div v-if="periods.length" class="period-list" style="gap: 10px;">
      <div
        v-for="(p, index) in periods"
        :key="p.start"
        class="period"
        :class="{
          'status-available': p.status === 'available',
          'status-booking': p.status === 'booking',
          'status-ban': p.status === 'ban',
        }"
        type="info"
        @click="periodClick(p, index)"
      >
        <p>{{ timeFormat(p.start) }}</p>
        <p>{{ statusFormat(p.status) }}</p>
      </div>
    </div>
    <div v-if="date && unit && !periods.length">
      {{ $t('shiftCalendar.dialog.empty.text')}}
    </div>

    <span slot="footer" class="dialog-footer">
      <div>
        <BaseElButton plain @click="$emit('close')">{{ $t('common.button.cancel.text')}}</BaseElButton>
        <BaseElButton type="primary" @click="updatePeriods">{{ $t('common.button.save.text')}}</BaseElButton>
      </div>
    </span>
  </el-dialog>
</template>

<script>
import { defineComponent, onMounted, ref, computed } from 'vue'
import ResourceUnitSelect from '@/components/Select/ResourceUnitSelect.vue'
// import { GetPeriods, UpdatePeriod } from '@/api/togglePeriod'
import { GetPeriods, UpdatePeriods } from '@/api/resource'
import dayjs from '@/lib/dayjs'
import store from '@/store'
import { getDay } from '@/utils/date'
import { get, set } from 'lodash'
import { i18n } from '@/plugins/i18n/i18n'

export default defineComponent({
  name: 'TogglePeriodDialog',
  components: { ResourceUnitSelect },
  props: {
    title: String,
    selectDate: [String, Date],
    selectUnit: [String, Object],
  },
  setup (props, { emit }) {
    const date = ref('')
    const unit = ref('')
    const config = ref(null)
    const available = ref([])
    const periods = ref([])
    const interval = ref(0)
    const shopId = computed(() => get(store.getters, 'shop'))

    const findResourceUnit = async () => {
      if (!get(unit.value, 'id')) return
      if (date.value === '') return
      const { start, end } = getDay(date.value)
      const [res, err] = await GetPeriods({
        shopId: shopId.value,
        id: get(unit.value, 'id'),
        start,
        end,
      })
      periods.value = get(res, 'times')
      interval.value = get(res, 'timeUnit')
      if (err) window.$message.error(err)
    }

    const periodClick = (period, index) => {
      console.log(period)
      const status = period.status
      if (status === 'available') {
        set(periods.value[index], 'status', 'ban')
      }
      if (status === 'ban') {
        set(periods.value[index], 'status', 'available')
      }
    }

    const statusFormat = (status) => {
      const statusDict = {
        available: i18n.t('shiftCalendar.status.available.text'),
        booking: i18n.t('shiftCalendar.status.booking.text'),
        ban: i18n.t('shiftCalendar.status.ban.text'),
      }
      return statusDict[status]
    }

    const timeFormat = (dateTime) => {
      return dayjs(dateTime).format('HH:mm')
    }

    const updatePeriods = async () => {
      const tempTimes = periods.value.map(item => ({ ...item, availableVolume: undefined }))
      const filteredItems = tempTimes.filter(item => item.status !== 'booking')
      const [res, err] = await UpdatePeriods({
        shopId: shopId.value,
        id: get(unit.value, 'id'),
        operates: filteredItems,
      })
      if (err) window.$message.error(err)
      if (res) {
        window.$message.success(i18n.t('common.message.updateSuccess'))
        emit('updated')
        // emit('close')
      }
    }

    onMounted(async () => {
      if (props.selectDate) date.value = new Date(props.selectDate)
      if (props.selectUnit) {
        unit.value = { id: props.selectUnit }
        await findResourceUnit()
      }
    })
    return {
      date,
      unit,
      config,
      available,
      periods,
      interval,
      shopId,
      periodClick,
      statusFormat,
      timeFormat,
      updatePeriods,
      findResourceUnit,
    }
  },
})
</script>

<style scoped lang="scss">
::v-deep .el-date-editor{
  @apply w-full;
}
.period {
  border: solid transparent 1px;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
}

.period-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.status-available {
  color: white;
  background: #00CD20;
}

.status-ban {
  color: var(--gray-60);
  background: var(--gray-40);
}

.status-booking {
  color: white;
  background: var(--gray-80);
}
</style>
