<template>
  <div class="flex flex-col" style="gap: 12px;">
    <p class="section-label">堂票</p>
    <div class="grid grid-cols-2" style="gap: 12px">
      <div
        class="classTicket-discount-item"
        :class="{ isSelected, isDisabled }"
        @click="onSelect"
      >
        堂票
      </div>
    </div>

    <SalesPOSClassTicketDiscountSelectModal
      v-if="showModal"
      :multiple="false"
      scope="singleDiscount"
      :availableIds="availablesClassTickets"
      @close="showModal = false"
      @confirm="onConfirm"
    />
  </div>
</template>

<script>
import SalesPOSClassTicketDiscountSelectModal from '@/components/SalesPOSClassTicketDiscountSelectModal.vue'
import { useSalesPOSCreate } from '@/use/useSalesPOS'
import { computed, defineComponent, ref } from 'vue'
import { find, get } from 'lodash'

export default defineComponent({
  name: 'SalesPOSClassTicketDiscountList',
  components: {
    SalesPOSClassTicketDiscountSelectModal,
  },
  setup (props, { emit }) {
    const { getActiveCartItem } = useSalesPOSCreate()
    const showModal = ref(false)
    const isSelected = computed(() => {
      const activeCartItem = getActiveCartItem()
      if (!activeCartItem) return false
      return Boolean(find(activeCartItem.discounts, { id: 'classTicket' }))
    })

    const isDisabled = computed(() => {
      if (!isSelected.value) {
        const activeCartItem = getActiveCartItem()
        if (!activeCartItem) return true
        return activeCartItem.getDiscountRemainingPrice() <= 0
      }
      return false
    })

    const availablesClassTickets = computed(() => {
      const activeCartItem = getActiveCartItem()
      const discountClassTicketIds = get(activeCartItem, 'appointmentData.discountClassTicketIds', [])
      return discountClassTicketIds
    })

    const onSelect = () => {
      if (isDisabled.value) return
      const activeCartItem = getActiveCartItem()
      if (!activeCartItem) return
      if (isSelected.value) {
        activeCartItem.removeSingleDiscount({ id: 'classTicket' })
        return
      }
      showModal.value = true
    }

    const onConfirm = (data) => {
      const activeCartItem = getActiveCartItem()
      if (!activeCartItem) return

      activeCartItem.addSingleDiscount({
        id: 'classTicket',
        name: data.name,
        mustSpendSetting: {
          type: 'classTicket',
          classTicketRecordId: data.id,
          amount: 1,
        },
        discountSource: 'noSource',
        cashAmount: activeCartItem.getDiscountRemainingPrice(),
        type: 'classTicket',
      })

      showModal.value = false
    }

    return { isSelected, showModal, onSelect, onConfirm, isDisabled, availablesClassTickets }
  },
})
</script>

<style lang="postcss" scoped>
.section-label {
  @apply text-primary-100 text-normal font-bold;
}
.classTicket-discount-item {
  @apply bg-primary-10 rounded-[6px] h-[88px] w-full;
  @apply flex flex-col items-center justify-center text-center;
  @apply select-none cursor-pointer;
  @apply border-[2px] border-transparent;
}
.isSelected {
  @apply border-primary-100 border-[2px] bg-white text-primary-100;
}
.isDisabled {
  @apply border-gray-20 bg-gray-20 cursor-not-allowed;
}
</style>
