<template>
  <GrayBlockContainer>
    <p class="section-title">發票資訊</p>
    <BaseElFormItem label="選擇發票類型" prop="invoice.type">
      <BaseElSelect v-model="context.invoice.type">
        <BaseElSelectOption
          v-for="option in displayInvoiceTypeOptions"
          :key="option.value"
          :value="option.value"
          :label="option.label"
        />
      </BaseElSelect>
    </BaseElFormItem>

    <div v-if="showControl.B2BInfo">
      <BaseElFormItem label="公司行號抬頭" prop="invoice.companyTitle">
        <BaseElInput v-model="context.invoice.companyTitle" placeholder="請輸入公司行號抬頭" clearable />
      </BaseElFormItem>
      <BaseElFormItem label="統一編號" prop="invoice.taxId">
        <BaseElInput v-model="context.invoice.taxId" placeholder="請輸入統一編號" clearable />
      </BaseElFormItem>
    </div>

    <BaseElFormItem label="電子信箱" prop="invoice.email">
      <BaseElInput v-model="context.invoice.email" type="email" name="email" placeholder="請輸入Email" clearable />
    </BaseElFormItem>
  </GrayBlockContainer>
</template>

<script>
import GrayBlockContainer from '@/components/Container/GrayBlockContainer.vue'
import { useCreateAappointment } from '@/use/useCreateAppointment'
import { computed, defineComponent } from 'vue'

export default defineComponent({
  name: 'AppointmentInvoiceInfoBlock',
  components: {
    GrayBlockContainer,
  },
  setup (props, { emit }) {
    const { context } = useCreateAappointment()
    const showControl = computed(() => {
      const controls = {
        B2BInfo: false,
      }
      if (context.invoice.type === 'B') {
        controls.B2BInfo = true
      }
      return controls
    })

    const displayInvoiceTypeOptions = computed(() => {
      const options = [
        { value: 'C', label: '二聯式發票' },
        { value: 'B', label: '三聯式發票' },
      ]

      return options
    })

    return { context, showControl, displayInvoiceTypeOptions }
  },
})

</script>

<style scoped lang="postcss">
.section-title {
  @apply font-bold text-primary-100 text-[18px] mb-[24px];
}
</style>
