import { map, get } from 'lodash'

export const useReservationCheckout = () => {
  const feeTarget = (configData) => {
    const config = get(configData, 'freeMemberConfigView')
    const enabledExclude = get(configData, 'freeMemberConfig.enableExcludeTag')
    const enabledInclude = get(configData, 'freeMemberConfig.enableIncludeTag')

    // 因為freeMemberConfig定義的是免收費，所以在意義上是相反的，因此 include 與 exclude 是相反的
    const includeTags = get(config, 'includeTags', [])
    const excludeTags = get(config, 'excludeTags', [])

    let label
    if (!enabledInclude && !enabledExclude) label = '所有會員'

    if (!enabledExclude && enabledInclude && includeTags.length) {
      const include = map(includeTags, 'name')
      label = `進階標籤會員 排除：${include.join('、')}`
    }

    if (enabledExclude && !enabledInclude && excludeTags.length) {
      const exclude = map(excludeTags, 'name')
      label = `進階標籤會員 包含：${exclude.join('、')}`
    }

    if (enabledExclude && enabledInclude) {
      const include = map(includeTags, 'name')
      const exclude = map(excludeTags, 'name')
      if (include.length && exclude.length) {
        label = `進階標籤會員 包含：${exclude.join('、')}｜排除：${include.join('、')}`
      }
      if (!include.length && exclude.length) {
        label = `進階標籤會員 包含：${exclude.join('、')}`
      }
      if (include.length && !exclude.length) {
        label = `進階標籤會員 排除：${include.join('、')}`
      }
    }
    return label
  }

  return {
    feeTarget,
  }
}
