<template>
  <BaseDialog
    title="第三方儲值金顯示名稱設定"
    :btn1-loading="loading"
    @close="$emit('close')"
    @confirm="onConfirm"
  >
    <BaseElForm ref="formRef" :model="formData" :rules="formRules" label-position="top">
      <BaseElFormItem prop="origin">
        <template #label>
          <FormItemTooltipLabel label="儲值金來源" :tooltip-width="200">
            請選擇第三方儲值金的來源，請注意，若無選擇，在相關顯示的頁面上將預設顯示為「第三方儲值金」。
          </FormItemTooltipLabel>
        </template>
        <BaseElSelect v-model="formData.origin" @change="onOriginChange" placeholder="請選擇" clearable>
          <BaseElSelectOption
            v-for="item in externalWalletSourceConfig"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </BaseElSelect>
      </BaseElFormItem>
      <BaseElFormItem prop="name">
        <template #label>
          <FormItemTooltipLabel label="顯示名稱" :tooltip-width="200">
            您可以自行設定會員餘額在後台及客人端顯示的文字，至多6個字。
          </FormItemTooltipLabel>
        </template>
        <BaseElInput v-model="formData.name" :maxlength="6" show-word-limit />
      </BaseElFormItem>
      <BaseElFormItem prop="displayDepositRecord" label="是否隱藏儲值紀錄">
        <BaseElSwitch
          v-model="formData.displayDepositRecord"
          inactive-text="隱藏"
          active-text="顯示"
        />
      </BaseElFormItem>
    </BaseElForm>
  </BaseDialog>
</template>

<script>
import { defineComponent, computed, onMounted } from 'vue'
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import { useBaseForm, mappingSyncFormData } from '@/use/useForm'
import { noEmptyRules } from '@/validation'
import FormItemTooltipLabel from '@/components/Form/FormItemTooltipLabel.vue'
import notifyMessage from '@/config/notifyMessage'
import { UpdateExternalWalletConfig } from '@/api/externalWallet'
import { useShop } from '@/use/shop'
import { externalWalletSourceConfig } from '@/config/externalWallet'

export default defineComponent({
  name: 'EditExternalWalletDisplaySettingsModal',
  components: { BaseDialog, FormItemTooltipLabel },
  props: {
    configData: {
      type: Object,
      default: () => ({}),
      required: true,
    },
  },
  setup (props, { emit }) {
    const { shopId } = useShop()
    const { loading, formRef, initFormData, formData, checkForm } = useBaseForm()

    initFormData({
      origin: null,
      name: null,
      displayDepositRecord: true,
    })

    const formRules = computed(() => {
      return {
        // origin: [noEmptyRules()],
        name: [noEmptyRules()],
      }
    })
    const onOriginChange = (val) => {
      if (val === 'posPal') {
        formData.name = '會員餘額'
      }
    }
    const onConfirm = async () => {
      if (loading.value) return
      loading.value = true
      if (!await checkForm(formRef.value)) {
        loading.value = false
        return
      }

      const [, err] = await UpdateExternalWalletConfig({
        shopId: shopId.value,
        source: formData.origin || null,
        displayName: formData.name,
        displayDepositRecord: formData.displayDepositRecord,
      })
      loading.value = false
      if (err) {
        window.$message.error(err)
        return
      }
      window.$message.success(notifyMessage.updateSuccess)
      emit('refresh')
      emit('close')
    }

    onMounted(() => {
      mappingSyncFormData({
        source: 'origin',
        displayName: 'name',
      }, props.configData, formData)
    })

    return { formRef, initFormData, formData, formRules, loading, onConfirm, onOriginChange, externalWalletSourceConfig }
  },
})
</script>

<style lang="postcss" scoped>

</style>
