<template>
  <div class="pagination-wrapper">
    <el-pagination
      class="pagination"
      layout="prev, pager, next"
      v-bind="$attrs"
      :page-size="pageLimit"
      :total="total"
      :current-page.sync="curPageModel"
      @current-change="$emit('pageChange', $event)"
    />
  </div>
</template>

<script>
export default {
  name: 'Pagination',
  props: {
    curPage: {
      type: [Number, String],
      default: 1,
    },
    pageLimit: {
      type: [Number, String],
      default: 0,
    },
    total: {
      type: [Number, String],
      default: 0,
    },
  },

  computed: {
    curPageModel: {
      get () {
        return this.curPage
      },
      set (data) {
        this.$emit('update:curPage', data)
      },
    },
  },

  mounted () {
    this.curPageModel = JSON.parse(JSON.stringify(this.curPage))
  },

}
</script>

<style lang="postcss" scoped>
.pagination-wrapper {
  @apply flex justify-center text-gray-100 py-[44px];
}

.pagination {
  @apply flex gap-[12px];
}

::v-deep .el-pagination button {
  @apply bg-transparent text-gray-100;
}

::v-deep .el-pagination button:hover:enabled {
  @apply bg-primary-100 text-white rounded-sub;
}

::v-deep .el-pagination .number {
  @apply bg-transparent text-gray-100;
}

::v-deep .el-pagination .number:hover {
  @apply bg-primary-100 text-white rounded-sub;
}

::v-deep .el-pagination .number.active {
  @apply bg-primary-100 text-white rounded-sub;
}

::v-deep .el-pager {
  @apply flex gap-[12px];
}
/* ::v-deep .el-pager li {
  @apply bg-transparent;
} */
::v-deep .el-pager li.btn-quicknext {
  @apply bg-transparent;
}
::v-deep .el-pager li.btn-quickprev {
  @apply bg-transparent;
}

::v-deep .el-pager li:hover {
  @apply text-primary-100;
}

::v-deep .el-pagination button:disabled {
  @apply text-gray-40;
}

</style>
