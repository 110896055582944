import Layout from '@/layout/index.vue'
import { t } from '@/plugins/i18n/i18n'
export default [
  {
    path: '/members',
    component: Layout,
    alwaysShow: true,
    isGroup: true,
    name: 'Members',
    meta: {
      title: '會員模組',
    },
    children: [
      {
        path: 'members-manage',
        name: 'MembersManage',
        component: () => import('@/views/Members/MembersManage'),
        meta: {
          title: '會員管理',
          action: 'admin.member.page',
          bobPageAction: 'PAGE_VIEW_DASH_SHOP_MEMBER_LIST',
        },
      },
      {
        path: 'member-profile/:id/:tab?',
        name: 'MemberProfile',
        hidden: true,
        component: () => import('@/views/Members/MemberProfile'),
        meta: {
          title: '會員檢視',
          action: 'admin.member.page',
        },
      },
      {
        path: 'member-create',
        name: 'MemberCreate',
        hidden: true,
        component: () =>
          import('@/views/Members/MemberCreate/MemberCreate.vue'),
        meta: {
          title: '新增會員',
          action: 'admin.member.page',
        },
      },
      // 會員階級
      {
        path: 'member-level/setting',
        name: 'MemberLevelSetting',
        component: () =>
          import('@/views/Members/MemberLevelSetting/MemberLevelSetting.vue'),
        meta: {
          title: '會員等級設定',
          action: 'admin.member.page',
        },
      },
      {
        path: 'member-level/edit/:id?',
        name: 'MemberLevelEdit',
        hidden: true,
        component: () =>
          import('@/views/Members/MemberLevelEdit/MemberLevelEdit.vue'),
        meta: {
          title: '編輯會員等級設定',
          action: 'admin.member.page',
        },
      },
      {
        path: 'member-card-setting',
        name: 'MemberCardSetting',
        component: () =>
          import('@/views/MemberCardSetting/MemberCardSetting.vue'),
        meta: {
          title: '會員卡面設定',
          action: 'admin.member.page',
        },
      },
      {
        path: 'member-card-edit/:styleName?/:id?',
        name: 'MemberCardEdit',
        hidden: true,
        component: () =>
          import('@/views/MemberCardSetting/components/MemberCardEdit.vue'),
        meta: {
          title: '會員卡面詳情',
          action: 'admin.member.page',
        },
      },
    ],
  },
]
