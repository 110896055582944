<template>
  <el-drawer
    :visible="true"
    :with-header="false"
    direction="rtl"
    :size="592"
    :show-close="false"
    :wrapperClosable="false"
    @close="$emit('close-sales-record-dialog')"
  >
    <div class="record-container">
      <div v-if="sellAtDate" class="record__title">{{ sellAtDate }} 銷售紀錄</div>
      <div v-else class="record__title">{{ recordDateFromat() }} 銷售紀錄</div>
      <div v-if="selectedTarget" class="record__customer">
        {{
          selectedTarget.Member && selectedTarget.Member.UserInfo
            ? selectedTarget.Member.UserInfo.name
            : '非會員'
        }}
      </div>
      <div
        v-if="selectedTarget && selectedTarget.SalesRecordItems"
        class="record__list"
      >
        <div
          v-for="item in salesRecordCollectionList"
          :key="item.unitId"
          class="record__list__item"
        >
          <div class="item__name">{{ item.unitName }}</div>
          <div
            v-for="subItem in item.list"
            :key="subItem.id"
            class="item__unit item"
          >
            <span class="item__title">{{ subItem.name }}</span>
            <span class="item__price">{{ subItem.quantity }} x {{ subItem.price }}</span>
          </div>
        </div>
        <!-- Total Items Price -->
        <el-divider />
        <div class="items-price item">
          <span class="item__title font-bold">總額</span>
          <span
            class="item__price"
            style="font-weight: 700"
          >$ {{ selectedTarget.totalItemsPrice }}</span>
        </div>
        <!-- Discount -->
        <template v-if="selectedTarget && selectedTarget.SalesRecordDiscounts">
          <div
            v-for="subItem in selectedTarget.SalesRecordDiscounts"
            :key="subItem.id"
            class="discount item"
          >
            <span class="item__title">{{ subItem.name }}</span>
            <span class="item__price">- $ {{ subItem.savePrice }}</span>
          </div>
        </template>
        <el-divider />
        <!-- Total Price -->
        <div class="items-price item">
          <span class="item__title font-bold">已付金額</span>
          <span
            class="item__price"
            style="font-weight: 700"
          >$ {{ totalPaymentsAmount }}</span>
        </div>
        <template v-if="selectedTarget">
          <div
            v-for="payment in selectedTarget.SalesRecordPayments"
            :key="payment.id"
            class="total-price item"
          >
            <span class="item__title">{{
              `使用${paymentTrans(payment.type)}`
            }}</span>
            <span class="item__price">$ {{ payment.amount }}</span>
          </div>
        </template>
        <el-divider />
        <div v-if="selectedTarget.Member && hasReward" class="discount">
          <div class="item__title font-bold item">本次可獲得回饋</div>
          <div
            v-if="useShopRebate && selectedTarget.totalAddCashback"
            class="discount item"
          >
            <span class="item__title">回饋金</span>
            <span class="item__price">$ {{ selectedTarget.totalAddCashback }}</span>
          </div>
          <div v-if="useShopRebate && totalRebate" class="discount item">
            <span class="item__title">檔期回饋金</span>
            <span class="item__price">$ {{ totalRebate }}</span>
          </div>
          <div v-if="useShopPoint && totalPoint" class="discount item">
            <span class="item__title">點數</span>
            <span class="item__price">{{ totalPoint }} 點</span>
          </div>
        </div>
        <!-- Note -->
        <div v-if="selectedTarget" class="note item">
          <span class="item__title">備註</span>
          <span v-if="selectedTarget.note" class="item__note">{{
            selectedTarget.note
          }}</span>
          <span class="item__button" @click="openRecordNoteDialog">
            {{ selectedTarget.note ? '編輯' : '填寫備註' }}
          </span>
        </div>
        <div v-if="useStorePermission" class="unit-block">
          <el-divider v-if="selectedTarget.Branch" />
          <div v-if="selectedTarget.Branch" class="flex justify-between mb-2">
            <div class="title">新增紀錄操作單位</div>
            <div class="title">{{ `${selectedTarget.Branch.name} 門市` }}</div>
          </div>
          <!-- <div class="flex justify-between mb-2">
            <div class="title">作廢紀錄操作單位</div>
            <div class="title">XX門市</div>
          </div> -->
        </div>
        <div v-if="createOrigin">
          <el-divider />
          <div class="item">
            <span class="item__title">建立管道</span>
            <span class="item__price">{{ createOrigin }}</span>
          </div>
        </div>
        <div>
          <div class="item">
            <span class="item__title">銷售紀錄建立時間</span>
            <span class="item__price">{{ recordDateFromat() }}</span>
          </div>
        </div>
      </div>
      <div>
        <div class="item">
          <span class="item__title">銷售紀錄建立者</span>
          <span class="item__price">{{ get(selectedTarget, 'Creator.name', '-') }}</span>
        </div>
      </div>
    </div>
    <div class="record--button">
      <BaseElButton plain @click="$emit('close-sales-record-dialog')">
        返回
      </BaseElButton>
      <BaseElButton
        v-if="selectedTarget"
        :type="selectedTarget.status === 'invalid' ? 'info' : 'primary'"
        :disabled="selectedTarget.status === 'invalid'"
        @click="$emit('show-password-dialog')"
      >
        作廢
      </BaseElButton>
    </div>
  </el-drawer>
</template>

<script>
import { mapGetters } from 'vuex'
import dayjs from '@/lib/dayjs'
import { GetSalesPaymentTypes } from '@/api/sales'
import { checkUserFeature } from '@/store/modules/permission'
import { get, find } from 'lodash'
import { formatDate } from '@/utils/date'

export default {
  name: 'SalesRecordDialog',
  props: {
    selectedTarget: {
      type: Object,
      required: true,
      default: () => {},
    },
    useShopRebate: Boolean,
  },
  setup () {
    return { get }
  },
  data: () => ({
    availablePaymentTypes: [],
    salesRecordCollectionList: [],
  }),
  computed: {
    ...mapGetters(['shop', 'userPlanFeature', 'userFeatures']),

    totalPaymentsAmount () {
      return this.selectedTarget.SalesRecordPayments.reduce(
        (sum, i) => sum + i.amount,
        0,
      )
    },

    sellAtDate () {
      const sellAt = get(this.selectedTarget, 'sellAt')
      return formatDate(sellAt)
    },

    useStorePermission () {
      return checkUserFeature(
        this.userPlanFeature,
        this.userFeatures,
        'admin.branch.adminUseStore',
      )
    },

    useShopPoint () {
      return checkUserFeature(
        this.userPlanFeature,
        this.userFeatures,
        'admin.shopPoint.page',
      )
    },

    totalPoint () {
      const totalPoint = this.selectedTarget.RewardActivityRecords.reduce(
        (prev, curr) => {
          if (curr.rewardType === 'POINT') {
            return prev + curr.rewardAmount
          }
          return prev
        },
        0,
      )
      return totalPoint
    },
    totalRebate () {
      // return this.selectedTarget.RewardActivityRecords?.reduce((sum, i) => sum + i.rewardAmount, 0) || 0
      const total = this.selectedTarget.RewardActivityRecords.reduce(
        (prev, curr) => {
          if (curr.rewardType === 'CASHBACK') {
            return prev + curr.rewardAmount
          }
          return prev
        },
        0,
      )
      // return total + (this.selectedTarget.totalAddCashback || 0)
      return total
    },

    createOrigin () {
      const origin = get(this.selectedTarget, 'origin')
      if (!origin) return ''
      if (origin === 'admin') return '後台'
      if (origin === 'client') return '顧客'
      if (origin === 'admin_past') return '後台跨日補登'
      return ''
    },
    hasReward () {
      return this.selectedTarget.totalAddCashback || this.totalPoint || this.totalRebate
    },
  },

  async created () {
    await this.getSalesPaymentTypes()
    this.getSalesRecordItemsCollectionList()
  },

  methods: {
    recordDateFromat () {
      if (!this.selectedTarget) return ''
      return this.selectedTarget
        ? dayjs(this.selectedTarget.createdAt).format('YYYY/MM/DD HH:mm')
        : ''
    },

    openRecordNoteDialog () {
      this.$emit('open-record-note-dialog', true)
    },

    //= > 付款方式翻譯
    paymentTrans (text) {
      const target = this.availablePaymentTypes.find(
        (payment) => payment.type === text,
      )
      return target?.name
    },

    getSalesRecordItemsCollectionList () {
      let target = []
      const units = []

      if (this.selectedTarget && this.selectedTarget.SalesRecordItems) {
        target = this.selectedTarget.SalesRecordItems
        this.salesRecordCollectionList = []
      }

      target.forEach((item) => {
        if (item.SalesUnitId) {
          if (units.includes(item.SalesUnit.name)) {
            const temp = this.salesRecordCollectionList.find(
              (subItem) => subItem.unitId === item.SalesUnitId,
            )

            if (temp) {
              temp.list.push({
                id: item.id,
                name: item.name,
                quantity: item.quantity,
                price: item.price,
              })
            }
          } else {
            units.push(item.SalesUnit.name)
            this.salesRecordCollectionList.push({
              unitId: item.SalesUnitId,
              unitName: item.SalesUnit.name,
              list: [
                {
                  id: item.id,
                  name: item.name,
                  quantity: item.quantity,
                  price: item.price,
                },
              ],
            })
          }
        } else {
          if (units.includes('無銷售人員')) {
            const temp = this.salesRecordCollectionList.find(
              (subItem) => subItem.unitId === 'without-unit',
            )

            if (temp) {
              temp.list.push({
                id: item.id,
                name: item.name,
                quantity: item.quantity,
                price: item.price,
              })
            }
          } else {
            units.push('無銷售人員')
            this.salesRecordCollectionList.push({
              unitId: 'without-unit',
              unitName: '無銷售人員',
              list: [
                {
                  id: item.id,
                  name: item.name,
                  quantity: item.quantity,
                  price: item.price,
                },
              ],
            })
          }
        }
      })
    },

    async getSalesPaymentTypes () {
      try {
        const res = await GetSalesPaymentTypes({ shopId: this.shop })
        this.availablePaymentTypes = res.allPaymentTypes
        if (!find(this.availablePaymentTypes, { type: 'offline' })) {
          this.availablePaymentTypes.push({
            name: '現場付款',
            type: 'offline',
          })
        }
      } catch (error) {
        console.log(error || error.message)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.record-container {
  margin: 56px 44px 80px 20px;
}

.record__title {
  font-size: 24px;
  font-weight: 500;
  line-height: 35px;
}

.record__customer {
  margin-top: 34px;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
}

.record__list {
  margin-top: 17px;
}

.item__name {
  margin-top: 17px;
  height: 32px;
  padding: 4px 10px;
  background-color: var(--primary-30);
  line-height: 24px;
}

.items-price {
  position: relative;
}

.item__unit {
  margin-top: 18px;
}

.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 26px;
}

.item__title {
  flex-shrink: 0;
  font-size: 16px;
  line-height: 24px;
}

.item__price {
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
}

.item__note {
  flex: 2 0 auto;
  margin-left: 80px;
  max-width: 338px;
  white-space: pre-line;
  word-wrap: break-word;
}

.item__button {
  flex: 0 0 auto;
  margin-left: 8px;
  width: 80px;
  color: #2bb8c1;
  text-align: right;
  text-decoration: underline;
  cursor: pointer;
}

.note.item {
  justify-content: flex-start;
  align-items: flex-start;
}

.el-drawer__body {
  position: relative;
}

.record--button {
  @apply flex justify-end;
  @apply absolute bottom-0 left-0 right-0 p-[28px] bg-white;

  .el-button {
    @apply min-w-[100px];

    &.el-button--danger {
      margin-left: 20px;
    }
  }
}
</style>
