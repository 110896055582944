import { admin } from '@/api/instance'

const baseUrl = (shopId) => {
  return `/${shopId}/appointmentScheduleDay`
}

//= > 創建班別
export const CreateShiftsClass = async ({
  shopId,
  name,
  color,
  code,
  times,
  order,
}) => {
  const res = await admin({
    method: 'post',
    url: baseUrl(shopId),
    data: {
      name,
      color,
      code,
      times,
      order,
    },
  })

  return res.data
}

//= > 取得班別
export const GetShiftsClass = async ({
  shopId,
  start,
  limit,
  name,
}) => {
  const res = await admin({
    method: 'get',
    url: baseUrl(shopId),
    params: {
      start,
      limit,
      name,
    },
  })

  return res.data
}

//= > 取得班別總數
export const GetShiftsClassCount = async ({
  shopId,
  name,
}) => {
  const res = await admin({
    method: 'get',
    url: `${baseUrl(shopId)}/count`,
    params: {
      name,
    },
  })

  return res.data
}

//= > 取得指定班別
export const FindShiftsClass = async ({
  shopId,
  id,
}) => {
  const res = await admin({
    method: 'get',
    url: `${baseUrl(shopId)}/${id}`,
  })

  return res.data
}

//= > 更新班別
export const UpdateShiftsClass = async ({
  shopId,
  id,
  name,
  color,
  code,
  times,
  order,
}) => {
  const res = await admin({
    method: 'put',
    url: `${baseUrl(shopId)}/${id}`,
    data: {
      name,
      color,
      code,
      times,
      order,
    },
  })

  return res.data
}

//= > 刪除班別
export const DeleteShiftsClass = async ({
  shopId,
  id,
}) => {
  const res = await admin({
    method: 'delete',
    url: `${baseUrl(shopId)}/${id}`,
  })

  return res.data
}
