<template>
  <div>
    <div class="flex justify-between items-center">
      <div class="sub-title">
        <span v-if="!hideTitleStick" class="title-hint" :style="`font-size: ${fontSize}px`">|</span>
        <span v-if="title" class="title-text" :style="`font-size: ${fontSize}px`">{{ title }}</span>
        <TipInfo v-if="tooltip" class="ml-[8px] text-gray-60" :width="tooltipWidth" :size="20">
          {{ tooltip }}
        </TipInfo>
        <slot v-if="!title" name="title" />
      </div>

      <BaseElButton
        v-if="!hideBtn"
        :disabled="disabled"
        type="primary"
        :data-cy="cyBtn"
        @click="$emit('edit')"
      >
        {{ btn || $t('common.button.edit.text') }}
      </BaseElButton>
    </div>
    <p v-if="hint" class="hint">{{ hint }}</p>
  </div>
</template>

<script>
import TipInfo from '@/components/TipInfo.vue'
export default {
  name: 'SectionTitle',
  components: {
    TipInfo,
  },
  props: {
    cyBtn: { default: '' },
    title: { default: '' },
    hint: { default: '' },
    btn: { default: '' },
    disabled: Boolean,
    hideBtn: Boolean,
    hideTitleStick: Boolean,
    fontSize: { default: 24 },
    tooltip: {
      type: String,
      default: null,
    },
    tooltipWidth: {
      type: [String, Number],
      default: '200',
    },
  },
}
</script>

<style lang="scss" scoped>
.sub-title {
  @apply mb-[10px] font-medium flex items-center;

  .title-hint {
    @apply mr-[5px] text-primary-100;
  }
}

.hint {
  @apply text-sub text-gray-80 font-medium mt-[15px];
}

::v-deep .el-button{
  @apply min-w-[100px] text-normal;
}
</style>
