<template>
  <BaseElForm ref="formRef" :model="syncItem" :rules="formRules" label-position="top">
    <BaseElFormItem :label="displayData.formItemLabel.isOverTime">
      <BaseElRadioGroup
        v-model="syncItem.isOverTime"
        @change="onIsOvertimeChange(syncItem)"
      >
        <BaseElRadio :label="true" data-cy="main-overTime-yes">是</BaseElRadio>
        <BaseElRadio :label="false" data-cy="main-overTime-no">否</BaseElRadio>
      </BaseElRadioGroup>
    </BaseElFormItem>

    <p class="font-medium text-gray-100" style="margin-bottom: 8px;"><span class="font-medium" style="color: #F56C6C; margin-right: 4px;">*</span>{{ displayData.formItemLabel.date }}</p>
    <div class="flex" style="gap: 8px">
      <BaseElFormItem class="w-full" prop="date">
        <el-date-picker
          ref="datePickerRef"
          v-model="syncItem.date"
          style="width: 100%"
          editable
          :picker-options="datePickerOptions(syncItem)"
          type="date"
          placeholder="選擇日期"
          @focus="getDateOptions(syncItem)"
          @change="onDateChange(syncItem)"
        />
      </BaseElFormItem>

      <BaseElFormItem class="w-full" prop="time">
        <ElInputWrapper>
          <BaseElSelect
            v-if="showControl.formItem.time.timeSelect"
            v-model="syncItem.time"
            testName="formData_time"
            placeholder="請選擇"
            no-data-text="無數據"
            @change="onTimeChange(syncItem)"
          >
            <BaseElSelectOption
              v-for="item in displayAvailableTimes(syncItem)"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </BaseElSelect>
        </ElInputWrapper>

        <ElInputWrapper>
          <BaseElSelect
            v-if="showControl.formItem.time.overTimeSelect"
            v-model="syncItem.time"
            testName="formData_time"
            placeholder="請選擇"
            no-data-text="無數據"
            @change="onTimeChange(syncItem)"
          >
            <BaseElSelectOption
              v-for="item in displayOvertimeTimeOptions(syncItem)"
              :key="item.label"
              :label="item.label"
              :value="item.value"
            />
          </BaseElSelect>
        </ElInputWrapper>
      </BaseElFormItem>
    </div>

    <BaseElFormItem prop="classTickets">
      <template #label>
        <div class="flex flex-col" style="gap: 4px">
          <FormItemTooltipLabel :label="displayData.formItemLabel.classTickets">
            <p>{{ displayData.formItemTooltip.classTicket }}</p>
          </FormItemTooltipLabel>
          <p v-if="showControl.formItemHint.classTicket" class="text-sm text-gray-60">{{ displayData.formItemHint.classTicket }}</p>
        </div>
      </template>
      <BaseElSelect
        v-model="syncItem.classTickets"
        testName="formData_service_classTicket"
        value-key="id"
        multiple
        :multiple-limit="1"
        @change="onClassTicketChange(syncItem)"
      >
        <BaseElSelectOption
          v-for="classTicket in displayClassTicketOptions(syncItem)"
          :key="classTicket.id"
          :label="classTicket.label"
          :disabled="classTicket.disabled"
          :value="classTicket"
        />
      </BaseElSelect>
    </BaseElFormItem>
  </BaseElForm>
</template>

<script>
import { useCreateAappointment } from '@/use/useCreateAppointment'
import { generateFormatPeriod } from '@/utils/time'
import { noEmptyRules } from '@/validation'
import { useVModel } from '@vueuse/core'
import { map, filter, get, omit, intersection, uniq, includes, find, forEach, reduce, findIndex } from 'lodash'
import { formatDate, getMonth, thisMonth } from '@/utils/date'
import { computed, defineComponent, ref, nextTick, set, onMounted, inject } from 'vue'
import dayjs from '@/lib/dayjs'
import { CalculateCheckoutCashbackLimit, CheckReservation, GetAvailableTimes } from '@/api/reservation'
import { serviceTypeConfig } from '@/config/reservation'
import { useShop } from '@/use/shop'
import FormItemTooltipLabel from '@/components/Form/FormItemTooltipLabel.vue'
import ElInputWrapper from '@/components/ElInputWrapper.vue'

export default defineComponent({
  name: 'AppointmentPeriodForm',
  components: {
    FormItemTooltipLabel,
    ElInputWrapper,
  },
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  setup (props, { emit }) {
    const dataPlaceholder = inject('dataPlaceholder')
    const {
      cart,
      context,
      configData,
      memberData,
      getCartItemPreReservationsPayload,
      composeAllCartItemReservations,
      defaultSelectClassTicket,
    } = useCreateAappointment()
    const syncItem = useVModel(props, 'item', emit)
    const { shopId } = useShop()
    const datePickerRef = ref(null)
    const mainPeriodCartItem = computed(() => get(cart.value, '[0].cartItem'))
    const formRef = ref(null)
    const formRules = computed(() => {
      const rules = {
        date: [noEmptyRules()],
        time: [noEmptyRules()],
      }

      const selectedService = get(mainPeriodCartItem.value, 'service')

      // 如果服務必選堂票
      if (get(selectedService, 'enableClassTicket') && get(selectedService, 'requiredClassTicket')) {
        rules.classTickets = [{ type: 'array', required: true, trigger: ['change', 'blur'], message: '請選擇堂票' }]
      }

      return rules
    })

    const showControl = computed(() => {
      const controls = {
        formItem: {
          time: {
            timeSelect: true,
            overTimeSelect: false,
          },
        },
        formItemHint: {
          classTicket: false,
        },
      }

      if (context.peopleCount > 1) {
        controls.formItemHint.classTicket = true
      }

      // 如果是加班時段
      if (syncItem.value.isOverTime) {
        controls.formItem.time.timeSelect = false
        controls.formItem.time.overTimeSelect = true
      }

      return controls
    })

    const displayData = computed(() => {
      return {
        mainServiceName: get(mainPeriodCartItem.value, 'service.name', '-'),

        formItemLabel: {
          isOverTime: '是否為加班時段',
          date: '預約日期',
          time: '預約時段',
          classTickets: '選擇堂票',
        },
        formItemTooltip: {
          classTicket: '僅顯示此會員擁有、並能用於已選擇的服務項目的堂票',
        },
        formItemHint: {
          classTicket: '多人預約時，選擇堂票僅會使用一張堂票',
        },
      }
    })

    // ?? 顯示的加班時段選項
    const displayOvertimeTimeOptions = computed(() => {
      return (cartItem) => {
        const offset = get(configData.reservation, 'timeUnitOffset')
        const timeUnit = get(configData.reservation, 'timeUnit')
        const selectedDate = formatDate(cartItem.date, 'YYYY/MM/DD')
        const times = generateFormatPeriod({ gap: timeUnit, offset, maxEnd: 1440 })
        return map(times, (time) => {
          return {
            label: time,
            value: dayjs(`${selectedDate} ${time}`).toDate(),
          }
        })
      }
    })

    // ?? 顯示的可用時段
    const displayAvailableTimes = computed(() => {
      return (cartItem) => {
        const now = dayjs()
        const times = map(filter(get(dataPlaceholder, `${cartItem.nanoId}.availableTimes`), (item) => {
          return dayjs(item).isSame(cartItem.date, 'date')
        }), i => {
          return {
            label: dayjs(i).format('HH:mm'),
            value: i,
          }
        })
        const availableTimes = filter(times, (item) => {
          return dayjs(item.value).isAfter(now, 'minute')
        })

        return availableTimes
      }
    })

    const datePickerOptions = computed(() => {
      return (cartItem) => {
        return {
          disabledDate: (date) => {
            const isOverTime = cartItem.isOverTime
            if (isOverTime) return false
            const d = dayjs(date).format('YYYY/MM/DD')
            const nowTime = dayjs().format('YYYY/MM/DD')
            return !find(get(dataPlaceholder, `${cartItem.nanoId}.availableDate`), (item) => item === d) || dayjs(d).isBefore(nowTime, 'date')
          },
        }
      }
    },

    )

    // ?? 顯示的堂票選項
    const displayClassTicketOptions = computed(() => {
      return (cartItem) => {
        const selectedService = mainPeriodCartItem.value.getDetailData('service')
        const appointmentDateTime = cartItem.time
        if (!selectedService) return []
        if (!appointmentDateTime) return []

        // 過濾已過期或無可用次數的堂票
        const availableClassTickets = filter(memberData.classTickets, (item) => {
          if (!item.availableUseTimes) return false
          if (item.isExp) {
            const expDate = item.exp
            if (!expDate) return true
            // 檢查堂票過期時間是否大於預約時段
            else if (dayjs(expDate).isAfter(dayjs(appointmentDateTime), 'date')) return true
          } else {
            return true
          }
        })

        // 過濾會員針對該服務適用的堂票
        const serviceClassTicketIds = map(selectedService.ClassTickets, 'id')
        const classTickets = map(availableClassTickets, 'ClassTicketId')
        const intersectionClassTickets = intersection(serviceClassTicketIds, uniq(classTickets))
        if (!intersectionClassTickets.length) return [] // 會員沒有可用於服務的堂票

        const finalAvailablesClassTickets = filter(availableClassTickets, (item) => includes(intersectionClassTickets, item.ClassTicketId))
        const preCartItem = []
        const curCartItemIdx = findIndex(cart.value, { id: cartItem.nanoId })
        for (let i = 0; i < curCartItemIdx; i++) {
          preCartItem.push(get(cart.value, `[${i}].cartItem`))
        }

        const formatedOptions = map(finalAvailablesClassTickets, (item) => {
        // 此張堂票已經被使用的次數
          const preUseTimes = reduce(preCartItem, (sum, i) => {
            const used = find(i.classTickets, { id: item.id })
            return sum + (used ? 1 : 0)
          }, 0)

          const expDate = item.exp ? formatDate(item.exp) : ''
          let label = `${item.name} 剩餘${item.availableUseTimes - preUseTimes}張`
          if (expDate) label = `${label} （期限：${expDate}）`

          return {
            ...item,
            label,
            disabled: item.availableUseTimes - preUseTimes <= 0,
          }
        })

        // 排序堂票，將 disabled: true 的選項排到最後，expDate 快過期的排在前面
        formatedOptions.sort((a, b) => {
          if (a.disabled && !b.disabled) return 1
          if (!a.disabled && b.disabled) return -1
          if (a.expDate && b.expDate) {
            if (dayjs(a.expDate).isBefore(b.expDate)) return -1
            if (dayjs(a.expDate).isAfter(b.expDate)) return 1
          }
          return 0
        })

        return formatedOptions
      }
    })

    // => 取得可預約時間
    const getAvailableTimes = async ({ cartItem, start, end }) => {
      const selectedServiceUnitId = get(cartItem.serviceUnit, 'id')
      const serviceType = cartItem.serviceType
      if (serviceType === serviceTypeConfig.humanService.value) {
        if (!selectedServiceUnitId) return
      }

      const [res] = await GetAvailableTimes({
        shopId: shopId.value,
        ...cartItem.getAvailableTimesPayload({
          start: start.toDate(),
          end: end.toDate(),
          notSpecifyUnits: get(configData, 'reservation.NotSpecifyUnits'),
          peopleCount: context.peopleCount,
          preReservations: getCartItemPreReservationsPayload(cartItem.nanoId),
        }),
      })

      const list = []
      // 過濾已經超過當前時間的時間
      const availableTimes = filter(res.times, (item) => {
        return dayjs(item).isAfter(dayjs(), 'minute')
      })
      forEach(availableTimes, (item) => {
        list.push(dayjs(item).format('YYYY/MM/DD'))
      })

      set(dataPlaceholder, cartItem.nanoId, {
        availableDate: Array.from(new Set(list)),
        availableTimes: res.times,
      })
    }

    // => 更新可選預約日期
    const getDateOptions = async (cartItem) => {
      await nextTick()
      let dateStart
      let dateEnd

      if (get(datePickerRef.value, 'picker')) {
        const year = get(datePickerRef.value, 'picker.year')
        const month = get(datePickerRef.value, 'picker.month')
        const { start, end } = getMonth(year, month + 1)
        dateStart = start
        dateEnd = end
      } else {
        const { start, end } = thisMonth()
        dateStart = start
        dateEnd = end
      }

      await getAvailableTimes({ cartItem, start: dayjs(dateStart), end: dayjs(dateEnd) })

      const pre = document.querySelector(
        '.el-picker-panel__icon-btn.el-date-picker__prev-btn.el-icon-arrow-left',
      )
      const next = document.querySelector(
        '.el-picker-panel__icon-btn.el-date-picker__next-btn.el-icon-arrow-right',
      )
      const preYear = document.querySelector(
        '.el-picker-panel__icon-btn.el-date-picker__prev-btn.el-icon-d-arrow-left',
      )
      const nextYear = document.querySelector(
        '.el-picker-panel__icon-btn.el-date-picker__next-btn.el-icon-d-arrow-right',
      )

      const task = async () => {
        set(dataPlaceholder, cartItem.nanoId, {
          availableDate: [],
          availableTimes: [],
        })
        // dataPlaceholder[cartItem.nanoId].availableDate = []
        const year = get(datePickerRef.value, 'picker.year')
        const month = get(datePickerRef.value, 'picker.month')
        const { start, end } = getMonth(year, month + 1)

        // await this.getAvailableDate(start, end)
        await getAvailableTimes({ cartItem, start: dayjs(start), end: dayjs(end) })
      }
      pre.removeEventListener('click', task)
      next.removeEventListener('click', task)
      preYear.removeEventListener('click', task)
      nextYear.removeEventListener('click', task)

      pre.addEventListener('click', task)
      next.addEventListener('click', task)
      preYear.addEventListener('click', task)
      nextYear.addEventListener('click', task)
    }

    // => 當日期變動時
    const onDateChange = (cartItem) => {
      cartItem.resetData(['time', 'classTickets'])
      cartItem.resetDetailData(['reservation'])
    }

    // => 當時間變動時
    const onTimeChange = async (cartItem) => {
      cartItem.resetData(['classTickets'])
      cartItem.resetDetailData(['reservation'])
      const [res, err] = await CheckReservation({
        shopId: shopId.value,
        ...cartItem.getPreDetailByStartPayload({
          notSpecifyUnits: get(configData.reservation, 'NotSpecifyUnits'),
          preReservations: getCartItemPreReservationsPayload(cartItem.nanoId),
          peopleCount: context.peopleCount,
        }),
      })
      if (err) {
        window.$message.error(err)
        return
      }
      cartItem.setDetailData('reservation', res)
      setTimeout(async () => {
        await nextTick()
        await defaultSelectClassTicket({
          displayClassTicketOptions: displayClassTicketOptions.value(cartItem),
          cartItem,
        })
        await calculateCheckoutCashbackLimit()
      }, 0)
    }

    // => 當堂票變動時
    const onClassTicketChange = async (cartItem) => {
      cartItem.resetDetailData(['reservation'])
      const [res, err] = await CheckReservation({
        shopId: shopId.value,
        ...cartItem.getPreDetailByStartPayload({
          notSpecifyUnits: get(configData.reservation, 'NotSpecifyUnits'),
          preReservations: getCartItemPreReservationsPayload(cartItem.nanoId),
          peopleCount: context.peopleCount,
        }),
      })
      if (err) {
        window.$message.error(err)
        return
      }
      cartItem.setDetailData('reservation', res)
      setTimeout(async () => {
        await nextTick()
        await calculateCheckoutCashbackLimit()
      }, 0)
    }

    // => 當是否為加班時段變動時
    const onIsOvertimeChange = (cartItem) => {
      cartItem.resetData(['date', 'time', 'classTickets'])
      cartItem.resetDetailData(['reservation'])
    }

    const calculateCheckoutCashbackLimit = async () => {
      const [res, err] = await CalculateCheckoutCashbackLimit({
        shopId: shopId.value,
        memberId: get(context.member, 'id'),
        reservations: map(composeAllCartItemReservations.value, (item) =>
          omit(item, ['start']),
        ),
      })
      if (err) {
        window.$message.error(err)
        return
      }
      context.discount.cashback.limit = res.limit
    }

    onMounted(async () => {
      await nextTick()
      set(dataPlaceholder, mainPeriodCartItem.value.nanoId, {
        availableDate: [],
        availableTimes: [],
      })
      context.periodSettingFormRefs.push(formRef.value)
    })

    return {
      formRef,
      syncItem,
      formRules,
      showControl,
      displayData,
      displayOvertimeTimeOptions,
      onTimeChange,
      onIsOvertimeChange,
      onDateChange,
      displayAvailableTimes,
      getDateOptions,
      datePickerOptions,
      displayClassTicketOptions,
      dataPlaceholder,
      onClassTicketChange,
      datePickerRef,
    }
  },
})
</script>

<style lang="postcss" scoped>

</style>
