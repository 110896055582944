<template>
  <el-dialog
    :title="$t('reservationNotspecifySettings.dialog.title')"
    :visible="true"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <BaseElForm ref="formRef" :model="formData" :rules="formRules" label-position="top">
      <BaseElFormItem :label="`${$t('reservationNotspecifySettings.useNotSpecify.title')} ：`" class="settings-item" prop="useNotSpecify">
        <BaseElSwitch
          v-model="formData.useNotSpecify"
          testName="formData_useNotSpecify"
          class="switch-input"
          :active-text="$t('common.open.text')"
          :inactive-text="$t('common.close.text')"
        />
      </BaseElFormItem>

      <BaseElFormItem :label="`${$t('reservationNotspecifySettings.notSpecifySkipUnit.title')} ：`" class="settings-item" prop="notSpecifySkipUnit">
        <BaseElSwitch
          v-model="formData.notSpecifySkipUnit"
          testName="formData_notSpecifySkipUnit"
          :disabled="!formData.useNotSpecify"
          class="switch-input"
          :active-text="$t('common.open.text')"
          :inactive-text="$t('common.close.text')"
        />
      </BaseElFormItem>

      <BaseElFormItem>
        <template slot="label">
          <FormItemTooltipLabel :label="$t('reservationNotspecifySettings.notSpecifyOrderRule.title')" :tooltipWidth="200">
            {{ $t('reservationNotspecifySettings.notSpecifyOrderRule.tooltip') }}
          </FormItemTooltipLabel>
        </template>

        <BaseElSelect v-model="formData.notSpecifyOrderRule" testName="formData_notSpecifyOrderRule">
          <BaseElSelectOption
            v-for="option in noSpecifyUnitOrderOptionConfig"
            :key="option.value"
            :label="$t(option.label)"
            :value="option.value"
          />
        </BaseElSelect>
      </BaseElFormItem>

      <BaseElFormItem :label="`${$t('reservationNotspecifySettings.notSpecifyName.title')} ：`" class="settings-item" prop="notSpecifyName">
        <BaseElInput
          v-model="formData.notSpecifyName"
          testName="formData_notSpecifyName"
          :disabled="!formData.useNotSpecify"
          :placeholder="$t('common.input.placeholder')"
        />
      </BaseElFormItem>

      <BaseElFormItem :label="$t('reservationNotspecifySettings.notSpecifyImage.title')" class="settings-item" prop="NotSpecifyImage">
        <template slot="label">
          <FormItemTooltipLabel :label="$t('reservationNotspecifySettings.notSpecifyImage.title')">
            {{ $t('reservationNotspecifySettings.notSpecifyImage.tooltip')}}
          </FormItemTooltipLabel>
        </template>
        <UploadButton
          cyUploadBtn="nospecify-unit-img-upload"
          cyUploadedImg="nospecify-unit-img-uploaded"
          :disabled="!formData.useNotSpecify"
          style="width: 150px; height: 150px"
          :img="formData.NotSpecifyImage"
          @change="imageChange"
        />
        <!-- <img  v-if="image" :src="imgUrl(image)" alt="" > -->
      </BaseElFormItem>

      <BaseElFormItem :label="$t('reservationNotspecifySettings.notSpecifyUnits.title')" class="settings-item" prop="NotSpecifyUnits">
        <template slot="label">
          <FormItemTooltipLabel :label="$t('reservationNotspecifySettings.notSpecifyUnits.title')">
            {{ $t('reservationNotspecifySettings.notSpecifyUnits.tooltip')}}
          </FormItemTooltipLabel>
        </template>
        <ServicesUnitSelect
          testName="formData_NotSpecifyUnits"
          :disabled="!formData.useNotSpecify"
          :model.sync="formData.NotSpecifyUnits"
          multiple
        />
      </BaseElFormItem>

      <BaseElFormItem :label="`${$t('reservationNotspecifySettings.notSpecifyRule.title')} ：`" class="settings-item" prop="notSpecifyRule">
        <BaseElSelect
          v-model="formData.notSpecifyRule"
          testName="formData_notSpecifyRule"
          :disabled="!formData.useNotSpecify"
          :no-data-text="$t('common.table.empty.text')"
          :placeholder="$t('reservationNotspecifySettings.notSpecifyRule.placeholder')"
          clearable
        >
          <BaseElSelectOption
            v-for="rule in notSpecifyRules"
            :key="rule.id"
            :label="$t(rule.name)"
            :value="rule.id"
          />
        </BaseElSelect>
      </BaseElFormItem>

      <p>{{ $t('reservationNotspecifySettings.dialog.rule1')}}</p>
      <p>{{ $t('reservationNotspecifySettings.dialog.rule2')}}</p>
      <p>{{ $t('reservationNotspecifySettings.dialog.rule3')}}</p>
    </BaseElForm>

    <div slot="footer">
      <BaseElButton plain @click="$emit('close')">{{ $t('common.button.cancel.text')}}</BaseElButton>
      <BaseElButton
        :lodading="loading"
        data-cy="edit-notspecify-dialog-confirm-btn"
        type="primary"
        @click="onSubmit"
      >
        {{ $t('common.button.save.text')}}
      </BaseElButton>
    </div>

    <ImageCropper
      v-if="uploadDialog"
      :image="formData.NotSpecifyImage"
      @close="uploadDialog = false"
      @uploaded="getImage"
    />
  </el-dialog>
</template>

<script>
import { computed, defineComponent, onBeforeMount, ref } from 'vue'
import { useBaseForm } from '@/use/useForm'
import { noEmptyRules } from '@/validation'
import { noSpecifyUnitOrderOptionConfig } from '@/config/reservation'
import FormItemTooltipLabel from '@/components/Form/FormItemTooltipLabel.vue'
import UploadButton from '@/components/Button/UploadButton.vue'
import ImageCropper from '@/components/ImageCropper.vue'
import ServicesUnitSelect from '@/components/Select/ServicesUnitSelect.vue'
import notifyMessage from '@/config/notifyMessage'
import { UpdateReservationParameter } from '@/api/reservation'
import { useShop } from '@/use/shop'
import { get, map } from 'lodash'

export default defineComponent({
  name: 'EditReservationNotspecifySettingsModal',
  components: { FormItemTooltipLabel, UploadButton, ServicesUnitSelect, ImageCropper },
  props: {
    configData: { type: Object, default: () => ({}) },
  },
  setup (props, { emit }) {
    const uploadDialog = ref(false)
    const { shopId } = useShop()
    const { formRef, formData, initFormData, loading, checkForm } = useBaseForm()

    const notSpecifyRules = {
      order: { id: 'order', name: 'reservationNotspecifySettings.notSpecifyRule.order.title' },
      reservation: { id: 'reservation', name: 'reservationNotspecifySettings.notSpecifyRule.reservation.title' },
      notSpecifyReservation: {
        id: 'notSpecifyReservation',
        name: 'reservationNotspecifySettings.notSpecifyRule.notSpecifyReservation.title',
      },
      random: { id: 'random', name: 'reservationNotspecifySettings.notSpecifyRule.random.title' },
    }

    const formRules = computed(() => {
      return {
        useNotSpecify: [noEmptyRules()],
        notSpecifySkipUnit: [noEmptyRules()],
        notSpecifyName: [noEmptyRules()],
        // NotSpecifyImage: [noEmptyRules()],
        notSpecifyRule: [noEmptyRules()],
        NotSpecifyUnits: formData.useNotSpecify ? [noEmptyRules()] : [],
      }
    })

    const imageChange = (img) => {
      formData.NotSpecifyImage = img
      uploadDialog.value = true
    }

    const getImage = (img) => {
      formData.NotSpecifyImage = img
      uploadDialog.value = false
    }

    const onSubmit = async () => {
      if (loading.value) return
      loading.value = true
      if (!(await checkForm(formRef.value))) return
      const [, err] = await UpdateReservationParameter({
        shopId: shopId.value,
        ...props.configData,
        useNotSpecify: formData.useNotSpecify,
        notSpecifySkipUnit: formData.notSpecifySkipUnit,
        notSpecifyOrderRule: formData.notSpecifyOrderRule,
        notSpecifyName: formData.notSpecifyName,
        notSpecifyImage: get(formData.NotSpecifyImage, 'id') || null,
        notSpecifyUnits: map(formData.NotSpecifyUnits, 'id'),
        notSpecifyRule: formData.notSpecifyRule,
      })
      loading.value = false
      if (err) {
        window.$message.error(err)
        return
      }

      window.$message.success(notifyMessage.updateSuccess)
      emit('close')
      emit('refresh')
    }

    onBeforeMount(() => {
      initFormData({
        useNotSpecify: props.configData.useNotSpecify,
        notSpecifySkipUnit: props.configData.notSpecifySkipUnit,
        notSpecifyOrderRule: props.configData.notSpecifyOrderRule,
        notSpecifyName: props.configData.notSpecifyName,
        NotSpecifyImage: props.configData.NotSpecifyImage,
        NotSpecifyUnits: props.configData.NotSpecifyUnits,
        notSpecifyRule: props.configData.notSpecifyRule,
      })
    })

    return {
      formRef,
      formData,
      loading,
      notSpecifyRules,
      formRules,
      noSpecifyUnitOrderOptionConfig,
      uploadDialog,
      imageChange,
      getImage,
      onSubmit,
    }
  },
})
</script>

<style lang="postcss" scoped></style>
