import { computed, ref } from 'vue'
import store from '@/store'
import { get } from 'lodash'
import { GetExternalWalletConfig, GetExternalWalletBalance } from '@/api/externalWallet'
import { usePermissions } from '@/use/permissions'

export const useExternalWallet = () => {
  const externalWalletConfig = ref({})
  const externalWalletBalance = ref(0)
  const { checkAction } = usePermissions()
  const enableExternalWallet = computed(() => checkAction('admin.externalWallet.page'))
  const getExternalWalletConfig = async () => {
    if (!enableExternalWallet.value) return
    const [res, err] = await GetExternalWalletConfig({
      shopId: store.getters.shop,
    })
    if (err) {
      window.$message.error(err.msg || err)
      return
    }
    externalWalletConfig.value = res
  }
  const externalWalletName = computed(
    () => get(externalWalletConfig.value, 'displayName') || '第三方儲值金',
  )
  const getExternalWalletBalance = async (memberId) => {
    if (!enableExternalWallet.value) return
    if (!memberId) return
    const [res, err] = await GetExternalWalletBalance({
      shopId: store.getters.shop,
      memberId,
    })
    if (err) {
      window.$message.error(err.msg || err)
      return
    }
    externalWalletBalance.value = res.balance
  }
  return {
    getExternalWalletConfig,
    externalWalletName,
    getExternalWalletBalance,
    externalWalletBalance,
    enableExternalWallet,
  }
}
