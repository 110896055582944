<template>
  <div class="sales-parameter">
    <section class="section-block">
      <SectionTitle :title="$t('publicAppointment.linkSettings.title')" @edit="linkSettingDialog = true" />
      <BaseElForm label-position="left" label-width="250px">
        <BaseElFormItem :label="$t('publicAppointment.code.title')">
          <div class="secret-text collapse">
            <span>{{ code ? code : $t('common.notSetupYet.text') }}</span>
          </div>
        </BaseElFormItem>
        <BaseElFormItem :label="$t('publicAppointment.link.title')">
          <div class="copy-block">
            <a class="secret-text">
              {{ ecommerceLink }}
            </a>
            <div>
              <BaseElButton
                class="copy-btn"
                type="primary"
                @click.prevent="copyLink(ecommerceLink)"
              >
                {{ $t('common.button.copy.text')}}
              </BaseElButton>
            </div>
          </div>
        </BaseElFormItem>
      </BaseElForm>
    </section>

    <!-- 連結設定 Dialog -->
    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="linkSettingDialog"
      :title="$t('publicAppointment.linkSettings.dialog.title')"
      @close="resetForm"
    >
      <BaseElForm
        ref="form"
        :model="formData"
        :rules="formRules"
        label-position="top"
      >
        <BaseElFormItem :label="$t('publicAppointment.codeSetting.title')" prop="code">
          <template slot="label">
            <FormItemTooltipLabel
              :label="$t('publicAppointment.codeSetting.title')"
              class="title"
            >
              {{ $t('publicAppointment.codeSetting.tooltip')}}
            </FormItemTooltipLabel>
          </template>
          <BaseElInput
            v-model="formData.code"
            :min="6"
            class="dialog-input"
            :placeholder="$t('common.input.placeholder')"
          />
          <p style="font-size: 12px; color: gray;">
            {{ $t('publicAppointment.codeSetting.info.title')}}
          </p>
        </BaseElFormItem>
      </BaseElForm>

      <div slot="footer">
        <BaseElButton plain @click="linkSettingDialog = false">{{ $t('common.button.cancel.text')}}</BaseElButton>
        <BaseElButton type="primary" @click="updateCode">{{ $t('common.button.save.text')}}</BaseElButton>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { defineComponent, ref, reactive, computed, set, watch, nextTick } from 'vue'
import SectionTitle from '@/components/Title/SectionTitle'
import FormItemTooltipLabel from '@/components/Form/FormItemTooltipLabel.vue'
import copy from 'clipboard-copy'

import { noEmptyRules, domainRules } from '@/validation'
import formUtils from '@/utils/form'
import configuration from '@/configuration'
import { get } from 'lodash'
import { i18n } from '@/plugins/i18n/i18n'

export default defineComponent({
  name: 'LinkSettings',
  components: { SectionTitle, FormItemTooltipLabel },
  props: {
    content: {
      type: Object,
      default: () => ({}),
    },
  },
  setup (props, { emit }) {
    const ecommerceLink = computed(() => {
      const code = get(props, 'content.code')
      if (!code) return i18n.t('common.notSetupYet.text')

      return `${configuration('pubAptHost')}/${code}`
    })
    const linkSettingDialog = ref(false)
    const formData = reactive({
      code: '',
    })
    const code = ref('')
    const formRules = reactive({
      code: [
        noEmptyRules(),
        domainRules(),
        { min: 6, message: i18n.t('validation.minLength2.text', { number: 6}) },
      ],
    })
    const form = ref(null)
    const updateCode = async () => {
      if (!(await formUtils.checkForm(form.value))) return
      emit('updatePubApt', formData)
      linkSettingDialog.value = false
    }
    const copyLink = (link) => {
      copy(link)
      window.$message.success(i18n.t('common.message.copySuccess'))
    }
    const resetForm = () => {
      formUtils.resetForm(form.value)
    }
    const syncData = async () => {
      await nextTick()
      set(formData, 'code', get(props, 'content.code'))
      set(code, 'value', get(props, 'content.code'))
    }
    watch(props, async () => {
      await syncData()
    })
    return {
      linkSettingDialog,
      formData,
      formRules,
      ecommerceLink,
      updateCode,
      form,
      copyLink,
      resetForm,
      code,
    }
  },
})
</script>

<style lang="scss">
.el-switch__label span {
  font-size: 16px;
}
</style>

<style scoped lang="scss">
.secret-text,
.note {
  // width: 100px;
  font-weight: 400;
  @apply text-gray-80;
  letter-spacing: 1px;
}

.collapse {
  width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.copy-block {
  @apply flex items-center overflow-hidden max-w-[440px];

  a {
    @apply overflow-hidden text-gray-80;
    text-decoration: none;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.copy-btn {
  @apply h-[30px] rounded-full py-[3px] ml-[30px] px-[30px];
}
</style>
