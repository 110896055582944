<template>
  <div class="resource-reservation-settings">
    <div class="font-bold text-lg text-primary-100">{{ $t('resourceReservationSettings.title')}}</div>
    <p class="sub-title">
      {{ $t('resourceReservationSettings.subTitle')}}
      <a class="cursor-pointer" @click="showModal = true">{{ $t('resourceReservationSettings.link')}}</a>
    </p>
    <BaseElForm ref="formRef" label-position="top" :model="formData" :rules="formRules">
      <BaseElFormItem prop="enableTimeOff">
        <BaseElRadioGroup v-model="formData.enableTimeOff">
          <BaseElRadio v-for="item in enableTimeOffOptions" :key="item.value" :label="item.value">{{ item.label }}</BaseElRadio>
        </BaseElRadioGroup>
      </BaseElFormItem>
      <template v-if="formData.enableTimeOff && formData.timeOffConfig">
        <BaseElFormItem :label="$t('resourceReservationSettings.months.title')">
          <BaseElSelect v-model="formData.timeOffConfig.months" :placeholder="$t('resourceReservationSettings.months.placeholder')" multiple>
            <BaseElSelectOption
              v-for="item in monthOptions"
              :key="item.value"
              :label="$t(item.label)"
              :value="item.value"
            />
          </BaseElSelect>
        </BaseElFormItem>
        <BaseElFormItem :label="$t('resourceReservationSettings.weeks.title')">
          <BaseElSelect v-model="formData.timeOffConfig.weekdays" :placeholder="$t('resourceReservationSettings.weeks.placeholder')" multiple>
            <BaseElSelectOption
              v-for="item in weeksOptions"
              :key="item.value"
              :label="$t(item.label)"
              :value="item.value"
            />
          </BaseElSelect>
        </BaseElFormItem>
        <BaseElFormItem :label="$t('resourceReservationSettings.dateRangeSettings.title')" prop="timeOffConfig.dateRangeSettings">
          <BaseElButton class="mb-[8px]" plain @click="addDateRange">{{ $t('resourceReservationSettings.button.addDateRange.text')}}</BaseElButton>
          <div v-for="(item ,index) in formData.timeOffConfig.dateRangeSettings" :key="index" class="date-range mb-[8px]">
            <el-date-picker
              v-model="formData.timeOffConfig.dateRangeSettings[index]"
              editable
              type="daterange"
              :range-separator="$t('common.input.datepicker.rangeSeparator')"
              :start-placeholder="$t('common.input.datepicker.startDate.title')"
              :end-placeholder="$t('common.input.datepicker.endDate.title')"
            />
            <i class="el-icon-close" @click="formData.timeOffConfig.dateRangeSettings.splice(index, 1)" />
          </div>
        </BaseElFormItem>
        <BaseElFormItem :label="$t('resourceReservationSettings.timeRangeSettings.title')" prop="timeOffConfig.timeRangeSettings">
          <BaseElButton class="mb-[8px]" plain @click="addTimeRange">{{ $t('resourceReservationSettings.button.addTimeRange.text')}}</BaseElButton>
          <div v-for="(item ,index) in formData.timeOffConfig.timeRangeSettings" :key="index" class="time-range mb-[8px]">
            <div class="flex-1">
              <el-date-picker
                v-model="formData.timeOffConfig.timeRangeSettings[index].date"
                editable
                class="date-picker"
                type="date"
                :placeholder="$t('resourceReservationSettings.timeRangeSettings.placeholder')"
              />
              <div class="flex">
                <el-time-select
                  v-model="formData.timeOffConfig.timeRangeSettings[index].timeStart"
                  data-cy="start-time-select"
                  class="w-full flex-1"
                  :placeholder="$t('resourceReservationSettings.timeStart.placeholder')"
                  :picker-options="{
                    start: '00:00',
                    step: '00:10',
                    end: '24:00',
                  }"
                  :disabled="!formData.timeOffConfig.timeRangeSettings[index].date"
                />
                <el-time-select
                  v-model="formData.timeOffConfig.timeRangeSettings[index].timeEnd"
                  data-cy="end-time-select"
                  class="w-full flex-1 ml-2"
                  :placeholder="$t('resourceReservationSettings.timeEnd.placeholder')"
                  :picker-options="{
                    start: formData.timeOffConfig.timeRangeSettings[index].timeStart,
                    step: '00:10',
                    end: '24:00',
                    minTime: formData.timeOffConfig.timeRangeSettings[index].timeStart,
                  }"
                  :disabled="!formData.timeOffConfig.timeRangeSettings[index].date"
                />
              </div>
            </div>
            <i class="el-icon-close" @click="formData.timeOffConfig.timeRangeSettings.splice(index, 1)" />
          </div>
        </BaseElFormItem>
      </template>
    </BaseElForm>
    <ShopBusinessTimeDialog
      :title="$t('shopBusinessTimeDialog.title')"
      :subtitle="$t('shopBusinessTimeDialog.subTitle')"
      :visible="showModal"
      :btn="$t('common.close.text')"
      @close="showModal = false"
    />
  </div>
</template>

<script>
// import ManualSalesRecordBasicSettingsEditModal from './ManualSalesRecordBasicSettingsEditModal.vue'
import { defineComponent, computed, onMounted, watch, nextTick, ref } from 'vue'
import { get } from 'lodash'
import { noEmptyRules, noEmptyDateTimeInterval, noEmptyDateTimeRangeInterval } from '@/validation'
import FormsContext from '../formsContext'
import { simpleSyncFormData, useBaseForm } from '@/use/useForm'
import { monthOptions, weeksOptions } from '@/config/date'
import ShopBusinessTimeDialog from './ShopBusinessTimeDialog.vue'
import dayjs from '@/lib/dayjs'
import { i18n } from '@/plugins/i18n/i18n'

export default defineComponent({
  name: 'ResourceReservationSettings',
  components: { ShopBusinessTimeDialog },
  props: {
    configData: { type: Object, default: () => ({}) },
  },
  setup (props) {
    const { initFormData, formData, formRef } = useBaseForm()
    const enableTimeOffOptions = computed(() =>  [
      { label: i18n.t('resourceReservationSettings.enableTimeOffOptions.false.title'), value: false },
      { label: i18n.t('resourceReservationSettings.enableTimeOffOptions.true.title'), value: true },
    ])
    const showModal = ref(false)

    // const empty = { start: '', end: '' }
    const emptyDate = ['', '']
    const emptyDateTime = { date: '', timeStart: '', timeEnd: '' }
    initFormData({
      enableTimeOff: false,
      timeOffConfig: {
        months: [],
        weekdays: [],
        dateRangeSettings: [],
        timeRangeSettings: [],
      },
    })

    // 表單欄位規則
    const formRules = computed(() => {
      const rules = {
        enableTimeOff: [noEmptyRules()],
        timeOffConfig: {
          dateRangeSettings: [noEmptyDateTimeInterval()],
          timeRangeSettings: [noEmptyDateTimeRangeInterval()],
        },
      }

      return rules
    })

    const syncFormData = (data) => {
      simpleSyncFormData(data, formData)
      if (get(formData, 'timeOffConfig')) {
        if (get(formData, 'timeOffConfig.dateRangeSettings').length > 0) {
          formData.timeOffConfig.dateRangeSettings = formData.timeOffConfig.dateRangeSettings.map((item) => {
            return [item.start, item.end]
          })
        }
        if (get(formData, 'timeOffConfig.timeRangeSettings').length > 0) {
          formData.timeOffConfig.timeRangeSettings = formData.timeOffConfig.timeRangeSettings.map((item) => {
            return {
              date: item.start,
              timeStart: getTimeFromDate(item.start),
              timeEnd: getTimeFromDate(item.end),
            }
          })
        }
      } else {
        formData.timeOffConfig = {
          months: [],
          weekdays: [],
          dateRangeSettings: [],
          timeRangeSettings: [],
        }
      }
    }

    const compactData = computed(() => {
      let data = {
        enableTimeOff: get(formData, 'enableTimeOff'),
        timeOffConfig: {
          months: [],
          weekdays: [],
          dateRangeSettings: [],
          timeRangeSettings: [],
        },
      }
      if (get(formData, 'enableTimeOff')) {
        const dateRangeSettings = get(formData, 'timeOffConfig') && get(formData, 'timeOffConfig.dateRangeSettings').length > 0 ? formData.timeOffConfig.dateRangeSettings.map((item) => {
          if (!item) {
            return {
              start: null,
              end: null,
            }
          }
          return {
            start: item[0] ? dayjs(item[0]).startOf('day').toISOString() : '',
            end: item[1] ? dayjs(item[1]).endOf('day').toISOString() : '',
          }
        }) : []
        const timeRangeSettings = get(formData, 'timeOffConfig') && get(formData, 'timeOffConfig.timeRangeSettings').length > 0 ? formData.timeOffConfig.timeRangeSettings.map((item) => {
          return {
            start: addTimeToDateTime(item.date, item.timeStart),
            end: addTimeToDateTime(item.date, item.timeEnd),
          }
        }) : []
        data = {
          enableTimeOff: get(formData, 'enableTimeOff'),
          timeOffConfig: {
            months: get(formData, 'timeOffConfig.months') || null,
            weekdays: get(formData, 'timeOffConfig.weekdays') || null,
            dateRangeSettings,
            timeRangeSettings,
          },
        }
      }
      return data
    })

    const getTimeFromDate = (dateString) => {
      const date = new Date(dateString)
      const hours = date.getHours().toString().padStart(2, '0')
      const minutes = date.getMinutes().toString().padStart(2, '0')
      return hours + ':' + minutes
    }

    const addTimeToDateTime = (dateTime, timeToAdd) => {
      if (dateTime && timeToAdd) {
        const [hoursToAdd, minutesToAdd] = timeToAdd.split(':')

        const result = new Date(dateTime)
        result.setHours(result.getHours() + parseInt(hoursToAdd, 10))
        result.setMinutes(result.getMinutes() + parseInt(minutesToAdd, 10))

        return result
      }
    }

    watch(compactData, () => {
      FormsContext.setFormData('formRef2', { ...compactData.value })
    })

    const addDateRange = () => {
      formData.timeOffConfig.dateRangeSettings.push(JSON.parse(JSON.stringify(emptyDate)))
    }
    const addTimeRange = () => {
      formData.timeOffConfig.timeRangeSettings.push(JSON.parse(JSON.stringify(emptyDateTime)))
    }

    onMounted(async () => {
      await nextTick()
      FormsContext.setFormRef('formRef2', formRef.value)
      FormsContext.setFormData('formRef2', { enableTimeOff: false })
      if (get(props.configData, 'id')) syncFormData(props.configData)
    })

    return {
      formRef,
      formData,
      formRules,
      enableTimeOffOptions,
      addDateRange,
      addTimeRange,
      monthOptions,
      weeksOptions,
      showModal,
    }
  },
})
</script>

  <style lang="postcss" scoped>
.sub-title {
  font-size: 12px;
  color: var(--gray-80);
  font-weight: 500;
  margin-bottom: 32px;
}
.el-radio {
  display: flex;
  margin-bottom: 8px;
}
.date-range {
  @apply bg-[#F5F7FA] rounded-[8px] p-[12px] max-w-[560px] flex items-center
}
.time-range {
  @apply bg-[#F5F7FA] rounded-[8px] p-[12px] max-w-[560px] flex items-center
}
.date-picker {
  @apply w-full mb-[8px]
}
.el-range-editor {
  flex: 1;
}
.el-icon-close {
  @apply w-[24px] h-[24px] flex justify-center items-center ml-[8px]
}
  </style>
