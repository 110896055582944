<template>
  <BaseDialog
    title="編輯銷售人員報表設定"
    :btn1-loading="loading"
    width="610px"
    :btn1Disabled="!formData.config.length && formData.enabled"
    @confirm="onConfirm"
    @cancel="$emit('close')"
    @close="$emit('close')"
  >
    <div>
      <BaseElForm ref="formRef" :model="formData" :rules="formRules" label-position="top" label-width="180px" testName="salesUnitSetting">
        <BaseElFormItem label="管理員檢視設定">
          <BaseElSwitch
            v-model="formData.enabled"
            testName="formData_enabled"
            active-text="開啟"
            inactive-text="關閉"
          />
        </BaseElFormItem>
        <BaseElFormItem v-show="formData.enabled" label="管理員可檢視銷售人員" class="w-[560px]">
          <template #label>
            <div class="w-full inline-flex items-center justify-between">
              <p>管理員可檢視銷售人員</p>
              <BaseElButton slot="reference" type="text" class="py-0" @click="addTarget()">
                新增
                <i class="el-icon-plus " />
              </BaseElButton>
            </div>
          </template>
          <div class="flex flex-col gap-[20px]">
            <div v-for="(item, idx) in formData.config" :key="idx">
              <div class="flex items-center gap-[8px]">
                <BaseElFormItem class="w-full" :prop="`config[${idx}].User`">
                  <ElInputWrapper>
                    <BaseElSelect
                      v-model="item.userId"
                      placeholder="請選擇管理員"
                      clearable
                    >
                      <BaseElSelectOption
                        v-for="manager in bindUsers"
                        :key="manager.id"
                        :label="`${manager.name} (${manager.id.slice(-5)})`"
                        :value="manager.id"
                        :disabled="manager.disabled"
                      />
                    </BaseElSelect>
                  </ElInputWrapper>
                </BaseElFormItem>
                <BaseElFormItem class="w-full" :prop="`config[${idx}].allowedSalesUnitIds`">
                  <ElInputWrapper>
                    <BaseElSelect
                      v-model="item.allowedSalesUnitIds"
                      placeholder="請選擇銷售人員"
                      multiple
                      collapse-tags
                      clearable
                    >
                      <BaseElSelectOption
                        v-for="unit in salesUnits"
                        :key="unit.id"
                        :value="unit.id"
                        :label="unit.name"
                      />
                    </BaseElSelect>
                  </ElInputWrapper>
                </BaseElFormItem>
                <div class="flex items-center cursor-pointer" @click="remvoeTarget(idx)">
                  <SVGIcon icon="common-trash" fill="var(--gray-60)" />
                </div>
              </div>
            </div>

            <GrayBlockContainer v-show="!formData.config.length">
              請點擊右上角新增管理員可檢視對象
            </GrayBlockContainer>
          </div>
        </BaseElFormItem>
      </BaseElForm>
    </div>
  </BaseDialog>
</template>

<script>
import GrayBlockContainer from '@/components/Container/GrayBlockContainer.vue'
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import { useBaseForm } from '@/use/useForm'
import { computed, defineComponent, onBeforeMount, ref } from 'vue'
import { UpdateSalesUnitReportConfig, GetSalesUnitBindList } from '@/api/sales'
import notifyMessage from '@/config/notifyMessage'
import { useShop } from '@/use/shop'
import { get, map } from 'lodash'
import { noEmptyRules } from '@/validation'
import ElInputWrapper from '@/components/ElInputWrapper.vue'

export default defineComponent({
  name: 'SalesUnitSettingModal',
  components: {
    GrayBlockContainer,
    BaseDialog,
    ElInputWrapper,
  },
  props: {
    configData: {
      type: Object,
      default: () => ({}),
    },
  },
  setup (props, { emit }) {
    const { formRef, formData, initFormData, loading, checkForm } = useBaseForm()
    const { shopId } = useShop()
    const salesUnitBindList = ref({})

    const formRules = computed(() => {
      const rules = {
        config: formData.config.map(() => ({
          userId: [noEmptyRules()],
          allowedSalesUnitIds: [noEmptyRules()],
        }))
      }
      return rules
    })
    const bindUsers = computed(() => {
      const bindUsersList = get(salesUnitBindList.value, 'bindUsers', [])
      const selectedUserIds = map(formData.config, (item) => get(item, 'userId'))

      return map(bindUsersList, user => {
        return {
          ...user,
          disabled: selectedUserIds.includes(user.id)
        }
      })
    })
    const salesUnits = computed(() => {
      return get(salesUnitBindList.value, 'salesUnits', [])
    })
    initFormData({
      enabled: false,
      config: [],
    })
    const addTarget = () => {
      formData.config.push({
        userId: '',
        User: '',
        allowedSalesUnitIds: [],
        AllowedSalesUnits: [],
      })
    }
    const remvoeTarget = (idx) => {
      formData.config.splice(idx, 1)
    }
    const onConfirm = async () => {
      if (loading.value) return
      loading.value = true
      if (!(await checkForm(formRef.value))) {
        loading.value = false
        return
      }
      const [, err] = await UpdateSalesUnitReportConfig({
        shopId: shopId.value,
        enabled: formData.enabled,
        config: map(formData.config, (item) => {
          return {
            // User: item.User,
            userId: get(item, 'userId'),
            allowedSalesUnitIds: item.allowedSalesUnitIds,
          }
        }),
      })
      loading.value = false
      if (err) {
        window.$message.error(err)
        return
      }
      window.$message.success(notifyMessage.updateSuccess)
      emit('refresh')
      emit('close')
    }
    const getSalesUnitBindList = async () => {
     const [res, err] = await GetSalesUnitBindList({ shopId: shopId.value })
      if (err) {
        window.$message.error(err)
        return
      }
      salesUnitBindList.value = res
    }
    onBeforeMount(async() => {
      await getSalesUnitBindList()
      if (props.configData) {
        formData.enabled = get(props.configData, 'enabled')
        formData.config = get(props.configData, 'config', [])
      }
    })

    return {
      formRef,
      formData,
      formRules,
      loading,
      onConfirm,
      addTarget,
      remvoeTarget,
      bindUsers,
      salesUnits,
    }
  },
})

</script>

<style lang="postcss" scoped>
::v-deep .el-form-item__label {
  @apply w-full inline-flex
}
</style>
