import { render, staticRenderFns } from "./BasicSettingEditModal.vue?vue&type=template&id=96b02dca&scoped=true"
import script from "./BasicSettingEditModal.vue?vue&type=script&lang=js"
export * from "./BasicSettingEditModal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "96b02dca",
  null
  
)

export default component.exports