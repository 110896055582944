<template>
  <div class="flex flex-col" style="gap: 12px">
    <p class="text-primary-100 font-bold text-normal">票券</p>
    <div>
      <div
        class="coupon-discount-item"
        :class="{ isSelected, isDisabled: disabledControl.coupon }"
        @click="onSelect"
      >
        票券
      </div>
    </div>

    <SalesPOSCouponDiscountSelectModal
      v-if="showModal"
      scope="orderDiscount"
      @close="showModal = false"
      @confirm="onConfirm"
    />
  </div>
</template>

<script>
import SalesPOSCouponDiscountSelectModal from '@/components/SalesPOSCouponDiscountSelectModal.vue'
import { useSalesPOSCreate } from '@/use/useSalesPOS'
import { computed, defineComponent, ref } from 'vue'
import { find, isEmpty, forEach, get } from 'lodash'

export default defineComponent({
  name: 'SalesPOSCheckoutCouponDiscountList',
  components: {
    SalesPOSCouponDiscountSelectModal,
  },
  setup (props, { emit }) {
    const { context, addOrderDiscount, pruneOrderDiscounts, orderUnpaidPrice } = useSalesPOSCreate()
    const showModal = ref(false)
    const isSelected = computed(() => {
      return Boolean(find(context.orderDiscount, { id: 'coupon-discount' }))
    })

    const disabledControl = computed(() => {
      const controls = {
        coupon: false,
      }

      if (!isSelected.value) {
        if (orderUnpaidPrice.value <= 0 || find(context.orderDiscount, { id: 'coupon-discount' })) {
          controls.coupon = true
        }
      }

      return controls
    })

    const onSelect = () => {
      if (disabledControl.value.coupon) return
      if (isSelected.value) {
        pruneOrderDiscounts('coupon-discount')
        return
      }
      showModal.value = true
    }

    const onConfirm = (data) => {
      if (!data || isEmpty(data)) return
      forEach(data, (item) => {
        addOrderDiscount({
          id: 'coupon-discount',
          name: item.name,
          mustSpendSetting: {
            type: get(item, 'mustSpendSetting.type'),
            couponRecordId: get(item, 'couponRecord.id'),
            amount: 1,
          },
          discountSource: 'discount',
          percentAmount: item.percentAmount,
          cashAmount: item.cashAmount,
          salesDiscountId: item.id,
          type: item.type,
        })
      })

      showModal.value = false
    }

    return { isSelected, showModal, onSelect, onConfirm, disabledControl }
  },
})
</script>

<style lang="postcss" scoped>
.coupon-discount-item {
  @apply font-bold text-sub text-gray-80 p-[8px] select-none cursor-pointer;
}
.isSelected {
  @apply bg-primary-30 text-primary-100;
}
.isDisabled {
  @apply opacity-50 cursor-not-allowed;
}
</style>
