import configuration from '@/configuration'
import ls from '@/utils/localstorage'

const state = {
  isMobile: false,
  sidebar: true,
  sidebarPage: ls.getSidebarPage() || 1,
  appTheme: '',
  commander: false,
  pageChanging: false,
  haveUnreadAnnouncements: false,
  toggle: {
    featureKeyDebugger: false,
  },
  keepAlive: [],
  currentModule: [],
}

const mutations = {
  SET_TOGGLE: (state, { key, toggle }) => {
    state.toggle[key] = toggle
  },
  SET_HAVE_UNREAD_ANNOUNCEMENTS: (state, toggle) => {
    state.haveUnreadAnnouncements = toggle
  },
  SHOW_COMMANDER: (state, toggle) => {
    state.commander = toggle
  },
  TOGGLE_PAGE_CHANGING: (state, toggle) => {
    state.pageChanging = toggle
  },
  TOGGLE_SIDEBAR: (state) => {
    state.sidebar = !state.sidebar
  },
  SET_SIDEBAR_PAGE: (state, sidebarPage) => {
    ls.setSidebarPage(sidebarPage)
    state.sidebarPage = sidebarPage
  },
  SET_SIDEBAR: (state, status) => {
    state.sidebar = status
  },
  UPDATE_VIEW_WIDTH: (state, width) => {
    state.viewWidth = width
  },
  SET_MOBILE: (state, status) => {
    state.isMobile = status
  },
  SET_APP_THEME: (state, theme) => {
    state.appTheme = theme
  },
  SET_KEEP_ALIVE: (state, page) => {
    if (state.keepAlive.length > 1) state.keepAlive.shift()
    if (state.keepAlive.includes(page)) return
    state.keepAlive.push(page)
  },
  EMPTY_KEEP_ALIVE: (state) => {
    state.keepAlive = []
  },
  SET_CURRENT_MODULE(state, module) {
    // console.log('🔥🔥🔥 module', module)
    if (state.currentModule.includes(module)) return
    state.currentModule.push(module)
    // console.log('🔥🔥🔥 state.currentModule', state.currentModule)
  }
}

const actions = {
  toggleSidebar ({ commit }) {
    commit('TOGGLE_SIDEBAR')
  },
  setSidebarPage ({ commit }, page) {
    commit('SET_SIDEBAR_PAGE', page)
  },
  setSidebar ({ commit }, status) {
    commit('SET_SIDEBAR', status)
  },
  setMobile ({ commit }, status) {
    commit('SET_MOBILE', status)
  },
  emptyKeepAlive ({ commit }) {
    commit('EMPTY_KEEP_ALIVE')
  },
}

const getters = {
  usePermissionsDebugger () {
    return ['dev', 'uat'].includes(configuration('appRuntime'))
  },
  useSidebarSearch () {
    return ['dev', 'uat'].includes(configuration('appRuntime'))
  },
  useRecentShops () {
    return ['dev', 'uat'].includes(configuration('appRuntime'))
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
