<template>
  <div class="member-center">
    <PageTitle
      icon="chevron_left"
      title="會員中心設定"
      hideBtn
      style="padding-bottom: 24px"
      @iconClick="$router.push({ name: 'Parameters' })"
    />

    <!-- 會員中心連結 -->
    <MemberCenterLink v-if="useLiff" data-permit="'admin.lineLiff.page'" :liff="liff" @refresh="getLiff" />
    <!-- 客服連結 -->
    <ContactUsLink @update="refresh" />

    <MemberCenterColorTheme />
    <!-- 會員卡顯示設定 -->
    <MemberCardShow
      v-if="functionMenu"
      :configData="functionMenuShowConfig"
      @refresh="refresh"
    />

    <MemberCardFuncShow :functionMenuConfig="functionMenuShowConfig" />

    <section class="area-wrapper section-block">
      <SectionTitle title="底部快捷功能設定" @edit="showNavSettingDialog = true" />

      <BaseElForm label-position="left" label-width="65px">
        <BaseElFormItem label="排序1">
          <p>會員中心</p>
        </BaseElFormItem>
        <BaseElFormItem label="排序2">
          <p :class="{ 'gray-text': !formData.nav2.type }">
            {{ navTrans(formData.nav2.type) }}
          </p>
          <p>{{ formData.nav2.name }}</p>
          <p>{{ navUrl(formData.nav2) }}</p>
        </BaseElFormItem>
        <BaseElFormItem label="排序3">
          <p :class="{ 'gray-text': !formData.nav3.type }">
            {{ navTrans(formData.nav3.type) }}
          </p>
          <p>{{ formData.nav3.name }}</p>
          <p>{{ navUrl(formData.nav3) }}</p>
        </BaseElFormItem>
        <BaseElFormItem label="排序4">
          <p :class="{ 'gray-text': !formData.nav4.type }">
            {{ navTrans(formData.nav4.type) }}
          </p>
          <p>{{ formData.nav4.name }}</p>
          <p>{{ navUrl(formData.nav4) }}</p>
        </BaseElFormItem>
        <BaseElFormItem label="排序5">
          <p :class="{ 'gray-text': !formData.nav5.type }">
            {{ navTrans(formData.nav5.type) }}
          </p>
          <p>{{ formData.nav5.name }}</p>
          <p>{{ navUrl(formData.nav5) }}</p>
        </BaseElFormItem>
      </BaseElForm>
    </section>

    <ExternalMemberCenterSetting v-if="useMemberQRCode" />

    <!-- Dialog -->

    <el-dialog
      title="提示"
      :visible.sync="navItemWarningDialog"
      width="590px"
      :close-on-click-modal="false"
    >
      <p>請至「公司資訊設定」設定聯繫客服方式。</p>
      <div slot="footer">
        <BaseElButton type="primary">確認</BaseElButton>
      </div>
    </el-dialog>

    <el-dialog
      title="提示"
      :visible.sync="updateWarningDialog"
      width="590px"
      :close-on-click-modal="false"
    >
      <p>請至少選擇兩項快捷功能。</p>
      <div slot="footer">
        <BaseElButton type="primary">確認</BaseElButton>
      </div>
    </el-dialog>

    <NavFooterSettingDialog
      v-if="showNavSettingDialog"
      :navData="formData"
      :customerLink="contactCustomerLink"
      :allowNavFooter="allowNavFooterComputed"
      @close="showNavSettingDialog = false"
      @save="updateNavFooters"
    />
  </div>
</template>

<script>
import PageTitle from '@/components/Title/PageTitle.vue'
import MemberCenterLink from './components/MemberCenterLink.vue'
import MemberCardShow from './MemberCardShow/MemberCardShow.vue'
import ContactUsLink from './components/ContactUsLink.vue'
import MemberCardFuncShow from './components/MemberCardFuncShow.vue'
import ExternalMemberCenterSetting from './components/ExternalMemberCenterSetting.vue'
import MemberCenterColorTheme from './components/MemberCenterColorTheme.vue'
import { navConfig } from '@/config/memberCenter'
import {
  GetMemberCenterConfig,
  UpdateMemberCenterConfig,
  GetShopInfo,
  GetAllowFooterNav,
  // GetAllowFuncMenu,
} from '@/api/member/memberCenter'
import {
  GetLiff,
} from '@/api/shop'

import NavFooterSettingDialog from '@/views/Parameters/MemberCenter/components/NavFooterSettingDialog.vue'
import { onMounted, computed } from 'vue'
import { mapGetters } from 'vuex'
import { filter, forEach, find } from 'lodash'
import SectionTitle from '@/components/Title/SectionTitle.vue'
import { checkUserFeature } from '@/store/modules/permission'
import { useExternalWallet } from '@/use/useExternalWallet'
import { usePermissions } from '@/use/permissions'

export default {
  name: 'MemberCenterSetting',
  components: {
    PageTitle,
    SectionTitle,
    NavFooterSettingDialog,
    MemberCenterLink,
    MemberCardShow,
    MemberCardFuncShow,
    ContactUsLink,
    ExternalMemberCenterSetting,
    MemberCenterColorTheme,
  },
  setup () {
    const { checkAction, checkActionList } = usePermissions()
    const usePospal = computed(() => checkActionList(['adminView.memberCenterConfig.findPosPalMemberWallet', 'admin.externalWallet.page']))
    const { externalWalletName, getExternalWalletConfig, enableExternalWallet } = useExternalWallet()
    const useWallet = computed(() => checkAction('admin.walletRecord.page'))
    const navTrans = (key) => {
      if (key === 'posPalMemberWallet') {
        return externalWalletName.value
      }
      return navConfig[key] ? navConfig[key].name : '尚未選擇'
    }
    onMounted(() => {
      getExternalWalletConfig()
    })
    return { navTrans, useWallet, enableExternalWallet }
  },
  data: () => ({
    liff: null,
    // navConfig,
    config: null,
    // allowFuncMenu: null,
    allowNavFooter: null,
    contactCustomerLink: null,
    navItemWarningDialog: false,
    updateWarningDialog: false,
    showNavSettingDialog: false,
    functionMenuShowConfig: [],
    functionMenu: {
      wallet: true,
      coupon: false,
      point: false,
      address: false,
    },
    formData: {
      nav2: {
        type: '',
        name: '',
        link: {
          url: '',
        },
      },
      nav3: {
        type: '',
        name: '',
        link: {
          url: '',
        },
      },
      nav4: {
        type: '',
        name: '',
        link: {
          url: '',
        },
      },
      nav5: {
        type: '',
        name: '',
        link: {
          url: '',
        },
      },
    },
  }),

  computed: {
    ...mapGetters(['shop', 'userPlanFeature', 'userFeatures']),

    navFooters () {
      const footers = [{ type: 'memberCenter' }]
      const others = filter(this.formData, (nav) => nav !== '')
      forEach(others, (nav) => footers.push({ type: nav }))
      return footers
    },

    useSalesRecordFeature () {
      return checkUserFeature(this.userPlanFeature, this.userFeatures, 'admin.salesRecord.page')
    },
    useMemberQRCode () {
      return checkUserFeature(this.userPlanFeature, this.userFeatures, 'admin.memberCenterQRcode.page')
    },
    useLiff () {
      return checkUserFeature(this.userPlanFeature, this.userFeatures, 'admin.lineLiff.page')
    },
    allowNavFooterComputed () {
      if (!this.useWallet) {
        return filter(this.allowNavFooter, (i) => i.type !== 'walletRecord')
      }
      if (!this.enableExternalWallet) {
        return filter(this.allowNavFooter, (i) => i.type !== 'posPalMemberWallet')
      }
      return this.allowNavFooter
    },
  },

  async mounted () {
    await this.refresh()
  },

  methods: {
    async getMemberCenterConfig () {
      const [res, err] = await GetMemberCenterConfig({ shopId: this.shop })
      if (err) return this.$message.error(err)
      return res
    },

    // navTrans (key) {
    //   if (key === 'posPalMemberWallet') {
    //     return externalWalletName.value
    //   }
    //   return navConfig[key] ? navConfig[key].name : '尚未選擇'
    // },

    navUrl (nav) {
      if (!nav.link) return ''
      const linkType = nav.link.type
      if (linkType === 'lineId') return nav.link.lineId
      else if (linkType === 'email') return nav.link.email
      else if (linkType === 'phone') return nav.link.phone
      else if (linkType === 'url') return nav.link.url
      return nav.link.type
    },

    async refresh () {
      await this.getShopInfo()
      const config = await this.getMemberCenterConfig()
      this.config = config

      // await this.getAllowFuncMenu()
      await this.getAllowFooterNav()
      this.getLiff()

      // 同步功能快捷欄資料
      const syncNavType = (index) => {
        if (config.footers[index]) {
          return {
            type: config.footers[index].type || '',
            name: config.footers[index].name || '',
            link: config.footers[index].link || {},
            // link: {
            //   url: config.footers[index].link
            //     ? config.footers[index].link.url
            //     : '',
            //   type: config.footers[index].link
            //     ? config.footers[index].link.type
            //     : '',
            // },
          }
        }
        return {
          type: '',
          name: '',
          link: { url: '', type: '' },
        }
      }

      this.formData.nav2 = syncNavType(1)
      this.formData.nav3 = syncNavType(2)
      this.formData.nav4 = syncNavType(3)
      this.formData.nav5 = syncNavType(4)

      // 同步功能菜單資料
      if (!config.functionMenuSetting) {
        // 如果沒資料 自動打一次更新當預設資料
        await this.updateFunctionMenu()
        return
      }

      this.functionMenuShowConfig = config.functionMenuSetting

      const getFunctionMenuConfig = (type) => {
        const item = find(config.functionMenuSetting, { type: type })
        return item ? item.enable : false
      }

      this.functionMenu.wallet = getFunctionMenuConfig('walletBalance')
      this.functionMenu.coupon = getFunctionMenuConfig('couponCount')
      this.functionMenu.point = getFunctionMenuConfig('bonusPointsBalance')
      this.functionMenu.address = getFunctionMenuConfig('userInfoMoreAddress')
    },

    hasTypeDuplicates (data) {
      console.log(data)
      return data.some((one, index) => {
        return data.findIndex((o) => o.type === one.type) !== index
      })
    },

    async updateNavFooters (data) {
      const [res, err] = await UpdateMemberCenterConfig({
        shopId: this.shop,

        footers: data,
      })
      if (err) return this.$message.error(err)
      console.log(res)
      this.$message.success('更新成功！')
      this.showNavSettingDialog = false
      await this.refresh()
    },

    async updateFunctionMenu () {
      const [res, err] = await UpdateMemberCenterConfig({
        shopId: this.shop,
        functionMenuSetting: [
          { type: 'walletBalance', enable: this.functionMenu.wallet },
          { type: 'couponCount', enable: this.functionMenu.coupon },
          { type: 'bonusPointsBalance', enable: this.functionMenu.point },
          { type: 'userInfoMoreAddress', enable: this.functionMenu.address },
        ],
      })
      if (err) return this.$message.error(err)
      console.log(res)
      this.$message.success('更新成功！')
      await this.refresh()
    },

    async updateCardFunctionMenu (data) {
      const { key, value } = data
      console.log(key, value)
      this.functionMenu[key] = value
      await this.updateFunctionMenu()
    },

    async getShopInfo () {
      const [res, err] = await GetShopInfo({ shopId: this.shop })
      if (err) return this.$message.error(err)
      this.contactCustomerLink = res.contactCustomerLink
    },

    // async getAllowFuncMenu() {
    //   const [res, err] = await GetAllowFuncMenu({ shopId: this.shop })
    //   if (err) return this.$message.error(err)
    //   this.allowFuncMenu = res
    // },
    async getAllowFooterNav () {
      const [res, err] = await GetAllowFooterNav({ shopId: this.shop })
      if (err) return this.$message.error(err)
      this.allowNavFooter = res
    },

    async getLiff () {
      try {
        const res = await GetLiff({ shopId: this.shop })
        this.liff = res
      } catch (error) {
        this.liff = []
      }
    },
  },
}
</script>

<style lang="postcss" scoped>
.area-wrapper {
  @apply bg-white px-[30px] py-[24px];
}

.container-title {
  @apply font-medium text-[24px] leading-[34.75px];
}

.container-hint {
  @apply text-gray-20;
}

.gray-text {
  @apply text-gray-20;
}
</style>
