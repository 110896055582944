<template>
  <div class="sales-pos-normal-discount-list">
    <p class="font-bold text-primary-100" style="margin-bottom: 12px">優惠</p>
    <div v-if="showControl.normalDiscounts" class="w-full">
      <swiper
        v-if="loaded && displayData.length"
        class="w-full h-full"
        style="padding-bottom: 30px"
        navigation
        pagination
        centeredSlides
        :slides-per-view="1"
        :loop="false"
      >
        <swiper-slide v-for="(chunk, chunkIdx) in displayData" :key="chunkIdx">
          <div class="normal-discount-list-chunk ">
            <div
              v-for="item in chunk"
              :key="item.id"
              class="normal-discount-item"
              :class="{ isSelected: isSelected(item), isDisabled: isDisabled(item) }"
              @click="onSelect(item)"
            >
              {{ item.name }}
            </div>
          </div>
        </swiper-slide>
      </swiper>
    </div>

    <div v-if="showControl.unsetDiscounts" class="flex justify-center items-center text-gray-60" style="padding: 12px 0">
      尚未設定優惠
    </div>

    <!-- <SalesPOSManualCashDiscountModal
      v-if="modal.manualDiscount"
      scope="singleDiscount"
      @close="modal.manualDiscount = false"
      @confirm="onManualDiscountConfirm"
    /> -->
    <SalesPOSManualCashDiscountModal
      v-if="modal.customDiscount"
      scope="singleDiscount"
      @close="modal.customDiscount = false"
      @confirm="onCustomDiscountConfirm"
    />
  </div>
</template>

<script>
import { useSalesPOSCreate } from '@/use/useSalesPOS'
import { chunk, filter, find, get } from 'lodash'
import { computed, defineComponent, ref, watch, nextTick, onMounted, reactive } from 'vue'
import { Swiper, SwiperSlide, SwiperCore } from 'swiper-vue2'
import { Navigation } from 'swiper/core'
import 'swiper/swiper-bundle.css'
import SalesPOSManualCashDiscountModal from '@/components/SalesPOSManualCashDiscountModal.vue'

SwiperCore.use([Navigation])
export default defineComponent({
  name: 'SalesPOSNormalDiscountList',
  components: {
    Swiper,
    SwiperSlide,
    SalesPOSManualCashDiscountModal,
  },
  setup (props, { emit }) {
    const { sortedMenus, context, getActiveCartItem } = useSalesPOSCreate()
    const loaded = ref(false)
    const modal = reactive({
      manualDiscount: false,
      customDiscount: false,
    })
    const selectDiscount = ref(null)

    const displayData = computed(() => {
      const dataList = sortedMenus.value.discount
      const showList = filter(dataList, { mustSpendSetting: null })
      return chunk(showList, 10)
    })

    const showControl = computed(() => {
      const controls = {
        normalDiscounts: true,
        unsetDiscounts: false,
      }

      if (!displayData.value.length) {
        controls.normalDiscounts = false
        controls.unsetDiscounts = true
      }

      return controls
    })

    const isSelected = computed(() => {
      return (item) => {
        const activeCartItem = getActiveCartItem()
        return Boolean(find(get(activeCartItem, 'discounts'), { id: item.id }))
      }
    })

    const isDisabled = computed(() => {
      return (item) => {
        if (!isSelected.value(item)) {
          const activeCartItem = getActiveCartItem()
          if (activeCartItem.getDiscountRemainingPrice() <= 0) return true
        }
        return false
      }
    })

    const refreshSwiper = () => {
      const prevBtn = document.querySelector('.sales-pos-normal-discount-list .swiper-button-prev')
      const nextBtn = document.querySelector('.sales-pos-normal-discount-list .swiper-button-next')
      const chevronRightIcon = document.createElement('span')
      chevronRightIcon.classList.add('material-icons')
      chevronRightIcon.textContent = 'chevron_right'
      chevronRightIcon.style.fontSize = '24px'
      chevronRightIcon.style.width = '24px'
      chevronRightIcon.style.color = 'var(--primary-100)'

      const chevronLefttIcon = document.createElement('span')
      chevronLefttIcon.classList.add('material-icons')
      chevronLefttIcon.textContent = 'chevron_left'
      chevronLefttIcon.style.fontSize = '24px'
      chevronLefttIcon.style.width = '24px'
      chevronLefttIcon.style.color = 'var(--primary-100)'

      // 清空 prevBtn 和 nextBtn 的內容，避免重複添加
      if (prevBtn) prevBtn.innerHTML = ''
      if (nextBtn) nextBtn.innerHTML = ''

      if (prevBtn) prevBtn.appendChild(chevronLefttIcon)
      if (nextBtn) nextBtn.appendChild(chevronRightIcon)
    }

    const onSelect = (data) => {
      selectDiscount.value = data
      if (isDisabled.value(data)) return
      const target = getActiveCartItem()
      if (!target) return

      const isExist = find(target.discounts, (item) => item.id === data.id)

      if (isExist) {
        target.discounts = filter(target.discounts, (item) => item.id !== data.id)
      } else {
        if (data.type === 'free') {
          modal.customDiscount = true
          return
        }
        if (data.id === 'manual') {
          modal.manualDiscount = true
          return
        }

        target.addSingleDiscount({
          ...data,
          salesDiscountId: data.id,
          discountSource: 'discount',
          order: null,
        })
      }
    }

    const onManualDiscountConfirm = (data) => {
      const target = getActiveCartItem()
      if (!target) return

      target.addSingleDiscount({
        ...data,
        discountSource: 'noSource',
        order: null,
      })
    }

    const onCustomDiscountConfirm = (data) => {
      const target = getActiveCartItem()
      if (!target) return

      target.addSingleDiscount({
        ...data,
        id: selectDiscount.value.id,
        name: selectDiscount.value.name,
        salesDiscountId: selectDiscount.value.id,
        discountSource: 'discount',
        order: null,
      })
    }

    watch(displayData, async () => {
      loaded.value = false
      await nextTick()
      setTimeout(() => {
        refreshSwiper()
      }, 100)
      loaded.value = true
    })

    onMounted(async () => {
      loaded.value = false
      await nextTick()
      setTimeout(() => {
        refreshSwiper()
      }, 100)
      loaded.value = true
    })

    return {
      displayData,
      isSelected,
      loaded,
      onSelect,
      onManualDiscountConfirm,
      onCustomDiscountConfirm,
      modal,
      isDisabled,
      showControl,
    }
  },
})
</script>

<style lang="postcss" scoped>
.normal-discount-list-chunk {
  @apply grid grid-cols-5 gap-[12px] px-[32px];
}

.normal-discount-item {
  @apply bg-primary-10 rounded-[6px] h-[88px];
  @apply flex items-center justify-center text-center;
  @apply select-none cursor-pointer;
  @apply border-[2px] border-transparent;
}

.isSelected {
  @apply border-primary-100 border-[2px] bg-white text-primary-100;
}

.isDisabled {
  @apply border-gray-20 bg-gray-20 cursor-not-allowed;
}

::v-deep(.swiper-button-prev) {
  @apply bg-primary-30 left-0 top-[50%] cursor-pointer;
  @apply w-[24px] h-[24px] rounded-[6px];
}

::v-deep(.swiper-button-next) {
  @apply bg-primary-30 right-0 top-[50%] cursor-pointer;
  @apply w-[24px] h-[24px] rounded-[6px];
}

::v-deep(.swiper-pagination) {
  @apply bottom-0;
}

::v-deep(.swiper-pagination-bullet-active) {
  @apply bg-primary-100;
}
::v-deep(.swiper-button-prev::after) {
  content: none;
}
::v-deep(.swiper-button-next::after) {
  content: none;
}
</style>
