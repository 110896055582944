<template>
  <HorizontalTabs
    :tabs="displayTabs"
    :value.sync="context.categoryGroup"
    type="card"
    :tab-gap="0"
    @select="onSelectTab"
  />
</template>

<script>
import { computed, defineComponent } from 'vue'
import HorizontalTabs from '@/components/HorizontalTabs.vue'
import { map, get } from 'lodash'
import { useSalesPOSCreate } from '@/use/useSalesPOS'
import { usePermissions } from '@/use/permissions'

export default defineComponent({
  name: 'SalesPOSCategoryGroupTab',
  components: {
    HorizontalTabs,
  },
  setup (props, { emit }) {
    const { context, filterCategoryMenu, filterProductGroupMenu, sortedMenus } = useSalesPOSCreate()
    const { checkAction } = usePermissions()

    const displayTabs = computed(() => {
      const dataList = sortedMenus.value.categoryGroup
      const showList = map(dataList, (item) => {
        return {
          label: get(item, 'name'),
          key: get(item, 'id'),
        }
      })
      if (get(context.member, 'id')) {
        if (checkAction('admin.appointmentReservation.page')) {
          showList.unshift({ label: '今日預約', key: 'appointment' })
        }
      }

      showList.push({ label: '其他', key: null })

      return showList
    })

    const onSelectTab = () => {
      if (context.categoryGroup === 'appointment') {
        context.category = null
        context.productGroup = null
      } else {
        context.category = get(filterCategoryMenu(context.categoryGroup), '[0].id', null)
        context.productGroup = get(filterProductGroupMenu(context.category), '[0].id', null)
      }
    }

    return {
      context,
      displayTabs,
      onSelectTab,
    }
  },
})
</script>

<style lang="postcss" scoped>
::v-deep(.el-tabs__header) {
  @apply !border-none bg-[#F6F7FB];
}

::v-deep(.el-tabs__nav) {
  @apply !border-none;
}

::v-deep(.el-tabs__item) {
  @apply !border-none text-normal leading-[20.27px] h-auto py-[8px] text-gray-80;
  @apply hover:text-primary-50;
}

::v-deep(.el-tabs__item.is-active) {
  @apply bg-white text-gray-80;
  @apply !border-b-[2px] !border-b-primary-100 !border-solid;
  border-left: 0px !important;
}
</style>
