<template>
  <BaseElSelect
    v-model="syncModel"
    v-bind="$attrs"
    :multiple="multiple"
    filterable
    remote
    clearable
    reserve-keyword
    :placeholder="$t('reservationCalendar.search.service.placeholder')"
    :no-match-text="$t('common.select.noMatch.text')"
    :no-data-text="$t('common.table.empty.text')"
    :remote-method="remoteSearch"
    :loading="loading"
    value-key="id"
    @change="$emit('change')"
    @clear="getService"
  >
    <i slot="prefix" class="el-input__icon el-icon-search" />
    <BaseElSelectOption
      v-for="item in servicesList"
      :key="item.id"
      :label="item.name"
      :value="item"
    />
  </BaseElSelect>
</template>

<script>
import { GetService } from '@/api/service'
import { mapGetters } from 'vuex'
export default {
  name: 'ServiceSearch',
  props: ['model', 'type', 'multiple'],

  computed: {
    ...mapGetters([
      'shop',
    ]),
    syncModel: {
      get () {
        return this.model
      },

      set (data) {
        this.$emit('update:model', data)
      },
    },
  },

  // watch: {
  //   model(){
  //     this.servicesList = this.model
  //   }
  // },

  data: () => ({
    loading: false,
    servicesList: [],
  }),

  async mounted () {
    await this.getService()
  },

  methods: {
    async remoteSearch (query) {
      query = query.trim()
      if (query === '') this.servicesList = []

      try {
        this.loading = true
        setTimeout(async () => {
          this.getService(query)
          this.loading = false
        }, 200)
      } catch (error) {
        console.log(error)
      }
    },

    async getService (query = null) {
      try {
        this.servicesList = []
        setTimeout(async () => {
          const res = await GetService({
            shopId: this.shop,
            name: (query) || undefined,
            limit: 50,
          })
          this.servicesList = res
        }, 200)
      } catch (error) {
        console.log(error)
      }
    },
  },

}
</script>

<style scoped lang="postcss">

</style>
