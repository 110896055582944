import {
  GetSalesPosCreationConfig,
  GetSalesPosMemberDetail,
} from '@/api/salesPOS'
import { computed, reactive, ref, set } from 'vue'
import { useShop } from './shop'
import { createGlobalState } from '@vueuse/core'
import { sortOrder } from '@/utils/helper'
import { filter, get, map, find, includes, reduce, isUndefined, forEach, isEmpty, flatten, toNumber } from 'lodash'
import { nanoid } from 'nanoid'
import { useRoute } from 'vue-router/composables'
import dayjs from '@/lib/dayjs'

export const useSalesPOSCreate = createGlobalState(() => {
  const { shopId } = useShop()
  const route = useRoute()

  const context = reactive({
    categoryGroup: null,
    category: null,
    productGroup: null,
    salesUnitGroup: null,
    member: null,
    product: null,
    salesUnit: null,

    cart: {
      item: null,
    },
    payments: [], // 付款方式
    orderDiscount: [], // 整單折扣

    oldCashbackUse: null,
    sellAt: null,
  })

  const originData = reactive({
    oldCashbackUse: null,
  })
  const flags = reactive({
    oldCashbackUseChanged: false,
  })
  const cart = ref([])

  const configData = reactive({
    enableCashbackDiscount: false,
    enablePointDiscount: false,
    pointDiscountRate: 1,
    menu: {
      salesCategoryMenus: [],
      salesGroupMenus: [],
      salesProductMenus: [],
      salesUnitMenus: [],
    },
    paymentTypes: [],
    consumerData: {},
    appointmentOrders: [],
  })

  const getCreateConfig = async () => {
    const [res, err] = await GetSalesPosCreationConfig({
      shopId: shopId.value,
    })
    if (err) {
      window.$message.error(err)
    }
    configData.menu = res.menu
    configData.paymentTypes = res.paymentTypes
    configData.enableCashbackDiscount = res.enableCashbackDiscount
    configData.enablePointDiscount = res.enablePointDiscount
    configData.pointDiscountRate = res.pointDiscountRate
  }

  // => 設定當前選擇的商品
  const setActiveCartItem = (product) => {
    context.cart.item = product.nanoId
    context.categoryGroup = product.categoryGroup
    context.category = product.category
    context.productGroup = product.productGroup
    context.product = product.id

    if (!product.needUnit) {
      context.salesUnit = null
    } else {
      context.salesUnitGroup = product.salesUnitGroup
      context.salesUnit = product.salesUnit
    }
  }

  // => 新增商品到購物車
  const addProductToCart = (product) => {
    const newItem = new SalesPOSProductItem(product)
    cart.value.push(newItem)
    context.cart.item = newItem.nanoId
  }

  // => 從購物車移除商品
  const removeProductFromCart = (productNanoId) => {
    cart.value = filter(cart.value, (item) => item.nanoId !== productNanoId)
  }

  // -- methods --
  // => 重置付款方式
  const resetOrderPayment = () => {
    context.payments = []
    originData.oldCashbackUse = null
    flags.oldCashbackUseChanged = false
  }
  // => 新增付款方式
  const addOrderPayment = ({
    method,
    price,
    type,
    appointmentOrderId,
  }) => {
    context.payments.push(new SalesPOSPaymentItem({ method, price, type, appointmentOrderId }))
  }
  // => 移除付款方式
  const removeOrderPayment = (paymentid) => {
    // TOFIX use nanoId
    context.payments = filter(context.payments, (item) => item.id !== paymentid)
  }
  // => 取得會員資訊
  const getMemberInfoData = async () => {
    if (!get(context.member, 'id')) return
    let date
    const sellAt = dayjs(get(route.query, 'sellAt')).startOf('d').toDate()
    if (sellAt) date = sellAt
    const [res, err] = await GetSalesPosMemberDetail({
      shopId: shopId.value,
      memberId: context.member.id,
      date,
    })
    if (err) {
      window.$message.error(err)
      return
    }
    configData.consumerData = res
    configData.appointmentOrders = get(res, 'todayAppointmentOrderOptions', [])
  }

  const filterGroupMenu = (menu, type) => {
    return filter(get(configData, `menu.${menu}`, []), { salesGroup: { type } })
  }

  // => 給予 categoryGroupId 來過濾顯示的類別
  const filterCategoryMenu = (categoryGroupId) => {
    const sortedCategory = sortedMenus.value.category

    if (categoryGroupId === null) {
      return filter(sortedCategory, { SalesGroupId: null })
    }

    if (categoryGroupId) {
      const filterShowList = filter(sortedCategory, { SalesGroupId: categoryGroupId })
      return filterShowList
    }

    return sortedCategory
  }

  // => 給予 categoryId 來過濾顯示的產品群組
  const filterProductGroupMenu = (categoryId) => {
    const categoryMenu = get(configData, 'menu.salesCategoryMenus', [])
    const categoryProducts = get(configData, 'menu.salesProductMenus', [])

    const selectedCategoryProductIds = get(find(categoryMenu, { salesCategory: { id: categoryId } }), 'salesProductIds', [])
    const selectedCategoryProducts = filter(categoryProducts, (product) => includes(selectedCategoryProductIds, get(product, 'salesProduct.id')))

    const productGroups = map(sortedMenus.value.productGroup, (productGroup) => ({
      ...productGroup,
      products: filter(selectedCategoryProducts, (product) => product.salesProduct.SalesGroupId === productGroup.id),
    }))

    const showList = sortOrder(filter(productGroups, (group) => group.products.length > 0))

    return showList
  }

  const filterSalesUnitMenu = (salesUnitGroupId) => {
    const salesUnitMenus = sortedMenus.value.salesUnit
    if (salesUnitGroupId) {
      return filter(salesUnitMenus, { SalesGroupId: salesUnitGroupId })
    }
    return salesUnitMenus
  }

  const defaultSelect = () => {
    context.categoryGroup = get(sortedMenus.value.categoryGroup, '[0].id') || null
    const categorys = filterCategoryMenu(context.categoryGroup)
    context.category = get(categorys, '[0].id') || null
    const productGroups = filterProductGroupMenu(context.category)
    context.productGroup = get(productGroups, '[0].id') || null
    context.salesUnitGroup = get(sortedMenus.value.salesUnitGroup, '[0].id') || null
    context.salesUnit = get(filterSalesUnitMenu(context.salesUnitGroup), '[0].id')
  }

  // => 初始化
  const init = async () => {
    getMemberInfoData()
    defaultSelect()
  }

  const reset = () => {
    const initialContext = {
      categoryGroup: null,
      category: null,
      productGroup: null,
      salesUnitGroup: null,
      member: null,
      product: null,
      salesUnit: null,

      cart: {
        item: null,
      },
      payments: [], // 付款方式
      orderDiscount: [], // 整單折扣
      sellAt: null,
    }
    const initalConfigData = {
      enableCashbackDiscount: false,
      enablePointDiscount: false,
      pointDiscountRate: 1,
      menu: {
        salesCategoryMenus: [],
        salesGroupMenus: [],
        salesProductMenus: [],
        salesUnitMenus: [],
      },
      paymentTypes: [],
      consumerData: {},
      appointmentOrders: [],
    }

    Object.assign(context, initialContext)
    Object.assign(configData, initalConfigData)

    cart.value = []
  }

  // => 取得當前選擇的商品
  const getActiveCartItem = () => {
    return find(cart.value, { nanoId: context.cart.item })
  }

  // => 取得指定 nanoId 的商品
  const getCartItem = (nanoId) => {
    return find(cart.value, { nanoId })
  }

  // => 取得所有單項折扣總金額
  const getAllSingleDiscountsPrice = () => {
    const discounts = []
    forEach(cart.value, item => {
      discounts.push(item.totalDiscountPrice())
    })
    return reduce(discounts, (sum, item) => {
      return sum + item
    }, 0)
  }

  // => 新增整單折扣
  const addOrderDiscount = (discount) => {
    context.orderDiscount.push(new SalesPOSDiscountItem(discount))
  }
  // => 移除整單折扣
  const removeOrderDiscount = (discount) => {
    const removed = find(context.orderDiscount, { id: discount.id })
    context.orderDiscount = filter(context.orderDiscount, (item) => item.id !== discount.id)
    return removed
  }

  // => 批次移除整單折扣
  const pruneOrderDiscounts = (discountId) => {
    context.orderDiscount = filter(context.orderDiscount, (item) => item.id !== discountId)
  }

  // => 移除無效的單項折扣
  const pruneInvalidSingleDiscount = (cartItem) => {
    const detail = cartItem.getSingleDiscountsDetail({ withNanoId: true })
    // 移除 detail 中 savePrice 為 0 的單項折扣
    const needRemove = filter(detail, { savePrice: 0 })
    forEach(needRemove, (item) => {
      cartItem.discounts = filter(cartItem.discounts, (discount) => discount.nanoId !== item.nanoId)
    })
  }

  // -- getters --
  // => 取得所有整單折扣明細
  const getAllOrderDiscountsDetail = ({ discounts } = {}) => {
    const totalPrice = originTotalPrice.value - getAllSingleDiscountsPrice()
    const singleDiscountsDetail = flatten(map(cart.value, (item) => item.getSingleDiscountsDetail()))
    const preUsed = {
      cashback: reduce(filter(singleDiscountsDetail, { spendRecord: { type: 'cashback' } }), (sum, item) => {
        return sum + item.savePrice
      }, 0),
    }
    const balance = {
      cashback: get(configData, 'consumerData.cashbackBalance', 0),
    }
    return getDiscountDetail({
      totalPrice,
      discounts: discounts || context.orderDiscount,
      preUsed,
      balance,
    })
  }
  // => 取得整單折扣總金額
  const getAllOrderDiscountsPrice = () => {
    const discount = getAllOrderDiscountsDetail()
    return reduce(discount, (sum, item) => {
      return sum + item.savePrice
    }, 0)
  }

  // => 取得所有付款方式總金額
  const getAllOrderPaymentsPrice = () => {
    const payments = context.payments
    if (!context.payments.length) return 0
    return reduce(payments, (sum, item) => {
      return sum + toNumber(item.price)
    }, 0)
  }

  // ?? 所有相關菜單
  const sortedMenus = computed(() => {
    const sortedCategoryGroup = sortOrder(map(filterGroupMenu('salesGroupMenus', 'salesCategory'), 'salesGroup'))
    const sortedCategory = sortOrder(map(get(configData, 'menu.salesCategoryMenus', []), 'salesCategory'))
    const sortedProductGroup = sortOrder(map(filterGroupMenu('salesGroupMenus', 'salesProduct'), 'salesGroup'))
    const sortedUnitGroup = sortOrder(map(filterGroupMenu('salesGroupMenus', 'salesUnit'), 'salesGroup'))
    const sortedProduct = sortOrder(map(get(configData, 'menu.salesProductMenus', []), 'salesProduct'))
    const sortedUnit = sortOrder(map(get(configData, 'menu.salesUnitMenus', []), 'salesUnit'))
    const sortedDiscount = sortOrder(map(get(configData, 'menu.salesDiscountMenus', []), 'salesDiscount'))
    const sortedPaymentType = sortOrder(get(configData, 'paymentTypes', []))

    return {
      categoryGroup: sortedCategoryGroup,
      category: sortedCategory,
      productGroup: sortedProductGroup,
      salesUnitGroup: sortedUnitGroup,
      product: sortedProduct,
      salesUnit: sortedUnit,
      discount: sortedDiscount,
      paymentType: sortedPaymentType,
    }
  })

  // ?? 品項合計 (所有商品金額總和，不扣折扣)
  const originTotalPrice = computed(() => {
    return reduce(cart.value, (sum, item) => {
      const defaultPrice = item.defaultPrice
      const customPrice = item.customPrice
      const quantity = item.quantity
      return sum + (isUndefined(customPrice) ? defaultPrice : customPrice) * quantity
    }, 0)
  })
  // ?? 需付現金金額
  const needPaidCashPrice = computed(() => {
    const singleDiscountsPrice = getAllSingleDiscountsPrice()
    const orderDiscountsPrice = getAllOrderDiscountsPrice()
    const orderPaymentsPrice = getAllOrderPaymentsPrice()

    if (!originTotalPrice.value) return 0
    const total = originTotalPrice.value - singleDiscountsPrice - orderDiscountsPrice - orderPaymentsPrice
    return total
  })

  // ?? 未付款金額
  const orderUnpaidPrice = computed(() => {
    const totalOrderDiscount = getAllOrderDiscountsPrice()
    const totalSingleDiscount = getAllSingleDiscountsPrice()
    const finalTotalPrice = originTotalPrice.value - totalSingleDiscount - totalOrderDiscount
    return finalTotalPrice > 0 ? finalTotalPrice : 0
  })

  // ?? 計算已使用的資源
  const computedUsedResource = computed(() => {
    const detail = flatten(map(cart.value, (item) => item.getSingleDiscountsDetail({ withNanoId: true })))

    const coupon = map(filter(detail, { spendRecord: { type: 'coupon' } }), 'spendRecord.couponRecordId')
    const cashback = reduce(filter(detail, { spendRecord: { type: 'cashback' } }), (sum, item) => {
      return sum + get(item, 'spendRecord.amount', 0)
    }, 0)
    const point = reduce(filter(detail, { spendRecord: { type: 'point' } }), (sum, item) => {
      return sum + get(item, 'spendRecord.amount', 0)
    }, 0)
    const classTicket = {}

    forEach(filter(detail, { spendRecord: { type: 'classTicket' } }), (item) => {
      const classTicketId = item.spendRecord.classTicketRecordId
      if (!get(classTicket, classTicketId)) {
        set(classTicket, classTicketId, 0)
      }
      classTicket[classTicketId]++
    })

    return {
      coupon,
      cashback,
      point,
      classTicket,
    }
  })

  // ?? 格式化最後送出給 api 的資料格式
  const formatOrderData = computed(() => {
    const data = {
      customer: {
        memberId: get(context.member, 'id'),
        phone: get(context.member, 'phone'),
      },
      salesRecordItems: map(cart.value, (item) => item.formatItemData()),
      salesRecordDiscounts: [
        {
          type: 'totalItemDiscount',
          name: '單項折扣總和',
          savePrice: getAllSingleDiscountsPrice(),
        },
        ...getAllOrderDiscountsDetail(),
      ],
      salesRecordPayments: map(context.payments, (payment) => payment.formatPaymentItem()),
      confirmInfo: {
        totalItemsPrice: originTotalPrice.value,
        totalDiscountPrice: getAllSingleDiscountsPrice() + getAllOrderDiscountsPrice(),
        totalPaymentPrice: getAllOrderPaymentsPrice(),
      },
      settings: {
        setSalesCashbackAmount: context.oldCashbackUse,
        sellAt: context.sellAt,
      },
    }

    // 如果有剩餘未結清金額，則手動增加現金付款方式
    if (needPaidCashPrice.value > 0) {
      data.salesRecordPayments.push({
        type: 'cash',
        amount: needPaidCashPrice.value,
      })

      data.confirmInfo.totalPaymentPrice += needPaidCashPrice.value
    }

    return data
  })

  return {
    init,
    cart,
    context,
    getCartItem,
    configData,
    getCreateConfig,
    reset,
    setActiveCartItem,
    addProductToCart,
    sortedMenus,
    filterCategoryMenu,
    filterProductGroupMenu,
    removeProductFromCart,
    getActiveCartItem,
    getAllSingleDiscountsPrice,
    getAllOrderDiscountsPrice,
    getAllOrderDiscountsDetail,
    addOrderDiscount,
    removeOrderDiscount,
    pruneOrderDiscounts,
    formatOrderData,
    originTotalPrice,
    needPaidCashPrice,
    getAllOrderPaymentsPrice,
    addOrderPayment,
    removeOrderPayment,
    resetOrderPayment,
    orderUnpaidPrice,
    pruneInvalidSingleDiscount,
    computedUsedResource,
    originData,
    flags,
  }
})

// 折扣物件
export class SalesPOSDiscountItem {
  constructor (data) {
    this.nanoId = nanoid(10)
    this.cashAmount = data.cashAmount || null
    this.id = data.id
    this.mustSpendSetting = data.mustSpendSetting || null
    this.name = data.name
    // this.order // 排序
    this.salesDiscountId = data.salesDiscountId || null
    this.percentAmount = data.percentAmount || null
    this.type = data.type // cash | percent | point | classTicket | coupon
    this.discountSource = data.discountSource || 'discount' // discount | totalItemDiscount | noSource
    this.balance = data.balance || null
    this.scope = data.scope
  }

  formatDiscountItem ({ price, order }, { withNanoId }) {
    const data = {
      spendRecord: this.mustSpendSetting,
      name: this.name,
      savePrice: price,
      SalesDiscountId: this.salesDiscountId, // bob 銷售優惠
      order,
      type: this.discountSource,
    }
    if (withNanoId) {
      data.nanoId = this.nanoId
    }
    return data
  }
}

// 付款項目物件
export class SalesPOSPaymentItem {
  constructor (data) {
    this.nanoId = nanoid(10)
    this.method = data.method // part | all
    this.price = data.price
    this.type = data.type // 付款方式
    this.appointmentOrderId = data.appointmentOrderId
  }

  formatPaymentItem () {
    const data = {
      AppointmentOrderId: this.appointmentOrderId,
      amount: this.price,
      type: this.type,
    }
    return data
  }
}

// 商品物件
export class SalesPOSProductItem {
  constructor (data) {
    this.defaultPrice = data.defaultPrice
    this.id = data.id
    this.nanoId = nanoid(10)
    this.name = data.name
    this.type = data.type || 'salesProduct' //  salesProduct┃classTicketRecordUse┃appointmentService┃appointmentServiceAttach
    this.quantity = 1
    this.needUnit = data.needUnit
    this.order = data.order
    this.useClassTicket = data.useClassTicket
    this.useCoin = data.useCoin

    this.appointmentData = data.appointmentData

    this.categoryGroup = data.categoryGroup
    this.category = data.category
    this.productGroup = data.productGroup
    this.salesUnitGroup = data.salesUnitGroup
    this.salesUnit = data.salesUnit
    this.customPrice = data.customPrice

    this.discounts = []
  }

  formatData () {}

  // 更新品項的價格
  changePrice (price) {
    this.customPrice = price
  }

  // 更新品項的數量
  changeQuantity (quantity) {
    this.quantity = quantity
  }

  // 更新品項的銷售人員
  updateSalesUnit (salesUnitGroup, salesUnit) {
    this.salesUnitGroup = salesUnitGroup
    this.salesUnit = salesUnit
  }

  // 新增指定的單項折扣
  addSingleDiscount (discount) {
    if (!discount || isEmpty(discount)) return
    // this.discounts.push(discount)
    this.discounts.push(new SalesPOSDiscountItem(discount))
  }

  // 移除指定的單項折扣
  removeSingleDiscount (discount) {
    const removeItems = find(this.discounts, (item) => item.id === discount.id)
    this.discounts = filter(this.discounts, (item) => item.id !== discount.id)
    return removeItems
  }

  // 批量移除單項折扣
  pruneSingleDiscounts (discountId) {
    const removeItems = filter(this.discounts, (item) => item.id === discountId)
    this.discounts = filter(this.discounts, (item) => item.id !== discountId)
    return removeItems
  }

  // getters

  // 取得所有單項折扣明細
  getSingleDiscountsDetail ({ withNanoId } = { withNanoId: false }) {
    const discounts = this.discounts
    const totalPrice = this.totalPrice()
    return getDiscountDetail({ totalPrice, discounts, withNanoId })
  }

  // 單項折扣總金額
  totalDiscountPrice () {
    const discounts = this.getSingleDiscountsDetail()
    return reduce(discounts, (sum, item) => {
      return sum + item.savePrice
    }, 0)
  }

  // 項目總金額
  totalPrice () {
    const defaultPrice = this.defaultPrice
    const customPrice = this.customPrice
    const quantity = this.quantity
    return !isUndefined(customPrice) ? customPrice * quantity : defaultPrice * quantity
  }

  // 格式化成 api 的 cartItem 格式
  formatItemData () {
    const originalPrice = this.defaultPrice
    const customPrice = this.customPrice
    const price = !isUndefined(customPrice) ? customPrice : originalPrice

    const data = {
      type: this.type,
      name: this.name,
      originalPrice: this.defaultPrice,
      price,
      quantity: this.quantity,
      totalPrice: this.totalPrice(),
      itemDiscounts: this.getSingleDiscountsDetail(),
      SalesUnitId: this.salesUnit,
      SalesCategoryId: this.category,
      SalesProductId: this.id,

      tags: get(this.appointmentData, 'tags'),
      AppointmentServiceId: get(this.appointmentData, 'AppointmentServiceId'),
      AppointmentServiceAttachId: get(this.appointmentData, 'AppointmentServiceAttachId'),
      AppointmentOrderId: get(this.appointmentData, 'AppointmentOrderId'),
    }

    if (this.appointmentData) {
      data.SalesProductId = null
    }

    return data
  }

  getPreDiscountRemainingPrice (discountIdx) {
    const discounts = this.discounts
    const defaultPrice = this.defaultPrice
    const customPrice = this.customPrice
    const quantity = this.quantity
    const totalPrice = !isUndefined(customPrice) ? customPrice * quantity : defaultPrice * quantity

    let remainingPrice = totalPrice
    for (let i = 0; i < discountIdx; i++) {
      const discount = discounts[i]
      let discountAmount = 0
      if (discount.type === 'cash') {
        discountAmount = discount.cashAmount
      } else if (discount.type === 'percent') {
        discountAmount = remainingPrice * (discount.percentAmount / 100)
      }
      remainingPrice -= discountAmount
    }

    return remainingPrice
  }

  // 取得折扣後剩餘的金額
  getDiscountRemainingPrice () {
    const totalPrice = this.totalPrice()
    const totalDiscountPrice = this.totalDiscountPrice()
    return totalPrice - totalDiscountPrice
  }
}

class DiscountFactory {
  static create (discount) {
    switch (discount.type) {
    case 'cash':
      return new CashDiscount(discount)
    case 'percent':
      return new PercentDiscount(discount)
    case 'cashback':
      return new CashbackDiscount(discount)
    case 'point':
      return new PointDiscount(discount)
    case 'classTicket':
      return new ClassTicketDiscount(discount)
    case 'coupon':
      return new CouponDiscount(discount)
    default:
      throw new Error(`Unknown discount type: ${discount.type}`)
    }
  }
}

class CashDiscount {
  constructor (discount) {
    this.discount = discount
  }

  calculate ({ remainingPrice, preUsed }) {
    return Math.min(this.discount.cashAmount, remainingPrice)
  }
}

class PercentDiscount {
  constructor (discount) {
    this.discount = discount
  }

  calculate ({ remainingPrice, preUsed }) {
    return remainingPrice - Math.round(remainingPrice * this.discount.percentAmount)
  }
}

class CashbackDiscount {
  constructor (discount) {
    this.discount = discount
  }

  calculate ({ remainingPrice, preUsed, balance }) {
    const useAmount = this.discount.cashAmount

    if (this.discount.id === 'cashback-all') {
      if (remainingPrice <= useAmount) {
        return remainingPrice
      }
      return useAmount
    } else if (this.discount.id === 'cashback-part') {
      if (remainingPrice <= useAmount) {
        return remainingPrice
      }
      return useAmount
    }
    return useAmount
  }
}

class PointDiscount {
  constructor (discount) {
    this.discount = discount
  }

  calculate ({ remainingPrice, preUsed }) {
    const useAmount = this.discount.cashAmount
    if (this.discount.id === 'point-all') {
      if (remainingPrice <= useAmount) {
        return remainingPrice
      }
      return useAmount
    } else if (this.discount.id === 'point-part') {
      if (remainingPrice <= useAmount) {
        return remainingPrice
      }
      return useAmount
    }
    return useAmount
  }
}

class ClassTicketDiscount {
  constructor (discount) {
    this.discount = discount
  }

  calculate ({ remainingPrice, preUsed }) {
    // 整單折抵堂票不算錢
    if (this.discount.scope === 'orderDiscount') return 0
    return remainingPrice
  }
}

class CouponDiscount {
  constructor (discount) {
    this.discount = discount
  }

  calculate ({ remainingPrice, preUsed }) {
    // TODO
    return 0
  }
}

export function getDiscountDetail ({ totalPrice, discounts, preUsed, balance, withNanoId }) {
  let remainingPrice = totalPrice
  const showList = []

  forEach(discounts, (discount, idx) => {
    const discountInstance = DiscountFactory.create(discount)
    const discountAmount = discountInstance.calculate({ remainingPrice, preUsed, balance })

    remainingPrice -= discountAmount
    showList.push(discount.formatDiscountItem({
      price: Math.round(discountAmount),
      order: idx,
    }, { withNanoId }))
  })

  return showList
}
