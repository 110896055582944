<template>
  <el-dialog
    title="銷售報表顯示設定"
    width="60%"
    :visible="true"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <BaseElForm label-position="top">
      <BaseElFormItem required label="實收比例金額是否排除儲值金付款訂單">
        <BaseElRadioGroup v-model="formData.excludeWallet">
          <BaseElRadio :label="true">是</BaseElRadio>
          <BaseElRadio :label="false">否</BaseElRadio>
        </BaseElRadioGroup>
      </BaseElFormItem>
    </BaseElForm>

    <div class="flex justify-end">
      <BaseElButton plain @click="$emit('close')">取消</BaseElButton>
      <BaseElButton type="primary" @click="onConfirm">
        確定
      </BaseElButton>
    </div>
  </el-dialog>
</template>

<script>
import { defineComponent, onMounted, reactive } from 'vue'
import { useDisplaySettings } from '@/use/displaySettings'

export default defineComponent({
  name: 'SalesReportDisplaySettingsDialog',
  setup (props, { emit }) {
    const { displaySettings, getDisplaySettings, updateDisplaySettings } = useDisplaySettings('salesReport', {
      excludeWallet: false,
    })
    const formData = reactive({
      excludeWallet: false,
    })
    const onConfirm = () => {
      updateDisplaySettings(formData)
      emit('confirm')
      emit('close')
    }

    const syncFormData = () => {
      for (const key in displaySettings.value) {
        formData[key] = displaySettings.value[key]
      }
    }

    onMounted(() => {
      getDisplaySettings()
      syncFormData()
    })

    return {
      formData,
      onConfirm,
      displaySettings,
    }
  },
})
</script>

<style lang="postcss" scoped>

</style>
