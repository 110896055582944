import { concat, filter, find, isUndefined, map } from 'lodash'
import { computed, nextTick, ref } from 'vue'
import { useStorage } from '@vueuse/core'

// export interface TabItem {
//   name: string
//   path: string
//   params: Record<string, any>
//   query: Record<string, any>
//   meta: {
//     keepAlive: boolean
//   }
// }

export const usePageTabs = () => {
  const state = useStorage('page_tabs_state', {
    tabs: [],
    activeTab: null,
    excludeCachedTabs: [],
    pinnedTabs: [],
    cachedTabs: [],
  })

  const settings = useStorage('page_tabs_settings', {
    maxTabs: 15,
  })
  const renderRouteView = ref(true)

  const removeTab = (tab) => {
    const index = state.value.tabs.findIndex((item) => item.name === tab.name)
    if (index >= 0) state.value.tabs.splice(index, 1)
  }

  const goToTab = async (tab, router) => {
    const { params, path, query } = tab
    const toParams = {
      params: params || {},
      path,
      query: query || {},
    }
    await router.replace(toParams)
  }

  const setActiveTab = async (tab) => {
    await nextTick()
    state.value.activeTab = tab
  }

  const updateCacheTab = async () => {
    const cacheMap = new Set()

    for (const tab of state.value.tabs) {
      // 跳過強制被排除 keepAlive 的分頁
      const keepAlive = tab.meta.keepAlive
      if (!isUndefined(keepAlive) && !keepAlive) continue
      // tab.matched.forEach((t, i) => {
      //   if (i > 0) {
      //     cacheMap.add(t.name as string)
      //   }
      // })
    }
    state.value.cachedTabs = Array.from(cacheMap)
  }

  const addTab = (tab) => {
    const existTab = find(state.value.tabs, (item) => item.name === tab.name)
    if (existTab) {
      existTab.params = tab.params
      existTab.query = tab.query
      setActiveTab(existTab)
      return
    }
    const maxTabs = settings.value.maxTabs
    if (state.value.tabs.length >= maxTabs) {
      state.value.tabs.shift()
    }
    state.value.tabs.push(tab)
    setActiveTab(tab)
    updateCacheTab()
  }

  // 重新載入分頁
  const refreshTab = async (tab) => {
    state.value.excludeCachedTabs.push(tab.name)
    renderRouteView.value = false
    await new Promise((resolve) => setTimeout(resolve, 200))
    state.value.excludeCachedTabs = filter(state.value.excludeCachedTabs, (name) => name !== tab.name)
    renderRouteView.value = true
  }

  // 關閉分頁
  const closeTab = (tab, router) => {
    const isActivedTab = state.value.activeTab?.name === tab.name
    const tabIndex = state.value.tabs.findIndex((item) => item.name === tab.name)

    // 關閉不是當前分頁
    if (!isActivedTab) {
      removeTab(tab)
      updateCacheTab()
      return
    }

    const beforeTab = state.value.tabs[tabIndex - 1]
    const afterTab = state.value.tabs[tabIndex + 1]
    const nearByTab = afterTab || beforeTab

    removeTab(tab)
    setActiveTab(nearByTab)
    goToTab(nearByTab, router)
  }
  // 關閉所有分頁
  const closeAllTabs = () => {
    state.value.tabs = filter(state.value.tabs, (tab) => {
      // 排除被固定的分頁
      if (state.value.pinnedTabs.includes(tab.name)) return false
      return tab
    })
    updateCacheTab()
  }

  // 關閉其他分頁
  const closeOtherTabs = (tab, router) => {
    state.value.tabs = state.value.tabs.filter((item) => {
      if (item.name === tab.name) return true
      // 排除被固定的分頁
      if (state.value.pinnedTabs.includes(item.name)) return true
      return false
    })
    updateCacheTab()
    goToTab(tab, router)
  }

  // 關閉左邊所有分頁
  const closeLeftTabs = (tab, router) => {
    const computedTabs = state.value.tabs.filter((item) => !state.value.pinnedTabs.includes(item.name))
    const pinnedTabs = map(state.value.pinnedTabs, (name) => find(state.value.tabs, (item) => item.name === name))
    const index = computedTabs.findIndex((item) => item.name === tab.name)
    const leftTabs = computedTabs.slice(index)
    state.value.tabs = concat(pinnedTabs, leftTabs)
    updateCacheTab()
    goToTab(tab, router)
  }
  // 關閉右邊所有分頁
  const closeRightTabs = (tab, router) => {
    const computedTabs = state.value.tabs.filter((item) => !state.value.pinnedTabs.includes(item.name))
    const pinnedTabs = map(state.value.pinnedTabs, (name) => find(state.value.tabs, (item) => item.name === name))
    const index = computedTabs.findIndex((item) => item.name === tab.name)
    const rightTabs = computedTabs.slice(0, index + 1)
    state.value.tabs = concat(pinnedTabs, rightTabs)
    updateCacheTab()
    goToTab(tab, router)
  }

  // 根據名稱關閉分頁
  const closeTabByName = (tab, router) => {
    const existTab = find(state.value.tabs, (item) => item.name === tab.name)
    if (existTab) closeTab(existTab, router)
  }

  // 固定分頁
  const pinTab = (tab) => {
    const existTab = find(state.value.tabs, (item) => item.name === tab.name)
    if (!existTab) return
    const alreadyPinned = state.value.pinnedTabs.includes(tab.name)
    if (!alreadyPinned) state.value.pinnedTabs.push(tab.name)
  }

  // 取消固定分頁
  const unpinTab = (tab) => {
    const existTab = find(state.value.tabs, (item) => item.name === tab.name)
    if (!existTab) return
    state.value.pinnedTabs = state.value.pinnedTabs.filter((name) => name !== tab.name)
  }

  const resetTabs = () => {
    state.value.tabs = []
    state.value.activeTab = null
    state.value.excludeCachedTabs = []
    state.value.pinnedTabs = []
    state.value.cachedTabs = []
  }

  const displayCachedTabs = computed(() => [...state.value.cachedTabs])
  const displayExcludeCachedTabs = computed(() => [...state.value.excludeCachedTabs])
  const isPinTab = computed(() => {
    return (tab) => {
      if (!tab) return false
      return state.value.pinnedTabs.includes(tab.name)
    }
  })

  return {
    state,
    renderRouteView,
    addTab,
    removeTab,
    setActiveTab,
    updateCacheTab,
    refreshTab,
    closeTab,
    closeAllTabs,
    closeTabByName,
    pinTab,
    unpinTab,
    goToTab,
    displayCachedTabs,
    displayExcludeCachedTabs,
    closeLeftTabs,
    closeRightTabs,
    closeOtherTabs,
    settings,
    resetTabs,
    isPinTab,
  }
}
