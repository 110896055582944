export const memberCardConfig = {
  0: { name: '無會員等級', level: 0 },
  1: { name: '銀卡', level: 1 },
  2: { name: '金卡', level: 2 },
  3: { name: '鑽石藍卡', level: 3 },
  4: { name: '曜石黑卡', level: 4 },
}
export const memberLoginTypeConfig = {
  google: { label: 'publicAppointmentReservationList.google.title', value: true },
  // email: { label: '電子郵件驗證', value: false },
}

export const memberOriginsConfig = {
  client: { label: '顧客建立', value: 'client' },
  openapi: { label: '其他', value: 'openapi' },
  admin: { label: '後台建立', value: 'admin' },
  adminBatch: { label: '後台匯入', value: 'adminBatch' },
}

export const memberProfileTabs = [
  { label: '特質雷達圖', value: '360Analysis' },
  { label: '預約狀況', value: 'reservation' },
  { label: '進場紀錄', value: 'entryRecord' },
  { label: '儲值金', value: 'wallet' },
  { label: '點數', value: 'point' },
  { label: '回饋金', value: 'rebate' },
  { label: '點數卡紀錄', value: 'pointCard' },
  { label: '堂票紀錄', value: 'classTicket' },
  { label: '次卡紀錄', value: 'punchCard' },
  { label: '訊息提醒', value: 'notify' },
  { label: '服務歷程', value: 'memberHistory' },
  { label: '會員資訊', value: 'info' },
  { label: '常用收件地點', value: 'memberAddress' },
  { label: '抽獎紀錄', value: 'lotteryHistory' },
  { label: '票券紀錄', value: 'couponHistory' },
  { label: '訂單紀錄', value: 'orderRecord' },
  { label: '商城訂單', value: 'memberShopOrderRecord' },
  { label: '訂閱紀錄', value: 'subscriptionRecord' },
  { label: '會員權益', value: 'periodicBenefit' },
  { label: '第三方消費紀錄', value: 'externalTransaction' },
  { label: '第三方儲值金', value: 'externalWallet' },
]

// 27
export const memberSystemTagTypes = {
  memberLevel: 'memberLevel', // 會員卡等級
  userInfoGender: 'userInfoGender', // 性別
  userInfoAge: 'userInfoAge', // 年齡
  userInfoBirthday: 'userInfoBirthday', // 壽星標記
  memberCreatedAt: 'memberCreatedAt', // 註冊時間
  lastReservationWait: 'lastReservationWait', // 預約即將到來
  lastReservationStart: 'lastReservationStart', // 預約最後預約時間
  cancelReservationCount: 'cancelReservationCount', // 取消預約次數
  absenceReservationCount: 'absenceReservationCount', // 未現預約次數
  reservation90DaysCount: 'reservation90DaysCount', // 預約90天內次數
  reservation180DaysCount: 'reservation180DaysCount', // 預約180天內次數
  reservation365DaysCount: 'reservation365DaysCount', // 預約365天內次數
  completeReservationCount: 'completeReservationCount',
  lastEcommerceOderCreatedAt: 'lastEcommerceOderCreatedAt', // 電商上次消費時間
  ecommerceOrder365DaysCount: 'ecommerceOrder365DaysCount', // 電商365天內消費次數
  ecommerceOrder365DaysTotalPrice: 'ecommerceOrder365DaysTotalPrice', // 電商365天內消費金額
  ecommerceOrderYearlyCount: 'ecommerceOrderYearlyCount', // 電商年度消費次數
  ecommerceOrderYearlyTotalPrice: 'ecommerceOrderYearlyTotalPrice', // 電商年度消費金額
  lastSalesRecordCreatedAt: 'lastSalesRecordCreatedAt', // 銷售上次消費時間
  salesRecordCount: 'salesRecordCount', // 銷售365天內消費次數
  salesRecordQ1TotalPrice: 'salesRecordQ1TotalPrice', // 銷售Q1消費金額
  salesRecordQ2TotalPrice: 'salesRecordQ2TotalPrice', // 銷售Q2消費金額
  salesRecordQ3TotalPrice: 'salesRecordQ3TotalPrice', // 銷售Q3消費金額
  salesRecordQ4TotalPrice: 'salesRecordQ4TotalPrice', // 銷售Q4消費金額
  salesRecordYearlyTotalPrice: 'salesRecordYearlyTotalPrice', // 銷售年度消費金額
  salesRecordAllTotalPrice: 'salesRecordAllTotalPrice', // 銷售歷史消費金額
  salesRecord365DaysCount: 'salesRecord365DaysCount',
  lastLotteryCreatedAt: 'lastLotteryCreatedAt', // 上次抽獎時間
  remainingClassTicketRecord: 'remainingClassTicketRecord', // 剩餘堂票
  remainingWallet: 'remainingWallet', // 剩餘儲值金,
  remainingPoint: 'remainingPoint', // 剩餘點數
  remainingCashback: 'remainingCashback', // 剩餘回饋金

  lastSalesAndExternalTransactionCreatedAt: 'lastSalesAndExternalTransactionCreatedAt', // 合併POS銷售活躍
  salesAndExternalTransactionQ1TotalPrice: 'salesAndExternalTransactionQ1TotalPrice',
  salesAndExternalTransactionQ2TotalPrice: 'salesAndExternalTransactionQ2TotalPrice',
  salesAndExternalTransactionQ3TotalPrice: 'salesAndExternalTransactionQ3TotalPrice',
  salesAndExternalTransactionQ4TotalPrice: 'salesAndExternalTransactionQ4TotalPrice',
  salesAndExternalTransactionYearlyTotalPrice: 'salesAndExternalTransactionYearlyTotalPrice',
  salesAndExternalTransactionAllTotalPrice: 'salesAndExternalTransactionAllTotalPrice',
  salesAndExternalTransaction365DaysCount: 'salesAndExternalTransaction365DaysCount',
  salesAndExternalTransactionCount: 'salesAndExternalTransactionCount',
  lastExternalTransactionCreatedAt: 'lastExternalTransactionCreatedAt',
  externalTransactionQ1TotalPrice: 'externalTransactionQ1TotalPrice',
  externalTransactionQ2TotalPrice: 'externalTransactionQ2TotalPrice',
  externalTransactionQ3TotalPrice: 'externalTransactionQ3TotalPrice',
  externalTransactionQ4TotalPrice: 'externalTransactionQ4TotalPrice',
  externalTransactionYearlyTotalPrice: 'externalTransactionYearlyTotalPrice',
  externalTransactionAllTotalPrice: 'externalTransactionAllTotalPrice',
  externalTransaction365DaysCount: 'externalTransaction365DaysCount',
  externalTransactionCount: 'externalTransactionCount',
  posPalRemainingBalance: 'posPalRemainingBalance',

  memberReferral30DaysCount: 'memberReferral30DaysCount',
  memberReferral365DaysCount: 'memberReferral365DaysCount',
  memberReferralCount: 'memberReferralCount',
  beautyAIMTag: 'beautyAIMTag',
}

// 前端自定義
export const uiGroupTagTypes = {
  memberLevel: 'memberLevel',
  profile: 'profile',
  registry: 'registry',
  memberInvite: 'memberInvite',
  reservationActivity: 'activity',
  reservationLoyalty: 'loyalty',
  ecomActivity: 'ecomActivity',
  ecomLoyalty: 'ecomLoyalty',
  ecomContribute: 'ecomContribute',
  salesActivity: 'salesActivity',
  salesLoyalty: 'salesLoyalty',
  salesContribute: 'salesContribute',
  lotteryActivity: 'lotteryActivity',
  classTicket: 'classTicket',
  mergedPOSSalesLast: 'mergedPOSSales', // 合併POS銷售活躍
  mergedPOSSalesContributes: 'mergedPOSSalesContributes', // 合併POS銷售消費貢獻
  mergedPOSSalesLoyaltyFrequency: 'mergedPOSSalesLoyaltyFrequency', // 合併POS銷售忠誠頻次標籤
  POSSalesLast: 'POSSalesLast', // POS銷售活躍標籤
  POSSalesContributes: 'POSSalesContributes', // POS銷售消費貢獻標籤
  POSSalesLoyaltyFrequency: 'POSSalesLoyaltyFrequency', // POS銷售忠誠頻次標籤
  POSBalance: 'POSBalance', // POS次卡/會員餘額標籤
  beautyAIMTag: 'beautyAIMTag',
}

export const uiGroupTagTypeName = {
  [uiGroupTagTypes.beautyAIMTag]: 'AI 智能標籤',
  [uiGroupTagTypes.memberLevel]: '等級標籤',
  [uiGroupTagTypes.profile]: '樣貌屬性標籤',
  [uiGroupTagTypes.registry]: '新舊會員標籤',
  [uiGroupTagTypes.memberInvite]: '會員推薦標籤',
  [uiGroupTagTypes.reservationActivity]: '預約活躍標籤',
  [uiGroupTagTypes.reservationLoyalty]: '預約忠誠頻次標籤',
  [uiGroupTagTypes.ecomActivity]: '電商活躍標籤',
  [uiGroupTagTypes.ecomLoyalty]: '電商忠誠頻次標籤',
  [uiGroupTagTypes.ecomContribute]: '電商消費貢獻標籤',
  [uiGroupTagTypes.salesActivity]: '銷售活躍標籤',
  [uiGroupTagTypes.salesLoyalty]: '銷售忠誠頻次標籤',
  [uiGroupTagTypes.salesContribute]: '銷售消費貢獻標籤',
  [uiGroupTagTypes.lotteryActivity]: '抽獎活躍標籤',
  [uiGroupTagTypes.classTicket]: '會員餘額標籤',
  [uiGroupTagTypes.mergedPOSSalesLast]: '合併POS銷售活躍標籤',
  [uiGroupTagTypes.mergedPOSSalesContributes]: '合併POS銷售消費貢獻標籤',
  [uiGroupTagTypes.mergedPOSSalesLoyaltyFrequency]: '合併POS銷售忠誠頻次標籤',
  [uiGroupTagTypes.POSSalesLast]: 'POS銷售活躍標籤',
  [uiGroupTagTypes.POSSalesContributes]: 'POS銷售消費貢獻標籤',
  [uiGroupTagTypes.POSSalesLoyaltyFrequency]: 'POS銷售忠誠頻次標籤',
  [uiGroupTagTypes.POSBalance]: 'POS次卡/會員餘額標籤',
}

export const uiGroupTagFeatureKey = {
  [uiGroupTagTypes.mergedPOSSalesLast]: 'admin.memberExternalTransaction.page',
  [uiGroupTagTypes.mergedPOSSalesContributes]: 'admin.memberExternalTransaction.page',
  [uiGroupTagTypes.mergedPOSSalesLoyaltyFrequency]: 'admin.memberExternalTransaction.page',
  [uiGroupTagTypes.POSSalesLast]: 'admin.memberExternalTransaction.page',
  [uiGroupTagTypes.POSSalesContributes]: 'admin.memberExternalTransaction.page',
  [uiGroupTagTypes.POSSalesLoyaltyFrequency]: 'admin.memberExternalTransaction.page',
  [uiGroupTagTypes.POSBalance]: 'admin.memberExternalTransaction.page',
  [uiGroupTagTypes.beautyAIMTag]: 'admin.beautyAIMTag.enable',
}

export const uiGroupTagTypeList = Object.keys(uiGroupTagTypeName).map(
  (key) => {
    // 針對 pospal 相關標籤做特別處理
    const pospalTags = [
      'mergedPOSSalesLast',
      'mergedPOSSalesContributes',
      'mergedPOSSalesLoyaltyFrequency',
      'POSSalesLast',
      'POSSalesContributes',
      'POSSalesLoyaltyFrequency',
      'POSBalance',
    ]
    if (pospalTags.includes(key)) {
      return {
        name: uiGroupTagTypeName[key],
        value: key,
        pos: true,
      }
    }

    // 針對 beautyAI 相關標籤做特別處理
    const beautyAITags = ['beautyAIMTag']
    if (beautyAITags.includes(key)) {
      return {
        name: uiGroupTagTypeName[key],
        value: key,
        beautyAI: true,
      }
    }
    return {
      name: uiGroupTagTypeName[key],
      value: key,
    }
  },
)

export const mapUI2System = {
  [uiGroupTagTypes.beautyAIMTag]: [
    memberSystemTagTypes.beautyAIMTag,
  ],
  [uiGroupTagTypes.memberLevel]: [memberSystemTagTypes.memberLevel],
  [uiGroupTagTypes.profile]: [
    memberSystemTagTypes.userInfoGender,
    memberSystemTagTypes.userInfoAge,
    memberSystemTagTypes.userInfoBirthday,
  ],
  [uiGroupTagTypes.registry]: [memberSystemTagTypes.memberCreatedAt],

  [uiGroupTagTypes.memberInvite]: [
    memberSystemTagTypes.memberReferral30DaysCount,
    memberSystemTagTypes.memberReferral365DaysCount,
    memberSystemTagTypes.memberReferralCount,
  ],
  [uiGroupTagTypes.reservationActivity]: [
    memberSystemTagTypes.lastReservationWait,
    memberSystemTagTypes.lastReservationStart,
  ],

  [uiGroupTagTypes.reservationLoyalty]: [
    memberSystemTagTypes.cancelReservationCount,
    memberSystemTagTypes.absenceReservationCount,
    memberSystemTagTypes.reservation90DaysCount,
    memberSystemTagTypes.reservation180DaysCount,
    memberSystemTagTypes.reservation365DaysCount,
    memberSystemTagTypes.completeReservationCount,
  ],
  [uiGroupTagTypes.ecomActivity]: [
    memberSystemTagTypes.lastEcommerceOderCreatedAt,
  ],
  [uiGroupTagTypes.ecomLoyalty]: [
    memberSystemTagTypes.ecommerceOrder365DaysCount,
    memberSystemTagTypes.ecommerceOrderYearlyCount,
  ],
  [uiGroupTagTypes.ecomContribute]: [
    memberSystemTagTypes.ecommerceOrder365DaysTotalPrice,
    memberSystemTagTypes.ecommerceOrderYearlyTotalPrice,
  ],
  [uiGroupTagTypes.salesActivity]: [
    memberSystemTagTypes.lastSalesRecordCreatedAt,
  ],
  [uiGroupTagTypes.salesLoyalty]: [
    memberSystemTagTypes.salesRecordCount,
    memberSystemTagTypes.salesRecord365DaysCount,
  ],
  [uiGroupTagTypes.salesContribute]: [
    memberSystemTagTypes.salesRecordQ1TotalPrice,
    memberSystemTagTypes.salesRecordQ2TotalPrice,
    memberSystemTagTypes.salesRecordQ3TotalPrice,
    memberSystemTagTypes.salesRecordQ4TotalPrice,
    memberSystemTagTypes.salesRecordYearlyTotalPrice,
    memberSystemTagTypes.salesRecordAllTotalPrice,
  ],
  [uiGroupTagTypes.lotteryActivity]: [
    memberSystemTagTypes.lastLotteryCreatedAt,
  ],
  [uiGroupTagTypes.classTicket]: [
    memberSystemTagTypes.remainingClassTicketRecord,
    memberSystemTagTypes.remainingWallet,
    // memberSystemTagTypes.remainingPoint,
    memberSystemTagTypes.remainingCashback,
  ],

  [uiGroupTagTypes.mergedPOSSalesLast]: [
    memberSystemTagTypes.lastSalesAndExternalTransactionCreatedAt,
  ],
  [uiGroupTagTypes.mergedPOSSalesContributes]: [
    memberSystemTagTypes.salesAndExternalTransactionQ1TotalPrice,
    memberSystemTagTypes.salesAndExternalTransactionQ2TotalPrice,
    memberSystemTagTypes.salesAndExternalTransactionQ3TotalPrice,
    memberSystemTagTypes.salesAndExternalTransactionQ4TotalPrice,
    memberSystemTagTypes.salesAndExternalTransactionYearlyTotalPrice,
    memberSystemTagTypes.salesAndExternalTransactionAllTotalPrice,
  ],
  [uiGroupTagTypes.mergedPOSSalesLoyaltyFrequency]: [
    memberSystemTagTypes.salesAndExternalTransaction365DaysCount,
    memberSystemTagTypes.salesAndExternalTransactionCount,
  ],
  [uiGroupTagTypes.POSSalesLast]: [
    memberSystemTagTypes.lastExternalTransactionCreatedAt,
  ],
  [uiGroupTagTypes.POSSalesContributes]: [
    memberSystemTagTypes.externalTransactionQ1TotalPrice,
    memberSystemTagTypes.externalTransactionQ2TotalPrice,
    memberSystemTagTypes.externalTransactionQ3TotalPrice,
    memberSystemTagTypes.externalTransactionQ4TotalPrice,
    memberSystemTagTypes.externalTransactionYearlyTotalPrice,
    memberSystemTagTypes.externalTransactionAllTotalPrice,
  ],
  [uiGroupTagTypes.POSSalesLoyaltyFrequency]: [
    memberSystemTagTypes.externalTransaction365DaysCount,
    memberSystemTagTypes.externalTransactionCount,
  ],
  [uiGroupTagTypes.POSBalance]: [
    memberSystemTagTypes.posPalRemainingBalance,
  ],

}

export const mapSystem2UI = Object.keys(mapUI2System).reduce((dataMap, key) => {
  mapUI2System[key].forEach((systemtag) => {
    dataMap[systemtag] = key
  })
  return dataMap
}, {})
