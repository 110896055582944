<template>
  <BaseDialog
    title="出帳明細"
    width="480px"
    hideConfirm
    hideCancel
    @close="$emit('close')"
  >
    <SalesPOSCheckoutReceipt />
    <div class="row text-primary-100 font-bold text-normal" style="padding: 0 8px; margin-top: 16px">
      <p>刷卡機（ezAID）</p>
      <p>{{ displayData.orderUnpaidPrice }}</p>
    </div>

    <div class="flex items-center justify-center" style="margin-top: 12px">
      <BaseElButton
        type="primary"
        :loading="loading"
        @click="onConfirm"
      >
        產生結帳 QRcode
      </BaseElButton>
    </div>
  </BaseDialog>
</template>

<script>
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import { useSalesPOSCreate } from '@/use/useSalesPOS'
import { get, find } from 'lodash'

import { computed, defineComponent, ref } from 'vue'
import SalesPOSCheckoutReceipt from './SalesPOSCheckoutReceipt.vue'
import { SalesPosCreateSalesRecord } from '@/api/salesPOS'
import { useShop } from '@/use/shop'

export default defineComponent({
  name: 'SalesPOSCheckoutEzaioReceiptModal',
  components: {
    BaseDialog,
    SalesPOSCheckoutReceipt,
  },
  setup (props, { emit }) {
    const { context, orderUnpaidPrice, formatOrderData, needPaidCashPrice } = useSalesPOSCreate()
    const { shopId } = useShop()
    const loading = ref(false)

    const displayData = computed(() => {
      return {
        orderUnpaidPrice: orderUnpaidPrice.value,
        ezaioPaidPrice: get(find(context.payments, { type: 'ezAIOTrust' }), 'price', 0),
      }
    })

    const onConfirm = async () => {
      if (needPaidCashPrice.value < 0) {
        window.$message.warning('訂單金額不得為負數')
        return
      }
      if (loading.value) return
      loading.value = true
      const [res, err] = await SalesPosCreateSalesRecord({
        shopId: shopId.value,
        ...formatOrderData.value,
      })
      loading.value = false
      if (err) {
        window.$message.error(err)
        return
      }
      window.$message.success('出帳成功')
      emit('generateQRcode', res.id)
    }

    return { displayData, onConfirm, loading }
  },
})
</script>

<style scoped lang="postcss">
.row {
  @apply flex justify-between;
}

.item-row {
  @apply flex justify-between bg-gray-20;
  @apply py-[4px] px-[8px] rounded-[6px];
}
</style>
