<template>
  <el-dialog
    :title="dialogTitle"
    :visible="true"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <div v-if="type === 'other'">
      {{ $t('orderPaymentBlock.otherRefund.title')}}
    </div>

    <BaseElForm v-if="type === 'wallet'" ref="form" :model="formData" :rules="formRules">
      <BaseElFormItem v-if="type === 'wallet'" :label="$t('orderPaymentBlock.walletPrice.title')" prop="walletPrice">
        <BaseElInput v-model="formData.walletPrice" :placeholder="$t('orderPaymentBlock.walletPrice.placeholder')" />
      </BaseElFormItem>
    </BaseElForm>

    <div slot="footer">
      <BaseElButton plain @click="$emit('close')">{{ $t('common.button.cancel.text')}}</BaseElButton>
      <BaseElButton
        ref="createBtn"
        v-loading="loading"
        type="primary"
        @click="refund"
      >
        {{ $t('common.button.confirm.text')}}
      </BaseElButton>
    </div>
  </el-dialog>
</template>

<script>
import { noEmptyRules } from '@/validation'
import formUtils from '@/utils/form'
import { refundLinkConfig, manualRefundPaymentType } from '@/config/deposit'
import { OrderDepositRefund } from '@/api/reservation'
import { get } from 'lodash'
import { mapGetters } from 'vuex'
import { i18n } from '@/plugins/i18n/i18n'
export default {
  name: 'DepositRefundDialog',
  props: {
    type: {
      type: String,
      default: 'wallet',
    },
    payment: {
      type: Object,
      default: () => ({}),
    },
    isManual: {
      type: Boolean,
      default: false,
    },
    orderId: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    loading: false,
    formData: {
      walletPrice: null,
    },
    formRules: {
      walletPrice: [noEmptyRules()],
    },
  }),
  computed: {
    ...mapGetters(['shop']),
    dialogTitle () {
      let label = ''
      if (this.type === 'wallet') label = i18n.t('orderPaymentBlock.dialog.refund.title')
      if (this.type === 'other') label = i18n.t('common.dialog.delete.title')
      return label
    },
  },
  methods: {
    async refund () {
      if (this.type === 'wallet') {
        await this.walletRefund()
        return
      }
      const link = refundLinkConfig[this.payment.type]
      window.open(link)
      this.$emit('close')
    },

    async walletRefund () {
      if (!await formUtils.checkForm(this.$refs.form)) return

      let type = this.payment.type
      if (this.isManual) type = get(manualRefundPaymentType[type], 'value')
      this.loading = true

      const [, err] = await OrderDepositRefund({
        shopId: this.shop,
        id: this.orderId,
        refundType: type,
        refundTypeComment: this.payment.comment || undefined,
        refundPrice: this.formData.walletPrice,
      })
      if (err) {
        console.log(err)
        this.$message.error(err)
        this.loading = false
        return
      }
      this.$message.success(i18n.t('common.message.refundSuccess'))
      this.$emit('refunded')
      this.$emit('close')
      this.loading = false
    },
  },
}
</script>

<style lang="postcss" scoped>
</style>
