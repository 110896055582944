<template>
  <div v-loading="loading.table || loading.select">
    <BaseElSelect
      v-model="syncValue"
      clearable
      multiple
      collapse-tags
      :value-key="keyMap.id"
      popper-class="select-popper"
      @visible-change="modal.selector = true"
      @change="$emit('update')"
    >
      <BaseElSelectOption
        v-for="item in tableData"
        :key="item[keyMap.id]"
        :label="item[keyMap.name]"
        :value="item"
      />
    </BaseElSelect>

    <BaseDialog
      v-if="modal.selector"
      :title="displayDataText.dialogTitle"
      width="500px"
      :btn1="displayDataText.buttons.confirm"
      :btn2="displayDataText.buttons.cancel"
      :btn1-disabled="disabledControl.btn.confirm"
      :close-on-click-modal="true"
      @close="modal.selector = false"
      @confirm="onConfirm"
      @cancel="onCancel"
    >
      <header class="flex flex-col" style="gap: 12px">
        <div class="flex items-center justify-between w-full" style="gap: 12px">
          <ElInputWrapper class="w-full">
            <SearchTextInput
              :value.sync="search.name"
              :placeholder="displayDataText.placeholders.name"
            />
          </ElInputWrapper>
        </div>

        <div class="flex items-center">
          <BaseElButton
            v-if="showControl.multipleOperation"
            class="w-full"
            size="small"
            type="primary"
            :loading="loading.select"
            @click="onSelectAll"
          >
            {{ displayDataText.buttons.selectAll }}
          </BaseElButton>
          <BaseElButton
            class="w-full"
            size="small"
            type="danger"
            :disabled="disabledControl.btn.clearSelect"
            @click="onClearSelect"
          >
            {{ displayDataText.buttons.clearSelected }}
          </BaseElButton>
        </div>

        <div class="text-gray-60 text-sm flex items-center" style="gap: 12px">
          <div class="text-primary">
            {{ displayDataText.hint.selected }}<span
              :class="{
                'text-primary-100': selected.length <= max,
                'text-danger': selected.length > max,
              }"
            >{{ selected.length }}</span>
          </div>
          <div v-if="showControl.hint.maxSelect">
            {{ displayDataText.hint.maxSelect }}<span>{{ max }}</span>
          </div>
        </div>
      </header>

      <BaseDivider />

      <main v-loading="loading.table || loading.select" style="margin-bottom: 40px">
        <div class="overflow-y-auto" style="height: 300px; margin-top: 20px; padding: 12px 0">
          <BaseElCheckboxGroup
            v-model="selected"
            class="flex flex-col"
            style="gap: 16px;"
          >
            <div v-for="product in displayTableData" :key="product.id">
              <div class="grid-container">
                <BaseElCheckbox
                  class="flex items-center"
                  :label="product.id"
                  :disabled="disabledItem(product)"
                >
                  {{ product.name }}
                </BaseElCheckbox>
              </div>
            </div>
          </BaseElCheckboxGroup>
        </div>
      </main>
    </BaseDialog>
  </div>
</template>

<script>
import { defineComponent, reactive, ref, computed, onBeforeMount, nextTick, watch } from 'vue'
import { map, get, flattenDeep, includes, filter } from 'lodash'
import { useTable } from '@/use/table'
import dayjs from '@/lib/dayjs'
import BaseDialog from '../Dialog/BaseDialog.vue'
import SearchTextInput from '../Input/SearchTextInput.vue'
import { useShop } from '@/use/shop'
import ElInputWrapper from '../ElInputWrapper.vue'
import { useFetch } from '@/use/fetch'
import { useVModel } from '@vueuse/core'

export default defineComponent({
  name: 'BaseAdavanceSelector',
  components: {
    BaseDialog,
    SearchTextInput,
    ElInputWrapper,
  },
  props: {
    title: { type: String, default: '請選擇' },
    value: { type: [Array, Object], default: () => [] },
    multiple: { type: Boolean, default: false },
    max: { type: Number },
    min: { type: Number, default: 1 },
    returnKey: { type: [String, null], default: null },
    getDataApi: { type: Function, default: async () => {} },
    getDataCountApi: { type: Function, default: async () => {} },
    keyMap: {
      type: Object,
      default: () => ({
        id: 'id',
        name: 'name',
      }),
    },
  },
  setup (props, { emit }) {
    const { fetchAll } = useFetch()
    const syncValue = useVModel(props, 'value', emit)
    const {
      tableData,
      tableOptions,
      tableDataCount,
      loading,
      pageStartIndex,
      // fetchData,
      fetchDataCount,
      // dateFormat,
      extendData,
    } = useTable()
    tableOptions.pageLimit = 10

    extendData('loading', {
      select: false,
    })

    const modal = reactive({
      selector: false,
    })
    const selected = ref([])
    const { shopId } = useShop()
    const search = reactive({
      name: null,
    })

    const displayTableData = computed(() => {
      const formatedData = map(tableData.value, (item) => {
        return {
          id: get(item, props.keyMap.id),
          name: get(item, props.keyMap.name),
          value: item,
        }
      })

      if (search.name) {
        return filter(formatedData, (item) => {
          return includes(item.name, search.name)
        })
      }

      return formatedData
    })

    // !! 頁面顯示文字
    const displayDataText = computed(() => {
      return {
        dialogTitle: props.title,
        placeholders: {
          name: '請輸入名稱',
        },
        labels: {
          name: '名稱',
        },
        buttons: {
          selectAll: '全選所有',
          cancel: '取消',
          confirm: '確認',
          clearSelected: '清除選取',
        },
        hint: {
          selected: '已選擇：',
          maxSelect: '選擇上限：',
        },
      }
    })

    // ?? 選取選項是否 disabled
    const disabledItem = computed(() => {
      return (item) => {
        // 如果沒啟用多選，且已經有選擇過，則其他選項都要 disabled
        if (!props.multiple && selected.value.length) {
          return !selected.value.includes(item.id)
        }
      }
    })

    // ?? 顯示控制
    const showControl = computed(() => {
      const controls = {
        multipleOperation: props.multiple,
        hint: {
          maxSelect: props.max,
        },
      }
      return controls
    })

    // ?? 控制是否 disabled
    const disabledControl = computed(() => {
      const controls = {
        btn: {
          confirm: selected.value.length > props.max,
          clearSelect: selected.value.length <= 0,
        },
      }

      return controls
    })

    // => 取得資料
    const getTableData = async () => {
      // const payload = {
      //   shopId: shopId.value,
      //   start: pageStartIndex.value,
      //   limit: tableOptions.pageLimit,
      //   name: search.name || undefined,
      // }
      await Promise.allSettled([
        fetchAll(props.getDataApi, { shopId: shopId.value }, (res) => {
          tableData.value = res
        }),
        // fetchDataCount(props.getDataCountApi, payload),
      ])
    }

    // => 刷新
    const refresh = async () => {
      loading.table = true
      await getTableData()
      loading.table = false
    }

    const onSelectAll = async () => {
      loading.select = true
      selected.value = map(tableData.value, (item) => get(item, props.keyMap.id))
      loading.select = false
    }

    const onClearSelect = () => {
      selected.value = []
    }

    const onConfirm = () => {
      const selectedData = filter(tableData.value, (item) => includes(selected.value, get(item, props.keyMap.id)))
      syncValue.value = selectedData
      modal.selector = false
    }

    const onCancel = () => {
      modal.selector = false
    }

    onBeforeMount(async () => {
      await refresh()
      await nextTick()
      setTimeout(() => {
        selected.value = map(props.value, (item) => get(item, props.keyMap.id))
      }, 0)
    })

    watch(() => props.value, async () => {
      await nextTick()
      setTimeout(() => {
        selected.value = map(props.value, (item) => get(item, props.keyMap.id))
      }, 0)
    })

    // TODO 指定回傳格式

    return {
      syncValue,
      modal,
      showControl,
      flattenDeep,
      search,
      selected,
      displayTableData,
      tableDataCount,
      tableOptions,
      dayjs,
      displayDataText,
      onConfirm,
      onCancel,
      disabledItem,
      refresh,
      loading,
      onClearSelect,
      disabledControl,
      onSelectAll,
      tableData,
    }
  },
})
</script>

<style scoped lang="postcss">
.grid-container {
  @apply grid gap-[20px];
  @apply text-normal;
}
::v-deep .el-input {
  @apply w-full;
}
::v-deep .el-select {
  @apply w-full;
}
::v-deep .el-checkbox {
  @apply w-full truncate;
}
::v-deep .el-checkbox .el-checkbox__label {
  @apply truncate w-full;
}

</style>
