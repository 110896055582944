export const exportFormatOptions = [
  {
    label: 'Excel',
    value: 'xlsx',
  },
  {
    label: 'PDF',
    value: 'pdf',
  },
]

// 待棄用
export const salesRecordStatusOptions = {
  complete: '已完成',
  invalid: '已作廢',
}

export const salesRecordOrderStatusConfig = {
  complete: {
    value: 'complete',
    label: '已完成',
    tagType: 'action',
  },
  invalid: {
    value: 'invalid',
    label: '已作廢',
    tagType: 'danger',
  },
  pending: {
    value: 'pending',
    label: '待付款',
    tagType: 'info',
  },
  cancel: {
    value: 'cancel',
    label: '已取消',
    tagType: 'danger',
  },
}

export const ezAIOPaymentStatusConfig = {
  fail: {
    value: 'fail',
    label: '失敗',
    tagType: 'danger',
  },
  paid: {
    value: 'paid',
    label: '已付款',
    tagType: 'success',
  },
  pending: {
    value: 'pending',
    label: '待付款',
    tagType: 'info',
  },
  process: {
    value: 'process',
    label: '付款處理中',
    tagType: 'warning',
  },
}

export const salesGroupTypeOptions = {
  salesCategory: {
    value: 'salesCategory',
    label: '銷售類別',
  },
  salesProduct: {
    value: 'salesProduct',
    label: '銷售產品',
  },
  salesUnit: {
    value: 'salesUnit',
    label: '銷售人員',
  },
}

export const salesProductPropertyOptions = {
  product: {
    value: 'product',
    label: '一般產品',
  },
  ticket: {
    value: 'ticket',
    label: '堂票',
  },
  deposit: {
    value: 'deposit',
    label: '儲值金',
  },
}
