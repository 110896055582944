/* eslint-disable no-new */
/* eslint-disable no-unused-expressions */
import Vue from 'vue'
import store from './store'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui'
import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
import { i18n } from './plugins/i18n/i18n'

import './permission'
import 'element-ui/lib/theme-chalk/index.css'
import locale from '@/config/element-locale'
import '@/plugins/vue-gtm'
import './assets/tailwind.css'
import ComponentsRegister from './components/index'
import '@/styles/github-markdown.css'
import logger from '@/utils/logger'
// import VConsole from 'vconsole'

// -- Configure theme stylesheet file
// const configureTheme = configuration('appTheme')
// import(`@/styles/${configureTheme}/index.scss`)
import('@/styles/global/index.scss')

const requireAll = requireContext => requireContext.keys().map(requireContext)
const req = require.context('@/assets/svgIcon', true, /\.svg$/)
requireAll(req)

// fix autocomplete no show keyboard
ElementUI.Select.computed.readonly = function () {
  const isIE = !this.$isServer && !Number.isNaN(Number(document.documentMode))
  if (!this.filterable) return true
  return !(this.filterable || this.multiple || !isIE) && !this.visible
}

// if (process.env.NODE_ENV === 'development') {
//   new VConsole({ theme: 'dark' })
// }

Vue.config.productionTip = false
Vue.prototype.$logger = logger
Vue.use(ElementUI, { locale })
Vue.use(ComponentsRegister)
Vue.use(VueViewer)

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app')
