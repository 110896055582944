<template>
  <div class="service-info" data-testid="otherServices">
    <hr class="mb-[20px]">
    <BaseElForm ref="form" :model="formData" :rules="formRules" label-position="top">
      <BaseElFormItem v-if="checkResource" label="服務性質" prop="type">
        <template slot="label">
          <div>
            <p>服務性質</p>
          </div>
        </template>
        <BaseElSelect
          v-model="formData.type"
          testName="formData_type"
          value-key="id"
          placeholder="請選擇"
          no-data-text="暫無數據"
        >
          <BaseElSelectOption
            v-for="item in showServiceTypeConfig"
            :key="item.value"
            :label="$t(item.label)"
            :value="item.value"
          />
        </BaseElSelect>
      </BaseElFormItem>
      <BaseElFormItem v-if="showHumanService" label="服務人員" prop="serviceUnit">
        <ServicesUnitSelect
          testName="formData_unit"
          :model.sync="formData.serviceUnit"
          @change="serviceUnitChange"
        />
      </BaseElFormItem>
      <BaseElFormItem label="服務項目" prop="service">
        <!-- <UnitServicesSelect
          showAll
          :unitId="getData(formData.serviceUnit, 'id', '')"
          :model.sync="formData.service"
          :isResourceBind="showResourceService"
          @change="serviceChange"
        /> -->
        <BaseElSelect
          v-model="formData.service"
          testName="formData_service"
          value-key="id"
          filterable
          clearable
          @change="serviceChange"
          @clear="serviceChange"
        >
          <BaseElSelectOption
            v-for="service in filterServiceOptions"
            :key="service.id"
            :value="service"
            :label="service.name"
          />
        </BaseElSelect>
      </BaseElFormItem>
      <BaseElFormItem v-if="enableSubService" label="子項目" prop="subService">
        <BaseElSelect
          v-model="formData.subService"
          testName="formData_subService"
          value-key="id"
          @change="subServiceChange"
        >
          <BaseElSelectOption
            v-for="service in subServiceList"
            :key="service.id"
            :label="service.name"
            :value="service"
          />
        </BaseElSelect>
      </BaseElFormItem>
      <BaseElFormItem label="附加服務">
        <AttachServiceSelect
          testName="formData_attachServices"
          :model.sync="formData.attachServices"
          :services="formData.service"
          multiple
          @change="attachServiceChange"
        />
      </BaseElFormItem>
      <BaseElFormItem v-if="showResourceService && checkResource" label="服務設備" prop="resourceItemId">
        <template slot="label">
          <div class="flex items-center" style="gap: 8px">
            <p>服務設備</p>
          </div>
        </template>
        <ResourceUnitSelect
          testName="formData_resourceItemId"
          :model.sync="formData.resourceItemId"
          :showAll="true"
        />
      </BaseElFormItem>

      <BaseElFormItem label="預估服務時數(分鐘)" prop="totalTime">
        <BaseElSelect
          v-model="formData.totalTime"
          testName="formData_totalTime"
          placeholder="請選擇"
          @change="totalTimeChange"
        >
          <BaseElSelectOption
            v-for="(item, index) in genNums"
            :key="index"
            :label="item"
            :value="item"
          />
        </BaseElSelect>
      </BaseElFormItem>
      <BaseElFormItem v-if="showHumanAndResourceService && checkResource" label="預估設備使用時數(分鐘)" prop="resourceTotalTime">
        <BaseElSelect
          v-model="formData.resourceTotalTime"
          testName="formData_resourceTotalTime"
          placeholder="請選擇"
          @change="totalTimeChange"
        >
          <BaseElSelectOption
            v-for="(item, index) in getResourceNums"
            :key="index"
            :label="item"
            :value="item"
          />
        </BaseElSelect>
      </BaseElFormItem>
      <BaseElFormItem label="是否為加班時段">
        <BaseElRadioGroup
          v-model="formData.isOverTime"
          testName="formData_isOverTime"
          @change=";(formData.time = '')"
        >
          <BaseElRadio :label="true">是</BaseElRadio>
          <BaseElRadio :label="false">否</BaseElRadio>
        </BaseElRadioGroup>
      </BaseElFormItem>

      <BaseElFormItem label="預約時段" prop="time">
        <div v-if="!date || !formData.service || !formData.type">請先選擇服務人員與服務項目</div>
        <AvailableTimeSelect
          v-if="date && formData.service && !formData.isOverTime"
          testName="formData_time"
          :model.sync="formData.time"
          :timesList="availableDate"
        />
        <BaseElSelect
          v-if="date && formData.isOverTime"
          v-model="formData.time"
          testName="formData_time"
          placeholder="請選擇"
          no-data-text="無數據"
        >
          <BaseElSelectOption
            v-for="(item, index) in genTimes()"
            :key="index"
            :label="item"
            :value="item"
          />
        </BaseElSelect>
      </BaseElFormItem>
    </BaseElForm>
  </div>
</template>

<script>
// import UnitServicesSelect from '@/components/Select/UnitServicesSelect.vue'
import ServicesUnitSelect from '@/components/Select/ServicesUnitSelect.vue'
// import ServiceUnitTimeSelect from '@/components/Select/ServiceUnitTimeSelect.vue'
import AttachServiceSelect from '@/components/Select/AttachServiceSelect.vue'
import AvailableTimeSelect from '@/components/Reservation/AvailableTimeSelect/AvailableTimeSelect.vue'
import ResourceUnitSelect from '@/components/Select/ResourceUnitSelect.vue'
import { mapGetters } from 'vuex'
import { defineComponent, watch, computed, reactive, ref, unref } from 'vue'

import { FindServiceUnit, GetServiceUnit } from '@/api/serviceUnit'
import { FindService, GetService } from '@/api/service'
import { GetAvailableTimes } from '@/api/reservation'

import { generateTimes, generateNumbers, sortOrder } from '@/utils/helper'
import { isInvalidDate, formatDate } from '@/utils/date'
import { noEmptyRules } from '@/validation'
import { serviceTypeConfig } from '@/config/reservation'

import dayjs from '@/lib/dayjs'
import { get, map, find, omit, filter, includes, isEmpty } from 'lodash'
import { checkUserFeature } from '@/store/modules/permission'

export default defineComponent({
  name: 'OtherServiceSelect',
  components: {
    // UnitServicesSelect,
    ServicesUnitSelect,
    AttachServiceSelect,
    // ServiceUnitTimeSelect,
    ResourceUnitSelect,
    AvailableTimeSelect,
  },
  props: {
    config: {
      type: Object,
      default: () => ({}),
    },
    date: {
      type: Date,
    },
    peopleCount: {
      type: Number,
      default: 0,
    },
    mainServiceData: {
      type: Object,
      default: () => ({}),
    },
  },
  setup (props, { emit }) {
    const formData = reactive({
      otherService: false,
      subService: null,
      service: null,
      attachServices: null,
      serviceUnit: null,
      totalTime: 0,
      resourceItemId: null,
      resourceTotalTime: 0,
      isOverTime: false,
      date: '',
      time: '',
      type: 'humanService',
    })
    const availableDate = ref([])

    const reset = () => {
      availableDate.value = []
      formData.otherService = false
      formData.service = null
      formData.attachServices = null
      formData.serviceUnit = null
      formData.totalTime = 0
      formData.isOverTime = false
      formData.date = ''
      formData.time = ''
      formData.serviceDetail = null
    }

    const genNums = ref([])
    const getResourceNums = ref([])
    watch(() => props.mainServiceData.time, () => {
      console.log('🔥mainServiceData time update')
      reset()
      return emit('update', { ...formData })
    })
    // mainServiceData: {
    //   handler: async function (data) {
    //     console.log('🔥mainServiceData update', data)
    //     if (!data.time) this.reset()
    //     return this.$emit('update', this.formData)
    //   },
    //   deep: true,
    // }

    const mainServicePreReservation = computed(() => {
      return {
        appointmentUnitId: get(props.mainServiceData, 'serviceUnit.id'),
        resourceItemId: get(props.mainServiceData, 'resourceItemId') || undefined,
        start: dayjs(`${formatDate(get(props.mainServiceData, 'date'), 'YYYY/MM/DD')} ${get(props.mainServiceData, 'time')}`).toDate(),
        totalBookingTime: get(props.mainServiceData, 'totalTime'),
      }
    })

    return { formData, availableDate, genNums, getResourceNums, mainServicePreReservation }
  },
  data () {
    return {

      serviceData: null,
      formRules: {
        type: [noEmptyRules()],
        service: [noEmptyRules()],
        subService: [noEmptyRules()],
        serviceUnit: [noEmptyRules()],
        date: [noEmptyRules()],
        time: [noEmptyRules()],
        resourceItemId: [noEmptyRules()],
        resourceTotalTime: [noEmptyRules()],
      },
      serviceTypeConfig,
      serviceOptions: [],
      allServiceList: [],
    }
  },

  computed: {
    ...mapGetters([
      'shop',
      'shopTimeUnit',
      'userFeatures',
      'userPlanFeature']),
    totalTime () {
      const form = this.formData
      if (!form.service) return 0
      let base = 0

      if (!form.subService) base += form.service.bookingTime
      if (form.subService) base += form.subService.bookingTime

      let attach = 0
      if (form.attachServices) {
        form.attachServices.forEach((item) => {
          attach += item.bookingTime
        })
      }

      return base + attach
    },
    subServiceList () {
      if (!this.serviceData) return []
      else if (get(this.formData, 'type') === 'resourceService') return get(this.serviceData, 'AppointmentSubServices')
      else return get(this.serviceData, 'AppointmentSubServices').filter(s => find(s.AppointmentUnits, { id: get(this.formData, 'serviceUnit.id') }) || isEmpty(s.AppointmentUnits))
    },
    enableSubService () {
      return get(this.formData.service, 'enableSubService')
    },
    checkResource () {
      return this.checkAction('admin.resourceItem.page')
    },
    showHumanService () {
      return this.formData.type === 'humanService' || this.formData.type === 'humanAndResourceService'
    },
    showResourceService () {
      return this.formData.type === 'resourceService' || this.formData.type === 'humanAndResourceService'
    },
    showHumanAndResourceService () {
      return this.formData.type === 'humanAndResourceService'
    },
    showServiceTypeConfig () {
      const omitList = []
      // if (!this.checkAction('admin.resourceItem.page')) omitList.push('resourceService', 'humanAndResourceService')
      omitList.push('resourceService', 'humanAndResourceService')
      return omit(this.serviceTypeConfig, omitList)
    },
    filterServiceOptions () {
      let serviceOptionList = []
      if (this.checkResource) {
        if (this.formData.type === 'resourceService') {
          serviceOptionList = filter(this.serviceOptions, (i) => !i.enableAppointmentUnit && i.enableResourceItem)
        } else if (this.formData.type === 'humanService') {
          serviceOptionList = filter(this.serviceOptions, (i) => i.enableAppointmentUnit && !i.enableResourceItem)
        } else if (this.formData.type === 'humanAndResourceService') {
          serviceOptionList = filter(this.serviceOptions, (i) => i.enableAppointmentUnit && i.enableResourceItem)
        } else {
          serviceOptionList = this.serviceOptions
        }
      } else {
        serviceOptionList = filter(this.serviceOptions, (i) => i.enableAppointmentUnit && !i.enableResourceItem)
      }
      return serviceOptionList
    },
  },
  watch: {
    formData: {
      handler: async function (data) {
        if (data.service) {
          await this.getAvailableTimes()
        }
        return this.$emit('update', data)
      },
      deep: true,
    },
    // mainServiceData: {
    //   handler: async function (data) {
    //     console.log('🔥mainServiceData update', data)
    //     if (!data.time) this.reset()
    //     return this.$emit('update', this.formData)
    //   },
    //   deep: true,
    // },
  },
  async mounted () {
    this.$nextTick(() => {
      this.$emit('ref', this.$refs.form)
    })
    if (!this.allServiceList.length) {
      const res = await GetService({ shopId: this.shop })
      this.allServiceList = res
      this.serviceOptions = unref(this.allServiceList)
    }
  },
  methods: {
    checkAction (action) {
      return checkUserFeature(this.userPlanFeature, this.userFeatures, action)
    },
    getData (obj, path, defaultValue) {
      return get(obj, path, defaultValue)
    },
    genTimes () {
      const offset = get(this.config, 'reservation.timeUnitOffset')
      const timeUnit = get(this.config, 'reservation.timeUnit')
      return generateTimes(timeUnit, offset)
    },
    updateTimeOptions () {
      this.genNums = generateNumbers(0, 600, this.shopTimeUnit)
      this.genNums.push(this.totalTime)
      this.genNums = [...new Set(this.genNums.sort((a, b) => a - b))]
      this.getResourceNums = generateNumbers(0, this.formData.totalTime + this.shopTimeUnit, this.shopTimeUnit)
      this.getResourceNums.push(this.totalTime)
      this.getResourceNums = [...new Set(filter(this.getResourceNums.sort((a, b) => a - b), (item) => item <= this.formData.totalTime))]
    },
    totalTimeChange () {
      if (this.formData.isOverTime) return
      this.formData.date = ''
      this.formData.resourceTotalTime = this.formData.totalTime
      this.updateTimeOptions()
    },
    async findServiceUnit (unitId) {
      try {
        const res = await FindServiceUnit({
          shopId: this.shop,
          id: unitId,
        })
        this.serviceOptions = sortOrder(res.AppointmentServices)
      } catch (error) {
        console.log(error)
        window.$message.error(error)
      }
    },
    serviceUnitChange (serviceUnit) {
      console.log('serviceUnitChange', serviceUnit)
      this.formData.totalTime = 0
      if (includes(['notSpecify', 'all'], serviceUnit.id)) {
        this.serviceOptions = unref(this.allServiceList)
      } else {
        this.findServiceUnit(serviceUnit.id)
      }
      this.formData.service = ''
      this.formData.attachServices = []
      this.formData.date = ''
      this.formData.time = ''
      this.updateTimeOptions()
    },

    async serviceChange () {
      await this.findService()
      this.formData.attachServices = []
      this.formData.subService = null
      this.formData.totalTime = this.totalTime
      this.formData.resourceTotalTime = this.totalTime
      this.updateTimeOptions()
      this.formData.date = ''
      this.formData.time = ''
    },

    subServiceChange () {
      this.formData.attachServices = []
      this.formData.totalTime = this.totalTime
      this.formData.resourceTotalTime = this.totalTime
      this.updateTimeOptions()
      this.formData.date = ''
      this.formData.time = ''
    },

    attachServiceChange () {
      this.formData.date = ''
      this.formData.time = ''
      this.formData.resourceTotalTime = this.totalTime
      this.formData.totalTime = this.totalTime
      this.updateTimeOptions()
    },

    async findService () {
      const [res, err] = await FindService({
        shopId: this.shop,
        id: this.formData.service.id,
      })
      if (err) {
        this.$message.error(err)
        return
      }
      this.serviceData = res
      this.formData.serviceDetail = res
    },

    async getAvailableTimes () {
      const date = dayjs(this.mainServiceData.date).format('YYYY/MM/DD')
      let preTime = dayjs(this.mainServiceData.time).format('HH:mm')
      if (isInvalidDate(preTime)) preTime = this.mainServiceData.time

      const start = dayjs(`${date} ${preTime}`)
      const end = dayjs(start.add(1, 'd').startOf('d'))

      const [res] = await GetAvailableTimes({
        shopId: this.shop,
        rangeStart: start.toDate(),
        rangeEnd: end.toDate(),
        appointmentUnitIds: this.formData.serviceUnit ? [this.formData.serviceUnit.id] : null,
        resourceItemIds: this.formData.resourceItemId ? [get(this.formData.resourceItemId, 'id')] : null,
        appointmentServiceId:
          get(this.formData, 'service.id') || undefined,
        appointmentSubServiceIds: get(this.formData, 'subService.id'),
        appointmentServiceAttachIds: map(
          get(this.formData, 'attachServices', []),
          'id',
        ),
        peopleCount: 1,
        setTotalBookingTime: this.formData.totalTime,
        setResourceItemTotalBookingTime: this.formData.resourceTotalTime,
        preReservations: [
          this.mainServicePreReservation,
        ],
      })
      if (res.times) this.availableDate = res.times.filter(i => dayjs(i).isSame(dayjs(this.mainServiceData.date), 'date'))
    },

  },
})
</script>

<style scoped lang="postcss">
::v-deep .el-form-item__label {
  @apply flex
}
</style>
