<template>
  <div class="page">
    <PageTitle
      title="銷售報表"
      btn="匯出"
      btn2="顯示設定"
      @btnClick="openExportDialog"
      @btn2Click="dialog.displaySettings = true"
    />

    <SalesReportDisplaySettingsDialog
      v-if="dialog.displaySettings"
      @close="dialog.displaySettings = false"
      @confirm="getDisplaySettings"
    />

    <p
      v-if="get(displaySettings, 'excludeWallet')"
      class="text-danger"
      style="margin-bottom: 20px"
    >
      *當前數據來源未包含儲值金付款訂單
    </p>

    <div class="datetime-container">
      <GroupTimeFilterButton
        :isCustomRange.sync="customerRange"
        @month="getMonthRange"
        @week="getWeekRange"
        @today="getTodayRange"
      />

      <div class="time-picker-container">
        <el-date-picker
          v-model="searchTime"
          editable
          type="datetimerange"
          range-separator="至"
          start-placeholder="開始日期"
          end-placeholder="结束日期"
          format="yyyy-MM-dd HH:mm"
          :default-time="['00:00:00', '23:59:59']"
          @change="
            changeToCustomer(), getSalesReport(), findIndicatorByBranch()
          "
        />
      </div>
    </div>

    <div class="title">
      <span class="title-hint">|</span>
      <span>總覽</span>
    </div>
    <div v-loading="loading.page" class="big-result-container">
      <div class="result">
        <span class="result-title">銷售總額(未扣折扣)</span>
        <div>
          <span class="result-number">$ {{ totalItemsPrice }}</span>
        </div>
      </div>

      <el-divider direction="vertical" />

      <div class="result">
        <span class="result-title">服務單數</span>
        <div>
          <span class="result-number">{{ totalRecordCount }}</span>
        </div>
      </div>

      <el-divider direction="vertical" />

      <div class="result">
        <span class="result-title">{{ totalPaymentPriceDiscountLabel }}</span>
        <div>
          <span class="result-number">$ {{ totalPaymentPrice }}</span>
        </div>
      </div>
    </div>

    <!-- 各門市統計 -->
    <div v-if="useStorePermission">
      <div class="title">
        <span class="title-hint">|</span>
        <span>各門市統計</span>
      </div>
      <BaseTable
        v-loading="loading.page"
        empty-text="暫無數據"
        :data="indicatorByBranchList[storePage - 1]"
        :default-sort="{ prop: 'date', order: 'descending' }"
      >
        <EmptyBlock slot="empty" />
        <BaseElTableColumn label="門市" prop="Branch.name" sortable align="center" />
        <BaseElTableColumn
          label="有多少銷售總額（未扣折扣）"
          prop="totalItemsPrice"
          sortable
          align="center"
        />
        <BaseElTableColumn label="服務單數" prop="count" sortable align="center" />
        <BaseElTableColumn
          :label="totalPaymentPriceDiscountLabel"
          prop="totalPaymentPrice"
          sortable
          align="center"
        >
          <template slot-scope="scope">
            {{
              get(displaySettings, 'excludeWallet')
                ? unitTotalPaymentPrice(
                  scope.row.totalPaymentPrice - scope.row.totalWalletAmount
                )
                : unitTotalPaymentPrice(scope.row.totalPaymentPrice)
            }}
          </template>
        </BaseElTableColumn>
      </BaseTable>
      <Pagination :pageLimit="storePerPage" :total="branchCount" :curPage.sync="storePage" />
    </div>

    <div class="title">
      <span class="title-hint">|</span>
      <span>銷售人員統計</span>
    </div>
    <BaseTable
      v-loading="loading.page"
      empty-text="暫無數據"
      :data="unitRecord"
      :default-sort="{ prop: 'date', order: 'descending' }"
    >
      <EmptyBlock slot="empty" />

      <BaseElTableColumn prop="salesUnit.name" label="人員" sortable>
        <template slot-scope="scope">
          {{ scope.row.salesUnitId ? scope.row.salesUnit.name : '無銷售人員' }}
        </template>
      </BaseElTableColumn>
      <BaseElTableColumn prop="totalPrice" label="銷售金額" sortable />
      <BaseElTableColumn prop="totalPaymentPrice" :label="totalPaymentPriceLabel" sortable>
        <template slot-scope="scope">
          {{
            get(displaySettings, 'excludeWallet')
              ? unitTotalPaymentPrice(
                scope.row.totalPaymentPrice - scope.row.totalWalletAmount
              )
              : unitTotalPaymentPrice(scope.row.totalPaymentPrice)
          }}
        </template>
      </BaseElTableColumn>
      <BaseElTableColumn prop="recordCount" label="服務單數" sortable />
      <BaseElTableColumn prop="salesUnit.profit" label="業績金額" sortable>
        <template slot-scope="scope">
          {{ unitSalesPrice(scope.row) }}
        </template>
      </BaseElTableColumn>
      <BaseElTableColumn prop="salesUnit.profit" label="抽潤比例" sortable>
        <template slot-scope="scope">
          {{ scope.row.salesUnitId ? scope.row.salesUnit.profit : 0 }}
        </template>
      </BaseElTableColumn>
      <BaseElTableColumn prop="salesUnit.profit" label="人員抽潤" sortable>
        <template slot-scope="scope">
          {{
            scope.row.salesUnitId
              ? scope.row.salesUnit.profit * scope.row.totalRevenuePrice
              : 0
          }}
        </template>
      </BaseElTableColumn>
    </BaseTable>

    <div class="title">
      <span class="title-hint">|</span>
      <span>支付方式統計</span>
    </div>
    <BaseTable
      v-loading="loading.page"
      empty-text="暫無數據"
      class="sales-table"
      :data="paymentRecord"
      style="width: 100%"
      :default-sort="{ prop: 'date', order: 'descending' }"
    >
      <EmptyBlock slot="empty" />
      <BaseElTableColumn prop="paymentType.name" label="支付方式" sortable />
      <BaseElTableColumn prop="totalAmount" label="支付金額" sortable />
    </BaseTable>

    <div v-if="showChart" class="title">
      <span class="title-hint">|</span>
      <span>來客數統計</span>
    </div>
    <div v-if="showChart" v-loading="loading.page" class="chart-grid-container">
      <div class="pie-cahrt">
        <EmptyBlock v-if="chartData.labels.length === 0" />
        <pie-chart
          v-if="!loading.page && chartData.labels.length > 0"
          :width="250"
          :height="250"
          :data="chartData"
          :options="chartOptions"
        />
      </div>
      <BaseTable
        empty-text="暫無數據"
        class="data-table"
        :data="identitiesRecord"
        style="width: 100%"
        :default-sort="{ prop: 'date', order: 'descending' }"
      >
        <EmptyBlock slot="empty" />
        <BaseElTableColumn
          prop="identity.name"
          title="test"
          label="會員類別"
          sortable
          align="center"
        />
        <BaseElTableColumn
          prop="totalItemsPrice"
          label="銷售金額"
          sortable
          align="center"
        />
        <BaseElTableColumn prop="count" label="來客數" sortable align="center" />
      </BaseTable>
    </div>

    <!-- Dialog -->
    <el-dialog
      title="匯出"
      :visible.sync="showExportDialog"
      width="600px"
      :close-on-click-modal="false"
    >
      <!-- <p v-if="useSheetExportCenter" class="mb-[8px]">確認匯出後，請至下載中心查看任務與下載匯出檔案</p> -->
      <BaseElForm
        ref="exportFormRef"
        :model="exportFormData"
        :rules="exportFormRules"
      >
        <BaseElFormItem label="匯出格式">
          <BaseElSelect
            v-model="exportFormData.format"
            placeholder="請選擇格式"
            @change="exportFormData.categories = null"
          >
            <BaseElSelectOption
              v-for="item in filteredExportFormatOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </BaseElSelect>
        </BaseElFormItem>
        <BaseElFormItem v-show="exportFormData.format === 'pdf'" label="銷售類別">
          <BaseElSelect
            v-model="exportFormData.categories"
            placeholder="請選擇銷售類別"
            multiple
            clearable
          >
            <BaseElSelectOption
              v-for="item in salesCategoryOptions"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            />
          </BaseElSelect>
        </BaseElFormItem>
      </BaseElForm>
      <template #footer>
        <BaseElButton :loading="loading.button" @click="showExportDialog = false">
          取消
        </BaseElButton>
        <BaseElButton
          type="primary"
          :loading="loading.button"
          @click="exportExcel"
        >
          確認
        </BaseElButton>
      </template>
    </el-dialog>

    <GoogleSheetDialog
      v-if="googleSheetDialog"
      :shopId="shop"
      :start="searchTime[0]"
      :end="searchTime[1]"
      @close="googleSheetDialog = false"
    />

    <ExportHtmlToPdf
      ref="exportPdfRef"
      :time-range="searchTime"
      :category-options="salesCategoryOptions"
      :export-data-list="exportDataList"
    />
  </div>
</template>

<script>
import { defineComponent, reactive, computed } from 'vue'
import { mapGetters } from 'vuex'
import GroupTimeFilterButton from '@/components/Input/GroupTimeFilterButton'
import SalesReportDisplaySettingsDialog from './components/SalesReportDisplaySettingsDialog.vue'
import PieChart from '@/components/PieChart.js'
// eslint-disable-next-line no-unused-vars
import ChartJSPluginDatalabels from 'chartjs-plugin-datalabels'

import EmptyBlock from '@/components/EmptyBlock.vue'
import GoogleSheetDialog from '@/components/Dialog/GoogleSheetDialog'
import ExportHtmlToPdf from '@/components/ExportHtmlToPdf.vue'
import {
  GetSalesReport,
  GetSalesRecord,
  GetSalesCategory,
  GetSalesCategoryCount,
  FindIndicatorByBranch,
} from '@/api/sales'
import { FindStoreCount, FindStore } from '@/api/store'
import { getAllDataFromApi } from '@/utils/helper'
import dayjs from '@/lib/dayjs'
import startOfDay from 'date-fns/startOfDay'
import endOfDay from 'date-fns/endOfDay'
import startOfWeek from 'date-fns/startOfWeek'
import endOfWeek from 'date-fns/endOfWeek'
import startOfMonth from 'date-fns/startOfMonth'
import endOfMonth from 'date-fns/endOfMonth'
import { exportFormatOptions } from '@/config/sales'
import { checkUserFeature } from '@/store/modules/permission'
import { useDisplaySettings } from '@/use/displaySettings'
import { get, chunk } from 'lodash'
import formUtils from '@/utils/form'

export default defineComponent({
  name: 'SalesReport',
  components: {
    PieChart,
    EmptyBlock,
    GoogleSheetDialog,
    ExportHtmlToPdf,
    GroupTimeFilterButton,
    SalesReportDisplaySettingsDialog,
  },
  setup (props) {
    const { getDisplaySettings, displaySettings } =
      useDisplaySettings('salesReport')
    const dialog = reactive({
      displaySettings: false,
    })
    // const useSheetExportCenter = computed(() => checkAction('admin.sheetExportTask.page'))
    // TODO: 改成 props 傳入 useExportTask base on admin.salesXXXXXX.createSheetExportTask
    const totalPaymentPriceDiscountLabel = computed(() => {
      return get(displaySettings.value, 'excludeWallet')
        ? '實收比例金額(已扣折扣、儲值金)'
        : '實收比例金額(已扣折扣)'
    })
    const totalPaymentPriceLabel = computed(() => {
      return get(displaySettings.value, 'excludeWallet')
        ? '實收比例金額(扣除儲值金)'
        : '實收比例金額'
    })
    return {
      dialog,
      getDisplaySettings,
      displaySettings,
      totalPaymentPriceDiscountLabel,
      totalPaymentPriceLabel,
      // useSheetExportCenter,
    }
  },
  data () {
    return {
      loading: {
        page: false,
        button: false,
      },
      exportOption: '匯出全部明細',
      showChart: true,
      salesRecord: [],
      searchTime: [],
      customerRange: false,

      timeRangeType: 'today',

      chartData: {
        labels: ['暫無資料'],
        datasets: [
          {
            backgroundColor: [
              // '#ada3f7',
              // '#68b5de',
              // '#f5cf5a',
              // '#75d997',
              // '#de68a5',
            ],
            data: [100],
            borderWidth: 0,
          },
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRadio: false,
        legend: {
          display: false,
        },
        tooltips: {
          enabled: false,
        },

        plugins: {
          datalabels: {
            color: 'white',
            textAlign: 'center',
            font: {
              // weight: "bold",
              size: 16,
            },

            formatter: this.charFormat,
          },
        },
      },
      googleSheetDialog: false,
      exportFormatOptions,
      showExportDialog: false,
      exportFormData: {
        format: 'xlsx',
        categories: null,
      },
      exportFormRules: {
        categories: [
          {
            type: 'array',
            required: true,
            message: '請選擇銷售類別',
            trigger: 'change',
          },
        ],
      },
      salesCategoryOptions: [],
      salesRecordList: [],
      exportDataList: [],
      indicatorByBranchList: [],
      storeList: [],
      branchCount: 0,
      storePage: 1,
      storeStart: 0,
      storePerPage: 10,
    }
  },

  computed: {
    ...mapGetters(['shop', 'userPlanFeature', 'userFeatures']),

    totalRecordCount () {
      return this.salesRecord.count
    },
    totalItemsPrice () {
      return this.salesRecord.totalItemsPrice
    },
    totalPaymentPrice () {
      if (get(this.displaySettings, 'excludeWallet')) {
        return (
          this.salesRecord.totalPaymentPrice -
          this.salesRecord.totalWalletAmount
        )
      }
      return this.salesRecord.totalPaymentPrice
    },

    unitRecord () {
      return this.salesRecord.units
    },
    paymentRecord () {
      return this.salesRecord.payments
    },
    identitiesRecord () {
      return this.salesRecord.identities
    },

    useSalesExportExcel () {
      return checkUserFeature(
        this.userPlanFeature,
        this.userFeatures,
        'admin.salesRecord.exportExcel',
      )
    },

    useSalesExportPdf () {
      return checkUserFeature(
        this.userPlanFeature,
        this.userFeatures,
        'admin.salesRecord.exportPDF',
      )
    },

    useStorePermission () {
      return checkUserFeature(
        this.userPlanFeature,
        this.userFeatures,
        'admin.branch.adminUseStore',
      )
    },

    filteredExportFormatOptions () {
      let options = this.exportFormatOptions

      if (!this.useSalesExportExcel) { options = options.filter((item) => item.value !== 'xlsx') }
      if (!this.useSalesExportPdf) { options = options.filter((item) => item.value !== 'pdf') }

      return options
    },
  },

  watch: {
    showExportDialog (current) {
      if (!current) {
        this.clearExportData()
      }
    },
  },

  mounted () {
    this.getDisplaySettings()
    const docStyle = getComputedStyle(document.documentElement)
    for (let i = 0; i < 10; i++) {
      this.chartData.datasets[0].backgroundColor.push(
        docStyle.getPropertyValue(`--chart-${i + 1}`),
      )
    }
    if (this.useStorePermission) {
      this.findStoreCount()
      this.getStore()
      this.findIndicatorByBranch()
    }
  },
  async created () {
    this.getTodayRange()
    await this.getSalesCategory()
    await this.getSalesReport()
  },

  methods: {
    get,
    unitSalesPrice (row) {
      const totalRevenuePrice = row.salesUnitId ? row.totalRevenuePrice : 0
      return totalRevenuePrice
    },
    unitTotalPaymentPrice (price) {
      if (!price) return 0
      const total = price.toFixed(1)
      return parseFloat(total)
    },

    async getSalesReport () {
      try {
        this.loading.page = true
        const res = await GetSalesReport({
          shopId: this.shop,
          start: this.searchTime[0],
          end: this.searchTime[1],
        })

        this.salesRecord = res
        this.filterCustomersLabels()
        this.filterCustomersPercentege()
        this.loading.page = false
      } catch (error) {
        console.log(error)
        this.$message.error(error || error.message)
      }
    },

    async getSalesCategoryCount () {
      try {
        return await GetSalesCategoryCount({
          shopId: this.shop,
        })
      } catch (error) {
        console.log(error)
        this.$message.error(error || error.message)
      }
    },

    async getSalesCategory () {
      try {
        this.loading.page = true
        const max = await this.getSalesCategoryCount()
        const config = {
          start: 0,
          limit: 100,
          shopId: this.shop,
        }
        this.salesCategoryOptions = await getAllDataFromApi(
          max,
          GetSalesCategory,
          config,
        )
        this.loading.page = false
      } catch (error) {
        console.log(error)
        this.$message.error(error || error.message)
        this.loading.page = false
      }
    },

    async getSalesRecord () {
      try {
        const res = await GetSalesRecord({
          shopId: this.shop,
          sellAt_gte: this.searchTime[0],
          sellAt_lte: this.searchTime[1],
        })

        this.salesRecordList = res
      } catch (error) {
        console.log(error)
        this.$message.error(error || error.message)
      }
    },

    payType (type) {
      const typeDict = {
        cash: '現金',
        credit: '信用卡',
        coin: '儲值金',
      }
      return typeDict[type]
    },

    charFormat (value, ctx) {
      const label = ctx.chart.data.labels[ctx.dataIndex]
      const percentege = Math.round(value)
      return `${label}\n${percentege}%`
    },

    async getTodayRange () {
      const start = startOfDay(new Date())
      const end = endOfDay(new Date())
      this.searchTime = []
      this.searchTime[0] = start
      this.searchTime[1] = end
      await this.getSalesReport()
      await this.findIndicatorByBranch()
    },
    async getWeekRange () {
      const start = startOfWeek(new Date())
      const end = endOfWeek(new Date())
      this.searchTime = []
      this.searchTime[0] = start
      this.searchTime[1] = end
      await this.getSalesReport()
      await this.findIndicatorByBranch()
    },
    async getMonthRange () {
      const start = startOfMonth(new Date())
      const end = endOfMonth(new Date())
      this.searchTime = []
      this.searchTime[0] = start
      this.searchTime[1] = end
      await this.getSalesReport()
      await this.findIndicatorByBranch()
    },

    changeToCustomer () {
      const start = dayjs(this.searchTime[0])
      const end = dayjs(this.searchTime[1])

      if (start.hour() !== 0 || start.minute() !== 0) {
        this.customerRange = true
      } else if (end.hour() !== 23 || end.minute() !== 59) {
        this.customerRange = true
      }
    },

    //= > 改變查詢時間範圍
    async changeTimeRangeType (type) {
      if (type === 'today') {
        this.getTodayRange()
      } else if (type === 'week') {
        this.getWeekRange()
      } else if (type === 'month') {
        this.getMonthRange()
      }

      this.timeRangeType = type
      this.customerRange = false

      await this.getSalesReport()
    },

    filterCustomersLabels () {
      const labels = []
      this.identitiesRecord.forEach((item) => {
        if (!labels.includes(item.identity.name)) {
          labels.push(item.identity.name)
        }
      })
      // this.$set(this.chartData, 'label', labels)
      this.chartData.labels = labels
    },

    filterCustomersPercentege () {
      this.chartData.datasets[0].data = []
      this.chartData.labels.forEach((label) => {
        this.identitiesRecord.forEach((item) => {
          if (item.identity.name === label) {
            this.chartData.datasets[0].data.push(item.count)
          }
        })
      })

      const reducer = (accumulator, currentValue) => accumulator + currentValue
      const datasets = this.chartData.datasets[0].data

      if (datasets.length === 0) return
      const total = datasets.reduce(reducer)
      this.chartData.datasets[0].data = datasets.map(
        (val) => (val / total) * 100,
      )
      this.showChart = false
      this.showChart = true
    },

    exportUnit () {
      const record = this.unitRecord
      const sheet = {
        sheetName: '銷售人員明細',
        data: [],
      }

      record.forEach((item) => {
        const data = {
          銷售人員: item.salesUnit ? item.salesUnit.name : '無銷售人員',
          抽潤比例: item.salesUnit ? item.salesUnit.profit : '無',
          銷售單數: item.recordCount,
          銷售品項數: item.itemCount,
          總金額: item.totalPrice,
        }
        sheet.data.push(data)
      })
      return sheet
    },

    async exportExcel () {
      if (this.exportFormData.format === 'xlsx') {
        this.showExportDialog = false
        this.googleSheetDialog = true
      } else {
        if (!this.useSalesExportPdf) return this.$message.error('無此權限')

        if (
          !this.exportFormData.categories ||
          !this.exportFormData.categories.length
        ) { return this.$message.error('請選擇銷售類別') }
        const valid = await formUtils.checkForm(this.$refs.exportFormRef)
        if (!valid) return false

        this.loading.button = true

        await this.getSalesRecord()

        const categories = this.exportFormData.categories
        for (let i = 0; i < categories.length; i += 1) {
          await this.prepareExportData(categories[i], i)
        }

        await this.$refs.exportPdfRef.generateReport()

        this.loading.button = false
        this.showExportDialog = false
        this.exportDataList = []
      }
    },

    prepareExportData (category, index) {
      return new Promise((resolve, reject) => {
        const exportData = this.salesRecordList.filter((item) => {
          const itemIncluded = item.SalesRecordItems.find(
            (subItem) => subItem.SalesCategoryId === category,
          )
          if (itemIncluded) return item
        })

        this.exportDataList.push({
          index: index,
          category: this.exportFormData.categories[index],
          data: exportData,
        })

        resolve()
      })
    },

    openExportDialog () {
      if (!this.filteredExportFormatOptions.length) { return this.$message.error('無此權限') } else { this.exportFormData.format = this.filteredExportFormatOptions[0].value }

      this.showExportDialog = true
    },

    clearExportData () {
      this.showExportDialog = false
      this.exportFormData.format = 'xlsx'
      this.salesRecordList = []
      this.exportDataList = []
    },
    async findIndicatorByBranch () {
      if (this.useStorePermission) {
        this.loading.page = true
        const [res, err] = await FindIndicatorByBranch({
          shopId: this.shop,
          start: this.searchTime[0],
          end: this.searchTime[1],
        })
        this.loading.page = false
        if (err) {
          this.$message.error(err)
        }
        this.indicatorByBranchList = chunk(res, this.storePerPage)
        this.branchCount = res.length
        // this.translateIndicatorByBranchToObject(res)
        console.log(res)
      }
    },
    async findStoreCount () {
      this.loading.page = true
      const [res, err] = await FindStoreCount({ shopId: this.shop })
      this.loading.page = false
      if (err) {
        window.$message.error(err)
        return
      }
      this.branchCount = res.count
    },
    async getStore () {
      this.loading.page = true
      const [res, err] = await FindStore({
        shopId: this.shop,
        start: this.storeStart,
        limit: this.storePerPage,
      })
      this.loading.page = false
      if (err) {
        window.$message.error(err)
        return
      }
      console.log('store', res)
      this.storeList = res
    },
    translateIndicatorByBranchToObject (data) {
      const obj = {}
      data.forEach((item) => {
        obj[item.id] = item
      })
      return obj
    },
  },
})
</script>

<style>
.el-divider {
  height: auto;
  width: 2px;
}
</style>

<style lang="scss" scoped>
.sales-record__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.page {
  padding: 0 10px;
  font-weight: normal;
}
.title {
  font-size: 24px;
  margin-top: 30px;
  margin-bottom: 30px;
  // color: white;

  .sub {
    font-size: 13px;
    color: #666;
  }
}

.title-hint {
  @apply text-primary-100 mr-[5px];
}

.spliter {
  width: 1px;
  border: solid 1px gray;
}

.picker-container {
  margin-right: 10px;
}

.date-range-btn {
  color: white;
  background: white;
  border: 1px solid #dedede;
  border-right-color: #dedede !important;
  border-left-color: #dedede !important;
}

.active {
  @apply border border-primary-100 bg-primary-100 text-white;
}

.dialog-content {
  display: flex;
  flex-direction: column;
}

.end-block {
  padding-bottom: 100px;
}

.datetime-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px;
  margin: 8px 0 30px;
}

.big-result-container {
  border-radius: 4px;
  display: flex;
  justify-content: space-evenly;
  padding: 10px;
  margin-bottom: 20px;
  background: white;

  .result {
    margin: 2px 0;
    display: flex;
    gap: 3px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .result-number {
    font-size: 24px;
    @apply font-bold text-primary-100;
  }
}

/*=======================*/
/*==== 當日店內銷售統計 ====*/
/*=======================*/
.today-result-banner {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: 15px;
  margin-bottom: 15px;
}

.result-container {
  width: 70%;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: white;

  .result {
    span:nth-child(1) {
      @apply text-[35px] text-primary-100;
    }
    span:nth-child(1) {
      @apply text-primary-100;
    }
  }

  .title-container {
    display: flex;
    align-items: center;
  }

  .cash-icon {
    width: 48px;
    height: 48px;
    margin-right: 5px;
  }
}

.date {
  width: 30%;
  height: 100%;
  text-align: right;
  vertical-align: bottom;
}

/*==================*/
/*==== 來客數統計 ====*/
/*==================*/
.date-control-container {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.chart-grid-container {
  // height: 100px;
  display: grid;
  grid-gap: 15px;
  grid-template-columns: 300px auto;
  margin-top: 15px;
  margin-bottom: 15px;

  .pie-cahrt {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
    background: white;
  }
}

/*======================*/
/*==== 服務人員銷售表 ====*/
/*======================*/
.sales-table {
  margin-top: 10px;
}

/*=======================*/
/*==== 匯出期間統計數據 ====*/
/*=======================*/
.export-control-container {
  display: flex;
  align-items: flex-end;
}

.btn-type-create {
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  border-color: transparent;
  @apply text-white bg-primary-100;
}

.btn-type-create:hover {
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  border-color: #0b0f88;
  background-color: transparent;
  color: black;
}

.value {
  padding-top: 20px;
  .head {
    font-size: 18px;
  }
  .number {
    font-size: 24px;
  }
}
.list {
  padding-top: 20px;
  .head {
    font-size: 18px;
    padding: 16px 0;
  }
}

::v-deep .el-select .el-input {
  @apply h-full;
}
</style>
