import { isNull, isUndefined } from 'lodash'

export const toFixed = (num, fixed) => {
  return Number(num.toFixed(fixed))
}

export const statisticalValue = (value, defaultValue = '-') => {
  if (isUndefined(value) || isNull(value) || isNaN(value)) return defaultValue
  return value
}
