<template>
  <BaseDialog
    title="回饋金部分折抵"
    width="576px"
    :btn1Disabled="disabledControl.btn.confirm"
    @confirm="onConfirm"
    @close="$emit('close')"
  >
    <div style="margin-bottom: 40px">
      <BaseElForm ref="formRef" :model="formData" :rules="formRules">
        <BaseElFormItem prop="amount">
          <div class="flex items-center justify-evenly" style="gap: 8px">
            <span>使用</span>
            <ElInputWrapper>
              <BaseElInputNumber
                v-model="formData.amount"
                :step="1"
                step-strictly
                style="width: 418px;"
                :max="maxDiscount"
              />
            </ElInputWrapper>
            <span>回饋金</span>
          </div>
        </BaseElFormItem>
      </BaseElForm>

      <div>
        現有回饋金 {{ displayData.memberCashback }}，折扣後剩餘 {{ displayData.remainingCashback }} 回饋金
      </div>

      <div class="text-danger flex justify-between">
        <span>折扣金額</span>
        <span>-$ {{ formData.amount }}</span>
      </div>
    </div>
  </BaseDialog>
</template>

<script>
import { computed, defineComponent } from 'vue'
import BaseDialog from './Dialog/BaseDialog.vue'
import { useBaseForm } from '@/use/useForm'
import ElInputWrapper from '@/components/ElInputWrapper.vue'
import { useSalesPOSCreate } from '@/use/useSalesPOS'
import { get } from 'lodash'
import { maxNumberRules, minRules, noEmptyRules } from '@/validation'

export default defineComponent({
  name: 'SalesPOSCashbackPartDiscountModal',
  components: {
    BaseDialog,
    ElInputWrapper,
  },
  props: {
    maxPrice: {
      type: Number,
    },
  },
  setup (props, { emit }) {
    const { configData, context, computedUsedResource } = useSalesPOSCreate()
    const { formRef, formData, initFormData, checkForm, loading } = useBaseForm()
    initFormData({
      amount: 0,
    })

    const formRules = computed(() => {
      const usedCashback = get(computedUsedResource.value, 'cashback', 0)
      const memberCashback = get(configData, 'consumerData.cashbackBalance', 0)
      const remainingCashbackBalance = memberCashback - usedCashback

      let maxNumMessage
      if (formData.amount > remainingCashbackBalance) {
        maxNumMessage = '回饋金餘額不足'
      }

      const rules = {
        amount: [noEmptyRules(), minRules(1), maxNumberRules(remainingCashbackBalance, maxNumMessage)],
      }
      return rules
    })

    const disabledControl = computed(() => {
      const controls = {
        btn: {
          confirm: false,
        },
      }

      if (formData.amount <= 0) {
        controls.btn.confirm = true
      } else if (formData.amount > maxDiscount.value) {
        controls.btn.confirm = true
      }

      return controls
    })

    const maxDiscount = computed(() => {
      if (props.maxPrice) {
        return props.maxPrice
      }
      const usedCashback = get(computedUsedResource.value, 'cashback', 0)
      const memberCashback = get(configData, 'consumerData.cashbackBalance', 0)
      const remainingCashbackBalance = memberCashback - usedCashback
      return remainingCashbackBalance
    })

    const displayData = computed(() => {
      const usedCashback = get(computedUsedResource.value, 'cashback', 0)
      const memberCashback = get(configData, 'consumerData.cashbackBalance', 0)
      const remainingCashbackBalance = memberCashback - usedCashback
      return {
        memberCashback: remainingCashbackBalance,
        remainingCashback: remainingCashbackBalance - formData.amount,
      }
    })

    const onConfirm = async () => {
      if (loading.value) return
      loading.value = true
      const pass = await checkForm(formRef.value)
      loading.value = false
      if (!pass) return
      emit('confirm', formData)
      emit('close')
    }

    return {
      formRef,
      formData,
      formRules,
      onConfirm,
      displayData,
      maxDiscount,
      disabledControl,
    }
  },
})
</script>

<style lang="postcss" scoped>

</style>
