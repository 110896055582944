<template>
  <BaseDialog
    title="後台手動標記"
    :visible="true"
    :close-on-click-modal="false"
    @close="$emit('close')"
    @confirm="updateStatus"
  >
    <BaseElForm
      ref="formRef"
      :model="formData"
      :rules="formRules"
      label-position="top"
    >
      <BaseElFormItem label="操作" prop="status">
        <BaseElSelect
          v-model="formData.status"
          placeholder="請選擇標記狀態"
        >
          <BaseElSelectOption
            v-for="item in markedStatusOptions"
            :key="item.value"
            :label="item.optionLabel"
            :value="item.value"
          />
        </BaseElSelect>
      </BaseElFormItem>
    </BaseElForm>
    <p class="text-gray-60 text-sm">注意：標記狀態不會影響系統獎勵的觸發與回收</p>
  </BaseDialog>
</template>

<script>
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import { noEmptyRules } from '@/validation/index'
import { markedStatusConfig } from '@/config/externalTransaction'
import { onMounted, ref, computed } from 'vue'
import { useBaseForm } from '@/use/useForm'
import { omit } from 'lodash'

export default {
  name: 'MarkExternalTransactionModal',
  components: {
    BaseDialog,
  },
  props: {
    status: {
      type: String,
    },
  },
  setup(props, { emit }) {
    const { formRef, formData, initFormData, checkForm } = useBaseForm()
    const markedStatusOptions = computed(() => {
      const omitList = ['systemInvalid']
      return omit(markedStatusConfig, omitList)
    })
    const formRules = {
      status: [noEmptyRules()],
    }
    initFormData({
      status: '',
    })
    const updateStatus = async () => {
      const pass = await checkForm(formRef.value)
      if (!pass) return
      emit('updateStatus', formData.status)
    }
    onMounted(() => {
      formData.status = props.status
    })
    return {
      markedStatusOptions,
      formRef,
      formData,
      formRules,
      updateStatus,
    }
  },
}
</script>

<style></style>
