export const paymentOptionsConfig = {
  wallet: { name: '儲值金', value: 'wallet', type: 'wallet' },
  externalWallet: { name: '會員餘額', value: 'externalWallet', type: 'wallet' },
  newebpay: { name: '藍新支付', value: 'newebpay', type: 'third-part' },
  jkos: { name: '街口支付', value: 'jkos', type: 'third-part' },
  linepay: { name: 'LINE Pay', value: 'linepay', type: 'third-part' },
  pchomepay: { name: 'PChomePay 支付連', value: 'pchomepay', type: 'third-part' },
  atm: { name: 'ATM轉帳', value: 'atm', type: 'offline' },
  COD: { name: '貨到付款', value: 'COD', type: 'offline' },
  'offline-jkos': { name: '街口支付（實體店）', value: 'offline-jkos', type: 'offline' },
  'offline-newebpay': { name: '信用卡（實體店）', value: 'offline-newebpay', type: 'offline' },
  'offline-linepay': { name: 'LINE Pay（實體店）', value: 'offline-linepay', type: 'offline' },
  'offline-cash': { name: '現金（實體店）', value: 'offline-cash', type: 'offline' },
  free: { name: '免付款', value: 'free', type: 'free' },
  offline: { name: '匯款（實體店）', value: 'offline', type: 'offline' },
  ezAIO: { name: '刷卡機（ezAIO）', value: 'ezAIO' },
  ezAIOTrust: { name: '刷卡機（ezAIO）', value: 'ezAIOTrust' },
  cash: { name: '現金', value: 'cash' },
  deposit: { name: '儲值金', value: 'deposit' },
}

export const paymentStatusConfig = {
  unpaid: { name: '未付款', value: 'unpaid', tagType: 'warning' },
  paid: { name: '已付款', value: 'paid', tagType: 'action' },
  waitRefund: { name: '等待退款', value: 'waitRefund', tagType: 'warning' },
  refund: { name: '已退款', value: 'refund', tagType: 'info' },
  fail: { name: '付款失敗', value: 'fail', tagType: 'danger' },
  expire: { name: '付款過期', value: 'expire', tagType: 'danger' },
  cancel: { name: '付款過期', value: 'cancel', tagType: 'danger' },
}

export const paymentConfigFields = {
  linepay: ['linePayChannelId', 'linePayChannelSecretKey'],
  jkos: ['jkosStoreId', 'jkosApiKey', 'jkosSercertKey'],
  newebpay: ['newebpayMerchantId', 'newebpayHashKey', 'newebpayHashIV'],
}
