<template>
  <div class="sales-pos-item-edit-tab">
    <SalesPOSCategoryGroupTab />
    <SalesPOSCategoryTab />
    <SalesPOSProductGroupTab />
    <SalesPOSAppointmentItemList v-if="showControl.appointmentItemList" class="flex-1" />
    <SalesPOSProductList v-if="showControl.productList" class="flex-1" />
    <SalesPOSSalesUnitGroupTab />
    <SalesPOSSalesUnitList />
  </div>
</template>

<script>
import { computed, defineComponent } from 'vue'
import SalesPOSCategoryGroupTab from './SalesPOSCategoryGroupTab.vue'
import SalesPOSCategoryTab from './SalesPOSCategoryTab.vue'
import SalesPOSProductGroupTab from './SalesPOSProductGroupTab.vue'
import SalesPOSSalesUnitGroupTab from './SalesPOSSalesUnitGroupTab.vue'
import SalesPOSProductList from './SalesPOSProductList.vue'
import SalesPOSSalesUnitList from './SalesPOSSalesUnitList.vue'
import SalesPOSAppointmentItemList from './SalesPOSAppointmentItemList.vue'
import { useSalesPOSCreate } from '@/use/useSalesPOS'
import { usePermissions } from '@/use/permissions'

export default defineComponent({
  name: 'SalesPOSItemEditTab',
  components: {
    SalesPOSProductList,
    SalesPOSCategoryGroupTab,
    SalesPOSCategoryTab,
    SalesPOSProductGroupTab,
    SalesPOSSalesUnitGroupTab,
    SalesPOSSalesUnitList,
    SalesPOSAppointmentItemList,
  },
  setup(props, { emit }) {
    const { context } = useSalesPOSCreate()

    const showControl = computed(() => {
      const controls = {
        appointmentItemList: context.categoryGroup === 'appointment',
        productList: context.categoryGroup !== 'appointment',
      }

      return controls
    })

    return { showControl }
  },
})
</script>

<style lang="postcss" scoped>
.sales-pos-item-edit-tab {
  @apply w-full flex flex-col gap-[12px];
}
</style>
