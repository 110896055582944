export const rebateStatusConfig = {
  wait: { name: '等待發送', value: 'wait' },
  add: { name: '已發送', value: 'add' },
  cancel: { name: '已收回', value: 'cancel' },
}

export const originConfig = {
  ADMIN_ADD: { name: '後台贈送', value: 'ADMIN_ADD' },
  ADMIN_USE: { name: '後台扣除', value: 'ADMIN_USE' },
  SALE_RECORD_USE: { name: '銷售使用', value: 'SALE_RECORD_USE' },
  SALE_RECORD_ADD: { name: '銷售回饋', value: 'SALE_RECORD_ADD' },
  SALE_RECORD_ADD_CANCEL: { name: '銷售回饋取消', value: 'SALE_RECORD_ADD_CANCEL' },
  SALE_RECORD_USE_CANCEL: { name: '銷售取消不回補', value: 'SALE_RECORD_USE_CANCEL' },
  EC_ORDER_USE: { name: '電商使用', value: 'EC_ORDER_USE' },
  EC_ORDER_ADD: { name: '電商回饋', value: 'EC_ORDER_ADD' },
  EC_ORDER_ADD_CANCEL: { name: '電商回饋取消', value: 'EC_ORDER_ADD_CANCEL' },
  EC_ORDER_USE_CANCEL: { name: '電商取消不回補', value: 'EC_ORDER_USE_CANCEL' },
  EXPIRED: { name: '過期', value: 'EXPIRED' },
  MEMBER_STORE_USE: { name: '商城扣除', value: 'MEMBER_STORE_USE' },
  POINT_EXCHANGE_ADD: { name: '點數兌換回饋', value: 'POINT_EXCHANGE_ADD' },
  POINT_EXCHANGE_ADD_CANCEL: { name: '點數兌換回收', value: 'POINT_EXCHANGE_ADD_CANCEL' },
  REWARD_ACTIVITY_EC_ORDER_ADD: { name: '電商回饋', value: 'REWARD_ACTIVITY_EC_ORDER_ADD' },
  REWARD_ACTIVITY_EC_ORDER_ADD_CANCEL: { name: '電商回收', value: 'REWARD_ACTIVITY_EC_ORDER_ADD_CANCEL' },
  REWARD_ACTIVITY_SALE_RECORD_ADD: { name: '銷售回饋', value: 'REWARD_ACTIVITY_SALE_RECORD_ADD' },
  REWARD_ACTIVITY_SALE_RECORD_ADD_CANCEL: { name: '銷售回收', value: 'REWARD_ACTIVITY_SALE_RECORD_ADD_CANCEL' },
  REWARD_ACTIVITY_MEMBER_STORE_ADD: { name: '商城回饋', value: 'REWARD_ACTIVITY_MEMBER_STORE_ADD' },
  REWARD_ACTIVITY_MEMBER_STORE_ADD_CANCEL: { name: '商城回收', value: 'REWARD_ACTIVITY_MEMBER_STORE_ADD_CANCEL' },
  REWARD_ACTIVITY_MEMBER_EXTERNAL_TRANSACTION_ADD: { name: '消費回饋', value: 'REWARD_ACTIVITY_MEMBER_EXTERNAL_TRANSACTION_ADD' },
  REWARD_ACTIVITY_MEMBER_EXTERNAL_TRANSACTION_ADD_CANCEL: { name: '消費回收', value: 'REWARD_ACTIVITY_MEMBER_EXTERNAL_TRANSACTION_ADD_CANCEL' },
  LOTTERY_AWARD_ADD: { name: '抽獎活動獲得', value: 'LOTTERY_AWARD_ADD' },
  SERIAL_ACTIVITY_ADD: { name: '序號登錄獲得', value: 'SERIAL_ACTIVITY_ADD' },
  EVENT_PLAYBOOK_SEND: { name: '系統行銷活動發放', value: 'EVENT_PLAYBOOK_SEND' },
  EXTEND_IN: { name: '回饋金展延-當年度轉入', value: 'EXTEND_IN' },
  EXTEND_OUT: { name: '回饋金展延-當年度轉出', value: 'EXTEND_OUT' },
  APPOINTMENT_CHECKOUT_USE: { name: '預約結帳使用', value: 'APPOINTMENT_CHECKOUT_USE' },
  rewardDistribution: { name: '獎勵發放活動', value: 'rewardDistribution' },
  REWARD_DISTRIBUTION_ADD: { name: '獎勵活動發放', value: 'REWARD_DISTRIBUTION_ADD' },
  MEMBER_BENEFIT_ADD: { name: '會員權益發放', value: 'MEMBER_BENEFIT_ADD' },
}
