<template>
  <section class="section-block">
    <SectionTitle title="銷售紀錄折抵設定" @edit="modal.edit = true" />
    <BaseElForm label-position="left" label-width="200px">
      <BaseElFormItem
        v-for="field in displayFields"
        :key="field.label"
        :label="field.label"
      >
        <div class="secret-text">
          <span>{{ field.value }}</span>
        </div>
      </BaseElFormItem>
    </BaseElForm>

    <EditSalesDiscountConfigModal
      v-if="modal.edit"
      :configData="configData"
      @close="modal.edit = false"
      @refresh="$emit('refresh')"
    />
  </section>
</template>

<script>
import { computed, defineComponent, reactive } from 'vue'
import EditSalesDiscountConfigModal from './components/EditSalesDiscountConfigModal.vue'
import { get, filter } from 'lodash'
import { usePermissions } from '@/use/permissions'

export default defineComponent({
  name: 'SalesDiscountConfigBlock',
  components: {
    EditSalesDiscountConfigModal,
  },
  props: {
    configData: {
      type: Object,
      default: () => ({}),
    },
  },
  setup (props) {
    const { checkAction } = usePermissions()
    const modal = reactive({
      edit: false,
    })

    const useFeatures = computed(() => {
      return {
        usePoint: checkAction('admin.shopPoint.page'),
        useCashback: checkAction('admin.shopCashback.page'),
      }
    })

    const displayFields = computed(() => {
      const pointDiscountRate = get(props.configData, 'pointDiscountRate', '-')
      const omitList = []
      const fields = [
        { label: '回饋金折抵', value: get(props.configData, 'enableCashbackDiscount') ? '是' : '否' },
        { label: '點數折抵', value: get(props.configData, 'enablePointDiscount') ? '是' : '否' },
        { label: '點數折扣規則', value: `扣除 ${pointDiscountRate} 點，折抵 1 元` },
      ]

      if (!get(props.configData, 'enablePointDiscount')) {
        omitList.push('點數折扣規則')
      }

      if (!useFeatures.value.useCashback) {
        omitList.push('回饋金折抵')
      }
      if (!useFeatures.value.usePoint) {
        omitList.push('點數折抵')
        omitList.push('點數折扣規則')
      }

      return filter(fields, (field) => {
        return !omitList.includes(field.label)
      })
    })

    return { modal, displayFields }
  },
})
</script>

<style lang="postcss" scoped>

</style>
