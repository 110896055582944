<template>
  <BaseDialog
    title="備註"
    width="610px"
    @close="$emit('close')"
    @confirm="onConfirm"
  >
    <div style="margin-bottom: 40px">
      <BaseElInput
        v-model="formData.note"
        type="textarea"
        :rows="10"
        placeholder="請輸入備註文字"
        maxlength="100"
        show-word-limit
      />
    </div>
  </BaseDialog>
</template>

<script>
import { defineComponent, onBeforeMount, reactive } from 'vue'
import BaseDialog from './Dialog/BaseDialog.vue'
import { apiFormatAdaptor } from '@/utils/api'
import { useShop } from '@/use/shop'
import { UpdateSalesRecord } from '@/api/sales'
import notifyMessage from '@/config/notifyMessage'
import { get } from 'lodash'
export default defineComponent({
  name: 'EditSalesRecordNoteModal',
  components: {
    BaseDialog,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
      required: true,
    },
  },
  setup (props, { emit }) {
    const { shopId } = useShop()
    const formData = reactive({
      note: '',
    })
    const onConfirm = async () => {
      const [res, err] = await apiFormatAdaptor(UpdateSalesRecord)({
        shopId: shopId.value,
        id: get(props.data, 'id'),
        note: formData.note,
      })
      if (err) {
        window.$message.error(err)
        return
      }
      window.$message.success(notifyMessage.updateSuccess)
      emit('close')
      emit('refresh')
    }

    onBeforeMount(() => {
      formData.note = get(props.data, 'note')
    })

    return { formData, onConfirm }
  },
})
</script>

<style lang="postcss" scoped>

</style>
