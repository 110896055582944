<template>
  <el-dialog
    data-testid="toggle-period-modal"
    :title="title"
    :visible="true"
    width="610px"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <section class="flex flex-col">
      <ServiceUnitSelect
        testName="search_unit"
        class="mb-3"
        :model.sync="unit"
        @change="getPeriods"
      />
      <el-date-picker
        v-model="date"
        data-testid="el-date-picker-search_date"
        editable
        type="date"
        :placeholder="$t('shiftCalendar.dialog.date.placeholder')"
        @change="getPeriods"
      />
    </section>

    <div v-if="periods.length" class="period-list" style="gap: 10px;">
      <div
        v-for="(p, index) in periods"
        :key="p.start"
        :data-testid="`period-${timeFormat(p.start)}`"
        class="period"
        :class="{
          'status-available': p.status === 'available',
          'status-booking': p.status === 'booking',
          'status-ban': p.status === 'ban',
        }"
        type="info"
        @click="periodClick(p, index)"
      >
        <p>{{ timeFormat(p.start) }}</p>
        <p>{{ statusFormat(p.status) }}</p>
      </div>
    </div>
    <div v-if="date && unit && !periods.length">
      {{ $t('shiftCalendar.dialog.empty.text')}}
    </div>

    <span slot="footer" class="dialog-footer">
      <div>
        <BaseElButton plain @click="$emit('close')">{{ $t('common.button.cancel.text')}}</BaseElButton>
        <BaseElButton type="primary" @click="updatePeriod">{{ $t('common.button.save.text')}}</BaseElButton>
      </div>
    </span>
  </el-dialog>
</template>

<script>
import ServiceUnitSelect from '@/components/Select/ServicesUnitSelect.vue'
import { GetPeriods, UpdatePeriod } from '@/api/togglePeriod'
import { timesConvert } from '@/utils/helper'
import dayjs from '@/lib/dayjs'
import { mapGetters } from 'vuex'
import { i18n } from '@/plugins/i18n/i18n'

export default {
  name: 'TogglePeriodDialog',
  components: { ServiceUnitSelect },
  props: {
    title: String,
    selectDate: [String, Date],
    selectUnit: [String, Object],
  },
  computed: {
    ...mapGetters(['shop']),
  },

  data: () => ({
    date: '',
    unit: '',
    config: null,
    available: [],
    periods: [],
    interval: 0,
  }),

  async mounted () {
    if (this.selectDate) this.date = new Date(this.selectDate)
    if (this.selectUnit) {
      this.unit = { id: this.selectUnit }
      await this.getPeriods()
    }
    // this.generatePeriod()
  },

  methods: {
    timeFormat (dateTime) {
      return dayjs(dateTime).format('HH:mm')
    },

    statusFormat (status) {
      const statusDict = {
        available: i18n.t('shiftCalendar.status.available.text'),
        booking: i18n.t('shiftCalendar.status.booking.text'),
        ban: i18n.t('shiftCalendar.status.ban.text'),
      }
      return statusDict[status]
    },

    periodClick (period, index) {
      const status = period.status
      if (status === 'available') {
        this.periods[index].status = 'ban'
      }
      if (status === 'ban') {
        this.periods[index].status = 'available'
      }
    },

    generatePeriod () {
      const interval = this.config.timeUnit
      const offset = this.config.timeUnitOffset

      let count = 1
      while (interval * count + offset < 1440) {
        const period = timesConvert(interval * count + offset)
        count += 1
        this.period.push(period)
      }
    },

    async getPeriods () {
      if (!this.unit.id) return
      if (this.date === '') return
      const res = await GetPeriods({
        shopId: this.shop,
        appointmentUnitId: this.unit.id,
        start: new Date(`${dayjs(this.date).format('YYYY/MM/DD')} 00:00`),
        end: new Date(
          `${dayjs(this.date)
            .add(1, 'd')
            .format('YYYY/MM/DD')} 00:00`,
        ),
      })

      this.periods = res.times
      this.interval = res.timeUnit
    },

    async updatePeriod () {
      try {
        const tempTimes = this.periods.map(item => ({ ...item, availableVolume: undefined }))
        await UpdatePeriod({
          shopId: this.shop,
          appointmentUnitId: this.unit.id,
          times: tempTimes,
        })
        this.$message.success(i18n.t('common.message.updateSuccess'))
        this.$emit('updated')
        // this.$emit('close')
      } catch (error) {
        this.$message.error(error)
      }
    },
  },
}
</script>

<style scoped lang="scss">
::v-deep .el-date-editor{
  @apply w-full;
}
.period {
  border: solid gray 1px;
  border-radius: 5px;
  padding: 5px 10px;
  cursor: pointer;
}

.period-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.status-available {
  background: #31d58c;
}

.status-ban {
  background: #a1a1a1;
}

.status-booking {
  color: rgb(238, 238, 238);
  background: #f76b6e;
}
</style>
