<template>
  <div class="sales-parameter">
    <section class="section-block">
      <SectionTitle :title="$t('publicAppointment.storeInfo.title')" :btn="$t('publicAppointment.button.goBranchSetting.text')" @edit="router.push({ name: 'BranchShopSetting' })" />
      <div>
        <BaseElForm label-position="left" label-width="250px">
          <BaseElFormItem :label="$t('publicAppointment.storeInfo.shopName.title')">
            <div class="secret-text collapse">
              <span>{{ shopName || '-' }}</span>
            </div>
          </BaseElFormItem>
          <BaseElFormItem :label="$t('publicAppointment.storeInfo.shopPhone.title')">
            <div class="secret-text collapse">
              <span>{{ shopPhone || '-' }}</span>
            </div>
          </BaseElFormItem>
          <BaseElFormItem :label="$t('publicAppointment.storeInfo.shopAddress.title')">
            <div class="secret-text collapse">
              <span>{{ shopAddress || '-' }}</span>
            </div>
          </BaseElFormItem>
          <BaseElFormItem :label="$t('publicAppointment.storeInfo.shopEmail.title')">
            <div class="secret-text collapse">
              <span>{{ shopEmail ? shopEmail : $t('common.notSetupYet.text') }}</span>
            </div>
          </BaseElFormItem>
        </BaseElForm>
      </div>
    </section>
  </div>
</template>

<script>
import { defineComponent, onMounted, reactive } from 'vue'
import SectionTitle from '@/components/Title/SectionTitle'
import { useRouter } from 'vue-router/composables'
import { useShop } from '@/use/shop'
export default defineComponent({
  name: 'StoreInfo',
  components: { SectionTitle },
  setup () {
    const router = useRouter()
    const { shopName, shopPhone, shopAddress, shopEmail } = useShop()
    return {
      router,
      shopName,
      shopPhone,
      shopAddress,
      shopEmail,
    }
  },
})
</script>

<style scoped lang="scss">
.secret-text,
.note {
  // width: 100px;
  font-weight: 400;
  @apply text-gray-80;
  letter-spacing: 1px;
}
</style>
