import Layout from '@/layout/index.vue'
import { EcommerceParameters } from './ecommerce'
import { MemberShopParameters } from './memberShop'
import { SalesParameters } from './sales'
import { MemberLevelParameters } from './memberLevel'

export const parametersRoutes = [
  {
    path: '/parameters',
    name: 'Parameters',
    component: Layout,
    redirect: '/parameter-settings',
    hidden: true,
    children: [
      {
        path: '/parameter/line-settings',
        name: 'LineSettings',
        component: () => import('@/views/Parameters/LineSettings/LineSettings.vue'),
        meta: {
          title: 'Line串接設定',
          action: 'admin.lineConfig.page',
        },
      },
      {
        path: '/parameter/member-center',
        name: 'MembersCenter',
        // hidden: true,
        component: () => import('@/views/Parameters/MemberCenter/MemberCenter'),
        meta: {
          title: '會員中心模組參數設定',
          action: 'admin.memberCenterConfig.page',
        },
      },
      // 預約
      {
        path: '/parameter/reservations/reservation-parameter',
        name: 'ReservationParameter',
        component: () => import('@/views/Parameters/ReservationParameters/ReservationParameters'),
        meta: {
          title: 'parameterSetting.reservationParameterSetting.title',
          action: 'admin.appointmentConfig.page',
        },
      },
      {
        path: 'reservations/payment-settings',
        name: 'EditReservationPaymentSettings',
        hidden: true,
        component: () =>
          import(
            '@/views/Parameters/ReservationParameters/components/EditReservationPaymentSettings/EditReservationPaymentSettings.vue'
          ),
        meta: {
          title: '收費模式設定',
          action: 'admin.appointmentConfig.page',
        },
      },
      // {
      //   path: '/parameter/sales/sales-parameter',
      //   name: 'SalesParameter',
      //   component: () => import('@/views/Parameters/SalesParameters/SalesParameters.vue'),
      //   meta: {
      //     title: '銷售紀錄參數設定',
      //     action: 'admin.salesConfig.page',
      //   },
      // },
      {
        path: '/parameter/sales/receive-notify',
        name: 'ReceiveNotify',
        component: () => import('@/views/Parameters/ReceiveNotify/ReceiveNotify.vue'),
        meta: {
          title: 'parameterSetting.reveiceNotifySetting.title',
          action: 'admin.shopEmailNotify.find',
        },
      },
      {
        path: '/parameter/rebate/rebate-settings',
        component: () => import('@/views/Parameters/Rebate/RebateSettings.vue'),
        meta: {
          title: '回饋金參數設定',
          action: 'admin.shopCashback.page',
        },
      },
      {
        path: '/parameter/store/store-settings',
        name: 'StoreSetting',
        component: () => import('@/views/Parameters/Store/StoreSettings.vue'),
        meta: {
          title: '門市設定',
          action: 'admin.branch.page',
        },
      },
      {
        path: '/parameter/ticket/ticket-settings',
        name: 'TicketSetting',
        component: () => import('@/views/Parameters/Ticket/TicketSettings.vue'),
        meta: {
          title: '堂票模組參數設定',
          action: 'admin.classTicketConfig.page',
        },
      },
      {
        path: '/parameter/point/point-settings',
        name: 'PointSetting',
        component: () => import('@/views/Parameters/Point/PointSettings.vue'),
        meta: {
          title: '點數模組參數設定',
          action: 'admin.shopPoint.page',
        },
      },

      ...EcommerceParameters,
      ...MemberShopParameters,
      ...SalesParameters,
      {
        path: '/parameter/external-module-settings',
        name: 'ExternalModuleSettings',
        component: () =>
          import('@/views/Parameters/ExternalModuleParameters/ExternalModuleParameters.vue'),
        meta: {
          title: '第三方交易模組設定',
          action: 'admin.memberExternalTransaction.page',
        },
      },
      {
        path: '/parameter/coupon-settings',
        name: 'CouponSettings',
        component: () => import('@/views/Parameters/Coupon/CouponSettings.vue'),
        meta: {
          title: '票券模組設定',
          action: 'admin.couponConfig.page',
        },
      },
      {
        path: '/parameter/reward-activity-settings',
        name: 'RewardActivitySettings',
        component: () => import('@/views/Parameters/RewardActivity/RewardActivitySettings.vue'),
        meta: {
          title: '序號登錄模組參數設定',
          action: 'admin.serialActivity.page',
        },
      },
      {
        path: '/parameter/external-integrate-module-settings',
        name: 'ExternalIntegrateModuleSettings',
        component: () =>
          import('@/views/Parameters/ExternalIntegrateParameters/ExternalIntegrateParameters.vue'),
        meta: {
          title: '第三方串接模組設定',
          action: 'admin.shopCustomModule.page',
        },
      },
      {
        path: '/parameter/resource-settings',
        name: 'ResourceSettings',
        component: () => import('@/views/Parameters/Resource/ResourceSettings.vue'),
        meta: {
          title: 'parameterSetting.resourceSettings.title',
          action: 'admin.resourceConfig.page',
        },
      },
      {
        path: '/parameter/shop-business-time-settings',
        name: 'ShopBusinessTimeSettings',
        component: () => import('@/views/Parameters/ShopBusinessTime/ShopBusinessTimeSettings.vue'),
        meta: {
          title: 'parameterSetting.businessTimeSetting.title',
          action: 'admin.shopBusinessTime.page',
        },
      },
      ...MemberLevelParameters,
      {
        path: '/parameter/tappay-settings',
        name: 'TapPaySettings',
        component: () => import('@/views/Parameters/TapPay/TapPay.vue'),
        meta: {
          title: 'iPhone 卡緊收參數設定',
          action: 'admin.tapPayConfig.page',
        },
      },
      {
        path: '/parameter/indoor-control-settings',
        name: 'IndoorControlSettings',
        component: () => import('@/views/Parameters/IndoorControl/IndoorControl.vue'),
        meta: {
          title: '室內設備控制設定',
          action: 'admin.indoorControlConfig.page',
        },
      },
      {
        path: '/parameter/indoor-control-detail-settings/:id?/:branchName?',
        name: 'IndoorControlDetailSettings',
        component: () => import('@/views/Parameters/IndoorControl/IndoorControlDetail.vue'),
        hidden: true,
        meta: {
          title: '室內設備控制設定',
          action: 'admin.indoorControlConfig.page',
        },
      },
      {
        path: '/parameter/checkin-page-settings',
        name: 'CheckinPageSettings',
        component: () => import('@/views/Parameters/CheckInPage/CheckInPage.vue'),
        meta: {
          title: '報到頁面設定',
          action: 'admin.checkInPageSetting.page',
        },
      },
      {
        path: '/parameter/custom-order-status',
        name: 'CustomOrderStatus',
        component: () => import('@/views/Parameters/CustomOrderStatus/CustomOrderStatus.vue'),
        meta: {
          title: '自定義訂單狀態模組',
          action: 'admin.customFlow.page',
        },
      },
      {
        path: '/parameter/custom-order-status/edit/:scope/:id?',
        name: 'EditCustomOrderStatusSetting',
        component: () =>
          import(
            '@/views/Parameters/CustomOrderStatus/components/EditCustomOrderStatusSetting.vue'
          ),
        hidden: true,
        meta: {
          title: '編輯義訂單狀態模組',
          action: 'admin.customFlow.page',
        },
      },
      // 第三方模組顯示名稱設定
      {
        path: '/parameter/external-point-settings',
        name: 'ExternalPointSettings',
        component: () => import('@/views/Parameters/ExternalPoint/ExternalPoint.vue'),
        meta: {
          title: '第三方模組顯示名稱設定',
          // action: 'admin.externalPointConfig.page',
          actions: ['admin.externalPointConfig.page', 'admin.externalWalletConfig.page'],
        },
      },
      {
        path: '/parameter/public-appointment-settings',
        name: 'PublicAppointmentSettings',
        component: () => import('@/views/Parameters/PublicAppointment/PublicAppointment.vue'),
        meta: {
          title: 'parameterSetting.publicAppointmentSetting.title',
          action: 'admin.pubAptConfig.page',
        },
      },
      {
        path: '/parameter/entry-control-settings',
        name: 'EntryControlSettings',
        component: () => import('@/views/Parameters/EntryControlSettings/EntryControlSettings.vue'),
        meta: {
          title: '進場模組設定',
          action: 'admin.entryControlConfig.page',
        },
      },
      {
        path: '/parameter/entry-control-settings/time-and-price',
        name: 'EditEntryControlTimeAndPriceSetting',
        hidden: true,
        component: () => import('@/views/Parameters/EntryControlSettings/components/EditEntryControlTimeAndPriceSetting/EditEntryControlTimeAndPriceSetting.vue'),
        meta: {
          title: '編輯進場時段與收費模式',
        },
      },
      {
        path: '/parameter/point-card-settings',
        name: 'PointCardSettings',
        component: () => import('@/views/Parameters/PointCard/PointCardSettings.vue'),
        meta: {
          title: '點數卡模組設定',
          action: 'admin.pointCardConfig.page',
        },
      },
      {
        path: '/parameter/punch-card-settings',
        name: 'PunchCardSettings',
        component: () => import('@/views/Parameters/PunchCard/PunchCardSettings.vue'),
        meta: {
          title: '次卡模組設定',
          action: 'admin.punchCardConfig.page',
        },
      },
      {
        path: '/parameter/periodic-benefit-settings',
        name: 'PeriodicBenefitSettings',
        component: () => import('@/views/Parameters/PeriodicBenefitParameters/PeriodicBenefitParameters.vue'),
        meta: {
          title: '會員權益參數設定',
          action: 'admin.periodicBenefitConfig.page',
        },
      },
    ],
  },
]
