<template>
  <GrayBlockContainer class="flex flex-col" style="gap: 24px">
    <p class="section-title">活動折抵</p>

    <div v-if="showControl.formItem.cashbackUse">
      <p>{{ displayData.formItem.cashback }}</p>
      <p class="text-sm text-gray-80">{{ displayData.hint.cashbackLimit }}</p>

      <BaseElFormItem prop="discount.cashback.use">
        <BaseElInput
          v-model="context.discount.cashback.use"
          type="number"
          :max="discountLimit"
          :disabled="disabledControl.cashbackUse"
          :placeholder="displayData.placeholder.cashbackUse"
        />
      </BaseElFormItem>
    </div>

    <div v-if="showControl.hint.noMember">
      <p class="text-sm text-gray-80">{{ displayData.hint.noMember }}</p>
    </div>
  </GrayBlockContainer>
</template>

<script>
import GrayBlockContainer from '@/components/Container/GrayBlockContainer.vue'
import { useCreateAappointment } from '@/use/useCreateAppointment'
import { toNumber, isNumber, get } from 'lodash'

import { computed, defineComponent } from 'vue'

export default defineComponent({
  name: 'AppointmentDiscountBlock',
  components: {
    GrayBlockContainer,
  },
  setup (props, { emit }) {
    const { context, memberData, configData } = useCreateAappointment()
    const chargeType = computed(() => get(context.checkoutDetail, 'chargeType'))
    const enableCashbackDiscount = computed(() => {
      return (
        get(configData.checkout, 'enableCashbackDeduction') && chargeType.value === 'checkout'
      )
    })
    const discountLimit = computed(() => toNumber(get(context, 'discount.cashback.limit')))

    const disabledControl = computed(() => {
      const controls = {
        cashbackUse: false,
      }

      const cashbackLimit = get(context, 'discount.cashback.limit', 0)
      const cashbackBalance = get(memberData, 'cashback.balance', 0)
      const cashbackUse = get(context, 'discount.cashback.use', 0)

      if (!context.member) {
        controls.cashbackUse = true
      } else if (toNumber(cashbackBalance) <= 0) {
        controls.cashbackUse = true
      }

      return controls
    })

    const showControl = computed(() => {
      const controls = {
        formItem: {
          cashbackUse: true,
        },
        hint: {
          noMember: false,
        },
      }

      if (!context.member) {
        controls.formItem.cashbackUse = false
        controls.hint.noMember = true
      }

      if (!enableCashbackDiscount.value) {
        controls.formItem.cashbackUse = false
      }

      return controls
    })

    const displayData = computed(() => {
      const cashbackLimit = get(context, 'discount.cashback.limit')
      const cashbackBalance = get(memberData, 'cashback.balance')
      let cashbackUsePlaceholder = '請輸入折抵金額'

      if (toNumber(cashbackBalance) <= 0) {
        cashbackUsePlaceholder = '餘額不足'
      }

      return {
        formItem: {
          cashback: '回饋金',
        },
        hint: {
          cashbackLimit: `此筆預約訂單可折抵上限為 ${isNumber(cashbackLimit) ? cashbackLimit : '-'} 元，目前會員持有回饋金 ${isNumber(cashbackBalance) ? cashbackBalance : '-'} 元。`,
          noMember: '請先選擇會員',
        },
        placeholder: {
          cashbackUse: cashbackUsePlaceholder,
        },
      }
    })

    return { context, displayData, memberData, discountLimit, disabledControl, showControl }
  },
})
</script>

<style lang="postcss" scoped>
.section-title {
  @apply font-bold text-primary-100 text-[18px];
}
</style>
