import { render, staticRenderFns } from "./MemberShopPointLinkEditInfoBlock.vue?vue&type=template&id=45677b70&scoped=true"
import script from "./MemberShopPointLinkEditInfoBlock.vue?vue&type=script&lang=js"
export * from "./MemberShopPointLinkEditInfoBlock.vue?vue&type=script&lang=js"
import style0 from "./MemberShopPointLinkEditInfoBlock.vue?vue&type=style&index=0&id=45677b70&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45677b70",
  null
  
)

export default component.exports