import { admin, admin2 } from '@/api/instance'

const baseUrl = (shopId) => {
  return `/${shopId}/appointmentUnit`
}

export const GetAllowBindUserList = async ({
  shopId,
}) => {
  return await admin2({
    method: 'get',
    url: `${baseUrl(shopId)}/allowBindUserList`,
  })
}

//= > 創建服務人員
export const CreateServiceUnit = async ({
  shopId,
  name,
  image,
  order,
  description,
  bookingMax,
  isPublic,
  isPubApt,
  appointmentServices,
  users,
  color,
}) => {
  const res = await admin({
    method: 'post',
    url: baseUrl(shopId),
    data: {
      name,
      image,
      order,
      description,
      bookingMax,
      isPublic,
      isPubApt,
      appointmentServices,
      users,
      color,
    },
  })

  return res.data
}

//= > 取得服務人員
export const GetServiceUnit = async ({
  shopId,
  start,
  limit,
  name,
}) => {
  const res = await admin({
    method: 'get',
    url: baseUrl(shopId),
    params: {
      start,
      limit,
      name,
    },
  })

  return res.data
}

//= > 取得服務人員總數
export const GetServiceUnitCount = async ({
  shopId,
  name,
}) => {
  const res = await admin({
    method: 'get',
    url: `${baseUrl(shopId)}/count`,
    params: {
      name,
    },
  })

  return res.data.count
}

//= > 取得指定服務人員
export const FindServiceUnit = async ({
  shopId,
  id,
}) => {
  const res = await admin({
    method: 'get',
    url: `${baseUrl(shopId)}/${id}`,
  })

  return res.data
}

//= > 更新服務人員
export const UpdateServiceUnit = async ({
  shopId,
  id,
  name,
  image,
  order,
  description,
  bookingMax,
  isPublic,
  isPubApt,
  appointmentServices,
  users,
  color,
}) => {
  const res = await admin({
    method: 'put',
    url: `${baseUrl(shopId)}/${id}`,
    data: {
      name,
      image,
      order,
      description,
      bookingMax,
      isPublic,
      isPubApt,
      appointmentServices,
      users,
      color,
    },
  })

  return res.data
}

//= > 刪除服務人員
export const DeleteServiceUnit = async ({
  shopId,
  id,
}) => {
  const res = await admin({
    method: 'delete',
    url: `${baseUrl(shopId)}/${id}`,
  })

  return res.data
}

//= > 服務人員可預約時間
export const ServiceUnitAvailableTime = async ({
  shopId,
  id,
  totalBookingTime,
  start,
  end,
  omitOhbookBookingId,
}) => {
  const res = await admin({
    method: 'get',
    url: `${baseUrl(shopId)}/${id}/availableTime`,
    params: {
      start,
      end,
      totalBookingTime,
      omitOhbookBookingId,
    },
  })

  return res.data
}

//= > 查找日曆
export const GetGoogleCalendar = async ({
  shopId,
  id,
}) => {
  const res = await admin({
    method: 'get',
    url: `${baseUrl(shopId)}/${id}/googleCalendarList`,
  })

  return res.data
}

//= > 連動日曆
export const CreateGoogleCalendar = async ({
  shopId,
  id,
  email,
}) => {
  const res = await admin({
    method: 'post',
    url: `${baseUrl(shopId)}/${id}/googleCalendarList`,
    data: {
      email,
    },
  })

  return res.data
}

//= > 刪除日曆
export const DeleteGoogleCalendar = async ({
  shopId,
  id,
  email,
}) => {
  const res = await admin({
    method: 'post',
    url: `${baseUrl(shopId)}/${id}/googleCalendarList/delete`,
    data: {
      email,
    },
  })

  return res.data
}

// NEW

export const GetAvailableTimeByTotalBookingTime = async ({
  shopId,
  id,
  useAllAppointmentUnit,
  useNotSpecifyUnits,
  appointmentUnitId,
  start,
  end,
  totalBookingTime,
  volume,
  preReservation,
  omitOhbookBookings,
}) => {
  return await admin2({
    method: 'POST',
    url: `/${shopId}/appointmentUnit/${id}/showAvailableTimeByTotalBookingTime`,
    data: {
      useAllAppointmentUnit,
      useNotSpecifyUnits,
      appointmentUnitId,
      start,
      end,
      totalBookingTime,
      volume,
      preReservation,
      omitOhbookBookings,
    },
  })
}

export const GetAvailableBookingTime = async ({
  shopId,
  id,
  start,
  end,
  preReservation,
}) => {
  return await admin2({
    method: 'POST',
    url: `/${shopId}/appointmentUnit/${id}/showAvailableBookingTime`,
    data: {
      start,
      end,
      preReservation,
    },
  })
}
