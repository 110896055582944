<template>
  <div class="card-container" style="padding: 20px; padding-left: 30px">
    <p class="section-title">預約時段</p>
    <GrayBlockContainer>
      <p class="section-title" style="margin-bottom: 8px">主要服務</p>
      <p class="text-gray-60">{{ displayData.mainServiceName }}</p>

      <el-divider />

      <div class="periods-list">
        <div v-for="(item, idx) in cart" :key="item.id">
          <div class="flex justify-between items-center">
            <p class="section-title">第{{ idx + 1 }}個時段</p>
            <div v-if="idx !== 0" class="remove-period-btn" @click="onRemovePeriod(item.cartItem)">
              <SVGIcon icon="common-trash" fill="var(--gray-60)" />
            </div>
          </div>
          <AppointmentPeriodForm :item.sync="item.cartItem" />
          <el-divider v-if="idx !== cart.length - 1" />
        </div>

        <div class="flex items-center" style="gap: 24px">
          <BaseElButton type="primary" style="padding: 8.5px 12px;" :disabled="disabledControl.btn.addPeriod" @click="onAddPeriod">
            <div class="flex items-center" style="gap: 4px;">
              新增時段
              <MaterialIcon>add</MaterialIcon>
            </div>
          </BaseElButton>
          <div>
            最多可新增{{ maxPeriodCount }}個不同時段，目前還可再新增<span class="text-danger">{{ remainingPeriodCount }}</span>個時段
          </div>
        </div>
      </div>
    </GrayBlockContainer>
  </div>
</template>

<script>
import GrayBlockContainer from '@/components/Container/GrayBlockContainer.vue'
import { useCreateAappointment } from '@/use/useCreateAppointment'
import { get } from 'lodash'
import { computed, defineComponent, reactive, nextTick, set, onMounted, provide, onBeforeUnmount } from 'vue'
import AppointmentPeriodForm from './AppointmentPeriodForm.vue'

export default defineComponent({
  name: 'AppointmentMultiplePeriodBlock',
  components: { GrayBlockContainer, AppointmentPeriodForm },
  setup (props, { emit }) {
    const {
      cart,
      context,
      addAppointmentCartItem,
      removeAppointmentCartItem,
      allCartItemReady,
    } = useCreateAappointment()
    const maxPeriodCount = 15
    const dataPlaceholder = reactive({})
    const remainingPeriodCount = computed(() => maxPeriodCount - cart.value.length)

    const mainPeriodCartItem = computed(() => get(cart.value, '[0].cartItem'))

    const disabledControl = computed(() => {
      const controls = {
        btn: {
          addPeriod: false,
        },
      }

      if (cart.value.length >= maxPeriodCount) {
        controls.btn.addPeriod = true
      }

      if (!allCartItemReady.value) {
        controls.btn.addPeriod = true
      }

      return controls
    })

    const displayData = computed(() => {
      return {
        mainServiceName: get(mainPeriodCartItem.value, 'service.name', '-'),
      }
    })

    const onAddPeriod = () => {
      const item = addAppointmentCartItem({
        serviceType: mainPeriodCartItem.value.serviceType,
        peopleCount: mainPeriodCartItem.value.peopleCount,
        service: mainPeriodCartItem.value.service,
        serviceUnit: mainPeriodCartItem.value.serviceUnit,
        subService: mainPeriodCartItem.value.subService,
        attachedServices: mainPeriodCartItem.value.attachedServices,
        resources: mainPeriodCartItem.value.resources,
      })

      set(dataPlaceholder, item.id, {
        availableDate: [],
        availableTimes: [],
      })
    }
    const onRemovePeriod = (cartItem) => {
      if (cartItem) removeAppointmentCartItem(cartItem.nanoId)
    }

    provide('dataPlaceholder', dataPlaceholder)

    onMounted(async () => {
      await nextTick()
      set(dataPlaceholder, mainPeriodCartItem.value.nanoId, {
        availableDate: [],
        availableTimes: [],
      })
    })

    onBeforeUnmount(() => {
      context.periodSettingFormRefs = []
    })

    return {
      cart,
      onAddPeriod,
      maxPeriodCount,
      remainingPeriodCount,
      onRemovePeriod,
      displayData,
      dataPlaceholder,
      disabledControl,
      allCartItemReady,
    }
  },
})
</script>

<style lang="postcss" scoped>
.periods-list {
  @apply flex flex-col;
}

.remove-period-btn {
  @apply cursor-pointer;
}

.section-title {
  @apply font-bold text-primary-100 text-[18px] mb-[24px];
}

::v-deep(.el-form-item__content) {
  @apply w-full;
}
</style>
