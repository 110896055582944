<template>
  <div class="reservation-parameter">
    <PageTitle
      icon="chevron_left"
      :title="$t('parameterSetting.reservationParameterSetting.title')"
      hideBtn
      style="padding-bottom: 24px"
      @iconClick="$router.push({ name: 'Parameters' })"
    />
    <ReservationClientLink
      v-if="useLiff && !isJapanShop"
      data-permit="'admin.lineLiff.page'"
      data-testid="reservation-client-link-section"
    />

    <!-- ANCHOR 時段設定 -->
    <ReservationBasicSettings
      v-loading="loading"
      data-testid="reservation-basic-settings-section"
      :configData="configData.reservation"
      @refresh="getConfig('reservation')"
    />

    <!-- ANCHOR 不指定人員設定 -->
    <ReservationNotspecifySettings
      v-loading="loading"
      data-testid="reservation-notspecify-settings-section"
      :configData="configData.reservation"
      @refresh="getConfig('reservation')"
    />

    <AttachServiceSettings
      v-loading="loading"
      data-testid="reservation-attachService-settings-section"
      :configData="configData.reservation"
      @refresh="getConfig('reservation')"
    />

    <!-- ANCHOR 進階設定 -->
    <ReservationAdvanceSettings
      v-if="useAdvance && !isJapanShop"
      v-loading="loading"
      data-testid="reservation-advance-settings-section"
      :configData="configData.reservation"
      @refresh="getConfig('reservation')"
    />

    <ReservationPaymentSettings
      v-if="!isJapanShop"
      v-loading="loading"
      data-testid="reservation-payment-settings-section"
      :configData="configData"
    />

    <NoticeSettings
      v-if="!isJapanShop"
      v-loading="loading"
      data-testid="reservation-notice-settings-section"
      :configData="configData.reservation"
    />

    <OrderConfirmNoticeSettings
      v-if="!isJapanShop"
      v-loading="loading"
      data-testid="reservation-order-confirm-notice-settings-section"
      :configData="configData.reservation"
    />

    <!-- ANCHOR 預約收件地點 -->
    <ReservationAddressSettings
      v-loading="loading"
      data-testid="reservation-address-settings-section"
      :configData="configData.reservation"
      @refresh="getConfig('reservation')"
    />

    <!-- ANCHOR Theme -->
    <ReservationCustomTextSettings
      v-if="hasThemePermission"
      v-loading="loading"
      data-testid="reservation-custom-text-settings-section"
      :configData="configData.reservation"
      :isJapanShop="isJapanShop"
      @refresh="getConfig('reservation')"
    />
  </div>
</template>

<script>
import PageTitle from '@/components/Title/PageTitle'
import ReservationClientLink from './components/ReservationClientLink.vue'
import ReservationAdvanceSettings from './components/ReservationAdvanceSettings/ReservationAdvanceSettings.vue'
import NoticeSettings from './components/NoticeSettings.vue'
import OrderConfirmNoticeSettings from './components/OrderConfirmNoticeSettings.vue'
import ReservationAddressSettings from './components/ReservationAddressSettings.vue'
import AttachServiceSettings from './components/AttachServiceSettings.vue'
import ReservationNotspecifySettings from './components/ReservationNotspecifySettings.vue'
// import ReservationNotifySettings from './components/ReservationNotifySettings/ReservationNotifySettings.vue'
import ReservationPaymentSettings from './components/ReservationPaymentSettings/ReservationPaymentSettings.vue'
import ReservationBasicSettings from './components/ReservationBasicSettings.vue'
import ReservationCustomTextSettings from './components/ReservationCustomTextSettings.vue'
import { mapGetters } from 'vuex'
import { useReservation } from '@/use/useReservation'
import { onMounted, ref, defineComponent, computed } from 'vue'
import { usePermissions } from '@/use/permissions'
import store from '@/store'
import { get } from 'lodash'

export default defineComponent({
  name: 'ReservationParameter',
  components: {
    PageTitle,
    ReservationClientLink,
    ReservationBasicSettings,
    ReservationAdvanceSettings,
    NoticeSettings,
    OrderConfirmNoticeSettings,
    ReservationAddressSettings,
    AttachServiceSettings,
    // ReservationNotifySettings,
    ReservationPaymentSettings,
    ReservationNotspecifySettings,
    ReservationCustomTextSettings,
  },
  setup (props) {
    const { configData, getAllConfig, getConfig } = useReservation()
    const { checkAction } = usePermissions()
    const isJapanShop = computed(() => get(store.state, 'org.isJapanShop'))
    const loading = ref(false)
    const refresh = async () => {
      try {
        loading.value = true
        await getAllConfig()
      } catch (error) {
        console.log(error)
      } finally {
        loading.value = false
      }
    }

    onMounted(async () => {
      refresh()
    })
    return { configData, getAllConfig, refresh, loading, checkAction, getConfig, isJapanShop }
  },

  computed: {
    ...mapGetters(['userFeatures', 'userPlanFeature']),

    hasThemePermission () {
      return this.checkAction('admin.appointmentConfig.updatePageSetting')
    },
    useAdvance () {
      return this.checkAction('admin.appointmentConfig.editAdvancedFeatures')
    },
    useDeposit () {
      return this.checkAction('adminView.appointmentDeposit.enable')
    },
    useLiff () {
      return this.checkAction('admin.lineLiff.page')
    },
  },
})
</script>

<style scoped lang="scss">
span {
  font-weight: 400;
  color: #5e5e5e;
}

.sub-title {
  margin-bottom: 10px;
  font-size: 24px;
  font-weight: 500;

  .title-hint {
    @apply mr-[5px] text-primary-100;
  }
}

// .settings-section {
//   margin-bottom: 42.5px;
// }

.settings-item {
  margin-bottom: 16px;
}
</style>
