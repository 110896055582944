<template>
  <BaseDialog title="票券折抵" @close="$emit('close')" @confirm="onConfirm">
    <div style="margin-bottom: 40px">
      <BaseElForm ref="formRef" :model="formData">
        <BaseElFormItem label="使用票券">
          <BaseElSelect
            v-model="formData.selected"
            multiple
            collapse-tags
            value-key="id"
          >
            <BaseElSelectOption
              v-for="item in displayOptions"
              :key="item.id"
              :label="item.label"
              :value="item"
            />
          </BaseElSelect>
        </BaseElFormItem>
      </BaseElForm>

      <div class="flex justify-between items-center font-medium text-danger">
        <span>折扣金額</span>
        <span>-$ {{ totalSelectedDiscountPrice }}</span>
      </div>
    </div>
  </BaseDialog>
</template>

<script>
import { computed, defineComponent, nextTick, onMounted } from 'vue'
import BaseDialog from './Dialog/BaseDialog.vue'
import { useBaseForm } from '@/use/useForm'
import { getDiscountDetail, SalesPOSDiscountItem, useSalesPOSCreate } from '@/use/useSalesPOS'
import { map, get, filter, find, reduce, includes } from 'lodash'
import { formatDate } from '@/utils/date'

export default defineComponent({
  name: 'SalesPOSCouponDiscountSelectModal',
  components: {
    BaseDialog,
  },
  props: {
    scope: {
      type: String,
      required: true,
    },
  },
  setup (props, { emit }) {
    const {
      context,
      configData,
      sortedMenus,
      getActiveCartItem,
      getAllOrderDiscountsDetail,
      computedUsedResource,
    } = useSalesPOSCreate()
    const { formData, formRef, initFormData, loading, checkForm } = useBaseForm()
    initFormData({
      selected: [],
    })

    const displayOptions = computed(() => {
      // 票券銷售優惠列表
      const couponDiscountList = filter(get(sortedMenus.value, 'discount'), { mustSpendSetting: { type: 'coupon' } })

      const memberRemainingCouponRecord = filter(
        configData.consumerData.couponRecords,
        (item) => !includes(computedUsedResource.value.coupon, item.id) && item.status === 'open',
      )

      console.log('memberRemainingCouponRecord', memberRemainingCouponRecord)

      // 過濾會員擁有的票券 => 可以使用的票券銷售優惠
      const availableCouponDiscountList = filter(couponDiscountList, (item) => {
        const couponId = get(item, 'mustSpendSetting.couponId')
        const exist = find(memberRemainingCouponRecord, { CouponId: couponId })
        return Boolean(exist)
      })

      return map(availableCouponDiscountList, (item) => {
        const couponId = get(item, 'mustSpendSetting.couponId')
        const couponRecord = find(memberRemainingCouponRecord, { CouponId: couponId })
        const expAt = get(couponRecord, 'expAt')
        let label = get(item, 'name')
        if (expAt) {
          label += ` (期限: ${formatDate(expAt, 'YYYY/MM/DD')})`
        }
        return {
          ...item,
          label,
          couponRecord,
        }
      })
    })

    const totalSelectedDiscountPrice = computed(() => {
      let detail

      const discountInstanceList = map(formData.selected, (item) => {
        return new SalesPOSDiscountItem({
          name: item.name,
          percentAmount: item.percentAmount,
          cashAmount: item.cashAmount,
          type: item.type,
        })
      })

      if (props.scope === 'singleDiscount') {
        const activeCartItem = getActiveCartItem()
        if (!activeCartItem) return 0
        detail = getDiscountDetail({
          totalPrice: activeCartItem.getDiscountRemainingPrice(),
          discounts: discountInstanceList,
        })
      } else if (props.scope === 'orderDiscount') {
        detail = getAllOrderDiscountsDetail({ discounts: discountInstanceList })
      }

      return reduce(detail, (sum, item) => {
        return sum + item.savePrice
      }, 0)
    })

    const onSelect = (data) => {
      formData.selected = data
    }

    const onConfirm = async () => {
      const pass = await checkForm(formRef.value)
      if (!pass) return

      emit('confirm', formData.selected)
      emit('close')
    }

    const syncFormData = () => {
      if (props.scope === 'singleDiscount') {
        const activeCartItem = getActiveCartItem()
        if (!activeCartItem) return
        const discounts = get(activeCartItem, 'discounts')
        const selected = filter(displayOptions.value, (item) => {
          return Boolean(find(discounts, { salesDiscountId: item.id }))
        })
        formData.selected = selected
      } else if (props.scope === 'orderDiscount') {
        const discounts = get(context, 'orderDiscount')
        const selected = filter(displayOptions.value, (item) => {
          return Boolean(find(discounts, { salesDiscountId: item.id }))
        })
        formData.selected = selected
      }
    }

    onMounted(async () => {
      await nextTick()
      syncFormData()
    })

    return {
      configData,
      formData,
      formRef,
      loading,
      onSelect,
      onConfirm,
      displayOptions,
      sortedMenus,
      totalSelectedDiscountPrice,
    }
  },
})
</script>

<style lang="postcss" scoped>

</style>
