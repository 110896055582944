import { render, staticRenderFns } from "./SalesRecordDetailDrawer.vue?vue&type=template&id=1bf31004&scoped=true"
import script from "./SalesRecordDetailDrawer.vue?vue&type=script&lang=js"
export * from "./SalesRecordDetailDrawer.vue?vue&type=script&lang=js"
import style0 from "./SalesRecordDetailDrawer.vue?vue&type=style&index=0&id=1bf31004&prod&lang=postcss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1bf31004",
  null
  
)

export default component.exports