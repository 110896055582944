import { reactive, computed, set } from 'vue'
import { ExportExcel, ExportMoreSheetExcel } from '@/utils/excel'
import { useShop } from '@/use/shop'
import { reduce, get } from 'lodash'

export const useExport = () => {
  const { shopId } = useShop()
  const exportState = reactive({
    content: '匯出資料中',
    stage: 0,
    modal: false,
    exportting: false,
    error: false,
    exportData: [],
    dataCount: [],
    percentage: 0,
    success: false,
  })
  const resetExport = () => {
    exportState.content = ''
    exportState.exportting = false
    exportState.error = false
    exportState.exportData = []
    exportState.dataCount = []
    exportState.percentage = 0
    exportState.modal = false
    exportState.success = false
  }
  const totalDataCount = computed(() => reduce(exportState.dataCount, (sum, n) => {
    return sum + n
  }, 0))
  const curTotalDataCount = computed(() => reduce(exportState.exportData, (sum, dataList) => {
    return sum + dataList.length
  }, 0))
  const totalPercentege = computed(() => {
    if (exportState.success) return 100
    return Math.floor((curTotalDataCount.value / totalDataCount.value) * 100) || 0
  })

  const getExportData = async ({ stage, fetchAPI, payload, limit, throwErr }) => {
    let start = 0
    set(exportState.exportData, stage, [])
    const _limit = limit || 1000

    while (true) {
      const [res, err] = await fetchAPI({
        ...payload,
        start,
        limit: _limit,
      })
      if (err) {
        if (throwErr) throw err
        window.$message.error(err)
        return
      }

      start += _limit
      exportState.exportData[stage].push(...res)
      if (res.length < _limit) {
        break
      }
    }

    return exportState.exportData[stage]
  }

  const getExportDataOld = async ({ stage, fetchAPI, payload, limit, throwErr }) => {
    let start = 0
    set(exportState.exportData, stage, [])
    const _limit = limit || 100

    try {
      while (true) {
        const res = await fetchAPI({
          ...payload,
          start,
          limit: _limit,
        })
        start += _limit
        exportState.exportData[stage].push(...res)
        if (res.length < _limit) {
          break
        }
      }
    } catch (err) {
      if (err) {
        if (throwErr) throw err
        window.$message.error(err)
        return
      }
    }

    return exportState.exportData[stage]
  }

  return {
    exportState,
    resetExport,
    ExportExcel,
    ExportMoreSheetExcel,
    getExportData,
    getExportDataOld,
    totalDataCount,
    totalPercentege,
    curTotalDataCount,
  }
}
