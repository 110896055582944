<template>
  <BaseDialog title="出帳明細" width="480px" hideConfirm hideCancel @close="$emit('close')">
    <div>
      <div>
        <SalesPOSCheckoutReceipt style="margin-bottom: 12px" />

        <div class="flex flex-col" style="gap: 12px; padding: 0px 8px;">
          <div class="flex flex-col" style="gap: 12px">
            <div v-for="item in displayPaymentData" :key="item.name" class="row">
              <p>{{ item.name }}</p>
              <p>$ {{ item.price }}</p>
            </div>
          </div>

          <div class="row font-bold text-normal text-primary-100">
            <p>應收現金</p>
            <p>$ {{ displayData.needPaidCashPrice }}</p>
          </div>
        </div>
      </div>

      <div class="flex justify-center items-center" style="margin-top: 12px">
        <BaseElButton type="primary" :loading="loading" @click="onConfirm">確認出帳</BaseElButton>
      </div>
    </div>
  </BaseDialog>
</template>

<script>
import { computed, defineComponent, ref } from 'vue'
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import SalesPOSCheckoutReceipt from './SalesPOSCheckoutReceipt.vue'
import { SalesPosCreateSalesRecord } from '@/api/salesPOS'
import { useShop } from '@/use/shop'
import { useSalesPOSCreate } from '@/use/useSalesPOS'
import { map, get, find } from 'lodash'
import { paymentOptionsConfig } from '@/config/payment'
import { useRouter } from 'vue-router/composables'

export default defineComponent({
  name: 'SalesPOSCheckoutConfirmModal',
  components: {
    BaseDialog,
    SalesPOSCheckoutReceipt,
  },
  setup (props) {
    const { shopId } = useShop()
    const { context, formatOrderData, needPaidCashPrice, reset } = useSalesPOSCreate()
    const router = useRouter()
    const loading = ref(false)

    const displayData = computed(() => {
      return {
        needPaidCashPrice: needPaidCashPrice.value,
      }
    })

    // 顯示的已使用付款資料
    const displayPaymentData = computed(() => {
      const paymentOptionsConfigExtend = {
        ...paymentOptionsConfig,
        paidDeposit: {
          name: '已收訂金',
          value: 'paidDeposit',
        },
        credit: {
          name: '信用卡',
          value: 'credit',
        },
        other: {
          name: '其他',
          value: 'other',
        },
      }

      paymentOptionsConfigExtend.offline.name = '現場付款'
      paymentOptionsConfigExtend.atm.name = '轉帳'

      return map(context.payments, (paymentMethod) => {
        return {
          name: get(find(paymentOptionsConfigExtend, { value: paymentMethod.type }), 'name'),
          price: paymentMethod.price,
        }
      })
    })

    const onConfirm = async () => {
      if (needPaidCashPrice.value < 0) {
        window.$message.warning('訂單金額不得為負數')
        return
      }

      if (loading.value) return
      loading.value = true
      const [, err] = await SalesPosCreateSalesRecord({
        shopId: shopId.value,
        ...formatOrderData.value,
      })
      loading.value = false
      if (err) {
        window.$message.error(err)
        return
      }
      window.$message.success('出帳成功')

      reset()
      router.push({ name: 'SalesPOSEntry', query: { new: true } })
    }
    return { onConfirm, loading, displayData, displayPaymentData }
  },
})
</script>

<style lang="postcss" scoped>
.row {
  @apply flex justify-between items-center;
}
</style>
