defin<template>
  <div class="data-table">
    <BaseTable :data="displayData">
      <EmptyBlock slot="empty" />
      <BaseElTableColumn label="會員名稱" prop="username" align="center" width="120" fixed="left" />
      <BaseElTableColumn label="會員手機" prop="phone" align="center" width="120" fixed="left" />
      <BaseElTableColumn label="票券來源" prop="origin" align="center" width="120" />
      <BaseElTableColumn label="票券編號" prop="code" align="center" />
      <BaseElTableColumn label="票券種類" prop="couponType" align="center" />
      <BaseElTableColumn label="票券名稱" prop="couponName" align="center" />
      <BaseElTableColumn label="兌換碼" prop="exchangeCode" align="center" />
      <BaseElTableColumn label="票券使用狀態" prop="status" align="center">
        <template slot-scope="scope">
          <Tag :type="get(scope.row, 'status.tagType')">{{ get(scope.row, 'status.label') }}</Tag>
        </template>
      </BaseElTableColumn>
      <BaseElTableColumn label="票券發送日期" prop="giveAt" align="center" width="120" />
      <BaseElTableColumn label="票券使用日期" prop="usedAt" align="center" width="120" />
      <BaseElTableColumn label="票券到期日期" prop="expAt" align="center" width="120" />
      <BaseElTableColumn label="操作" fixed="right" width="110" align="center">
        <template slot-scope="scope">
          <TableEditBtnGroup
            hideDelete
            editBtn="核銷"
            :editDisabled="disableUseCoupon(scope.row.rawStatus)"
            :hideEdit="hideUseCoupon(scope.row.type)"
            @edit=";(selectRow = scope.row), (modal.useCoupon = true)"
          />
        </template>
      </BaseElTableColumn>
    </BaseTable>

    <WarningDialog
      v-if="modal.useCoupon"
      content="核銷後會員將無法再使用此使用券，確認核銷？"
      @close="modal.useCoupon = false"
      @confirm="useCoupon"
    />
  </div>
</template>

<script>
import { defineComponent, computed, ref, reactive } from 'vue'
import EmptyBlock from '@/components/EmptyBlock.vue'
import WarningDialog from '@/components/Dialog/WarningDialog.vue'
import { useTable } from '@/use/table'
import { couponTypeConfig, couponStatusConfig, couponOriginTypeConfig } from '@/config/coupon'
import { get, map } from 'lodash'
import { formatDate } from '@/utils/date'
import { UseCoupon } from '@/api/lottery/couponRecord'
import Tag from '@/components/Tag/Tag.vue'

export default defineComponent({
  name: 'CouponUsageRecordTable',
  components: { EmptyBlock, WarningDialog, Tag },
  props: {
    tableData: { type: Array, defalt: () => [] },
  },
  emits: ['refresh'],
  setup (props, { emit }) {
    const { shopId } = useTable()
    const selectRow = ref(null)
    const modal = reactive({
      useCoupon: false,
    })

    const displayData = computed(() => {
      const data = props.tableData
      // handle data here
      return map(data, (item) => {
        const usedAtDate = get(item, 'usedAt')
        const expAtDate = get(item, 'expAt')
        const giveAtDate = get(item, 'createdAt')
        const originType = get(item, 'originType')
        return {
          id: get(item, 'id'),
          username: get(item, 'Member.UserInfo.name') || '-',
          phone: get(item, 'Member.UserInfo.phone') || '-',
          type: get(item, 'type'),
          couponType: get(couponTypeConfig, `${item.type}.label`),
          code: get(item, 'code'),
          exchangeCode: get(item, 'exchangeCode') || '-',
          status: get(couponStatusConfig, item.status),
          couponName: get(item, 'Coupon.name'),
          usedAt: usedAtDate ? formatDate(usedAtDate) : '-',
          expAt: expAtDate ? formatDate(expAtDate) : '-',
          giveAt: giveAtDate ? formatDate(giveAtDate) : '-',
          origin: get(couponOriginTypeConfig, `${originType}.label`) || '-',
          rawStatus: item.status,
        }
      })
    })

    const onRowDelete = (row) => {
      selectRow.value = row
      modal.delete = true
    }

    const useCoupon = async () => {
      const coupon = selectRow.value
      const [, err] = await UseCoupon({
        shopId: shopId.value,
        id: get(coupon, 'id'),
      })
      if (err) return window.$message.error(err)
      modal.useCoupon = false
      emit('refresh')
    }

    const disableUseCoupon = (status) => {
      return status === 'complete' || status === 'overdue'
    }
    const hideUseCoupon = (type) => {
      return type === 'pospal'
    }

    return {
      get,
      modal,
      selectRow,
      displayData,
      onRowDelete,
      disableUseCoupon,
      useCoupon,
      hideUseCoupon,
    }
  },
})
</script>
