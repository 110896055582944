<template>
  <el-drawer class="reservation-order-history" size="600px" :title="$t('batchOrderPaymentDrawer.title')" :visible="show" @close="onClose">
    <div class="content-wrapper">
      <BaseTable :data="displayData.tableData">
        <BaseElTableColumn prop="code" :label="$t('batchOrderPaymentDrawer.code.title')" align="center" />
        <BaseElTableColumn prop="status" :label="$t('batchOrderPaymentDrawer.status.title')" align="center">
          <template #default="{ row }">
            <Tag :type="row.status.tagType" disableTransitions>{{ row.status.label }}</Tag>
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn prop="totalPrice" :label="displayData.totalPriceTitle" align="center" />
      </BaseTable>

      <hr>

      <div class="flex justify-between items-center py-[12px]">
        <p>{{ displayData.checkoutTitle }}</p>
        <p>$ {{ displayData.checkoutPrice }}</p>
      </div>
    </div>
  </el-drawer>
</template>

<script>
import { defineComponent, ref, computed, onBeforeMount } from 'vue'
import { get, toNumber, map } from 'lodash'
import { FindAppointmentBatchOrder } from '@/api/reservation'
import { useShop } from '@/use/shop'
import { orderStatusConfig } from '@/config/reservation'
import { i18n } from '@/plugins/i18n/i18n'

export default defineComponent({
  name: 'BatchOrderPaymentDrawer',
  props: {
    batchOrder: { type: Object, default: () => ({}) },
    chargeType: { type: String, default: 'checkout' },
  },
  setup (props, { emit }) {
    const { shopId } = useShop()
    const show = ref(true)
    const onClose = () => {
      show.value = false
      setTimeout(() => {
        emit('close')
      }, 300)
    }
    const data = ref([])
    const displayData = computed(() => {
      let checkoutTitle
      let totalPriceTitle
      const details = get(props.batchOrder, 'details')

      if (props.chargeType === 'checkout') {
        checkoutTitle = i18n.t('batchOrderPaymentDrawer.checkout.checkoutTitle.title')
        totalPriceTitle = i18n.t('batchOrderPaymentDrawer.checkout.totalPriceTitle.title')
      } else if (props.chargeType === 'deposit') {
        checkoutTitle = i18n.t('batchOrderPaymentDrawer.deposit.checkoutTitle.title')
        totalPriceTitle = i18n.t('batchOrderPaymentDrawer.deposit.totalPriceTitle.title')
      }

      return {
        tableData: map(get(data.value, 'AppointmentOrders'), (item, idx) => {
          const status = get(item, 'status')
          return {
            code: get(item, 'code'),
            status: {
              label: get(orderStatusConfig, `[${status}].name`),
              tagType: get(orderStatusConfig, `[${status}].tag`),
            },
            totalPrice: get(details, `[${idx}].price`).toLocaleString(),
          }
        }),
        checkoutPrice: toNumber(get(props.batchOrder, 'price')).toLocaleString(),
        checkoutTitle,
        totalPriceTitle,
      }
    })

    onBeforeMount(async () => {
      const [res, err] = await FindAppointmentBatchOrder({
        shopId: shopId.value,
        id: get(props.batchOrder, 'id'),
      })
      if (err) {
        window.$message.error(err)
        return
      }

      console.log(res)
      data.value = res
    })

    return { displayData, show, onClose }
  },
})

</script>

<style lang="postcss" scoped>
.content-wrapper {
  @apply px-[24px] pb-[100px];
}

</style>
