<template>
  <div class="lauout">
    <Headbar v-show="showControl.headbar" />
    <div v-loading="!loaded" class="main-view">
      <Sidebar
        v-if="loaded && showControl.sidebar"
        :class="{ openSideBar: sidebar }"
      />
      <div v-if="loaded" id="viewContainer" ref="viewContainer" class="overflow-x-hidden overflow-y-auto border-none w-full">
        <keep-alive :include="keepAlive">
          <router-view />
        </keep-alive>
      </div>
    </div>
  </div>
</template>

<script>
import { Sidebar, Headbar } from '@/layout/components'
import { get } from 'lodash'
import { formatDate, addDays } from '@/utils/date'
import { defineComponent, ref, computed, onMounted, watch } from 'vue'
import store from '@/store'
import { useRoute, useRouter } from 'vue-router/composables'
import { usePageTabs } from '@/use/usePageTabs'

export default defineComponent({
  name: 'LayoutFullpage',
  components: {
    Headbar,
    Sidebar,
  },
  setup () {
    const route = useRoute()
    const router = useRouter()
    const loaded = ref(false)
    const sidebar = computed(() => get(store.getters, 'sidebar'))
    const isMobile = computed(() => get(store.state, 'app.isMobile'))
    const keepAlive = computed(() => get(store.state, 'app.keepAlive'))
    const shopConfig = computed(() => get(store.state, 'shop.shopConfig'))
    const sidebarPage = computed(() => get(store.state, 'app.sidebarPage'))
    const expiredDate = computed(() => {
      const endDate = get(shopConfig.value, 'ContractPlan.Contract.end')
      return endDate ? formatDate(endDate, 'YYYY/MM/DD') : '-'
    })

    const showControl = computed(() => {
      const controls = {
        headbar: true,
        sidebar: true,
      }

      if (route.name === 'SalesPOS') {
        controls.headbar = false
        controls.sidebar = false
      }

      return controls
    })

    const showPageTabs = computed(() => {
      if (['SalesRecordCreateIdentity', 'SalesRecordCreate', 'SalesRecordPayment', 'SalesPOS'].includes(route.name)) return false
      return true
    })
    watch(route, () => {
      if (isMobile.value) store.dispatch('app/setSidebar', false)
      setTimeout(() => {
        const elem = document.getElementById('viewContainer')
        elem.scrollTo({
          top: 0,
          behavior: 'smooth',
        })
      }, 300)
    })

    router.afterEach((to) => {
      const { addTab } = usePageTabs()
      addTab({
        name: to.name,
        path: to.path,
        params: to.params,
        query: to.query,
        meta: {
          title: to.meta.title,
          keepAlive: to.meta.keepAlive,
        },
      })
    })

    onMounted(() => {
      console.log('🧩layout mounted')
      setTimeout(() => {
        loaded.value = true
      }, 300)
    })
    return {
      loaded,
      sidebar,
      isMobile,
      keepAlive,
      shopConfig,
      sidebarPage,
      expiredDate,
      showPageTabs,
      showControl,
    }
  },
})
</script>

<style scoped lang="scss">
.lauout {
  @apply bg-white flex flex-col h-full overflow-hidden;
}

.main-view {
  @apply flex flex-1 overflow-hidden;
}

@media screen and (min-width: 1200px) {
  .openSideBar {
    @apply w-[260px] flex-shrink-0;
    transition: ease-in-out 0.3s;
  }
}

@media screen and (max-width: 1200px) {
  .openSideBar {
    @apply absolute left-0 top-[80px] w-[260px] bottom-0 flex-shrink-0 z-[500];
    transition: ease-in-out 0.3s;
  }
}

</style>
