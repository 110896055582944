<template>
  <el-dialog
    :title="$t('reservationCustomTextSettings.title')"
    :visible="true"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <BaseElForm ref="formRef" :model="formData" :rules="formRules" label-position="top">
      <BaseElFormItem :label="$t('reservationCustomTextSettings.table.default.title')">
        <BaseElInput :value="getPageTextDefault" :name="'pageTextDefault.' + editTextKey" disabled />
      </BaseElFormItem>
      <BaseElFormItem :label="$t('reservationCustomTextSettings.table.custom.title')" :prop="'pageText.' + editTextKey">
        <BaseElInput
          v-model="formData.pageText[editTextKey]"
          :name="'pageText.' + editTextKey"
          :placeholder="$t('reservationCustomTextSettings.table.custom.placeholder')"
        />
      </BaseElFormItem>
    </BaseElForm>

    <div slot="footer">
      <BaseElButton plain @click="$emit('close')">{{ $t('common.button.cancel.text')}}</BaseElButton>
      <BaseElButton type="primary" @click="onSubmit"> {{ $t('common.button.confirm.text')}} </BaseElButton>
    </div>
  </el-dialog>
</template>

<script>
import { defineComponent, onBeforeMount, computed } from 'vue'
import { useBaseForm } from '@/use/useForm'
import { UpdateReservationParameter } from '@/api/reservation'
import { useShop } from '@/use/shop'
import notifyMessage from '@/config/notifyMessage'
import { pageStyleList, pageTextDefault, pageTextKeys } from '@/config/reservation'
import { get, find, cloneDeep } from 'lodash'
import { lengthRules } from '@/validation'

export default defineComponent({
  name: 'EditReservationThemeModal',
  props: {
    configData: { type: Object, default: () => ({}) },
    editTextKey: { type: String, default: '' },
  },
  setup(props, { emit }) {
    const { formRef, formData, initFormData, loading, checkForm } = useBaseForm()
    const { shopId } = useShop()

    const formRules = computed(() => {
      return {
        ['pageText.' + pageTextKeys.category]: [lengthRules(0, 8)],
        ['pageText.' + pageTextKeys.service]: [lengthRules(0, 8)],
        ['pageText.' + pageTextKeys.unit]: [lengthRules(0, 8)],
        ['pageText.' + pageTextKeys.serviceAttach]: [lengthRules(0, 8)],
      }
    })

    const getPageTextDefault = computed(() => {
      if (!props.editTextKey) return ''
      return get(
        find(pageTextDefault, (item) => item.key === props.editTextKey),
        'default',
      )
    })

    const onSubmit = async () => {
      if (loading.value) return
      loading.value = true
      if (!(await checkForm(formRef.value))) return
      const [, err] = await UpdateReservationParameter({
        shopId: shopId.value,
        pageText: formData.pageText,
      })
      loading.value = false
      if (err) {
        window.$message.error(err)
        return
      }

      window.$message.success(notifyMessage.updateSuccess)
      emit('close')
      emit('refresh')
    }

    onBeforeMount(() => {
      initFormData({
        pageText: cloneDeep(props.configData.pageText),
      })
    })
    return { formRef, formData, formRules, loading, onSubmit, pageStyleList, getPageTextDefault }
  },
})
</script>

<style lang="postcss" scoped></style>
