export default {
  operationSuccess: '操作成功！',
  deleteSuccess: '刪除成功！',
  createSuccess: '新增成功！',
  updateSuccess: '更新成功！',
  copySuccess: '複製成功！',
  voidSuccess: '作廢成功！',
  permissionsDenied: '您的身份權限不足',
  formDataError: '欄位填寫不完整或有誤，請檢查',
}
