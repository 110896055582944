<template>
  <div class="create-reservation-service">
    <div class="card-container wrapper">
      <div class="font-bold text-lg text-primary-100" style="margin-bottom: 16px">{{ $t('createReservationService.title')}}</div>
      <AiHintBlock
        v-if="!serviceId"
        :hintLoading="hintLoading"
        showKeepOriginalContent
        @confirm="hintConfirm"
      />
      <BaseElForm ref="formRef" v-loading="hintLoading" label-position="top" :model="formData" :rules="formRules">
        <BaseElFormItem :label="$t('createReservationService.image.title')" prop="image">
          <template slot="label">
            <FormItemTooltipLabel
              :label="$t('createReservationService.image.title')"
              class="title"
            >
              {{ $t('createReservationService.image.tooltip')}}
            </FormItemTooltipLabel>
          </template>
          <UploadButton
            cyUploadBtn="service-img-upload-btn"
            cyUploadedImg="service-img-uploaded"
            :img="formData.avatar"
            :isAvatar="true"
            @change="loadImg"
          />
        </BaseElFormItem>
        <BaseElFormItem v-if="!isJapanShop" :label="$t('createReservationService.isPublic.title')">
          <BaseElSwitch
            v-model="formData.isPublic"
            testName="formData_isPublic"
            :active-text="$t('common.yes.text')"
            :inactive-text="$t('common.no.text')"
          />
        </BaseElFormItem>
        <BaseElFormItem v-if="usePubApt" :label="$t('createReservationService.isPubApt.title')">
          <BaseElSwitch
            v-model="formData.isPubApt"
            testName="formData_isPubApt"
            :active-text="$t('common.yes.text')"
            :inactive-text="$t('common.no.text')"
          />
        </BaseElFormItem>
        <BaseElFormItem v-if="usePubApt" :label="$t('createReservationService.wildimage.title')" prop="wildimage">
          <template slot="label">
            <FormItemTooltipLabel
              :label="$t('createReservationService.wildimage.title')"
              class="title"
            >
              {{ $t('createReservationService.wildimage.tooltip')}}
            </FormItemTooltipLabel>
          </template>
          <UploadButton
            class="wideImage"
            cyUploadBtn="service-img-upload-btn"
            cyUploadedImg="service-img-uploaded"
            :img="formData.avatarWide"
            :isAvatar="true"
            @change="loadWideImg"
          />
        </BaseElFormItem>
        <BaseElFormItem :label="$t('createReservationService.name.title')" prop="name">
          <BaseElInput v-model="formData.name" testName="formData_name" :maxlength="55" show-word-limit placeholder="名稱" />
        </BaseElFormItem>
        <BaseElFormItem :label="$t('createReservationService.description.title')" prop="description">
          <BaseElInput
            v-model="formData.description"
            testName="formData_description"
            type="textarea"
            :autosize="{ minRows: 3, maxRows: 6}"
            maxlength="500"
            show-word-limit
            :placeholder="$t('createReservationService.description.placeholder')"
          />
        </BaseElFormItem>
        <BaseElFormItem :label="$t('createReservationService.bookingTime.title')" prop="bookingTime">
          <template slot="label">
            <FormItemTooltipLabel
              :label="$t('createReservationService.bookingTime.title')"
              class="title"
            >
              {{ $t('createReservationService.bookingTime.tooltip')}}
            </FormItemTooltipLabel>
          </template>
          <div class="flex flex-col">
            <BaseElInput
              v-model="formData.bookingTime"
              testName="formData_bookingTime"
              :placeholder="$t('createReservationService.bookingTime.placeholder')"
            />
            <BaseElCheckbox
              v-model="formData.hideTime"
              testName="formData_hideTime"
            >
              {{ $t('createReservationService.hideTime.title')}}
            </BaseElCheckbox>
          </div>
        </BaseElFormItem>
        <BaseElFormItem
          v-if="formData.hideTime"
          :label="$t('createReservationService.showTime.title')"
          prop="showTime"
        >
          <template slot="label">
            <FormItemTooltipLabel
              :label="$t('createReservationService.showTime.title')"
              class="title"
            >
              {{ $t('createReservationService.showTime.tooltip')}}
            </FormItemTooltipLabel>
          </template>
          <BaseElInput
            v-model="formData.showTime"
            testName="formData_showTime"
            :placeholder="$t('createReservationService.showTime.placeholder')"
          />
        </BaseElFormItem>
        <BaseElFormItem :label="$t('createReservationService.price.title')" prop="price">
          <template slot="label">
            <FormItemTooltipLabel
              :label="$t('createReservationService.price.title')"
              class="title"
            >
            <template v-if="isJapanShop" v-slot:subTitle>
              <span class="text-gray-80 text-sm">{{ $t('branchSetting.table.currency.title') }}：{{ displayCurrency }}</span>
            </template>
              {{ $t('createReservationService.price.tooltip')}}
            </FormItemTooltipLabel>
          </template>
          <div class="flex flex-col">
            <p v-if="chargeType === 'checkout' && !useFeatures.forceCanHidePrice" class="text-sm leading-[17.38px] mb-[8px]">
              {{ $t('createReservationService.price.rule.title')}}
            </p>
            <BaseElInput
              v-model="formData.price"
              testName="formData_price"
              :placeholder="$t('createReservationService.price.placeholder')"
            />
            <BaseElCheckbox
              v-model="formData.hidePrice"
              testName="formData_hidePrice"
              :disabled="chargeType === 'checkout' && !useFeatures.forceCanHidePrice"
            >
              {{ $t('createReservationService.hidePrice.title')}}
            </BaseElCheckbox>
          </div>
        </BaseElFormItem>
        <BaseElFormItem
          v-if="formData.hidePrice"
          :label="$t('createReservationService.showPrice.title')"
          prop="showPrice"
        >
          <template slot="label">
            <FormItemTooltipLabel
              :label="$t('createReservationService.showPrice.title')"
              class="title"
            >
              {{ $t('createReservationService.showPrice.tooltip')}}
            </FormItemTooltipLabel>
          </template>
          <BaseElInput
            v-model="formData.showPrice"
            testName="formData_showPrice"
            :maxlength="40"
            show-word-limit
            :placeholder="$t('createReservationService.showPrice.placeholder')"
          />
        </BaseElFormItem>
        <BaseElFormItem :label="$t('createReservationService.order.title')" prop="order">
          <template slot="label">
            <FormItemTooltipLabel
              :label="$t('createReservationService.order.title')"
              class="title"
            >
              {{ $t('createReservationService.order.tooltip')}}
            </FormItemTooltipLabel>
          </template>
          <BaseElInput
            v-model="formData.order"
            testName="formData_order"
            clearable
            :placeholder="$t('createReservationService.order.placeholder')"
          />
        </BaseElFormItem>
        <BaseElFormItem :label="$t('createReservationService.serviceCategorys.title')" prop="category">
          <template slot="label">
            <FormItemTooltipLabel
              :label="$t('createReservationService.serviceCategorys.title')"
              class="title"
            >
              {{ $t('createReservationService.serviceCategorys.tooltip')}}
            </FormItemTooltipLabel>
          </template>
          <BaseElSelect
            v-model="formData.serviceCategorys"
            testName="formData_serviceCategorys"
            multiple
            value-key="id"
            collapse-tags
            :placeholder="$t('createReservationService.serviceCategorys.placeholder')"
            :no-data-text="$t('common.table.empty.text')"
          >
            <BaseElSelectOption
              v-for="item in serviceCategoryList"
              :key="item.id"
              :label="item.name"
              :value="item"
            />
          </BaseElSelect>
        </BaseElFormItem>
        <BaseElFormItem v-if="checkResource" :label="$t('createReservationService.type.title')" prop="type">
          <BaseElSelect
            v-model="formData.type"
            testName="formData_type"
            value-key="id"
            :placeholder="$t('common.select.placeholder')"
            :no-data-text="$t('common.table.empty.text')"
            @change="changeType"
          >
            <BaseElSelectOption
              v-for="item in serviceTypeConfigList"
              :key="item.value"
              :label="$t(item.label)"
              :value="item.value"
            />
          </BaseElSelect>
        </BaseElFormItem>
        <BindClassTicketBlock
          :classTickets.sync="formData.classTickets"
          :requiredClassTicket.sync="formData.requiredClassTicket"
          :useBindClassTickets="useBindClassTickets"
          :classTicketList="classTicketList"
        />

        <BaseElFormItem v-if="formData.enableAppointmentUnit" :label="$t('createReservationService.serviceUnits.title')" prop="unit">
          <template slot="label">
            <FormItemTooltipLabel
              :label="$t('createReservationService.serviceUnits.title')"
              class="title"
            >
              {{ $t('createReservationService.serviceUnits.tooltip')}}
            </FormItemTooltipLabel>
          </template>
          <ServiceUnitSelect
            :model.sync="formData.serviceUnits"
            testName="formData_serviceUnits"
            multiple
            :showAll="true"
          />
        </BaseElFormItem>
        <BaseElFormItem v-if="formData.enableResourceItem && checkResource" :label="$t('createReservationService.resource.title')" prop="resource">
          <template slot="label">
            <FormItemTooltipLabel
              :label="$t('createReservationService.resource.title')"
              class="title"
            >
              <ol style="list-style: decimal;padding-left: 16px;">
                <li>
                  {{ $t('createReservationService.resource.tooltip1')}}
                </li>
                <li>
                  {{ $t('createReservationService.resource.tooltip2')}}
                </li>
              </ol>
            </FormItemTooltipLabel>
          </template>
          <ResourceUnitSelect
            :model.sync="formData.resourceItems"
            testName="formData_resourceItems"
            multiple
            :showAll="true"
          />
        </BaseElFormItem>
        <BaseElFormItem :label="$t('createReservationService.attachService.title')">
          <template slot="label">
            <FormItemTooltipLabel
              :label="$t('createReservationService.attachService.title')"
              class="title"
            >
              {{ $t('createReservationService.attachService.tooltip')}}
            </FormItemTooltipLabel>
          </template>
          <AttachServiceSelect
            :model.sync="formData.attachService"
            testName="formData_attachService"
            multiple
            :showAll="true"
          />
        </BaseElFormItem>
        <div v-if="formData.enableResourceItem && formData.enableAppointmentUnit && formData.attachService.length > 0 && checkResource" class="attachServiceBindResource">
          <p class="font-medium text-gray-100 mb-[8px]">{{ $t('createReservationService.attachServiceBindResource.title') }}</p>
          <div v-for="item in formData.attachService" :key="item.id" class="flex items-center mb-[8px]">
            <p class="w-[120px] mr-[12px] text-gray-100">{{ item.name }}</p>
            <BaseElSwitch
              v-model="item.enableResourceItemBooking"
              testName="formData_enableResourceItemBooking"
              :active-text="$t('common.yes.text')"
              :inactive-text="$t('common.no.text')"
            />
          </div>
        </div>

        <div v-if="useSubService">
          <hr style="margin-bottom: 22px">

          <BaseElFormItem :label="$t('createReservationService.enableSubService.title')">
            <BaseElSwitch
              v-model="formData.enableSubService"
              testName="formData_enableSubService"
              :active-text="$t('common.yes.text')"
              :inactive-text="$t('common.no.text')"
            />
          </BaseElFormItem>
          <BaseElFormItem v-if="formData.enableSubService">
            <template slot="label">
              <FormItemTooltipLabel
                :label="$t('createReservationService.enableSubServiceClientHide.title')"
                :tooltipWidth="200"
                class="title"
              >
                {{ $t('createReservationService.enableSubServiceClientHide.tooltip1')}}<br>
                {{ $t('createReservationService.enableSubServiceClientHide.tooltip2')}}
              </FormItemTooltipLabel>
            </template>
            <BaseElSwitch
              v-model="formData.enableSubServiceClientHide"
              testName="formData_enableSubServiceClientHide"
              :active-text="$t('common.yes.text')"
              :inactive-text="$t('common.no.text')"
            />
          </BaseElFormItem>
        </div>
      </BaseElForm>

      <ChildServiceTable
        v-if="formData.enableSubService && useSubService"
        :data="formData.AppointmentSubServices"
        :mainService="formData"
        :configData="configData"
        @update="subServicesUpdate"
      />
    </div>

    <ImageCropper
      v-if="uploadDialog"
      :image="formData.img"
      @uploaded="getImage"
      @close="uploadDialog = false"
    />

    <ImageCropper
      v-if="uploadWideDialog"
      :image="formData.imgWide"
      :ratio="[3,2]"
      @uploaded="getWideImage"
      @close="uploadWideDialog = false"
    />

    <PageFixedFooter
      :confirmLoading="loading"
      @cancel="$router.push({name: 'ServiceSetting'})"
      @confirm="handleConfirm"
    />
  </div>
</template>

<script>
import { computed, onMounted, ref, reactive } from 'vue'
import imageMixin from '@/mixin/image'
import ChildServiceTable from './components/ChildServiceTable.vue'
// import ServiceCategorySelect from '@/components/Select/ServiceCategorySelect.vue'
import AttachServiceSelect from '@/components/Select/AttachServiceSelect.vue'
import ServiceUnitSelect from '@/components/Select/ServicesUnitSelect.vue'
import ResourceUnitSelect from '@/components/Select/ResourceUnitSelect.vue'
import UploadButton from '@/components/Button/UploadButton.vue'
import ImageCropper from '@/components/ImageCropper.vue'
import FormItemTooltipLabel from '@/components/Form/FormItemTooltipLabel.vue'
import BindClassTicketBlock from './components/BindClassTicketBlock.vue'
// api
import { CreateService, UpdateService, FindService } from '@/api/service'
// utils
import { checkUserFeature } from '@/store/modules/permission'
import { mapGetters } from 'vuex'
import { noEmptyRules, isDigitRules, rangeRules } from '@/validation'
import { map, find, omit, get, filter, includes, isEmpty } from 'lodash'
import formUtils from '@/utils/form'

import { useAllData } from '@/use/useAllData'
import { usePermissions } from '@/use/permissions'
import { serviceTypeConfig } from '@/config/reservation'
import AiHintBlock from '@/components/AiHintBlock.vue'
import { useAi } from '@/use/useAi'
import { useRoute } from 'vue-router/composables'
import { useServiceCategory } from '@/use/useServiceCategory'
import { hint } from '@/config/hint'
import { useReservation } from '@/use/useReservation'
import { useShop } from '@/use/shop'
import { i18n } from '@/plugins/i18n/i18n'
import store from '@/store'
import { useCurrency } from '@/use/useCurrency'

export default {
  name: 'CreateReservationService',
  components: {
    UploadButton,
    ImageCropper,
    ChildServiceTable,
    // ServiceCategorySelect,
    AttachServiceSelect,
    ServiceUnitSelect,
    ResourceUnitSelect,
    FormItemTooltipLabel,
    BindClassTicketBlock,
    AiHintBlock,
  },
  mixins: [imageMixin],
  setup (props) {
    const { getAllClassTicket } = useAllData()
    const { shopId } = useShop()
    const { checkActionList, checkAction } = usePermissions()
    const { configData, getConfig } = useReservation()
    const { displayCurrency } = useCurrency()
    const useBindClassTickets = computed(() => checkActionList(['admin.appointmentService.bindClassTicket', 'admin.classTicket.find'], 'union'))
    const usePubApt = computed(() => checkAction('admin.pubAptConfig.page'))
    const { configurationHint, hintLoading, setData } = useAi()
    const route = useRoute()
    const isCopy = computed(() => route.query.copy) // 是否是複製模式
    const classTicketList = ref([])
    const formRef = ref(null)
    const formData = reactive({
      img: null,
      imgWide: null,
      avatar: null,
      avatarWide: null,
      name: '',
      description: '',
      hidePrice: false,
      hideTime: false,
      price: '',
      showPrice: '',
      showTime: '',
      bookingTime: '',
      order: 100,
      isPublic: true,
      isPubApt: false,
      attachService: [],
      serviceCategorys: [],
      serviceUnits: [],
      resourceItems: [],
      AppointmentSubServices: [],
      enableSubService: false,
      enableSubServiceClientHide: false,
      classTickets: [],
      requiredClassTicket: false,
      type: 'humanService',
      enableAppointmentUnit: true,
      enableResourceItem: false,
    })

    const useFeatures = computed(() => {
      return {
        forceCanHidePrice: checkAction('admin.appointmentService.enableShowPrice'),
      }
    })
    const isJapanShop = computed(() => get(store.state, 'org.isJapanShop'))

    const chargeType = computed(() => {
      return get(configData, 'reservation.chargeType')
    })

    const serviceTypeConfigList = computed(() => {
      const omitList = []
      if (!checkAction('admin.resourceItem.page')) omitList.push('resourceService', 'humanAndResourceService')
      return omit(serviceTypeConfig, omitList)
    })
    const checkResource = computed(() => {
      return checkAction('admin.resourceItem.page')
    })
    const show = reactive({
      classTicketSelect: false,
    })
    const changeType = (data) => {
      switch (data) {
      case 'humanService':
        formData.enableAppointmentUnit = true
        formData.enableResourceItem = false
        break
      case 'resourceService':
        formData.enableAppointmentUnit = false
        formData.enableResourceItem = true
        break
      case 'humanAndResourceService':
        formData.enableAppointmentUnit = true
        formData.enableResourceItem = true
        break
      default:
        break
      }
    }
    const serviceId = computed(() => {
      return route.params.serviceId
    })
    const { serviceCategoryList, getAllServiceCategory } = useServiceCategory()
    const hintConfirm = async (payload) => {
      payload.origin = payload.origin ? formData : undefined
      const key = payload.origin ? 'appointmentServiceCorrection.key' : 'appointmentService.key'
      const res = await configurationHint(get(hint, key), payload)
      if (!res) {
        hintLoading.value = false
        return
      }
      setData(formData, res)
      const ids = get(res.hint, 'categoryIds')
      formData.serviceCategorys = filter(serviceCategoryList.value, item => includes(ids, item.id))
    }

    //= > 查找特定服務
    const findService = async () => {
      const [res, err] = await FindService({
        shopId: shopId.value,
        id: serviceId.value,
      })
      if (err) {
        window.$message.error(err)
        return
      }

      syncData(res)
    }

    const syncData = (res) => {
      formData.avatar = res.Image
      formData.avatarWide = res.WideImage
      formData.name = res.name
      formData.description = res.description
      formData.isPublic = res.isPublic
      formData.isPubApt = res.isPubApt
      formData.order = res.order
      formData.price = res.price
      formData.bookingTime = res.bookingTime
      formData.attachService = res.AppointmentServiceAttaches.filter(item => !item.isRemove)
      formData.serviceUnits = res.AppointmentUnits.filter(item => !item.isRemove)
      formData.resourceItems = res.ResourceItems
      formData.serviceCategorys = res.AppointmentCategories.filter(item => !item.isRemove)
      formData.AppointmentSubServices = res.AppointmentSubServices
      formData.classTickets = map(res.ClassTickets, 'id')
      formData.requiredClassTicket = res.requiredClassTicket

      // 如果 chargeType 是 checkout，則不允許隱藏價格
      if (chargeType.value === 'checkout' && !useFeatures.value.forceCanHidePrice) {
        formData.hidePrice = false
        formData.showPrice = res.showPrice
      } else {
        if (res.showPrice && res.showPrice !== '0') {
          formData.hidePrice = true
          formData.showPrice = res.showPrice
        }
      }

      if (res.showTime && res.showTime !== '0') {
        formData.hideTime = true
        formData.showTime = res.showTime
      }
      formData.enableSubService = res.enableSubService
      formData.enableSubServiceClientHide = !res.enableSubServiceClientHide
      formData.enableAppointmentUnit = res.enableAppointmentUnit
      formData.enableResourceItem = res.enableResourceItem
      if (res.enableAppointmentUnit && res.enableResourceItem) {
        formData.type = 'humanAndResourceService'
      } else if (res.enableAppointmentUnit) {
        formData.type = 'humanService'
      } else if (res.enableResourceItem) {
        formData.type = 'resourceService'
      }

      formData.attachService = formData.attachService.map(item => {
        const matchingItem = res.attachServiceSettings.find(mergeItem => mergeItem.appointmentServiceAttachId === item.id)
        if (matchingItem) {
          return {
            ...item,
            appointmentServiceAttachId: matchingItem.appointmentServiceAttachId,
            enableResourceItemBooking: matchingItem.enableResourceItemBooking,
          }
        } else {
          return item
        }
      })
    }

    onMounted(async () => {
      if (useBindClassTickets.value) classTicketList.value = await getAllClassTicket()
      await getAllServiceCategory()
      await getConfig('reservation')
      // if (usePubApt.value) formData.isPubApt = true
      if (serviceId.value) findService()
    })

    return {
      classTicketList,
      useBindClassTickets,
      getAllClassTicket,
      show,
      formData,
      formRef,
      changeType,
      serviceTypeConfigList,
      checkResource,
      hintConfirm,
      hintLoading,
      serviceId,
      serviceCategoryList,
      usePubApt,
      chargeType,
      configData,
      useFeatures,
      isCopy,
      isJapanShop,
      displayCurrency,
    }
  },
  data: () => ({
    imgSize: 90,
    loading: false,
    uploadDialog: false,
    uploadWideDialog: false,
    avatarChanged: false,
    avatarChangedWide: false,
    subServiceList: [],

    formRules: {
      name: noEmptyRules(),
      description: noEmptyRules(),
      price: [isDigitRules(), noEmptyRules(), rangeRules()],
      // showPrice: [isDigitRules(false)],
      showTime: [isDigitRules(false), rangeRules()],
      bookingTime: [noEmptyRules(), isDigitRules(), rangeRules()],
      order: [noEmptyRules(), isDigitRules(), rangeRules()],
      type: [noEmptyRules()],
    },
  }),
  computed: {
    ...mapGetters([
      'shop',
      'userFeatures',
      'userPlanFeature',
    ]),
    useSubService () {
      return checkUserFeature(this.userPlanFeature, this.userFeatures, 'admin.appointmentSubService.edit')
    },
    // serviceId () {
    //   return this.$route.params.serviceId
    // },
  },

  methods: {
    subServicesUpdate (data) {
      console.log('subServicesUpdate', data)
      this.subServiceList = data
    },
    removeUnbindUnit () {
      this.subServiceList.forEach(service => {
        if (service.appointmentUnits) {
          const res = service.appointmentUnits.filter(i => find(get(this.formData, 'serviceUnits'), { id: i }))
          service.appointmentUnits = res
          if (isEmpty(res)) service.allAppointmentUnit = true
        }
      })
    },
    loadImg (img) {
      this.formData.img = img
      this.uploadDialog = true
    },
    loadWideImg (img) {
      this.formData.imgWide = img
      this.uploadWideDialog = true
    },
    getImage (data) {
      this.formData.avatar = data
      this.avatarChanged = true
      this.uploadDialog = false
    },
    getWideImage (data) {
      this.formData.avatarWide = data
      this.avatarChangedWide = true
      this.uploadWideDialog = false
    },
    async handleConfirm () {
      this.loading = true
      if (!await formUtils.checkForm(this.formRef)) return
      if (this.isCopy) await this.createService()
      else if (this.serviceId) await this.updateService()
      else if (!this.serviceId) await this.createService()
      this.loading = false
    },
    //= > 創建服務
    async createService () {
      const form = this.formData
      if (form.enableSubService && form.isPubApt) {
        this.$message.error(i18n.t('createReservationService.message.enableSubService.text'))
        return
      }
      if (!form.hidePrice) form.showPrice = '0'
      if (!form.hideTime) form.showTime = '0'
      this.removeUnbindUnit()

      let subServiceList = this.subServiceList
      if (this.isCopy) subServiceList = map(subServiceList, item => omit(item, 'id'))

      const [, err] = await CreateService({
        shopId: this.shop,
        name: form.name,
        image: (!form.avatar) ? undefined : this.formData.avatar.id,
        wideImage: (!form.avatarWide) ? undefined : this.formData.avatarWide.id,
        description: form.description,
        price: form.price,
        showPrice: (form.showPrice === '') ? '0' : form.showPrice,
        showTime: (form.showTime === '') ? 0 : form.showTime,
        bookingTime: form.bookingTime,
        order: form.order,
        isPublic: form.isPublic,
        isPubApt: form.isPubApt,
        appointmentCategories: map(this.formData.serviceCategorys, 'id'),
        appointmentServiceAttaches: map(this.formData.attachService, 'id'),
        appointmentUnits: map(this.formData.serviceUnits, 'id'),
        resourceItems: map(this.formData.resourceItems, 'id'),
        enableSubService: this.useSubService ? this.formData.enableSubService : undefined,
        enableSubServiceClientHide: this.useSubService ? !this.formData.enableSubServiceClientHide : undefined,
        appointmentSubServices: this.useSubService ? subServiceList : undefined,
        requiredClassTicket: this.formData.requiredClassTicket,
        classTickets: this.formData.classTickets,
        enableAppointmentUnit: this.formData.enableAppointmentUnit,
        enableResourceItem: this.formData.enableResourceItem,
        attachServiceSettings: this.formData.attachService.map(item => {
          return {
            appointmentServiceAttachId: item.id,
            enableResourceItemBooking: item.enableResourceItemBooking,
          }
        }),
      })
      if (err) {
        this.$message.error(err)
        return
      }

      this.$message.success(i18n.t('createReservationService.message.createServiceSuccess.text'))
      this.$router.push({ name: 'ServiceSetting' })
    },

    //= > 更新服務
    async updateService () {
      console.log('update')
      const form = this.formData
      if (form.enableSubService && form.isPubApt) {
        this.$message.error(i18n.t('createReservationService.message.enableSubService.text'))
        return
      }
      if (!form.hidePrice) form.showPrice = '0'
      if (!form.hideTime) form.showTime = '0'
      this.removeUnbindUnit()
      const [, err] = await UpdateService({
        shopId: this.shop,
        id: this.serviceId,
        name: form.name,
        image: (this.avatarChanged) ? this.formData.avatar.id : undefined,
        wideImage: (this.avatarChangedWide) ? this.formData.avatarWide.id : undefined,
        description: form.description,
        price: form.price,
        showPrice: (form.showPrice === '') ? '0' : form.showPrice,
        showTime: (form.showTime === '') ? 0 : form.showTime,
        bookingTime: form.bookingTime,
        order: form.order,
        isPublic: form.isPublic,
        isPubApt: form.isPubApt,
        appointmentCategories: map(this.formData.serviceCategorys, 'id'),
        appointmentServiceAttaches: map(this.formData.attachService, 'id'),
        appointmentUnits: map(this.formData.serviceUnits, 'id'),
        resourceItems: map(this.formData.resourceItems, 'id'),
        enableSubService: this.useSubService ? this.formData.enableSubService : undefined,
        enableSubServiceClientHide: this.useSubService ? !this.formData.enableSubServiceClientHide : undefined,
        appointmentSubServices: this.useSubService ? this.subServiceList : undefined,
        requiredClassTicket: this.formData.requiredClassTicket,
        classTickets: this.formData.classTickets,
        enableAppointmentUnit: this.formData.enableAppointmentUnit,
        enableResourceItem: this.formData.enableResourceItem,
        attachServiceSettings: this.formData.attachService.map(item => {
          return {
            appointmentServiceAttachId: item.id,
            enableResourceItemBooking: item.enableResourceItemBooking,
          }
        }),
      })
      if (err) {
        this.$message.error(err)
        return
      }
      this.$message.success(i18n.t('createReservationService.message.updateServiceSuccess.text'))
      this.$router.push({ name: 'ServiceSetting' })
    },

  },
}
</script>

<style lang="postcss" scoped>
.wrapper {
  padding: 20px !important;
  padding-left: 29px !important;
}
.attachServiceBindResource {
  @apply rounded-[8px] bg-[#F5F7FA] p-[20px] max-w-[560px] mb-[20px]
}
::v-deep .wideImage .avatar {
  @apply h-[60px]
}
</style>
