<template>
  <BaseElSelect
    v-model="syncModel"
    v-bind="$attrs"
    :multiple="multiple"
    filterable
    remote
    clearable
    reserve-keyword
    :placeholder="$t('reservationList.search.serviceUnit.placeholder')"
    :no-match-text="$t('common.select.noMatch.text')"
    :no-data-text="$t('common.table.empty.text')"
    :remote-method="remoteSearch"
    :loading="loading"
    value-key="id"
    :class="styling"
    @change="$emit('change')"
    @clear="getServiceUnit"
  >
    <i slot="prefix" class="el-input__icon el-icon-search" />
    <BaseElSelectOption
      v-for="item in shopList"
      :key="item.id"
      :label="item.name"
      :value="item"
    />
  </BaseElSelect>
</template>

<script>
import { GetServiceUnit } from '@/api/serviceUnit'
import { mapGetters } from 'vuex'
export default {
  name: 'ServiceUnitSearch',
  props: ['model', 'type', 'multiple', 'styling'],
  computed: {
    ...mapGetters([
      'shop',
    ]),
    syncModel: {
      get () {
        return this.model
      },

      set (data) {
        this.$emit('update:model', data)
      },
    },
  },

  // watch: {
  //   model(){
  //     this.shopList = this.model
  //   }
  // },

  data: () => ({
    loading: false,
    shopList: [],
  }),

  async mounted () {
    await this.getServiceUnit()
  },

  methods: {

    async remoteSearch (query) {
      query = query.trim()
      if (query === '') this.shopList = []

      try {
        this.loading = true
        setTimeout(async () => {
          await this.getServiceUnit(query)
          this.loading = false
        }, 200)
      } catch (error) {
        console.log(error)
      }
    },

    async getServiceUnit (query = null) {
      try {
        const res = await GetServiceUnit({
          shopId: this.shop,
          name: (query) || undefined,
          limit: 50,
        })
        this.shopList = res
      } catch (error) {
        console.log(error)
      }
    },
  },

}
</script>

<style lang="scss">
.full-input {
  grid-column: 1/3;
  width: 100%!important;
}
.full-input .el-input {
  width: 100%!important;
}
</style>
