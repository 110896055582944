<template>
  <BaseDialog
    :title="displayData.paymentName"
    :btn1Disabled="disabledControl.btn.confirm"
    @close="$emit('close')"
    @confirm="onConfirm"
  >
    <div>
      <BaseElForm ref="formRef" :model="formData" :rules="formRules" label-position="top">
        <BaseElFormItem label="使用方式" prop="method">
          <BaseElRadioGroup v-model="formData.method" @change="onMethodChange">
            <BaseElRadio label="all">全額</BaseElRadio>
            <BaseElRadio label="part">部分金額</BaseElRadio>
          </BaseElRadioGroup>
        </BaseElFormItem>

        <BaseElFormItem label="結帳金額" prop="price">
          <BaseElInput v-model="formData.price" type="number" :disabled="disabledControl.price" />
        </BaseElFormItem>

        <BaseElFormItem label="餘額">
          <p class="text-primary-100">{{ displayData.computedRemainingUnpaidPrice }}</p>
        </BaseElFormItem>
      </BaseElForm>
    </div>
  </BaseDialog>
</template>

<script>
import { computed, defineComponent, onBeforeMount } from 'vue'
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import { paymentOptionsConfig } from '@/config/payment'
import { get } from 'lodash'
import { useBaseForm } from '@/use/useForm'
import { isDigitRules, maxNumberRules, noEmptyRules } from '@/validation'
import { useSalesPOSCreate } from '@/use/useSalesPOS'

export default defineComponent({
  name: 'SalesPOSCheckoutPaymentPriceModal',
  components: {
    BaseDialog,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  setup (props, { emit }) {
    const { configData, needPaidCashPrice } = useSalesPOSCreate()
    const { formRef, formData, initFormData, loading, checkForm } = useBaseForm()

    initFormData({
      method: 'all',
      price: undefined,
    })

    const maxPaymentPrice = computed(() => {
      if (props.data.value === 'wallet') {
        const memberWallet = get(configData, 'consumerData.walletBalance', 0)
        if (memberWallet < needPaidCashPrice.value) {
          return memberWallet
        }
      }
      return needPaidCashPrice.value
    })

    const formRules = computed(() => {
      let maxNumMessage = `金額不可超過 ${maxPaymentPrice.value}`
      if (props.data.value === 'wallet') {
        const memberWallet = get(configData, 'consumerData.walletBalance', 0)
        if (formData.price > memberWallet) {
          maxNumMessage = '儲值金餘額不足'
        }
      }
      const rules = {
        price: [noEmptyRules(), isDigitRules(), maxNumberRules(maxPaymentPrice.value, maxNumMessage)],
      }

      return rules
    })

    const displayData = computed(() => {
      const computedRemainingUnpaidPrice = needPaidCashPrice.value - formData.price
      return {
        paymentName: get(
          {
            ...paymentOptionsConfig,
            cash: { name: '現金', value: 'cash', type: 'cash' },
            credit: { name: '信用卡', value: 'credit', type: 'credit' },
          }, `${props.data.value}.name`, '其他'),
        computedRemainingUnpaidPrice,
      }
    })

    const showControl = computed(() => {
      const controls = {
        remainBalance: false,
      }
      return controls
    })

    const disabledControl = computed(() => {
      const controls = {
        price: false,
        btn: {
          confirm: false,
        },
      }

      if (formData.method === 'all') {
        controls.price = true
      }

      if (!formData.price) {
        controls.btn.confirm = true
      }

      return controls
    })

    const onMethodChange = (val) => {
      if (val === 'all') {
        formData.price = maxPaymentPrice.value
      }
    }

    const onConfirm = async () => {
      const pass = await checkForm(formRef.value)
      if (!pass) return
      emit('confirm', {
        ...formData,
        type: props.data.value,
      })
      emit('close')
    }

    onBeforeMount(() => {
      onMethodChange(formData.method)
    })

    return {
      formData,
      formRef,
      formRules,
      displayData,
      loading,
      onConfirm,
      showControl,
      disabledControl,
      onMethodChange,
    }
  },
})
</script>

<style lang="postcss" scoped>

</style>
