import { render, staticRenderFns } from "./TimeRangeStatistic.vue?vue&type=template&id=318c9b10&scoped=true"
import script from "./TimeRangeStatistic.vue?vue&type=script&setup=true&lang=js"
export * from "./TimeRangeStatistic.vue?vue&type=script&setup=true&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "318c9b10",
  null
  
)

export default component.exports