<template>
  <div class="resource-item-settings">
    <div class="font-bold text-lg text-primary-100" style="margin-bottom: 16px">{{ $t('resourceItemSettings.title')}}</div>

    <BaseElForm ref="formRef" label-position="top" :model="formData" :rules="formRules">
      <BaseElFormItem :label="$t('resourceSetting.table.enabled.title')" prop="enabled">
        <template slot="label">
          <FormItemTooltipLabel :label="$t('resourceSetting.table.enabled.title')" :tooltipWidth="200">
            <ul class="tooltip-ul">
              <li>{{ $t('resourceItemSettings.enabled.toolip1')}}</li>
              <li>{{ $t('resourceItemSettings.enabled.toolip2')}}</li>
            </ul>
          </FormItemTooltipLabel>
        </template>
        <BaseElSwitch
          v-model="formData.enabled"
          :active-text="$t('common.open.text')"
          :inactive-text="$t('common.close.text')"
        />
      </BaseElFormItem>
      <BaseElFormItem :label="$t('resourceSetting.table.image.title')" prop="image">
        <UploadButton
          cyUploadBtn="resource-img-upload-btn"
          cyUploadedImg="resource-img-uploaded"
          :img="formData.avatar"
          :isAvatar="true"
          @change="imageChange"
        />
      </BaseElFormItem>
      <BaseElFormItem :label="$t('resourceItemSettings.name.title')" prop="name">
        <BaseElInput v-model="formData.name" :maxlength="55" show-word-limit data-cy="name-input" :placeholder="$t('resourceItemSettings.name.placeholder')" />
      </BaseElFormItem>
      <BaseElFormItem :label="$t('resourceSetting.table.bookingMax.title')" prop="bookingMax" :placeholder="$t('resourceItemSettings.bookingMax.placeholder')">
        <BaseElInput v-model.number="formData.bookingMax" type="number" data-cy="booking-max-input" />
      </BaseElFormItem>
      <BaseElFormItem :label="$t('resourceSetting.table.order.title')" prop="order">
        <BaseElInput v-model="formData.order" clearable data-cy="order-input" :placeholder="$t('resourceItemSettings.order.placeholder')" />
      </BaseElFormItem>
      <BaseElFormItem :label="$t('resourceItemSettings.bindServices.title')" prop="appointmentServices">
        <template slot="label">
          <div class="inline-flex items-center" style="gap: 8px">
            <p>{{ $t('resourceItemSettings.bindServices.title')}}</p>
            <el-tooltip placement="right">
              <div slot="content">
                {{ $t('resourceItemSettings.bindServices.tooltip')}}
              </div>
              <span class="material-icons">help_outline</span>
            </el-tooltip>
          </div>
        </template>
        <ServiceSelect data-cy="unit-binding-select" :model.sync="formData.appointmentServices" checkResource multiple :showAll="true" />
      </BaseElFormItem>
    </BaseElForm>
    <ImageCropper
      v-if="modal.cropper"
      :image="formData.image"
      @uploaded="getCroppedImage"
      @close="modal.cropper = false"
    />
  </div>
</template>

<script>
import UploadButton from '@/components/Button/UploadButton.vue'
import ServiceSelect from '@/components/Select/ServicesSelect.vue'
import ImageCropper from '@/components/ImageCropper.vue'
import FormItemTooltipLabel from '@/components/Form/FormItemTooltipLabel.vue'
import { defineComponent, computed, reactive, onMounted, watch, nextTick } from 'vue'
import { get } from 'lodash'
import { mappingSyncFormData, useBaseForm } from '@/use/useForm'
import { noEmptyRules } from '@/validation'
import FormsContext from '../formsContext'

export default defineComponent({
  name: 'ResourceItemSettings',
  components: { UploadButton, ServiceSelect, ImageCropper, FormItemTooltipLabel },
  props: {
    configData: { type: Object, default: () => ({}) },
  },
  setup (props) {
    const { initFormData, formData, formRef } = useBaseForm()
    const modal = reactive({
      cropper: false,
    })

    initFormData({
      enabled: false,
      image: null,
      avatar: null,
      name: '',
      bookingMax: null,
      appointmentServices: [],
      order: 100,
    })

    const formRules = computed(() => {
      const rules = {
        enabled: [noEmptyRules()],
        name: [noEmptyRules()],
        bookingMax: [noEmptyRules()],
        order: [noEmptyRules()],
      }
      return rules
    })

    const syncFormData = (data) => {
      mappingSyncFormData({ Image: 'avatar', AppointmentServices: 'appointmentServices' }, data, formData)
    }

    const compactData = computed(() => {
      const data = {
        enabled: get(formData, 'enabled'),
        image: get(formData, 'avatar.id'),
        // avatar: get(formData, 'avatar'),
        name: get(formData, 'name'),
        bookingMax: get(formData, 'bookingMax'),
        order: get(formData, 'order'),
        appointmentServices: formData.appointmentServices ? formData.appointmentServices.map(item => item.id) : [],
      }
      return data
    })

    watch(compactData, () => {
      FormsContext.setFormData('formRef1', { ...compactData.value })
    })

    const getCroppedImage = (data) => {
      formData.avatar = data
      modal.cropper = false
    }

    const imageChange = (img) => {
      formData.image = img
      modal.cropper = true
    }

    onMounted(async () => {
      await nextTick()
      FormsContext.setFormRef('formRef1', formRef.value)
      if (get(props.configData, 'id')) syncFormData(props.configData)
    })

    return {
      modal,
      formRef,
      formData,
      formRules,
      getCroppedImage,
      imageChange,
    }
  },
})
</script>

<style lang="postcss" scoped>

.sub-title {
  font-size: 12px;
  color: var(--gray-80);
  font-weight: 500;
  margin-bottom: 32px;
}
.tooltip-ul {
  list-style: disc;
  padding-left: 16px;
}
</style>
