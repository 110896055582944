<template>
  <BaseDialog
    width="610px"
    :title="dialogTitle"
    :btn1Loading="loading"
    @close="$emit('close')"
    @confirm="onConfirm"
  >
    <div>
      <BaseElForm ref="formRef" :model="formData" :rules="formRules" label-position="top">
        <BaseElFormItem prop="name">
          <template #label>
            <FormItemTooltipLabel :tooltipWidth="280" label="顯示名稱">
              您可以自行設定點數在客人端顯示的文字，至多 5
              個字。例如：紅利點數、Point 等。
            </FormItemTooltipLabel>
          </template>
          <BaseElInput v-model="formData.name" />
        </BaseElFormItem>
        <BaseElFormItem prop="unitName">
          <template #label>
            <FormItemTooltipLabel :tooltipWidth="318" label="顯示單位">
              您可以自行設定點數在客人端顯示的文字，至多 3個字。
              例如：點、dot 等
            </FormItemTooltipLabel>
          </template>
          <BaseElInput v-model="formData.unitName" />
        </BaseElFormItem>
        <BaseElFormItem label="年度錢包設定" prop="isAnnual">
          <BaseElRadioGroup v-model="formData.isAnnual" :disabled="isEdit">
            <BaseElRadio :label="true">
              <div class="inline-flex items-center" style="gap: 8px">
                年度錢包
                <TipInfo>
                  <p>當年累積點數到隔年還可以使用。</p>
                  <p>當年累積的點數期限，最長期限會至隔年的年度到期日。</p>
                </TipInfo>
              </div>
            </BaseElRadio>
            <BaseElRadio :label="false">
              <div class="inline-flex items-center" style="gap: 8px">
                非年度錢包
                <TipInfo>
                  <p>非年度錢包：依照固定到期日或者有限天數為期限。</p>
                  <p>假設固定到期日為6/30，2024/7/1 之後累積的點數效期都到 2025/6/30。</p>
                  <p>有效天數為30天，6/1新增點數後，到期日為</p>
                  <p>7/1，一但有點數新增，會重新計算點數到期日。</p>
                </TipInfo>
              </div>
            </BaseElRadio>
          </BaseElRadioGroup>
        </BaseElFormItem>

        <BaseElFormItem v-if="formData.isAnnual" prop="validDate">
          <template #label>
            <FormItemTooltipLabel :tooltipWidth="380" label="有效期限設定">
              當您更改有效期限時、年度固定到期日時，不會影響顧客已擁有的點
              數日期，僅會影響未來發生的操作或交易。
            </FormItemTooltipLabel>
          </template>
        </BaseElFormItem>

        <BaseElFormItem v-if="!formData.isAnnual" label="有效期限設定" prop="expireMode">
          <template #label>
            <FormItemTooltipLabel :tooltipWidth="380" label="有效期限設定">
              當您更改有效期限時、年度固定到期日時，不會影響顧客已擁有的點
              數日期，僅會影響未來發生的操作或交易。
            </FormItemTooltipLabel>
          </template>
          <BaseElRadioGroup
            v-model="formData.expireMode"
            class="flex"
            style="gap: 8px"
            :disabled="!useFeatures.editWalletExp"
            @change="onExpireModeChange"
          >
            <BaseElRadio label="fixedDate">
              <div class="inline-flex items-center" style="gap: 8px">
                固定到期日
                <TipInfo>
                  <div>
                    <p>固定到期日之前累積的點數過了當年度的固定到期日後即失效。</p>
                    <p>假設現在是2024/3/1，6/30到期，2024/3/1~2024/6/30</p>
                    <p>累積的點數都是2024/6/30 到期。</p>
                    <p>2024/7/1 之後累積的點數會在2025/6/30到期</p>
                  </div>
                </TipInfo>
              </div>
            </BaseElRadio>
            <BaseElRadio label="dayCount">
              <div class="inline-flex items-center" style="gap: 8px">
                點數有效天數
                <TipInfo>
                  假設為 30 天，6/1 新增點數後，到期日為 7/1，一但有點數新增，會重新計算點數到期日。
                </TipInfo>
              </div>
            </BaseElRadio>
          </BaseElRadioGroup>
        </BaseElFormItem>

        <!-- 年度錢包設定 -->
        <BaseElFormItem v-if="showBlock.fixedDate && formData.isAnnual" prop="expireSettings.fixedDate">
          <template #label>
            <FormItemTooltipLabel :tooltipWidth="356" label="年度到期日">
              <div>
                <p>年度到期日：當年度獲得的點數，在隔年的最大到期日。</p>
                <p>例如有效期限 365 天，年度到期日為12/31，2024/12/31 獲得的點數</p>
                <p>到期日為隔年 2025/12/31。有效期限 90 天，年度到期日為12/31，</p>
                <p>2024/12/31 獲得的點數到期日為隔年 2025/3/31。</p>
              </div>
            </FormItemTooltipLabel>
          </template>
          <div class="inline-flex" style="gap: 8px">
            <BaseElFormItem class="w-full" prop="month" label="">
              <ElInputWrapper>
                <BaseElSelect v-model="formData.month" placeholder="選擇月份" :disabled="!useFeatures.editWalletExp" @change="formData.date= null">
                  <BaseElSelectOption
                    v-for="month in monthOptions"
                    :key="month.label"
                    :label="$t(month.label)"
                    :value="month.value"
                    :disabled="disabledMonth(month)"
                  />
                </BaseElSelect>
              </ElInputWrapper>
            </BaseElFormItem>

            <BaseElFormItem class="w-full" prop="date" label="">
              <ElInputWrapper>
                <BaseElSelect v-model="formData.date" placeholder="選擇日期" :disabled="!formData.month || !useFeatures.editWalletExp">
                  <BaseElSelectOption
                    v-for="item in monthDateOptions"
                    :key="item"
                    :label="`${item}日`"
                    :value="item"
                  />
                </BaseElSelect>
              </ElInputWrapper>
            </BaseElFormItem>
          </div>
        </BaseElFormItem>

        <!-- 非年度錢包設定 -->
        <BaseElFormItem
          v-if="showBlock.fixedDate && !formData.isAnnual"
          label="固定到期日"
        >
          <div class="inline-flex" style="gap: 8px">
            <BaseElFormItem class="w-full" prop="month" label="">
              <ElInputWrapper>
                <BaseElSelect v-model="formData.month" placeholder="選擇月份" :disabled="!useFeatures.editWalletExp" @change="formData.date= null">
                  <BaseElSelectOption
                    v-for="month in monthOptions"
                    :key="month.label"
                    :label="$t(month.label)"
                    :value="month.value"
                    :disabled="disabledMonth(month)"
                  />
                </BaseElSelect>
              </ElInputWrapper>
            </BaseElFormItem>

            <BaseElFormItem class="w-full" prop="date" label="">
              <ElInputWrapper>
                <BaseElSelect v-model="formData.date" placeholder="選擇日期" :disabled="!formData.month || !useFeatures.editWalletExp">
                  <BaseElSelectOption
                    v-for="item in monthDateOptions"
                    :key="item"
                    :label="`${item}日`"
                    :value="item"
                  />
                </BaseElSelect>
              </ElInputWrapper>
            </BaseElFormItem>
          </div>
          <!-- <el-date-picker
            v-model="formData.expireSettings.fixedDate"
            type="date"
            placeholder="選擇日期"
            value-format="MM/dd"
            format="MM/dd"
          /> -->
        </BaseElFormItem>

        <BaseElFormItem v-if="showBlock.dayCount" prop="expireSettings.dayCount">
          <template #label>
            <FormItemTooltipLabel :tooltipWidth="500" label="點數有效天數" :hideIcon="!formData.isAnnual">
              假設為 30 天，6/1 新增點數後，到期日為 7/1，一但有點數新增，會重新計算點數到期日。
            </FormItemTooltipLabel>
          </template>
          <div class="flex items-center" style="gap: 8px">
            <ElInputWrapper>
              <BaseElInput
                v-model="formData.expireSettings.dayCount"
                :disabled="isEdit && !formData.isAnnual && !useFeatures.editWalletExp"
                style="width: 100px"
                type="number"
                :max="365"
              />
            </ElInputWrapper>
            天
          </div>
        </BaseElFormItem>

        <BaseElFormItem prop="expireNotifyDays">
          <template #label>
            <FormItemTooltipLabel :tooltipWidth="500" label="到期通知發送天數">
              系統將於您在到期日前 X 天的中午 12 點推播 Line 訊息提醒。舉例：若設定到期日前 3 天通知假設某位客人的回饋金是 12/31 到期，系統將於 12/28的中午 12 點提醒
            </FormItemTooltipLabel>
          </template>
          <BaseElInput v-model="formData.expireNotifyDays" type="number" />
        </BaseElFormItem>
      </BaseElForm>
    </div>
  </BaseDialog>
</template>

<script>
import { computed, defineComponent, onBeforeMount } from 'vue'
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import { useBaseForm } from '@/use/useForm'
import { isDigitRules, maxNumberRules, noEmptyRules } from '@/validation'
import FormItemTooltipLabel from '@/components/Form/FormItemTooltipLabel.vue'
import TipInfo from '@/components/TipInfo.vue'
import ElInputWrapper from '@/components/ElInputWrapper.vue'
import { toNumber, get } from 'lodash'
import { CreateShopPoint, UpdateShopPointByKey } from '@/api/shopPoint'
import { useShop } from '@/use/shop'
import notifyMessage from '@/config/notifyMessage'
import { monthOptions } from '@/config/date'
import { generateNumbers } from '@/utils/helper'
import { plusZero } from '@/utils/time'
import { usePermissions } from '@/use/permissions'

export default defineComponent({
  name: 'EditPointModal',
  components: {
    BaseDialog,
    FormItemTooltipLabel,
    TipInfo,
    ElInputWrapper,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  setup (props, { emit }) {
    const { formRef, formData, initFormData, loading, checkForm } = useBaseForm()
    const { checkAction } = usePermissions()
    const { shopId } = useShop()
    const dialogTitle = computed(() => {
      return get(props.data, 'id') ? '編輯點數' : '新增點數'
    })
    const isEdit = computed(() => !!get(props.data, 'id'))
    const useFeatures = computed(() => {
      return {
        editWalletExp: isEdit.value ? checkAction('admin.shopPoint.updatePointWalletAdvanced') : true,
      }
    })

    initFormData({
      name: null,
      unitName: null,
      expireNotifyDays: null,
      month: null,
      date: null,

      isAnnual: true,
      expireMode: 'fixedDate',
      expireSettings: {
        fixedDate: null,
        dayCount: null,
      },
    })

    const formRules = computed(() => {
      const rules = {
        name: [noEmptyRules()],
        unitName: [noEmptyRules()],
        expireNotifyDays: [noEmptyRules(), maxNumberRules(60), isDigitRules()],
      }

      if (formData.isAnnual) {
        rules.month = [noEmptyRules()]
        rules.date = [noEmptyRules()]
        if (formData.expireMode === 'fixedDate') {
          // rules['expireSettings.fixedDate'] = [noEmptyRules()]
          rules['expireSettings.dayCount'] = [noEmptyRules(), isDigitRules(), maxNumberRules(365)]
        }
      } else if (!formData.isAnnual) {
        if (formData.expireMode === 'fixedDate') {
          // rules['expireSettings.fixedDate'] = [noEmptyRules()]
          rules.month = [noEmptyRules()]
          rules.date = [noEmptyRules()]
        } else if (formData.expireMode === 'dayCount') {
          rules['expireSettings.dayCount'] = [noEmptyRules(), isDigitRules(), maxNumberRules(1200)]
        }
      }

      return rules
    })

    const disabledMonth = computed(() => {
      return (monthOption) => {
        if (!isEdit.value) return false
        const curMonth = props.data.annualSettlementDate ? props.data.annualSettlementDate.split('/')[0] : null
        if (monthOption.value < toNumber(curMonth)) return true
        return false
      }
    })

    const monthDateOptions = computed(() => {
      if ([1, 3, 5, 7, 8, 10, 12].includes(formData.month)) {
        return generateNumbers(1, 32, 1)
      } else if (formData.month === 2) {
        return generateNumbers(1, 29, 1)
      }
      return generateNumbers(1, 31, 1)
    })

    const showBlock = computed(() => {
      const blocks = {
        fixedDate: true,
        dayCount: true,
      }

      if (formData.isAnnual) {
        return blocks
      } else if (!formData.isAnnual) {
        if (formData.expireMode === 'fixedDate') {
          blocks.dayCount = false
        } else if (formData.expireMode === 'dayCount') {
          blocks.fixedDate = false
        }
      }

      return blocks
    })

    const submitData = computed(() => {
      const data = {
        shopId: shopId.value,
        key: get(props.data, 'key'),
        name: formData.name,
        unitName: formData.unitName,
        daysUntilExpiration: toNumber(formData.expireSettings.dayCount),
        annualSettlementDate: `${plusZero(formData.month)}/${plusZero(formData.date)}`,
        daysDiffExpirationNotify: toNumber(formData.expireNotifyDays),
        type: formData.isAnnual ? 'year' : 'none',
      }

      if (!formData.isAnnual) {
        if (formData.expireMode === 'fixedDate') {
          data.daysUntilExpiration = null
        } else if (formData.expireMode === 'dayCount') {
          data.annualSettlementDate = null
        }
      }

      return data
    })

    const onConfirm = async () => {
      if (loading.value) return
      loading.value = true
      const valid = await checkForm(formRef.value)
      if (!valid) {
        loading.value = false
        return
      }

      let apiMethod = CreateShopPoint
      if (isEdit.value) apiMethod = UpdateShopPointByKey

      const [, err] = await apiMethod(submitData.value)
      if (err) {
        window.$message.error(err)
        loading.value = false
        return
      }

      if (isEdit.value) {
        window.$message.success(notifyMessage.updateSuccess)
      } else {
        window.$message.success(notifyMessage.createSuccess)
      }

      emit('refresh')
      emit('close')
    }

    const onExpireModeChange = (mode) => {
      formData.expireSettings.fixedDate = null
      formData.expireSettings.dayCount = null
      formData.month = null
      formData.date = null
    }

    const syncFormData = () => {
      if (!isEdit.value) return
      formData.name = props.data.name
      formData.unitName = props.data.unitName
      formData.isAnnual = props.data.type === 'year'
      formData.expireNotifyDays = props.data.daysDiffExpirationNotify
      formData.expireMode = props.data.annualSettlementDate ? 'fixedDate' : 'dayCount'
      formData.expireSettings.fixedDate = props.data.annualSettlementDate
      const month = props.data.annualSettlementDate ? props.data.annualSettlementDate.split('/')[0] : null
      const date = props.data.annualSettlementDate ? props.data.annualSettlementDate.split('/')[1] : null
      formData.month = toNumber(month)
      formData.date = toNumber(date)
      formData.expireSettings.dayCount = props.data.daysUntilExpiration
    }

    onBeforeMount(() => {
      syncFormData()
    })

    return {
      useFeatures,
      dialogTitle,
      formRef,
      formData,
      loading,
      formRules,
      onConfirm,
      showBlock,
      submitData,
      onExpireModeChange,
      isEdit,
      monthOptions,
      monthDateOptions,
      disabledMonth,
    }
  },
})
</script>

<style lang="postcss" scoped>

</style>
