export const businessTimeOptions = [
  { label: 'businessTimeSettings.24hours.title', value: '24Hours' },
  { label: 'businessTimeSettings.customWeek.title', value: 'CustomWeek' },
]
export const weeksOptions = [
  { label: 'weeksOptions.mon', value: 1 },
  { label: 'weeksOptions.tue', value: 2 },
  { label: 'weeksOptions.wed', value: 3 },
  { label: 'weeksOptions.thu', value: 4 },
  { label: 'weeksOptions.fri', value: 5 },
  { label: 'weeksOptions.sat', value: 6 },
  { label: 'weeksOptions.sun', value: 0 },
]

export const monthOptions = [
  { label: 'monthOptions.jan', value: 1 },
  { label: 'monthOptions.feb', value: 2 },
  { label: 'monthOptions.mar', value: 3 },
  { label: 'monthOptions.apr', value: 4 },
  { label: 'monthOptions.may', value: 5 },
  { label: 'monthOptions.jun', value: 6 },
  { label: 'monthOptions.jul', value: 7 },
  { label: 'monthOptions.aug', value: 8 },
  { label: 'monthOptions.sep', value: 9 },
  { label: 'monthOptions.oct', value: 10 },
  { label: 'monthOptions.nov', value: 11 },
  { label: 'monthOptions.dec', value: 12 },
]
