<template>
  <div class="services-select">
    <BaseElSelect
      v-model="syncModel"
      :multiple="multiple"
      :disabled="disabled"
      value-key="id"
      collapse-tags
      :placeholder="placeholderText"
      :no-data-text="$t('common.table.empty.text')"
      @change="$emit('change', serviceList)"
    >
      <BaseElSelectOption
        v-for="item in serviceList"
        :key="item.id"
        :label="item.name"
        :value="item"
      />
    </BaseElSelect>
  </div>
</template>

<script>
import { GetService, GetServiceCount } from '@/api/service'
import { mapGetters } from 'vuex'
export default {
  name: 'ServiceSelect',
  props: {
    model: Array,
    multiple: Boolean,
    showAll: Boolean,
    disabled: Boolean,
    placeholder: String,
    manual: Boolean,
    data: Array,
    checkResource: Boolean,
  },
  // props: ['model', 'multiple', 'showAll', 'disabled', 'placeholder', 'manual', 'data'],
  data: () => ({
    serviceList: [],
  }),
  computed: {
    ...mapGetters(['shop']),
    syncModel: {
      get () {
        return this.model
      },
      set (data) {
        this.$emit('update:model', data)
      },
    },

    placeholderText () {
      let text = '請選擇服務'
      if (this.showAll) return text
      if (this.placeholder) text = this.placeholder
      return text
    },
  },

  watch: {
    data () {
      if (this.manual) this.serviceList = this.data
    },
  },

  async mounted () {
    console.log('here')
    if (this.manual) this.serviceList = this.data
    else await this.getAllService()
    if (this.checkResource) this.serviceList = this.serviceList.filter(item => item.enableResourceItem)
  },

  methods: {
    sortServiceList () {
      this.serviceList.sort((a, b) => {
        if (a.order > b.order) return 1
        if (a.order < b.order) return -1
      })
    },

    async getAllService () {
      try {
        let max = await this.getServiceCount()
        max = max.data.count
        const options = {
          shopId: this.shop,
          start: 0,
          limit: 100,
        }
        const res = await GetService(options)
        this.serviceList = res
        while (this.serviceList.length < max) {
          options.start += options.limit
          const res = await GetService(options)
          this.serviceList.push(...res)
          console.log('serviceList', this.serviceList.length)
        }
        this.sortServiceList()
      } catch (error) {
        console.log(error)
      }
    },

    async getServiceCount () {
      try {
        return await GetServiceCount({ shopId: this.shop })
      } catch (error) {
        console.log(error)
      }
    },
  },
}
</script>

<style scoped lang="scss">
.services-select {
  @apply flex items-center;
}
</style>
