<template>
  <div class="flex flex-col" style="gap: 12px">
    <p class="text-primary-100 font-bold text-normal">點數</p>
    <div>
      <p
        class="point-order-discount-item"
        :class="{ isSelected: isSelected({id: 'point-all'}), isDisabled: disabledControl.all }"
        @click="onSelect('all')"
      >
        點數全數折抵
      </p>
      <p
        class="point-order-discount-item"
        :class="{ isSelected: isSelected({id: 'point-part'}), isDisabled: disabledControl.part }"
        @click="onSelect('part')"
      >
        點數部分折抵
      </p>
    </div>

    <SalesPOSPointPartDiscountModal
      v-if="modal.partDiscount"
      scope="orderDiscount"
      :maxPrice="maxDiscountPrice"
      @confirm="onConfirmPartDiscount"
      @close="modal.partDiscount = false"
    />
  </div>
</template>

<script>
import { useSalesPOSCreate } from '@/use/useSalesPOS'
import { computed, defineComponent, reactive } from 'vue'
import SalesPOSPointPartDiscountModal from '@/components/SalesPOSPointPartDiscountModal.vue'
import { get, find } from 'lodash'

export default defineComponent({
  name: 'SalesPOSCheckoutPointDiscountList',
  components: {
    SalesPOSPointPartDiscountModal,
  },
  setup (props, { emit }) {
    const { context, addOrderDiscount, removeOrderDiscount, configData, orderUnpaidPrice, computedUsedResource } = useSalesPOSCreate()
    const modal = reactive({
      partDiscount: false,
    })

    const disabledControl = computed(() => {
      const controls = {
        part: false,
        all: false,
      }

      if (!isSelected.value({ id: 'point-part' })) {
        if (orderUnpaidPrice.value <= 0 || find(context.orderDiscount, { id: 'point-all' })) {
          controls.part = true
        }
      }

      if (!isSelected.value({ id: 'point-all' })) {
        if (orderUnpaidPrice.value <= 0) {
          controls.all = true
        }
      }

      return controls
    })

    const isSelected = computed(() => {
      return (item) => {
        return Boolean(find(context.orderDiscount, { id: item.id }))
      }
    })

    const maxDiscountPrice = computed(() => {
      return orderUnpaidPrice.value
    })

    const onSelect = (type) => {
      const isSelectedAll = isSelected.value({ id: 'point-all' })
      const isSelectedPart = isSelected.value({ id: 'point-part' })

      if (type === 'part') {
        if (disabledControl.value.part) return
        if (isSelectedPart) {
          removeOrderDiscount({ id: 'point-part' })
          return
        }
        modal.partDiscount = true
      } else if (type === 'all') {
        if (disabledControl.value.all) return
        if (isSelectedAll) {
          removeOrderDiscount({ id: 'point-all' })
          return
        }

        const preUsedPoint = get(computedUsedResource.value, 'point', 0)
        const memberPoint = get(configData, 'consumerData.pointBalance', 0)
        const pointDiscountRate = get(configData, 'pointDiscountRate', 1)
        const remainingPointBalance = memberPoint - preUsedPoint
        const remainingPrice = orderUnpaidPrice.value
        const maxSavePrice = Math.floor(remainingPointBalance / pointDiscountRate)

        let pointUse = 0
        let savePrice = 0

        if (remainingPrice >= maxSavePrice) {
          pointUse = maxSavePrice * pointDiscountRate
          savePrice = maxSavePrice
        } else {
          pointUse = remainingPrice * pointDiscountRate
          savePrice = remainingPrice
        }

        if (!pointUse) {
          window.$message.warning('點數餘額不足已折抵金額')
          return
        }

        if (isSelectedPart) {
          removeOrderDiscount({ id: 'point-part' })
        }

        addOrderDiscount({
          id: 'point-all',
          name: '點數折抵',
          mustSpendSetting: {
            type: 'point',
            amount: pointUse,
          },
          discountSource: 'noSource',
          type: 'point',
          cashAmount: savePrice,
          balance: memberPoint,
        })
      }
    }

    const onConfirmPartDiscount = (data) => {
      const isSelectedAll = isSelected.value({ id: 'point-all' })
      if (isSelectedAll) {
        removeOrderDiscount({ id: 'point-all' })
      }
      addOrderDiscount({
        id: 'point-part',
        name: '點數折抵',
        mustSpendSetting: {
          type: 'point',
          amount: data.pointUsed,
        },
        discountSource: 'noSource',
        type: 'point',
        cashAmount: data.savePrice,
      })
    }

    return { onSelect, modal, isSelected, onConfirmPartDiscount, disabledControl, maxDiscountPrice }
  },
})
</script>

<style lang="postcss" scoped>
.point-order-discount-item {
  @apply font-bold text-sub text-gray-80 p-[8px] select-none cursor-pointer;
}

.isSelected {
  @apply bg-primary-30 text-primary-100;
}

.isDisabled {
  @apply opacity-50 cursor-not-allowed;
}
</style>
