<template>
  <BaseElSelect
    v-model="syncModel"
    :multiple="multiple"
    v-bind="$attrs"
    value-key="id"
    collapse-tags
    :filterable="false"
    :disabled="disabled"
    :placeholder="$t('createReservationService.resource.placeholder')"
    :no-data-text="$t('common.table.empty.text')"
    @change="$emit('change')"
  >
    <BaseElSelectOption
      v-for="item in resourceItemList"
      :key="item.id"
      :label="item.name"
      :value="item"
    />
  </BaseElSelect>
</template>

<script>
import { defineComponent, computed, ref, onMounted } from 'vue'
import { GetResourceItem, GetResourceItemCount } from '@/api/resource'

import { get } from 'lodash'
import store from '@/store'

export default defineComponent({
  name: 'ResourceUnitSelect',
  props: [
    'model',
    'multiple',
    'disabled',
    'data',
  ],
  setup (props, { emit }) {
    const shopId = computed(() => get(store.getters, 'shop'))
    const syncModel = computed({
      get () {
        return props.model
      },
      set (data) {
        emit('update:model', data)
      },
    })
    const allResourceItems = ref([])

    const getResourceItem = async () => {
      const count = await getResourceItemCount()
      const payload = {
        shopId: shopId.value,
        start: 0,
        limit: 100,
      }

      const [res, err] = await GetResourceItem(payload)
      if (err) {
        window.$message.warning(err)
        return
      }
      allResourceItems.value = res
      if (allResourceItems.value) {
        while (allResourceItems.value.length < count) {
          payload.start += payload.limit
          const data = await GetResourceItem(payload)
          allResourceItems.value.push(...data)
        }
      }
    }

    const getResourceItemCount = async () => {
      const [res, err] = await GetResourceItemCount({ shopId: shopId.value })
      if (err) window.$message.warning(err)
      return get(res, 'count')
    }
    const resourceItemList = computed(() => {
      console.log(allResourceItems.value)
      if (props.data) return props.data
      else return allResourceItems.value
    })
    onMounted(async () => {
      await getResourceItem()
    })
    return {
      shopId,
      syncModel,
      resourceItemList,
    }
  },
})
</script>

<style scoped lang="scss"></style>
