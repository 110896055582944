<template>
  <BaseDialog
    :show="true"
    :title="$t('badicSetting.dialog.title')"
    @close="$emit('close')"
    @cancel="$emit('close')"
    @confirm="onConfirm"
  >
    <div>
      <BaseElForm
        ref="formRef"
        label-position="top"
        :model="formData"
        :rules="formRules"
      >
        <BaseElFormItem prop="isShowIndustry" :label="$t('basicSetting.isShowIndustry.title')">
          <BaseElSwitch
            v-model="formData.isShowIndustry"
            :inactive-text="$t('common.open.text')"
            :active-text="$t('common.close.text')"
          />
        </BaseElFormItem>
        <BaseElFormItem prop="homeMode" :label="$t('basicSetting.homeMode.title')">
          <span class="flex text-sm leading-[17.38px] my-[8px]">{{ $t('basicSetting.homeMode.subTitle') }}</span>
          <BaseElSelect v-model="formData.homeMode">
            <BaseElSelectOption
              v-for="item in homeModeConfig"
              :key="item.value"
              :label="$t(item.label)"
              :value="item.value"
            />
          </BaseElSelect>
        </BaseElFormItem>
      </BaseElForm>
    </div>
  </BaseDialog>
</template>

<script>
import { defineComponent, onMounted } from 'vue'
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import { useBaseForm } from '@/use/useForm'
import { noEmptyRules } from '@/validation'
import { homeModeConfig } from '@/config/pubApt'
import { get } from 'lodash'

export default defineComponent({
  name: 'BasicSettingEditModal',
  components: { BaseDialog },
  props: {
    configData: { type: Object, default: () => ({}) },
    onSubmit: { type: Function, default: () => {} },
  },
  setup (props, { emit }) {
    const { formRef, formData, initFormData, onSubmit, loading } = useBaseForm()

    initFormData({
      isShowIndustry: get(props, 'configData.isShowIndustry'),
      homeMode: get(props, 'configData.homeMode'),
    })

    const formRules = {
      homeMode: [noEmptyRules()],
    }

    const onConfirm = async () => {
      await onSubmit(props.onSubmit, formData, {
        emit,
        loading,
      })
    }
    return { formRef, formData, formRules, onConfirm, homeModeConfig }
  },
})
</script>

<style lang="postcss" scoped></style>
