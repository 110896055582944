<template>
  <el-dialog
    :title="$t('childServiceTable.dialog.title')"
    :visible="true"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <BaseElForm ref="form" :model="formData" label-position="top" :rules="formRules">
      <BaseElFormItem :label="$t('childServiceTable.table.image.title')" prop="image">
        <UploadButton
          cyUploadBtn="service-img-upload-btn"
          cyUploadedImg="service-img-uploaded"
          :img="formData.Image"
          :isAvatar="true"
          @change="loadImg"
        />
      </BaseElFormItem>
      <BaseElFormItem :label="$t('childServiceTable.dialog.name.title')" prop="name">
        <BaseElInput
          v-model="formData.name"
          testName="formData_name"
          :maxlength="55"
          show-word-limit
          :placeholder="$t('childServiceTable.dialog.name.placeholder')"
        />
      </BaseElFormItem>
      <BaseElFormItem :label="$t('childServiceTable.dialog.description.title')" prop="description">
        <BaseElInput
          v-model="formData.description"
          type="textarea"
          :autosize="{ minRows: 3, maxRows: 6 }"
          testName="formData_description"
          maxlength="200"
          show-word-limit
          :placeholder="$t('childServiceTable.dialog.description.placeholder')"
        />
      </BaseElFormItem>
      <BaseElFormItem :label="$t('childServiceTable.dialog.bookingTime.title')" prop="bookingTime">
        <div class="flex flex-col">
          <BaseElInput
            v-model="formData.bookingTime"
            testName="formData_bookingTime"
            :placeholder="$t('childServiceTable.dialog.bookingTime.placeholder')"
          />
          <BaseElCheckbox v-model="formData.hideTime" testName="formData_hideTime">
            {{ $t('childServiceTable.dialog.hideTime.title')}}
          </BaseElCheckbox>
        </div>
      </BaseElFormItem>
      <BaseElFormItem
        v-if="formData.hideTime"
        :label="$t('childServiceTable.dialog.showTime.title')"
        prop="showTime"
      >
        <BaseElInput
          v-model="formData.showTime"
          testName="formData_showTime"
          :placeholder="$t('childServiceTable.dialog.showTime.placeholder')"
        />
      </BaseElFormItem>
      <BaseElFormItem :label="$t('childServiceTable.dialog.price.title')" prop="price">
        <div class="flex flex-col">
          <p v-if="chargeType === 'checkout' && !useFeatures.forceCanHidePrice" class="text-sm leading-[17.38px] mb-[8px]">
            {{ $t('childServiceTable.dialog.price.rule.title') }}
          </p>
          <BaseElInput
            v-model="formData.price"
            testName="formData_price"
            :placeholder="$t('childServiceTable.dialog.price.placeholder')"
          />
          <BaseElCheckbox
            v-model="formData.hidePrice"
            :disabled="chargeType === 'checkout' && !useFeatures.forceCanHidePrice "
            testName="formData_hidePrice"
          >
            {{ $t('childServiceTable.dialog.hidePrice.title')}}
          </BaseElCheckbox>
        </div>
      </BaseElFormItem>
      <BaseElFormItem v-if="formData.hidePrice" :label="$t('childServiceTable.dialog.showPrice.title')" prop="showPrice">
        <BaseElInput
          v-model="formData.showPrice"
          :maxlength="40"
          show-word-limit
          testName="formData_showPrice"
          :placeholder="$t('childServiceTable.dialog.showPrice.placeholder')"
        />
      </BaseElFormItem>
      <BaseElFormItem :label="$t('childServiceTable.dialog.order.title')" prop="order">
        <BaseElInput
          v-model="formData.order"
          testName="formData_order"
          :placeholder="$t('childServiceTable.dialog.order.placeholder')"
        />
      </BaseElFormItem>

      <BaseElFormItem v-if="mainService.enableAppointmentUnit" :label="$t('childServiceTable.dialog.serviceUnits.title')" prop="unit">
        <template slot="label">
          <div class="flex items-center" style="gap: 8px">
            <p>{{ $t('childServiceTable.dialog.serviceUnits.title')}}</p>
            <el-tooltip placement="right">
              <div slot="content">
                <ol style="list-style: decimal; padding-left: 16px">
                  <li>
                    {{ $t('childServiceTable.dialog.serviceUnits.tooltip1')}}
                  </li>
                  <li>{{ $t('childServiceTable.dialog.serviceUnits.tooltip2')}}</li>
                </ol>
              </div>
              <span class="material-icons">help_outline</span>
            </el-tooltip>
          </div>
        </template>
        <BaseElSelect
          v-model="formData.serviceUnits"
          value-key="id"
          testName="formData_serviceUnits"
          :placeholder="$t('childServiceTable.dialog.serviceUnits.placeholder')"
          clearable
          multiple
        >
          <BaseElSelectOption
            v-for="unit in mainService.serviceUnits"
            :key="unit.id"
            :label="unit.name"
            :value="unit"
          />
        </BaseElSelect>
      </BaseElFormItem>
      <BaseElFormItem v-if="mainService.enableResourceItem" :label="$t('childServiceTable.dialog.resource.title')" prop="resource">
        <template slot="label">
          <div class="flex items-center" style="gap: 8px">
            <p>{{ $t('childServiceTable.dialog.resource.title')}}</p>
            <el-tooltip placement="right">
              <div slot="content">
                <ol style="list-style: decimal; padding-left: 16px">
                  <li>
                    {{ $t('childServiceTable.dialog.resource.tooltip1') }}
                  </li>
                  <li>{{ $t('childServiceTable.dialog.resource.tooltip2')}}</li>
                </ol>
              </div>
              <span class="material-icons">help_outline</span>
            </el-tooltip>
          </div>
        </template>
        <ResourceUnitSelect
          testName="formData_resourceItems"
          :model.sync="formData.resourceItems"
          :data="resourceItemList"
          multiple
          :showAll="true"
        />
      </BaseElFormItem>
    </BaseElForm>

    <div slot="footer">
      <BaseElButton data-testid="dialog-cancel-btn" plain @click="$emit('close')">{{ $t('common.button.cancel.text')}}</BaseElButton>
      <BaseElButton data-testid="dialog-confirm-btn" type="primary" @click="handleConfirm">{{ $t('common.button.confirm.text')}}</BaseElButton>
    </div>

    <ImageCropper
      v-if="uploadDialog"
      :image="formData.Image"
      @uploaded="getImage"
      @close="uploadDialog = false"
    />
  </el-dialog>
</template>

<script>
import ServiceUnitSelect from '@/components/Select/ServicesUnitSelect.vue'
// import { UpdateSubService } from '@/api/service'
import ResourceUnitSelect from '@/components/Select/ResourceUnitSelect.vue'
import UploadButton from '@/components/Button/UploadButton.vue'
import ImageCropper from '@/components/ImageCropper.vue'
import { noEmptyRules, isDigitRules, rangeRules } from '@/validation'
import formUtils from '@/utils/form'
import { computed, defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import { map, cloneDeep, isEmpty, find, get } from 'lodash'
import { usePermissions } from '@/use/permissions'

export default defineComponent({
  name: 'CreateChildServiceDialog',
  components: {
    ImageCropper,
    UploadButton,
    // ServiceUnitSelect,
    ResourceUnitSelect,
  },
  props: {
    type: {
      type: String,
      default: '',
    },
    service: {
      type: [Object, String],
      default: () => ({}),
    },
    mainService: {
      type: Object,
      default: () => ({}),
    },
    configData: {
      type: Object,
      default: () => ({}),
    },
  },
  setup (props, { emit }) {
    const { checkAction } = usePermissions()
    const chargeType = computed(() => {
      return get(props.configData, 'reservation.chargeType')
    })
    const useFeatures = computed(() => {
      return {
        forceCanHidePrice: checkAction('admin.appointmentSubService.enableShowPrice'),
      }
    })
    return {
      chargeType,
      useFeatures,
    }
  },
  data: () => ({
    selectService: null,
    // availableUnitList: [],
    uploadDialog: false,
    formData: {
      Image: null,
      name: null,
      description: null,
      hidePrice: false,
      hideTime: false,
      price: null,
      showPrice: '',
      showTime: '',
      bookingTime: null,
      order: 100,
      allAppointmentUnit: false,
      serviceUnits: null,
      resourceItems: null,
      enableAppointmentUnit: false,
      enableResourceItem: false,
    },
    formRules: {
      name: noEmptyRules(),
      description: noEmptyRules(),
      price: [isDigitRules(), noEmptyRules(), rangeRules()],
      // showPrice: [isDigitRules(false)],
      showTime: [isDigitRules(false), rangeRules()],
      bookingTime: [noEmptyRules(), isDigitRules(), rangeRules()],
      order: [noEmptyRules(), isDigitRules(), rangeRules()],
    },
  }),
  computed: {
    ...mapGetters(['shop']),
    resourceItemList () {
      return get(this.mainService, 'resourceItems')
    },
  },
  mounted () {
    // this.availableUnitList = this.mainService.serviceUnits
    if (!isEmpty(this.service)) this.syncData()
    if (isEmpty(this.service)) this.setDefaultData()
    const units = this.removeUnbindUnit(this.mainService.serviceUnits, this.formData.serviceUnits)
    this.formData.serviceUnits = units
  },
  methods: {
    getImage (data) {
      this.formData.Image = data
      // this.avatarChanged = true
      this.uploadDialog = false
    },
    loadImg (img) {
      this.formData.Image = img
      this.uploadDialog = true
    },
    async handleConfirm () {
      this.loading = true
      if (this.type === 'create') await this.createSubService()
      if (this.type === 'edit') await this.updateSubService()
      this.loading = false
    },
    //= > 創建服務
    async createSubService () {
      if (!(await formUtils.checkForm(this.$refs.form))) return
      // this.$message.success('新增服務成功!')
      // if (!this.formData.serviceUnits || isEmpty(this.formData.serviceUnits)) {
      //   this.formData.serviceUnits = this.mainService.serviceUnits
      // }
      this.$emit('created', this.formData)
      this.$emit('close')
    },

    //= > 更新服務
    async updateSubService () {
      if (!(await formUtils.checkForm(this.$refs.form))) return
      // this.$message.success('更新服務成功!')
      // if (!this.formData.serviceUnits || isEmpty(this.formData.serviceUnits)) {
      //   this.formData.serviceUnits = this.mainService.serviceUnits
      // }
      this.$emit('edited', this.formData)
      this.$emit('close')
    },

    syncData () {
      const res = cloneDeep(this.service)
      this.formData.name = res.name
      this.formData.Image = res.Image
      if (res.id) this.formData.id = res.id
      this.formData.description = res.description
      this.formData.isPublic = res.isPublic
      this.formData.order = res.order
      this.formData.price = res.price
      this.formData.bookingTime = res.bookingTime
      this.formData.serviceUnits = res.serviceUnits
      this.formData.resourceItems = res.resourceItems

      if (this.chargeType === 'checkout' && !this.useFeatures.forceCanHidePrice) {
        this.formData.hidePrice = false
        this.formData.showPrice = res.showPrice
      } else {
        if (res.showPrice && res.showPrice !== '0') {
          this.formData.hidePrice = true
          this.formData.showPrice = res.showPrice
        }
      }

      if (res.showTime && res.showTime !== '0') {
        this.formData.hideTime = true
        this.formData.showTime = res.showTime
      }
      this.formData.enableSubService = res.enableSubService
      this.formData.enableSubServiceClientHide = res.enableSubServiceClientHide
      this.formData.enableAppointmentUnit = res.enableAppointmentUnit
      this.formData.enableResourceItem = res.enableResourceItem
    },

    setDefaultData () {
      const data = cloneDeep(this.mainService)
      this.formData.price = data.price
      this.formData.bookingTime = data.bookingTime
      // this.availableUnitList = data.serviceUnits
      if (data.hidePrice && data.showPrice && data.showPrice !== '0') {
        this.formData.hidePrice = true
        this.formData.showPrice = data.showPrice
      }
      if (data.hideTime && data.showTime && data.showTime !== '0') {
        this.formData.hideTime = true
        this.formData.showTime = data.showTime
      }
      this.formData.enableAppointmentUnit = data.enableAppointmentUnit
      this.formData.enableResourceItem = data.enableResourceItem
    },
    removeUnbindUnit (availableUnitList, data) {
      if (!data) return []
      return data.filter((i) => find(availableUnitList, { id: i.id }))
      // if (this.formData.serviceUnits) {
      //   this.formData.serviceUnits = this.formData.serviceUnits.filter(i => find(this.availableUnitList, { id: i.id }))
      // }
    },
  },
})
</script>

<style scoped lang="scss">
::v-deep .el-select {
  @apply h-full;
}
::v-deep .el-select .el-input {
  @apply h-full;
}
::v-deep .el-tag--info {
  @apply h-full break-all;
  white-space: break-spaces;
}
::v-deep .el-select__tags {
  @apply flex flex-wrap;
}
</style>
