import Layout from '@/layout'

export const appointmentRoutes = [
  // 預約模組
  {
    path: '/reservations',
    component: Layout,
    isGroup: true,
    meta: {
      title: 'page.reservations.title',
    },
    children: [
      {
        path: 'settings',
        component: () => import('@/components/RouterViewWrapper.vue'),
        meta: {
          title: 'page.reservations.settings.title',
        },
        children: [
          {
            path: 'service-category-setting',
            name: 'ServiceCategorySetting',
            component: () =>
              import('@/views/Reservations/Setting/ServiceCategorySetting'),
            meta: {
              title: 'page.serviceCategorySetting.title',
              action: 'admin.appointmentCategory.page',
              bobPageAction: 'PAGE_VIEW_DASH_SHOP_RESERVATION_CATEGORY_LIST',
            },
          },
          {
            path: 'service-setting',
            name: 'ServiceSetting',
            component: () =>
              import('@/views/Reservations/Setting/ServiceSetting'),
            meta: {
              title: 'page.serviceSetting.title',
              action: 'admin.appointmentService.page',
              bobPageAction: 'PAGE_VIEW_DASH_SHOP_RESERVATION_SERVICE_LIST',
              group: 'Appointment',
            },
          },
          {
            path: '/create-reservation-service/:serviceId?',
            name: 'CreateReservationService',
            hidden: true,
            component: () =>
              import('@/views/Reservations/CreateReservationService/CreateReservationService.vue'),
            meta: {
              title: '創建服務項目',
              action: 'admin.appointmentService.page',
              // bobPageAction: 'PAGE_VIEW_DASH_SHOP_RESERVATION_SERVICE_LIST',
            },
          },
          {
            path: 'attach-service-setting',
            name: 'AttachServiceSetting',
            component: () =>
              import('@/views/Reservations/Setting/AttachServiceSetting'),
            meta: {
              title: 'page.attachServiceSetting.title',
              action: 'admin.appointmentServiceAttach.page',
              bobPageAction: 'PAGE_VIEW_DASH_SHOP_RESERVATION_ATTACH_LIST',
            },
          },
          {
            path: 'service-unit-setting',
            name: 'ServiceUnitSetting',
            component: () =>
              import('@/views/Reservations/Setting/ServiceUnitSetting'),
            meta: {
              title: 'page.serviceUnitSetting.title',
              action: 'admin.appointmentUnit.page',
              bobPageAction: 'PAGE_VIEW_DASH_SHOP_RESERVATION_UNIT_LIST',
            },
          },
          {
            path: 'shifts-setting',
            name: 'ShiftClassSetting',
            component: () =>
              import('@/views/Reservations/ShiftClassSetting/ShiftClassSetting.vue'),
            meta: {
              title: 'page.shiftClassSetting.title',
              action: 'admin.appointmentScheduleDay.page',
              bobPageAction: 'PAGE_VIEW_DASH_SHOP_RESERVATION_SHIFT_LIST',
            },
          },
          {
            path: 'shifts-calendar-setting',
            name: 'ShiftsCalendarSetting',
            component: () =>
              import('@/views/ShiftCalendar/ShiftCalendar.vue'),
            meta: {
              title: 'page.shiftCalendarSetting.title',
              action: 'admin.appointmentSchedule.page',
              bobPageAction: 'PAGE_VIEW_DASH_SHOP_RESERVATION_SHIFT_SETTING',
            },
          },
          {
            path: 'shifts-calendar',
            name: 'ShiftsCalendar',
            hidden: true,
            component: () =>
              import('@/views/ShiftCalendarEdit/ShiftCalendarEdit.vue'),
            meta: {
              title: 'page.shiftCalendarEdit.title',
              action: 'admin.appointmentSchedule.page',
              // bobPageAction: 'PAGE_VIEW_DASH_SHOP_RESERVATION_SHIFT_SETTING'
            },
          },
        ],
      },
      {
        path: '/reservation-create/:step?',
        name: 'ReservationCreate',
        hidden: true,
        component: () => import('@/views/Reservations/ReservationCreate/ReservationCreate.vue'),
        meta: {
          title: 'page.reservationCreate.title',
          action: 'admin.appointmentReservation.page',
        },
      },
      {
        path: '/new-reservation-create/:step?',
        name: 'NewReservationCreate',
        component: () => import('@/views/NewCreateAppointment/NewCreateAppointment.vue'),
        hidden: true,
        meta: {
          title: '創建預約',
          action: 'admin.appointmentReservation.page',
        },
      },
      {
        path: 'reservation-list',
        name: 'ReservationList',
        component: () => import('@/views/Reservations/ReservationList/ReservationList.vue'),
        meta: {
          title: 'page.reservationList.title',
          action: 'admin.appointmentReservation.page',
          bobPageAction: 'PAGE_VIEW_DASH_SHOP_RESERVATION_LIST',
        },
      },
      {
        path: 'reservation-calendar',
        name: 'ReservationCalendar',
        component: () => import('@/views/Reservations/ReservationCalendar/ReservationCalendar.vue'),
        meta: {
          title: 'page.reservationCalendar.title',
          action: 'admin.appointmentReservation.page',
          bobPageAction: 'PAGE_VIEW_DASH_SHOP_RESERVATION_CALENDAR',
        },
      },
      {
        path: 'reservation-time-space',
        name: 'ReservationTimeSpace',
        component: () => import('@/views/Reservations/ReservationTimeSpace'),
        meta: {
          title: 'page.reservationTimeSpace.title',
          action: 'admin.appointmentScheduleBan.page',
          bobPageAction: 'PAGE_VIEW_DASH_SHOP_RESERVATION_UNIT_SPACE_LIST',
        },
      },
    ],
  },
]
