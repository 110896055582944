export const currencyOptions = {
    TWD: { label: 'NT$ TWD', value: 'TWD', title: 'currencyOptions.TWD', symbol: 'NT$' },
    JPY: { label: '¥ JPY', value: 'JPY', title: 'currencyOptions.JPY', symbol: '¥' },
    KRW: { label: '₩ KRW', value: 'KRW', title: 'currencyOptions.KRW', symbol: '₩' },
    USD: { label: '$ USD', value: 'USD', title: 'currencyOptions.USD', symbol: '$' },
}

export const timezoneOptions = {
    'Asia/Taipei': { label: '(UTC+08:00) 台北', value: 'Asia/Taipei' },
    'Asia/Tokyo': { label: '(UTC+09:00) 東京、大阪、札幌', value: 'Asia/Tokyo' },
    'Asia/Seoul': { label: '(UTC+09:00) 首爾', value: 'Asia/Seoul' },
    'America/Los_Angeles': { label: '(UTC-08:00) 洛杉磯', value: 'America/Los_Angeles' },
}
