export const useFetch = () => {
  const fetchAdapter = async (apiMethod, payload, { version = 'v2' }) => {
    if (version === 'v2') return await apiMethod(payload)
    else if (version === 'v1') {
      try {
        const res = await apiMethod(payload)
        return [res, null]
      } catch (error) {
        return [null, error]
      }
    }
  }
  const simpleFetch = async (apiMethod, payload, callback, throwErr = false, errorToast = true) => {
    const [res, err, rawErr] = await apiMethod(payload)
    if (err) {
      console.log(rawErr)
      if (throwErr) throw err
      console.log('err', err)
      if (errorToast) window.$message.error(err.msg || err)
      return 'error'
    }
    if (callback) await callback(res)
    return res
  }

  const simpleFetchOld = async (apiMethod, payload, callback, throwErr = false) => {
    try {
      const res = await apiMethod(payload)
      if (callback) await callback(res)
      return res
    } catch (error) {
      if (throwErr) throw error
      console.log('err', error)
      window.$message.error(error.msg || error)
      return 'error'
    }
  }

  const fetchAll = async (fetchAPI, payload, callback, onError, options = {}) => {
    let start = 0
    const data = []
    const _limit = options.limit || 100

    while (true) {
      const [res, err] = await fetchAPI({
        ...payload,
        start,
        limit: _limit,
      })
      if (err) {
        window.$message.error(err)
        if (onError) await onError(err)
        return
      }
      start += _limit
      data.push(...res)
      if (res.length < _limit) {
        break
      }
    }

    if (callback) await callback(data)
  }

  const fetchWithAmount = async (fetchAPI, totalLimit, percentage, total, payload, callback) => {
    let start = 0
    const data = []
    const _limit = 1000

    while (true) {
      const [res, err] = await fetchAPI({
        ...payload,
        start,
        limit: _limit,
      })
      if (err) {
        console.log(err)
        window.$message.error(err)
        return
      }
      start += _limit
      data.push(...res)
      percentage.value = Math.floor((data.length / total) * 100)
      if (res.length < _limit || data.length >= totalLimit) {
        break
      }
    }

    if (callback) await callback(data)
  }

  const fetchAllOld = async (fetchAPI, payload, callback, onError, options = {}) => {
    let start = 0
    const data = []
    const _limit = options.limit || 100

    try {
      while (true) {
        const result = await fetchAPI({
          start,
          limit: _limit,
          ...payload,
        })
        start += _limit
        data.push(...result)
        if (result.length < _limit) {
          break
        }
      }

      if (callback) await callback(data)
    } catch (error) {
      window.$message.error(error)
      if (onError) await onError()
    }
  }

  return { simpleFetch, fetchAll, fetchAllOld, simpleFetchOld, fetchWithAmount, fetchAdapter }
}
