<template>
  <div class="sales-pos-checkout-consumer-info">
    <div>
      <p>{{ displayData.name }}</p>
      <p>{{ displayData.phone }}</p>
    </div>

    <div class="flex" style="gap: 12px">
      <div class="grid grid-cols-2" style="column-gap: 12px">
        <div v-for="resource in displayResourceFields" :key="resource.label" class="text-sub">
          <span class="font-medium">{{ resource.label }}</span>：<span class="text-primary-100">{{ resource.value }}</span>
        </div>
      </div>
      <div class="order-discount-btn" plain @click="onOrderDiscount">{{ '整單\n折扣' }}</div>
      <div class="edit-order-btn" plain @click="onEditOrder">{{ '更改\n明細' }}</div>
    </div>
  </div>
</template>

<script>
import { usePermissions } from '@/use/permissions'
import { useSalesPOSCreate } from '@/use/useSalesPOS'
import { isNull, filter, get } from 'lodash'

import { computed, defineComponent } from 'vue'
import { useRouter } from 'vue-router/composables'
export default defineComponent({
  name: 'SalesPOSCheckoutConsumerInfo',
  setup (props, { emit }) {
    const { context, configData, originData, flags } = useSalesPOSCreate()
    const router = useRouter()
    const { checkAction } = usePermissions()

    const useFeatures = computed(() => {
      return {
        usePoint: checkAction('admin.shopPoint.page'),
        useChashback: checkAction('admin.shopCashback.page'),
        useClassTicket: checkAction('admin.classTicket.page'),
        useCoupon: checkAction('admin.coupon.page'),
      }
    })

    const displayData = computed(() => {
      const consumerData = configData.consumerData
      let userName
      let userPhone

      if (get(context.member, 'id')) {
        userName = get(consumerData, 'userInfo.name')
        userPhone = get(consumerData, 'userInfo.phone')
      } else {
        userName = get(context.member, 'name')
        userPhone = get(context.member, 'phone')
      }

      return {
        name: userName,
        phone: userPhone,
        resources: {
          cashback: get(consumerData, 'cashbackBalance'), // 回饋金
          wallet: get(consumerData, 'walletBalance'), // 儲值金
          coupon: get(consumerData, 'couponRecords.length'), // 票券
          point: get(consumerData, 'pointBalance'), // 點數
        },
      }
    })

    const displayResourceFields = computed(() => {
      const omitList = []
      const dields = [
        { label: '儲值金', value: displayData.value.resources.wallet },
        { label: '點數', value: displayData.value.resources.point },
        { label: '回饋金', value: displayData.value.resources.cashback },
        { label: '票券', value: displayData.value.resources.coupon },
      ]

      if (isNull(displayData.value.resources.point)) omitList.push('點數')
      if (isNull(displayData.value.resources.cashback)) omitList.push('回饋金')
      if (isNull(displayData.value.resources.coupon)) omitList.push('票券')
      if (isNull(displayData.value.resources.wallet)) omitList.push('儲值金')

      if (!get(context, 'member.id')) {
        return []
      }

      return filter(dields, (field) => !omitList.includes(field.label))
    })

    const onOrderDiscount = () => {
      emit('orderDiscount')
    }

    const onEditOrder = () => {
      context.payments = filter(context.payments, (payment) => payment.type === 'paidDeposit')
      context.orderDiscount = []
      originData.oldCashbackUse = null
      flags.oldCashbackUseChanged = false
      router.push({ name: 'SalesPOS', query: { edit: true } })
    }
    return { onEditOrder, onOrderDiscount, displayResourceFields, displayData }
  },
})
</script>

<style lang="postcss" scoped>
.sales-pos-checkout-consumer-info {
  @apply bg-primary-10 rounded-[6px] p-[16px];
  @apply flex items-center justify-between select-none;
}

.order-discount-btn,
.edit-order-btn {
 @apply w-[48px] h-[46px] whitespace-pre-line text-sm;
 @apply border-[1px] border-primary-100 rounded-[6px] text-primary-100 font-medium bg-white;
 @apply flex items-center justify-center cursor-pointer;
 @apply leading-[17.38px] hover:bg-primary-100 hover:text-white select-none;
}

</style>
