<template>
  <section class="section-block">
    <SectionTitle :title="$t('publicAppointment.invoiceConfigBlock.title')" @edit="modal.edit = true" />
    <BaseElForm label-position="left" label-width="250px">
      <BaseElFormItem v-for="field in displayData" :key="field.label" :label="field.label">
        <div class="text-[#5E5E5E]">
          <span>{{ field.value }}</span>
        </div>
      </BaseElFormItem>
    </BaseElForm>

    <InvoiceConfigEditModal
      v-if="modal.edit"
      :content="content"
      @close="modal.edit = false"
      @refresh="$emit('refresh')"
    />
  </section>
</template>

<script>
import { defineComponent, computed, reactive } from 'vue'
import SectionTitle from '@/components/Title/SectionTitle.vue'
import { invoice3rdPlatformConfig } from '@/config/pubApt'
import { get } from 'lodash'
import InvoiceConfigEditModal from './InvoiceConfigEditModal.vue'
import { i18n } from '@/plugins/i18n/i18n'

export default defineComponent({
  name: 'InvoiceConfigBlock',
  components: { SectionTitle, InvoiceConfigEditModal },
  props: {
    content: { type: Object, default: () => ({}) },
  },
  emits: ['refresh'],
  setup (props) {
    const displayData = computed(() => {
      const config = props.content
      return [
        { label: i18n.t("publicAppointment.invoiceConfigBlock.enable.title"), value: config.enable ? i18n.t('common.open.text') : i18n.t('common.close.text') },
        { label: i18n.t('publicAppointment.invoiceConfigBlock.platform.title'), value: get(invoice3rdPlatformConfig, `${config.platform}.label`) || i18n.t('common.notSetupYet.text') },
        { label: i18n.t('publicAppointment.invoiceConfigBlock.merchantId.title'), value: get(config, 'invoiceApiConfig.merchantID') || '-' },
        { label: i18n.t('publicAppointment.invoiceConfigBlock.hashKey.title'), value: get(config, 'invoiceApiConfig.hashKey') || '-' },
        { label: i18n.t('publicAppointment.invoiceConfigBlock.hashIv.title'), value: get(config, 'invoiceApiConfig.hashIV') || '-' },
      ]
    })
    const modal = reactive({
      edit: false,
    })
    return { displayData, modal }
  },
})
</script>

<style lang="postcss" scoped>

</style>
