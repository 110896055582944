import { admin2 } from '@/api/instance'

//= > 更新會員中心設置
export const GetShopInfo = async ({ shopId }) => {
  const res = await admin2({
    method: 'get',
    url: `/${shopId}/shopInfo`,
  })

  return res
}

//= > 更新會員中心設置
export const UpdateShopInfo = async ({ shopId, contactCustomerLink }) => {
  const res = await admin2({
    method: 'put',
    url: `/${shopId}/shopInfo`,
    data: {
      contactCustomerLink,
    },
  })

  return res
}

//= > 取得店家會員中心設置
export const GetMemberCenterConfig = async ({ shopId }) => {
  const res = await admin2({
    method: 'get',
    url: `/${shopId}/memberCenterConfig`,
  })

  return res
}

//= > 更新會員中心設置
export const UpdateMemberCenterConfig = async ({
  shopId,
  functionMenuSetting,
  footers,
}) => {
  const res = await admin2({
    method: 'put',
    url: `/${shopId}/memberCenterConfig`,
    data: {
      functionMenuSetting,
      footers,
    },
  })

  return res
}

//= > 取得會員中心設置
export const GetAllowFooterNav = async ({ shopId }) => {
  const res = await admin2({
    method: 'get',
    url: `/${shopId}/memberCenterConfig/allowFooters`,
  })

  return res
}

export const GetAllowFuncMenu = async ({ shopId }) => {
  const res = await admin2({
    method: 'get',
    url: `/${shopId}/memberCenterConfig/allowFunctionMenu`,
  })

  return res
}

export const GetIsScanQrcodeValid = async ({ shopId }) => {
  const res = await admin2({
    method: 'get',
    url: `/${shopId}/memberCenterConfig/scanQrcode`,
  })

  return res
}

// 外部會員卡設置
export const GetMemberCenterQRCode = async ({ shopId }) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/memberCenterQRcode`,
  })
}

export const UpdateMemberCenterQRCode = async ({ shopId, enable, type, isExternal, externalType }) => {
  return await admin2({
    method: 'PUT',
    url: `/${shopId}/memberCenterQRcode`,
    data: { enable, type, isExternal, externalType },
  })
}

export const GetClientPageConfig = async ({ shopId }) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/clientPageSetting`,
  })
}

export const UpdateClientPageConfig = async ({
  shopId,
  colorStyle,
  CustomColorStyleId,
  enableLiff,
  clientShortUrls,
}) => {
  return await admin2({
    method: 'PUT',
    url: `/${shopId}/clientPageSetting`,
    data: {
      colorStyle,
      CustomColorStyleId,
      enableLiff,
      clientShortUrls,
    },
  })
}
// 取得Client頁面設置連結
export const GetClientPageConfigLink = async ({ shopId }) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/clientPageSetting/links`,
  })
}

// 更新會員第三方交易設定
export const UpdateExternalTransactionConfig = async ({
  shopId,
  enableClientDisplayTimeRange,
  clientDisplayTimeRangeStartAt,
  clientDisplayTimeRangeEndAt,
}) => {
  return await admin2({
    method: 'PUT',
    url: `/${shopId}/memberExternalTransactionConfig`,
    data: {
      enableClientDisplayTimeRange,
      clientDisplayTimeRangeStartAt,
      clientDisplayTimeRangeEndAt,
    },
  })
}

// 取得會員第三方交易設定
export const GetExternalTransactionConfig = async ({
  shopId,
}) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/memberExternalTransactionConfig`,
  })
}

export const GetMemberCenterCardOptions = async ({ shopId }) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/memberCenterConfig/memberCardOptions`,
  })
}
