<template>
  <div class="edit-shift-calendar">
    <PageTitle
      :title="displayData.pageTitle"
      hideBtn
      :icon="editContext.mode === 'preview' || !useBatchCreateSchedule? 'chevron_left' : '' "
      @iconClick="$router.go(-1)"
    />

    <BaseElForm label-position="top" :show-message="false">
      <FiltersContainer style="margin-bottom: 0">
        <div class="flex items-center">
          <BaseElFormItem :label="displayData.labels.serviceUnit" required>
            <ServicesUnitSelect testName="serviceUnit" :model.sync="editContext.serviceUnit" @change="changeServiceUnit" />
          </BaseElFormItem>
        </div>
        <BaseElButton v-if="editContext.mode === 'preview'" type="primary" class="primary-btn" style="width: 100px" @click="onCreate">{{ displayData.buttons.addSchedule }}</BaseElButton>

        <BaseElFormItem v-if="editContext.mode !== 'preview'" :label="displayData.labels.shiftClass" required>
          <BaseElSelect
            v-model="editContext.shiftClass"
            :placeholder="displayData.placeholders.selectShiftClass"
            value-key="id"
            :no-data-text="displayData.noDataText"
            testName="shiftClass"
          >
            <BaseElSelectOption
              v-for="item in shopShiftClassList"
              :key="item.name"
              :label="item.name"
              :value="item"
            />
          </BaseElSelect>
        </BaseElFormItem>
      </FiltersContainer>
    </BaseElForm>

    <div v-loading="loading" class="flex-1" style="margin-top: 22px">
      <FullCalendar v-if="show && shiftCalendar.initialDate" ref="calendarRef" class="h-full" :options="showCalendar" />
      <div style="height: 200px" />
    </div>

    <SelectEditShiftScheduleModeModal
      v-if="modal.selectEditMode"
      @close="modal.selectEditMode = false"
      @confirm="onEditModeChange"
    />

    <BatchCreateShiftScheduleModal
      v-if="modal.batchCreate"
      :shift-class-list="shopShiftClassList"
      :shopBusinessTimeConfig="shopBusinessTimeConfig"
      :context="editContext"
      @close="modal.batchCreate = false"
      @refresh="refresh"
    />
    <BatchDeleteShiftScheduleModal
      v-if="modal.batchDelete"
      :shift-class-list="shopShiftClassList"
      :shopBusinessTimeConfig="shopBusinessTimeConfig"
      :context="editContext"
      @close="modal.batchDelete = false"
      @refresh="refresh"
    />

    <PageFixedFooter
      v-if="editContext.mode === 'sketch'"
      :confirmBtn="useBatchCreateSchedule ? displayData.buttons.saveAndEdit : displayData.buttons.save"
      @cancel="onCancelEdit"
      @confirm="saveEditSchedule"
    />

    <PageFixedFooter
      v-if="editContext.mode === 'batch'"
      hideCancel
      :confirmBtn="displayData.buttons.backToEdit"
      @confirm="onCancelEdit"
    />
  </div>
</template>
<script>
import { get, filter, find, indexOf, cloneDeep } from 'lodash'
import ServicesUnitSelect from '@/components/Select/ServicesUnitSelect.vue'
import FullCalendar from '@fullcalendar/vue'
import { FindServiceUnit } from '@/api/serviceUnit'
import { GetShopBusinessTimeConfig } from '@/api/shopBusinessTime'
import { useShop } from '@/use/shop'
import { useEditUnitSchedule, useShiftCalendar } from '@/use/useShiftCalendar'
import { useFullCalendar } from '@/use/useFullCalendar'
import { onMounted, reactive, ref, nextTick, set, computed, onBeforeMount, onUnmounted, watch } from 'vue'
import SelectEditShiftScheduleModeModal from './components/SelectEditShiftScheduleModeModal.vue'
import BatchCreateShiftScheduleModal from './components/BatchCreateShiftScheduleModal.vue'
import BatchDeleteShiftScheduleModal from './components/BatchDeleteShiftScheduleModal.vue'
import { useFetch } from '@/use/fetch'
import { shiftCalendarConfig, pageTitleConfig } from '@/config/shiftCalendar'
import { passValue } from '@/utils/helper'
import { useRoute } from 'vue-router/composables'
import dayjs from '@/lib/dayjs'
import { usePermissions } from '@/use/permissions'
import router from '@/router'
import { i18n } from '@/plugins/i18n/i18n'
import { calendarConfigs } from '@/config/reservationCalendar'

const loadDebounceKeyList = {}
function localDebounce (key, next) {
  const stl = 500
  if (loadDebounceKeyList[key]) {
    if (loadDebounceKeyList[key] + stl > +new Date()) {
      return () => {}
    }
  }
  loadDebounceKeyList[key] = +new Date()
  return next()
}

export default {
  name: 'ShifCalendarEdit',
  components: { ServicesUnitSelect, FullCalendar, BatchCreateShiftScheduleModal, SelectEditShiftScheduleModeModal, BatchDeleteShiftScheduleModal },
  setup (props, { emit }) {
    const { shopId } = useShop()
    const { simpleFetch, simpleFetchOld } = useFetch()
    const editContext = reactive({
      mode: 'preview',
      serviceUnit: null,
      shiftClass: null,
      selectDate: null,
      selectEvent: null,
    })
    const route = useRoute()
    const show = ref(true)
    const shopBusinessTimeConfig = ref({})
    const loading = ref(false)
    const calendarRef = ref(null)

    const search = reactive({
      serviceUnit: null,
    })
    const modal = reactive({
      selectEditMode: false,
      editSchedule: false,
      batchCreate: false,
      batchDelete: false,
    })
    const { checkAction } = usePermissions()
    const useBatchCreateSchedule = computed(() => checkAction('admin.appointmentSchedule.batchCreateByWeek'))
    const {
      calendarRange,
      calendarRangeString,
      getCalendarDate,
      createCalendarEvent,
    } = useFullCalendar()

    const {
      unitSchedule, shopSchedule,
      shopShiftClassList,
      getShopShiftsClass,
      getShopShiftsSchdule,
      updateUnitSchedule,
      updateShopShiftsSchedule,
    } = useShiftCalendar()

    const {
      cachedEditUnitSchedule,
      displayUnitSchedule,
      getUnitDisplaySchedule,
      updateUnitCachedEditSchedule,
      submitEditedSchedule,
    } = useEditUnitSchedule()

    // ?? 頁面顯示資料
    const displayData = computed(() => {
      return {
        pageTitle: useBatchCreateSchedule.value ? get(pageTitleConfig, editContext.mode) : i18n.t('shiftCalendarEdit.title'),
        labels: {
          serviceUnit: i18n.t('shiftCalendarEdit.search.name.title'),
          shiftClass: i18n.t('shiftCalendarEdit.shiftClass.title'),
        },
        buttons: {
          addSchedule: i18n.t('shiftCalendarEdit.button.addShift.text'),
          saveAndEdit: i18n.t('shiftCalendarEdit.button.saveAndReturn.text'),
          save: i18n.t('common.button.save.text'),
          backToEdit: i18n.t('shiftCalendarEdit.button.return.text'),
        },
        placeholders: {
          selectShiftClass: i18n.t('common.select.placeholder'),
        },
        notify: {
          warning: {
            selectServiceUnit: i18n.t('shiftCalendarEdit.message.selectServiceUnitFirst'),
            selectShiftClass: i18n.t('shiftCalendarEdit.message.selectShiftClassFirst'),
          },
        },
        noDataText: i18n.t('common.select.empty.text'),
      }
    })
    const calendarContext = computed(() => calendarConfigs[i18n.locale] || calendarConfigs.default)
    // => 選擇服務人員 > 更新顯示人員班表
    const changeServiceUnit = async (v) => {
      loading.value = true
      updateUnitSchedule(v)
      getUnitDisplaySchedule({
        serviceUnitId: get(editContext.serviceUnit, 'id'),
        unitSchedule: unitSchedule.value,
      })
      refreshCalendarEvents({
        unitSchedule: displayUnitSchedule.value,
        shopShiftClassList: shopShiftClassList.value,
      })
      loading.value = false
    }

    // => 切換月曆時間
    const changeCalendarRange = async () => {
      const prevDayStart = get(calendarRangeString.value, 'dayStart')
      const { start, end } = getCalendarDate(calendarRef.value.getApi())
      calendarRange.start = start
      calendarRange.end = end
      const currentDayStart = get(calendarRangeString.value, 'dayStart')
      if (prevDayStart === currentDayStart) return
      await getShopShiftsSchdule({ dateRange: calendarRangeString.value })

      if (!editContext.serviceUnit) return

      updateUnitSchedule(editContext.serviceUnit)

      getUnitDisplaySchedule({
        serviceUnitId: get(editContext.serviceUnit, 'id'),
        unitSchedule: unitSchedule.value,
      })
      refreshCalendarEvents({
        unitSchedule: displayUnitSchedule.value,
        shopShiftClassList: shopShiftClassList.value,
      })
    }

    // => 新增排班
    const onCreate = () => {
      if (!editContext.serviceUnit) {
        window.$message.warning(displayData.value.notify.warning.selectServiceUnit)
        return
      }
      modal.selectEditMode = true
    }

    // => 編輯模式改變
    const onEditModeChange = (mode) => {
      editContext.mode = mode
    }

    // => 清除編輯緩存
    const clearEditCache = () => {
      cachedEditUnitSchedule.value = {}
    }

    // => 取消編輯
    const onCancelEdit = () => {
      if (!useBatchCreateSchedule.value) {
        router.push({
          name: 'ShiftsCalendarSetting',
        })
        return
      }
      editContext.mode = 'preview'
      clearEditCache()
      getUnitDisplaySchedule({
        serviceUnitId: get(editContext.serviceUnit, 'id'),
        unitSchedule: unitSchedule.value,
      })
      refreshCalendarEvents({
        unitSchedule: displayUnitSchedule.value,
        shopShiftClassList: shopShiftClassList.value,
      })
    }

    // => 刷新
    const refresh = async () => {
      loading.value = true
      clearEditCache()
      await getShopShiftsSchdule({ dateRange: calendarRangeString.value })
      await nextTick()
      await changeServiceUnit(editContext.serviceUnit)
      if (useBatchCreateSchedule.value && editContext.mode === 'sketch') {
        editContext.mode = 'preview'
      }
    }

    // => 重建當前行事曆所有事件
    const refreshCalendarEvents = ({ unitSchedule, shopShiftClassList }) => {
      shiftCalendar.value.events = []
      for (const item of unitSchedule) {
        const shiftClass = find(shopShiftClassList, { id: item.appointmentScheduleDayId })
        const shiftClasstimesConfig = get(shiftClass, 'AppointmentScheduleDayTimes') || []
        for (const time of shiftClasstimesConfig) {
          const newEventData = createCalendarEvent({
            title: get(shiftClass, 'name'),
            day: item.day,
            timeStart: time.start,
            timeEnd: time.end,
            props: item,
            color: get(shiftClass, 'color'),
            className: `${item.day}-${get(shiftClass, 'name')}`,
          })
          updateCalendarSignleDayEvent({
            mode: 'update',
            date: item.day,
            newEventData,
          })
        }
      }
    }

    // => 更新單筆行事曆事件
    const updateCalendarSignleDayEvent = ({ mode, date, newEventData }) => {
      let events = cloneDeep(shiftCalendar.value.events)
      const isExist = find(events, { date })
      if (mode === 'remove') {
        events = filter(events, (item) => item.date !== date)
      } else {
        if (isExist) events.splice(indexOf(isExist, 1))
        events.push(newEventData)
      }
      shiftCalendar.value.events = events
    }

    // => 點擊行事曆單天空白處
    const onDateClick = (date) => {
      date.jsEvent.preventDefault()
      if (editContext.mode === 'preview') return
      editContext.selectDate = date
      if (!editContext.shiftClass) {
        window.$message.warning(displayData.value.notify.warning.selectShiftClass)
        return
      } else if (!editContext.serviceUnit) {
        window.$message.warning(displayData.value.notify.warning.selectServiceUnit)
        return
      } else if (editContext.mode === 'batch') {
        modal.batchCreate = true
        return
      }

      localDebounce(date.dateStr, () => {
        const day = date.dateStr
        const serviceUnitId = editContext.serviceUnit.id
        const shiftClassId = editContext.shiftClass.id

        const unitCachedEditSchedule = get(cachedEditUnitSchedule.value, serviceUnitId)

        const isExist = find(unitCachedEditSchedule, { day })

        if (!isExist) {
          // 當日尚無排班 -> 新增
          if (!get(cachedEditUnitSchedule.value, serviceUnitId)) set(cachedEditUnitSchedule.value, serviceUnitId, [])
          const newDataList = passValue(get(cachedEditUnitSchedule.value, serviceUnitId))
          newDataList.push({
            day,
            appointmentScheduleDayId: shiftClassId,
          })
          set(cachedEditUnitSchedule.value, serviceUnitId, newDataList)
        } else if (isExist.appointmentScheduleDayId !== shiftClassId) {
          // 當日已存在不同該班 -> 覆蓋
          set(cachedEditUnitSchedule.value[serviceUnitId], indexOf(cachedEditUnitSchedule.value[serviceUnitId], isExist), {
            day,
            appointmentScheduleDayId: shiftClassId,
          })
        }

        getUnitDisplaySchedule({
          serviceUnitId: serviceUnitId,
          unitSchedule: unitSchedule.value,
        })
        refreshCalendarEvents({
          unitSchedule: displayUnitSchedule.value,
          shopShiftClassList: shopShiftClassList.value,
        })
      })
    }

    // => 點擊行事曆事件
    const onEventClick = (e) => {
      e.jsEvent.preventDefault()
      const eventProps = get(e, 'event.extendedProps')
      if (editContext.mode === 'preview' | editContext.mode === 'batch') {
        editContext.selectEvent = eventProps
        modal.batchDelete = true
        return
      }
      localDebounce(get(eventProps, 'day'), () => {
        const contextServiceUnitId = get(editContext.serviceUnit, 'id')
        const date = get(eventProps, 'day')
        const isEditDayExist = find(get(cachedEditUnitSchedule.value, contextServiceUnitId), { day: date })
        const isDayExist = find(get(unitSchedule.value, contextServiceUnitId), (i) => dayjs(i.day).format('YYYY-MM-DD') === date)

        if (isEditDayExist) {
          // 移除編輯班別緩存
          updateUnitCachedEditSchedule({
            mode: 'remove',
            serviceUnitId: get(eventProps, 'appointmentUnitId'),
            shiftClassId: get(eventProps, 'appointmentScheduleDayId'),
            date,
          })
        }
        // 當天已有班別 -> 移除排班
        if (isDayExist) {
          // 標記移除班別
          updateUnitCachedEditSchedule({
            mode: 'remove-update',
            serviceUnitId: get(eventProps, 'appointmentUnitId'),
            shiftClassId: get(eventProps, 'appointmentScheduleDayId'),
            date,
          })
        }

        getUnitDisplaySchedule({
          serviceUnitId: get(editContext.serviceUnit, 'id'),
          unitSchedule: unitSchedule.value,
        })
        refreshCalendarEvents({
          unitSchedule: displayUnitSchedule.value,
          shopShiftClassList: shopShiftClassList.value,
        })
      })
    }

    // =>行事曆設定資料
    const shiftCalendar = ref({
      ...shiftCalendarConfig,
      eventDidMount: (e) => {
        if (e.el)e.el.setAttribute('data-testid', `calendar-event-${e.event?.title}`)
      },
      customButtons: {
        prev: {
          click: () => {
            calendarRef.value.getApi().prev()
            changeCalendarRange()
          },
        },
        next: {
          click: () => {
            calendarRef.value.getApi().next()
            changeCalendarRange()
          },
        },
        today: {
          text: calendarContext.value.buttonText.today,
          click: () => {
            calendarRef.value.getApi().today()
            changeCalendarRange()
          },
        },
      },
      dateClick: onDateClick,
      eventClick: onEventClick,
    })
    const showCalendar = computed(() => shiftCalendar.value)
    watch(
      () => i18n.locale,
      (newLocale) => {
        // TODO: today button not update
        shiftCalendar.value.locale = calendarConfigs[newLocale]?.code || calendarConfigs.default.code;
        // console.log('localeLLL', calendarContext.value, shiftCalendar.value.locale)
      },
      { immediate: true }
    );
    // => 取得服務人員
    const findServiceUnit = async (id) => {
      if (!id) return
      await simpleFetchOld(FindServiceUnit, {
        shopId: shopId.value,
        id,
      }, (res) => {
        editContext.serviceUnit = res
      })
    }

    // => 儲存編輯排班
    const saveEditSchedule = async () => {
      loading.value = true
      await updateShopShiftsSchedule(submitEditedSchedule.value)
      await refresh()
      loading.value = false
    }

    onBeforeMount(() => {
      if (!useBatchCreateSchedule.value) {
        editContext.mode = 'sketch'
      }
    })

    onMounted(async () => {
      const queryDate = get(route.query, 'date')
      const queryUnit = get(route.query, 'unit')
      if (queryDate) {
        shiftCalendar.value.initialDate = new Date(queryDate)
      } else shiftCalendar.value.initialDate = new Date()

      await Promise.all([
        getShopShiftsClass(),
        getShopShiftsSchdule({ dateRange: calendarRangeString.value }),
        simpleFetch(GetShopBusinessTimeConfig, { shopId: shopId.value }, (res) => {
          shopBusinessTimeConfig.value = res
        }),
      ])

      if (queryUnit) {
        await findServiceUnit(queryUnit)
        await changeServiceUnit(editContext.serviceUnit)
      }
      // if (useBatchCreateSchedule.value) {
      //   editContext.mode = 'preview'
      // }

      window.gotoDate = (date) => {
        const calendarApi = calendarRef.value.getApi()
        calendarApi.gotoDate(date)
        changeCalendarRange()
      }
    })

    onUnmounted(() => {
      delete window.gotoDate
    })

    return {
      show,
      shiftCalendar,
      refresh,
      displayUnitSchedule,
      shopId,
      search,
      loading,
      calendarRange,
      calendarRangeString,
      getCalendarDate,
      unitSchedule,
      shopSchedule,
      shopShiftClassList,
      getShopShiftsSchdule,
      changeCalendarRange,
      changeServiceUnit,
      calendarRef,
      createCalendarEvent,
      updateShopShiftsSchedule,
      shopBusinessTimeConfig,
      modal,
      editContext,
      onCreate,
      onEditModeChange,
      onCancelEdit,
      cachedEditUnitSchedule,
      submitEditedSchedule,
      saveEditSchedule,
      displayData,
      useBatchCreateSchedule,
      showCalendar,
      calendarConfigs,
    }
  },
  computed: {
    sidebar () { return this.$store.state.app.sidebar },
  },

  watch: {
    sidebar () {
      // const api = this.$refs.calendarRef.getApi()
      // api.render()
      this.show = false
      setTimeout(() => {
        this.show = true
      }, 300)
    },
  },
}
</script>

<style scoped lang="postcss">

::v-deep .fc .fc-scroller-liquid-absolute {
  display: block;
  position:  unset !important;
  overflow: hidden !important;
  /* height: 100%; */
}

.edit-shift-calendar {
  @apply pb-[200px] mb-[200px] h-full flex flex-col;
}

.page-footer {

  box-shadow: 0px -4px 8px rgba(47, 32, 27, 0.04);
  padding: 15px 60px !important;
  /* background: white; */
  @apply fixed flex justify-end bottom-0 left-0 right-0 gap-[30px];
  @apply bg-white z-[5];
}

.controls-container {
  @apply flex gap-[24px] ;
}

@media screen and (max-width:1024px){
 .controls-container {
  @apply flex-wrap gap-0;
}
}
</style>
