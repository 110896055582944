<template>
  <div class="point-setting">
    <PageTitle
      title="點數模組參數設定"
      icon="chevron_left"
      style="padding-bottom: 24px"
      hideBtn
      @iconClick="$router.push({ name: 'Parameters' })"
    />
    <!-- <PointExpirySetting /> -->
    <PointNameSetting />
    <PointList />
  </div>
</template>

<script>
import { defineComponent, ref, computed, onMounted } from 'vue'
import { get } from 'lodash'
import PageTitle from '@/components/Title/PageTitle.vue'
// import PointExpirySetting from './components/PointExpirySetting.vue'
import PointNameSetting from './components/PointNameSetting.vue'
import { FindShopPoint } from '@/api/shopPoint'
import store from '@/store'
import PointList from './components/PointList.vue'

export default defineComponent({
  name: 'pointSettings',
  components: {
    PageTitle,
    // PointExpirySetting,
    PointNameSetting,
    PointList,
  },
  setup () {
    const loading = ref(false)
    const shopId = computed(() => store.getters.shop)
    const shopPoint = ref(null)

    const findShopPoint = async () => {
      loading.value = true
      const [res, err] = await FindShopPoint({ shopId: shopId.value })
      loading.value = false
      if (err) {
        window.$message.error(err)
        return
      }
      shopPoint.value = res
    }

    onMounted(async () => {
      loading.value = true
      await findShopPoint()
      loading.value = false
    })

    return {
      get,
      loading,
      shopPoint,
    }
  },
})
</script>
