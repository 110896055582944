import { admin2 } from './instance'
// 匯出會員資料
export const CreateMemberSheetExportTask = async ({
  shopId,
  name,
  phone,
  birthdayMonth,
  mTagId,
}) => {
  return admin2({
    method: 'POST',
    url: `/${shopId}/member/createSheetExportTask`,
    data: {
      input: {
        name,
        phone,
        birthdayMonth,
        mTagId,
      },
    },
  })
}
// 匯出堂票新增紀錄
export const CreateClassTicketRecordSheetExportTask = async ({
  shopId,
  code,
  MemberId,
  ClassTicketId,
  createdAtStart,
  createdAtEnd,
}) => {
  return admin2({
    method: 'POST',
    url: `/${shopId}/classTicketRecord/createSheetExportTask`,
    data: {
      input: {
        code,
        MemberId,
        ClassTicketId,
        createdAtStart,
        createdAtEnd,
      },
    },
  })
}
// 匯出堂票使用紀錄
export const CreateClassTicketRecordUseSheetExportTask = async ({
  shopId,
  code,
  MemberId,
  ClassTicketId,
  createdAtStart,
  createdAtEnd,
}) => {
  return admin2({
    method: 'POST',
    url: `/${shopId}/classTicketRecordUse/createSheetExportTask`,
    data: {
      input: {
        code,
        MemberId,
        ClassTicketId,
        createdAtStart,
        createdAtEnd,
      },
    },
  })
}
// 匯出商城訂單紀錄
export const CreateMemberStoreOrderSheetExportTask = async ({
  shopId,
  MemberId,
  code,
  status,
  paymentStatus,
  createdAtStart,
  createdAtEnd,
  customFlowNodeId,
}) => {
  return admin2({
    method: 'POST',
    url: `/${shopId}/memberStoreOrder/createSheetExportTask`,
    data: {
      input: {
        MemberId,
        code,
        status,
        paymentStatus,
        createdAtStart,
        createdAtEnd,
        customFlowNodeId,
      },
    },
  })
}
// 匯出點數兌換紀錄
export const CreateMemberStoreOrderPointExchangeSheetExportTask = async ({
  shopId,
  MemberId,
  code,
  status,
  paymentStatus,
  createdAtStart,
  createdAtEnd,
  customFlowNodeId,
}) => {
  return admin2({
    method: 'POST',
    url: `/${shopId}/memberStoreOrder/createSheetExportTaskPointBasic`,
    data: {
      input: {
        MemberId,
        code,
        status,
        paymentStatus,
        createdAtStart,
        createdAtEnd,
        customFlowNodeId,
      },
    },
  })
}
// 匯出點數報表
export const CreateShopPointReportSheetExportTask = async ({
  shopId,
  createdAtStart,
  createdAtEnd,
  shopPointKey,
}) => {
  return admin2({
    method: 'POST',
    url: `/${shopId}/shopPointReport/createSheetExportTask`,
    data: {
      input: {
        createdAtStart,
        createdAtEnd,
        shopPointKey,
      },
    },
  })
}
// 匯出回饋金報表紀錄
export const CreateShopCashbackReportSheetExportTask = async ({
  shopId,
  createdAtStart,
  createdAtEnd,
  origin,
  memberId,
}) => {
  return admin2({
    method: 'POST',
    url: `/${shopId}/shopCashbackReport/createSheetExportTask`,
    data: {
      input: {
        createdAtStart,
        createdAtEnd,
        origin,
        memberId,
      },
    },
  })
}
// 匯出第三方消費紀錄
export const CreateMemberExternalTransactionSheetExportTask = async ({
  shopId,
  source,
  transactionId,
  transactionTimeStart,
  transactionTimeEnd,
  customerPhone,
}) => {
  return admin2({
    method: 'POST',
    url: `/${shopId}/memberExternalTransaction/createSheetExportTask`,
    data: {
      input: {
        source,
        transactionId,
        transactionTimeStart,
        transactionTimeEnd,
        customerPhone,
      },
    },
  })
}
// 匯出兌換券設定兌換碼管理紀錄
export const CreateCouponExchangeSerialSheetExportTask = async ({
  shopId,
  CouponId,
}) => {
  return admin2({
    method: 'POST',
    url: `/${shopId}/couponExchangeSerial/createSheetExportTask`,
    data: {
      input: {
        CouponId,
      },
    },
  })
}
// 匯出儲值金報表紀錄
export const CreateWalletRecordSheetExportTask = async ({
  shopId,
  phone,
  noteCodeType,
  creatorName,
  createdAtStart,
  createdAtEnd,
}) => {
  return admin2({
    method: 'POST',
    url: `/${shopId}/walletRecord/createSheetExportTask`,
    data: {
      input: {
        phone,
        noteCodeType,
        creatorName,
        createdAtStart,
        createdAtEnd,
      },
    },
  })
}
// 匯出序號登錄活動活動序號管理紀錄
export const CreateSerialActivityCodeSheetExportTask = async ({
  shopId,
  SerialActivityId,
}) => {
  return admin2({
    method: 'POST',
    url: `/${shopId}/serialActivityCode/createSheetExportTask`,
    data: {
      input: {
        SerialActivityId,
      },
    },
  })
}
// 匯出訂閱紀錄
export const CreateSubscriptionOrderSheetExportTask = async ({
  shopId,
  status,
  hasFailRecord,
  planName,
  equityPackName,
  memberPhone,
  memberName,
}) => {
  return admin2({
    method: 'POST',
    url: `/${shopId}/subscriptionOrder/createSheetExportTask`,
    data: {
      input: {
        status,
        hasFailRecord,
        planName,
        equityPackName,
        memberPhone,
        memberName,
      },
    },
  })
}
// // 匯出事件行銷發放報表
// export const CreateEventPlaybookSheetExportTask = async ({
//   shopId,
//   createdAtStart,
//   createdAtEnd,
// }) => {
//   return admin2({
//     method: 'POST',
//     url: `/${shopId}/eventPlaybookAwardRecord/createSheetExportTask`,
//     data: {
//       input: {
//         createdAtStart,
//         createdAtEnd,
//       },
//     },
//   })
// }
// 匯出抽獎紀錄
export const CreateMemberGameRecordSheetExportTask = async ({
  shopId,
  MemberGameId,
  createdAtStart,
  createdAtEnd,
}) => {
  return admin2({
    method: 'POST',
    url: `/${shopId}/memberGameRecord/createSheetExportTask`,
    data: {
      input: {
        MemberGameId,
        createdAtStart,
        createdAtEnd,
      },
    },
  })
}
// 匯出消費登錄連結紀錄
export const CreateSalesClientLinkSheetExportTask = async ({
  shopId,
  branchName,
}) => {
  return admin2({
    method: 'POST',
    url: `/${shopId}/salesClientLink/createSheetExportTask`,
    data: {
      input: {
        branchName,
      },
    },
  })
}
// 匯出電商模組訂單記錄
export const CreateEcommerceOrderSheetExportTask = async ({
  shopId,
  code,
  status,
  MemberId,
  EcommerceDeliveryId,
  createdAtStart,
  createdAtEnd,
  customFlowNodeId,
}) => {
  return admin2({
    method: 'POST',
    url: `/${shopId}/ecommerceOrder/createSheetExportTask`,
    data: {
      input: {
        code,
        status,
        MemberId,
        EcommerceDeliveryId,
        createdAtStart,
        createdAtEnd,
        customFlowNodeId,
      },
    },
  })
}
// 匯出票券新增紀錄
export const CreateCouponRecordCreateSheetExportTask = async ({
  shopId,
  MemberId,
  status,
  dateRangeType,
  startAt,
  endAt,
  code,
  exchangeCode,
  type,
}) => {
  return admin2({
    method: 'POST',
    url: `/${shopId}/couponRecord/createSheetExportTaskCreatedBasic`,
    data: {
      input: {
        MemberId,
        status,
        dateRangeType,
        startAt,
        endAt,
        code,
        exchangeCode,
        type,
      },
    },
  })
}
// 匯出票券使用紀錄
export const CreateCouponRecordUseSheetExportTask = async ({
  shopId,
  MemberId,
  status,
  dateRangeType,
  startAt,
  endAt,
  code,
  exchangeCode,
  type,
}) => {
  return admin2({
    method: 'POST',
    url: `/${shopId}/couponRecord/createSheetExportTaskUsedBasic`,
    data: {
      input: {
        MemberId,
        status,
        dateRangeType,
        startAt,
        endAt,
        code,
        exchangeCode,
        type,
      },
    },
  })
}
// 匯出跨境預約訪客人次紀錄
export const CreateAppointmentVisitorPreOrderSheetExportTask = async ({
  shopId,
  visitorEmail,
  visitorName,
  hasVisitorAuthGoogle,
  regionCode,
  createdAtStart,
  createdAtEnd,
}) => {
  return admin2({
    method: 'POST',
    url: `/${shopId}/appointmentVisitorPreOrder/createSheetExportTask`,
    data: {
      input: {
        visitorEmail,
        visitorName,
        hasVisitorAuthGoogle,
        regionCode,
        createdAtStart,
        createdAtEnd,
      },
    },
  })
}
// 匯出預約紀錄
export const CreateAppointmentReservationSheetExportTask = async ({
  shopId,
  dateStart,
  dateEnd,
  code,
  userName,
  AppointmentUnitId,
  ResourceItemId,
  customFlowNodeId,
}) => {
  return admin2({
    method: 'POST',
    url: `/${shopId}/appointmentReservation/createSheetExportTask`,
    data: {
      input: {
        dateStart,
        dateEnd,
        code,
        userName,
        AppointmentUnitId,
        ResourceItemId,
        customFlowNodeId,
      },
    },
  })
}
// 匯出次卡新增紀錄
export const CreatePunchCardRecordSheetExportTask = async ({
  shopId,
}) => {
  return admin2({
    method: 'POST',
    url: `/${shopId}/punchCardRecord/createSheetExportTask`,
    data: {}, // TODO: 先傳空物件
  })
}
// 匯出次卡使用紀錄
export const CreatePunchCardRecordUseSheetExportTask = async ({
  shopId,
}) => {
  return admin2({
    method: 'POST',
    url: `/${shopId}/punchCardRecord/createSheetExportTaskUsageLog`,
    data: {}, // TODO: 先傳空物件
  })
}
// 匯出點數卡新增紀錄
export const CreatePointCardRecordSheetExportTask = async ({
  shopId,
}) => {
  return admin2({
    method: 'POST',
    url: `/${shopId}/pointCardRecord/createSheetExportTask`,
    data: {}, // TODO: 先傳空物件
  })
}
// 匯出點數卡使用紀錄
export const CreatePointCardRecordUseSheetExportTask = async ({
  shopId,
}) => {
  return admin2({
    method: 'POST',
    url: `/${shopId}/pointCardRecord/createSheetExportTaskUsageLog`,
    data: {}, // TODO: 先傳空物件
  })
}
// 匯出事件行銷發放報表
export const CreateEventPlaybookAwardRecordSheetExportTask = async ({
  shopId,
  MemberId,
  isSend,
  errorCode,
  eventTemplateConfigSource,
  eventTemplateConfigId,
  awardType,
  awardId,
  createdAtStart,
  createdAtEnd,
  sortBy,
}) => {
  return admin2({
    method: 'POST',
    url: `/${shopId}/eventPlaybookAwardRecord/createSheetExportTask`,
    data: {
      input: {
        MemberId,
        isSend,
        errorCode,
        eventTemplateConfigSource,
        eventTemplateConfigId,
        awardType,
        awardId,
        createdAtStart,
        createdAtEnd,
        sortBy,
      },
    },
  })
}
// 匯出銷售人員報表
export const CreateSalesUnitReportSheetExportTask = async ({
  shopId,
  salesUnitIds,
  createdAtStart,
  createdAtEnd,
  deductWallet,
}) => {
  return admin2({
    method: 'POST',
    url: `/${shopId}/salesUnitReport/createSheetExportTask`,
    data: {
      input: {
        salesUnitIds,
        createdAtStart,
        createdAtEnd,
        deductWallet,
      },
    },
  })
}
