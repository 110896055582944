<template>
  <section v-loading="loading" class="section-block">
    <SectionTitle title="刷卡機（ezAIO）串接設定" @edit="modal.edit = true" />
    <div>
      <InfoList
        label-width="200px"
        label-position="left"
        :displayData="displayData"
      />
    </div>

    <EditEZAIOSettingModal
      v-if="modal.edit"
      :configData="configData"
      @close="modal.edit = false"
      @refresh="refresh"
    />
  </section>
</template>

<script>
import InfoList from '@/components/InfoList.vue'
import SectionTitle from '@/components/Title/SectionTitle.vue'
import { computed, defineComponent, onBeforeMount, reactive, ref } from 'vue'
import EditEZAIOSettingModal from './components/EditEZAIOSettingModal.vue'
import { FindSalesPaymentConfig } from '@/api/sales'
import { useShop } from '@/use/shop'
import { get } from 'lodash'

export default defineComponent({
  name: 'EZAIOSettingBlock',
  components: {
    SectionTitle,
    InfoList,
    EditEZAIOSettingModal,
  },
  props: {
  },
  setup (props, { emit }) {
    const { shopId } = useShop()
    const loading = ref(false)
    const modal = reactive({
      edit: false,
    })
    const configData = ref({})

    const displayData = computed(() => {
      const ezAIOConfig = get(configData.value, 'config.ezAIOTrustConfig')
      return [
        {
          label: '啟用刷卡機串接',
          value: configData.value.enable ? '是' : '否',
        },
        {
          label: 'AIO_MerchantID',
          value: get(ezAIOConfig, 'merchantID', '-'),
        },
        {
          label: 'Newebpay_MerchantID',
          value: get(ezAIOConfig, 'newebpayMerchantID', '-'),
        },
      ]
    })

    const refresh = async () => {
      if (loading.value) return
      loading.value = true
      const [res, err] = await FindSalesPaymentConfig({
        shopId: shopId.value,
        type: 'ezAIOTrust',
      })
      loading.value = false
      if (err) {
        window.$message.error(err)
        return
      }

      configData.value = res
    }

    onBeforeMount(async () => {
      refresh()
    })

    return { modal, configData, displayData, refresh, loading }
  },
})

</script>

<style lang="postcss" scoped>
</style>
