<template>
  <section class="section-block">
    <SectionTitle :title="$t('reservationCustomTextSettings.title')" :btn="$t('common.button.edit.text')" @edit="modal.editTheme = true" :hideBtn="isJapanShop"/>
    <div v-if="!isJapanShop">
      <BaseElForm label-position="left" label-width="250px">
        <BaseElFormItem :label="$t('reservationCustomTextSettings.style.title')" class="settings-item">
          <span class="content">
            {{ pageStyleName[configData.pageStyle] || '-' }}
          </span>
        </BaseElFormItem>
      </BaseElForm>
    </div>
    <div>
      <div class="mb-4"><span>{{ $t('reservationCustomTextSettings.content.title') }}</span></div>
      <!-- ANCHOR 預約文字表格 -->
      <BaseTable :data="pageTextTable" testName="樣式與標題設定">
        <BaseElTableColumn prop="name" :label="$t('reservationCustomTextSettings.table.default.title')" align="center">
          <template slot-scope="scope">
            <div>
              {{ scope.row.defaultValue }}
            </div>
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn prop="name" :label="$t('reservationCustomTextSettings.table.custom.title')" align="center">
          <template slot-scope="scope">
            <div>
              {{ scope.row.value }}
            </div>
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn prop="name" :label="$t('common.table.action.text')" align="center">
          <template slot-scope="scope">
            <div class="table-edit-btn-group" :data-testid="`row-${scope.$index}`">
              <BaseElButton type="text" class="table-opt-edit" @click="onEditPageText(scope.row.key)">
                {{ $t('common.button.edit.text')}}
              </BaseElButton>
            </div>
          </template>
        </BaseElTableColumn>
      </BaseTable>
    </div>
    <EditReservationThemeModal
      v-if="modal.editTheme"
      :configData="configData"
      @close="modal.editTheme = false"
      @refresh="$emit('refresh')"
    />
    <EditReservationCustomTextModal
      v-if="modal.editText"
      :configData="configData"
      :editTextKey="editTextKey"
      @close="modal.editText = false"
      @refresh="$emit('refresh')"
    />
  </section>
</template>

<script>
import { defineComponent, computed, ref, reactive } from 'vue'
import { pageStyleName, pageTextDefault } from '@/config/reservation'
import { get, map, omitBy } from 'lodash'
import EditReservationThemeModal from './EditReservationThemeModal.vue'
import EditReservationCustomTextModal from './EditReservationCustomTextModal.vue'

export default defineComponent({
  name: 'ReservationCustomTextSettings',
  components: { EditReservationThemeModal, EditReservationCustomTextModal },
  props: {
    configData: { type: Object, default: () => ({}) },
    isJapanShop: { type: Boolean, default: false },
  },
  setup (props, { emit }) {
    const editTextKey = ref('')
    const modal = reactive({
      editText: false,
      editTheme: false,
    })
    const pageTextTable = computed(() => {
      const pageText = get(props.configData, 'pageText') || {}
      const omitList = []
      if (props.isJapanShop) omitList.push('APPOINTMENT_SUB_SERVICE')
      const pageTextOmit = omitBy(pageTextDefault, (item) => omitList.includes(item.key))
      return map(pageTextOmit, (item) => {
        return {
          key: item.key,
          defaultValue: item.default,
          value: pageText[item.key] || '-',
        }
      })
    })

    const onEditPageText = (textKey) => {
      editTextKey.value = textKey
      modal.editText = true
    }

    return { pageStyleName, pageTextTable, editTextKey, onEditPageText, modal }
  },
})
</script>

<style lang="postcss" scoped></style>
