<template>
  <div v-if="displayData">
    <BaseElForm label-position="left" label-width="150px">
      <BaseElFormItem :label="$t('checkInBlock.date.title')">
        {{ displayData.date }}
      </BaseElFormItem>
      <BaseElFormItem :label="$t('checkInBlock.branch.title')">
        {{ displayData.branchName }}
      </BaseElFormItem>
    </BaseElForm>
  </div>
</template>

<script>
import { defineComponent, computed } from 'vue'
import { get } from 'lodash'
import { formatDate } from '@/utils/date'

export default defineComponent({
  name: 'CheckInBlock',
  props: {
    order: { type: Object, default: () => ({}) },
  },
  setup (props, { emit }) {
    const displayData = computed(() => {
      return {
        date: get(props.order, 'AppointmentOrderCheckIn') ? formatDate(get(props.order, 'AppointmentOrderCheckIn.checkInAt')) : '-',
        branchName: get(props.order, 'AppointmentOrderCheckIn') ? get(props.order, 'AppointmentOrderCheckIn.Branch.name') : '-',
      }
    })

    return {
      displayData,
    }
  },
})

</script>

<style lang="postcss" scoped>

</style>
