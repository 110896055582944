<template>
  <el-dialog
    :visible="true"
    v-bind="$attrs"
    :title="title"
    :width="width"
    :close-on-click-modal="closeOnClickModal"
    v-on="$listeners"
    @close="$emit('close')"
  >
    <div>
      <slot />
    </div>

    <slot name="btnContainer">
      <div class="flex justify-end items-center">
        <BaseElButton v-if="!hideCancel" v-loading="btn2Loading" :disabled="btn2Disabled" plain @click="$emit('cancel'), $emit('close')">
          {{ btn2 || '取消' }}
        </BaseElButton>
        <BaseElButton v-if="!hideConfirm" v-loading="btn1Loading" :disabled="btn1Disabled" type="primary" @click="$emit('confirm')">
          {{ btn1 || '確認' }}
        </BaseElButton>
      </div>
    </slot>
  </el-dialog>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'BaseDialog',
  props: {
    title: { type: String, default: '' },
    btn1: { type: String, default: '' },
    btn2: { type: String, default: '' },
    btn1Loading: { type: Boolean, default: false },
    btn2Loading: { type: Boolean, default: false },
    btn1Disabled: { type: Boolean, default: false },
    btn2Disabled: { type: Boolean, default: false },
    hideConfirm: { type: Boolean, default: false },
    hideCancel: { type: Boolean, default: false },
    width: { type: String, default: 'fit-content' },
    closeOnClickModal: { type: Boolean, default: true },
  },
})
</script>

<style scoped lang="scss">

</style>
