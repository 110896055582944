<template>
  <el-popover
    v-model="visible"
    placement="bottom"
    trigger="click"
    @show="onShow"
  >
    <div style="padding: 12px">
      <div class="flex justify-between">
        <p class="text-primary-100 font-medium" style="font-size: 16px">{{ $t('tableFilter.button.columnManagement.text')}}</p>
        <div @click="visible=false">
          <MaterialIcon size="16" pointer>close_round</MaterialIcon>
        </div>
      </div>
      <div class="flex justify-between items-center" style="margin-top: 20px">
        <BaseElCheckbox v-model="checkAll" @change="handleCheckAllChange">{{ $t('tableFilter.selectAll.text')}}</BaseElCheckbox>
        <BaseElButton class="clear-btn" type="text" @click="handleClear">{{ $t('tableFilter.reset.text')}}</BaseElButton>
      </div>
      <hr style="margin: 6px 0">
      <BaseElCheckboxGroup v-model="checked" @change="handleCheckedCitiesChange">
        <div class="options-container">
          <BaseElCheckbox
            v-for="option in options"
            :key="option.value"
            :label="option.value"
          >
            {{ option.label }}
          </BaseElCheckbox>
        </div>
      </BaseElCheckboxGroup>
      <div class="flex justify-end" style="margin-top: 10px">
        <BaseElButton plain @click="handleConfrim">{{ $t('tableFilter.confirm.text')}}</BaseElButton>
      </div>
    </div>
    <BaseElButton slot="reference" class="pop-btn">
      <div class="flex">
        <img :src="toggleIcon">
        <span style="margin-left: 6px">{{ $t('tableFilter.button.columnManagement.text')}}</span>
      </div>
    </BaseElButton>
  </el-popover>
</template>

<script>
import toggleIcon from '@/assets/icon/toggle.svg'
import { map } from 'lodash'
import { updateTableFilterConfig, getTableFilterConfig } from '@/utils/tableFilter'
import { defineComponent } from 'vue'
import MaterialIcon from '@/components/MaterialIcon.vue'

export default defineComponent({
  name: 'TableFilter',
  components: { MaterialIcon },
  props: {
    tableName: {
      type: String,
      default: '',
    },
    options: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    toggleIcon,
    visible: false,
    checked: [],
    checkAll: false,
    // isIndeterminate: false,
  }),
  methods: {
    onShow () {
      this.checked = getTableFilterConfig(this.tableName) || []
      this.checkAll = this.checked.length === this.options.length
    },
    handleCheckAllChange (val) {
      this.checked = val ? map(this.options, 'value') : []
      // this.isIndeterminate = false
    },
    handleCheckedCitiesChange (value) {
      const checkedCount = value.length
      this.checkAll = checkedCount === this.options.length
      // this.isIndeterminate = checkedCount > 0 && checkedCount < this.options.length
    },
    handleClear () {
      this.checked = []
      this.checkAll = false
    },
    handleConfrim () {
      updateTableFilterConfig(this.tableName, this.checked)
      this.visible = false
      this.$emit('update', this.checked)
    },
  },
})
</script>

<style lang="postcss" scoped>
::v-deep .el-popover {
  @apply p-[12px] ;
}
.options-container {
  /* @apply flex gap-[8px] flex-wrap; */
  @apply grid grid-cols-2 gap-x-[28px] gap-y-[9px];
  @apply  text-sub leading-[20.27px] ;
}

.clear-btn {
  @apply underline text-normal font-normal text-danger text-sub p-0;
}

.pop-btn {
  margin: 0 !important;
}
</style>
