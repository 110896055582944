import { admin, admin2 } from '@/api/instance'

const baseUrl = (shopId) => {
  return `/${shopId}/appointmentSchedule`
}

//= > 取得班表
export const GetShiftsSchedule = async ({
  shopId,
  start,
  limit,
  dayStart,
  dayEnd,
}) => {
  const res = await admin({
    method: 'get',
    url: baseUrl(shopId),
    params: {
      start,
      limit,
      dayStart,
      dayEnd,
    },
  })

  return res.data
}

//= > 取得班表總數
export const GetShiftsScheduleCount = async ({
  shopId,
  dayStart,
  dayEnd,
}) => {
  const res = await admin({
    method: 'get',
    url: `${baseUrl(shopId)}/count`,
    params: {
      dayStart,
      dayEnd,
    },
  })

  return res
}

//= > 取得指定班表
export const FindShiftsSchedule = async ({
  shopId,
  id,
}) => {
  const res = await admin({
    method: 'get',
    url: `${baseUrl(shopId)}/${id}`,
  })

  return res.data
}

//= > 更新班表
export const UpdateShiftsSchedule = async ({
  shopId,
  data,
}) => {
  const res = await admin({
    method: 'put',
    url: `${baseUrl(shopId)}`,
    data: {
      data,
    },
  })

  return res.data
}

export const GetServiceUnitRangeSchedule = async ({
  shopId,
  appointmentUnitId,
  weeks,
  start,
  end,
}) => {
  return await admin2({
    method: 'POST',
    url: `/${shopId}/appointmentSchedule/checkHasAppointmentScheduleByWeek`,
    data: {
      appointmentUnitId,
      weeks,
      start,
      end,
    },
  })
}

export const BatchUpdateUnitShiftSchedule = async ({
  shopId,
  appointmentUnitId,
  appointmentScheduleDayId,
  weeks,
  start,
  end,
}) => {
  return await admin2({
    method: 'PUT',
    url: `/${shopId}/appointmentSchedule/batchCreateByWeek`,
    data: {
      appointmentUnitId,
      appointmentScheduleDayId,
      weeks,
      start,
      end,
    },
  })
}

export const BatchDeleteUnitShiftSchedule = async ({
  shopId,
  appointmentUnitId,
  appointmentScheduleDayId,
  start,
  end,
}) => {
  return await admin2({
    method: 'PUT',
    url: `/${shopId}/appointmentSchedule/batchDestroyByScheduleDay`,
    data: {
      appointmentUnitId,
      appointmentScheduleDayId,
      start,
      end,
    },
  })
}
