<template>
  <section class="payment-options">
    <div class="cate-title">回饋金</div>
    <div class="option-items">
      <div
        class="item-card"
        :class="{
          select: isUsed,
        }"
        @click="handleUseRebate"
      >
        <span class="name">回饋金折抵</span>
      </div>
    </div>

    <UseRebateDialog
      v-if="dialog.use"
      :balance="rebateWalletBalance"
      :totalPrice="totalPrice"
      @close="dialog.use = false"
      @confirm="handleConfirm"
    />
  </section>
</template>

<script setup>
import UseRebateDialog from './UseRebateDialog.vue'
import { reactive, computed } from 'vue'

const props = defineProps({
  rebateWalletBalance: {
    type: [String, Number],
    default: 0,
  },
  discountStack: {
    type: Array,
    default: () => [],
  },
  totalPrice: {
    type: [String, Number],
    default: 0,
  },
})
const emit = defineEmits(['use', 'unuse'])
const isUsed = computed(() => props.discountStack.find((item) => item.id === 'rebate'))

const dialog = reactive({
  use: false,
})

const handleUseRebate = () => {
  if (isUsed.value) {
    emit('unuse')
    return
  }
  dialog.use = true
}
const handleConfirm = (useAmount) => {
  dialog.use = false
  emit('use', useAmount)
}
</script>

<style scoped lang="scss">
@import '../styles.scss';
</style>
