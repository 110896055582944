<template>
  <div v-loading="loading" class="section-block">
    <SectionTitle title="瑞乘顯示名稱設定" @edit="modal.edit = true" />

    <BaseElForm label-position="left" label-width="300px">
      <BaseElFormItem v-for="field in displayData" :key="field.label" :label="field.label">
        {{ field.value }}
      </BaseElFormItem>
    </BaseElForm>

    <PospalDisplaySettingsModal
      v-if="modal.edit"
      :configData="configData"
      @close="modal.edit = false"
      @refresh="refresh"
    />
  </div>
</template>

<script>
import { ref, computed, defineComponent, reactive, onMounted } from 'vue'
import PospalDisplaySettingsModal from './PospalDisplaySettingsModal.vue'
import { get, find } from 'lodash'
import { useShop } from '@/use/shop'
import { GetCustomModule } from '@/api/customModule'

export default defineComponent({
  name: 'ExternalPointDisplaySettings',
  components: { PospalDisplaySettingsModal },
  setup (props, { emit }) {
    const { shopId } = useShop()
    const loading = ref(false)
    const configData = ref({})
    const modal = reactive({
      edit: false,
    })

    const displayData = computed(() => {
      const customDisplayName = get(configData.value, '[0].customDisplayName', {})
      const posPalMemberTransaction = find(customDisplayName, {label: 'posPalMemberTransaction'})
      const posPalMemberPassProduct = find(customDisplayName, {label: 'posPalMemberPassProduct'})
      return [
        { label: '消費累計顯示名稱', value: get(posPalMemberTransaction, 'displayName', '消費累計') },
        { label: '消費累計頁面是否顯示會員餘額支付金額', value: get(posPalMemberTransaction, 'additionalSetting.displayMemberWalletAmount', true) ? '顯示' : '隱藏' },
        { label: '會員餘額是否顯示 $ 錢字符號', value: get(posPalMemberTransaction, 'additionalSetting.displayMemberWalletAmountIcon', true) ? '顯示' : '隱藏' },
        { label: '消費累計頁面是否顯示次卡支付金額', value: get(posPalMemberTransaction, 'additionalSetting.displayMemberPassProductAmount', true) ? '顯示' : '隱藏' },
        { label: '次卡是否顯示 $ 錢字符號', value: get(posPalMemberTransaction, 'additionalSetting.displayMemberPassProductAmountIcon', true) ? '顯示' : '隱藏' },
        { label: '次卡顯示名稱', value: get(posPalMemberPassProduct, 'displayName', '次卡') },
      ]
    })

    const refresh = async () => {
      loading.value = true
      const [res, err] = await GetCustomModule({
        shopId: shopId.value,
        type: 'posPal',
      })
      loading.value = false
      if (err) {
        window.$message.error(err)
        return
      }
      configData.value = res
      emit('getConfig', find(res, { type: 'posPal' }))
    }

    onMounted(async () => {
      refresh()
    })

    return { modal, displayData, configData, loading, refresh }
  },
})
</script>

<style lang="postcss" scoped>

</style>
