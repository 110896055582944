<template>
  <div class="sales-pos-side-panel-product-item-group">
    <div class="flex items-center" style="gap: 8px; padding-left: 8px">
      <MaterialIcon size="16">person_outline</MaterialIcon>
      <span class="text-sub">{{ data.name }}</span>
    </div>
    <div class="group-content-wrapper">
      <slot />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'SalesPOSSidePanelProductItemGroup',
  props: {
    data: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  setup (props) {
    return {}
  },
})
</script>

<style lang="postcss" scoped>
.sales-pos-side-panel-product-item-group {
@apply flex flex-col gap-[6px];

  .group-content-wrapper {
    @apply bg-white p-[4px] rounded-[6px];
  }
}
</style>
