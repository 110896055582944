<template>
  <div class="other-user">
    <BaseElForm ref="formRef" label-position="top" :model="context.otherUserInfo.formData" :rules="formRules">
      <div v-for="(user, key, index) in context.otherUserInfo.formData" :key="key">
        <BaseElFormItem :label="`其他服務對象姓名 -${index + 1}`" :prop="`${key}.name`">
          <div>
            <BaseElInput
              v-model="context.otherUserInfo.formData[key].name"
              testName="formData_otherUser_name"
            />
            <BaseElButton
              class="delete-btn ml-[12px]"
              type="text"
              @click="removeUser(key)"
            >
              刪除
            </BaseElButton>
          </div>
        </BaseElFormItem>

        <BaseElFormItem :label="`其他服務對象手機號碼 -${index + 1}`" :prop="`${key}.phone`">
          <BaseElInput v-model="context.otherUserInfo.formData[key].phone" testName="formData_otherUser_phone" />
        </BaseElFormItem>
      </div>
    </BaseElForm>

    <div v-if="count < context.peopleCount - 1" class="add-user-btn" @click="addUser">
      <!-- <Icon><AddFilled /></Icon> -->
      <MaterialIcon size="20">add_filled</MaterialIcon>
      <div>新增其他服務對象資料</div>
    </div>
  </div>
</template>

<script>
import { useCreateAappointment } from '@/use/useCreateAppointment'
import { noEmptyRules, phoneRules } from '@/validation'
import { watch, defineComponent, ref, onMounted, set, nextTick, computed } from 'vue'

export default defineComponent({
  name: 'AppointmentOtherUserInfo',
  setup (props, { emit }) {
    const { context } = useCreateAappointment()
    const formRef = ref(null)
    const count = ref(0)
    const formRules = computed(() => {
      const rules = {}
      // context.otherUserInfo.formData
      Object.keys(context.otherUserInfo.formData).forEach((key) => {
        set(rules, `${key}.name`, [noEmptyRules()])
        set(rules, `${key}.phone`, [noEmptyRules(), phoneRules()])
      })
      return rules
    })

    watch(() => context.peopleCount, async (newVal) => {
      if (count.value > newVal - 1) {
        count.value = newVal - 1
        await refreshFormData()
      }
    })

    const addUser = async () => {
      count.value++
      await refreshFormData()
    }

    const removeUser = async (key) => {
      delete context.otherUserInfo.formData[key]
      count.value--
      await refreshFormData()
    }

    const refreshFormData = async () => {
      const cloneForm = JSON.parse(JSON.stringify(context.otherUserInfo.formData))
      const keys = Object.keys(cloneForm)

      // empty context.otherUserInfo.formData object
      Object.keys(context.otherUserInfo.formData).forEach((key) => {
        delete context.otherUserInfo.formData[key]
      })

      for (let index = 0; index < count.value; index++) {
        const exist = keys[index]
        if (exist) {
          set(context.otherUserInfo.formData, `user_${index + 1}`, cloneForm[keys[index]])
        } else {
          set(context.otherUserInfo.formData, `user_${index + 1}`, {
            name: '',
            phone: '',
          })
        }
      }

      await nextTick()
      setTimeout(() => {
        context.otherUserInfo.formRef = formRef.value
      }, 0)
    }

    return {
      context,
      formRef,
      count,
      addUser,
      removeUser,
      formRules,
    }
  },
})
</script>

<style lang="postcss" scoped>
.delete-btn {
  @apply underline text-normal font-normal;
  @apply text-danger;
}

.add-user-btn {
  @apply flex items-center text-primary-100 cursor-pointer mb-[16px];
}
</style>
