<template>
  <section class="section-block settings-section">
    <SectionTitle
      cyBtn="edit-notspecify-parameter"
      :title="$t('reservationNotspecifySettings.title')"
      :tooltip="$t('reservationNotspecifySettings.tooltip')"
      :btn="$t('common.button.edit.text')"
      @edit="modal.edit = true"
    />
    <div>
      <BaseElForm label-position="left" label-width="250px">
        <BaseElFormItem :label="`${$t('reservationNotspecifySettings.useNotSpecify.title')} ：`" class="settings-item">
          <span data-cy="use-notspecify-view" class="content">{{
            configData.useNotSpecify ? $t('common.open.text') : $t('common.close.text')
          }}</span>
        </BaseElFormItem>

        <BaseElFormItem :label="`${$t('reservationNotspecifySettings.notSpecifySkipUnit.title')} ：`" class="settings-item">
          <span data-cy="notspecify-skip-unit-view" class="content">{{
            configData.notSpecifySkipUnit ? $t('common.open.text') : $t('common.close.text')
          }}</span>
        </BaseElFormItem>

        <BaseElFormItem :label="`${$t('reservationNotspecifySettings.notSpecifyOrderRule.title')} ：`" class="settings-item">
          <span class="content">{{
            $t(get(noSpecifyUnitOrderOptionConfig, `${configData.notSpecifyOrderRule}.label`))
          }}</span>
        </BaseElFormItem>

        <BaseElFormItem :label="`${$t('reservationNotspecifySettings.notSpecifyName.title')} ：`" class="settings-item">
          <span data-cy="notspecify-name-view" class="content">{{
            configData.notSpecifyName !== null ? configData.notSpecifyName : $t('common.notSetupYet.text')
          }}</span>
        </BaseElFormItem>

        <BaseElFormItem :label="`${$t('reservationNotspecifySettings.notSpecifyImage.title')} ：`" class="settings-item">
          <!-- <span data-cy="notspecify-img-view" class="content">{{
            configData.NotSpecifyImage ? '已設置' : '尚未設定'
          }}</span> -->
          <img
            width="90px"
            :src="imgSource(configData.NotSpecifyImage)"
            alt=""
          >
        </BaseElFormItem>

        <BaseElFormItem :label="`${$t('reservationNotspecifySettings.notSpecifyUnits.title')} ：`" class="settings-item">
          <span
            data-cy="notspecify-unit-view"
            class="content"
            style="width: 400px; word-wrap: break-word"
          >
            {{ notSpecifyUnits }}
          </span>
        </BaseElFormItem>

        <BaseElFormItem :label="`${$t('reservationNotspecifySettings.notSpecifyRule.title')} ：`" class="settings-item">
          <span data-cy="notspecify-rule-view" class="content">{{
            configData.notSpecifyRule ? $t(notSpecifyRules[configData.notSpecifyRule].name) : $t('common.notSetupYet.text')
          }}</span>
        </BaseElFormItem>
      </BaseElForm>
    </div>

    <EditReservationNotspecifySettingsModal
      v-if="modal.edit"
      data-testid="edit-reservation-notspecify-settings-modal"
      :configData="configData"
      @close="modal.edit = false"
      @refresh="$emit('refresh')"
    />
  </section>
</template>

<script>
import { computed, defineComponent, reactive } from 'vue'
import { noSpecifyUnitOrderOptionConfig } from '@/config/reservation'
import { get } from 'lodash'
import EditReservationNotspecifySettingsModal from './EditReservationNotspecifySettingsModal.vue'
import { imgSrc } from '@/utils/helper'
import { i18n } from '@/plugins/i18n/i18n'

export default defineComponent({
  name: 'ReservationNotspecifySettings',
  components: {
    EditReservationNotspecifySettingsModal,
  },
  props: {
    configData: { type: Object, default: () => ({}) },
  },
  setup (props) {
    const modal = reactive({ edit: false })

    const notSpecifyUnits = computed(() => {
      const list = []
      if (
        !get(props.configData, 'NotSpecifyUnits') ||
        !get(props.configData, 'NotSpecifyUnits.length')
      ) {
        return i18n.t('common.notSetupYet.text')
      }
      props.configData.NotSpecifyUnits.forEach((unit) => {
        list.push(unit.name)
      })
      return list.join('、')
    })

    const notSpecifyRules = {
      order: { id: 'order', name: 'reservationNotspecifySettings.notSpecifyRule.order.title' },
      reservation: { id: 'reservation', name: 'reservationNotspecifySettings.notSpecifyRule.reservation.title' },
      notSpecifyReservation: {
        id: 'notSpecifyReservation',
        name: 'reservationNotspecifySettings.notSpecifyRule.notSpecifyReservation.title',
      },
      random: { id: 'random', name: 'reservationNotspecifySettings.notSpecifyRule.random.title' },
    }
    const imgSource = (image) => {
      return imgSrc(90, image || null)
    }

    return { modal, noSpecifyUnitOrderOptionConfig, notSpecifyUnits, get, notSpecifyRules, imgSource }
  },
})
</script>

<style lang="postcss" scoped></style>
