<template>
  <div class="sales-parameter">
    <section class="section-block">
      <SectionTitle :title="$t('publicAppointment.enableSettings.title')" hideBtn />
      <div>
        <BaseElForm v-model="formData" label-position="left" label-width="250px">
          <BaseElFormItem :label="$t('publicAppointment.enable.title')">
            <BaseElSwitch
              v-model="formData.enable"
              data-cy="allow-order-multi-people-switch"
              class="switch-input"
              :active-text="$t('common.open.text')"
              :inactive-text="$t('common.close.text')"
              @change="updateEnableSetting"
            />
          </BaseElFormItem>
          <p class="text-sub text-gray-60">{{ $t('publicAppointment.enableSettings.info.title')}}</p>
        </BaseElForm>
      </div>
    </section>
  </div>
</template>

<script>
import { defineComponent, onMounted, reactive, watch } from 'vue'
import SectionTitle from '@/components/Title/SectionTitle'
import { get } from 'lodash'
export default defineComponent({
  name: 'EnableSettings',
  components: { SectionTitle },
  props: {
    content: { type: Object, default: () => ({}) },
  },
  setup (props, { emit }) {
    const formData = reactive({
      enable: false,
    })
    const updateEnableSetting = async () => {
      emit('updatePubApt', formData)
    }
    const syncData = async () => {
      formData.enable = JSON.parse(JSON.stringify(get(props, 'content.enable')))
    }
    watch(props, async () => {
      await syncData()
    })
    return {
      formData,
      updateEnableSetting,
    }
  },
})
</script>

<style scoped lang="scss">
</style>
