<template>
  <div class="event-marketing-setting">
    <p class="card-title">行銷內容設定</p>
    <BaseElForm
      ref="formRef"
      label-position="top"
      :model="formData"
      :rules="formRules"
    >
      <BaseElFormItem label="總活動領獎次數規則" prop="sendLimit" required>
        <BaseElSelect
          v-model="formData.sendLimit"
          :disabled="isEdit || !!accumulation"
        >
          <BaseElSelectOption
            v-for="item in typeOfEventNumberConfig"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </BaseElSelect>
        <p class="tips">{{ sendLimitWord }}</p>
      </BaseElFormItem>

      <BaseElFormItem v-if="showReceiveRewardType" label="總活動領獎次數進階設定">
        <div>
          <div class="grid grid-cols-2" style="width: 560px; gap: 8px">
            <BaseElFormItem class="flex-1" prop="receiveRewardType">
              <ElInputWrapper>
                <BaseElSelect v-model="formData.receiveRewardType" style="width: 100%" @change="formData.maxMemberAwardCount = null">
                  <BaseElSelectOption
                    v-for="item in rewardCountAdvanceOptionsConfig"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </BaseElSelect>
              </ElInputWrapper>
            </BaseElFormItem>

            <BaseElFormItem v-if="formData.receiveRewardType === 'maxMemberAwardCount'" class="flex-1" prop="maxMemberAwardCount">
              <ElInputWrapper>
                <BaseElInput v-model="formData.maxMemberAwardCount" style="width: 100%" placeholder="請輸入上限數值">
                  <template #suffix>次</template>
                </BaseElInput>
              </ElInputWrapper>
            </BaseElFormItem>
          </div>
          <p class="tips">會員達成事件滿 n 次後，不會再領到獎勵 (避免單一會員領走大量獎勵)</p>
        </div>
      </BaseElFormItem>

      <BaseElFormItem v-if="showSingleOrderRewardType" label="單筆訂單領獎數量設定">
        <div>
          <div class="grid grid-cols-2" style="width: 560px; gap: 8px">
            <BaseElFormItem class="flex-1" prop="singleOrderRwardType">
              <ElInputWrapper>
                <BaseElSelect v-model="formData.singleOrderRwardType" style="width: 100%" @change="formData.maxSingleAwardCount = null">
                  <BaseElSelectOption
                    v-for="item in singleOrderAwardCountConfig"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </BaseElSelect>
              </ElInputWrapper>
            </BaseElFormItem>

            <BaseElFormItem v-if="formData.singleOrderRwardType === 'maxSingleAwardCount'" class="flex-1" prop="maxSingleAwardCount">
              <ElInputWrapper>
                <BaseElInput v-model="formData.maxSingleAwardCount" style="width: 100%" placeholder="請輸入上限數值">
                  <template #suffix>份</template>
                </BaseElInput>
              </ElInputWrapper>
            </BaseElFormItem>
          </div>
          <p class="tips">會員在單筆消費累積滿 n 份後，不會再領到獎勵 (避免會員於單筆消費領走大量獎勵)</p>
        </div>
      </BaseElFormItem>

      <BaseElFormItem label="選擇行銷項目" prop="eventAwardType" required>
        <BaseElSelect v-model="formData.eventAwardType" @change="onAwardTypeChange">
          <BaseElSelectOption
            v-for="item in featureKeysByEventAwardConfig"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </BaseElSelect>
      </BaseElFormItem>

      <!-- 行銷項目：堂票 -->
      <BaseElFormItem
        v-if="formData.eventAwardType === 'classTicket'"
        label="選擇行銷票券"
        prop="classTicketId"
        required
      >
        <EventClassTicketSelect :classTicketId.sync="formData.classTicketId" @change="onAwardChange" />
      </BaseElFormItem>

      <!-- 行銷項目：票券 -->
      <BaseElFormItem
        v-if="formData.eventAwardType === 'coupon'"
        label="選擇票券"
        prop="couponId"
        required
      >
        <EventCouponSelect :couponId.sync="formData.couponId" :couponType="couponType" @change="onAwardChange" />
      </BaseElFormItem>

      <BaseElFormItem v-if="formData.eventAwardType === 'point'" label="選擇點數">
        <BaseDataSelect
          :value.sync="formData.shopPointKey"
          :dataList="shopPointList"
          placeholder="請選擇點數"
          objKey="key"
        />
      </BaseElFormItem>

      <BaseElFormItem prop="maxAwardStock">
        <FormItemTooltipLabel label="行銷項目總數" :tooltipWidth="180">
          事件啟用後，當「行銷項目總數」不足「每次達成事件發送的行銷項目數量」，則會員達成事件條件時，不發送獎勵
        </FormItemTooltipLabel>
        <div class="flex flex-col">
          <BaseElInput
            v-model="formData.maxAwardStock"
            placeholder="請輸入"
            :disabled="formData.maxAwardStockUnlimited"
          >
            <template #suffix>{{ rewardUnitLabel[formData.eventAwardType] }}</template>
          </BaseElInput>
          <BaseElCheckbox
            v-model="formData.maxAwardStockUnlimited"
            @change="onMaxAwardStockUnlimitedChange"
          >
            無上限
          </BaseElCheckbox>
          <p v-if="rewardSentCount > 1 && !formData.maxAwardStockUnlimited" class="tips">提醒：該行銷項目先前已發出{{ rewardSentCount }}{{ rewardUnitLabel[formData.eventAwardType] }}，請納入總數發放考量</p>
        </div>
      </BaseElFormItem>

      <!-- 行銷項目： 堂票 票券 回饋金 點數 -->
      <BaseElFormItem
        v-if="
          formData.eventAwardType && formData.eventAwardType !== 'lineMessage'
        "
        label="每次達成事件發送的行銷項目數量"
        prop="eventAwardCount"
        required
      >
        <BaseElInput v-model="formData.eventAwardCount" placeholder="請輸入">
          <template #suffix>{{ rewardUnitLabel[formData.eventAwardType] }}</template>
        </BaseElInput>
      </BaseElFormItem>

      <!-- 行銷項目：圖文訊息 -->
      <BaseElFormItem
        v-if="formData.eventAwardType === 'lineMessage'"
        label="圖文訊息設置"
        prop="eventMessage"
        required
        class="message"
      >
        <EventMessageBlock :eventMessage.sync="formData.eventMessage" />
      </BaseElFormItem>

      <!-- 發送時間 -->
      <BaseElFormItem label="發送時間" prop="sendDateSetting" required>
        <BaseElRadioGroup
          v-model="formData.sendDateSetting"
          class="flex flex-col"
          style="gap: 20px"
          @change="resetTime"
        >
          <div>
            <BaseElRadio label="immediately">
              <div class="inline-flex items-center" style="gap: 4px">
                <span>當下發送</span>
                <!-- <span v-show="sourceType === 'memberExternalTransactionCompleted'">(依據POS端同步銷售資料後時間發送：約凌晨6:00)</span> -->
                <el-tooltip
                  placement="right"
                  class="condition-help"
                >
                  <div slot="content" class="w-[380px]">
                    說明：第三方消費紀錄認列時間，視各分店所串接的POS系統而定。
                    <ul class="list-disc pl-[16px]">
                      <li>微碧POS- 約每10分鐘認列一次</li>
                      <li>瑞乘POS- 隔日凌晨約4:00撈取前一日紀錄</li>
                      <li>OPENTIX POS - 約每小時33分認列一次</li>
                    </ul>
                  </div>
                  <span class="relative" style="top: 2px">
                    <span v-show="sourceType === 'memberExternalTransactionCompleted'" class="material-icons">help_outline</span>
                  </span>
                </el-tooltip>
              </div>
            </BaseElRadio>
          </div>
          <div v-if="showSpecifySendDay">
            <BaseElRadio label="specify">
              指定時間
              <div
                class="flex"
                style="margin-left: 25px; margin-top: 8px; gap: 20px"
              >
                <div class="flex items-center" style="gap: 20px">
                  <span>隔</span>
                  <BaseElFormItem prop="part.specifySendDay">
                    <BaseElInput
                      v-model="formData.part.specifySendDay"
                      :disabled="formData.sendDateSetting !== 'specify'"
                      style="width: 100px"
                      placeholder="請輸入"
                    />
                  </BaseElFormItem>
                  <span>天後的</span>
                </div>

                <BaseElFormItem :show-message="false" prop="specifySendTime">
                  <div class="flex items-center" style="gap: 20px">
                    <el-time-picker
                      v-model="formData.part.specifySendTime"
                      style="width: 135px"
                      :disabled="formData.sendDateSetting !== 'specify'"
                      disabled-seconds
                      format="HH:mm"
                      :picker-options="timeOptions"
                      placeholder="請選擇時間"
                    />
                    <span>發送</span>
                  </div>
                </BaseElFormItem>
              </div>
            </BaseElRadio>
          </div>
        </BaseElRadioGroup>
      </BaseElFormItem>
    </BaseElForm>
  </div>
</template>

<script>
import {
  defineComponent,
  ref,
  reactive,
  onMounted,
  nextTick,
  computed,
  watch,
} from 'vue'
import { get, set, map } from 'lodash'
import dayjs from '@/lib/dayjs'
import {
  noEmptyRules,
  isDigitRules,
  integerRules,
  minRules,
  rangeRules,
} from '@/validation'
import {
  eventNumberOfReceiptsConfig,
  eventAwardConfig,
  eventTipsConfig,
  rewardCountAdvanceOptionsConfig,
  singleOrderAwardCountConfig,
} from '@/config/marketing'
import ElInputWrapper from '@/components/ElInputWrapper.vue'
import EventMessageBlock from './EventMessageBlock.vue'
import EventCouponSelect from './EventCouponSelect.vue'
import EventClassTicketSelect from './EventClassTicketSelect.vue'
import FormItemTooltipLabel from '@/components/Form/FormItemTooltipLabel.vue'
import { usePermissions } from '@/use/permissions'
import { onFormRulesChangeClearValidate } from '@/use/useForm'
import store from '@/store'
import { GetEventTemplateAwardStatus } from '@/api/eventMarketing'
import { useFetch } from '@/use/fetch'
import { useShop } from '@/use/shop'
import BaseDataSelect from '@/components/Select/BaseDataSelect.vue'

export default defineComponent({
  name: 'EventMarketingContent',
  components: {
    EventMessageBlock,
    EventCouponSelect,
    EventClassTicketSelect,
    FormItemTooltipLabel,
    ElInputWrapper,
    BaseDataSelect,
  },
  props: [
    'FormsContext',
    'eventData',
    'sourceType',
    'accumulation',
    'eventType',
    'singleConsumption',
  ],
  setup (props) {
    const { checkAction } = usePermissions()
    const { simpleFetch } = useFetch()
    const { shopId, shopPointList } = useShop()
    const formRef = ref(null)
    const rewardSentCount = ref(0)

    const rewardTypes = map(eventAwardConfig, 'label')
    const rewardUnitLabel = {
      classTicket: '份',
      coupon: '張',
      point: '點',
      cashback: '元',
    }

    const formData = reactive({
      sendLimit: null,
      eventAwardType: null,
      sendDateSetting: null,
      eventAwardCount: null,
      couponId: null,
      classTicketId: null,
      amountConfig: {
        amountType: null,
      },
      shopPointKey: null,
      maxAwardStock: null, // 行銷項目總數
      maxAwardStockUnlimited: true, // 行銷項目總數是否無限制

      receiveRewardType: 'none', // 總活動領獎次數進階設定
      maxMemberAwardCount: null, // 上限值
      singleOrderRwardType: 'none', // 單筆訂單領獎數量設定
      maxSingleAwardCount: null, // 單筆訂單上限值
      part: {
        specifySendDay: null,
        specifySendTime: null,
      },
      eventMessage: [
        {
          type: 'text',
          content: '',
          action: undefined,
          actionContent: undefined,
          Image: undefined,
        },
      ],
    })

    const couponType = computed(() => {
      const couponTypeArray = []
      if (get(formData, 'eventAwardType') === 'coupon') {
        if (checkAction('admin.coupon.page')) couponTypeArray.push('coupon')
        if (checkAction('admin.couponExchange.page')) couponTypeArray.push('exchange')
        if (checkAction('admin.couponPospal.find')) couponTypeArray.push('pospal')
      }
      return couponTypeArray
    })

    const timeOptions = computed(() => {
      if (props.sourceType !== 'memberExternalTransactionCompleted') {
        return {
          selectableRange: '00:00:00 - 23:59:59',
        }
      }
      return {
        selectableRange: '00:00:00 - 23:59:59',
      }
      // if (toNumber(formData.part.specifySendDay) === 1) {
      //   return {
      //     selectableRange: '06:00:00 - 23:59:59',
      //   }
      // } else {
      //   return {
      //     selectableRange: '00:00:00 - 23:59:59',
      //   }
      // }
    })

    const formRules = computed(() => {
      const rules = {
        sendLimit: [noEmptyRules('請輸入總活動領獎次數規則')],
        eventAwardType: [noEmptyRules('請輸入行銷項目')],
        sendDateSetting: [noEmptyRules('請選擇發送時間')],
        StandardMessage: [noEmptyRules('請輸入圖文訊息')],
        couponId: [noEmptyRules('請選擇票券')],
        classTicketId: [noEmptyRules('請選擇堂票')],
        eventAwardCount: [
          noEmptyRules('請輸入數量'),
          isDigitRules(),
          integerRules(),
        ],
      }
      if (formData.sendDateSetting === 'specify') {
        rules['part.specifySendDay'] = [
          noEmptyRules('請輸入天數'),
          minRules(1, '指定時間最少須設定1天（隔日），不可輸入0天（當日）'),
        ]
      }
      // 行銷項目總數
      if (!formData.maxAwardStockUnlimited) {
        rules.maxAwardStock = [
          noEmptyRules('請輸入數量'),
          isDigitRules(),
        ]
      }

      // 總活動領獎次數進階設定
      if (formData.receiveRewardType === 'maxMemberAwardCount') {
        rules.maxMemberAwardCount = [noEmptyRules(), isDigitRules(), minRules(1)]
      }
      if (formData.singleOrderRwardType === 'maxSingleAwardCount') {
        rules.maxSingleAwardCount = [noEmptyRules(), isDigitRules(), minRules(1)]
      }
      return rules
    })

    onFormRulesChangeClearValidate(formRef, formRules)

    const featureKeys = {
      classTicket: 'admin.classTicket.page',
      coupon: 'admin.coupon.page',
      point: 'admin.shopPoint.page',
      cashback: 'admin.shopCashback.page',
    }

    const featureKeysByEventAwardConfig = computed(() => {
      const marketingPermission = store.getters.computedShopMarketingPermission
      return eventAwardConfig.filter(({ value }) => {
        // 推薦禮移除圖文訊息
        if (props.sourceType === 'memberReferralRecordSuccessReferrer' ||
         props.sourceType === 'memberReferralRecordSuccessReferee'
        ) {
          return value !== 'lineMessage' && marketingPermission[value]
        }
        if (featureKeys[value]) {
          if (marketingPermission[value]) {
            return true
          }
          return checkAction(featureKeys[value]) && marketingPermission[value]
        }
        return true
      })
    })

    const isEdit = computed(() => {
      return !!get(props.eventData, 'id')
    })

    const typeOfEventNumberConfig = computed(() => {
      const config = ['firstOrderAmount', 'firstOrderNumber', 'registerAndLineAuth']
      if (props.sourceType === 'register') {
        return eventNumberOfReceiptsConfig.filter(
          ({ value }) => value !== 'repeat',
        )
      }

      // 過濾只顯示只領取1次
      if (config.includes(props.eventType)) {
        return eventNumberOfReceiptsConfig.filter(
          ({ value }) => value === 'once',
        )
      }
      const subscriptionEventConfig = ['singlePaymentRecord']
      if (subscriptionEventConfig.includes(props.eventType)) {
        return eventNumberOfReceiptsConfig.filter(
          ({ value }) => value === 'repeat',
        )
      }
      return eventNumberOfReceiptsConfig
    })
    const showReceiveRewardType = computed(() => {
      const subscriptionConfig = ['subscriptionPlanBenefits']
      return formData.sendLimit === 'repeat' && !subscriptionConfig.includes(props.sourceType)
    })
    const showSingleOrderRewardType = computed(() => {
      const repeatStatus = isEdit.value ? get(formData, 'amountConfig.amountType') === 'repeatRate' : props.singleConsumption === 'repeat'
      return repeatStatus
    })
    const sendLimitWord = computed(() => {
      if (props.accumulation) {
        return '規則說明：已套用上方事件範本的累積計算方式作為總活動領獎次數規則'
      }
      return props.sourceType && formData.sendLimit
        ? eventTipsConfig[props.sourceType][formData.sendLimit]
        : ''
    })

    const showSpecifySendDay = computed(() => {
      const config = ['level', 'birthday']
      return !config.includes(props.sourceType)
    })

    const onMaxAwardStockUnlimitedChange = (toggle) => {
      if (toggle) formData.maxAwardStock = null
    }

    const compactData = computed(() => {
      const data = {
        sendLimit: get(formData, 'sendLimit'),
        eventAwardType: get(formData, 'eventAwardType'),
        eventAwardCount: get(formData, 'eventAwardCount'),
        sendDateSetting: get(formData, 'sendDateSetting'),
        maxMemberAwardCount: get(formData, 'maxMemberAwardCount'),
        maxSingleAwardCount: get(formData, 'maxSingleAwardCount'),
        maxAwardStock: get(formData, 'maxAwardStock'),
      }
      if (data.eventAwardType === 'lineMessage') {
        data.messages = get(formData, 'eventMessage')
      }
      if (data.eventAwardType === 'coupon') {
        data.relatedId = [get(formData, 'couponId')]
      }
      if (data.eventAwardType === 'classTicket') {
        data.relatedId = [get(formData, 'classTicketId')]
      }
      if (data.eventAwardType === 'point') {
        data.shopPointKey = get(formData, 'shopPointKey')
      }
      if (data.sendDateSetting === 'specify') {
        const hour = dayjs(formData.part.specifySendTime).format('HH')
        const minute = dayjs(formData.part.specifySendTime).format('mm')
        data.specifySendDay = get(formData.part, 'specifySendDay')
        data.specifySendTime = Number(hour * 60) + Number(minute)
      }
      return { ...data }
    })

    const syncData = () => {
      const data = props.eventData
      const { award, amountConfig } = data.config
      set(formData, 'maxAwardStock', data.maxAwardStock)
      if (data.maxAwardStock) formData.maxAwardStockUnlimited = false
      set(formData, 'maxMemberAwardCount', award.maxMemberAwardCount)
      if (award.maxMemberAwardCount) formData.receiveRewardType = 'maxMemberAwardCount'
      set(formData, 'maxSingleAwardCount', award.maxSingleAwardCount)
      if (award.maxSingleAwardCount) formData.singleOrderRwardType = 'maxSingleAwardCount'
      if (amountConfig && amountConfig.amountType) {
        set(formData, 'amountConfig.amountType', amountConfig.amountType)
      }
      set(formData, 'sendLimit', award.sendLimit)
      set(formData, 'eventAwardType', award.type)
      set(formData, 'sendDateSetting', award.sendDateSetting)
      set(formData, 'eventAwardCount', award.amount)
      set(formData.part, 'specifySendDay', award.specifySendDay)
      set(
        formData.part,
        'specifySendTime',
        dayjs().startOf('day').add(award.specifySendTime, 'minutes'),
      )
      if (award.type === 'coupon') {
        set(formData, 'couponId', award.relatedId[0])
      }
      if (award.type === 'classTicket') {
        set(formData, 'classTicketId', award.relatedId[0])
      }
      if (award.type === 'point') {
        set(formData, 'shopPointKey', award.shopPointKey)
      }
      if (award.type === 'lineMessage') {
        set(
          formData,
          'eventMessage',
          award.messages.map((item) => {
            return {
              type: 'text',
              content: '',
              action: undefined,
              actionContent: undefined,
              ...item,
            }
          }),
        )
      }
    }

    const checkEventTemplateAwardStatus = async (awardType) => {
      let awardId
      if (awardType === 'classTicket') awardId = get(formData, 'classTicketId')
      else if (awardType === 'coupon') awardId = get(formData, 'couponId')

      if (get(props.eventData, 'id')) {
        simpleFetch(GetEventTemplateAwardStatus, {
          shopId: shopId.value,
          id: get(props.eventData, 'id'),
          awardType,
          awardId: awardId || null,
        }, (res) => {
          rewardSentCount.value = res.sentCount
        })
      }
    }

    onMounted(async () => {
      await nextTick()

      if (get(props.eventData, 'id')) syncData()
      props.FormsContext.setFormRef('content', formRef.value)

      const awardType = get(props.eventData, 'config.award.type')

      checkEventTemplateAwardStatus(awardType)

      // let awardId
      // if (awardType === 'classTicket') awardId = get(formData, 'classTicketId')
      // else if (awardType === 'coupon') awardId = get(formData, 'couponId')
      // if (get(props.eventData, 'id')) {
      //   simpleFetch(GetEventTemplateAwardStatus, {
      //     shopId: shopId.value,
      //     id: get(props.eventData, 'id'),
      //     awardType: get(props.eventData, 'config.award.type'),
      //     awardId: awardId || null,
      //   }, (res) => {
      //     rewardSentCount.value = res.sentCount
      //   })
      // }
    })

    watch(formData, () => {
      props.FormsContext.setFormData('content', { ...compactData.value })
    })

    const onAwardTypeChange = (awardType) => {
      formData.couponId = null
      formData.classTicketId = null
    }
    const onAwardChange = (awardType) => {
      checkEventTemplateAwardStatus(formData.eventAwardType)
    }

    const trans = {
      rate: 'repeat',
      once: 'once',
    }

    watch(
      () => props.accumulation,
      (newValue) => {
        formData.sendLimit = trans[newValue]
      },
    )
    watch(
      () => props.eventType,
      (newValue) => {
        formData.sendLimit = trans[props.accumulation] || null
      },
    )
    const resetTime = () => {
      formData.part = {
        specifySendDay: null,
        specifySendTime: null,
      }
    }

    return {
      formRef,
      formData,
      formRules,
      eventNumberOfReceiptsConfig,
      featureKeysByEventAwardConfig,
      onAwardTypeChange,
      onAwardChange,
      sendLimitWord,
      isEdit,
      resetTime,
      showSpecifySendDay,
      typeOfEventNumberConfig,
      showReceiveRewardType,
      showSingleOrderRewardType,
      timeOptions,
      couponType,
      rewardTypes,
      rewardCountAdvanceOptionsConfig,
      singleOrderAwardCountConfig,
      onMaxAwardStockUnlimitedChange,
      rewardUnitLabel,
      rewardSentCount,
      shopPointList,
      compactData,
    }
  },
})
</script>

<style lang="postcss" scoped>
.message ::v-deep .el-input {
  @apply w-full;
}
.tips {
  @apply text-[#636363];
}
</style>
