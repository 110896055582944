import Layout from '@/layout'

export const sheetExportCenterRoutes = [
  {
    path: '/data-explorer',
    component: Layout,
    name: 'SheetExportCenter',
    alwaysShow: true,
    isGroup: true,
    meta: {
      title: 'page.dataModule.title',
    },
    children: [
      {
        path: 'sheet-export-center',
        component: () => import('@/views/Basic/SheetExportCenter'),
        name: 'SheetExportCenter',
        meta: {
          title: 'page.downloadCenter.title',
          action: 'admin.sheetExportTask.page',
        },
      },
    ],
  },
]
