<template>
  <div class="sales-discount-setting">
    <PageTitle :title="displayData.pageTitle" :btn="displayData.btnText.create" @btnClick="onCreate" />
    <FiltersContainer>
      <BaseElInput v-model="nameSearch" clearable :placeholder="displayData.placeholder.nameSearch" @keypress.enter.native="refresh(true)" @clear="refresh(true)">
        <i slot="suffix" class="el-input__icon el-icon-search" @click="refresh(true)" />
      </BaseElInput>
    </FiltersContainer>
    <section>
      <BaseTable v-loading="loading.table" :data="tableData" :empty-text="displayData.emptyText">
        <EmptyBlock slot="empty" />
        <BaseElTableColumn prop="name" :label="displayData.tableColumn.name" width="150" align="center" />
        <BaseElTableColumn prop="type" :label="displayData.tableColumn.type" align="center">
          <template slot-scope="scope">
            {{
              (scope.row.type === 'free')
                ? displayData.discountType.free
                : (scope.row.type === 'cash')
                  ? displayData.discountType.cash
                  : displayData.discountType.percent
            }}
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn prop="type" :label="displayData.tableColumn.amount" align="center">
          <template slot-scope="scope">
            {{
              (scope.row.type === 'free')
                ? displayData.discountType.freeAmount
                : (scope.row.percentAmount)
                  ? `${discountFormat(scope.row.percentAmount)}${displayData.discountType.percentSuffix}`
                  : `${scope.row.cashAmount}${displayData.discountType.cashSuffix}`
            }}
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn prop="order" :label="displayData.tableColumn.order" align="center" />
        <BaseElTableColumn :label="displayData.tableColumn.actions" fixed="right" width="100" align="center">
          <template slot-scope="scope">
            <TableEditBtnGroup
              @edit="onEdit(scope.row)"
              @delete="onDelete(scope.row)"
            />
          </template>
        </BaseElTableColumn>
      </BaseTable>
      <Pagination
        :curPage.sync="tableOptions.page"
        :pageLimit="tableOptions.pageLimit"
        :total="tableDataCount"
        @pageChange="refresh(false)"
      />
    </section>

    <EditSalesDiscountModal
      v-if="modal.edit"
      :selectRow="selectRow"
      @close="onEditClose"
      @refresh="refresh"
    />

    <DeleteDialog
      v-if="modal.delete"
      :title="displayData.deleteDialog.title"
      :content="displayData.deleteDialog.content"
      width="40%"
      @close="modal.delete = false"
      @delete="deleteSalesDiscount(), modal.delete = false"
    />
  </div>
</template>

<script>
import { defineComponent, ref, reactive, computed, onMounted } from 'vue'
import DeleteDialog from '@/components/Dialog/DeleteDialog'
import EmptyBlock from '@/components/EmptyBlock.vue'
import { useTable } from '@/use/table'
import {
  GetSalesDiscount,
  GetSalesDiscountCount,
  DeleteSalesDiscount,
} from '@/api/sales'
import formUtils from '@/utils/form'
import { dialogTitle } from '@/utils/dialog'
import notifyMessage from '@/config/notifyMessage'
import { useShop } from '@/use/shop'
import EditSalesDiscountModal from './components/EditSalesDiscountModal.vue'

export default defineComponent({
  name: 'SalesDiscountSetting',
  components: { DeleteDialog, EmptyBlock, EditSalesDiscountModal },
  setup (props, { emit }) {
    const { shopId } = useShop()
    const { tableData, tableOptions, tableDataCount, loading, pageStartIndex, fetchData, fetchDataCount } = useTable()
    const nameSearch = ref('')
    const showDialog = ref(false)
    const dialogType = ref('create')
    const selectRow = ref(null)
    const modal = reactive({
      edit: false,
      delete: false,
    })

    const displayData = reactive({
      pageTitle: '銷售優惠設定',
      placeholder: {
        nameSearch: '輸入優惠名稱',
      },
      emptyText: '暫無數據',
      btnText: {
        create: '新增',
      },
      tableColumn: {
        name: '名稱',
        type: '打折形式',
        amount: '金額/比例',
        order: '排序',
        actions: '操作',
      },
      discountType: {
        free: '自定義優惠',
        cash: '現金',
        percent: '比例',
        freeAmount: '-',
        percentSuffix: '折',
        cashSuffix: '元',
      },
      deleteDialog: {
        title: '提醒',
        content: '刪除後將無法復原，確定要刪除？',
      },
    })

    const dialogTitleComputed = computed(() => {
      return dialogTitle(
        dialogType.value,
        {
          create: '新增銷售優惠',
          update: '編輯銷售優惠',
        },
      )
    })

    const refresh = async (search = false) => {
      if (search) tableOptions.page = 1
      loading.table = true
      await getTableData()
      loading.table = false
    }

    const discountFormat = (amount) => {
      let word = (amount * 100).toFixed()
      if (word) { if (word.length === 1) word = `0.${word}` }
      if (word % 10 === 0) word = word.replace('0', '')
      return word
    }

    const onCreate = () => {
      modal.edit = true
    }

    const checkForm = async () => {
      return await formUtils.checkForm(this.$refs.form)
    }

    const getTableData = async () => {
      const payload = {
        shopId: shopId.value,
        start: pageStartIndex.value,
        limit: tableOptions.pageLimit,
        name: (nameSearch.value === '') ? undefined : nameSearch.value,
      }
      await Promise.allSettled([
        fetchData(GetSalesDiscount, payload),
        fetchDataCount(GetSalesDiscountCount, payload),
      ])
    }

    const deleteSalesDiscount = async () => {
      try {
        await DeleteSalesDiscount({
          shopId: shopId.value,
          id: selectRow.value.id,
        })
        window.$message.success(notifyMessage.deleteSuccess)
        await refresh()
      } catch (error) {
        console.log(error)
        window.$message.error(error)
      }
    }

    const onEdit = (row) => {
      selectRow.value = row
      modal.edit = true
    }

    const onDelete = (row) => {
      selectRow.value = row
      modal.delete = true
    }

    const onEditClose = () => {
      modal.edit = false
      selectRow.value = null
    }

    onMounted(async () => {
      await refresh()
    })

    return {
      modal,
      loading,
      nameSearch,
      showDialog,
      dialogType,
      selectRow,
      tableOptions,
      tableData,
      tableDataCount,
      dialogTitle: dialogTitleComputed,
      refresh,
      discountFormat,
      onCreate,
      checkForm,
      deleteSalesDiscount,
      onEdit,
      onDelete,
      displayData,
      onEditClose,
    }
  },
})
</script>

<style scoped lang="scss"></style>
