<template>
  <div class="section-block">
    <BaseElFormItem label="會員權益範本性質" prop="type">
      <BaseElRadioGroup v-model="syncFormData.type">
        <BaseElRadio label="standard">非訂閱商品</BaseElRadio>
        <BaseElRadio v-if="useSubscriptionPlan" label="subscription">訂閱商品</BaseElRadio>
      </BaseElRadioGroup>
    </BaseElFormItem>

    <BaseElFormItem label="會員權益範本名稱" prop="name">
      <BaseElInput v-model="syncFormData.name" />
    </BaseElFormItem>

    <BaseElFormItem label="圖片" prop="img">
      <UploadButton
        :img="formData.img"
        :isAvatar="true"
        @change="loadImg"
      />
    </BaseElFormItem>

    <BaseElFormItem label="商品描述" prop="description" style="width: 560px">
      <template #label>
        <div class="w-full inline-flex items-center justify-between">
          <p>商品描述</p>
          <el-popover
            v-model="popover.contractTemplate"
            placement="bottom-end"
            :visible-arrow="false"
            :offset="10"
            width="200"
            trigger="click"
          >
            <div>
              <p
                v-for="item in contractTemplateOptions"
                :key="item.value"
                class="resource-option"
                @click="onSelectContractTemplate(item.value)"
              >
                {{ item.label }}
              </p>
            </div>

            <BaseElButton slot="reference" type="text" class="py-0">
              套用合約範本
            </BaseElButton>
          </el-popover>
        </div>
      </template>
      <BaseTextEditor :value.sync="syncFormData.description" />
    </BaseElFormItem>

    <BaseElFormItem label="發放時間" prop="period.timeRange">
      <BaseElSelect v-model="syncFormData.period.timeRange">
        <BaseElSelectOption
          v-for="option in timeRangeOptions"
          :key="option.value"
          :label="option.label"
          :value="option.value"
        />
      </BaseElSelect>
    </BaseElFormItem>

    <BaseElFormItem label="排序" prop="order">
      <BaseElInput v-model="syncFormData.order" />
    </BaseElFormItem>

    <ImageCropper
      v-if="modal.imgCropper"
      :image="imagePlaceHolder"
      @close="modal.imgCropper = false"
      @uploaded="getImage"
    />
  </div>
</template>

<script>
import UploadButton from '@/components/Button/UploadButton.vue'
import ImageCropper from '@/components/ImageCropper.vue'
import BaseTextEditor from '@/components/BaseTextEditor.vue'
import { computed, defineComponent, onBeforeMount, reactive, ref } from 'vue'
import { useVModel } from '@vueuse/core'
import { systemContract, gymContract, productContract } from '@/config/subscribe'
import { FindSubscribeConfig } from '@/api/subscribe'
import { usePermissions } from '@/use/permissions'
import { useShop } from '@/use/shop'

export default defineComponent({
  name: 'BaseInfoFormBlock',
  components: {
    UploadButton,
    ImageCropper,
    BaseTextEditor,
  },
  props: {
    formData: {
      type: Object,
      default: () => ({}),
    },
  },
  setup (props, { emit }) {
    const { shopId } = useShop()
    const { checkAction } = usePermissions()
    const modal = reactive({
      imgCropper: false,
    })
    const popover = reactive({
      subscriptionTemplate: false,
    })
    const imagePlaceHolder = ref(null)
    const syncFormData = useVModel(props, 'formData', emit)
    const subscribeConfigData = ref({})
    const useSubscriptionPlan = computed(() => {
      return checkAction('admin.subscriptionPlan.find') && subscribeConfigData.value.id
    })

    const contractTemplateOptions = computed(() => {
      return [
        { label: '系統合約', value: 'system' },
        { label: '健身房合約', value: 'gym' },
        { label: '商品合約', value: 'product' },
      ]
    })

    const timeRangeOptions = computed(() => {
      return [
        // { label: '凌晨（00:00～06:00）', value: 'earlyMorning' },
        { label: '上午（06:00～12:00）', value: 'morning' },
        { label: '下午（12:00～18:00）', value: 'afternoon' },
        { label: '晚間（18:00～24:00）', value: 'night' },
      ]
    })

    const onSelectContractTemplate = (key) => {
      switch (key) {
      case 'system':
        syncFormData.value.description = systemContract
        break
      case 'gym':
        syncFormData.value.description = gymContract
        break
      case 'product':
        syncFormData.value.description = productContract
        break
      }
      popover.contractTemplate = false
    }

    const loadImg = (img) => {
      imagePlaceHolder.value = img
      modal.imgCropper = true
    }

    const getImage = (data) => {
      syncFormData.value.img = data
      modal.imgCropper = false
    }

    onBeforeMount(async () => {
      const [res, err] = await FindSubscribeConfig({
        shopId: shopId.value,
      })
      if (err) { return }
      subscribeConfigData.value = res
    })

    return {
      popover,
      syncFormData,
      modal,
      imagePlaceHolder,
      loadImg,
      getImage,
      timeRangeOptions,
      contractTemplateOptions,
      onSelectContractTemplate,
      useSubscriptionPlan,
    }
  },
})

</script>

<style lang="postcss" scoped>
::v-deep .el-form-item__label {
  @apply w-full inline-flex;
}

.resource-option {
  @apply cursor-pointer py-[8px] px-[12px] ;
  @apply hover:bg-primary-100 hover:text-white;
}
</style>
