<template>
  <section v-loading="loading" class="section-block">
    <SectionTitle title="銷售人員報表設定" @edit="modal.edit = true" />
    <div>
      <InfoList
        label-width="200px"
        label-position="left"
        :displayData="displayData"
      />
    </div>

    <SalesUnitSettingModal
      v-if="modal.edit"
      :configData="configData"
      @close="modal.edit = false"
      @refresh="refresh"
    />
  </section>
</template>

<script>
import InfoList from '@/components/InfoList.vue'
import SectionTitle from '@/components/Title/SectionTitle.vue'
import { computed, defineComponent, onBeforeMount, reactive, ref } from 'vue'
import SalesUnitSettingModal from './components/SalesUnitSettingModal.vue'
import { FindSalesUnitReportConfig } from '@/api/sales'
import { useShop } from '@/use/shop'
import { get } from 'lodash'

export default defineComponent({
  name: 'SalesUnitSettingBlock',
  components: {
    SectionTitle,
    InfoList,
    SalesUnitSettingModal,
  },
  props: {
  },
  setup (props, { emit }) {
    const { shopId } = useShop()
    const loading = ref(false)
    const modal = reactive({
      edit: false,
    })
    const configData = ref({})

    const displayData = computed(() => {
      return [
        {
          label: '管理員檢視設定',
          value: configData.value.enabled ? '開啟' : '關閉',
        },
      ]
    })

    const refresh = async () => {
      if (loading.value) return
      loading.value = true
      const [res, err] = await FindSalesUnitReportConfig({
        shopId: shopId.value,
      })
      loading.value = false
      if (err) {
        window.$message.error(err)
        return
      }

      configData.value = res
    }

    onBeforeMount(async () => {
      refresh()
    })

    return { modal, configData, displayData, refresh, loading }
  },
})

</script>

<style lang="postcss" scoped>
</style>
