import { render, staticRenderFns } from "./EditSalesDiscountConfigModal.vue?vue&type=template&id=72af20b9&scoped=true"
import script from "./EditSalesDiscountConfigModal.vue?vue&type=script&lang=js"
export * from "./EditSalesDiscountConfigModal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72af20b9",
  null
  
)

export default component.exports