export const taskStatusConfig = {
  completed: { label: '已完成', value: 'completed', type: 'info' },
  failed: { label: '失敗', value: 'failed', type: 'danger' },
  processing: { label: '進行中', value: 'processing', type: 'action' },
  pending: { label: '等待排程', value: 'pending', type: 'warning' },
}

export const workBookTemplateConfig = {
  memberBasic: { label: '會員管理匯出', value: 'memberBasic' },
  eventPlaybookAwardRecordBasic: { label: '事件行銷發放紀錄匯出', value: 'eventPlaybookAwardRecordBasic' },
  shopPointReportBasic: { label: '點數發放紀錄匯出', value: 'shopPointReportBasic' },
  memberGameRecordBasic: { label: '抽獎紀錄發放紀錄匯出', value: 'memberGameRecordBasic' },
  appointmentVisitorPreOrderBasic: { label: '跨境預約訪客人次紀錄匯出', value: 'appointmentVisitorPreOrderBasic' },
  couponExchangeSerialBasic: { label: '兌換券設定兌換碼管理紀錄匯出', value: 'couponExchangeSerialBasic' },
  couponRecordCreatedBasic: { label: '票券新增紀錄匯出', value: 'couponRecordCreatedBasic' },
  couponRecordUsedBasic: { label: '票券使用紀錄匯出', value: 'couponRecordUsedBasic' },
  classTicketRecordBasic: { label: '堂票新增紀錄匯出', value: 'classTicketRecordBasic' },
  classTicketRecordUseBasic: { label: '堂票使用紀錄匯出', value: 'classTicketRecordUseBasic' },
  memberStoreOrderBasic: { label: '商城訂單紀錄匯出', value: 'memberStoreOrderBasic' },
  memberStoreOrderPointBasic: { label: '點數兌換紀錄匯出', value: 'memberStoreOrderPointBasic' },
  ecommerceOrderBasic: { label: '電商模組訂單記錄匯出', value: 'ecommerceOrderBasic' },
  memberExternalTransactionBasic: { label: '第三方消費紀錄匯出', value: 'memberExternalTransactionBasic' },
  shopCashbackReportBasic: { label: '回饋金報表紀錄匯出', value: 'shopCashbackReportBasic' },
  walletRecordBasic: { label: '儲值金報表紀錄匯出', value: 'walletRecordBasic' },
  serialActivityCodeBasic: { label: '序號登錄活動活動序號管理紀錄匯出', value: 'serialActivityCodeBasic' },
  salesClientLinkBasic: { label: '消費登錄連結紀錄匯出', value: 'salesClientLinkBasic' },
  appointmentReservationBasic: { label: '預約紀錄匯出', value: 'appointmentReservationBasic' },
  subscriptionOrderBasic: { label: '訂閱紀錄匯出', value: 'subscriptionOrderBasic' },
  pointCardUsageLogBasic: { label: '點數卡使用紀錄匯出', value: 'pointCardUsageLogBasic' },
  pointCardRecordBasic: { label: '點數卡新增紀錄匯出', value: 'pointCardRecordBasic' },
  punchCardUsageLogBasic: { label: '次卡使用紀錄匯出', value: 'punchCardUsageLogBasic' },
  punchCardRecordBasic: { label: '次卡新增紀錄匯出', value: 'punchCardRecordBasic' },
  salesUnitReportBasic: { label: '銷售人員報表匯出', value: 'salesUnitReportBasic' },
}

export const errorCodeConfig = {
  INPUT_SCHEMA_VALIDATION_ERROR: { label: '輸入結構驗證錯誤', value: 'INPUT_SCHEMA_VALIDATION_ERROR' },
  WORKBOOK_PRE_DATA_ERROR: { label: '表單檔案前置資料錯誤', value: 'WORKBOOK_PRE_DATA_ERROR' },
  WORKSHEET_PRE_DATA_ERROR: { label: '表單前置資料錯誤', value: 'WORKSHEET_PRE_DATA_ERROR' },
  QUERY_DATA_CONNECTION_ERROR: { label: '查詢資料連接錯誤', value: 'QUERY_DATA_CONNECTION_ERROR' },
  QUERY_DATA_ERROR: { label: '查詢資料錯誤', value: 'QUERY_DATA_ERROR' },
  POST_PROCESS_DATA_ERROR: { label: '資料處理錯誤', value: 'POST_PROCESS_DATA_ERROR' },
  FORMAT_ROW_ERROR: { label: '資料格式化錯誤', value: 'FORMAT_ROW_ERROR' },
  CREATE_WORKSHEET_ERROR: { label: '建立工作表單錯誤', value: 'CREATE_WORKSHEET_ERROR' },
  GENERATE_EXPORT_SUMMARY_ERROR: { label: '生成匯出錯誤', value: 'GENERATE_EXPORT_SUMMARY_ERROR' },
  SHOP_POINT_NOT_ENABLED: { label: '尚未啟用點數功能', value: 'SHOP_POINT_NOT_ENABLED' },
  SHOP_CASHBACK_NOT_ENABLED: { label: '尚未啟用回饋金功能', value: 'SHOP_CASHBACK_NOT_ENABLED' },
}
