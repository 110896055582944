
<template>
  <div class="externalTransaction-tab">
    <ExternalTransactionRecordTable
      v-loading="loading.table"
      :tableData="tableData"
      :options="{
        showBranch: false,
        showPhone: false,
      }"
      @refresh="refresh(false)"
    />
    <Pagination
      :curPage.sync="tableOptions.page"
      :pageLimit="tableOptions.pageLimit"
      :total="tableDataCount"
      @pageChange="refresh(false)"
    />
  </div>
</template>

<script>
import { get } from 'lodash'
import { useShop } from '@/use/shop'
import { onMounted, onActivated, defineComponent } from 'vue'
import { useTable } from '@/use/table'
import { GetMemberExternalTransaction, GetMemberExternalTransactionCount } from '@/api/memberExternalTransaction'
import ExternalTransactionRecordTable from '@/components/ExternalTransaction/ExternalTransactionRecordTable.vue'

export default defineComponent({
  name: 'ExternalTransactionTab',
  components: {
    ExternalTransactionRecordTable,
  },
  props: ['member'],
  setup (props, { emit }) {
    const {
      tableData,
      tableOptions,
      tableDataCount,
      loading,
      pageStartIndex,
      fetchData,
      fetchDataCount,
    } = useTable()
    const { shopId } = useShop()

    const getTableData = async () => {
      const payload = {
        MemberId: get(props.member, 'id', undefined),
        shopId: shopId.value,
        start: pageStartIndex.value,
        limit: tableOptions.pageLimit,
      }
      await Promise.allSettled([
        fetchData(GetMemberExternalTransaction, payload),
        fetchDataCount(GetMemberExternalTransactionCount, payload),
      ])
    }
    const refresh = async (search = false) => {
      if (search) tableOptions.page = 1
      loading.table = true
      await getTableData()
      loading.table = false
    }
    onMounted( async() => {
      await refresh()
    })
    onActivated( async() => {
      await refresh()
    })
    return {
      tableData,
      tableOptions,
      tableDataCount,
      loading,
      refresh,
    }
  },
})
</script>

<style scoped lang="scss">
</style>
