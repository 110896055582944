<template>
  <div class="resource-time-space">
    <PageTitle
      :title="$t('resourceTimeSpace.title')"
      :btn2="$t('resourceTimeSpace.button.closePeriod.text')"
      fontSize="20"
      :hideBtn1="true"
      @btn2Click="togglePeriodDialog = true"
    />
    <FiltersContainer>
      <!-- <ServicesUnitSelect :model.sync="unit" :showAll="true" @change="refresh"/> -->
      <el-date-picker
        v-model="searchDate"
        editable
        format="yyyy-MM-dd"
        :placeholder="$t('resourceTimeSpace.search.date.placeholder')"
        @change="refresh"
      />
    </FiltersContainer>
    <section v-if="times">
      <BaseTable
        v-if="resourceItemList.length === 0"
        class="test"
        :data="resourceItemList"
        :empty-text="$t('common.table.empty.text')"
      >
        <EmptyBlock slot="empty" />
        <BaseElTableColumn :label="$t('resourceSetting.table.name.title')" prop="name" fixed="left" />
      </BaseTable>

      <BaseTable
        v-if="resourceItemList.length > 0"
        v-loading="loading"
        :data="resourceItemList"
        :empty-text="$t('common.table.empty.text')"
      >
        <BaseElTableColumn
          :label="$t('resourceSetting.table.name.title')"
          width="120px"
          align="center"
          prop="name"
          fixed="left"
        />
        <BaseElTableColumn
          v-for="num in showColumn()"
          :key="num"
          align="center"
          min-width="120px"
          :label="num"
          prop="availableTimes.times"
        >
          <template slot-scope="scope">
            <div class="block" :class="periodBlock(scope).status">
              {{ trans(periodBlock(scope)) }}
            </div>
          </template>
        </BaseElTableColumn>
      </BaseTable>
      <Pagination
        :curPage.sync="tableOptions.page"
        :pageLimit="tableOptions.pageLimit"
        :total="resourceItemCount"
        @pageChange="refresh"
      />
    </section>

    <!-- 關閉時段 Dialog -->
    <TogglePeriodDialog
      v-if="togglePeriodDialog"
      :selectDate="searchDate"
      :title="$t('resourceTimeSpace.dialog.closePeriod.title')"
      width="440px"
      @close="togglePeriodDialog = false"
      @updated="refresh"
    />

    <!-- 預約新增 Dialog -->
  </div>
</template>

<script>
import { defineComponent, ref, reactive, onMounted, computed, set } from 'vue'
import { GetResourceItem, GetResourceItemCount, GetPeriods, GetMoreSchedule } from '@/api/resource'
import { timesConvert } from '@/utils/helper'
import { FindReservationParameter } from '@/api/reservation'
import { pageStartIndex } from '@/utils/table'
import EmptyBlock from '@/components/EmptyBlock.vue'
import TogglePeriodDialog from '@/components/Dialog/ToggleResourcePeriodDialog.vue'
import dayjs from '@/lib/dayjs'
import { getDay } from '@/utils/date'
import { get, map, min, max, filter } from 'lodash'
import store from '@/store'
import { i18n } from '@/plugins/i18n/i18n'

export default defineComponent({
  name: 'ResourceTimeSpace',
  components: {
    EmptyBlock,
    TogglePeriodDialog,
  },
  setup () {
    const searchDate = ref(new Date())
    const show = ref([])
    const unit = ref('')
    const times = ref([])
    const loading = ref(false)
    const unitTimesList = ref([])
    const resourceItemList = ref([])
    const resourceItemCount = ref(0)

    const tableOptions = reactive({
      page: 1,
      pageLimit: 10,
    })

    const reservationConfig = ref({})
    const togglePeriodDialog = ref(false)

    const shopId = computed(() => get(store.getters, 'shop'))
    const startIndex = computed(() => pageStartIndex(tableOptions.page, tableOptions.pageLimit))

    const genTimes = async () => {
      const end = 1440
      const unit = get(reservationConfig.value, 'timeUnit')
      const offset = get(reservationConfig.value, 'timeUnitOffset')
      let time = 0
      while (time + offset < end) {
        times.value.push(timesConvert(time + offset))
        time += unit
      }
    }

    const findReservationParameter = async () => {
      try {
        reservationConfig.value = await FindReservationParameter({
          shopId: shopId.value,
        })
      } catch (error) {
        console.log(error)
        window.$message.error(error)
      }
    }

    const getResourceItem = async () => {
      const [res, err] = await GetResourceItem({
        shopId: shopId.value,
        start: startIndex.value,
        limit: tableOptions.pageLimit,
      })
      resourceItemList.value = res
      if (err) window.$message.warning(err)
    }

    const getResourceItemCount = async () => {
      const [res, err] = await GetResourceItemCount({ shopId: shopId.value })
      resourceItemCount.value = get(res, 'count')
      if (err) window.$message.warning(err)
    }

    const findResourceUnit = async () => {
      // cal api once
      show.value = []
      loading.value = true
      const { start, end } = getDay(searchDate.value)
      const [res, err] = await GetMoreSchedule({
        shopId: shopId.value,
        resourceItemIds: map(resourceItemList.value, 'id'),
        start,
        end,
      })
      if (err) window.$message.error(err)
      if (res) {
        resourceItemList.value.map(async (item) => {
          set(item, 'availableTimes', [])
          const target = res.data.find(obj => obj.resourceItem.id === item.id)
          if (target) {
            set(item, 'availableTimes', target)
            show.value.push(
              ...map(target.times, (item) => {
                return dayjs(item.start).format('HH:mm')
              }),
            )
            show.value = Array.from(new Set(show.value))
          }
        })
      }
      loading.value = false

      // show.value = []
      // loading.value = true
      // try {
      //   const resourceItemPromises = resourceItemList.value.map(async (unit) => {
      //     try {
      //       set(unit, 'availableTimes', [])
      //       const { start, end } = getDay(searchDate.value)
      //       const [res, err] = await GetPeriods({
      //         shopId: shopId.value,
      //         id: unit.id,
      //         start,
      //         end,
      //       })

      //       if (err) {
      //         window.$message.error(err)
      //       } else {
      //         set(unit, 'availableTimes', res)
      //         show.value.push(
      //           ...map(res.times, (item) => {
      //             return dayjs(item.start).format('HH:mm')
      //           }),
      //         )
      //         show.value = Array.from(new Set(show.value))
      //       }
      //     } catch (error) {
      //       console.error(error)
      //     }
      //   })

      //   await Promise.all(resourceItemPromises)
      // } finally {
      //   loading.value = false
      // }
    }

    const refresh = async () => {
      loading.value = true
      await findReservationParameter()
      await getResourceItem()
      await getResourceItemCount()
      await findResourceUnit()
      loading.value = false
    }

    const showColumn = () => {
      if (!show.value.length) return times.value
      const timeToNum = (time) => {
        const hm = time.split(':')
        return hm[0] * 60 + Number(hm[1])
      }
      const temp = map(show.value, (item) => {
        return timeToNum(item)
      })
      const minPeriod = min(temp)
      const maxPeriod = max(temp)
      // console.log(temp)
      return filter(times.value, (item) => {
        const num = timeToNum(item)
        return num >= minPeriod && num <= maxPeriod
      })
    }

    const periodBlock = (scope) => {
      if (!get(scope, 'row.availableTimes')) return { status: '' }
      const times = scope.row.availableTimes.times
      const label = scope.column.label
      if (!times || times.length === 0) return { status: 'empty' }
      const res = times.find((item) => {
        const time = dayjs(item.start).format('HH:mm')
        if (time === '00:00' && label === '24:00') return true
        return time === label
      })
      if (!res) return { status: 'empty' }
      else return { status: res.status, availableVolume: res.availableVolume }
    }

    const trans = ({ status, availableVolume }) => {
      const dict = {
        empty: i18n.t('shiftCalendar.status.empty.text'),
        available: i18n.t('shiftCalendar.status.available.text'),
        booking: i18n.t('shiftCalendar.status.booking.text'),
        ban: i18n.t('shiftCalendar.status.ban.text'),
      }

      return availableVolume ? `${dict[status]}(${availableVolume})` : dict[status]
    }

    onMounted(async () => {
      await refresh()
      genTimes()
    })
    return {
      searchDate,
      show,
      unit,
      times,
      loading,
      unitTimesList,
      resourceItemList,
      resourceItemCount,
      tableOptions,
      reservationConfig,
      togglePeriodDialog,
      shopId,
      startIndex,
      trans,
      showColumn,
      periodBlock,
      refresh,
    }
  },
})
</script>

<style scoped lang="scss">
.block {
  border-radius: 8px;
  padding: 5px;
  text-align: center;
}

.empty {
  color: var(--gray-60);
  background: var(--gray-40);
}

.ban {
  color: var(--gray-60);
  background: var(--gray-40);
}

.booking {
  color: white;
  background: var(--gray-80);
}

.available {
  color: white;
  background: #00CD20;
}
</style>
