import { admin, admin2 } from '@/api/instance'

//= > 新增設備項目
export const CreateResource = async ({
  shopId,
  enabled,
  name,
  image,
  order,
  enableTimeOff,
  bookingMax,
  appointmentServices,
  timeOffConfig,
}) => {
  const res = await admin2({
    method: 'post',
    url: `/${shopId}/resourceItem`,
    data: {
      enabled,
      name,
      image,
      order,
      bookingMax,
      enableTimeOff,
      appointmentServices,
      timeOffConfig,
    },
  })

  return res
}

//= > 更新設備項目
export const UpdateResource = async ({
  shopId,
  id,
  enabled,
  name,
  image,
  order,
  enableTimeOff,
  bookingMax,
  appointmentServices,
  timeOffConfig,
}) => {
  const res = await admin2({
    method: 'put',
    url: `/${shopId}/resourceItem/${id}`,
    data: {
      enabled,
      name,
      image,
      order,
      bookingMax,
      enableTimeOff,
      appointmentServices,
      timeOffConfig,
    },
  })
  return res
}

//= > 取得設備項目列表
export const GetResourceItem = async ({
  shopId,
  start,
  limit,
  name,
}) => {
  const res = await admin2({
    method: 'get',
    url: `/${shopId}/resourceItem`,
    params: {
      start,
      limit,
      name,
    },
  })

  return res
}

//= > 取得設備項目數量
export const GetResourceItemCount = async ({
  shopId,
  name,
  isRemove,
}) => {
  const res = await admin2({
    method: 'get',
    url: `/${shopId}/resourceItem/count`,
    params: {
      name,
      isRemove,
    },
  })

  return res
}

//= > 取得設備項目
export const FindResourceItem = async ({
  shopId,
  id,
}) => {
  const res = await admin2({
    method: 'get',
    url: `/${shopId}/resourceItem/${id}`,
  })
  return res
}

//= > 刪除設備項目
export const DeleteResourceItem = async ({
  shopId,
  id,
}) => {
  const res = await admin2({
    method: 'delete',
    url: `/${shopId}/resourceItem/${id}`,
  })
  return res
}

//= > 取得設備模組設定
export const GetResourceConfig = async ({
  shopId,
}) => {
  const res = await admin2({
    method: 'get',
    url: `/${shopId}/resourceConfig`,
  })
  return res
}

//= > 更新設備模組設定
export const UpdateResourceConfig = async ({
  shopId,
  assignRule,
  pageText,
  clientSkipSelect,
}) => {
  const res = await admin2({
    method: 'put',
    url: `/${shopId}/resourceConfig`,
    data: {
      assignRule,
      pageText,
      clientSkipSelect,
    },
  })
  return res
}

//= > 查詢設備項目的預約空檔時段
export const GetPeriods = async ({
  shopId,
  id,
  start,
  end,
}) => {
  const res = await admin2({
    method: 'get',
    url: `${shopId}/resourceItem/${id}/schedule`,
    params: {
      start,
      end,
    },
  })

  return res
}

//= > 更新設備項目關閉時段
export const UpdatePeriods = async ({
  shopId,
  id,
  operates,
}) => {
  const res = await admin2({
    method: 'put',
    url: `${shopId}/resourceItem/${id}/scheduleBan`,
    data: {
      operates,
    },
  })

  return res
}

//= > 查詢設備項目的預約空檔時段

export const GetMoreSchedule = async ({
  shopId,
  resourceItemIds,
  start,
  end,
}) => {
  const res = await admin2({
    method: 'post',
    url: `${shopId}/resourceItem/moreSchedule`,
    data: {
      resourceItemIds,
      start,
      end,
    },
  })

  return res
}
