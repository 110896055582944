<template>
  <div class="sales-pos-order-discount-methods">
    <div class="discount-group-wrapper">
      <p class="discount-group-label">優惠</p>
      <div v-if="showControl.normal" class="flex flex-col gap-[6px] overflow-y-auto h-full" style="padding-bottom: 12px">
        <div
          v-for="item in displayNormalDiscount"
          :key="item.id"
          class="discount-item"
          :class="{isSelected: isSelected(item.id), isDisabled: disabledControl(item.id)}"
          @click="onSelectDiscount(item)"
        >
          {{ item.name }}
        </div>
      </div>

      <div v-if="!showControl.normal" class="text-gray-60 text-center flex justify-center items-center h-full">
        尚未設定
      </div>
    </div>
    <div v-if="showControl.memberDiscount" class="discount-group-wrapper overflow-y-auto">
      <div class="flex flex-col" style="gap: 24px">
        <SalesPOSCheckoutCashbackDiscountList v-if="showControl.cashback" />
        <SalesPOSCheckoutPointDiscountList v-if="showControl.point" />
        <SalesPOSCheckoutCouponDiscountList v-if="showControl.coupon" />
        <SalesPOSCheckoutClassTicketDiscountList v-if="showControl.classTicket" />
      </div>
    </div>

    <SalesPOSManualCashDiscountModal
      v-if="modal.manualDiscount"
      scope="orderDiscount"
      @close="modal.manualDiscount = false"
      @confirm="onManualDiscountConfirm"
    />
    <SalesPOSManualCashDiscountModal
      v-if="modal.customDiscount"
      scope="orderDiscount"
      @close="modal.customDiscount = false"
      @confirm="onCustomDiscountConfirm"
    />
  </div>
</template>

<script>
import { useSalesPOSCreate } from '@/use/useSalesPOS'
import { find, filter, get } from 'lodash'

import { computed, defineComponent, reactive, ref } from 'vue'
import SalesPOSCheckoutCashbackDiscountList from './SalesPOSCheckoutCashbackDiscountList.vue'
import SalesPOSCheckoutPointDiscountList from './SalesPOSCheckoutPointDiscountList.vue'
import SalesPOSCheckoutCouponDiscountList from './SalesPOSCheckoutCouponDiscountList.vue'
import SalesPOSCheckoutClassTicketDiscountList from './SalesPOSCheckoutClassTicketDiscountList .vue'
import SalesPOSManualCashDiscountModal from '@/components/SalesPOSManualCashDiscountModal.vue'
import { usePermissions } from '@/use/permissions'

export default defineComponent({
  name: 'SalesPOSOrderDiscountMethods',
  components: {
    SalesPOSCheckoutPointDiscountList,
    SalesPOSCheckoutCashbackDiscountList,
    SalesPOSCheckoutCouponDiscountList,
    SalesPOSCheckoutClassTicketDiscountList,
    SalesPOSManualCashDiscountModal,
  },
  setup (props, { emit }) {
    const { sortedMenus, context, configData, addOrderDiscount, orderUnpaidPrice } = useSalesPOSCreate()
    const modal = reactive({
      pointPartDiscount: false,
      manualDiscount: false,
      customDiscount: false,
    })
    const { checkAction } = usePermissions()
    const selectDiscount = ref(null)
    const useFeatures = computed(() => {
      return {
        classTicket: checkAction('admin.classTicket.page'),
        coupon: checkAction('admin.coupon.page'),
      }
    })

    const showControl = computed(() => {
      const controls = {
        normal: true,
        memberDiscount: true,
        cashback: get(configData, 'enableCashbackDiscount'),
        point: get(configData, 'enablePointDiscount'),
        classTicket: useFeatures.value.classTicket,
        coupon: useFeatures.value.coupon,
      }

      if (!get(context.member, 'id')) {
        controls.point = false
        controls.coupon = false
        controls.classTicket = false
        controls.cashback = false
      }

      if (!displayNormalDiscount.value.length) {
        controls.normal = false
      }

      const memberId = get(context, 'member.id')

      if (!memberId) {
        controls.memberDiscount = false
      }

      if (!controls.cashback && !controls.point && !controls.classTicket && !controls.coupon) {
        controls.memberDiscount = false
      }

      return controls
    })

    const displayNormalDiscount = computed(() => {
      const dataList = sortedMenus.value.discount
      const showList = filter(dataList, { mustSpendSetting: null })
      return filter(showList, (item) => item.type !== 'ezAIOTrust')
    })

    const isSelected = computed(() => {
      return (id) => {
        return find(context.orderDiscount, { id })
      }
    })

    const disabledControl = computed(() => {
      return (id) => {
        if (!isSelected.value(id)) {
          if (orderUnpaidPrice.value <= 0) {
            return true
          }
        }
      }
    })

    const onSelectDiscount = (item) => {
      if (disabledControl.value(item.id)) return
      selectDiscount.value = item
      const isSelected = find(context.orderDiscount, { id: item.id })
      if (isSelected) {
        context.orderDiscount = filter(context.orderDiscount, (i) => i.id !== item.id)
      } else {
        if (item.type === 'free') {
          modal.customDiscount = true
          return
        }
        if (item.id === 'manual') {
          modal.manualDiscount = true
        } else {
          addOrderDiscount({
            ...item,
            discountSource: 'discount',
            salesDiscountId: item.id,
          })

          context.payments = filter(context.payments, { type: 'paidDeposit' })
        }
      }
    }

    const onManualDiscountConfirm = (data) => {
      addOrderDiscount({
        ...data,
        discountSource: 'noSource',
      })
      context.payments = filter(context.payments, { type: 'paidDeposit' })
    }

    const onCustomDiscountConfirm = (data) => {
      addOrderDiscount({
        ...data,
        id: selectDiscount.value.id,
        name: selectDiscount.value.name,
        salesDiscountId: selectDiscount.value.id,
        discountSource: 'discount',
      })
    }

    return {
      displayNormalDiscount,
      isSelected,
      onSelectDiscount,
      context,
      modal,
      showControl,
      onManualDiscountConfirm,
      onCustomDiscountConfirm,
      disabledControl,
      get,
    }
  },
})
</script>

<style lang="postcss" scoped>
.sales-pos-order-discount-methods {
  @apply flex gap-[12px];
}

.discount-group-label {
  @apply font-bold leading-[23.17px] text-primary-100 select-none;
}

.discount-item {
  @apply p-[8px] font-bold text-sub leading-[20.27px] text-gray-80;
  @apply select-none cursor-pointer;
}

.isSelected {
  @apply bg-primary-30 text-primary-100;
}

.isDisabled {
  @apply opacity-50 cursor-not-allowed;
}

.discount-group-wrapper {
  @apply rounded-[6px] bg-white p-[12px] w-full;
}
</style>
