<template>
  <div class="">
    <p class="text-primary-100 font-bold text-normal">堂票</p>
    <div>
      <div
        class="classTicket-discount-item"
        :class="{ isSelected }"
        @click="onSelect"
      >
        堂票
      </div>
    </div>

    <SalesPOSClassTicketDiscountSelectModal
      v-if="showModal"
      scope="orderDiscount"
      multiple
      @close="showModal = false"
      @confirm="onConfirm"
    />
  </div>
</template>

<script>
import SalesPOSClassTicketDiscountSelectModal from '@/components/SalesPOSClassTicketDiscountSelectModal.vue'
import { useSalesPOSCreate } from '@/use/useSalesPOS'
import { computed, defineComponent, ref } from 'vue'
import { find, first, isEmpty, forEach, get } from 'lodash'

export default defineComponent({
  name: 'SalesPOSCheckoutClassTicketDiscountList',
  components: {
    SalesPOSClassTicketDiscountSelectModal,
  },
  setup (props, { emit }) {
    const { context, addOrderDiscount, removeOrderDiscount, configData } = useSalesPOSCreate()
    const showModal = ref(false)
    const isSelected = computed(() => {
      return Boolean(find(context.orderDiscount, { id: 'classTicket' }))
    })

    const onSelect = () => {
      if (isSelected.value) {
        removeOrderDiscount({ id: 'classTicket' })
        return
      }
      showModal.value = true
    }

    const onConfirm = (data) => {
      if (!data || isEmpty(data)) return

      forEach(data, (item) => {
        addOrderDiscount({
          id: 'classTicket',
          name: item.name,
          mustSpendSetting: {
            type: 'classTicket',
            classTicketRecordId: item.id,
            amount: 1,
          },
          discountSource: 'noSource',
          type: 'classTicket',
          scope: 'orderDiscount',
        })
      })
      showModal.value = false
    }

    return { isSelected, showModal, onSelect, onConfirm }
  },
})
</script>

<style lang="postcss" scoped>
.classTicket-discount-item {
  @apply font-bold text-sub text-gray-80 p-[8px] select-none cursor-pointer;
}
.isSelected {
  @apply bg-primary-30 text-primary-100;
}
</style>
