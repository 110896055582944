<template>
  <div class="service-info">
    <p class="font-bold text-lg text-primary-100 mb-[24px]">預約資訊</p>
    <BaseElForm
      ref="form"
      :model="formData"
      :rules="formRules"
      label-position="top"
    >
      <BaseElFormItem v-if="checkResource" label="服務性質" prop="type">
        <BaseElSelect
          v-model="formData.type"
          value-key="id"
          testName="formData_type"
          placeholder="請選擇"
          no-data-text="暫無數據"
          @change="serviceTypeChange"
        >
          <BaseElSelectOption
            v-for="item in showServiceTypeConfig"
            :key="item.value"
            :label="$t(item.label)"
            :value="item.value"
          />
        </BaseElSelect>
      </BaseElFormItem>
      <BaseElFormItem v-if="showHumanService" label="服務人員" prop="serviceUnit">
        <template slot="label">
          <FormItemTooltipLabel label="服務人員" :tooltipWidth="200" style="display: flex !important">
            <ol>
              <li>1. 由所有服務人員分配：由分店內所有服務人員分配</li>
              <br>
              <li>2. 由不指定人員分配：由綁定在不指定人員中之服務人員分配</li>
            </ol>
          </FormItemTooltipLabel>
        </template>
        <div>
          <BaseElSelect
            v-model="formData.serviceUnit"
            testName="formData_serviceUnit"
            value-key="id"
            filterable
            clearable
            @change="serviceUnitChange"
            @clear="serviceUnitChange"
          >
            <BaseElSelectOption
              v-for="unit in serviceUnitIOptions"
              :key="unit.id"
              :value="unit"
              :label="unit.name"
            />
          </BaseElSelect>
          <p
            v-show="serviceUnitSelectHint.content"
            :style="`color: ${serviceUnitSelectHint.color}`"
          >
            {{ serviceUnitSelectHint.content }}
          </p>
        </div>
      </BaseElFormItem>
      <BaseElFormItem label="服務項目" prop="service">
        <BaseElSelect
          v-model="formData.service"
          testName="formData_service"
          value-key="id"
          filterable
          :disabled="disabledInput.service"
          clearable
          @change="serviceChange"
          @clear="serviceChange"
        >
          <BaseElSelectOption
            v-for="service in filterServiceOptions"
            :key="service.id"
            :value="service"
            :label="service.name"
          />
        </BaseElSelect>
      </BaseElFormItem>
      <BaseElFormItem v-if="enableSubService" label="子項目" prop="subService">
        <BaseElSelect
          v-model="formData.subService"
          testName="formData_subService"
          value-key="id"
          :disabled="disabledInput.service"
          @change="subServiceChange"
        >
          <BaseElSelectOption
            v-for="service in subServiceList"
            :key="service.id"
            :label="service.name"
            :value="service"
          />
        </BaseElSelect>
      </BaseElFormItem>
      <BaseElFormItem label="附加服務" prop="attachServices">
        <AttachServiceSelect
          testName="formData_attachServices"
          :model.sync="formData.attachServices"
          :disabled="disabledInput.attachService"
          :services="formData.service"
          multiple
          @change="attachServiceChange"
        />
      </BaseElFormItem>
      <BaseElFormItem v-if="showResourceService && checkResource" label="服務設備" prop="resourceItemId">
        <template slot="label">
          <div class="flex items-center" style="gap: 8px">
            <p>服務設備</p>
          </div>
        </template>
        <ResourceUnitSelect
          testName="formData_resourceItemId"
          :model.sync="formData.resourceItemId"
          :data="resourceItemList"
          :showAll="true"
        />
      </BaseElFormItem>

      <BaseElFormItem label="預估服務時數(分鐘)" prop="totalTime">
        <BaseElSelect
          v-model="formData.totalTime"
          testName="formData_totalTime"
          placeholder="請選擇"
          :disabled="disabledInput.totalBookingTime"
          @change="totalTimeChange"
        >
          <BaseElSelectOption
            v-for="(item, index) in genNums"
            :key="index"
            :label="item"
            :value="item"
          />
        </BaseElSelect>
      </BaseElFormItem>
      <BaseElFormItem v-if="showHumanAndResourceService && checkResource" label="預估設備使用時數(分鐘)" prop="resourceTotalTime">
        <template slot="label">
          <div class="flex items-center" style="gap: 8px">
            <p>預估設備使用時數(分鐘)</p>
            <el-tooltip placement="right">
              <div slot="content">
                使用設備的起始時間與服務項目開<br>
                始時間一致。可調整此欄位的數<br>
                值，提前結束設備使用的時間
              </div>
              <span class="material-icons">help_outline</span>
            </el-tooltip>
          </div>
        </template>
        <div
          v-if="!formData.totalTime"
          class="text-gray-100"
        >
          請先選擇預估服務時數
        </div>
        <BaseElSelect
          v-else
          v-model="formData.resourceTotalTime"
          testName="formData_resourceTotalTime"
          placeholder="請選擇"
        >
          <BaseElSelectOption
            v-for="(item, index) in getResourceNums"
            :key="index"
            :label="item"
            :value="item"
          />
        </BaseElSelect>
      </BaseElFormItem>
      <BaseElFormItem label="是否為加班時段">
        <BaseElRadioGroup
          v-model="formData.isOverTime"
          testName="formData_isOverTime"
          :disabled="disabledInput.isOverTime"
          @change=";(formData.time = ''), (formData.date = '')"
        >
          <BaseElRadio :label="true" data-cy="main-overTime-yes">是</BaseElRadio>
          <BaseElRadio :label="false" data-cy="main-overTime-no">否</BaseElRadio>
        </BaseElRadioGroup>
      </BaseElFormItem>
      <hr style="margin-bottom: 16px">
      <BaseElFormItem label="預約日期" prop="date">
        <div
          v-if="!formData.service || !formData.type"
          class="text-gray-100"
        >
          請先選擇服務性質與服務項目
        </div>
        <el-date-picker
          v-if="formData.service && formData.type"
          ref="picker"
          v-model="formData.date"
          editable
          :picker-options="pickerOptions"
          type="date"
          placeholder="選擇日期"
          @focus="getDate"
          @change="dateChange"
        />
      </BaseElFormItem>
      <BaseElFormItem label="預約時段" prop="time">
        <div v-if="!formData.date" class="text-gray-100">請先選擇預約日期</div>
        <AvailableTimeSelect
          v-if="formData.date && !formData.isOverTime"
          data-testid="el-select_formData_time"
          :model.sync="formData.time"
          :timesList="availableTimes"
        />
        <!-- <ServiceUnitTimeSelect
          v-if="formData.date && !formData.isOverTime"
          :model.sync="formData.time"
          :date="formData.date"
          :unit="formData.serviceUnit"
          :totalTime="formData.totalTime"
        /> -->
        <BaseElSelect
          v-if="formData.date && formData.isOverTime"
          v-model="formData.time"
          testName="formData_time"
          placeholder="請選擇"
          no-data-text="無數據"
        >
          <BaseElSelectOption
            v-for="(item, index) in genTimes()"
            :key="index"
            :label="item"
            :value="item"
          />
        </BaseElSelect>
      </BaseElFormItem>
      <BaseElFormItem
        v-if="peopleCount === 1 && config.reservation.allowOtherReservation && formData.type === 'humanService'"
        label="是否有當日其他服務"
      >
        <BaseElRadioGroup v-model="formData.otherService" testName="formData_have_otherService">
          <BaseElRadio :label="true">是</BaseElRadio>
          <BaseElRadio :label="false">否</BaseElRadio>
        </BaseElRadioGroup>
      </BaseElFormItem>
    </BaseElForm>
  </div>
</template>

<script>
// import UnitServicesSelect from '@/components/Select/UnitServicesSelect.vue'
import FormItemTooltipLabel from '@/components/Form/FormItemTooltipLabel.vue'
// import ServicesUnitSelect from '@/components/Select/ServicesUnitSelect.vue'
// import ServiceUnitTimeSelect from '@/components/Select/ServiceUnitTimeSelect.vue'
import AttachServiceSelect from '@/components/Select/AttachServiceSelect.vue'
import AvailableTimeSelect from '@/components/Reservation/AvailableTimeSelect/AvailableTimeSelect.vue'
import ResourceUnitSelect from '@/components/Select/ResourceUnitSelect.vue'
import { computed, defineComponent, onMounted, ref, reactive, unref, nextTick, watch } from 'vue'
// api
import { GetAvailableTimes } from '@/api/reservation'
import { FindService, GetService } from '@/api/service'
import { FindServiceUnit, GetServiceUnit } from '@/api/serviceUnit'
// utils
import { generateFormatPeriod } from '@/utils/time'
import { generateTimes, generateNumbers, sortOrder } from '@/utils/helper'
import { setValues } from '@/utils/object'
import { getMonth, thisMonth } from '@/utils/date'
import { noEmptyRules } from '@/validation'
import { get, filter, map, find, omit, includes, isEmpty } from 'lodash'
import dayjs from '@/lib/dayjs'
import { notSpecifyRulesConfig, noSpecifyUnitOrderOptionConfig, serviceTypeConfig } from '@/config/reservation'
import { useFetch } from '@/use/fetch'
import { useShop } from '@/use/shop'
import { usePermissions } from '@/use/permissions'
import store from '@/store'

export default defineComponent({
  name: 'ServiceInfo',
  components: {
    // UnitServicesSelect,
    // ServicesUnitSelect,
    // ServiceUnitTimeSelect,
    FormItemTooltipLabel,
    ResourceUnitSelect,
    AvailableTimeSelect,
    AttachServiceSelect,
  },
  props: {
    peopleCount: {
      type: Number,
      default: 0,
    },
    config: {
      type: Object,
      default: () => ({}),
    },
    reservation: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['update', 'serviceUnitChange', 'serviceChange', 'subServiceChange'],
  setup (props, { emit }) {
    const { checkAction } = usePermissions()
    const { fetchAllOld } = useFetch()
    const { shopId } = useShop()
    const genNums = ref([])
    const getResourceNums = ref([])
    const allServiceUnitList = ref([])
    const allServiceList = ref([])
    const mainServiceData = ref(null)
    const serviceOptions = ref([])
    const availableDate = ref([])
    const availableTimes = ref([])
    const picker = ref(null)
    const form = ref(null)
    const formData = reactive({
      otherService: false,
      service: null,
      serviceDetail: null,
      subService: null,
      attachServices: null,
      serviceUnit: null,
      totalTime: 0,
      resourceItemId: null,
      resourceTotalTime: 0,
      isOverTime: false,
      date: '',
      time: '',
      type: 'humanService',
    })
    const pickerOptions = ref({
      disabledDate: (date) => {
        if (formData.isOverTime) return false
        const d = dayjs(date).format('YYYY/MM/DD')
        return !find(availableDate.value, (item) => item === d)
      },
    })
    const serviceUnitIOptions = computed(() => {
      const base = map(allServiceUnitList.value, (serviceUnit) => {
        return {
          id: serviceUnit.id,
          name: serviceUnit.name,
        }
      })
      if (['customRole:shopOperationDisableSchedule', 'shopOperation'].includes(role.value)) return base
      base.unshift({
        id: 'notSpecify',
        name: '由不指定人員分配',
      })
      base.unshift({
        id: 'all',
        name: '由所有服務人員分配',
      })
      return base
    })
    const serviceUnitSelectHint = computed(() => {
      const useNotSpecify = get(props.config, 'reservation.useNotSpecify')
      const notSpecifyRule = get(props.config, 'reservation.notSpecifyRule')
      const selectUnitId = get(formData.serviceUnit, 'id')
      if (!includes(['notSpecify', 'all'], selectUnitId)) return { content: '', color: 'var(--base)' }

      if (useNotSpecify) {
        const rule = get(notSpecifyRulesConfig, `${notSpecifyRule}.label`)
        return { content: `分配規則預設為「${rule}」如需修改請至基本參數設定 > 預約模組參數設定設置`, color: 'var(--base)' }
      } else if (!useNotSpecify) {
        if (selectUnitId === 'all') return { content: '分配規則預設為「依排序大小」如需修改請至基本參數設定 > 預約模組參數設定設置', color: 'var(--base)' }
        else if (selectUnitId === 'notSpecify') return { content: '未啟用不指定人員設定，請至基本參數設定 > 預約模組參數設定設置', color: 'var(--danger)' }
      }
      return { content: '', color: 'var(--base)' }
    })
    const shopTimeUnit = computed(() => get(store.getters, 'shopTimeUnit'))
    const role = computed(() => get(store.getters, 'role'))
    const disabledInput = computed(() => {
      const base = {
        serviceType: false,
        serviceUnit: false,
        service: false,
        attachService: false,
        totalBookingTime: false,
        isOverTime: false,
      }
      let disableds = []
      const useNotSpecify = get(props.config, 'reservation.useNotSpecify')
      const selectUnitId = get(formData.serviceUnit, 'id')
      if (!useNotSpecify && selectUnitId === 'notSpecify') disableds = ['service', 'attachService', 'totalBookingTime', 'isOverTime']
      setValues(base, disableds, true)
      return base
    })
    const subServiceList = computed(() => {
      if (!mainServiceData.value) return []
      else if (get(formData, 'type') === 'resourceService') return get(mainServiceData.value, 'AppointmentSubServices')
      else if (includes(['notSpecify', 'all'], get(formData, 'serviceUnit.id'))) return get(mainServiceData.value, 'AppointmentSubServices')
      else return get(mainServiceData.value, 'AppointmentSubServices').filter(s => find(s.AppointmentUnits, { id: get(formData, 'serviceUnit.id') }) || isEmpty(s.AppointmentUnits))
    })
    const enableSubService = computed(() => get(mainServiceData.value, 'enableSubService'))
    const enableResourceItem = computed(() => get(mainServiceData.value, 'enableResourceItem'))
    const checkResource = computed(() => checkAction('admin.resourceItem.page'))
    const showHumanService = computed(() => get(formData, 'type') === 'humanService' || get(formData, 'type') === 'humanAndResourceService')
    const showHumanAndResourceService = computed(() => get(formData, 'type') === 'humanAndResourceService')

    const showServiceTypeConfig = computed(() => {
      const omitList = []
      if (props.peopleCount > 1 || !checkAction('admin.resourceItem.page')) omitList.push('resourceService', 'humanAndResourceService')
      return omit(serviceTypeConfig, omitList)
    })

    const formRules = computed(() => {
      return {
        type: [noEmptyRules()],
        service: [noEmptyRules()],
        subService: [noEmptyRules()],
        serviceUnit: [noEmptyRules()],
        date: [noEmptyRules()],
        time: [noEmptyRules()],
        resourceItemId: [noEmptyRules()],
        resourceTotalTime: [noEmptyRules()],
      }
    })
    watch(() => props.reservation, async () => {
      await syncData()
    })
    watch(formData, (data) => {
      emit('update', data)
    }, { deep: true })
    const syncData = async () => {
      if (!isEmpty(props.reservation)) {
        const appointmentUnit = get(props.reservation, 'AppointmentUnit')
        const resourceItem = get(props.reservation, 'ResourceItem')
        if (get(props.reservation, 'AppointmentUnit')) {
          formData.serviceUnit = get(props.reservation, 'AppointmentUnit')
          // await nextTick()
          serviceUnitChange(formData.serviceUnit)
        }
        if (get(props.reservation, 'AppointmentService')) {
          formData.service = get(props.reservation, 'AppointmentService')
          // await nextTick()
          serviceChange(formData.service)
        }
        if (get(props.reservation, 'AppointmentSubService')) {
          formData.subService = get(props.reservation, 'AppointmentSubService')
        }
        if (get(props.reservation, 'AppointmentServiceAttaches')) {
          formData.attachServices = get(props.reservation, 'AppointmentServiceAttaches')
        }
        if (get(props.reservation, 'ResourceItemId')) {
          formData.resourceItemId = get(props.reservation, 'ResourceItem')
        }
        if (appointmentUnit && !resourceItem) {
          formData.type = 'humanService'
          formData.totalTime = get(props.reservation, 'totalBookingTime')
        } else if (!appointmentUnit && resourceItem) {
          formData.type = 'resourceService'
          formData.totalTime = get(props.reservation, 'resourceItemTotalBookingTime')
        } else if (appointmentUnit && resourceItem) {
          formData.type = 'humanAndResourceService'
          formData.totalTime = get(props.reservation, 'totalBookingTime')
          formData.resourceTotalTime = get(props.reservation, 'resourceItemTotalBookingTime')
        }
      }
    }
    const serviceChange = async (service) => {
      console.log('servicechange', service)
      findServiceDetail()
      formData.attachServices = []
      formData.subService = null
      formData.resourceItemId = null
      formData.totalTime = totalTime.value
      formData.resourceTotalTime = totalTime.value
      formData.date = ''
      formData.time = ''
      updateTimeOptions()
    }
    const findServiceDetail = async () => {
      const [res, err] = await FindService({
        shopId: shopId.value,
        id: formData.service.id,
      })
      if (err) {
        window.$message.error(err.msg)
        return
      }
      mainServiceData.value = res
      formData.serviceDetail = res
    }
    const getAvailableTimes = async ({ start, end }) => {
      let appointmentUnitIds = get(formData, 'serviceUnit.id') ? [get(formData, 'serviceUnit.id')] : null
      // const useNotSpecify = get(this.configData, 'useNotSpecify')
      if (appointmentUnitIds) {
        if (appointmentUnitIds.includes('all')) appointmentUnitIds = null
        else if (appointmentUnitIds.includes('notSpecify')) appointmentUnitIds = map(get(props.config, 'reservation.NotSpecifyUnits'), 'id')
      }

      console.log('NotSpecifyUnits', get(props.config, 'reservation.NotSpecifyUnits'))

      const [res] = await GetAvailableTimes({
        shopId: shopId.value,
        rangeStart: start.toDate(),
        rangeEnd: end.toDate(),
        appointmentUnitIds,
        resourceItemIds: formData.resourceItemId ? [get(formData.resourceItemId, 'id')] : null,
        appointmentServiceId:
          get(formData, 'service.id') || undefined,
        appointmentSubServiceId: get(formData, 'subService.id'),
        appointmentServiceAttachIds: map(
          get(formData, 'attachServices', []),
          'id',
        ),
        setTotalBookingTime: appointmentUnitIds ? formData.totalTime : undefined,
        setResourceItemTotalBookingTime: formData.resourceItemId ? formData.resourceTotalTime : undefined,
        peopleCount: props.peopleCount,
      })

      const list = []
      res.times.forEach((item) => {
        list.push(dayjs(item).format('YYYY/MM/DD'))
      })
      availableDate.value = Array.from(new Set(list))
      availableTimes.value = map(filter(res.times, (item) => {
        return dayjs(item).isSame(formData.date, 'date')
      }), i => dayjs(i).format('HH:mm'))
      // availableTimes.value = filter(res.times, (item) => {
      //   return dayjs(item).isAfter(dayjs(), 'h')
      // })
    }

    const dateChange = (date) => {
      const start = dayjs(date).startOf('d')
      const end = dayjs(date).add(1, 'd').startOf('d')
      formData.time = ''
      getAvailableTimes({
        start: dayjs(start),
        end: dayjs(end),
      })
    }
    const updateTimeOptions = () => {
      genNums.value = generateNumbers(0, 600, shopTimeUnit.value)
      genNums.value.push(totalTime.value)
      genNums.value = [...new Set(genNums.value.sort((a, b) => a - b))]
      getResourceNums.value = generateNumbers(0, formData.totalTime + shopTimeUnit.value, shopTimeUnit.value)
      getResourceNums.value.push(totalTime.value)
      getResourceNums.value = [...new Set(filter(getResourceNums.value.sort((a, b) => a - b), (item) => item <= formData.totalTime))]
    }
    const subServiceChange = (service) => {
      formData.subService = service
      formData.totalTime = totalTime.value
      formData.resourceTotalTime = totalTime.value
      updateTimeOptions()
      formData.resourceItemId = null
      formData.attachServices = []
      formData.date = ''
      formData.time = ''
    }
    const totalTimeChange = () => {
      if (formData.isOverTime) return
      formData.date = ''
      formData.resourceTotalTime = formData.totalTime
      updateTimeOptions()
    }
    const attachServiceChange = () => {
      formData.date = ''
      formData.time = ''
      formData.totalTime = totalTime.value
      formData.resourceTotalTime = totalTime.value
      updateTimeOptions()
    }
    const getData = (obj, path, defaultValue) => {
      return get(obj, path, defaultValue)
    }
    const genTimes = () => {
      const offset = get(props.config, 'reservation.timeUnitOffset')
      const timeUnit = get(props.config, 'reservation.timeUnit')
      return generateFormatPeriod({ gap: timeUnit, offset, maxEnd: 1440 })
    }

    const getDate = async () => {
      await nextTick()
      let dateStart
      let dateEnd

      if (get(picker.value, 'picker')) {
        const year = get(picker.value, 'picker.year')
        const month = get(picker.value, 'picker.month')
        const { start, end } = getMonth(year, month + 1)
        dateStart = start
        dateEnd = end
      } else {
        const { start, end } = thisMonth()
        dateStart = start
        dateEnd = end
      }

      // await this.getAvailableDate(dateStart, dateEnd)
      await getAvailableTimes({ start: dayjs(dateStart), end: dayjs(dateEnd) })
      // if (this.pickerAddEvent) return

      const pre = document.querySelector(
        '.el-picker-panel__icon-btn.el-date-picker__prev-btn.el-icon-arrow-left',
      )
      const next = document.querySelector(
        '.el-picker-panel__icon-btn.el-date-picker__next-btn.el-icon-arrow-right',
      )
      const preYear = document.querySelector(
        '.el-picker-panel__icon-btn.el-date-picker__prev-btn.el-icon-d-arrow-left',
      )
      const nextYear = document.querySelector(
        '.el-picker-panel__icon-btn.el-date-picker__next-btn.el-icon-d-arrow-right',
      )

      const task = async () => {
        availableDate.value = []

        const year = get(picker.value, 'picker.year')
        const month = get(picker.value, 'picker.month')

        const { start, end } = getMonth(year, month + 1)

        // await this.getAvailableDate(start, end)
        await getAvailableTimes({ start: dayjs(start), end: dayjs(end) })
      }
      pre.removeEventListener('click', task)
      next.removeEventListener('click', task)
      preYear.removeEventListener('click', task)
      nextYear.removeEventListener('click', task)

      pre.addEventListener('click', task)
      next.addEventListener('click', task)
      preYear.addEventListener('click', task)
      nextYear.addEventListener('click', task)
      // this.pickerAddEvent = true
    }
    const resourceItemList = computed(() => {
      const subService = get(mainServiceData.value, 'AppointmentSubServices')
      const result = filter(get(mainServiceData.value, 'ResourceItems'), (i) => i.enabled && !i.isRemove)
      if (subService && formData.subService) {
        if (get(subService, 'allResourceItem')) return result
        const subResourceItems = get(formData.subService, 'ResourceItems')
        if (isEmpty(subResourceItems)) return result
        else return filter(result, i => find(subResourceItems, { id: i.id }) && i.enabled && !i.isRemove)
      }
      return result
    })

    const showResourceService = computed(() => {
      return includes(['resourceService', 'humanAndResourceService'], formData.type)
    })
    const totalTime = computed(() => {
      const form = { ...formData }
      if (!form.service) return 0
      let base = 0

      if (!form.subService) base += form.service.bookingTime
      if (form.subService) base += form.subService.bookingTime

      let attach = 0
      if (form.attachServices) {
        form.attachServices.forEach((item) => {
          attach += item.bookingTime
        })
      }
      console.log(base, attach)
      return base + attach
    })

    const serviceTypeChange = async () => {
      formData.otherService = false
      formData.service = null
      formData.serviceDetail = null
      formData.subService = null
      formData.attachServices = null
      formData.serviceUnit = null
      formData.totalTime = 0
      formData.resourceItemId = null
      formData.resourceTotalTime = 0
      formData.isOverTime = false
      formData.date = null
      formData.time = null
    }
    const filterServiceOptions = computed(() => {
      let serviceOptionList = []
      if (checkAction('admin.resourceItem.page')) {
        if (formData.type === 'resourceService') {
          serviceOptionList = filter(serviceOptions.value, (i) => !i.enableAppointmentUnit && i.enableResourceItem)
        } else if (formData.type === 'humanService') {
          serviceOptionList = filter(serviceOptions.value, (i) => i.enableAppointmentUnit && !i.enableResourceItem)
        } else if (formData.type === 'humanAndResourceService') {
          serviceOptionList = filter(serviceOptions.value, (i) => i.enableAppointmentUnit && i.enableResourceItem)
        } else {
          serviceOptionList = serviceOptions.value
        }
      } else {
        serviceOptionList = serviceOptions.value
      }

      return serviceOptionList
    })

    const getServiceUnit = async () => {
      fetchAllOld(GetServiceUnit, { shopId: shopId.value }, (res) => {
        console.log('✅ res', res)
        allServiceUnitList.value = res
      })
    }

    const findServiceUnit = async (unitId) => {
      try {
        const res = await FindServiceUnit({
          shopId: shopId.value,
          id: unitId,
        })
        serviceOptions.value = sortOrder(res.AppointmentServices)
      } catch (error) {
        console.log(error)
        window.$message.error(error)
      }
    }

    const serviceUnitChange = (serviceUnit) => {
      emit('serviceUnitChange', serviceUnit)
      if (includes(['notSpecify', 'all'], serviceUnit.id)) {
        serviceOptions.value = unref(allServiceList.value)
      } else {
        if (serviceUnit.id) findServiceUnit(serviceUnit.id)
        else {
          serviceOptions.value = []
        }
      }

      // if (showResourceService.value) {
      //   serviceOptions.value = filter(serviceOptions.value, (i) => i.enableResourceItem)
      // }

      formData.totalTime = 0
      formData.service = ''
      formData.subService = null
      formData.attachServices = []
      formData.date = ''
      formData.time = ''
    }

    const testUpdate = (e, d) => {
      console.log('🔥testUpdate', e)
    }

    onMounted(async () => {
      await nextTick()
      console.log('here')
      emit('ref', form.value)
      getServiceUnit()
      if (!allServiceList.value.length) {
        fetchAllOld(GetService, { shopId: shopId.value }, (res) => {
          allServiceList.value = res
          serviceOptions.value = unref(allServiceList.value)
        })
      }
      await syncData()
    })

    return {
      testUpdate,
      formData,
      formRules,
      serviceUnitIOptions,
      allServiceUnitList,
      serviceUnitChange,
      allServiceList,
      serviceChange,
      mainServiceData,
      serviceOptions,
      showResourceService,
      serviceTypeChange,
      totalTime,
      resourceItemList,
      serviceUnitSelectHint,
      disabledInput,
      filterServiceOptions,
      genNums,
      getResourceNums,
      dateChange,
      subServiceChange,
      totalTimeChange,
      attachServiceChange,
      pickerOptions,
      picker,
      getData,
      genTimes,
      getDate,
      form,
      subServiceList,
      enableSubService,
      enableResourceItem,
      checkResource,
      showHumanService,
      showHumanAndResourceService,
      showServiceTypeConfig,
      getAvailableTimes,
      availableTimes,
    }
  },
})
</script>

<style scoped lang="postcss">
::v-deep .el-form-item__label {
  @apply flex
}
</style>
