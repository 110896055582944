<template>
  <div class="">
    <BaseAdavanceSelector
      :get-data-api="GetMembers"
      :get-data-count-api="GetMembersCount"
      :keyMap="{ id: 'id', name: 'UserInfo.name' }"
      multiple
    />
  </div>
</template>

<script>
import BaseAdavanceSelector from '@/components/Select/BaseAdavanceSelector.vue'
import { GetMembers, GetMembersCount } from '@/api/member'

import { defineComponent } from 'vue'
import { apiFormatAdaptor } from '@/utils/api'
export default defineComponent({
  name: 'Playground',
  components: {
    BaseAdavanceSelector,
  },
  setup (props) {
    const dataList = [
      { name: 'test1', id: 'type1' },
      { name: 'test2', id: 'type2' },
      { name: 'test3', id: 'type3' },
      { name: 'test4', id: 'type4' },
    ]

    return {
      dataList,
      GetMembers: apiFormatAdaptor(GetMembers),
      GetMembersCount: apiFormatAdaptor(GetMembersCount),
    }
  },
})
</script>

<style lang="postcss" scoped>

</style>
