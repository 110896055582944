<template>
  <div class="card-container" style="padding: 20px; padding-left: 30px">
    <p class="section-title">預約資訊</p>
    <AppointmentCoreFlowBlock
      :cartItem.sync="cart[0].cartItem"
    />
  </div>
</template>

<script>
import { useCreateAappointment } from '@/use/useCreateAppointment'
import { defineComponent } from 'vue'
import AppointmentCoreFlowBlock from './AppointmentCoreFlowBlock.vue'
export default defineComponent({
  name: 'AppointmentNormalMode',
  components: {
    AppointmentCoreFlowBlock,
  },
  setup (props, { emit }) {
    const { cart } = useCreateAappointment()
    return { cart }
  },
})
</script>

<style lang="postcss" scoped>
.section-title {
  @apply font-bold text-primary-100 text-[18px] mb-[24px];
}
</style>
