<template>
  <el-dialog
    :title="$t('orderPaymentBlock.dialog.markRefund.title')"
    :visible="true"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <BaseElForm ref="form" :rules="formRules" :model="formData" label-position="top">
      <BaseElFormItem :label="$t('markRefundDialog.markPrice.title')" prop="markPrice">
        <BaseElInput v-model="formData.markPrice" placeholder="請輸入金額" />
      </BaseElFormItem>
    </BaseElForm>
    <div class="hint" style="width: 560px">
      {{ $t('markRefundDialog.markPrice.hint.title')}}
    </div>

    <div slot="footer">
      <BaseElButton plain @click="$emit('close')">{{ $t('common.button.cancel.text')}}</BaseElButton>
      <BaseElButton ref="createBtn" v-loading="loading" type="primary" @click="markRefund">
        {{ $t('common.button.confirm.text')}}
      </BaseElButton>
    </div>
  </el-dialog>
</template>

<script>
import { noEmptyRules, isDigitRules, rangeRules } from '@/validation'
import formUtils from '@/utils/form'
import { OrderDepositRefund, OrderCheckoutRefund } from '@/api/reservation'
import { manualRefundPaymentType } from '@/config/deposit'
import { get } from 'lodash'
import { computed, reactive, ref } from 'vue'
import { useShop } from '@/use/shop'
import { i18n } from '@/plugins/i18n/i18n'

export default {
  name: 'MarkRefundDialog',
  props: {
    orderData: {
      type: Object,
      default: () => ({}),
    },
    depositData: {
      type: Object,
      default: () => ({}),
    },
    data: { type: Object, default: () => ({}) },
    chargeType: String,
    maxPrice: Number,
  },
  setup(props) {
    const { shopId } = useShop()
    const loading = ref(false)
    const formData = reactive({
      markPrice: null,
    })
    const formRules = computed(() => {
      const rules = {
        markPrice: [noEmptyRules(), isDigitRules()],
      }
      if (props.maxPrice) {
        rules.markPrice.push(rangeRules(1, props.maxPrice))
      }
      return rules
    })
    return { formRules, formData, loading, shopId }
  },
  methods: {
    async markRefund() {
      if (!(await formUtils.checkForm(this.$refs.form))) return

      let apiMethod
      const paidType = get(this.data, 'refundType')
      const comment = get(this.data, 'refundTypeComment')
      const type = get(manualRefundPaymentType[paidType], 'value')

      if (this.chargeType === 'deposit') {
        apiMethod = OrderDepositRefund
      } else if (this.chargeType === 'checkout') {
        apiMethod = OrderCheckoutRefund
      }

      try {
        this.loading = true
        await apiMethod({
          shopId: this.shopId,
          id: this.orderData.id,
          refundType: type,
          refundTypeComment: comment || undefined,
          refundPrice: this.formData.markPrice,
        })
        this.$message.success(i18n.t('common.message.markRefundSuccess'))
        this.$emit('marked')
        this.$emit('close')
        this.loading = false
      } catch (error) {
        this.$message.error(error)
        this.loading = false
      }
    },
  },
}
</script>

<style lang="postcss" scoped>
.hint {
  @apply text-danger break-all whitespace-normal text-sm w-auto leading-[17.38px] mt-[12px];
}
</style>
