<template>
  <BaseDialog
    title="會員卡顯示設定"
    class="member-center-show-edit-modal"
    :btn1Loading="loading"
    @close="$emit('close')"
    @confirm="updateFunctionMenu"
  >
    <BaseElForm ref="formRef" :model="formData" :rules="formRules">
      <BaseElFormItem label="排序一" prop="order1">
        <BaseElSelect v-model="formData.order1" placeholder="請選擇" clearable value-key="value">
          <BaseElSelectOption
            v-for="option in showOptions"
            :key="option.value"
            :label="option.label"
            :value="option"
            :disabled="disabledOption(option)"
          />
        </BaseElSelect>
      </BaseElFormItem>
      <BaseElFormItem label="排序二" prop="order2">
        <BaseElSelect v-model="formData.order2" placeholder="請選擇" clearable value-key="value">
          <BaseElSelectOption
            v-for="option in showOptions"
            :key="option.value"
            :label="option.label"
            :value="option"
            :disabled="disabledOption(option)"
          />
        </BaseElSelect>
      </BaseElFormItem>
      <BaseElFormItem label="排序三" prop="order3">
        <BaseElSelect v-model="formData.order3" placeholder="請選擇" clearable value-key="value">
          <BaseElSelectOption
            v-for="option in showOptions"
            :key="option.value"
            :label="option.label"
            :value="option"
            :disabled="disabledOption(option)"
          />
        </BaseElSelect>
      </BaseElFormItem>
    </BaseElForm>
  </BaseDialog>
</template>

<script>
import { defineComponent, computed, onMounted, ref } from 'vue'
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import { useBaseForm } from '@/use/useForm'
import { noEmptyRules } from '@/validation'
import { GetMemberCenterCardOptions, UpdateMemberCenterConfig } from '@/api/member/memberCenter'
import { get, values, find, map, uniq, uniqWith, isEqual } from 'lodash'
import formUtils from '@/utils/form'
import { useExternalWallet } from '@/use/useExternalWallet'
import { useShop } from '@/use/shop'
import { usePermissions } from '@/use/permissions'

export default defineComponent({
  name: 'MemberCardShowEditModal',
  components: { BaseDialog },
  props: {
    configData: { type: Array, default: () => [] },
  },
  emits: ['refresh', 'close'],
  setup (props, { emit }) {
    const { shopId, shopPointList } = useShop()
    const availableTypes = ref([])
    const { externalWalletName, getExternalWalletConfig } = useExternalWallet()
    const { checkAction, checkActionList } = usePermissions()
    const { initFormData, formData, formRef, loading } = useBaseForm()
    initFormData({
      order1: null,
      order2: null,
      order3: null,
    })
    const usePospal = computed(() => checkActionList(['adminView.memberCenterConfig.findPosPalMemberWallet', 'admin.externalWallet.page']))
    const useWallet = computed(() => checkAction('admin.walletRecord.page'))
    const compactData = computed(() => {
      // const test = [{ type: 'cashbackBalance', order: 1, enable: false }, { type: 'pointBalance', order: null, enable: false }, { type: 'classTicketRecordCount', order: 3, enable: false }, { type: 'test', order: 3, enable: false }]
      const settingTypes = ['walletBalance', 'couponCount', 'cashbackBalance', 'pointBalance', 'classTicketRecordCount', 'posPalMemberBalance']
      const showTypes = values(formData)
      const configData = props.configData
      const oldConfig = map(configData, (item) => ({ type: item.type, enable: false }))

      for (const key in formData) {
        const item = formData[key]
        if (!item) continue
        let exist
        let order

        if (key === 'order1') {
          order = 1
        } else if (key === 'order2') {
          order = 2
        } else if (key === 'order3') {
          order = 3
        }

        if (item.type === 'pointBalance') {
          const itemShopPointKey = get(item, 'shopPointKey')
          exist = find(oldConfig, { type: item.type, shopPointKey: itemShopPointKey })
        }
        if (exist) {
          exist.enable = true
          exist.order = order
          exist.shopPointKey = get(item, 'shopPointKey')
        } else {
          oldConfig.push({
            type: item.type,
            enable: true,
            order: order,
            shopPointKey: get(item, 'shopPointKey'),
          })
        }
      }

      return uniqWith(oldConfig, isEqual)
    })
    const formRules = computed(() => {
      const rules = {}

      if (formData.order3) {
        rules.order1 = [noEmptyRules()]
        rules.order2 = [noEmptyRules()]
      }

      if (formData.order2) {
        rules.order1 = [noEmptyRules()]
      }

      return rules
    })

    const showOptions = computed(() => {
      const options = {
        // walletBalance: '儲值金',
        couponCount: '票券',
        cashbackBalance: '回饋金',
        pointBalance: '點數',
        classTicketRecordCount: '堂票',
        // posPalMemberBalance: '會員餘額',
      }
      if (useWallet.value) {
        options.walletBalance = '儲值金'
      }
      if (usePospal.value) {
        options.posPalMemberBalance = externalWalletName.value
      }
      return map(availableTypes.value, (item) => {
        const shopPointKey = get(item, 'shopPointKey')
        const pointData = find(shopPointList.value, { key: shopPointKey }) || find(shopPointList.value, { isDefault: true })
        let label = options[item.type]
        if (item.type === 'pointBalance' && pointData) {
          label = `${options[item.type]}(${pointData.name})`
        }
        return {
          label,
          value: shopPointKey ? `${item.type}-${shopPointKey}` : item.type,
          type: item.type,
          shopPointKey,
        }
      })
    })

    const updateFunctionMenu = async () => {
      loading.value = true
      if (!await formUtils.checkForm(formRef.value)) {
        loading.value = false
        return
      }
      const [, err] = await UpdateMemberCenterConfig({
        shopId: shopId.value,
        functionMenuSetting: compactData.value,
      })
      loading.value = false
      if (err) return window.$message.error(err)
      window.$message.success('更新成功！')
      emit('refresh')
      emit('close')
    }

    const existOption = (item) => {
      const shopPointKey = get(item, 'shopPointKey')
      let itemValue = item.type
      if (shopPointKey) {
        itemValue = `${item.type}-${shopPointKey}`
      }

      // 支援舊點數
      if (item.type === 'pointBalance' && !shopPointKey) {
        const defaultPointKey = get(find(shopPointList.value, { isDefault: true }), 'key')
        itemValue = defaultPointKey ? `pointBalance-${defaultPointKey}` : 'pointBalance'
      }

      const exist = find(showOptions.value, { value: itemValue })
      if (!exist) return null
      return exist
    }

    const syncData = () => {
      const data = props.configData
      const order1 = find(data, { order: 1 })
      const order2 = find(data, { order: 2 })
      const order3 = find(data, { order: 3 })

      formData.order1 = existOption(order1)
      if (formData.order1) formData.order1.order = 1
      formData.order2 = existOption(order2)
      if (formData.order2) formData.order2.order = 2
      formData.order3 = existOption(order3)
      if (formData.order3) formData.order3.order = 3
    }

    const disabledOption = (item) => {
      const selected = values(formData)
      const exist = find(selected, { value: item.value })
      return Boolean(exist)
    }

    onMounted(async () => {
      const [res, err] = await GetMemberCenterCardOptions({
        shopId: shopId.value,
      })
      if (err) {
        window.$message.error(err)
        return
      }
      availableTypes.value = res
      syncData()
      getExternalWalletConfig()
    })

    return { formData, formRef, formRules, showOptions, updateFunctionMenu, find, disabledOption, loading, compactData }
  },
})
</script>

<style lang="postcss" scoped>

</style>
