<template>
  <div class="">
    <PageTitle
      :title="$t('publicAppointment.title')"
      icon="chevron_left"
      hideBtn
      @iconClick="$router.push({name: 'Parameters'})"
    />
    <EnableSettings v-loading="loading" :content="data" @updatePubApt="updatePubApt" />
    <LinkSettings v-loading="loading" :content="data" @updatePubApt="updatePubApt" />
    <StoreInfo />
    <PriceSettings v-loading="loading" :content="data" @updatePubApt="updatePubApt" />
    <PaymentTypeSettings v-if="usePubAptPaymentConfig" v-loading="loading" :content="paymentData" @refresh="refresh" />
    <PrePaymentSettings v-if="usePubAptDepositConfig" v-loading="loading" :content="prePaymentData" :paymentData="paymentData" @refresh="refresh" />
    <InvoiceConfigBlock v-if="usePubAptInvoiceConfig" v-loading="loading" :content="invoiceConfigData" @refresh="refresh" />
    <ConfirmReminderSettings v-if="usePubAptConfirmReminder" v-loading="loading" :content="reminderData" @updateConfirmReminder="updateConfirmReminder" />
    <BaseDialog
      v-if="depositWarning"
      width="566px"
      :title="$t('common.dialog.delete.title')"
      @confirm="depositWarning = false"
      @cancel="depositWarning = false"
      @close="depositWarning = false"
    >
      <div class="dialog-content">
        <p class="mb-[20px]">{{ $t('publicAppointment.dialog.content')}}</p>
      </div>
    </BaseDialog>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref, reactive, computed } from 'vue'
import EnableSettings from './components/EnableSettings.vue'
import PageTitle from '@/components/Title/PageTitle.vue'
import LinkSettings from './components/LinkSettings.vue'
import StoreInfo from './components/StoreInfo.vue'
import PriceSettings from './components/PriceSettings.vue'
import PaymentTypeSettings from './components/PaymentTypeSettings.vue'
import PrePaymentSettings from './components/PrePaymentSettings.vue'
import InvoiceConfigBlock from './components/InvoiceConfigBlock.vue'
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import { usePermissions } from '@/use/permissions'
import ConfirmReminderSettings from './components/ConfirmReminderSettings.vue'
import { get, isNull } from 'lodash'
import {
  FindPubAptConfirmReminder,
  UpdatePubAptConfirmReminder,
  FindPubAptConfig,
  UpdatePubAptConfig,
  FindPubAptInvoiceConfig,
  GetPubAptPaymentConfig,
  FindPubAptDepositConfig,
} from '@/api/pubApt'
import { useShop } from '@/use/shop'
import { i18n } from '@/plugins/i18n/i18n'

export default defineComponent({
  name: 'PublicAppointment',
  components: {
    PageTitle,
    EnableSettings,
    LinkSettings,
    StoreInfo,
    PriceSettings,
    PaymentTypeSettings,
    PrePaymentSettings,
    InvoiceConfigBlock,
    ConfirmReminderSettings,
    BaseDialog,
  },
  setup (props) {
    const { checkActionList, checkAction } = usePermissions()
    const { shopId, shopEmail } = useShop()
    const loading = ref(false)
    const depositWarning = ref(false)
    const usePubAptPaymentConfig = computed(() => checkAction('admin.pubAptPaymentConfig.page'))
    const usePubAptDepositConfig = computed(() => checkAction('admin.pubAptDepositConfig.page'))
    const usePubAptInvoiceConfig = computed(() => checkAction('admin.pubAptInvoiceConfig.page'))
    const usePubAptConfirmReminder = computed(() => checkAction('admin.pubAptConfirmReminder.page'))
    const data = reactive({
      enable: null,
      code: null,
      priceRate: null,
    })
    const reminderData = reactive({
      enable: null,
      title: null,
      content: null,
    })
    const paymentData = ref([])
    const prePaymentData = reactive({
      type: null,
      fixedAmount: null,
      percentAmount: null,
      amountType: 'fixed',
      PubAptPaymentConfigId: null,
      PubAptPaymentConfig: null,
    })
    const invoiceConfigData = reactive({
      enable: null,
      platform: null,
      invoiceApiConfig: {
        merchantID: null,
        hashKey: null,
        hashIV: null,
      },
    })
    const getPubAptConfig = async () => {
      loading.value = true
      const [res, err] = await FindPubAptConfig({
        shopId: shopId.value,
      })
      loading.value = false
      if (err) {
        window.$message.error(err)
        return
      }
      await syncData(data, res)
    }
    const syncData = async (data, res) => {
      for (const key in data) {
        if (key in res) {
          data[key] = res[key]
        }
      }
    }
    const updatePubAptConfig = async () => {
      loading.value = true
      if (data.enable) {
        if (!shopEmail.value) {
          window.$message.error(i18n.t('publicAppointment.message.shopEmailError'))
          loading.value = false
          data.enable = false
          return
        }
        if (data.code === null) {
          window.$message.error(i18n.t('publicAppointment.message.shopCodeError'))
          loading.value = false
          data.enable = false
          return
        }
        if (usePubAptPaymentConfig.value && isNull(get(prePaymentData, 'amountType'))) {
          depositWarning.value = true
          loading.value = false
          data.enable = false
          return
        }
      }
      const [, err] = await UpdatePubAptConfig({
        shopId: shopId.value,
        data,
      })
      loading.value = false
      if (err) {
        window.$message.error(err)
        return
      }
      window.$message.success(i18n.t('common.message.updateSuccess'))
      // console.log(res)
    }
    const getPubAptConfirmReminder = async () => {
      loading.value = true
      const [res, err] = await FindPubAptConfirmReminder({
        shopId: shopId.value,
      })
      loading.value = false
      if (err) {
        window.$message.error(err)
        return
      }
      await syncData(reminderData, res)
    }
    const updatePubAptConfirmReminder = async () => {
      loading.value = true
      const [, err] = await UpdatePubAptConfirmReminder({
        shopId: shopId.value,
        data: reminderData,
      })
      loading.value = false
      if (err) {
        window.$message.error(err)
        return
      }
      window.$message.success(i18n.t('common.message.updateSuccess'))
    }
    const getPubAptInvoiceConfig = async () => {
      loading.value = true
      const [res, err] = await FindPubAptInvoiceConfig({
        shopId: shopId.value,
      })
      loading.value = false
      if (err) {
        window.$message.error(err)
        return
      }
      await syncData(invoiceConfigData, res)
    }
    const getPubAptPaymentConfig = async () => {
      loading.value = true
      const [res, err] = await GetPubAptPaymentConfig({
        shopId: shopId.value,
      })
      loading.value = false
      if (err) {
        window.$message.error(err)
        return
      }
      paymentData.value = res
    }
    const getPubAptDepositConfig = async () => {
      loading.value = true
      const [res, err] = await FindPubAptDepositConfig({
        shopId: shopId.value,
      })
      loading.value = false
      if (err) {
        window.$message.error(err)
        return
      }
      await syncData(prePaymentData, res)
    }
    const updatePubApt = async (value) => {
      await syncData(data, value)
      await updatePubAptConfig()
    }
    const updateConfirmReminder = async (value) => {
      await syncData(reminderData, value)
      await updatePubAptConfirmReminder()
    }
    const refresh = async () => {
      await getPubAptConfig()
      await getPubAptConfirmReminder()
      if (usePubAptInvoiceConfig.value) await getPubAptInvoiceConfig()
      if (usePubAptPaymentConfig.value) await getPubAptPaymentConfig()
      if (usePubAptDepositConfig.value) await getPubAptDepositConfig()
    }
    onMounted(async () => {
      await refresh()
    })
    return {
      checkActionList,
      data,
      reminderData,
      paymentData,
      prePaymentData,
      invoiceConfigData,
      loading,
      updatePubApt,
      updateConfirmReminder,
      refresh,
      usePubAptPaymentConfig,
      usePubAptDepositConfig,
      usePubAptInvoiceConfig,
      usePubAptConfirmReminder,
      depositWarning,
    }
  },
})
</script>

<style lang="postcss" scoped>

</style>
