<template>
  <div class="test">
    <input type="file" @change="handleFileUpload">
    <input v-model="fileName" type="text" placeholder="File Name">
    <button @click="exportFile">Export</button>
  </div>
</template>

<script>
import SerialcodeImportModal from '@/components/SerialCode/SerialcodeImportModal.vue'
import { defineComponent, ref, onMounted } from 'vue'
import { useWindowUnload } from '@/use/useWindowUnload'
import { get, flatten } from 'lodash'
import {
  orderByOptionsConfig, couponStatusConfig, filterTimeCategoryConfig, couponOriginTypeConfig,
  couponTypeConfig,
} from '@/config/coupon'
import { formatDate } from '@/utils/date'
import { ExportExcel } from '@/utils/excel'
import * as XLSX from 'xlsx'
import csvParser from 'csv-parser'

export default defineComponent({
  name: 'Test',
  setup (props, { emit }) {
    const selectFile = ref(null)
    const fileName = ref(null)
    const dataArray = ref([])
    const formateExportData = (data) => {
      const sheetsData = []
      data.forEach((item) => {
        const couponType = get(item, 'CouponRecords.type')
        const originType = get(item, 'CouponRecords.originType')
        const usedAtDate = get(item, 'CouponRecords.usedAt')
        const expAtDate = get(item, 'CouponRecords.expAt')
        const giveAtDate = get(item, 'CouponRecords.createdAt')
        const status = get(item, 'CouponRecords.status')

        const row = {
          會員編號: get(item, 'Members.id') || '-',
          會員名稱: get(item, 'UserInfos.name') || '-',
          會員手機: get(item, 'UserInfos.phone') || '-',
          票券來源: get(couponOriginTypeConfig, `${originType}.label`) || '-',
          票券編號: get(item, 'CouponRecords.code'),
          票券種類: get(couponTypeConfig, `${couponType}.label`),
          票券名稱: get(item, 'Coupons.name'),
          兌換碼: get(item, 'CouponRecords.exchangeCode') || '-',
          // 票券使用狀態: usedAtDate ? '已使用' : '未使用',
          票券使用狀態: get(couponStatusConfig, `${status}.label`),
          票券發送日期: giveAtDate ? formatDate(giveAtDate) : '-',
          票券使用日期: usedAtDate ? formatDate(usedAtDate) : '-',
          票券到期日期: expAtDate ? formatDate(expAtDate) : '-',
        }
        if (couponType !== 'exchange') delete row['兌換碼']

        sheetsData.push(row)
      })
      return sheetsData
    }

    const convertCSV = (csvData) => {
      const lines = csvData.split('\n')
      const headers = lines[0].split(',')

      const result = lines.slice(1).reduce((acc, line) => {
        if (!line.trim()) return acc // Skip empty lines

        const values = line.split(',')
        const obj = headers.reduce((obj, header, index) => {
          obj[header.trim()] = values[index]?.trim()
          return obj
        }, {})

        acc.push(obj)
        return acc
      }, [])

      dataArray.value = result
    }

    const exportFile = () => {
      console.log('Exported Data:', dataArray.value)
      console.log(formateExportData(dataArray.value))
      ExportExcel(formateExportData(dataArray.value), fileName.value, fileName.value)
    }

    const handleFileUpload = (event) => {
      const file = event.target.files[0]
      if (file && file.type === 'text/csv') {
        const reader = new FileReader()
        reader.onload = (e) => {
          const csvData = e.target.result
          convertCSV(csvData)
        }
        reader.readAsText(file)
      } else {
        alert('Please select a valid CSV file.')
      }
    }

    return { exportFile, handleFileUpload, fileName }
  },
})
</script>

<style lang="postcss" scoped>

</style>
