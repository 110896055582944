<template>
  <div v-if="displayData">
    <BaseElForm label-position="left" label-width="150px">
      <BaseElFormItem :label="$t('invoiceBlock.status.title')">
        <Tag
          v-if="displayData.status"
          style="display: inline-flex"
          :type="invoiceStatus(displayData.status, 'tag')"
          disable-transitions
        >
          {{ $t(invoiceStatus(displayData.status, 'label')) }}
        </Tag>
        <BaseElButton
          v-if="displayData.status === 'pending'"
          class="primary-btn underline ml-2"
          type="text"
          @click="modal.warning = true"
        >
          {{ $t('invoiceBlock.button.issued.title')}}
        </BaseElButton>
        <BaseElButton
          v-if="displayData.status === 'issued'"
          class="primary-btn underline ml-2"
          type="text"
          @click="modal.update = true"
        >
          {{ $t('invoiceBlock.button.void.title')}}
        </BaseElButton>
      </BaseElFormItem>
      <BaseElFormItem :label="$t('invoiceBlock.invoiceType.title')">
        {{ displayData.displayInvioiceType }}
      </BaseElFormItem>
      <div v-if="displayData.invioiceType === 'B'">
        <BaseElFormItem :label="$t('invoiceBlock.companyName.title')">
          {{ displayData.companyName }}
        </BaseElFormItem>
        <BaseElFormItem :label="$t('invoiceBlock.taxId.title')">
          {{ displayData.taxId }}
        </BaseElFormItem>
      </div>
      <BaseElFormItem :label="$t('invoiceBlock.invoiceNo.title')">
        {{ displayData.invoiceNo }}
      </BaseElFormItem>
    </BaseElForm>
    <BaseDialog
      v-if="modal.warning"
      width="566px"
      :title="$t('common.dialog.delete.title')"
      @confirm="updateInvoiceStatus('issued')"
      @cancel="modal.warning = false"
      @close="modal.warning = false"
    >
      <div class="dialog-content">
        <p>{{ $t('invoiceBlock.dialog.issuedConfirm.content')}}</p>
      </div>
    </BaseDialog>
    <BaseDialog
      v-if="modal.update"
      width="566px"
      :title="$t('invoiceBlock.dialog.updateStatus.title')"
      @confirm="updateInvoiceStatus(formData.status)"
      @cancel="modal.update = false"
      @close="modal.update = false"
    >
      <div class="dialog-content pb-[24px]">
        <BaseElForm ref="formRef" :model="formData" :rules="formRules">
          <BaseElFormItem :label="$t('invoiceBlock.dialog.status.title')" prop="status">
            <BaseElSelect v-model="formData.status" class="mb-[20px]" :placeholder="$t('invoiceBlock.dialog.status.placeholder')">
              <BaseElSelectOption
                v-for="(status, index) in invoiceStatusOptions"
                :key="index"
                :label="status.label"
                :value="status.value"
              />
            </BaseElSelect>
          </BaseElFormItem>
        </BaseElForm>
        <p class="text-gray-60 text-sm">{{ $t('invoiceBlock.dialog.void.warning.title')}}</p>
      </div>
    </BaseDialog>
  </div>
</template>

<script>
import { defineComponent, computed, reactive, ref } from 'vue'
import { get, omit } from 'lodash'
import { invoiceStatusConfig, invoiceTypeConfig } from '@/config/pubApt'
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import formUtils from '@/utils/form'
import { i18n } from '@/plugins/i18n/i18n'

export default defineComponent({
  name: 'InvoiceBlock',
  components: { BaseDialog },
  props: {
    order: { type: Object, default: () => ({}) },
  },
  setup (props, { emit }) {
    const formRef = ref(null)
    const formData = reactive({
      status: '',
    })
    const formRules = reactive({
      status: [{ required: true, message: i18n.t('invoiceBlock.dialog.status.placeholder'), trigger: 'blur' }],
    })
    const invoiceOrder = computed(() => {
      const pubAptInvoice = get(props.order, 'AppointmentVisitorPreOrder.PubAptInvoiceOrder')
      const checkoutInvoice = get(
        props.order,
        'AppointmentCheckoutOrder.AppointmentCheckoutInvoice',
      )
      return pubAptInvoice || checkoutInvoice
    })
    const pubAptInvoiceData = computed(() => {
      const pubAptInvoiceOrder = invoiceOrder.value
      const invoiceInfo = get(props.order, 'AppointmentVisitorPreOrder.invoiceInfo')

      return {
        status: get(pubAptInvoiceOrder, 'status') || '-',
        invioiceType: get(invoiceInfo, 'buyerType', '-'),
        displayInvioiceType: get(invoiceInfo, 'buyerType')
          ? get(invoiceTypeConfig, `${get(invoiceInfo, 'buyerType')}.label`)
          : '-',
        invoiceNo: get(pubAptInvoiceOrder, 'invoiceNo')
          ? get(pubAptInvoiceOrder, 'invoiceNo')
          : '-',
        companyName: get(invoiceInfo, 'buyerName'),
        taxId: get(invoiceInfo, 'buyerIdentifier'),
      }
    })

    const checkoutInvoiceData = computed(() => {
      if (chargeType.value !== 'checkout') {
        return {
          status: '',
          invioiceType: '',
          invoiceNo: '',
        }
      }
      const invoiceContent = get(invoiceOrder.value, 'InvoiceContent')
      return {
        status: get(invoiceOrder.value, 'status') || '-',
        invioiceType: get(invoiceContent, 'buyer.type') || '-',
        displayInvioiceType:
          get(invoiceTypeConfig, `${get(invoiceContent, 'buyer.type')}.label`) || '-',
        invoiceNo: get(invoiceContent, 'invoiceNo') || '-',
        companyName: get(invoiceContent, 'buyer.name') || '-',
        taxId: get(invoiceContent, 'buyer.identifier') || '-',
      }
    })

    const chargeType = computed(() => get(props.order, 'chargeType'))

    const displayData = computed(() => {
      if (chargeType.value === 'checkout') return checkoutInvoiceData.value
      return pubAptInvoiceData.value
    })
    const modal = reactive({
      warning: false,
      update: false,
    })
    const invoiceStatus = (status, attr) => {
      return get(invoiceStatusConfig[status], attr)
    }
    const invoiceStatusOptions = computed(() => {
      return omit(invoiceStatusConfig, ['pending', 'issued', 'voided', 'allowance'])
    })
    const updateInvoiceStatus = (status) => {
      if (status === 'issued') {
        emit('updateInvoiceStatus', status)
        modal.warning = false
        modal.update = false
        return
      }
      // formRef validate
      formUtils.checkForm(formRef.value).then((valid) => {
        if (!valid) return
        emit('updateInvoiceStatus', status)
        modal.warning = false
        modal.update = false
      })
    }
    return {
      formRef,
      displayData,
      get,
      invoiceStatus,
      updateInvoiceStatus,
      modal,
      formRules,
      formData,
      invoiceStatusOptions,
    }
  },
})
</script>

<style lang="postcss" scoped>
::v-deep .el-select,
::v-deep .el-input {
  @apply w-full;
}
</style>
