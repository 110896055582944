<template>
  <div class="sales-pos-product-list">
    <swiper
      v-if="loaded"
      class="w-full h-full"
      navigation
      pagination
      centeredSlides
      :loop="false"
    >
      <swiper-slide v-for="(chunk, chunkIdx) in displayData" :key="chunkIdx">
        <div class="product-list-chunk">
          <div
            v-for="product in chunk"
            :key="product.id"
            class="product-item"
            :class="{
              isSelected: isSelected(product),
              'class-ticket-item': product.useClassTicket,
            }"
            @click="onSelectProduct(product)"
          >
            <div v-if="product.useClassTicket" class="classTicket-availableTimes">{{ get(product, 'ClassTicket.availableTimes', '?') }}次</div>
            <div
              :class="{
                'classTicket-content': product.useClassTicket,
              }"
            >
              {{ product.name }}
            </div>
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { useSalesPOSCreate } from '@/use/useSalesPOS'
import { passValue, sortOrder } from '@/utils/helper'
import { isNull, isEmpty, chunk, map, includes, filter, get, find } from 'lodash'

import { Swiper, SwiperSlide, SwiperCore } from 'swiper-vue2'
import { Navigation, Pagination } from 'swiper/core'
import 'swiper/swiper-bundle.css'

import { computed, defineComponent, nextTick, onMounted, ref, watch } from 'vue'

SwiperCore.use([Navigation, Pagination])
export default defineComponent({
  name: 'SalesPOSProductList',
  components: {
    Swiper,
    SwiperSlide,
  },
  setup (props, { emit }) {
    const loaded = ref(false)
    const { cart, configData, context, addProductToCart } = useSalesPOSCreate()

    const displayData = computed(() => {
      const selectedCategoryGroup = context.categoryGroup
      const selectedCategory = context.category
      const selectedProductGroup = context.productGroup

      const categoryMenus = get(configData, 'menu.salesCategoryMenus', [])
      const productMenus = get(configData, 'menu.salesProductMenus', [])

      let filterProductList = get(configData, 'menu.salesProductMenus', [])

      // 目前類別群組下選擇的類別
      const categorys = filter(categoryMenus, { salesCategory: { SalesGroupId: selectedCategoryGroup } })
      const activeCategory = find(categorys, { salesCategory: { id: selectedCategory } })

      // 目前類別下的產品
      const activeCategoryProductIds = get(activeCategory, 'salesProductIds', [])
      let categoryProducts = filter(productMenus, (item) => includes(activeCategoryProductIds, get(item, 'salesProduct.id')))
      if (selectedCategory === null) {
        categoryProducts = filter(productMenus, (item) => isEmpty(item.salesCategoryIds))
      }

      // 過濾產品群組的商品
      filterProductList = filter(categoryProducts, { salesProduct: { SalesGroupId: selectedProductGroup } })

      filterProductList = filter(filterProductList, (item) => {
        if (get(item, 'salesProduct.useClassTicket')) {
          if (isNull(get(item.salesProduct, 'ClassTicket'))) return false
        }
        return true
      })

      return chunk(sortOrder(map(filterProductList, 'salesProduct')), 15)
    })

    const isSelected = computed(() => {
      return (product) => {
        return product.id === context.product
      }
    })

    const onSelectProduct = (product) => {
      const curContext = passValue(context)
      const contextData = {
        categoryGroup: curContext.categoryGroup,
        category: curContext.category,
        productGroup: curContext.productGroup,
        salesUnitGroup: curContext.salesUnitGroup,
        salesUnit: curContext.salesUnit,
      }

      if (!product.needUnit) {
        delete contextData.salesUnit
        delete contextData.salesUnitGroup
      }

      addProductToCart({
        ...product,
        ...contextData,
      })

      context.product = product.id
    }

    const refreshSwiper = () => {
      const prevBtn = document.querySelector('.sales-pos-product-list .swiper-button-prev')
      const nextBtn = document.querySelector('.sales-pos-product-list .swiper-button-next')
      const chevronRightIcon = document.createElement('span')
      chevronRightIcon.classList.add('material-icons')
      chevronRightIcon.textContent = 'chevron_right'
      chevronRightIcon.style.fontSize = '24px'
      chevronRightIcon.style.width = '24px'
      chevronRightIcon.style.color = 'var(--primary-100)'

      const chevronLefttIcon = document.createElement('span')
      chevronLefttIcon.classList.add('material-icons')
      chevronLefttIcon.textContent = 'chevron_left'
      chevronLefttIcon.style.fontSize = '24px'
      chevronLefttIcon.style.width = '24px'
      chevronLefttIcon.style.color = 'var(--primary-100)'

      // 清空 prevBtn 和 nextBtn 的內容，避免重複添加
      if (prevBtn) prevBtn.innerHTML = ''
      if (nextBtn) nextBtn.innerHTML = ''

      if (prevBtn) prevBtn.appendChild(chevronLefttIcon)
      if (nextBtn) nextBtn.appendChild(chevronRightIcon)
    }

    watch(displayData, async () => {
      loaded.value = false
      await nextTick()
      setTimeout(() => {
        refreshSwiper()
      }, 0)
      loaded.value = true
    })

    onMounted(async () => {
      await nextTick()
      refreshSwiper()
      loaded.value = true
    })

    return { cart, context, displayData, configData, onSelectProduct, loaded, isSelected, get }
  },
})
</script>

<style lang="postcss" scoped>
.sales-pos-product-list {
  /* @apply grid grid-cols-5 grid-rows-3 gap-[12px]; */
}

.product-list-chunk {
  @apply grid grid-cols-5 grid-rows-3 gap-[12px] px-[32px];
  @apply w-full h-full;
  @apply pb-[50px];
}

.product-item {
  @apply bg-primary-10 rounded-[6px] overflow-hidden;
  @apply flex items-center justify-center text-center;
  @apply select-none cursor-pointer;
  @apply border-[2px] border-transparent;
}

.class-ticket-item {
  @apply flex flex-col justify-between;
}

.classTicket-availableTimes {
  @apply bg-primary-100 text-white w-full p-[6px];
}

.classTicket-content {
  @apply flex-1 flex items-center justify-center;
}

.isSelected {
  @apply border-primary-100 border-[2px] bg-white text-primary-100;
}

::v-deep(.swiper-button-prev) {
  @apply bg-primary-10 left-0 top-[50%] cursor-pointer;
  @apply w-[24px] h-[24px] rounded-[6px];
}
::v-deep(.swiper-button-next) {
  @apply bg-primary-10 right-0 top-[50%] cursor-pointer;
  @apply w-[24px] h-[24px] rounded-[6px];
}

::v-deep(.swiper-button-prev::after) {
  content: none;
}
::v-deep(.swiper-button-next::after) {
  content: none;
}

::v-deep(.swiper-pagination-bullet) {
  @apply bg-primary-100;
}
</style>
