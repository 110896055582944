<template>
  <div class="sales-pos-side-panel-product-item" :class="{isFocused}" @click="onFocusItem">
    <div class="upper">
      <div class="left-area">
        <div>
          {{ displayData.name }}
        </div>

        <div v-if="displayTags">
          {{ displayTags }}
        </div>
      </div>

      <div class="right-area" @click="onEdit">
        <p>$ {{ displayData.price }}</p>
        <p>x{{ displayData.quantity }}</p>
      </div>
    </div>

    <div class="lower">
      <el-collapse-transition>
        <div v-if="isFocused">
          <div v-for="discount in displayDiscounts" :key="discount.id" class="flex justify-between">
            <p class="text-gray-60">{{ discount.name }}</p>
            <p class="text-danger">-$ {{ discount.savePrice }}</p>
          </div>
        </div>
      </el-collapse-transition>
      <el-collapse-transition>
        <div v-if="!isFocused && displayDiscounts.length">
          <div class="flex justify-between text-gray-60">
            <p>單項折扣累計</p>
            <p>-$ {{ displayData.totalDiscountPrice }}</p>
          </div>
        </div>
      </el-collapse-transition>
    </div>

    <SalesPOSEditProductItemPriceModal
      v-if="modal.editPrice"
      :data="data"
      @close="modal.editPrice = false"
    />
  </div>
</template>

<script>
import { computed, defineComponent, reactive } from 'vue'
import { useSalesPOSCreate } from '@/use/useSalesPOS'
import SalesPOSEditProductItemPriceModal from './SalesPOSEditProductItemPriceModal.vue'
import { get, reduce, isEmpty, map, join, compact, includes } from 'lodash'

export default defineComponent({
  name: 'SalesPOSSidePanelProductItem',
  components: {
    SalesPOSEditProductItemPriceModal,
  },
  props: {
    data: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  setup (props, { emit }) {
    const { cart, context, setActiveCartItem } = useSalesPOSCreate()
    const modal = reactive({
      editPrice: false,
    })

    const displayData = computed(() => {
      let price = get(props.data, 'defaultPrice')
      const customPrice = get(props.data, 'customPrice')
      if (customPrice) price = customPrice

      return {
        name: get(props.data, 'name'),
        price,
        quantity: get(props.data, 'quantity'),
        totalDiscountPrice: !isEmpty(props.data) ? props.data.totalDiscountPrice() : '-',
      }
    })

    const displayDiscounts = computed(() => {
      const target = props.data
      if (isEmpty(target)) return []
      return target.getSingleDiscountsDetail()
    })

    const displayTags = computed(() => {
      const tags = get(props.data, 'appointmentData.tags')
      if (isEmpty(tags)) return ''

      const formated = compact(map(tags, (tag) => {
        if (tag === 'CLASS_TICKET_USED') return '已扣堂票'
        if (tag === 'CHECKOUT_PAID') return '已付清'
      }))

      if (!formated.length) return ''

      return join(formated, '、')
    })

    const onEdit = () => {
      if (includes(get(props.data, 'appointmentData.tags'), 'CLASS_TICKET_USED')) return
      if (includes(get(props.data, 'appointmentData.tags'), 'CHECKOUT_PAID')) return

      modal.editPrice = true
    }

    const onFocusItem = () => {
      setActiveCartItem(props.data)
    }

    const isFocused = computed(() => {
      return context.cart.item === props.data.nanoId
    })

    return { isFocused, onFocusItem, modal, displayData, cart, displayDiscounts, displayTags, onEdit }
  },
})
</script>

<style lang="postcss" scoped>
.sales-pos-side-panel-product-item {
  @apply rounded-[6px] px-[12px] text-sub;
  @apply flex flex-col gap-[12px] gap-[6px];
  @apply select-none cursor-pointer;
  @apply transition duration-300 ease-in-out;
}

.isFocused {
  @apply bg-primary-30;
}

.upper {
  @apply flex py-[6px];
  .left-area {
    @apply flex-1 ;
    @apply flex flex-col justify-between;
  }
  .right-area {
    @apply border-l-[1px] border-gray-80 w-[58px];
    @apply flex flex-col items-end justify-between;
  }
}

.divider {
  @apply w-[1px] h-[85px] bg-gray-80;
}
</style>
