<template>
  <el-drawer
    title="第三方消費紀錄詳情"
    :visible="true"
    :append-to-body="true"
    destroy-on-close
    size="600px"
    class="reservation-order-detail"
    @close="$emit('close')"
  >
    <div class="px-[20px]">
      <FormInfo gap="10">
        <FormInfoItem
          v-for="info in baseInfoData"
          :key="info.label"
          width="132"
          :title="info.label"
          :content="info.value"
        >
          <Tag v-if="info.isTag" :type="info.tagType">{{ info.tagContent }}</Tag>
          <BaseElButton v-if="info.isButton" type="text" @click="info.clickEvent">手動標記</BaseElButton>
        </FormInfoItem>
      </FormInfo>
      <hr style="margin: 32px 0">
      <FormInfo gap="10">
        <p class="forminfo-title">消費品項</p>
        <div class="flex flex-col gap-[32px]">
          <FormInfo v-for="(info, idx) in salesData" :key="idx" gap="10">
            <FormInfoItem
              v-for="item in info"
              :key="item.value"
              width="132"
              :title="item.label"
              :content="item.value"
            />
          </FormInfo>
        </div>
      </FormInfo>
      <hr v-if="discountData" style="margin: 32px 0">
      <FormInfo v-if="discountData" gap="10">
        <p class="forminfo-title">折扣</p>
        <div class="flex flex-col gap-[32px]">
          <FormInfo gap="10">
            <FormInfoItem
              v-for="item in discountData"
              :key="item.itemIndex"
              width="132"
              :title="item.name"
              :content="item.amount"
            />
          </FormInfo>
        </div>
      </FormInfo>
      <hr v-if="paymentData" style="margin: 32px 0">
      <FormInfo v-if="paymentData" gap="10">
        <p class="forminfo-title">支付方式</p>
        <div class="flex flex-col gap-[32px]">
          <FormInfo gap="10">
            <FormInfoItem
              v-for="item in paymentData"
              :key="item.type"
              width="132"
              :title="item.type"
              :content="item.amount"
            />
          </FormInfo>
        </div>
      </FormInfo>
      <hr v-if="rewardData.length" style="margin: 32px 0">
      <FormInfo v-if="rewardData.length" gap="10">
        <p class="forminfo-title">本次可獲得</p>
        <FormInfoItem
          v-for="info in rewardData"
          :key="info.value"
          width="132"
          :title="info.label"
          :content="info.value"
        />
      </FormInfo>
      <hr style="margin: 32px 0">
      <FormInfo gap="10">
        <FormInfoItem
          v-for="info in footerData"
          :key="info.value"
          width="132"
          :title="info.label"
          :content="info.value"
        />
      </FormInfo>
      <MarkExternalTransactionModal
        v-if="showMarkModal"
        @close="showMarkModal = false"
        @updateStatus="updateStatus"
        :record="recordData"
      />
      <div class="flex justify-end" style="margin-top: 70px;">
        <BaseElButton plain style="width: 100px;" @click="$emit('close')">返回</BaseElButton>
      </div>
    </div>
  </el-drawer>
</template>

<script>
import { computed, defineComponent, onBeforeMount, ref } from 'vue'
import FormInfo from '@/components/FormInfo/FormInfo.vue'
import FormInfoItem from '@/components/FormInfo/FormInfoItem.vue'
import Tag from '@/components/Tag/Tag.vue'
import { FindMemberExternalTransaction, VoidMemberExternalTransaction } from '@/api/memberExternalTransaction'
import { useFetch } from '@/use/fetch'
import { get, map } from 'lodash'
import { formatDate, formatDateRange } from '@/utils/date'
import { rewardActivityTypeConfig } from '@/config/rewardActivity'
import { localeString } from '@/utils/helper'
import { salesItemFieldConfig, originsConfig, memberExternalTransactionStatusConfig, markedStatusConfig } from '@/config/externalTransaction'
import MarkExternalTransactionModal from './MarkExternalTransactionModal.vue'

export default defineComponent({
  name: 'ExternalTransactionRecordDetail',
  components: { FormInfo, FormInfoItem, Tag, MarkExternalTransactionModal },
  props: { record: { type: Object, default: () => ({}) } },
  setup (props, { emit }) {
    const { simpleFetch } = useFetch()
    const recordData = ref({})
    const showMarkModal = ref(false)
    const baseInfoData = computed(() => {
      const data = props.record
      const transactionTime = get(data, 'time')
      const isInvalid = get(data, 'isInvalid')
      const status = get(data, 'status')
      const origin = get(data, 'origin', '-')
      const fields = [
        { label: '消費紀錄編號', value: get(data, 'transactionId', '-') },
        { label: '來源', value: get(data, 'origin', '-') },
        { label: '會員手機號碼', value: get(data, 'phone', '-') },
        { label: '認列金額', value: localeString(get(data, 'amount', '-')) },
        { label: '實際消費金額', value: localeString(get(data, 'sellAmount', '-')) },
        { label: '交易時間', value: transactionTime ? formatDate(transactionTime) : '-' },
        { label: '是否作廢', tagContent: isInvalid ? '是' : '否', tagType: isInvalid ? 'error' : 'info', isTag: true },
        { label: '訂單狀態', tagContent: memberExternalTransactionStatus(status, 'label'), tagType: memberExternalTransactionStatus(status, 'type'), isTag: true },
      ]
      const labels = get(data, 'labels', [])
      let voidLabels = false
      if (labels) voidLabels = labels.some(label => label.key === 'manualInvalid' || label.key === 'systemInvalid')
      if (status === 'created' && !voidLabels) { // 訂單完成且未作廢
        fields.push({
          label: '訂單標記',
          isButton: true,
          clickEvent: () => {
            showMarkModal.value = true
          },
        })
      } else if (voidLabels) {
        const voidLabel = labels.find(label => label.key === 'manualInvalid' || label.key === 'systemInvalid')
        fields.push({
          label: '訂單標記',
          value: get(markedStatusConfig[voidLabel.key], 'optionLabel'), // 後台標記作廢 or POS 標記作廢
        })
      }
      if (['dudoo', 'weiby'].includes(origin.toLowerCase())) {
        fields.splice(2, 0, { label: '交易門市', value: get(recordData.value, 'Branch.name', '-') })
      }
      return fields
    })
    const memberExternalTransactionStatus = (key, attr) => {
      return get(memberExternalTransactionStatusConfig[key], attr)
    }
    // 消費品項
    const salesData = computed(() => {
      const data = recordData.value
      return map(data.items, (item) => {
        const dataList = [
          { label: get(salesItemFieldConfig, `${data.source}.name.label`, '名稱'), value: get(item, 'name', '-') },
          { label: '金額', value: localeString(get(item, 'price', '-')) },
          { label: '數量', value: get(item, 'quantity', '-') },
        ]

        if (data.source === originsConfig.opentix.value) {
          dataList.push({
            label: get(salesItemFieldConfig, `${data.source}.eventTimeRange.label`, '起迄時間'),
            value: formatDateRange({
              dates: [
                get(item, 'context.opentixEventStartTime') * 1000,
                get(item, 'context.opentixEventEndTime') * 1000,
              ],
            }),
          })
        }

        // data.source 如果是 Pospal，則顯示 note 欄位
        if (data.source.toLowerCase() === originsConfig.pospal.value) {
          dataList.push({ label: '訂單備註', value: get(item, 'note', '-') })
        }

        if (data.source.toLowerCase() === originsConfig.drfoot.value) {
          // 將資料插入到陣列中第2個
          dataList.splice(1, 0, { label: '編號', value: get(item, 'itemNo', '-') })
        }

        return dataList
      })
    })
    const combineItems = (items) => {
      const itemMap = new Map()
      if (!items) return false
      items.forEach(item => {
        if (itemMap.has(item.name)) {
          const existingItem = itemMap.get(item.name)
          existingItem.amount += item.amount
        } else {
          itemMap.set(item.name, { ...item })
        }
      })

      return Array.from(itemMap.values());
    }
    const discountData = computed(() => {
      const data = get(recordData.value, 'discountDetails')
      return combineItems(data)
    })
    const paymentData = computed(() => {
      const data = props.record
      return get(data, 'paymentDetails')
    })

    const rewardData = computed(() => {
      const data = props.record
      const rewards = map(get(data, 'RewardActivityRecords'), reward => {
        let amount = get(reward, 'rewardAmount')
        if (!Number.isNaN(amount)) amount = amount.toLocaleString()
        if (reward.rewardType === 'CASHBACK') {
          return { label: rewardActivityTypeConfig.CASHBACK.label, value: `$ ${amount}` }
        } else if (reward.rewardType === 'POINT') {
          return { label: rewardActivityTypeConfig.POINT.label, value: `${amount} 點` }
        }
      })
      return rewards
    })

    const footerData = computed(() => {
      const data = recordData.value
      return [
        { label: '紀錄建立時間', value: data.createdAt ? formatDate(data.createdAt) : '-' },
      ]
    })
    const updateStatus = async (status) => {
      const [res, err] = await VoidMemberExternalTransaction({
        shopId: get(props.record, 'shopId'),
        id: get(props.record, 'id'),
      })
      if (err) return window.$message.error(err)
      window.$message.success('更新成功！')
      emit('close')
      emit('refresh')
      showMarkModal.value = false
    }
    const findMemberExternalTransaction = async () => {
      await simpleFetch(FindMemberExternalTransaction, {
        shopId: get(props.record, 'shopId'),
        id: get(props.record, 'id'),
      }, (res) => {
        recordData.value = res
      })
    }
    onBeforeMount(async () => {
      if (!get(props.record, 'id')) return
      await findMemberExternalTransaction()
    })

    return { baseInfoData, salesData, discountData, paymentData, rewardData, footerData, recordData, showMarkModal, updateStatus }
  },
})
</script>

<style lang="postcss" scoped>
.forminfo-title {
  @apply text-primary-100 font-medium text-normal;
}
</style>
