import { admin2 } from './instance'

// GET admin.externalWalletConfig.singleFind
export const GetExternalWalletConfig = async ({ shopId }) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/externalWalletConfig`,
  })
}

// PUT admin.externalWalletConfig.singleUpdate
export const UpdateExternalWalletConfig = async ({
  shopId,
  source,
  displayName,
  displayDepositRecord,
  ShopCustomModuleId,
}) => {
  return await admin2({
    method: 'PUT',
    url: `/${shopId}/externalWalletConfig`,
    data: {
      source,
      displayName,
      displayDepositRecord,
      ShopCustomModuleId,
    },
  })
}

// GET admin.externalWalletRecord.find
export const GetExternalWalletRecord = async ({ shopId, memberId, start, limit, startAt, endAt }) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/externalWalletRecord`,
    params: {
      memberId,
      start,
      limit,
      startAt,
      endAt,
    },
  })
}

// GET admin.externalWalletRecord.count
export const GetExternalWalletRecordCount = async ({ shopId, memberId, startAt, endAt }) => {
  return await admin2({
    method: 'GET',
    url: `/${shopId}/externalWalletRecord/count`,
    params: {
      memberId,
      startAt,
      endAt,
    },
  })
}

// POST admin.externalWallet.getBalance
export const GetExternalWalletBalance = async ({ shopId, memberId }) => {
  return await admin2({
    method: 'POST',
    url: `/${shopId}/externalWallet/balance`,
    data: {
      memberId,
    },
  })
}

// POST admin.externalWallet.deposit
export const DepositExternalWallet = async ({ shopId, memberId, amount, note }) => {
  return await admin2({
    method: 'POST',
    url: `/${shopId}/externalWallet/deposit`,
    data: {
      memberId,
      amount,
      note,
    },
  })
}

// POST admin.externalWallet.use
export const UseExternalWallet = async ({ shopId, memberId, amount, note }) => {
  return await admin2({
    method: 'POST',
    url: `/${shopId}/externalWallet/use`,
    data: {
      memberId,
      amount,
      note,
    },
  })
}
