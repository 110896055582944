<template>
  <div class="admin-list">
    <PageTitle :title="$t('page.adminSetting.title')" :btn="$t('common.button.create.text')" @btnClick="openDialog('create')" />
    <section class="flex-end">
      <!-- <SearchInput type="single" placeholder="請輸入使用者ID" :data.sync="userSearch"/> -->
    </section>

    <section>
      <BaseTable v-loading="loading" :data="userList" :empty-text="$t('common.table.empty.text')">
        <EmptyBlock slot="empty" />
        <BaseElTableColumn :label="$t('adminSetting.table.name.title')" prop="name" align="center" />
        <BaseElTableColumn
          :label="$t('adminSetting.table.email.title')"
          prop="AuthEmail.email"
          align="center"
        />
        <BaseElTableColumn :label="$t('adminSetting.table.role.title')" prop="role" align="center">
          <template slot-scope="scope">
            <Tag :type="tagType(scope.row.role)">
              {{
                roleTrans(scope.row.role)
              }}
            </Tag>
          </template>
        </BaseElTableColumn>

        <!-- <BaseElTableColumn label="ID" prop="id" align="center"/> -->

        <BaseElTableColumn fixed="right" :label="$t('common.table.action.text')" width="100" align="center">
          <template slot-scope="user">
            <el-popover placement="top">
              <div v-if="selectRow" class="table-edit-btn-popover">
                <BaseElButton
                  type="text"
                  @click="
                    openDialog('update'),
                    syncFormData(user.row),
                    (selectRow = user.row)
                  "
                >
                  {{ $t('adminSetting.editInfo.title')}}
                </BaseElButton>
                <BaseElButton
                  type="text"
                  @click="
                    openDialog('password'),
                    syncFormData(user.row),
                    (selectRow = user.row)
                  "
                >
                  {{ $t('adminSetting.editPassword.title')}}
                </BaseElButton>
                <BaseElButton
                  v-if="!selectRow.roleAllShop && displayShopManageByRole(user.row.role)"
                  type="text"
                  @click="
                    openDialog('shop'),
                    syncFormData(user.row),
                    (selectRow = user.row)
                  "
                >
                  {{ $t('adminSetting.editBranch.title')}}
                </BaseElButton>
                <BaseElButton
                  type="text"
                  class="delete"
                  @click=";(deleteDialog = true), (selectRow = user.row)"
                >
                  {{ $t('common.button.delete.text')}}
                </BaseElButton>
              </div>
              <TableSettingIconButton slot="reference" @click="selectRow = user.row" />
              <!-- <BaseElButton
                slot="reference"
                type="text"
                class="btn-type-edit-row"
                icon="el-icon-setting"
                @click="selectRow = user.row"
              /> -->
            </el-popover>
          </template>
        </BaseElTableColumn>
      </BaseTable>
      <Pagination
        :curPage.sync="tableOptions.page"
        :pageLimit="tableOptions.pageLimit"
        :total="usersCount"
        @pageChange="changePage"
      />
    </section>

    <!-- Dialog -->
    <el-dialog
      :title="dialogTitle"
      :visible.sync="showDialog"
      :close-on-click-modal="false"
      @close="resetForm()"
    >
      <BaseElForm
        ref="form"
        :model="formData"
        :rules="formRules"
        label-position="top"
      >
        <BaseElFormItem
          v-if="showInput(['create', 'update'])"
          :label="$t('adminSetting.dialog.name.title')"
          prop="name"
        >
          <BaseElInput v-model="formData.name" :placeholder="$t('adminSetting.dialog.name.placeholder')" />
        </BaseElFormItem>
        <BaseElFormItem
          v-if="showInput(['create'])"
          :label="$t('adminSetting.dialog.email.title')"
          prop="email"
        >
          <BaseElInput v-model="formData.email" :placeholder="$t('adminSetting.dialog.email.placeholder')" />
        </BaseElFormItem>
        <BaseElFormItem
          v-if="showInput(['create', 'password'])"
          :label="$t('adminSetting.dialog.password.title')"
          prop="password"
        >
          <BaseElInput v-model="formData.password" :placeholder="$t('adminSetting.dialog.password.placeholder')" />
        </BaseElFormItem>
        <BaseElFormItem
          v-if="showInput(['create', 'update'])"
          :label="$t('adminSetting.dialog.role.title')"
          prop="role"
        >
          <BaseElSelect
            v-model="formData.role"
            :disabled="disabledRoleSelect"
            :placeholder="$t('adminSetting.dialog.role.placeholder')"
          >
            <BaseElSelectOption
              v-for="item in availableRoleList"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            />
          </BaseElSelect>
        </BaseElFormItem>
        <BaseElFormItem
          v-if="showInput(['create', 'shop']) && formData.role !== 'orgAdmin' && showManageShop(formData.role)"
          :label="$t('adminSetting.dialog.branch.title')"
          prop="Shops"
        >
          <ShopMultiSelect :model.sync="formData.Shops" />
          <!-- <ShopSearch :model.sync="formData.shops" multiple/> -->
        </BaseElFormItem>
      </BaseElForm>

      <div slot="footer">
        <BaseElButton
          plain
          @click=";(showDialog = false), resetForm()"
        >
          {{ $t('common.button.cancel.text')}}
        </BaseElButton>
        <BaseElButton type="primary" @click="handleDialogConfirm">
          {{
            dialogType === 'create' ? $t('common.button.create.text') : $t('common.button.save.text')
          }}
        </BaseElButton>
      </div>
    </el-dialog>

    <DeleteDialog
      v-if="deleteDialog"
      :title="$t('common.dialog.delete.title')"
      :content="$t('common.dialog.delete.content')"
      width="40%"
      @close="deleteDialog = false"
      @delete="deleteUser(), (deleteDialog = false)"
    />
  </div>
</template>

<script>
import adminRole from '@/config/admin'
import ShopMultiSelect from '@/components/Select/ShopMultiSelect.vue'
import {
  GetUser,
  CreateUser,
  DeleteUser,
  UpdateUser,
  UpdateUserPassword,
  UpdateUserShops,
  GetUsersCount,
  FindCustomRole,
} from '@/api/user'
import DeleteDialog from '@/components/Dialog/DeleteDialog'
import EmptyBlock from '@/components/EmptyBlock.vue'
import { noEmptyRules, emailRules } from '@/validation'
// import ShopSearch from '@/components/Search/ShopSearch.vue'
// utils
import { pageStartIndex } from '@/utils/table'
import formUtils from '@/utils/form'
import { dialogTitle } from '@/utils/dialog'
import { extractList } from '@/utils/helper'
// import ls from 'local-storage'
// import { findIndex } from 'lodash'
import { i18n } from '@/plugins/i18n/i18n'
export default {
  name: 'AdminList',
  components: { DeleteDialog, ShopMultiSelect, EmptyBlock },
  data: () => ({
    adminRole,
    loading: false,
    userSearch: '',

    selectRow: null,
    selectUser: null,
    tableOptions: {
      page: 1,
      pageLimit: 10,
    },

    adminRoleList: ['orgAdmin', 'shopManager', 'shopProvider', 'shopMod', 'shopOperation'],

    userList: [],
    usersCount: 0,

    customRoleOptions: [],

    // Dialog
    showDialog: false,
    dialogType: 'create',
    deleteDialog: false,

    // create user from
    formData: {
      name: '',
      email: '',
      password: '',
      role: '',
      Shops: [],
      customRole: [],
    },

    // from rules
    formRules: {
      name: noEmptyRules(),
      email: emailRules(),
      password: [
        { min: 6, message: i18n.t('validation.minLength.text', { number: '6'}), trigger: 'blur' },
        noEmptyRules(),
      ],
      role: noEmptyRules(),
      // Shops: noEmptyRules(),
    },
  }),

  computed: {
    pageStartIndex () {
      return pageStartIndex(this.tableOptions.page, this.tableOptions.pageLimit)
    },

    dialogTitle () {
      return dialogTitle(this.dialogType, {
        create: i18n.t('adminSetting.createAdmin.title'),
        update: i18n.t('adminSetting.editInfo.title'),
        password: i18n.t('adminSetting.editPassword.title'),
        shop: i18n.t('adminSetting.editBranch.title'),
      })
    },

    curUser () {
      return this.$store.getters.user
    },
    disabledRoleSelect () {
      if (!this.selectRow) return false
      return this.selectRow.id === this.curUser.id
    },

    availableRoleList () {
      const availableRoleList = []
      this.adminRoleList.forEach(item => {
        availableRoleList.push(this.adminRole[item])
      })
      this.customRoleOptions.map(item => {
        availableRoleList.push({ name: item.name, value: item.role })
      })

      return availableRoleList
    },
  },

  async mounted () {
    await this.refresh()
  },

  methods: {
    roleTrans (role) {
      const targetRole = this.availableRoleList.find(item => item.value === role)

      return targetRole ? targetRole.name : ''
    },

    tagType (val) {
      const type = {
        orgAdmin: 'warning',
        shopManager: 'action',
        shopProvider: 'info',
        shopMod: 'action',
      }

      return type[val] || 'info'
    },

    async refresh () {
      await this.getUser()
      await this.getUsersCount()
      await this.getUserCustomRole()
    },

    showInput (typeList) {
      return !!typeList.includes(this.dialogType)
    },

    async changePage () {
      await this.getUser()
    },

    clearFormData () {
      this.formData = {
        name: '',
        email: '',
        password: '',
        role: '',
        Shops: [],
      }
    },

    syncFormData (row) {
      this.formData = JSON.parse(JSON.stringify(row))
    },

    async checkForm () {
      return await formUtils.checkForm(this.$refs.form)
    },

    resetForm () {
      formUtils.resetForm(this.$refs.form)
      this.clearFormData()
    },

    deleteOrg () {
      alert('delete')
    },

    async openDialog (type) {
      this.dialogType = type
      this.showDialog = true
    },

    showManageShop (role) {
      const target = this.customRoleOptions.find(item => item.role === role)

      return target ? target.needSettingShops : true
    },

    async handleDialogConfirm () {
      if (!(await this.checkForm())) return
      const type = this.dialogType
      if (type === 'create') await this.createUser()
      if (type === 'shop') await this.updateUserShops()
      if (type === 'password') await this.updateUserPassword()
      if (type === 'update') await this.updateUser()

      this.showDialog = false
    },

    //= > 取得使用者
    async getUser () {
      this.loading = true
      try {
        const startIndex = this.pageStartIndex
        const limit = this.tableOptions.pageLimit
        this.userList = await GetUser({ start: startIndex, limit: limit })
        this.loading = false
      } catch (error) {
        console.log(error)
        this.$message.error({
          message: error || error.message,
        })
      }
    },

    //= > 取得總使用者數量
    async getUsersCount () {
      try {
        this.usersCount = await GetUsersCount({
          // role: this.formData.role === '' ? undefined : this.formData.role,
          // name: this.formData.name === '' ? undefined : this.formData.name,
        })
      } catch (error) {
        console.log(error)
        this.$message.error({
          message: error || error.message,
        })
      }
    },

    //= > 創建使用者
    async createUser () {
      try {
        const form = this.formData
        await CreateUser({
          shops: extractList('id', form.Shops),
          name: form.name,
          email: form.email,
          password: form.password,
          role: form.role,
        })
        this.selectRow = null
        this.clearFormData()
        await this.refresh()
        this.$message.success(i18n.t('common.message.createSuccess'))
      } catch (error) {
        console.log(error)
        this.$message.error({
          message: error || error.message,
        })
      }
    },

    //= > 刪除使用者
    async deleteUser () {
      try {
        await DeleteUser({ id: this.selectRow.id })
        this.selectRow = null
        await this.refresh()
        this.$message.success(i18n.t('common.message.deleteSuccess'))
      } catch (error) {
        console.log(error)
        this.$message.error({
          message: error || error.message,
        })
      }
    },

    //= > 更新使用者
    async updateUser () {
      try {
        await UpdateUser({
          id: this.selectRow.id,
          name: this.formData.name,
          role: this.formData.role,
        })

        await this.refresh()
        this.$message.success(i18n.t('common.message.updateSuccess'))
        // await this.checkUserUpdate(this.selectRow.id, this.formData.role)
        this.selectRow = null
        this.clearFormData()
      } catch (error) {
        console.log(error)
        this.$message.error({
          message: error || error.message,
        })
      }
    },

    //= > 更新使用者店家
    async updateUserShops () {
      try {
        await UpdateUserShops({
          id: this.selectRow.id,
          shops: extractList('id', this.formData.Shops),
        })
        await this.refresh()
        this.$message.success(i18n.t('common.message.updateSuccess'))
      } catch (error) {
        this.$message.error(error)
      }
    },

    //= > 更新密碼
    async updateUserPassword () {
      try {
        await UpdateUserPassword({
          id: this.selectRow.id,
          password: this.formData.password,
        })
        this.selectRow = null
        this.clearFormData()
        await this.refresh()
        this.$message.success(i18n.t('common.message.updateSuccess'))
      } catch (error) {
        console.log(error)
        this.$message(error)
      }
    },

    async getUserCustomRole () {
      try {
        this.customRoleOptions = await FindCustomRole()
      } catch (error) {
        console.log(error)
        this.$message(error)
      }
    },

    displayShopManageByRole (role) {
      const targetRole = this.customRoleOptions.find(item => item.role === role)

      return targetRole ? targetRole.needSettingShops : true
    },
  },
}
</script>

<style scoped lang="scss"></style>
