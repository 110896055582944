<template>
  <div v-loading="loading" class="content-setting">
    <PageTitle
      :title="$t('contentSetting.title')"
      hideBtn
    />
    <BasicSetting :configData="formData" :updatePubAptConfig="updatePubAptConfig" />
    <BannerSetting :configData="formData" :updatePubAptConfig="updatePubAptConfig" />
    <CustomSetting :configData="formData" :updatePubAptConfig="updatePubAptConfig" />
  </div>
</template>

<script>
import PageTitle from '@/components/Title/PageTitle.vue'
import BasicSetting from './components/BasicSetting.vue'
import BannerSetting from './components/BannerSetting.vue'
import CustomSetting from './components/CustomSetting.vue'
import notifyMessage from '@/config/notifyMessage'
import { useTable } from '@/use/table'
import { useFetch } from '@/use/fetch'
import { useShop } from '@/use/shop'
import { defineComponent, reactive, ref, onMounted } from 'vue'
import { FindPubAptConfig, UpdatePubAptConfig } from '@/api/pubApt'

export default defineComponent({
  name: 'ContentSetting',
  components: {
    PageTitle,
    BasicSetting,
    BannerSetting,
    CustomSetting,
  },
  setup () {
    const { simpleFetch } = useFetch()
    const { tableOptions } = useTable()
    const { shopId } = useShop()
    const loading = ref(false)
    const formData = reactive({
      code: null,
      enable: false,
      priceRate: null,
      isShowIndustry: false,
      homeMode: null,
      fullBannerSetting: [],
      fullContentSetting: [],
    })

    const updatePubAptConfig = async (data) => {
      const [, err] = await UpdatePubAptConfig({
        shopId: shopId.value,
        data,
      })
      if (err) {
        window.$message.error(err)
        return
      }
      window.$message.success(notifyMessage.updateSuccess)
      refresh()
    }
    const refresh = async () => {
      loading.value = true
      await simpleFetch(FindPubAptConfig, { shopId: shopId.value }, (res) => {
        syncData(formData, res)
      })
      loading.value = false
    }
    const syncData = async (data, res) => {
      for (const key in data) {
        if (key in res) {
          data[key] = res[key]
        }
      }
    }
    onMounted(async () => {
      await refresh()
    })
    return {
      refresh,
      loading,
      tableOptions,
      formData,
      updatePubAptConfig,
    }
  },
})
</script>

<style scoped lang="postcss">
.monthlyData {
  @apply max-w-[692px] bg-white p-[20px] rounded-[4px] mb-[20px];
  box-shadow: 0 1px 13px 0 #0000001A;
}
.selectMonth {
  @apply w-[134px]
}
.login-auth {
  @apply flex justify-center gap-[4px];
}
.reservations-list {
  height: 100%;
}
</style>
