<template>
  <BaseDialog
    :title="displayData.title"
    :btn1-loading="loading"
    :hideConfirm="!showControl.btn.confirm"
    :btn1="displayData.btn.confirm"
    :btn2="displayData.btn.cancel"
    @close="$emit('close')"
    @confirm="onConfirm"
  >
    <div v-if="showControl.unsetPassword" style="margin-bottom: 40px;">
      <div>
        尚未設定銷售紀錄作廢密碼 <br>
        請前往「基本參數設定 → 銷售紀錄參數設定」進行設定。
      </div>
    </div>
    <div v-else style="margin-bottom: 40px;">
      <BaseElForm ref="formRef" :model="formData" :rules="formRules">
        <BaseElFormItem :label="displayData.formItemlabel.password" prop="password">
          <BaseElInput
            v-model="formData.password"
            type="password"
            show-password
            :placeholder="displayData.placeholder.password"
          />
        </BaseElFormItem>
      </BaseElForm>
    </div>
  </BaseDialog>
</template>

<script>
import { computed, defineComponent, inject } from 'vue'
import BaseDialog from './Dialog/BaseDialog.vue'
import { CancelSalesRecord } from '@/api/sales'
import { useShop } from '@/use/shop'
import { get } from 'lodash'
import { apiFormatAdaptor } from '@/utils/api'
import { useBaseForm } from '@/use/useForm'
import { noEmptyRules } from '@/validation'

export default defineComponent({
  name: 'SalesRecordCancelModal',
  components: {
    BaseDialog,
  },
  props: {
    record: {
      type: Object,
      default: () => ({}),
      required: true,
    },
    configData: {
      type: Object,
      default: () => ({}),
    },
  },
  setup (props, { emit }) {
    const { shopId } = useShop()
    const { formRef, formData, initFormData, checkForm, loading } = useBaseForm()
    initFormData({
      password: null,
    })
    const formRules = computed(() => ({
      password: [noEmptyRules()],
    }))
    const useInvalidPassword = computed(() => get(props.configData, 'useInvalidPassword'))
    const displayData = computed(() => {
      const data = {
        title: '輸入密碼',
        formItemlabel: {
          password: '密碼',
        },
        placeholder: {
          password: '請輸入密碼',
        },
        btn: {
          confirm: '確認',
          cancel: '取消',
        },
      }

      if (!useInvalidPassword.value) {
        data.btn.cancel = '關閉'
      }

      return data
    })

    const showControl = computed(() => {
      const control = {
        unsetPassword: !useInvalidPassword.value,

        btn: {
          confirm: true,
        },
      }

      if (!useInvalidPassword.value) {
        control.btn.confirm = false
      }

      return control
    })

    const onConfirm = async () => {
      loading.value = true
      if (!await checkForm(formRef.value)) {
        loading.value = false
        return
      }
      const [, err] = await apiFormatAdaptor(CancelSalesRecord)({
        shopId: shopId.value,
        id: get(props.record, 'id'),
        password: formData.password,
      })
      loading.value = false
      if (err) {
        window.$message.error(err)
        return
      }

      emit('close')
      emit('refresh')
    }
    return { onConfirm, formData, displayData, showControl, formRules, formRef, loading }
  },
})
</script>

<style lang="postcss" scoped>

</style>
