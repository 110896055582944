import DevOnly from './DevOnly.vue'
import Tag from '@/components/Tag/Tag.vue'
import Pagination from '@/components/Pagination/Pagination.vue'
import BaseTable from '@/components/BaseTable.vue'
import BaseElInput from './BaseElInput.vue'
import BaseElFormItem from './BaseElFormItem.vue'
import BaseElForm from './BaseElForm.vue'
import BaseElSelect from './BaseElSelect.vue'
import BaseElInputNumber from './BaseElInputNumber.vue'
import BaseElSelectOption from './BaseElSelectOption.vue'
import BaseElTableColumn from './BaseElTableColumn.vue'
import BaseElButtonGroup from './BaseElButtonGroup.vue'
import BaseElButton from './BaseElButton.vue'
import BaseElSwitch from './BaseElSwitch.vue'
import BaseElCheckboxGroup from './BaseElCheckboxGroup.vue'
import BaseElCheckbox from './BaseElCheckbox.vue'
import BaseElRadio from './BaseElRadio.vue'
import BaseElRadioGroup from './BaseElRadioGroup.vue'
import BaseDivider from './BaseDivider.vue'
// Button
import TableEditBtnGroup from '@/components/Button/TableEditBtnGroup.vue'
import TableSettingIconButton from '@/components/Button/TableSettingIconButton.vue'
import AddButton from '@/components/Button/AddButton.vue'
// Title
import PageTitle from '@/components/Title/PageTitle.vue'
import PageTitleExtra from '@/components/Title/PageTitleExtra.vue'
import SectionTitle from '@/components/Title/SectionTitle.vue'
import TitleWithHint from '@/components/Title/TitleWithHint.vue'
import DialogTitleWithHint from '@/components/Title/DialogTitleWithHint.vue'
// Footer
import PageFixedFooter from '@/components/Footer/PageFixedFooter.vue'
// Dialog
import DeleteDialog from '@/components/Dialog/DeleteDialog.vue'
// Block
import EmptyBlock from '@/components/EmptyBlock.vue'
// Container
import FiltersContainer from '@/components/Container/FiltersContainer.vue'
import BasicTabs from '@/components/Tabs/BasicTabs.vue'

import MaterialIcon from '@/components/MaterialIcon.vue'
import SVGIconVue from '@/components/SVGIcon.vue'

export default {
  install (Vue) {
    Vue.component('BaseDivider', BaseDivider)
    Vue.component('DevOnly', DevOnly)
    Vue.component('BaseElInput', BaseElInput)
    Vue.component('MaterialIcon', MaterialIcon)
    Vue.component('BaseElFormItem', BaseElFormItem)
    Vue.component('BaseElForm', BaseElForm)
    Vue.component('BaseElSelect', BaseElSelect)
    Vue.component('BaseElInputNumber', BaseElInputNumber)
    Vue.component('BaseElSelectOption', BaseElSelectOption)
    Vue.component('BaseElTableColumn', BaseElTableColumn)
    Vue.component('BaseElButtonGroup', BaseElButtonGroup)
    Vue.component('BaseElButton', BaseElButton)
    Vue.component('BaseElSwitch', BaseElSwitch)
    Vue.component('BaseElCheckbox', BaseElCheckbox)
    Vue.component('BaseElCheckboxGroup', BaseElCheckboxGroup)
    Vue.component('BaseElRadioGroup', BaseElRadioGroup)
    Vue.component('BaseElRadio', BaseElRadio)
    Vue.component('BaseTable', BaseTable)
    Vue.component('Tag', Tag)
    Vue.component('Pagination', Pagination)
    // Button
    Vue.component('AddButton', AddButton)
    Vue.component('TableEditBtnGroup', TableEditBtnGroup)
    Vue.component('TableSettingIconButton', TableSettingIconButton)
    // Title
    Vue.component('PageTitle', PageTitle)
    Vue.component('PageTitleExtra', PageTitleExtra)
    Vue.component('SectionTitle', SectionTitle)
    Vue.component('TitleWithHint', TitleWithHint)
    Vue.component('DialogTitleWithHint', DialogTitleWithHint)
    // Footer
    Vue.component('PageFixedFooter', PageFixedFooter)
    // Dialog
    Vue.component('DeleteDialog', DeleteDialog)
    // Block
    Vue.component('EmptyBlock', EmptyBlock)
    // Container
    Vue.component('FiltersContainer', FiltersContainer)

    Vue.component('BasicTabs', BasicTabs)
    Vue.component('SVGIcon', SVGIconVue)
  },
}
