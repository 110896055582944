<template>
  <el-dialog
    class="dialog"
    :title="title"
    v-bind="$attrs"
    width="450px"
    :visible="true"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <p v-if="useExportTask" class="mb-[8px]">{{ $t('common.dialog.message.toDownloadCenter.text')}}</p>
    <BaseElForm :model="formData" label-position="top">
      <BaseElFormItem v-if="!allRange" :label="$t('common.dialog.exportRange.title')">
        <BaseElRadioGroup v-model="formData.allRange">
          <BaseElRadio :label="true">{{ $t('common.dialog.exportRange.all.title')}}</BaseElRadio>
          <BaseElRadio :label="false">{{ $t('common.dialog.exportRange.specify.title')}}</BaseElRadio>
        </BaseElRadioGroup>

        <div>
          <el-date-picker
            v-if="!formData.allRange"
            v-model="formData.range"
            editable
            :type="timeSelectorType"
            :range-separator="$t('common.input.datepicker.rangeSeparator')"
            :start-placeholder="$t('common.input.datepicker.startDate.title')"
            :end-placeholder="$t('common.input.datepicker.endDate.title')"
            format="yyyy-MM-dd HH:mm"
            :default-time="['00:00:00', '23:59:59']"
          />
        </div>
      </BaseElFormItem>
      <BaseElFormItem :label="$t('common.dialog.exportType.title')">
        <BaseElSelect
          v-model="formData.format"
          style="width: 100%"
          :placeholder="$t('common.dialog.exportType.placeholder')"
        >
          <BaseElSelectOption
            v-for="item in exportFormatOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </BaseElSelect>
      </BaseElFormItem>
    </BaseElForm>
    <p v-if="info" class="text-base text-sub">{{ info }}</p>
    <template #footer>
      <BaseElButton plain @click="$emit('close')">{{ $t('common.button.cancel.text')}}</BaseElButton>
      <BaseElButton
        type="primary"
        style="margin-left: 12px"
        :disabled="!formData.allRange && !formData.range"
        @click="$emit('export', {
          all: formData.allRange || allRange,
          range: formData.range,
        })"
      >
        {{ $t('common.button.confirm.text')}}
      </BaseElButton>
    </template>
  </el-dialog>
</template>

<script>
import { exportFormatOptions } from '@/config/export'
import { computed, reactive } from 'vue'
import { i18n } from '@/plugins/i18n/i18n'

export default {
  name: 'ExportOptionsDialog',
  props: {
    allRange: {
      type: Boolean,
      default: false,
    },
    disabledTime: Boolean,
    title: {
      type: String,
      default: i18n.t('common.button.export.text'),
    },
    info: {
      type: String,
      default: '',
    },
    useExportTask: {
      type: Boolean,
      default: false,
    },
  },
  setup (props, { emit }) {
    const formData = reactive({
      allRange: true,
      range: null,
      format: 'excel',
    })
    const timeSelectorType = computed(() => {
      if (props.disabledTime) return 'daterange'
      return 'datetimerange'
    })
    return { exportFormatOptions, formData, timeSelectorType }
  },
}
</script>

<style scoped lang="scss">
.dialog {
  text-align: left;
}

::v-deep .el-input {
  width: 100% !important;
  max-width: 400px !important;
}
::v-deep .el-select {
  width: 100% !important;
  max-width: 400px !important;
}
::v-deep .el-input__inner {
  width: 100% !important
}
</style>
