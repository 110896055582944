<template>
  <div class="sales-pos-entry-specifyDate">
    <p class="title">{{ displayData.title }}</p>
    <BaseElForm ref="formRef" :model="formData" :rules="formRules" label-position="top">
      <BaseElFormItem :label="displayData.formItemLabel.salesDate" prop="salesDate">
        <el-date-picker
          v-model="formData.salesDate"
          editable
          :picker-options="pickerOptions"
          type="date"
          :placeholder="displayData.placeholder.salesDate"
        />
      </BaseElFormItem>
      <BaseElFormItem :label="displayData.formItemLabel.salesTime" prop="salesTime">
        <el-time-picker
          v-model="formData.salesTime"
          :placeholder="displayData.placeholder.salesTime"
        />
      </BaseElFormItem>
    </BaseElForm>

    <PageFixedFooter
      @cancel="onCancel"
      @confirm="onConfirm"
    />
  </div>
</template>

<script>
import PageFixedFooter from '@/components/Footer/PageFixedFooter.vue'
import { useBaseForm } from '@/use/useForm'
import { computed, defineComponent } from 'vue'
import dayjs from '@/lib/dayjs'
import { noEmptyRules } from '@/validation'
import formUtils from '@/utils/form'
import { useRouter } from 'vue-router/composables'
import { formatDate } from '@/utils/date'

export default defineComponent({
  name: 'SalesPOSEntrySpecifyDate',
  components: {
    PageFixedFooter,
  },
  setup (props) {
    const { formRef, formData, initFormData } = useBaseForm()
    const router = useRouter()
    initFormData({
      salesDate: null,
      salesTime: null,
    })

    const formRules = computed(() => {
      const rules = {
        salesDate: [noEmptyRules()],
        salesTime: [noEmptyRules()],
      }
      return rules
    })

    const displayData = computed(() => {
      const data = {
        title: '建立跨日銷售紀錄',
        formItemLabel: {
          salesDate: '銷售紀錄建立日期 (僅限7日內)',
          salesTime: '銷售紀錄建立時間',
        },
        placeholder: {
          salesDate: '選擇日期',
          salesTime: '選擇時間',
        },
      }
      return data
    })

    const pickerOptions = {
      disabledDate: (date) => {
        const minDate = dayjs().subtract(6, 'd')
        const maxDate = dayjs()
        const cur = dayjs(date)
        return cur.isBefore(minDate, 'd') || cur.isAfter(maxDate, 'd')
      },
    }

    const onCancel = () => {
      formData.salesDate = null
      formData.salesTime = null
    }
    const onConfirm = async () => {
      const pass = await formUtils.checkForm(formRef.value)
      if (!pass) return
      const date = formatDate(formData.salesDate, 'YYYY-MM-DD')
      const time = formatDate(formData.salesTime, 'HH:mm:ss')
      router.push({
        name: 'SalesPOSEntry',
        query: {
          sellAt: `${date} ${time}`,
        },
      })
    }

    return {
      displayData,
      formRef,
      formData,
      formRules,
      onCancel,
      onConfirm,
      pickerOptions,
    }
  },
})
</script>

<style lang="postcss" scoped>
.sales-pos-entry-specifyDate {
  @apply p-[30px];
  @apply flex flex-col gap-[24px];
}

.title {
  @apply font-medium text-[20px] leading-[28.96px];
}
</style>
