<template>
  <HorizontalTabs
    :tabs="displayTabs"
    :value.sync="context.salesUnitGroup"
    type="card"
    :tab-gap="0"
  />
</template>

<script>
import { computed, defineComponent } from 'vue'
import HorizontalTabs from '@/components/HorizontalTabs.vue'
import { useSalesPOSCreate } from '@/use/useSalesPOS'
import { map, get, filter } from 'lodash'
import { sortOrder } from '@/utils/helper'

export default defineComponent({
  name: 'SalesPOSSalesUnitGroupTab',
  components: {
    HorizontalTabs,
  },
  setup (props, { emit }) {
    const { context, configData } = useSalesPOSCreate()
    const displayTabs = computed(() => {
      const dataList = filter(get(configData, 'menu.salesGroupMenus', []), { salesGroup: { type: 'salesUnit' } })
      const data = sortOrder(map(dataList, 'salesGroup'))
      data.push({ name: '其他', id: null })
      const showList = map(data, (item) => ({
        label: item.name,
        key: item.id,
      }))

      return showList
    })
    return {
      context,
      displayTabs,
    }
  },
})
</script>

<style lang="postcss" scoped>

</style>
