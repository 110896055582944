import { get, uniqBy, forEach, find, map, cloneDeep, filter, isNull } from 'lodash'
import { useShop } from '@/use/shop'
import { useFetch } from '@/use/fetch'
import { GetShiftsClass } from '@/api/shiftsClass'
import { computed, reactive, ref, set } from 'vue'
import { GetShiftsSchedule, UpdateShiftsSchedule } from '@/api/shiftSchedule'
import dayjs from '@/lib/dayjs'
import { apiFormatAdaptor } from '@/utils/api'
import { sortOrder } from '@/utils/helper'

export const useShiftCalendar = () => {
  const { shopId } = useShop()
  const { fetchAllOld, simpleFetchOld, fetchAll } = useFetch()

  const shopShiftClassList = ref([]) // 店家所有班別
  const unitSchedule = ref({}) // 當前各人員排班表
  const shopSchedule = ref([]) // 當前店家所有人排班表

  // => 取得當前店家所有班別
  const getShopShiftsClass = async () => {
    await fetchAllOld(GetShiftsClass, { shopId: shopId.value }, (result) => {
      shopShiftClassList.value = sortOrder(result)
    })
  }

  // => 取得當前店家所有排班表
  const getShopShiftsSchdule = async ({ dateRange } = {}) => {
    let payload = { shopId: shopId.value }
    if (dateRange) payload = { shopId: shopId.value, ...dateRange }
    await fetchAll(apiFormatAdaptor(GetShiftsSchedule), payload, (res) => {
      shopSchedule.value = res
    }, null, { limit: 500 })
  }

  // => 更新人員排班表
  const updateUnitSchedule = (unit) => {
    const schedule = shopSchedule.value.filter(
      (item) => item.AppointmentUnitId === unit.id,
    )
    set(unitSchedule.value, unit.id, [])
    unitSchedule.value[unit.id].push(...schedule)
    set(unitSchedule.value, unit.id, uniqBy(unitSchedule.value[unit.id], 'id'))
  }

  // => 更新排班班表
  const updateShopShiftsSchedule = async (newSchedule) => {
    await simpleFetchOld(UpdateShiftsSchedule, { shopId: shopId.value, data: newSchedule }, () => {
      window.$message.success('更新排班班表成功 !')
    })
  }

  return {
    unitSchedule,
    shopSchedule,
    shopShiftClassList,
    getShopShiftsClass,
    getShopShiftsSchdule,
    updateUnitSchedule,
    updateShopShiftsSchedule,
  }
}

export const useEditUnitSchedule = () => {
  const cachedEditUnitSchedule = ref({}) // 當前編輯各人員緩存排班表
  const displayUnitSchedule = ref([])

  // 合併當前人員排班: 原有排班 + 當前已編輯排班 = 當前顯示的排班表
  const getUnitDisplaySchedule = ({ serviceUnitId, unitSchedule }) => {
    // const serviceUnitId = get(editContext.serviceUnit, 'id')
    if (!serviceUnitId) return []

    let unitOriginSchedule = map(cloneDeep(get(unitSchedule, serviceUnitId) || []), (item) => ({
      day: dayjs(item.day).format('YYYY-MM-DD'),
      appointmentScheduleDayId: item.AppointmentScheduleDayId,
      appointmentUnitId: item.AppointmentUnitId,
    }))
    const unitEditedSchedule = cloneDeep(get(cachedEditUnitSchedule.value, serviceUnitId) || [])

    forEach(unitEditedSchedule, (item) => {
      const sameDay = find(unitOriginSchedule, { day: item.day })
      const newShiftClassId = get(item, 'appointmentScheduleDayId')
      if (sameDay) {
        // 移除排班
        if (isNull(newShiftClassId)) unitOriginSchedule = filter(unitOriginSchedule, (i) => item.day !== i.day)
        // 覆蓋原來的排班資料
        else sameDay.appointmentScheduleDayId = newShiftClassId
      } else {
        // 增加新的排班資料
        unitOriginSchedule.push({
          ...item,
          appointmentUnitId: serviceUnitId,
        })
      }
    })

    displayUnitSchedule.value = unitOriginSchedule
  }

  // 所有人員最終混和排班表
  const submitEditedSchedule = computed(() => {
    const allNewSchedule = []

    for (const serviceUnitId in cachedEditUnitSchedule.value) {
      const newSchedule = map(get(cachedEditUnitSchedule.value, serviceUnitId), (item) => {
        // 如果班別是 null -> 移除屬性，代表當日無排班(移除排班)
        if (isNull(item.appointmentScheduleDayId)) delete item.appointmentScheduleDayId
        return {
          ...item,
          appointmentUnitId: serviceUnitId,
        }
      })
      allNewSchedule.push(...newSchedule)
    }

    return allNewSchedule
  })

  // TODO 合併班表

  // 更新人員緩存班表
  const updateUnitCachedEditSchedule = ({
    mode,
    date,
    serviceUnitId,
    shiftClassId,
  }) => {
    let unitSchedule = cloneDeep(get(cachedEditUnitSchedule.value, serviceUnitId))
    if (!unitSchedule) unitSchedule = []

    const payload = {
      day: date,
      appointmentScheduleDayId: shiftClassId,
    }
    if (mode === 'remove') {
      if (!unitSchedule) return
      unitSchedule = filter(unitSchedule, (item) => item.day !== date)
    } else if (mode === 'remove-update') {
      const sameDay = find(unitSchedule, { day: date })
      if (sameDay) sameDay.appointmentScheduleDayId = null
      else {
        payload.appointmentScheduleDayId = null
        unitSchedule.push(payload)
      }
    } else {
      const sameDay = find(unitSchedule, { day: date })
      if (sameDay) sameDay.appointmentScheduleDayId = shiftClassId
      else unitSchedule.push(payload)
    }
    if (!unitSchedule) set(unitSchedule, serviceUnitId, [])
    set(cachedEditUnitSchedule.value, serviceUnitId, unitSchedule)
  }

  return { cachedEditUnitSchedule, displayUnitSchedule, getUnitDisplaySchedule, updateUnitCachedEditSchedule, submitEditedSchedule }
}
