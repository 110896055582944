<template>
  <div class="sales-pos-side-panel">
    <SalesPOSMemberInfoBlock />

    <div class="sales-pos-side-panel-menu">
      <div v-if="!displayData.length" class="flex-1 flex justify-between items-center">
        <EmptyBlock />
      </div>

      <SalesPOSSidePanelProductItemGroup
        v-for="salesUnit in displayData"
        :key="salesUnit.id"
        :data="salesUnit"
      >
        <SalesPOSSidePanelProductItem v-for="item in salesUnit.items" :key="item.nanoId" :data="item" />
      </SalesPOSSidePanelProductItemGroup>
    </div>

    <SalesPOSSidePanelFooter />
  </div>
</template>

<script>
import { computed, defineComponent } from 'vue'
import SalesPOSMemberInfoBlock from './SalesPOSMemberInfoBlock.vue'
import SalesPOSSidePanelProductItemGroup from './SalesPOSSidePanelProductItemGroup.vue'
import SalesPOSSidePanelProductItem from './SalesPOSSidePanelProductItem.vue'
import EmptyBlock from '@/components/EmptyBlock.vue'
import { useSalesPOSCreate } from '@/use/useSalesPOS'
import { uniq, map, filter, find, get, compact } from 'lodash'
import SalesPOSSidePanelFooter from './SalesPOSSidePanelFooter.vue'

export default defineComponent({
  name: 'SalesPOSSidePanel',
  components: {
    SalesPOSMemberInfoBlock,
    EmptyBlock,
    SalesPOSSidePanelProductItemGroup,
    SalesPOSSidePanelProductItem,
    SalesPOSSidePanelFooter,
  },
  setup (props, { emit }) {
    const { cart, sortedMenus, context } = useSalesPOSCreate()

    const displayData = computed(() => {
      // 取得 cart 中所有的 salesUnit 有哪些
      const salesUnits = compact(uniq(map(cart.value, (item) => item.salesUnit)))
      // 依據 cart 中每一筆資料的 salesUnit 來分組
      const groups = {
        other: {
          name: '無銷售人員',
          id: null,
          items: [],
        },
      }
      for (const salesUnit of salesUnits) {
        if (!groups[salesUnit]) {
          const salesUnitData = find(sortedMenus.value.salesUnit, { id: salesUnit })
          groups[salesUnit] = { name: get(salesUnitData, 'name'), id: salesUnit, items: [] }
        }
        groups[salesUnit].items = filter(cart.value, (item) => item.salesUnit === salesUnit)
      }

      groups.other.items = filter(cart.value, (item) => !item.salesUnit)
      return filter(groups, (group) => group.items.length > 0)
    })

    return { displayData, context }
  },
})
</script>

<style lang="postcss" scoped>
.sales-pos-side-panel {
  @apply h-full flex flex-col;
}

.sales-pos-side-panel-menu {
  @apply bg-[#F6F7FB] flex-1 overflow-x-auto;
  @apply px-[16px] py-[12px];
  @apply flex flex-col gap-[12px];
}
</style>
