<template>
  <div class="sales-pos-checkout-detail">
    <div class="flex-1 text-sub overflow-y-auto">
      <div>
        <div class="flex items-center justify-between cursor-pointer" style="padding: 16px 12px;" @click="expand.products = !expand.products">
          <div class="flex items-center">
            <p>品項數量</p>
            <MaterialIcon v-if="!expand.products">keyboard_arrow_down</MaterialIcon>
            <MaterialIcon v-if="expand.products">keyboard_arrow_up</MaterialIcon>
          </div>
          <p>{{ displayData.cartItemCount }}</p>
        </div>

        <el-collapse-transition>
          <div v-show="expand.products" class="flex flex-col" style="gap: 6px">
            <div v-if="displayData.appointmentProducts.length" class="additional-product-list">
              <p class="text-primary-100 font-medium">今日服務</p>
              <div v-for="item in displayData.appointmentProducts" :key="item.nanoId" class="additional-product-detail">
                <div class="row-item">
                  <p>{{ item.name }}<span>x{{ item.quantity }}</span></p>
                  <p class="flex-shrink-0">$ {{ isUndefined(item.customPrice) ? item.defaultPrice : item.customPrice }}</p>
                </div>

                <div v-if="item.discounts.length">
                  <div v-for="(discount, idx) in item.getSingleDiscountsDetail()" :key="idx" class="flex justify-between text-gray-60 text-sm" style="gap: 8px">
                    <p>{{ discount.name }}</p>
                    <p class="flex-shrink-0">-$ {{ discount.savePrice }}</p>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="displayData.additionalProducts.length" class="additional-product-list">
              <p class="text-primary-100 font-medium">加購商品</p>
              <div v-for="item in displayData.additionalProducts" :key="item.nanoId" class="additional-product-detail">
                <div class="row-item">
                  <p>{{ item.name }}<span>x{{ item.quantity }}</span></p>
                  <p class="flex-shrink-0">$ {{ isUndefined(item.customPrice) ? item.defaultPrice : item.customPrice }}</p>
                </div>

                <div v-if="item.discounts.length">
                  <div v-for="(discount, idx) in item.getSingleDiscountsDetail()" :key="idx" class="flex justify-between text-gray-60 text-sm" style="gap: 8px">
                    <p>{{ discount.name }}</p>
                    <p class="flex-shrink-0">-$ {{ discount.savePrice }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-collapse-transition>
      </div>

      <!-- 品項合計 -->
      <div style="padding: 0 12px;">
        <div class="row-item">
          <p>品項合計</p>
          <p class="flex-shrink-0">$ {{ displayData.originTotalPrice }}</p>
        </div>
      </div>

      <!-- 單項折扣累計 -->
      <div style="padding: 0 12px;">
        <div class="row-item">
          <p>單項折扣累計</p>
          <p class="flex-shrink-0">-$ {{ displayData.totalSingleDiscount }}</p>
        </div>
      </div>

      <!-- 整單折扣 -->
      <div v-if="showControl.orderDiscounts" style="padding: 0 12px;">
        <div class="row-item cursor-pointer" @click="expand.orderDiscounts = !expand.orderDiscounts">
          <div class="flex items-center">
            <p>整單折扣</p>
            <MaterialIcon v-if="!expand.orderDiscounts">keyboard_arrow_down</MaterialIcon>
            <MaterialIcon v-if="expand.orderDiscounts">keyboard_arrow_up</MaterialIcon>
          </div>
          <p class="flex-shrink-0">-$ {{ displayData.totalOrderDiscount }}</p>
        </div>

        <el-collapse-transition>
          <div v-show="expand.orderDiscounts">
            <div v-for="item in displayData.orderDiscount" :key="item.id" class="sub-row-item">
              <p>{{ item.name }}</p>
              <p class="flex-shrink-0">-$ {{ item.savePrice }}</p>
            </div>
          </div>
        </el-collapse-transition>
      </div>

      <!-- 總折扣 -->
      <div style="padding: 0 12px;">
        <div class="row-item">
          <p>總折扣</p>
          <p class="text-danger flex-shrink-0">-$ {{ displayData.totalDiscount }}</p>
        </div>
      </div>

      <!-- 已獲得獎勵 -->
      <div v-if="showControl.rewardInfo" v-loading="loading.rewardInfo" style="padding: 0 12px;">
        <div class="divider" />
        <div v-for="item in displayRewardData" :key="item.label" class="row-item">
          <p>{{ item.label }}</p>
          <p class="flex-shrink-0">{{ item.amount }}</p>
        </div>
      </div>

      <!-- 舊制回饋金 -->
      <div v-if="showControl.oldCashbackRecord" style="padding: 0 12px;">
        <div v-if="!showControl.rewardInfo" class="divider" />
        <div class="row-item">
          <p>獲得回饋金</p>
          <div>
            <ElInputWrapper>
              <BaseElInput
                ref="oldCashbackUseInputRef"
                v-model="context.oldCashbackUse"
                type="number"
                style="width: 100px"
                @blur="onBlurOldCashbackUse"
                @clear="onClearOldCashbackUse"
              />
            </ElInputWrapper>
          </div>
        </div>
      </div>
    </div>

    <div style="padding: 0 12px;">
      <div class="divider" />
      <div>
        <div class="row-item font-bold text-normal">
          <p>合計</p>
          <p class="flex-shrink-0">$ {{ displayData.finalTotalPrice }}</p>
        </div>
      </div>

      <!-- 已使用付款資訊 -->
      <div>
        <div v-for="item in displayPaymentData" :key="item.key" class="row-item text-normal !text-sub">
          <p>{{ item.name }}</p>
          <p class="flex-shrink-0">$ {{ item.price }}</p>
        </div>
      </div>

      <div>
        <div class="row-item font-bold text-normal">
          <p>應收現金</p>
          <p class="text-primary-100 flex-shrink-0">$ {{ displayData.needPaidCashPrice }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useSalesPOSCreate } from '@/use/useSalesPOS'
import { computed, defineComponent, inject, reactive, ref } from 'vue'
import { isUndefined, map, find, get, filter, reduce, uniqueId, toNumber, isNull } from 'lodash'

import { paymentOptionsConfig } from '@/config/payment'
import ElInputWrapper from '@/components/ElInputWrapper.vue'

export default defineComponent({
  name: 'SalesPOSCheckoutDetail',
  components: { ElInputWrapper },
  setup (props, { emit }) {
    const rewardInfo = inject('rewardInfo')
    const loading = inject('loading')
    const {
      cart,
      context,
      getAllSingleDiscountsPrice,
      getAllOrderDiscountsPrice,
      getAllOrderDiscountsDetail,
      originTotalPrice,
      needPaidCashPrice,
      orderUnpaidPrice,
      originData,
      flags,
    } = useSalesPOSCreate()

    const oldCashbackUseInputRef = ref(null)

    const expand = reactive({
      products: false,
      orderDiscounts: false,
    })

    const paidDeposit = computed(() => {
      return reduce(context.payments, (sum, payment) => {
        if (payment.type === 'paidDeposit') {
          return sum + payment.price
        }
        return sum
      }, 0)
    })

    const showControl = computed(() => {
      const controls = {
        rewardInfo: false,
        orderDiscounts: false,
        oldCashbackRecord: false,
      }

      if (displayRewardData.value.length) {
        controls.rewardInfo = true
      }

      if (get(displayData.value, 'orderDiscount.length')) {
        controls.orderDiscounts = true
      }

      const oldCashbackRewardList = filter(rewardInfo.value, { type: 'salesCashback' }) // 舊制回饋金
      if (oldCashbackRewardList.length) {
        controls.oldCashbackRecord = true
      }

      return controls
    })

    const displayData = computed(() => {
      const totalOrderDiscount = getAllOrderDiscountsPrice()
      const totalSingleDiscount = getAllSingleDiscountsPrice()

      const additionalProducts = filter(cart.value, { type: 'salesProduct' })
      const appointmentProducts = filter(cart.value, (item) => [
        'appointmentService',
        'appointmentServiceAttach',
      ].includes(item.type))

      const data = {
        cartItemCount: cart.value.length,
        additionalProducts,
        appointmentProducts,
        originTotalPrice: originTotalPrice.value,
        totalSingleDiscount,
        orderDiscount: getAllOrderDiscountsDetail(),
        totalOrderDiscount,
        totalDiscount: totalSingleDiscount + totalOrderDiscount,
        deposit: paidDeposit.value,
        finalTotalPrice: orderUnpaidPrice.value,
        needPaidCashPrice: needPaidCashPrice.value,
      }

      return data
    })

    // 顯示的獎勵資料
    const displayRewardData = computed(() => {
      const cashbackRewardList = filter(rewardInfo.value, { type: 'rewardActivity', rewardActivityInfo: { rewardType: 'CASHBACK' } })
      const pointRewardList = filter(rewardInfo.value, { type: 'rewardActivity', rewardActivityInfo: { rewardType: 'POINT' } })
      const data = []

      if (cashbackRewardList.length) {
        const totalAmount = reduce(cashbackRewardList, (sum, item) => sum + get(item, 'rewardActivityInfo.rewardAmount', 0), 0)
        if (totalAmount) {
          data.push({
            label: '獲得檔期回饋金',
            type: 'CASHBACK',
            amount: totalAmount,
          })
        }
      }

      if (pointRewardList.length) {
        const totalAmount = reduce(pointRewardList, (sum, item) => sum + get(item, 'rewardActivityInfo.rewardAmount', 0), 0)
        if (totalAmount) {
          data.push({
            label: '獲得點數',
            type: 'POINT',
            amount: totalAmount,
          })
        }
      }

      return data
    })

    // 顯示的已使用付款資料
    const displayPaymentData = computed(() => {
      const paymentOptionsConfigExtend = {
        ...paymentOptionsConfig,
        paidDeposit: {
          name: '已收訂金',
          value: 'paidDeposit',
        },
        credit: {
          name: '信用卡',
          value: 'credit',
        },
        other: {
          name: '其他',
          value: 'other',
        },
      }
      paymentOptionsConfigExtend.offline.name = '現場付款'
      paymentOptionsConfigExtend.atm.name = '轉帳'

      const payments = map(context.payments, (paymentMethod) => {
        return {
          name: get(find(paymentOptionsConfigExtend, { value: paymentMethod.type }), 'name'),
          price: paymentMethod.price,
          key: uniqueId(),
          value: paymentMethod.type,
        }
      })

      // 合併多筆 value 為 paidDeposit 的付款方式
      const paidDeposit = reduce(payments, (sum, payment) => {
        if (payment.value === 'paidDeposit') {
          return sum + payment.price
        }
        return sum
      }, 0)

      const havePaidDeposit = !!find(payments, { value: 'paidDeposit' })

      if (havePaidDeposit) {
        return filter(payments, (payment) => payment.name !== '已收訂金').concat({
          name: '已收訂金',
          price: paidDeposit,
          key: uniqueId(),
        })
      }

      return payments
    })

    const onBlurOldCashbackUse = (e) => {
      let useAmount = context.oldCashbackUse
      if (useAmount === '0') {
        useAmount = 0
      } else if (!useAmount) {
        useAmount = null
      }

      if (!isNull(useAmount)) {
        if (originData.oldCashbackUse !== toNumber(useAmount)) {
          flags.oldCashbackUseChanged = true
        }
      }

      emit('refreshRewardInfo', useAmount)
    }

    const onClearOldCashbackUse = (e) => {
      flags.oldCashbackUseChanged = false
      originData.oldCashbackUse = null
      if (oldCashbackUseInputRef.value) {
        const inputEl = oldCashbackUseInputRef.value.$el.getElementsByTagName('input')[0]
        if (inputEl) inputEl.focus()
      }
    }

    return {
      loading,
      showControl,
      displayRewardData,
      displayData,
      isUndefined,
      expand,
      context,
      displayPaymentData,
      onBlurOldCashbackUse,
      onClearOldCashbackUse,
      oldCashbackUseInputRef,
    }
  },
})
</script>

<style lang="postcss" scoped>
.sales-pos-checkout-detail {
  @apply flex flex-col justify-between select-none overflow-hidden flex-1;
}
/* px-[12px] py-[8px] */
.additional-product-list {
  @apply bg-primary-10 select-none rounded-[6px];
  @apply py-[8px] px-[12px];
}

.row-item {
  @apply flex items-start justify-between gap-[8px];
  @apply py-[12px];
}

.sub-row-item {
  @apply flex items-start justify-between text-gray-60 text-sm gap-[8px];
  @apply py-[12px];
}

.divider {
  @apply w-full h-[2px] bg-primary-10;
}
</style>
