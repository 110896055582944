<template>
  <div class="flex flex-col" style="gap: 12px">
    <p class="text-primary-100 font-bold text-normal">回饋金</p>
    <div>
      <p
        class="cashback-order-discount-item"
        :class="{ isSelected: isSelected({id: 'cashback-all'}), isDisabled: disabledControl.all, }"
        @click="onSelect('all')"
      >
        回饋金全數折抵
      </p>
      <p
        class="cashback-order-discount-item"
        :class="{
          isSelected: isSelected({id: 'cashback-part'}),
          isDisabled: disabledControl.part,
        }"
        @click="onSelect('part')"
      >
        回饋金部分折抵
      </p>
    </div>

    <SalesPOSCashbackPartDiscountModal
      v-if="modal.partDiscount"
      :maxPrice="maxDiscountPrice"
      @confirm="onConfirmPartDiscount"
      @close="modal.partDiscount = false"
    />
  </div>
</template>

<script>
import { useSalesPOSCreate } from '@/use/useSalesPOS'
import { computed, defineComponent, reactive } from 'vue'
import SalesPOSCashbackPartDiscountModal from '@/components/SalesPOSCashbackPartDiscountModal.vue'
import { get, find } from 'lodash'

export default defineComponent({
  name: 'SalesPOSCheckoutCashbackDiscountList',
  components: {
    SalesPOSCashbackPartDiscountModal,
  },
  setup (props, { emit }) {
    const {
      context, addOrderDiscount, removeOrderDiscount, configData, orderUnpaidPrice,
      computedUsedResource,
    } = useSalesPOSCreate()
    const modal = reactive({
      partDiscount: false,
    })

    const isSelected = computed(() => {
      return (item) => {
        return Boolean(find(context.orderDiscount, { id: item.id }))
      }
    })

    const disabledControl = computed(() => {
      const controls = {
        part: false,
        all: false,
      }

      if (!isSelected.value({ id: 'cashback-part' })) {
        if (orderUnpaidPrice.value <= 0 || find(context.orderDiscount, { id: 'cashback-all' })) {
          controls.part = true
        }
      }

      if (!isSelected.value({ id: 'cashback-all' })) {
        if (orderUnpaidPrice.value <= 0) {
          controls.all = true
        }
      }

      return controls
    })

    const maxDiscountPrice = computed(() => {
      return orderUnpaidPrice.value
    })

    const onSelect = (type) => {
      const isSelectedAll = isSelected.value({ id: 'cashback-all' })
      const isSelectedPart = isSelected.value({ id: 'cashback-part' })

      if (type === 'part') {
        if (disabledControl.value.part) return
        if (isSelectedPart) {
          removeOrderDiscount({ id: 'cashback-part' })
          return
        }
        modal.partDiscount = true
      } else if (type === 'all') {
        if (disabledControl.value.all) return
        if (isSelectedPart) {
          removeOrderDiscount({ id: 'cashback-part' })
        }
        if (isSelectedAll) {
          removeOrderDiscount({ id: 'cashback-all' })
          return
        }

        const usedCashback = get(computedUsedResource.value, 'cashback', 0)
        const memberCashback = get(configData, 'consumerData.cashbackBalance', 0)
        const remainingCashback = memberCashback - usedCashback
        const remainingPrice = orderUnpaidPrice.value

        let cashbackUse = 0
        if (remainingPrice >= remainingCashback) {
          cashbackUse = remainingCashback
        } else {
          cashbackUse = remainingPrice
        }

        addOrderDiscount({
          id: 'cashback-all',
          name: '回饋金折抵',
          mustSpendSetting: {
            type: 'cashback',
            amount: cashbackUse,
          },
          discountSource: 'noSource',
          type: 'cashback',
          balance: memberCashback,
          cashAmount: cashbackUse,
        })
      }
    }

    const onConfirmPartDiscount = (data) => {
      const isSelectedAll = isSelected.value({ id: 'cashback-all' })
      if (isSelectedAll) {
        removeOrderDiscount({ id: 'cashback-all' })
      }
      addOrderDiscount({
        id: 'cashback-part',
        name: '回饋金折抵',
        mustSpendSetting: {
          type: 'cashback',
          amount: data.amount,
        },
        discountSource: 'noSource',
        type: 'cashback',
        cashAmount: data.amount,
      })
    }

    return { onSelect, modal, isSelected, onConfirmPartDiscount, disabledControl, maxDiscountPrice }
  },
})
</script>

<style lang="postcss" scoped>
.cashback-order-discount-item {
  @apply font-bold text-sub text-gray-80 p-[8px] select-none cursor-pointer;
}

.isSelected {
  @apply bg-primary-30 text-primary-100;
}

.isDisabled {
  @apply opacity-50 cursor-not-allowed;
}
</style>
